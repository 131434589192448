import { Pagination } from 'antd';
import { FC } from 'react';
import './index.less';
import { ArrowLeft } from '../../../Icons/Documents/arrowLeft';
import { ArrowRight } from '../../../Icons/Documents/arrowRight';

type PaginationsProps = {
  current?: number | null;
  changePagination?: any;
  total?: number | null;
  pageSize?: number | null;
  showSizeChanger?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const PaginationComponent: FC<PaginationsProps> = ({
  current,
  changePagination,
  total,
  pageSize,
  showSizeChanger,
  className,
  style
}) => {
  return (
    <div className={`pagination ${className ?? ''}`} style={style}>
      <Pagination
        current={current}
        onChange={changePagination}
        total={total}
        pageSize={pageSize}
        showSizeChanger={showSizeChanger}
        pageSizeOptions={['10', '20', '50', '100']}
        prevIcon={<ArrowLeft />}
        nextIcon={<ArrowRight />}
      />
    </div>
  );
};
