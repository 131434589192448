import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const ItemRender = ({
  value,
  optionkey,
  selectOption,
  filtervalue,
  checked,
  preventDeselectAll,
}) => {
  const intl = useIntl();
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(checked); // Set the checked state based on the prop
  }, [checked]);

  const handleCheckboxChange = () => {
    if (preventDeselectAll && filtervalue.length === 1) {
      setChecked(true);
    } else {
      setChecked(!isChecked); // Toggle the checked state when the li is clicked
    }
  };

  const handleOptionSelect = (value) => {
    selectOption(value);
    if (preventDeselectAll && filtervalue.length === 1) {
      setChecked(true);
    } else {
      setChecked(!isChecked);
    }
  };
  const isOptionSelected = (option) => {
    return filtervalue.includes(option);
  };
  return (
    <li
      key={optionkey}
      className={`option_filter ${
        isOptionSelected(optionkey) ? 'selected' : ''
      }`}
    >
      <input
        id={optionkey}
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        onClick={() => handleOptionSelect(optionkey)}
      />
      <label htmlFor={optionkey} className="label_option">
        {typeof value === 'string'
          ? intl.formatMessage({ id: value })
          : (value as string)}
      </label>
    </li>
  );
};

export default ItemRender;
