export const CalendarSVG = () => {
  return (
    <span
      role="img"
      aria-label="dashboard"
      className="anticon anticon-dashboard"
    >
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.83325 11.7023V11.6459M7.20825 11.7023V11.6459M7.20825 8.64591V8.58951M10.2083 8.64591V8.58951M1.58325 5.64587H12.0833M2.94039 1.33337V2.45851M10.5833 1.33337V2.45837M10.5833 2.45837H3.08325C1.84061 2.45837 0.833252 3.46573 0.833252 4.70837V12.2084C0.833252 13.4511 1.84061 14.4584 3.08325 14.4584H10.5833C11.8259 14.4584 12.8333 13.4511 12.8333 12.2084L12.8333 4.70837C12.8333 3.46573 11.8259 2.45837 10.5833 2.45837Z"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
