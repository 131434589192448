import { commonRequest } from 'services/generic';
import { AuthenticateRequest, AuthenticateResponse, RefreshTokensRequest } from './types';

export default class AuthProvider {
  async authenticate(payload: AuthenticateRequest) {
    const payloadData = { payload: payload }; 
    return await commonRequest<AuthenticateResponse>(
        'POST', 
        payloadData, 
        'Users/authenticate'
    );
  }

  async refreshToken(payload: RefreshTokensRequest) {
    const payloadData = { payload: payload }; 
    return await commonRequest<AuthenticateResponse>(
        'POST', 
        payloadData, 
        'Users/refreshToken'
    );
  }
}
