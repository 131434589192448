import './CardRequest.less';

import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import {
  parseTimeOffStatistics,
  rowNameTable
} from 'components/Widgets/TimeManagementWidget/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { getValue } from './utils';
import React from 'react';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import { useModalContext } from 'components/Modal/ModalProvider';

export const CardRequest = ({
  icon,
  title,
  request,
  allowunfinishedrequests,
  data,
  index,
  scope,
  openModal,
  setModalOpen,
  setModalProps,
  requestFormProps,
  selecteditem,
  setselecteditem,
  filteredrequests,
  onHover,
  isHovered,
  onMouseLeave
}: {
  icon: JSX.Element;
  title: string;
  request: string;
  allowunfinishedrequests: boolean;
  data: any;
  index: number;
  scope: string;
  openModal?: any;
  setModalOpen?: any;
  setModalProps?: any;
  requestFormProps?: any;
  selecteditem: number;
  setselecteditem: any;
  filteredrequests: any;
  onHover?: any;
  isHovered?: boolean;
  onMouseLeave?: any;
}) => {
  const { theme } = useThemeContext();
  const intl = useIntl();
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, adminRoles);
  const { toggleModal, isModalOpen } = useModalContext();

  const statistics =
    data &&
    data.statistics &&
    data.statistics.length &&
    data.statistics &&
    parseTimeOffStatistics(data);

  const sendUnfinishedText = intl.formatMessage({ id: 'sendUnfinished' });
  const cancelBtnText = intl.formatMessage({ id: 'cancel' });
  const requestText = intl.formatMessage({ id: 'request' });
  if (theme === 'dark') {
    rowNameTable[0].colorRow = '#fff';
  } else {
    rowNameTable[0].colorRow = '#393EC0';
  }

  const requestprops = [
    {
      formKeyId: 'timeOffForm',
      requestType: 'personal',
      modalTitle: <FormattedMessage id="RequestTimeOffModalTitle" />,
      submitBtnText: requestText,
      cancelBtnText: cancelBtnText,
      additionalBtnText: sendUnfinishedText,
      scope: scope === 'ColleagueProfile' ? 'ColleagueProfile' : 'request',
      isTimeOffRequestForm: true,
      displayRequestButton: true,
      title,
      requestTypeOff: request,
      displayFooterAdditionalButton: allowunfinishedrequests
    }
  ];

  const specialRequestProps = [
    {
      formKeyId: 'timeOffForm',
      requestType: 'special',
      modalTitle: <FormattedMessage id="RequestSpecialTimeOffModalTitle" />,
      submitBtnText: requestText,
      cancelBtnText: cancelBtnText,
      additionalBtnText: sendUnfinishedText,
      scope: scope === 'ColleagueProfile' ? 'ColleagueProfile' : 'request',
      isTimeOffRequestForm: true,
      displayRequestButton: true,
      title,
      requestTypes: filteredrequests,
      displayFooterAdditionalButton: allowunfinishedrequests
    }
  ];
  return (
    <div
      className={`card-request ${theme} ${
        isModalOpen &&
        selecteditem >= 0 &&
        openModal.request &&
        index === selecteditem
          ? 'selected'
          : ''
      }`}
    >
      <div
        className="card-request__header"
        onMouseLeave={onMouseLeave}
        onMouseOver={onHover}
        data-item={index}
        onClick={() => {
          setselecteditem(index);
          (userRole !== userRoles.Accountant || scope !== 'ColleagueProfile') &&
            setModalOpen({
              editProfile: false,
              clockInclockOut: false,
              request:
                allowPermision(user.role, [userRoles.Admin, userRoles.HR]) ||
                scope === 'MyProfile'
            });
          (userRole !== userRoles.Accountant || scope !== 'ColleagueProfile') &&
            (request === 'Special'
              ? setModalProps(...specialRequestProps)
              : setModalProps(...requestprops));
          (userRole !== userRoles.Accountant || scope !== 'ColleagueProfile') &&
            toggleModal();
        }}
      >
        <div className="card-request__cover-container">
          <div className={`card-request__cover ${isHovered ? 'hovered' : ''}`}>
            {icon}
          </div>
        </div>
        <div
          className="card-request__title"
          style={{ padding: isHovered ? '0' : '4px', pointerEvents: 'none' }}
        >
          {isHovered ? (
            <div className={`card-request__button ${theme}`}>
              <FormattedMessage id="TimeOffEvent" />
            </div>
          ) : (
            <span className="card-request__title-text">{title}</span>
          )}
        </div>
      </div>
      <div className="card-request__content">
        <div className="card-request__details">
          {request !== 'Special' &&
            statistics?.rowKeys
              .filter(row => row !== 'owner')
              .map(row =>
                rowNameTable.map(({ titleRow, keyRow, colorRow }, index) => {
                  if (keyRow === row) {
                    return (
                      <React.Fragment key={keyRow}>
                        {statistics?.keyColumns.map((i, index) => {
                          if (request === i) {
                            return (
                              <div
                                key={index}
                                className="card-request__details-item"
                              >
                                <span className="card-request__details-label">
                                  {' '}
                                  {titleRow}
                                </span>
                                <span
                                  className="card-request__details-value"
                                  style={{ color: colorRow }}
                                >
                                  {' '}
                                  {getValue(i, row, statistics)}
                                </span>
                              </div>
                            );
                          }
                        })}
                      </React.Fragment>
                    );
                  }
                })
              )}
          {request === 'Special' &&
            rowNameTable.map(({ titleRow, keyRow, colorRow }, index) => {
              return (
                <React.Fragment key={index}>
                  <div key={index} className="card-request__details-item">
                    <span className="card-request__details-label">
                      {' '}
                      {titleRow}
                    </span>
                    <span
                      className="card-request__details-value"
                      style={{ color: colorRow }}
                    >
                      {' '}
                      n/a
                    </span>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};
