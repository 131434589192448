import { FC, useState } from 'react';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowUnion } from 'Icons/General/arrowUnion';
import { DateToUTC, convertToLocalDate } from 'components/DateParser';
import dayjs from 'dayjs';
interface INotificationBoxComponent {
  headerTitle: string;
  boxKey: number | string;
  readStatus: boolean;
  createdDate?: Date;
  itemMessage: string | any;

  cssClass?: string;
  unreadCounter?: number;
  notificationBodyClick?: (notificationId: number) => void;
  onCollapseChange?: (e) => void;
  activeKey?: number;
  activeKeyProprety?: boolean;
}

const NotificationBoxComponent: FC<INotificationBoxComponent> = ({
  headerTitle,
  boxKey,
  readStatus,
  createdDate,
  itemMessage,

  cssClass,
  unreadCounter,
  notificationBodyClick,
  onCollapseChange,
  activeKey,
  activeKeyProprety
}) => {
  // convert time to locale
  const dateTime = convertToLocalDate(createdDate);
  const [isCollapsePanelOpened, setIsCollapsePanelOpened] = useState(false);

  const itemCreatedDate = createdDate ? new Date(DateToUTC(dateTime)) : null;
  const isGroupPanel = isNaN(boxKey as number);

  const collapseProps = activeKeyProprety
    ? {
      activeKey: activeKey
    }
    : {};

  return (
    <div key={boxKey} className="boxComponent">
      <div className="preNotification">
        <div className={`notificationTypeIcon ${readStatus ? '' : 'unread'}`}>
          {Array.from(headerTitle)[0]}
        </div>
        {unreadCounter && <div className="unreadCounter">{unreadCounter}</div>}
      </div>
      <Collapse
        {...collapseProps}
        bordered={false}
        style={{ fontWeight: readStatus ? 'normal' : 'bold' }}
        className={cssClass}
        expandIconPosition={'end'}
        expandIcon={({ isActive }) => (
          <ArrowUnion
            style={{ transform: isActive ? 'rotate(0deg)' : 'rotate(90deg)' }}
          />
        )}
        onChange={e => {
          setIsCollapsePanelOpened(prevState => !prevState);
          onCollapseChange && onCollapseChange(e);
        }}
      >
        <Collapse.Panel
          header={
            <div className={`${  boxKey === activeKey ? '': 'line-clamp-1'}`}>{headerTitle}</div>}
          key={boxKey}
        >
          {isGroupPanel ? (
            itemMessage
          ) : (
            <span onClick={() => notificationBodyClick(boxKey as number)}>
              {itemMessage}
            </span>
          )}
        </Collapse.Panel>
        <div className="notification-footer">
          {itemCreatedDate && !(isGroupPanel && isCollapsePanelOpened) && (
            <div className="notification-footer-time">
              {itemCreatedDate.toDateString() === new Date().toDateString()
                ? `${String(itemCreatedDate.getHours()).padStart(
                  2,
                  '0'
                )}:${String(itemCreatedDate.getMinutes()).padStart(2, '0')}`
                : `${itemCreatedDate
                  .toString()
                  .slice(4, 7)} ${itemCreatedDate.getDate()} ${itemCreatedDate
                    .toTimeString()
                    .slice(0, 5)}`}
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default NotificationBoxComponent;
