import { Col, Row } from 'antd';
import TicketsDetailsSection from './Layout/TicketsDetailsSection';
import TicketsMainSection from './Layout/TicketsMainSection';
import TicketsCardsSection from './Layout/TicketsCardsSection';
import styles from './detailedView.module.less';
import useSelectedTicketFromURL from './Layout/TicketsMainSection/hooks/useSelectedTicket';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { getAllTickets } from 'redux/slices/ticketingSlice/actions';
import { useOnMethodUpdate } from 'hooks/useOnMethodUpdate';

export default function TicketingDetailedView() {
  //whenever ticketID from URL changes, the getTicketById is dispatched
  useSelectedTicketFromURL();
  //trigger re-render when ticketing form is submitted and dispatches the "methodUpdate"
  useOnMethodUpdate(
    getAllTickets({
      pagination: { resultSize: 10, pageNumber: 1 }
    })
  );

  return (
    <Row gutter={16} className={styles.wrap}>
      <Col
        span={6}
        className={styles.scrollable}
        style={{ paddingRight: '0px' }}
      >
        <TicketsCardsSection />
      </Col>
      <Col span={13}>
        <TicketsMainSection />
      </Col>
      <Col
        style={{
          paddingRight: '0px'
        }}
        span={5}
        className={styles.scrollable}
      >
        <TicketsDetailsSection />
      </Col>
    </Row>
  );
}
