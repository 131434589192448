import { Col, Row, Typography } from 'antd';
import styles from './ticketsDetailsSection.module.less';
import dayjs from 'dayjs';
import PersonSelector from './components/PersonSelector';
import { FormattedMessage, useIntl } from 'react-intl';

import PrioritySelect from './components/PrioritySelect';
import { StatusSelect } from './components/StatusSelect';
import AsigneeSelect from './components/AssigneeSelect';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getTimezoneString } from 'utils/displayDate';
import { getCurrentEmployeePosition } from 'components/Employee/EmployeeForm/utils';
const TicketsDetailsSection = props => {
  const { selectedTicket: ticket } = useSelector(
    (state: RootState) => state.ticketing
  );
  const intl = useIntl();

  return (
    <div id="ticket__details" className={styles.container}>
      <div
        style={{
          overflow: 'auto',
          height: '100%',
          marginRight: '-8px'
        }}
      >
        {ticket && (
          <>
            <section>
              {/* Status */}
              <Row justify={'space-between'} align={'middle'}>
                <Col>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    <FormattedMessage id="TicketingTableColumn_status" />
                  </Typography.Title>
                </Col>
                <Col>
                  <StatusSelect />
                </Col>
              </Row>
              {/* Priority */}

              <Row justify={'space-between'} align={'middle'}>
                <Col>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    <FormattedMessage id="TicketingTableColumn_priority" />
                  </Typography.Title>
                </Col>
                <Col>
                  <PrioritySelect />
                </Col>
              </Row>
              {/* Due date */}
              <Row justify={'space-between'}>
                <Col span={24}>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    <FormattedMessage id="TicketingDetailsSection_due_date" />
                  </Typography.Title>
                </Col>
                <Col>
                  <Typography.Text>
                    {ticket?.dueDate
                      ? dayjs(ticket?.dueDate).format('DD/MM/YYYY HH:mm') +
                        ' ' +
                        getTimezoneString(dayjs(ticket?.dueDate))
                      : '-'}
                  </Typography.Text>
                </Col>
              </Row>
            </section>
            <section>
              {/* Category */}
              <Row>
                <Col span={24}>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    <FormattedMessage id="TicketingCommentSection_category" />
                  </Typography.Title>
                </Col>
                <Col>
                  <Typography className={styles.highlightedLabel}>
                    {ticket?.category &&
                      intl.formatMessage({
                        id: ticket?.category?.key,
                        defaultMessage: ticket?.category?.name
                      })}
                  </Typography>
                </Col>
              </Row>
              {/* Sub-category */}
              {ticket?.subCategory && (
                <Row>
                  <Col span={24}>
                    <Typography.Title level={5} className={styles.sectionTitle}>
                      <FormattedMessage id="TicketingDetailsSection_subcategory" />
                    </Typography.Title>
                  </Col>
                  <Col>
                    <Typography className={styles.highlightedLabel}>
                      {ticket?.subCategory &&
                        intl.formatMessage({
                          id: ticket?.subCategory?.key,
                          defaultMessage: ticket?.subCategory?.name
                        })}
                    </Typography>
                  </Col>
                </Row>
              )}
            </section>
            <section>
              {/* Assignee */}
              <Row>
                <Col span={24}>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    <FormattedMessage id="TicketingTableColumn_assignee" />
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <AsigneeSelect />
                </Col>
              </Row>
            </section>
            {/* Requester */}
            <section>
              <Row>
                <Col span={24}>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    <FormattedMessage id="TicketingDetailsSection_requester" />
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <PersonSelector
                    avatarSrc={ticket?.requester?.externalAvatarUrl}
                    employee={ticket?.requester}
                  />
                </Col>
              </Row>
            </section>
            {/* Role */}

            <section>
              <Row>
                <Col span={24}>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    <FormattedMessage id="Role" />
                  </Typography.Title>
                </Col>
                <Col>
                  <Typography.Text>
                    {getCurrentEmployeePosition(ticket?.requester?.position)}
                  </Typography.Text>
                </Col>
              </Row>
              {/* Email */}

              <Row>
                <Col span={24}>
                  <Typography.Title level={5} className={styles.sectionTitle}>
                    E-mail
                  </Typography.Title>
                </Col>
                <Col>
                  <Typography.Text>{ticket?.requester?.email}</Typography.Text>
                </Col>
              </Row>
            </section>
          </>
        )}
      </div>
    </div>
  );
};
export default TicketsDetailsSection;
