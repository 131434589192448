import { FormattedMessage } from 'react-intl';

export const titlesKeysofColumnsSoftware = [
  {
    title: <FormattedMessage id="inventorySoftware_table_Model" />,
    dataIndex: 'model',
  },
  {
    title: <FormattedMessage id="inventorySoftware_table_Category" />,
    dataIndex: 'categoryName',
  },
  {
    title: <FormattedMessage id="inventorySoftware_table_Details" />,
    dataIndex: 'details',
  },
  {
    title: <FormattedMessage id="inventorySoftware_table_Frequency" />,
    dataIndex: 'frequency',
  },
  {
    title: <FormattedMessage id="inventorySoftware_table_Price" />,
    dataIndex: 'price',
  },
  {
    title: <FormattedMessage id="inventorySoftware_table_Assignee" />,
    dataIndex: 'assigneeName',
  },
];

export const frequencySoftware = [
  {
    title: <FormattedMessage id="None" />,
    dataIndex: 'None',
  },
  {
    title: <FormattedMessage id="Monthly" />,
    dataIndex: 'Monthly',
  },
  {
    title: <FormattedMessage id="Yearly" />,
    dataIndex: 'Yearly',
  },
  {
    title: <FormattedMessage id="OneTime" />,
    dataIndex: 'OneTime',
  },
];
