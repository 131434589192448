export const FillArrowUp = ({ className, ...props }) => (
  <div role="img" aria-label="filter_arrow_up" className={`filter_arrow_up ${className}`} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 8 3"
      fill="none"
    >
      <path d="M4 0L7.4641 3H0.535898L4 0Z" fill="currentColor" />
    </svg>
  </div>
);
