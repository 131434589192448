import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Form, Input, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useModalContext } from 'components/Modal/ModalProvider';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { TimeOffColleaguesActions } from 'context/TimeManagement/ColleaguesRequests/actions';
import { useColleaguesRequestsFilterContext } from 'context/TimeManagement/ColleaguesRequests/context';
import dayjs from 'dayjs';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  isEventTimeOffRequestBased,
  parseHourMinutes
} from '../../TimeSheet/TimeSheetNew/utils';
import { conflictTypes } from '../../TimeSheet/TimeSheetTable/columnsTimeSheet';
import { aproximateHour, getEventsDay } from '../../TimeSheet/utils';
import '../index.less';

type DayEditFormProps = {
  data: any;

  children?: ReactNode;

  onFinishFormActions?: any;
  form?: any;
};

//is new form beacause new api was provided with refactor timesheet for inputs data in form
const DayViewForm: FC<DayEditFormProps> = ({ data, form }) => {
  const valueInputRef = useRef(null);

  const { theme } = useThemeContext();

  const { toggleModal } = useModalContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { timeOffColleaguesDispatch } = useColleaguesRequestsFilterContext();

  useEffect(() => {
    let notes;
    if (data.events) {
      notes = data.events
        .filter(({ type }) => type === 'ClockInOutHR')
        .map(({ description }) => description)[0];
    } else notes = [];
    const fields = {
      dateOnly: data?.dateOnly
        ? dayjs(data.dateOnly).format('MMMM D, YYYY')
        : null
    };

    if (conflictTypes.includes(data?.conflictType)) {
      if (valueInputRef.current) {
        valueInputRef.current.focus();
      }
    }

    form.setFieldsValue(fields);
  }, [data, form]);

  const eventText = intl.formatMessage({
    id: 'label_ViewConflictForm_Event'
  });

  return (
    <Form
      form={form}
      key="DayEditForm"
      id="dayEditForm"
      colon={false}
      name="basic"
      labelAlign="left"
      labelWrap
      className="day_edit_form attendance_form"
    >
      <Row key="Date">
        <ColFormItemComponent
          label={<FormattedMessage id="label_ViewConflictForm_Date" />}
          name="dateOnly"
          className={`col-form-item-component-view ${theme}`}
          span={24}
          isDisabled
        >
          <Input autoComplete="off" disabled={true} />
        </ColFormItemComponent>
      </Row>

      {data?.events?.map(
        (
          {
            requestId,
            eventDescription,
            eventType,
            workingHours,
            startDate,
            endDate,
            requestStatus
          },
          index
        ) => {
          const aproxHours = aproximateHour(workingHours, false);

          const displayEvents = getEventsDay(
            eventType,
            requestStatus,
            eventDescription,
            parseHourMinutes(aproxHours),
            startDate,
            endDate,
            intl
          )[eventType];

          return (
            <Row key={index}>
              <ColFormItemComponent
                label={`${eventText} ${index + 1}`}
                span={24}
                isDisabled
                isReadOnly={`col-form-item-component-view ${theme}`}
                onClick={() => {
                  if (
                    isEventTimeOffRequestBased(
                      eventType,
                      eventDescription,
                      requestId
                    )
                  ) {
                    toggleModal();
                    timeOffColleaguesDispatch({
                      type: TimeOffColleaguesActions.UPDATE_REQUEST_ID,
                      payload: {
                        requestId: requestId
                      }
                    });
                    if (!window.location.href.includes('colleaguesRequests')) {
                      navigate(
                        '/timeManagement/colleaguesRequests?requestId=' +
                          requestId
                      );
                    }
                  }
                }}
              >
                <TextArea
                  value={displayEvents}
                  autoSize
                  readOnly
                  className={`event-eventDescription ${
                    isEventTimeOffRequestBased(
                      eventType,
                      eventDescription,
                      requestId
                    )
                      ? 'underline_request'
                      : ''
                  }`}
                />
              </ColFormItemComponent>
            </Row>
          );
        }
      )}
    </Form>
  );
};

export default DayViewForm;
