import {
  IDocumentFields,
  toCommaSeparatedValuesStr,
  formatDateRange,
  formattedDateSingular,
} from './utils';
import { memo } from 'react';
import { useSafeIntl } from 'components/UIComponents/SafeFormattedMessage';
import {
  EmployeeDocumentSubType,
  EmployeeDocumentType,
} from 'services/providers/EmployeeDocumentProvider/utils';
import { Typography } from 'antd';

export const DocumentShortInfo = memo<{
  document: IDocumentFields;
}>(({ document }) => {
  const parsedDocumentObj = { ...document };
  const safeIntl = useSafeIntl();

  const {
    documentType,
    documentSubType,
    issueDate,
    expiryDate,
    documentStatus,
    countryIssuer,
    licenseCategory,
    placeOfBirth,
    birthDate,
    issuedBy,
    validFrom,
    validTo,
    specialization,
    institutionName,
    studyPeriodFrom,
    studyPeriodTo,
  } = parsedDocumentObj;

  const formattedFields = {
    ValidFromAndToDates: formatDateRange(validFrom, validTo),
    IssueDate: formattedDateSingular(issueDate),
    ExpiryDate: formattedDateSingular(expiryDate),
    IssueAndExpiryDates: formatDateRange(issueDate, expiryDate),
    Status:
      safeIntl.formatMessageSafely({ id: 'status' }) +
      ': ' +
      safeIntl.formatMessageSafely({ id: documentStatus }),
    SubType: safeIntl.formatMessageSafely({ id: documentSubType }),
    CountryIssuer: countryIssuer,
    LicenseCategory: safeIntl.formatMessageSafely({ id: licenseCategory }),
    PlaceOfBirth: safeIntl.formatMessageSafely({ id: placeOfBirth }),
    BirthDate: formattedDateSingular(birthDate),
    IssuedBy: issuedBy,
    StudyPeriodFromAndToDates: formatDateRange(studyPeriodFrom, studyPeriodTo),
    Specialization: specialization,
    InstitutionName: institutionName,
  };
  const ShortDescriptionByDocumentTypeMap = {
    [EmployeeDocumentType.IdentityDocument]: {
      [EmployeeDocumentSubType.IdentificationDocument]:
        toCommaSeparatedValuesStr(
          formattedFields.SubType,
          formattedFields.IssueAndExpiryDates,
          formattedFields.Status
        ),
      [EmployeeDocumentSubType.Passport]: toCommaSeparatedValuesStr(
        formattedFields.SubType,
        formattedFields.CountryIssuer,
        formattedFields.IssueAndExpiryDates,
        formattedFields.Status
      ),
      [EmployeeDocumentSubType.DriversLicense]: toCommaSeparatedValuesStr(
        formattedFields.SubType,
        formattedFields.LicenseCategory,
        formattedFields.IssueAndExpiryDates,
        formattedFields.Status
      ),
      [EmployeeDocumentSubType.BirthCertificate]: toCommaSeparatedValuesStr(
        formattedFields.SubType,
        formattedFields.PlaceOfBirth,
        formattedFields.BirthDate
      ),
      [EmployeeDocumentSubType.Other]: toCommaSeparatedValuesStr(
        formattedFields.SubType,
        safeIntl.formatMessageSafely({ id: 'TypeOfDocumentText' }),
        formattedFields.IssuedBy,
        formattedFields.IssueAndExpiryDates,
        formattedFields.Status
      ),
    },
    [EmployeeDocumentType.Agreement]: toCommaSeparatedValuesStr(
      formattedFields.SubType,
      formattedFields.ValidFromAndToDates,
      formattedFields.Status
    ),
    [EmployeeDocumentType.Diploma]: toCommaSeparatedValuesStr(
      formattedFields.SubType,
      formattedFields.Specialization,
      formattedFields.InstitutionName,
      formattedFields.StudyPeriodFromAndToDates
    ),
    [EmployeeDocumentType.Others]: toCommaSeparatedValuesStr(
      formattedFields.SubType +
        ' ' +
        safeIntl.formatMessageSafely({ id: 'TypeOfDocumentText' }),

      formattedFields.IssuedBy,
      formattedFields.IssueAndExpiryDates,
      formattedFields.Status
    ),
    [EmployeeDocumentType.TimeOffDocument]: 'Not yet implemented',
  };

  //IdentityDocuments different informaton for different subtypes, thus necessity to access inner level of map
  if (documentType === EmployeeDocumentType.IdentityDocument) {
    return (
      <Typography.Paragraph>
        {ShortDescriptionByDocumentTypeMap['IdentityDocument'][documentSubType]}
      </Typography.Paragraph>
    );
  } else
    return (
      <Typography.Paragraph>
        {ShortDescriptionByDocumentTypeMap[documentType]}
      </Typography.Paragraph>
    );
});
