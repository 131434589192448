import CertificateIcon from 'assets/documentWidgetAssets/certificate.svg';
import LicenseIcon from 'assets/documentWidgetAssets/licence.svg';
import DiplomaIcon from 'assets/documentWidgetAssets/diploma.svg';

export enum EmployeeDocumentType {
  IdentityDocument = 'IdentityDocument',
  Agreement = 'Agreement',
  Diploma = 'Diploma',
  Others = 'Others',
  TimeOffDocument = 'TimeOffDocument',
}

export const EmployeeDocumentAssets = [
  [EmployeeDocumentType.IdentityDocument, LicenseIcon],
  [EmployeeDocumentType.Agreement, CertificateIcon],
  [EmployeeDocumentType.Diploma, DiplomaIcon],
  [EmployeeDocumentType.Others, CertificateIcon],
  [EmployeeDocumentType.TimeOffDocument, CertificateIcon],
];

export enum EmployeeDocumentSubType {
  IdentificationDocument = 'IdentificationDocument',
  Passport = 'Passport',
  DriversLicense = 'DriversLicense',
  BirthCertificate = 'BirthCertificate',
  BaccalaureateDiploma = 'BaccalaureateDiploma',
  CollegeDiploma = 'CollegeDiploma',
  BachelorsDegree = 'BachelorsDegree',
  MastersDegree = 'MastersDegree',
  DoctorateDegree = 'DoctorateDegree',
  CoursesAndCertifications = 'CoursesAndCertifications',
  WorkAgreement = 'WorkAgreement',
  ServicesAgreement = 'ServicesAgreement',
  Appendix = 'Appendix',
  Assets = 'Assets',
  Other = 'Other',
}

export enum EmployeeDocumentStatus {
  Uploaded = 'Uploaded',
  Approved = 'Approved',
  Denied = 'Denied',
  Archived = 'Archived',
}
