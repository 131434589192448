import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import {
  useIntlContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { TimeOffColleaguesActions } from 'context/TimeManagement/ColleaguesRequests/actions';
import { useColleaguesRequestsFilterContext } from 'context/TimeManagement/ColleaguesRequests/context';
import ro from 'date-fns/locale/ro';
import dayjs from 'dayjs';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';
import SingleSelectDropdown from '../SingleSelectDropdown';
import CustomInputDatePicker from './CustomInputDatePicker/CustomInputDatePicker';
import { FilterDatesCriteria } from './types';

import './DatePickerComponent.less';

registerLocale('ro', ro);

const DatePickerComponent = ({ onCalendarChange }) => {
  // use conntexts providers
  const intl = useIntl();
  const { theme } = useThemeContext();
  const { locale } = useIntlContext();
  const [dates, setDates] = useState([]);
  const { timeOffColleaguesState, timeOffColleaguesDispatch } = useColleaguesRequestsFilterContext();

  // Label
  const SelectTypeOfCondition = intl.formatMessage({
    id: 'SelectTypeOfCondition'
  });
  const ConditionOfFilter = intl.formatMessage({ id: 'ConditionOfFilter' });

  const onChange = dates => {
    const [start, end] = dates;
    setDates(dates);
    if (start === null && end === null) {
      onCalendarChange(null, 'Range', 'period');
    } else {
      onCalendarChange([dayjs(start), dayjs(end)], 'Range', 'period');
    }
  };

  const handleCalendarOpen = () => {
    if (
      timeOffColleaguesState?.filterBy?.period?.startDate && timeOffColleaguesState?.filterBy.period?.endDate) {
      setDates([
        timeOffColleaguesState?.filterBy?.period?.startDate,
        timeOffColleaguesState?.filterBy?.period?.endDate
      ]);
    };
  };

  const MyContainer = ({ className, children, onCalendarChange }) => {
    return (
      <div className={`date-picker-custom-container ${theme} `}>
        <div className="header-filters-container">
          <div className="filter-dates-starts ">
            <SingleSelectDropdown
              dropdownAlign={{ offset: [34, -10] }}
              label={
                timeOffColleaguesState?.filterBy?.period?.customTimeRangeCriteria === null
                  ? `${SelectTypeOfCondition}`
                  : `${ConditionOfFilter} ${intl.formatMessage({
                    id: timeOffColleaguesState?.filterBy?.period?.customTimeRangeCriteria
                  })}`
              }
              value={timeOffColleaguesState?.filterBy?.period?.customTimeRangeCriteria}
              theme={theme}
              options={Object.keys(FilterDatesCriteria).map(value => ({
                value: value,
                label: intl.formatMessage({ id: value })
              }))}
              onChange={value => {
                timeOffColleaguesDispatch({
                  type: TimeOffColleaguesActions.UPDATE_TOOLBAR_CRITERIA_FILTER,
                  payload: {
                    requestId: null,
                    filterBy: {
                      period: {
                        customTimeRangeCriteria: value
                      }
                    },
                    filters: {
                      period: {
                        customTimeRangeCriteria: value
                      }
                    },
                  }
                });
              }}
            />
          </div>
        </div>
        <div>{children}</div>
      </div>
    );
  };

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <>
      <DatePicker
        renderCustomHeader={({
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
          increaseYear,
          decreaseYear,
          monthDate,
          yearDate
        }) => (
          <div className="calendar-header-title-month">
            <button
              className="navigation-arrow-picker"
              onClick={decreaseYear}
              disabled={prevYearButtonDisabled}
              style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
            >
              <DoubleLeftOutlined />
            </button>
            <button
              className="navigation-arrow-picker"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
            >
              <LeftOutlined />
            </button>
            <span className="react-datepicker__current-month">
              {dayjs(monthDate).format('MMM')} {dayjs(monthDate).format('YYYY')}
            </span>
            <button
              className="navigation-arrow-picker"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
            >
              <RightOutlined />
            </button>
            <button
              className="navigation-arrow-picker"
              onClick={increaseYear}
              disabled={nextYearButtonDisabled}
              style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
            >
              <DoubleRightOutlined />
            </button>
          </div>
        )}
        calendarClassName={`react-datepicker-custom-class ${theme} `}
        locale={locale}
        retainEndDateOnFirstSelection={true}
        moveRangeOnFirstSelection={false}
        customInput={<CustomInputDatePicker dates={dates} />}
        formatWeekDay={(day: string) =>
          (day.charAt(0).toUpperCase() + day.slice(1)).substring(0, 2)
        }
        selected={dates[1]}
        onChange={onChange}
        startDate={dates[0]}
        endDate={dates[1]}
        selectsRange
        monthsShown={2}
        calendarContainer={MyContainer}
        isClearable
        clearButtonClassName="clear-button"
        clearButtonTitle={'clear'}
        showPreviousMonths={true}
        dateFormat="MM/dd/yyyy"
        renderMonthContent={renderMonthContent}
        onCalendarOpen={handleCalendarOpen}
      />
    </>
  );
};

export default DatePickerComponent;
