import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITeam, } from 'services/providers/TeamsProvider/types';

const teamSlice = createSlice({
  name: 'teams',
  initialState: {},
  reducers: {
    setTeams(state, action: PayloadAction<ITeam[]>) {
      return { ...state, teams: action.payload };
    },
    setMyTeams(state, action: PayloadAction<any>) {
      return {...state, myTeams: action.payload}
  },
  }
});

export const { setTeams, setMyTeams } = teamSlice.actions;
export default teamSlice.reducer;
