import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompanySettings } from 'redux/types';

const companySettingsSlice = createSlice({
  name: 'user',
  initialState: {
    settings: {
      email: '',
      emailType: '',
      emailProvider: '',
      phoneCode: '',
      phoneCodeDigit: 0,
      phoneCodeCount: 0,
      phoneCount: 0,
      phoneCodeCountry: 0,
      countryCode: '',
      office: '',
    } as ICompanySettings
  },
  reducers: {
    setCompanySettings(state, action: PayloadAction<ICompanySettings>) {
      return { ...state, settings: action.payload };
    },
  }
});

export const { setCompanySettings } = companySettingsSlice.actions;
export default companySettingsSlice.reducer;
