import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { EventDefinitions } from 'pages/Calendar/types';
import { isTimeBasedRequest } from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm/utils';

import './EventListItem.less';
import { convertToLocalDate } from 'components/DateParser';

dayjs.extend(duration);

export const EventListItem = ({ theme, event, date }) => {
  const { eventType, eventName, startDate, endDate, timeOffType, timeFormat } =
    event;

  const formattedStartDate = dayjs(startDate).format('DD/MM/YYYY');
  const formattedEndDate = dayjs(endDate).format('DD/MM/YYYY');
  const formattedStartHour = dayjs(convertToLocalDate(startDate)).format(
    'HH:mm'
  );
  const formattedEndHour = dayjs(convertToLocalDate(endDate)).format('HH:mm');

  const durationInMinutes = dayjs(endDate).diff(dayjs(startDate), 'minute');
  const durationHours = Math.floor(durationInMinutes / 60);
  const durationMinutes = durationInMinutes % 60;

  const durationDays = dayjs(endDate).diff(dayjs(startDate), 'days') + 1;
  const isSameDay =
    dayjs(startDate).isSame(endDate, 'day') &&
    !dayjs(startDate).isSame(endDate, 'hour');

  const isTimeRangeEvent = isSameDay || timeFormat === 'TimeRange';

  const eventItemTextContent = (() => {
    if (isTimeBasedRequest(eventType) || isTimeRangeEvent) {
      return `${formattedStartHour} - ${formattedEndHour}, ${durationHours}h ${Math.abs(
        durationMinutes
      )}m`;
    } else {
      return durationDays > 1
        ? `${formattedStartDate} - ${formattedEndDate}`
        : `${formattedStartDate}`;
    }
  })();

  return (
    <div className={`event-list-item ${theme}`}>
      <div
        className={`event-list-item-marker`}
        style={{
          backgroundColor: EventDefinitions[eventType]
            ? EventDefinitions[eventType].badgeColor
            : '#5ab0ff'
        }}
      ></div>

      <div className="event-list-item-info">
        <Typography.Title level={5}>
          {['CompanyEvent', 'Holiday', 'TeamEvent', 'PersonalEvent'].includes(
            eventType
          ) && eventName ? (
            eventName.split('_').includes('BirthDate') ? (
              <FormattedMessage
                id="BirthDate"
                values={{
                  firstName: eventName.split('_')[0],
                  lastName: eventName.split('_')[1]
                }}
              />
            ) : (
              <>{eventName}</>
            )
          ) : eventType === 'TimeOffEvent' ? (
            <FormattedMessage id={timeOffType} />
          ) : (
            <FormattedMessage id={eventType} />
          )}
        </Typography.Title>

        <Typography.Paragraph>{eventItemTextContent}</Typography.Paragraph>
      </div>
    </div>
  );
};
