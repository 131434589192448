import dayjs from 'dayjs';

export const getMonthNameByNumber = (
  monthIndex: number,
  locale: string = 'ro'
): string => {
  if (monthIndex < 0 || monthIndex > 11) {
    throw new Error('Month index must be between 0 and 11');
  }
  dayjs.locale(locale);
  const date = dayjs().month(monthIndex);
  return date.format('MMMM');
};

export const getFormatTimeOfHours = (hours: number): any => {
  if (hours === 0) {
    return '─';
  }
  if (hours === undefined) {
    return '';
  }

  const hoursPart = Math.floor(hours);
  const minutesPart = Math.round((hours - hoursPart) * 60);

  return {
    hours: hoursPart.toString().padStart(2, '0'),
    minutes: minutesPart.toString().padStart(2, '0')
  };
};
