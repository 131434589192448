import { Typography } from 'antd';
import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { ArrowRight } from 'Icons/TimeManagementWidget/arrowRight';
import './calendarHeadingDateToggle.less';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

export const CalendarHeadingDateToggle = ({
  leftArrowClick = () => {},
  rightArrowClick = () => {},
  text = '',
}) => {
  const { theme } = useThemeContext();
  return (
    <div className={`calendar-heading ${theme}`}>
      <div className="toggle-wrapper">
        <ArrowLeft className="previsionElement" onClick={leftArrowClick} />
        <Typography.Title level={5}>{text}</Typography.Title>
        <ArrowRight className="nextElement" onClick={rightArrowClick} />
      </div>
    </div>
  );
};
