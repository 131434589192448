import { commonRequest } from 'services/generic';
import {
  GetAssetParams,
  IAsset,
  IAssetHistory,
  IAssignDeassignAsset
} from './types';

export default class AssetsProvider {
  async getAsset(payload: string, parameters: GetAssetParams) {
    if (!parameters.assetId) {
      return;
    } else {
      const payloadData = { parameters };
      return await commonRequest<IAsset>(
        'POST',
        payloadData,
        '/Inventory/GetAsset'
      );
    }
  }

  async getAssetHistory(parameters: { assetId: number }) {
    if (!parameters.assetId) {
      return;
    } else {
      const payloadData = { parameters };
      return await commonRequest<IAssetHistory[]>(
        'POST',
        payloadData,
        '/Inventory/GetAssetHistory'
      );
    }
  }

  async getAllAssets(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IAsset[]>(
      'POST',
      payloadData,
      '/Inventory/getAllAssets'
    );
  }

  async addAsset(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IAsset>(
      'POST',
      payloadData,
      '/Inventory/AddAsset'
    );
  }

  async updateAsset(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IAsset>(
      'POST',
      payloadData,
      '/Inventory/UpdateAsset'
    );
  }

  async assignAsset(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IAssignDeassignAsset>(
      'POST',
      payloadData,
      '/Inventory/assignAsset'
    );
  }

  async deassignAsset(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IAssignDeassignAsset>(
      'POST',
      payloadData,
      '/Inventory/deassignAsset'
    );
  }

  async getAssetAssignmentAgreementFile(parameters: {
    assetId: number;
    employeeId: number;
    fileName: string;
    teamId: number;
  }) {
    const payloadData = { parameters, customResponseType: 'arraybuffer' };
    return await commonRequest<any>(
      'GET',
      payloadData,
      '/Inventory/GetAssetAssignmentAgreementFile'
    );
  }

  async getInventoryTemplates() {
    return await commonRequest<any>(
      'GET',
      null,
      '/Inventory/GetInventoryTemplates'
    );
  }
}
