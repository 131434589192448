import { Tooltip, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { generateDaysColumns } from './AdditionalDataColumns/daysColumns';
import { initialAggregatedColumns } from './AdditionalDataColumns/additionalColumns';
import { addingAdditionalColumns } from './AdditionalDataColumns/addMoreColumns';
import _sort from 'utils/sorter';

const { Text, Paragraph } = Typography;

// export data  for columns TimeSheet
export const getDataOfEmployees = data => {
  const dataTimeSheet = data.map((employee, index) => {
    const timeSheetEntry = {
      //data for number column
      Nr: index + 1,
      //data for employee column
      EmployeeInfo: {
        employeeName: employee.name,
        employeeFunction: employee.function,
        employeeId: employee.id,
        employeeTeams: employee.teams
      }
    };
    //data for days columns
    employee?.days.forEach((day, indexDay) => {
      const daysOfMonth = employee?.days[indexDay];
      daysOfMonth.employeeId = employee.id;

      timeSheetEntry[indexDay + 1] = {
        letter: day.letter || '',
        events: day.events || [],
        conflictType: day.conflictType || '',
        data: daysOfMonth
      };
    });
    // data for aditional columns
    Object.entries(employee.aggColumns).forEach(([key, value]) => {
      timeSheetEntry[key] = value;
    });
    //add new columns
    Object.entries(employee.torColumns).forEach(([key, value]) => {
      timeSheetEntry[key] = value;
    });

    return timeSheetEntry;
  });
  return dataTimeSheet;
};

//generate Columns for TimeSheet Table
export const generateColumns = (
  data,
  params,
  hookUseStateActions,
  countryCode: string
) => {
  return [
    {
      key: 'Nr',
      title: (
        <Text className="text-bold-normal text-alignement-center text_color_timesheet_text">
          Nr.
        </Text>
      ),
      width: 35,
      className: 'employee_number_column',
      render: (_, record) => ({
        cellRowClassName: `employee_number`,
        content: (
          <div className="number_of_cell">
            <Text className="text-medium-normal text-alignement-center text_color_timesheet_text">
              {' '}
              {record?.Nr}
            </Text>
          </div>
        )
      })
    },
    {
      key: 'EmployeeInfo',
      title: (
        <div className="employee-title-details">
          <Text className="text-bold-normal text-alignement-center text_color_timesheet_text">
            <FormattedMessage id="table_TimesheetCol_LastName" />,{' '}
            <FormattedMessage id="table_TimesheetCol_FirstName" /> <br />{' '}
            <FormattedMessage id="table_TimesheetCol_Position" /> /{' '}
            <FormattedMessage id="table_TimesheetCol_Team" />
          </Text>
        </div>
      ),
      className: 'employee_details_column',
      // sorter: (a, b) => _sort(a.employeeName, b.employeeName, 'string'), // disable
      filters: getTeamsFilters(data),
      onFilter: (value, record) => {
        const teams = Object.values(
          record?.EmployeeInfo.employeeTeams
        ) as string[];
        return teams.some(team => value.includes(team));
      },
      render: (_, record) => ({
        cellRowClassName: `employee_details`,
        content: (
          <>
            <div
              className="employee_details_cell text_color_timesheet_text"
              style={{ width: '200px' }}
            >
              <Link
                to={`/allUsersList/viewProfile?employeeId=${record?.EmployeeInfo.employeeId}`}
                className="employee_name_elipsis text_color_timesheet_text"
              >
                <Typography.Text className="text-medium-big text_color_timesheet_text line-normal">
                  {record?.EmployeeInfo.employeeName}
                </Typography.Text>
              </Link>

              <Paragraph ellipsis={true} style={{ lineHeight: 'normal' }}>
                <Typography.Text className="text-medium-middle-small text_color_timesheet_text line-normal">
                  {record?.EmployeeInfo.employeeFunction ? (
                    record?.EmployeeInfo.employeeFunction
                  ) : (
                    <FormattedMessage id="TimeSheetTableRowCell_not_job_provided" />
                  )}
                </Typography.Text>{' '}
                <Tooltip
                  placement="top"
                  title={
                    (
                      Object.values(record?.EmployeeInfo.employeeTeams) as
                        | string[]
                        | undefined
                    )?.length > 0 ? (
                      (
                        Object.values(
                          record?.EmployeeInfo.employeeTeams
                        ) as string[]
                      ).map((team, index) => (index > 0 ? `, ${team}` : team))
                    ) : (
                      <FormattedMessage id="NoTeamsToDisplay" />
                    )
                  }
                >
                  <Text
                    className="text-medium-middle-small line-normal teams"
                    style={{ color: '#BDA7E8' }}
                  >
                    <>
                      {Object.values(record?.EmployeeInfo.employeeTeams) &&
                      Object.values(record?.EmployeeInfo.employeeTeams).length >
                        0 ? (
                        Object.values(record?.EmployeeInfo.employeeTeams).map(
                          (team, index) => (index > 0 ? `, ` + team : team)
                        )
                      ) : (
                        <FormattedMessage id="NoTeamsToDisplay" />
                      )}
                    </>
                  </Text>
                </Tooltip>
              </Paragraph>
            </div>
          </>
        )
      })
    },
    ...generateDaysColumns(data, params, hookUseStateActions, countryCode),
    ...initialAggregatedColumns(params.aggColumnsWithData, countryCode),
    ...addingAdditionalColumns(
      countryCode === 'RO'
        ? [...params.torColumns, ...params.totalsColumns]
        : params.totalsColumns,
      params.value,
      countryCode
    )
  ];
};

export const getTeamsFilters = data => {
  const uniqueTeams = new Set();
  const result = [];
  data &&
    data.forEach(employee => {
      Object.keys(employee.teams).forEach(teamId => {
        const teamName = employee.teams[teamId];
        // Check if teamId is not null and not already in the uniqueTeams set
        if (teamId && teamName && !uniqueTeams.has(teamId)) {
          result.push({
            text: teamName,
            value: teamName,
            key: teamId
          });

          uniqueTeams.add(teamId);
        }
      });
    });

  return result;
};
