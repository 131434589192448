import { PolicyConfig } from 'AppProvider/ConfigManager/config';
import { useMobileContext, useThemeContext } from 'AppProvider/ConfigProviderSettings';
import ComponentWithStatus from 'components/ComponentWithStatus';
import ModalComponent from 'components/Modal';
import { TimeManagementWidgetPlaceholder } from 'components/Placeholders';
import { TableParams } from 'components/Table';
import TimeOffRequestForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm';
import TimeOffRequestTable from 'components/TimeManagement/TimeOffRequest/TimeOffRequestTable';
import StateActionsForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestTable/StateActionsForm';
import { WidgetComponent } from 'components/Widgets';
import { TimeManagementWidget } from 'components/Widgets/TimeManagementWidget';
import { TimeOffPersonalActions } from 'context/TimeManagement/PersonalRequests/actions';
import { usePersonalRequestsFilterContext } from 'context/TimeManagement/PersonalRequests/context';
import useApiRequest from 'hooks/useApiRequest.hook';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Carousel } from 'react-responsive-carousel';
import { TimeOffService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { IEmployeeStatistics } from 'services/providers/EmployeeProvider/types';
import { TimeOffRequestResponse } from 'services/providers/TimeOffProvider/types';
import { useCompanySettings } from 'settings';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';
import './PersonalTimeOff.less';

type PersonalTimeOffPageProps = {};

export type PersonalTimeOffModalProps = {
  isTimeOffRequestForm?: boolean;
  formKeyId: string;
  modalTitle: string;
  submitBtnText: string | JSX.Element;
  cancelBtnText: string | JSX.Element;
  requestType?: string;
  scope?: string;
  timeOffId?: number;
  displayRequestButton?: boolean;
};

const PersonalTimeOffPage: React.FC<PersonalTimeOffPageProps> = () => {
  const intl = useIntl();
  const { user } = useAuth();
  const { countryCode } = useCompanySettings();
  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, adminRoles);
  const requestTimeOffText = intl.formatMessage({
    id: 'RequestTimeOffModalTitle'
  });

  const requestFormProps = {
    formKeyId: 'timeOffForm',
    requestType: 'personal',
    modalTitle: requestTimeOffText,
    submitBtnText: <FormattedMessage id="request" />,
    cancelBtnText: <FormattedMessage id="cancel" />,
    additionalBtnText: 'send unfinished',
    scope: 'request',
    isTimeOffRequestForm: true,
    displayRequestButton: true
  };

  const [modalProps, setModalProps] =
    React.useState<PersonalTimeOffModalProps>(requestFormProps);

  const { theme } = useThemeContext();

  const openModalDefaultValues = {
    editProfile: false,
    clockInclockOut: false,
    request: false,
    draft: false,
    stateAction: false
  };

  const [modalOpenValues, setModalOpenValues] = React.useState(
    openModalDefaultValues
  );

  let { data, status } = useApiRequest<IEmployeeStatistics>(
    TimeOffService.getTimeOffStatistics,
    null,
    {},
    {},
    null,
    [],
    false,
    true
  );

  //HIDE UNWANTED POLICIES
  data =
    Object.keys(data).length > 0
      ? {
        ...data,
        statistics: [
          ...data.statistics.filter(({ statisticType }) => {
            const policy = PolicyConfig[
              countryCode
            ].DefaultTimeOffRequests.find(
              policy => policy.request === statisticType
            );

            // If no matching policy is found, or if the policy is not hidden and not admin-only, include the statistic
            return (
              !policy ||
              (!policy.isHidden && !(policy.isAdminOnly && !isAdminUserRole))
            );
          })
        ]
      }
      : null;

  const { isMobile } = useMobileContext();
  const { timeOffPersonalState, timeOffPersonalDispatch } = usePersonalRequestsFilterContext();

  React.useEffect(() => {
    return () => {
      timeOffPersonalDispatch({
        type: TimeOffPersonalActions.RESET_TIME_OFF_PERSONAL_STATE
      });
    };
  }, []);

  const { data: responseData } = useApiRequest<TimeOffRequestResponse>(
    TimeOffService.getMyTimeOffRequests,
    {
      filterBy: {
        nextApproverIds: timeOffPersonalState?.filterBy?.nextApproverIds
      },
      pagination: timeOffPersonalState?.pagination,
    },
    [],
    {},
    null,
    [timeOffPersonalState, timeOffPersonalDispatch],
    false,
    true
  );

  const nextApproverFilter = responseData?.nextApprovers?.map(
    ({ employeeId, firstName, lastName, email }) => ({
      text: lastName + ' ' + firstName,
      value: employeeId
    })
  );

  const handleTableChange = (props: TableParams) => {
    const { pagination, filters } = props;
    const payload = {
      filterBy: {
        nextApproverIds: filters?.['primaryApproverEmployee'] as Array<number> || null,
      },
      pagination: {
        resultSize: pagination.pageSize,
        pageNumber: pagination.current
      }
    };
    timeOffPersonalDispatch({
      type: TimeOffPersonalActions.UPDATE_TIME_OFF_PERSONAL_TABLE_FILTER,
      payload: payload
    });
  };

  return (
    <>
      {isMobile ? (
        <Carousel
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          swipeScrollTolerance={40}
          preventMovementUntilSwipeScrollTolerance={true}
          className="mobile-widget-carousel"
        >
          <div className="mobile-carousel-item">
            <TimeOffRequestTable
              requestType="personal"
              modalOpenValues={modalOpenValues}
              setModalOpenValues={setModalOpenValues}
              setModalProps={setModalProps}
              responseData={responseData?.requests?.responseData}
              totalCount={responseData?.requests?.totalCount}
              resultSize={timeOffPersonalState?.pagination?.resultSize}
              onChange={handleTableChange}
            />
          </div>
          <div className="mobile-carousel-item">
            <div className={`border-custom ${theme} mobile-widget-height`}>
              <WidgetComponent
                bordered={false}
                className={`card-widget ${theme} time-management-widget `}
              >
                <TimeManagementWidget
                  scope="MyProfile"
                  employeeStatistics={{ data: data }}
                  openModal={modalOpenValues}
                  setModalOpen={setModalOpenValues}
                  size="small"
                />
              </WidgetComponent>
            </div>
          </div>
        </Carousel>
      ) : (
        <>
          {(modalOpenValues.draft || modalOpenValues.stateAction) && (
            <ModalComponent
              formKeyId={modalProps.formKeyId}
              children={
                modalOpenValues.draft ? (
                  <TimeOffRequestForm
                    requestType={modalProps.requestType}
                    scope={modalProps.scope}
                    timeOffId={modalProps.timeOffId}
                    context='personal'
                  />
                ) : (
                  <StateActionsForm
                    key={modalProps.formKeyId}
                    requestId={modalProps.timeOffId}
                    modalProps={modalProps}
                  />
                )
              }
              isMaskClosable={modalProps.scope === 'view'}
              title={modalProps.modalTitle}
              cancelBtnText={modalProps.cancelBtnText}
              submitBtnText={modalProps.submitBtnText}
              displayFooterSubmitButton={modalProps.displayRequestButton}
              justifyRequestButtonsStyle={
                modalProps.formKeyId === 'stateActionForm'
                  ? 'center'
                  : 'flex-end'
              }
            />
          )}

          <div className="personal-timeoff-page-layout">
            <div
              style={{
                height: '50%'
              }}
            >
              <div
                className={`border-custom ${theme} large ${status != 'success' ? `ant-card card-widget ${theme}` : ''}`}
              >
                <ComponentWithStatus
                  status={status}
                  Placeholder={<TimeManagementWidgetPlaceholder />}
                >
                  <WidgetComponent
                    bordered={false}
                    className={`card-widget ${theme} time-management-widget full-content`}
                  >
                    <TimeManagementWidget
                      scope="MyProfile"
                      employeeStatistics={{ data: data }}
                      openModal={modalOpenValues}
                      setModalOpen={setModalOpenValues}
                      size="large"
                    />
                  </WidgetComponent>
                </ComponentWithStatus>
              </div>
            </div>
            <div
              style={{
                height: '50%'
              }}
            >
              <TimeOffRequestTable
                requestType="personal"
                modalOpenValues={modalOpenValues}
                setModalOpenValues={setModalOpenValues}
                setModalProps={setModalProps}
                responseData={responseData?.requests?.responseData}
                nextApproverFilter={nextApproverFilter}
                totalCount={responseData?.requests?.totalCount}
                resultSize={timeOffPersonalState?.pagination?.resultSize}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PersonalTimeOffPage;
