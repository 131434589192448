import { FormattedMessage } from 'react-intl';

export const titlesKeysofColumnsColleagues = [
  {
    title: <FormattedMessage id='colleagues_tableCol_No'/>,
    dataIndex: 'employeeId',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_FirstName" />,
    dataIndex: 'firstName',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_LastName" />,
    dataIndex: 'lastName',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_FullName" />,
    dataIndex: 'fullName',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_Position" />,
    dataIndex: 'position',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_Teams" />,
    dataIndex: 'teams',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_Email"/>,
    dataIndex: 'email',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_PhoneNumber" />,
    dataIndex: 'phoneNumber',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_EmployeeStatus" />,
    dataIndex: 'status',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_StartDate" />,
    dataIndex: 'startDate',
  },
  {
    title: <FormattedMessage id="colleagues_tableCol_EndDate" />,
    dataIndex: 'endDate',
  },
];
