import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEmployee } from 'services/providers/EmployeeProvider/types';

const employeeSlice = createSlice({
  name: 'employee',
  initialState: [],
  reducers: {
    setEmployee(state, action: PayloadAction<IEmployee[]>) {
      return { ...state, employee: action.payload };
    },
  },
});

export const { setEmployee } = employeeSlice.actions;
export default employeeSlice.reducer;
