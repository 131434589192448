import {
  useIntlContext,
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { CardViewComponent } from 'Icons/Colleagues/cardViewComponent';
import { ListViewComponent } from 'Icons/Colleagues/listViewComponent';
import { ExportSvg } from 'Icons/ExportComponent';
import { ImportSvg } from 'Icons/ImportComponent';
import { AddUserSvg } from 'Icons/addUserComponent';
import type { InputRef } from 'antd';
import { Dropdown, Space, Spin } from 'antd';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import AddEmployeeModal from 'components/Employee/AddEmployeeModal';
import EmployeeGrid from 'components/Employee/EmployeeGrid';
import { useModalContext } from 'components/Modal/ModalProvider';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { useSwitchContext } from 'components/UIComponents/SwitchComponent/SwitchContextProvider';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { EmployeeServices } from 'services';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';
import { TableColleagues } from './Table';
import { titlesKeysofColumnsColleagues } from './Table/utils';
import './colleaguesStyles.less';
// IN-283
import Upload from 'antd/es/upload/Upload';
import LayoutPageMobileComponent from 'components/LayoutPageWithTitle.tsx/LayoutPageMobileComponent';
import ModalComponent from 'components/Modal';
import {
  GridViewPagePlaceholder,
  TableComponentPlaceholder
} from 'components/Placeholders';
import CustomSwitch from 'components/Switch';
import GridView from 'components/UIViews/GridView';
import GridViewStyles from 'components/UIViews/GridView/GridView.module.less';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'services/providers/AuthProvider';
import { downloadAndParseFile } from 'utils/downloadAndParseFileFromBlob';
import normalizeString from 'utils/normalizeString';
import {
  notifyWithError,
  notifyWithErrorOnMaxSizeUploadFile,
  notifyWithSuccessfulDataUpdated
} from 'utils/notificationsUtils';
import _sort from 'utils/sorter';
import '../LayoutPageWithTitle.tsx/TopRoleModal/TopRoleModal.less';
import { ModalContextProvider } from '../Modal/ModalProvider';
import { ToolbarSearchOptions } from './types';
import { FailedRowError } from './utils';

const AllUsersList = () => {
  const { isMobile } = useMobileContext();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { isToggleChecked, toggleSwitch } = useSwitchContext();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] =
    useState<boolean>(false);
  const { theme } = useThemeContext();
  const { toggleModal, isTopRoleModalOpen, setIsTopRoleModalOpen } =
    useModalContext();
  const ButtonCustom = BaseButton(ButtonSimple);
  const intl = useIntl();
  const { locale } = useIntlContext();
  const { user } = useAuth();
  const defaultPresortedKey = 'fullName';
  const isAdminUserRole = allowPermision(user.role, adminRoles);
  const params = {
    searchText,
    setSearchText,
    searchedColumn,
    setSearchedColumn,
    searchInput,
    theme,
    ButtonCustom,
    isAdminUserRole
  };

  const ButtonComponent = BaseButton(ButtonSimple);
  const dispatch = useDispatch();

  const triggerMethodUpdate = () => {
    dispatch(setMethodUpdate(true));
  };
  const { data, status } = useApiRequestHook<IEmployee[]>(
    EmployeeServices.getAll,
    'true',
    [],
    {}
  );

  const localStorageListInventaory = window.localStorage.getItem(
    'displayColleaguesAsGrid'
  );
  const [dataSource, setDataSource] = useState([]);
  const [dataCurrentAfterFilterSort, setDataCurrentFilterSort] = useState<
    any[]
  >([]);

  data.forEach(item => {
    item.fullName = `${item.lastName} ${item.firstName}`;
  });

  // whenever we get fetch employees data, presort them by last name asc
  const ascSortedDataByDefaultKey = useMemo(
    () =>
      [...data].sort((a, b) =>
        _sort(a[defaultPresortedKey], b[defaultPresortedKey], 'string')
      ),
    [data]
  );

  const { deviceHeight } = useMobileContext();

  useEffect(() => {
    if (localStorageListInventaory != null) {
      if (
        (isToggleChecked && localStorageListInventaory === 'List') ||
        (!isToggleChecked && localStorageListInventaory === 'Cards')
      ) {
        toggleSwitch();
        setDataCurrentFilterSort(ascSortedDataByDefaultKey);
      }
    }
    //eslint-disable-next-line
  }, [localStorageListInventaory]);

  useEffect(() => {
    setDataSource(ascSortedDataByDefaultKey);
    setDataCurrentFilterSort(ascSortedDataByDefaultKey);
  }, [ascSortedDataByDefaultKey, locale]);

  const handleSearchToolbar = (value, columnSearch) => {
    setSearchText(value);
    const currValue = value;

    const colToBeSearched =
      titlesKeysofColumnsColleagues.filter(
        item =>
          intl.formatMessage({ id: item.title.props.id ?? null }) ===
          columnSearch
      )[0] || null;
    const currKey = colToBeSearched?.dataIndex ?? '';
    setSearchedColumn(currKey);

    const filteredData = data.filter(entry => {
      if (currValue === '') {
        // when current value on search is initial is empty
        return entry;
      }
      if (entry[currKey] === null || entry[currKey] === undefined) {
        // when values from api is empty | null | undefined
        return false;
      }

      if (typeof entry[currKey] === 'object') {
        return Object.values(entry[currKey]).filter((e: string) =>
          normalizeString(e).startsWith(normalizeString(currValue))
        ).length;
      } else if (currKey === 'position') {
        return normalizeString(
          intl.formatMessage({ id: entry[currKey] })
        ).includes(normalizeString(currValue));
      } else {
        return normalizeString(entry[currKey]).includes(
          normalizeString(currValue)
        );
      }
    });

    setDataSource(
      [...filteredData].sort((a, b) =>
        _sort(
          //if the key is an object, (f.e employee teams is {1187: "Exberry", 1192: "IntraNet"}, we get values and parse as string)
          (a[defaultPresortedKey] as unknown) instanceof Object
            ? [...Object.values(a[defaultPresortedKey])].toString()
            : a[defaultPresortedKey],
          (b[defaultPresortedKey] as unknown) instanceof Object
            ? [...Object.values(b[defaultPresortedKey])].toString()
            : b[defaultPresortedKey],
          'string'
        )
      )
    ); //sort data based on locale
  };

  interface ButtonsType {
    show?: boolean;
    className?: string;
    type?: string;
    icon?: JSX.Element;
    iconSrc?: any;
    text: ReactNode;
    action?: any;
    isDisableButton?: boolean;
    isUploadButton?: boolean;
    isMultipleUpload?: boolean;
    uploadAccept?: string;
    uploadMaxCount?: number;
    uploadInProgress?: boolean;
    uploadShowList?: boolean;
    beforeUploadAction?: any;
    isDropdown?: boolean;
    dropdownOverlay?: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >;
  }

  const importButtonsProps: ButtonsType = {
    icon: uploadInProgress ? (
      <Spin size="small" className="upload-spinner-icon" />
    ) : (
      <ImportSvg />
    ),
    text: (
      <span>
        <FormattedMessage id="import" />
      </span>
    ),
    isUploadButton: true,
    isMultipleUpload: false,
    isDisableButton: uploadInProgress,
    uploadAccept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    uploadMaxCount: 1,
    uploadShowList: false,
    beforeUploadAction: async uploadedFile => {
      try {
        setUploadInProgress(true);
        // check for file size of the uploaded file to be less than 5mb
        if (uploadedFile.size && uploadedFile.size >= 5 * 1000000) {
          notifyWithErrorOnMaxSizeUploadFile(5);
          throw new Error('File exceeds maximum file size');
        }

        const formData = new FormData();
        formData.append('file', uploadedFile);

        EmployeeServices.importFromFile(formData).then(res => {
          if (res.error || res?.data?.failedRowsCount > 0) {
            const failedRowMessages = res?.data?.failedRows.map(
              (failedRow, index) => (
                <FailedRowError {...failedRow} key={`${failedRow}-${index}`} />
              )
            );
            notifyWithError({
              message: <FormattedMessage id="ValidationFailedErrorMessage" />,
              description: <>{failedRowMessages}</>
            });
          } else if (
            res.data === undefined ||
            res.data.successRowsCount === 0
          ) {
            notifyWithError({
              message: <FormattedMessage id="NoImportFile" />,
              description: (
                <FormattedMessage id="NoImportFileErrorDescription" />
              )
            });
          } else {
            notifyWithSuccessfulDataUpdated(
              '',
              <FormattedMessage
                id="ImportSuccess"
                values={{
                  successfulRowsCount: res.data.successRowsCount
                }}
              />
            );
          }
        });
      } finally {
        setTimeout(() => {
          setUploadInProgress(false);
        }, 1000);
        triggerMethodUpdate();
        return false;
      }
    },
    show: allowPermision(user.role, adminRoles)
  };

  const exportButtonProps: ButtonsType = {
    icon: <ExportSvg />,
    text: (
      <span>
        <FormattedMessage id="exportTemplate" />
      </span>
    ),
    action: () => {
      downloadAndParseFile(EmployeeServices.getImportTemplateFile(null));
    },
    show: allowPermision(user.role, adminRoles)
  };

  const topRoleButtons: ButtonsType[] = [
    {
      icon: <AddUserSvg />,
      text: (
        <span>
          <FormattedMessage id="addUser" />
        </span>
      ),
      action: () => {
        setIsAddEmployeeModalOpen(true);
        toggleModal();
        setIsTopRoleModalOpen(false);
      },
      show: allowPermision(user.role, adminRoles)
    },
    {
      ...exportButtonProps
    },
    { ...importButtonsProps }
  ];

  const DISPLAY_MODE_KEY = 'displayColleaguesAsGrid';

  const getDisplayMode = () => {
    const mode = window.localStorage.getItem(DISPLAY_MODE_KEY);
    return mode ?? 'List';
  };

  const toggleDisplayMode = () => {
    const currentMode = getDisplayMode();
    const newMode = currentMode === 'List' ? 'Cards' : 'List';
    window.localStorage.setItem(DISPLAY_MODE_KEY, newMode);

    toggleSwitch();
  };

  const userRole = getCurrentUserRole(user);

  return (
    <>
      {isAddEmployeeModalOpen && (
        <AddEmployeeModal submitBtnText={<FormattedMessage id="add" />} />
      )}
      <SearchToolbarComponent
        initialSelected={intl.formatMessage({
          id: `colleagues_toolbarFilter_${ToolbarSearchOptions.FullName}`
        })}
        displaySearchIcon={false}
        searchOptions={Object.keys(ToolbarSearchOptions).map(value => ({
          value: value as ToolbarSearchOptions,
          label: intl.formatMessage({
            id: `colleagues_toolbarFilter_${value}`
          })
        }))}
        buttons={[
          {
            icon: <AddUserSvg />,
            text: (
              <span>
                <FormattedMessage id="addUser" />
              </span>
            ),
            action: () => {
              setIsAddEmployeeModalOpen(true);
              toggleModal();
            },
            show: allowPermision(user.role, adminRoles)
          },
          { ...importButtonsProps },
          {
            ...exportButtonProps
          }
        ]}
        displaySearchInput={true}
        onChangeInputSearch={handleSearchToolbar}
      />
      <br className='break-line' />
      <GridView
        style={{
          height: `calc(${deviceHeight}px - 186px)`
        }}
        header={
          <div className={GridViewStyles['header']}>
            <h2 className={GridViewStyles['title']}>
              <FormattedMessage id="table_colleaguesTitle_Colleagues" /> (
              {isToggleChecked ? dataSource.length : totalRows})
            </h2>
            {!isMobile || !allowPermision(userRole, adminRoles) ? (
              <CustomSwitch
                values={[
                  {
                    id: 0,
                    value: 'List',
                    icon: <ListViewComponent />
                  },
                  {
                    id: 1,
                    value: 'Cards',
                    icon: <CardViewComponent />
                  }
                ]}
                defaultValue={getDisplayMode()}
                onChange={toggleDisplayMode}
              ></CustomSwitch>
            ) : null}
            {isMobile && allowPermision(userRole, adminRoles) ? (
              <LayoutPageMobileComponent></LayoutPageMobileComponent>
            ) : null}
          </div>
        }
        body={
          <div style={{
            height: !isMobile ? `calc(${deviceHeight}px - 292px)` : `calc(${deviceHeight}px - 296px)`
          }}>
            {!isToggleChecked && (
              <ComponentWithStatus
                status={status}
                Placeholder={
                  <TableComponentPlaceholder count={isMobile ? 2 : 8} />
                }
              >
                <TableColleagues
                  status={status}
                  setDataCurrentFilterSort={setDataCurrentFilterSort}
                  setTotalRows={setTotalRows}
                  role={user.role}
                  data={dataSource}
                  params={params}
                  isAnotherModalOpen={isAddEmployeeModalOpen}
                  setIsAnotherModalOpen={setIsAddEmployeeModalOpen}
                />
              </ComponentWithStatus>
            )}
            {isToggleChecked && (
              <ComponentWithStatus
                status={status}
                Placeholder={<GridViewPagePlaceholder />}
              >
                <EmployeeGrid
                  data={dataSource}
                  isAnotherModalOpen={isAddEmployeeModalOpen}
                  setIsAnotherModalOpen={setIsAddEmployeeModalOpen}
                />
              </ComponentWithStatus>
            )}
          </div>
        }
      ></GridView>

      <ModalContextProvider>
        <ModalComponent
          className="top-role-popup"
          isOpen={isTopRoleModalOpen}
          setIsOpen={setIsTopRoleModalOpen}
          footerButtons={false}
          displayFooterCloseButton={false}
          displayFooterSubmitButton={false}
          maskClosable={true}
        >
          <section className="top-role-popup-buttons">
            <button style={{ background: 'none', border: 'none' }}>
              <CustomSwitch
                values={[
                  {
                    id: 0,
                    value: 'List',
                    icon: <ListViewComponent />
                  },
                  {
                    id: 1,
                    value: 'Cards',
                    icon: <CardViewComponent />
                  }
                ]}
                defaultValue={getDisplayMode()}
                onChange={() => {
                  toggleDisplayMode();
                  setIsTopRoleModalOpen(false);
                  setDataCurrentFilterSort(dataSource);
                }}
              />
            </button>

            {topRoleButtons.map((button, index) =>
              button.show ? (
                button.isUploadButton ? (
                  <Upload
                    key="upload"
                    multiple={button.isMultipleUpload ?? false}
                    beforeUpload={button.beforeUploadAction}
                    accept={button.uploadAccept}
                    maxCount={button.uploadMaxCount}
                    showUploadList={button.uploadShowList}
                  >
                    <ButtonComponent
                      className={`text-bold-normal btn-default-custom ${theme}`}
                      icon={button.icon}
                    >
                      {button.text}
                    </ButtonComponent>
                    {button.uploadInProgress && (
                      <Spin size="small" className={'uploader-spinner'} />
                    )}
                  </Upload>
                ) : button.isDropdown ? (
                  <Dropdown
                    overlayStyle={{ fontWeight: 500 }}
                    overlay={button.dropdownOverlay}
                    trigger={['click']}
                    key={index}
                  >
                    <Space>
                      <ButtonComponent
                        className={`text-bold-normal btn-default-custom ${theme}`}
                        key={index}
                      >
                        {button.text}
                      </ButtonComponent>
                    </Space>
                  </Dropdown>
                ) : (
                  <ButtonComponent
                    key={`button-component-${index}`}
                    type={button.type}
                    icon={
                      button.icon ? (
                        button.icon
                      ) : button.iconSrc ? (
                        <embed src={button.iconSrc} />
                      ) : null
                    }
                    className={
                      button.className
                        ? button.className
                        : `text-bold-normal btn-default-custom ${theme}`
                    }
                    style={{ margin: '0' }}
                    onClick={button.action}
                  >
                    {button.text}
                  </ButtonComponent>
                )
              ) : null
            )}
          </section>
        </ModalComponent>
      </ModalContextProvider>
    </>
  );
};

export default AllUsersList;
