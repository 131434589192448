import TableComponent from 'components/Table';
import { useOnMethodUpdate } from 'hooks/useOnMethodUpdate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllTickets } from 'redux/slices/ticketingSlice/actions';
import { RootState, useAppDispatch } from 'redux/store';
import {
  AssignmentTypes,
  TicketPriority,
  TicketStatus,
  TicketType
} from '../types';
import styles from './ticketingListView.module.less';
import { useTicketTableColumns } from './useTicketTableColumns';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { isMobile } from 'react-device-detect';
export default function TicketingListView() {
  const ticketTableColumnsArray = useTicketTableColumns();
  const { allTickets: data, totalNumberOfTickets } = useSelector(
    (state: RootState) => state.ticketing
  );

  const { deviceHeight, isMobile, deviceOS } = useMobileContext();
  const dispatch = useAppDispatch();
  const { ticketingFilters } = useSelector((state: RootState) => state);
  const [tablePage, setTablePage] = useState(1);
  const [currentNumberOfItemsPerPage, setCurrentNumberOfItemsPerPage] =
    useState(
      JSON.parse(localStorage.getItem('tablePagination'))?.['ticketingTable'] ||
        20
    );

  //reset page on filters change
  useEffect(() => {
    setTablePage(1);
    setCurrentNumberOfItemsPerPage(
      JSON.parse(localStorage.getItem('tablePagination'))?.['ticketingTable'] ||
        20
    );
  }, [
    ticketingFilters.assignmentType,
    ticketingFilters.statusTypes,
    ticketingFilters.urgencyTypes,
    ticketingFilters.categoriesTypes
  ]);

  useOnMethodUpdate(
    getAllTickets({
      pagination: {
        resultSize: currentNumberOfItemsPerPage,
        pageNumber: 1
      }
    })
  );

  return (
    <div className={styles.containerListViewTable}>
      <TableComponent
        showSorterTooltip={false}
        tableSize="large"
        style={{
          height: `calc(${deviceHeight}px - 22em)`,
          minHeight: `calc(${deviceHeight}px - 22em)`
        }}
        scroll={{
          x: 'calc(100vw - 213px)',
          y: isMobile
            ? deviceOS === 'IOS'
              ? 'calc(100vh - 30.8em)'
              : 'calc(100vh - 28.8em)'
            : 'calc(100vh - 24.5em)'
        }}
        rowKey={ticket => ticket.ticketId}
        className={`ticketingTable ${styles.listViewTable}`}
        rowClassName={'rowHover'}
        dataSource={data}
        data={data}
        page={tablePage}
        setPage={setTablePage}
        pageSize={currentNumberOfItemsPerPage}
        onPaginationChange={(currentPage, pageSize) => {
          dispatch(
            getAllTickets({
              filterBy: {
                priorities: ticketingFilters?.urgencyTypes as TicketPriority[],
                assignTypes:
                  ticketingFilters?.assignmentType as AssignmentTypes[],
                statuses: ticketingFilters?.statusTypes as TicketStatus[],
                types: ticketingFilters?.categoriesTypes as TicketType[]
              },
              pagination: {
                resultSize: pageSize,
                pageNumber: currentPage
              }
            })
          );
        }}
        onNrItemsPerPageChange={size => {
          size !== currentNumberOfItemsPerPage &&
            setCurrentNumberOfItemsPerPage(size);
        }}
        totalItems={totalNumberOfTickets}
        columns={() => ticketTableColumnsArray}
      />
    </div>
  );
}
