import { Card, Typography, Row } from 'antd';
import { FC } from 'react';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import { useCompanySettings } from 'settings';
import { getCurrentEmployeePosition } from '../EmployeeForm/utils';
import { FormattedMessage } from 'react-intl';
import './EmployeeProfileCard.less';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import TextPhoneInput from 'components/UIComponents/InputPhoneComponent/TextPhoneInput';

type EmployeeInfoFieldsProps = { data: IEmployee };
const { Text, Title } = Typography;
const { Meta } = Card;
const styles = {
  employeeMetaRow: { width: '100%', padding: '0.1em' }
};

const EmployeeInfoFieldsMobile: FC<EmployeeInfoFieldsProps> = ({ data }) => {
  const { isMobile } = useMobileContext();
  const { office } = useCompanySettings();
  const teamsNamesArray =
    data.teams && Object.keys(data.teams).length > 0
      ? Object.entries(data.teams).map(([teamId, teamName]) => teamName ?? '')
      : [];

  const jobFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_position" />
  );
  const teamsFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Teams" />
  );
  const officeFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Office" />
  );
  const emailFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Email" />
  );
  const phoneFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Phone" />
  );
  const notYetInTeamText = (
    <FormattedMessage id="label_profileCardemployee_Not_yet_in_team" />
  );
  const phoneNotProvidedText = (
    <FormattedMessage id="label_profileCardemployee_Not_phone_number_provided" />
  );
  const employeeInfoMetas = [
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {jobFieldTitle}
        </Text>
      ),
      infoText: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {getCurrentEmployeePosition(data.position)}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {teamsFieldTitle}
        </Text>
      ),
      infoText: !teamsNamesArray.length ? (
        <Text className="text-medium-bigger-extra">{notYetInTeamText}</Text>
      ) : teamsNamesArray.length === 1 ? (
        <Text className="text-bold-bigger-extra">
          {teamsNamesArray.join('')}
        </Text>
      ) : (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {teamsNamesArray.join(', ')}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {officeFieldTitle}
        </Text>
      ),
      // FOR FUTURE DEVELOPMENT
      infoText: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {office}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {emailFieldTitle}
        </Text>
      ),
      infoText: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {data.email}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          {phoneFieldTitle}
        </Text>
      ),
      infoText: (
        <Text className={`${!isMobile ? `text-bold-bigger-extra` : ''}`}>
          <TextPhoneInput crudPhone={data?.phoneNumber} />
        </Text>
      )
    }
  ];
  const lastFirstName = [
    {
      infoText: (
        <Title
          className="color-inherit name-lastname"
          style={{ textAlign: 'center' }}
          level={1}
        >
          {data.lastName + ' ' + data.firstName}
        </Title>
      )
    }
  ];

  return (
    <>
      <Row justify="center" style={{ paddingBottom: '5px' }}>
        {lastFirstName.map(({ infoText }, index) => (
          <div key={`employee-name-${index}`}>{infoText}</div>
        ))}
      </Row>
      <br />
      <div className={'employee-info'}>
        {employeeInfoMetas.map(({ title, infoText }, index) => (
          <Meta
            key={`employee-info-card-meta-${index}`}
            title={title}
            style={{ ...styles.employeeMetaRow }}
            className="employee-info-row-mobile profile-card-info-row"
            description={infoText ? <>{infoText}</> : <p>NaN</p>}
          />
        ))}
      </div>
    </>
  );
};

export default EmployeeInfoFieldsMobile;
