import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { Dispatch, SetStateAction } from 'react';
import './index.less';
import { PrevisiousYearsIcon } from 'Icons/TimeManagementWidget/previsousYears';
import { FormattedMessage } from 'react-intl';

export type IPreviousYears = {
  requestType: string;
  showPreviousYears: boolean;
  setShowPreviousYears: Dispatch<SetStateAction<boolean>>;
};

export const PrevisiousYears = ({ previousYearsParams }) => {
  const ButtonCustom = BaseButton(ButtonSimple);
  const { theme } = useThemeContext();

  return (
    previousYearsParams &&
    previousYearsParams.requestType === 'personal' && (
      <>
        <br />
        <div className="center-column-flex">
          <ButtonCustom
            className={`text-bold-normal btn-default-custom ${theme}`}
            key="previousYearsRequestTimeOffButton"
            icon={
              previousYearsParams.showPreviousYears ? (
                <PrevisiousYearsIcon />
              ) : (
                <PrevisiousYearsIcon />
              )
            }
            onClick={() => {
              previousYearsParams.setShowPreviousYears(
                !previousYearsParams.showPreviousYears
              );
            }}
          >
            <span>
              <FormattedMessage id="previousYears" />
            </span>
          </ButtonCustom>
        </div>
      </>
    )
  );
};
