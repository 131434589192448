import { useDispatch, useSelector } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import {
  setAssignmentType,
  setCategoriesTypes,
  setStatusTypes,
  setUrgencyTypes
} from 'redux/slices/ticketingFilters';
import { getAllTickets } from 'redux/slices/ticketingSlice/actions';
import { TicketPriority, TicketStatus, TicketType } from '../types';
import { AppDispatch, RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';

export const useFiltersChangeEvents = () => {
  const { ticketingFilters } = useSelector((state: RootState) => state);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const filterBy = {
    priorities: ticketingFilters?.urgencyTypes as TicketPriority[],
    assignTypes: ticketingFilters?.assignmentType as any,
    statuses: ticketingFilters?.statusTypes as TicketStatus[],
    types: ticketingFilters?.categoriesTypes as TicketType[]
  };

  const pagination = {
    resultSize:
      location.pathname === '/ticketing/viewTicket'
        ? 10
        : JSON.parse(localStorage.getItem('tablePagination'))?.[
            'ticketingTable'
          ] || 20,
    pageNumber: 1
  };

  const onCategoriesChanged = value => {
    dispatch(setCategoriesTypes(value));
    dispatch(
      getAllTickets({
        filterBy: {
          ...filterBy,
          types: value as TicketType[]
        },
        pagination
      })
    );
    dispatch(setMethodUpdate(true));
  };

  const onUrgencyChanged = value => {
    dispatch(setUrgencyTypes(value));
    dispatch(
      getAllTickets({
        filterBy: {
          ...filterBy,
          priorities: value as TicketPriority[]
        },
        pagination
      })
    );
    dispatch(setMethodUpdate(true));
  };

  const onStatusChanged = value => {
    dispatch(setStatusTypes(value));
    dispatch(
      getAllTickets({
        filterBy: {
          ...filterBy,
          statuses: value as TicketStatus[]
        },
        pagination
      })
    );
    dispatch(setMethodUpdate(true));
  };

  const onAssigneeChanged = value => {
    dispatch(setAssignmentType(value));
    dispatch(
      getAllTickets({
        filterBy: {
          ...filterBy,
          assignTypes: value as any
        },
        pagination
      })
    );
    dispatch(setMethodUpdate(true));
  };

  return {
    onAssigneeChanged,
    onCategoriesChanged,
    onStatusChanged,
    onUrgencyChanged
  };
};
