import { ArrowLeft } from 'Icons/Documents/arrowLeft';
import { ArrowRight } from 'Icons/Documents/arrowRight';
import './documentsModalHeaderStyle.less';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

type DocumentsModalHeaderProps = {
  documentType: string;
  getNextDocumentType: () => any;
  getPrevDocumentType: () => any;
};

const DocumentsModalHeader: FC<DocumentsModalHeaderProps> = ({
  documentType,
  getNextDocumentType,
  getPrevDocumentType,
}) => {
  const { theme } = useThemeContext();
  return (
    <div className={`DocumentsModalHeader `}>
      <div className="DocumentsModalHeader-wrapper">
        <ArrowLeft
          className={`navigation-arrow ${theme}`}
          onClick={getPrevDocumentType}
        />
        <div className="title">{<FormattedMessage id={documentType} />}</div>
        <ArrowRight
          className={`navigation-arrow ${theme}`}
          onClick={getNextDocumentType}
        />
      </div>
    </div>
  );
};

export default DocumentsModalHeader;
