import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { HeaderMenuContext } from 'components/Layout/Header/HeaderMenuContext';
import React from 'react';
import { CustomMenuItemType } from '../CustomMenuItemType';
import '../Menu.less';
import Dropdown from '../components/Dropdown';
import { DropdownContext } from '../components/Dropdown/DropdownContext';
import Menu from '../components/Menu';

interface MobileMenu {
  theme: 'dark' | 'light';
  items: CustomMenuItemType[];
  currentLocation: string;
}

const MobileMenu = ({ theme, items = [], currentLocation }: MobileMenu) => {
  const { dropdownKey, setDropdownKey } = React.useContext(DropdownContext);
  const { isOpen, handleIsOpen } = React.useContext(HeaderMenuContext);
  const { deviceWidth, deviceHeight } = useMobileContext();

  React.useEffect(() => {
    if (dropdownKey) {
      setDropdownKey(null);
    }
  }, [currentLocation, deviceWidth, deviceHeight]);

  return (
    <>
      {isOpen && (
        <>
          <div
            role="button"
            tabIndex={-1}
            className="side-navigation-overlay"
            onClick={() => {
              setDropdownKey(null);
              handleIsOpen();
            }}
          ></div>

          <div
            id="side-navigation-bar--small"
            aria-hidden={!isOpen}
            className={`side-navigation-bar--small ${theme}`}
          >
            <Menu>
              <Splide
                options={{
                  arrows: false,
                  autoHeight: true,
                  direction: 'ttb',
                  drag: true,
                  gap: '8px',
                  height: 'auto',
                  pagination: false,
                  perMove: 1,
                  // slideFocus: true,
                  trimSpace: true
                  // updateOnMove: true
                }}
              >
                {items.map((item, index) => (
                  <SplideSlide key={index}>
                    {Array.isArray(item?.['children']) ? (
                      <Dropdown
                        title={item?.['label']}
                        isDropdownOpen={dropdownKey === item?.key}
                        preffixIcon={item?.icon}
                        key={item?.key}
                        className={
                          currentLocation.includes(item?.key?.toString())
                            ? 'nav__item nav__item--active'
                            : 'nav__item'
                        }
                        onClick={() =>
                          setDropdownKey(
                            dropdownKey === item?.key ? null : String(item?.key)
                          )
                        }
                      >
                        {item['children'].map(
                          (child, index) =>
                            ![undefined, null].includes(child) && (
                              <li
                                key={`${child?.key}-${index}`}
                                className={
                                  currentLocation === child?.key
                                    ? 'dropdown__item dropdown__item--active'
                                    : 'dropdown__item'
                                }
                              >
                                {child?.label}
                              </li>
                            )
                        )}
                      </Dropdown>
                    ) : (
                      <div
                        className={
                          currentLocation === item?.key?.toString()
                            ? 'nav__item nav__item--active'
                            : 'nav__item'
                        }
                      >
                        {item?.icon}
                        {item?.['label']}
                      </div>
                    )}
                  </SplideSlide>
                ))}
              </Splide>
            </Menu>
          </div>
        </>
      )}
    </>
  );
};

export default MobileMenu;
