export const SettingsSvg = () => {
  return (
    <span
      role="img"
      aria-label="settings"
      className="anticon anticon-settings"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.59998 3.19998L7.99998 3.19998M1.59998 7.99998H7.99998M7.99998 7.99998V9.59998M7.99998 7.99998V6.39998M1.59998 12.8H4.79998M7.99998 12.8L14.4 12.8M11.2 7.99998H14.4M11.2 3.19998L14.4 3.19998M11.2 3.19998V4.79998M11.2 3.19998V1.59998M5.19998 14.4V11.2"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
