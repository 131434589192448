export const allowPermision = (role, allowedRoles) =>
  allowedRoles.includes(role);

export const userRoles = {
  Admin: 'Admin',
  Accountant: 'Accountant',
  HR: 'HR',
  TL: 'TL',
  Employee: 'Employee',
  Intern: 'Intern',
  External: 'External'
};

export const rolesAllow = [
  userRoles.Admin,
  'User',
  userRoles.Accountant,
  userRoles.HR
];
export const adminRoles = [userRoles.Admin];
export const getCurrentUserRole = user => {
  return user && Object.keys(user).length ? user['role'] : 'User';
};
