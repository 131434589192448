export const TicketingSVG = () => {
  return (
    <span
      role="img"
      aria-label="dashboard"
      className="anticon anticon-dashboard"
    >
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.60006 9.09349V8.96427M9.59972 7.09157V6.96235M9.59972 5.09815V4.96893M3.34123 1.30652H8.00075C8.02722 2.24989 8.80027 3.00652 9.75005 3.00652C10.6998 3.00652 11.4729 2.24989 11.4993 1.30652H12.6589C14.0623 1.30652 15.2 2.44424 15.2 3.8477V10.1523C15.2 11.5558 14.0623 12.6935 12.6589 12.6935H11.4993C11.4729 11.7501 10.6998 10.9935 9.75005 10.9935C8.80027 10.9935 8.02722 11.7501 8.00075 12.6935H3.34123C1.93778 12.6935 0.800058 11.5558 0.800058 10.1523L0.800049 3.84769C0.800049 2.44424 1.93777 1.30652 3.34123 1.30652Z"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
