import { FeatureToggleContext } from '../FeatureToggleContext';
import { ComponentsFlags } from './ComponentsSettings';

type Props = {
  children: any;
  enabledFeatures: string[];
};

const useFeatureToggleContext = () => {
  const enabledFeatures = ComponentsFlags;
  return { enabledFeatures };
};

export const FeatureTogglePovider = ({ children}: Props) => {
  return (
    <FeatureToggleContext.Provider value={useFeatureToggleContext()}>
      {children}
    </FeatureToggleContext.Provider>
  );
};
