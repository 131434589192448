import {
  EventTypes,
  EventTimeFormat,
  EventDefinitions
} from 'pages/Calendar/types';
import './eventItem.less';
import dayjs from 'dayjs';
import { Collapse, Typography } from 'antd';
import { EventItemCollapsible, EventItemNonCollapsible } from './types';
import { useCalendarModal } from 'pages/Calendar/CalendarModalContext';
import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { convertToUTC } from 'components/DateParser';
import { isTimeBasedRequest } from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm/utils';
import {
  SafeFormattedMessage,
  useTranslationExistenceChecker
} from 'components/UIComponents/SafeFormattedMessage';
const { Panel } = Collapse;
/**
 * Event Item Component.
 *
 * @param {EventUpdateResponseDto} props.eventObject - Contains the event and all its properties
 * @param {boolean} [props.collapsible=false] - Whether the component is collapsible or not (has group of inner events of same type).
 * @param {EventItemProps[]} [props.eventsList] - List of events to be displayed within the collapsible item if props.collapsible = true.
 * @returns {JSX.Element} recursively EventItem components if props.collapsible === true and props.eventList > 1, else returns singular item
 */
export const EventItem: React.FC<
  EventItemCollapsible | EventItemNonCollapsible
> = ({ collapsible = false, eventObject, eventsList = [] }) => {
  const {
    startDate,
    endDate,
    eventName,
    eventType,
    timeOffType,
    details,
    employeeName,
    timeFormat,
    eventId
  } = eventObject;
  const { openFormCalendarModal } = useCalendarModal();
  const formattedStartDate = dayjs(startDate).format('DD/MM/YYYY');
  const formattedEndDate = dayjs(endDate).format('DD/MM/YYYY');
  const formattedStartHour = dayjs(startDate).format('HH:mm');
  const formattedEndHour = dayjs(endDate).format('HH:mm');

  const durationInMinutes = dayjs(endDate).diff(dayjs(startDate), 'minute');
  const durationHours = Math.floor(durationInMinutes / 60);
  const durationMinutes = durationInMinutes % 60;

  const eventItemClickHandler = () => {
    //prevent opening modal if the timeFormat doesn't match the existing ones (it's mostly relevant for system events, birthdayss)
    // !isTimeOffEvent  prevents opening modal for time off events
    EventTimeFormat[timeFormat] &&
      !isTimeOffEvent &&
      openFormCalendarModal('view', eventObject, convertToUTC(startDate));
  };
  const isHolidayTimeOffEvents = [EventTypes.Holiday].includes(
    EventTypes[eventType]
  );

  const isTimeOffEvent = [EventTypes.TimeOffEvent].includes(
    EventTypes[eventType]
  );

  const isAllDayEvents = ['None', EventTimeFormat.AllDay].includes(timeFormat);
  const isTimeRangeEvent = ['None', EventTimeFormat.TimeRange].includes(
    timeFormat
  );

  const isTeamCompanyEvents = [
    EventTypes.TeamEvent,
    EventTypes.CompanyEvent
  ].includes(EventTypes[eventType]);

  const durationDays =
    dayjs(eventObject?.endDate).diff(dayjs(eventObject?.startDate), 'days') + 1;

  const eventItemTextContent = (() => {
    if (isTimeOffEvent) {
      if (isTimeBasedRequest(eventObject?.timeOffType)) {
        return `${formattedStartHour} - ${formattedEndHour}, ${durationHours}h ${Math.abs(
          durationMinutes
        )}m`;
      } else {
        return durationDays > 1
          ? `${formattedStartDate} - ${formattedEndDate}`
          : `${formattedStartDate}`;
      }
    }

    if (isHolidayTimeOffEvents)
      return durationDays > 1
        ? `${formattedStartDate} - ${formattedEndDate}`
        : `${formattedStartDate}`;

    if (isTimeRangeEvent)
      return `${formattedStartHour} - ${formattedEndHour}, ${durationHours}h ${Math.abs(
        durationMinutes
      )}m`;

    if (isAllDayEvents) return `${formattedStartDate}`;

    if (isTeamCompanyEvents)
      return `${formattedStartHour}` + (details ? '-' + details : '');

    return `${formattedStartDate} ${eventName}`;
  })();
  const translationChecker = useTranslationExistenceChecker();

  return collapsible ? (
    <Collapse
      ghost
      bordered={false}
      defaultActiveKey={['1']}
      expandIconPosition={'end'}
      expandIcon={({ isActive }) => (
        <ArrowLeft style={{ transform: `rotate(${isActive ? -90 : 0}deg)` }} />
      )}
      className="eventsList-collapse site-collapse-custom-collapse"
    >
      <Panel
        header={
          <Typography.Title level={5} style={{ lineHeight: '20px' }}>
            {eventsList.length + ' '}
            <SafeFormattedMessage id={eventType + 's'} />{' '}
          </Typography.Title>
        }
        key={eventType}
        className="eventList-panel"
        style={{
          color: EventDefinitions[eventType].badgeColor //handles the border badge color
        }}
      >
        {eventsList?.map((event, index) => (
          <EventItem
            collapsible={false}
            key={'sub-' + eventName + index}
            eventObject={event}
          />
        ))}
      </Panel>
    </Collapse>
  ) : (
    <div
      className="eventItem"
      style={{
        color: EventDefinitions[eventType].badgeColor,
        pointerEvents: eventObject.isExtendedViewAllowed && eventObject.eventType !== 'TimeOffEvent' ? null : 'none'
      }} //handles the border badge color
      onClick={eventItemClickHandler}
    >
      <div className="eventItem-body">
        <Typography.Title level={5} style={{ lineHeight: '20px' }}>
          {eventName?.split('_').includes('BirthDate') ? (
            <SafeFormattedMessage
              id="BirthDate"
              values={{
                firstName: eventName.split('_')[0],
                lastName: eventName.split('_')[1]
              }}
            />
          ) : (
            <>
              <SafeFormattedMessage
                id={
                  eventType === EventTypes.TimeOffEvent
                    ? timeOffType
                    : translationChecker.doesTranslationExist({ id: eventName })
                }
              />

            </>
          )}
          {eventType === EventTypes.TimeOffEvent ? ', ' + employeeName : null}
        </Typography.Title>
        <Typography style={{ lineHeight: '16px' }}>
          {eventItemTextContent}
        </Typography>
      </div>
    </div>
  );
};
