import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { EditIcon } from 'Icons/Colleagues/EditIcon';
import avatar from 'assets/avatar.png';
import { useColleaguesContext } from 'components/AllUsersList/ColleaguesModalContext';
import { getCurrentEmployeePosition } from 'components/Employee/EmployeeForm/utils';
import EmployeeGridStyles from 'components/Employee/EmployeeGrid/EmployeeGrid.module.less';
import ModalComponent from 'components/Modal';
import { useModalContext } from 'components/Modal/ModalProvider';
import GridCard from 'components/UIComponents/GridCard';
import GridCardStyles from 'components/UIComponents/GridCard/GridCard.module.less';
import TextPhoneInput from 'components/UIComponents/InputPhoneComponent/TextPhoneInput';
import GridLayout from 'components/UILayout/GridLayout';
import EditProfilePage from 'pages/Profile/EditProfilePage';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'services/providers/AuthProvider';
import { IEmployee, IRole } from 'services/providers/EmployeeProvider/types';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  rolesAllow
} from 'utils/userManagement';

interface ListGroupProps {
  label: React.ReactElement;
  data?: React.ReactNode | null;
  hideOnMobile: boolean | null;
}

const EmployeeGrid = ({ data, isAnotherModalOpen, setIsAnotherModalOpen }) => {
  const navigate = useNavigate();

  const { toggleModal } = useModalContext();
  const { colleaguesModalProps, setColleagueData, openColleagueModal } =
    useColleaguesContext();
  const { isMobile, deviceHeight } = useMobileContext();
  const { theme } = useThemeContext();
  const { user } = useAuth();

  const [employeeId, setEmployeeId] = React.useState<number>(0);

  const userRole = getCurrentUserRole(user);
  const isAdminRole = allowPermision(userRole, rolesAllow);

  const getListGroupItems = (employee: IEmployee): ListGroupProps[] => [
    {
      label: null,
      data: getCurrentEmployeePosition(employee?.position),
      hideOnMobile: null
    },
    {
      label: null,
      data:
        employee && employee.teams && Object.keys(employee.teams).length ? (
          Object.values(employee.teams).join(', ')
        ) : (
          <FormattedMessage id="label_profileCardemployee_Not_yet_in_team" />
        ),
      hideOnMobile: isMobile
    },
    {
      label: null,
      data: !(userRole === IRole[IRole.Intern]) ? (
        <TextPhoneInput crudPhone={employee.phoneNumber} />
      ) : null,
      hideOnMobile: isMobile
    },
    {
      label: null,
      data: employee.email,
      hideOnMobile: isMobile
    }
  ];

  const handleCardClick = (employee: IEmployee) => {
    if (!isMobile) return;

    if (isAdminRole) {
      navigate(`viewProfile?employeeId=${employee.employeeId}`);
    } else {
      /* Employee open modal view colleague on Mobile click on Card */
      setColleagueData(employee);
      openColleagueModal();
    }
  };

  return (
    <>
      {/* Employee open modal view colleague on Mobile click on Card */}
      <ModalComponent {...colleaguesModalProps} />

      {!isAnotherModalOpen && (
        <ModalComponent
          isMaskClosable={false}
          key="EditEmployeeModal"
          formKeyId="employeeForm"
          title={<FormattedMessage id="EditColleagueModalTitle" />}
          submitBtnText={<FormattedMessage id="save" />}
          cancelBtnText={<FormattedMessage id="cancel" />}
          className="mobile-modal"
          children={<EditProfilePage scope="edit" employeeId={employeeId} />}
          centered={true}
        />
      )}

      <GridLayout
        style={{
          height: allowPermision(userRole, adminRoles)
            ? !isMobile
              ? `calc(${deviceHeight}px - 292px)`
              : `calc(${deviceHeight}px - 282px)`
            : `calc(${deviceHeight}px - 292px)`
        }}
        className={`${EmployeeGridStyles['layout']} ${EmployeeGridStyles[theme]}`}
        gridItems={data}
        GridItem={(employee: IEmployee) => (
          <GridCard
            key={`EmployeeGrid__${employee.employeeId}`}
            className={EmployeeGridStyles['card']}
            header={
              <div
                className={`${GridCardStyles['header']} ${EmployeeGridStyles['card__header']}`}
              >
                <div className={GridCardStyles['avatar']}>
                  <img
                    src={employee.externalAvatarUrl ?? avatar}
                    alt="Avatar"
                  />
                </div>
                {
                  isAdminRole ? (
                    <React.Fragment>
                      {
                        <Link
                          to={`viewProfile?employeeId=${employee?.employeeId}`}
                          className={EmployeeGridStyles['card__link']}
                        >
                          <h3 className={EmployeeGridStyles['card__title']}>
                            {employee.lastName}
                          </h3>
                          <h3 className={EmployeeGridStyles['card__title']}>
                            {employee.firstName}
                          </h3>
                        </Link>
                      }
                      {
                        !isMobile ? (
                          <button
                            className={EmployeeGridStyles['card__action']}
                            onClick={() => {
                              setEmployeeId(employee.employeeId);
                              setIsAnotherModalOpen(false);
                              toggleModal();
                            }}
                          >
                            <EditIcon></EditIcon>
                          </button>
                        ) : null
                      }
                    </React.Fragment>
                  ) : (
                    <div className={EmployeeGridStyles['card__link']}>
                      <h3 className={EmployeeGridStyles['card__title']}>
                        {employee.lastName}
                      </h3>
                      <h3 className={EmployeeGridStyles['card__title']}>
                        {employee.firstName}
                      </h3>
                    </div>
                  )
                }
              </div>
            }
            body={
              <ul className={GridCardStyles['list-group']}>
                {getListGroupItems(employee).map(
                  ({ label, data, hideOnMobile }, index) => {
                    return hideOnMobile === false || hideOnMobile === null ? (
                      <li
                        key={`EmployeeGrid__${index}`}
                        className={GridCardStyles['list-group__item']}
                      >
                        {label ? (
                          <h4 className={GridCardStyles['list-group__label']}>
                            {label}
                          </h4>
                        ) : null}
                        {data ? (
                          <h4 className={GridCardStyles['list-group__data']}>
                            {data}
                          </h4>
                        ) : null}
                      </li>
                    ) : null;
                  }
                )}
              </ul>
            }
            onClick={() => handleCardClick(employee)}
          ></GridCard>
        )}
      ></GridLayout>
    </>
  );
};

export default EmployeeGrid;
