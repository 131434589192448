import { createSelector } from '@reduxjs/toolkit';
import { TicketBaseDto } from 'pages/Ticketing/types';

const filters = (state) => state.ticketingFilters;

export const areFiltersSelected = createSelector([filters], (filters) => {
  return (
    filters.assignmentType ||
    filters.urgencyTypes ||
    filters.statusTypes ||
    filters.categoriesTypes
  );
});
