import ContentLoader from 'react-content-loader';

export const DateTogglerSkeleton = (props) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: '5 ',
      }}
    >
      <ContentLoader
        speed={1}
        width={'220'}
        height={'35'}
        // viewBox="0 20 100% 100%"
        backgroundColor={props.backgroundColor || '#ffffff33'}
        foregroundColor={props.foregroundColor || '#BDA7E833'}
        {...props}
      >
        <rect x="0" y="0" rx="10" ry="10" width="220" height="25" />
      </ContentLoader>
    </div>
  );
};
