import { PlusCircleOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { ExportSvg } from 'Icons/ExportComponent';
import AddCategoryIcon from 'Icons/Inventory/AddCategoryIcon';
import ManageCategoryIcon from 'Icons/Inventory/ManageCategoryIcon';
import { HardwareListComponent } from 'Icons/Inventory/hardwareListComponent';
import { SoftwareListComponent } from 'Icons/Inventory/softwareListComponent';
import { Dropdown, InputRef, Menu, Space, Spin, Upload } from 'antd';
import 'components/AllUsersList/colleaguesStyles.less';
import AssignForm from 'components/AssignForm';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import AssetCategoryForm from 'components/Categories/CategoryForm';
import HardwareForm from 'components/Hardware/HardwareForm';
import ModalComponent from 'components/Modal';
import {
  ModalContextProvider,
  useModalContext
} from 'components/Modal/ModalProvider';
import CustomSwitch from 'components/Switch';
import 'components/UIComponents/Dropdown/DropdownComponent.less';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAssets } from 'redux/slices/assetsSlice';
import { AssetsService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { firstCharToUpperCase } from 'utils/lang/utils';
import normalizeString from 'utils/normalizeString';
import _sort from 'utils/sorter';
import {
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import HardwareTable from './HardwareTable';
import { titlesKeysofColumnsHardware } from './utils';
import { RootState } from 'redux/store';

const ButtonComponent = BaseButton(ButtonSimple);

type HardwareProps = {
  formScoping?: string;
  assetId?: number | null;
  asignee?: string | null;
  formKeyId?: string | null;
  assignPerson?: object | null;
};

const CategoryMenu = ({ setIsOpenModal, setModalProps }) => {
  const { theme } = useThemeContext();

  const { setIsTopRoleModalOpen } = useModalContext();

  return (
    <Menu
      className={`dropdown-menu-categories ${theme}`}
      items={[
        {
          label: <FormattedMessage id="AddCategory" />,
          key: '0'
        },
        {
          label: (
            <Link to="../categories">
              {' '}
              <FormattedMessage id="ManageCategories" />
            </Link>
          ),
          key: '1'
        }
      ]}
      onClick={e => {
        if (e.key !== '0') {
          return;
        }
        setIsTopRoleModalOpen(false);
        setIsOpenModal(true);
        setModalProps(state => ({
          ...state,
          formKeyId: 'assetCategoryForm',
          modalTitle: <FormattedMessage id="AddQuickCategory" />,
          scope: 'add',
          cancelButtonText: <FormattedMessage id="cancel" />,
          submitButtonText: <FormattedMessage id="add" />
        }));
      }}
    />
  );
};

type ModalProps = {
  scope: string;
  modalTitle: string | JSX.Element;
  hardwareAsigneeName: string | JSX.Element;
  hardwareAsigneeType: string | JSX.Element;
  hardwareAssetId: number;
  submitButtonText: string | JSX.Element;
  cancelButtonText: string | JSX.Element;
  formKeyId: string;
  assignPerson: any;
};

const ViewHardware: FC<HardwareProps> = ({
  assetId,
  asignee,
  formScoping = 'add',
  formKeyId,
  assignPerson
}) => {
  const [modalProps, setModalProps] = useState<ModalProps>({
    scope: formScoping,
    modalTitle: '',
    hardwareAsigneeName: asignee,
    hardwareAsigneeType: asignee,
    hardwareAssetId: assetId,
    submitButtonText: <FormattedMessage id="submit" />,
    cancelButtonText: <FormattedMessage id="cancel" />,
    formKeyId: 'hardwareForm',
    assignPerson: assignPerson
  });

  const {
    toggleModal,
    setIsModalOpen,
    isModalOpen,
    isTopRoleModalOpen,
    setIsTopRoleModalOpen
  } = useModalContext();
  const navigate = useNavigate();
  const { isMobile, deviceOS, isTabletDevice } = useMobileContext();

  const { user } = useAuth();
  const role = getCurrentUserRole(user);
  const ButtonCustom = BaseButton(ButtonSimple);
  const { theme } = useThemeContext();
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const [hardwareData, setHardwareData] = useState<any[]>([]);
  const searchInput = useRef<InputRef>(null);
  const isAllowedUserRole = allowPermision(user.role, [
    userRoles.Admin,
    userRoles.Accountant
  ]);
  const [status, setStatus] = useState('pending');
  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAssets = () => {
      setStatus('pending');
      AssetsService.getAllAssets('true')
        .then(res => {
          const { data, error } = res;
          if (data) {
            const filtredAssets = data
              .filter(item => item.type === 'Hardware')
              .sort((a, b) =>
                _sort(b[defaultPresortedKey], a[defaultPresortedKey], 'string')
              );
            setData(filtredAssets);
            dispatch(setAssets(filtredAssets));
          } else {
            console.error(error);
          }
          setStatus('success');
        })
        .catch(err => {
          console.error(err);
          setStatus('error');
        });
    };

    fetchAssets();
  }, []);

  window.localStorage.setItem('displayHardwareList', 'Hardware');

  const defaultPresortedKey = 'model';

  const assetsStore = useSelector((state: RootState) => state.assets);

  useEffect(() => {
    setResults(assetsStore);
    setHardwareData(assetsStore);
  }, [assetsStore]);

  const params = {
    searchText,
    setSearchText,
    searchedColumn,
    setSearchedColumn,
    searchInput,
    isAllowedUserRole
  };

  const handleSearchToolbar = (value, columnSearch) => {
    setSearchText(value);
    const currValue = value;
    const colToBeSearched =
      titlesKeysofColumnsHardware.filter(
        item =>
          intl.formatMessage({ id: item.title.props.id ?? null }) ===
          columnSearch
      )[0] || null;
    const currKey = colToBeSearched?.dataIndex ?? '';
    setSearchedColumn(currKey);

    const filteredData = results.filter(entry => {
      if (currValue === '') {
        // when current value on search is initial is empty
        return entry;
      }
      if (
        entry[currKey] === null ||
        entry[currKey] === undefined ||
        entry[currKey].trim() === ''
      ) {
        // when values from api is empty | null | undefined
        return false;
      }

      return typeof entry[currKey] === 'object'
        ? Object.values(entry[currKey]).filter((e: string) =>
            normalizeString(e).startsWith(normalizeString(currValue))
          ).length
        : normalizeString(entry[currKey]).includes(normalizeString(currValue));
    });

    setHardwareData(
      [...filteredData].sort((a, b) =>
        _sort(b[defaultPresortedKey], a[defaultPresortedKey], 'string')
      )
    ); //sort data based on locale
  };

  interface ButtonsType {
    show?: boolean;
    className?: string;
    type?: string;
    icon?: ReactNode;
    iconSrc?: any;
    text: ReactNode;
    action?: any;
    isUploadButton?: boolean;
    isMultipleUpload?: boolean;
    uploadAccept?: string;
    uploadMaxCount?: number;
    uploadInProgress?: boolean;
    uploadShowList?: boolean;
    beforeUploadAction?: any;
    isDropdown?: boolean;
    dropdownOverlay?: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >;
  }

  const topRoleButtons: ButtonsType[] = [
    {
      icon: <PlusCircleOutlined />,
      text: (
        <span>
          <FormattedMessage id="addItem" />
        </span>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
        setModalProps(state => ({
          ...state,
          scope: 'add',
          hardwareAssetId: null,
          modalTitle: AddNewAssetModalTitle,
          cancelButtonText: <FormattedMessage id="cancel" />,
          submitButtonText: <FormattedMessage id="add" />,
          formKeyId: 'hardwareForm'
        }));
        toggleModal();
      },
      show: allowPermision(role, [userRoles.Admin, userRoles.Accountant])
    },
    {
      icon: <ExportSvg />,
      text: (
        <span>
          <FormattedMessage id="export" />
        </span>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
      },
      show: allowPermision(role, [userRoles.Admin, userRoles.Accountant])
    },
    {
      icon: (
        <AddCategoryIcon stroke={`${theme === 'dark' ? '#fff' : '#402A87'}`} />
      ),
      text: (
        <span>
          <FormattedMessage id="AddCategory" />
        </span>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
        setIsModalOpen(true);
        setModalProps(state => ({
          ...state,
          formKeyId: 'assetCategoryForm',
          modalTitle: addCategoryText,
          scope: 'add',
          cancelButtonText: <FormattedMessage id="cancel" />,
          submitButtonText: <FormattedMessage id="add" />
        }));
      },
      show: allowPermision(role, [userRoles.Admin, userRoles.Accountant])
    },
    {
      icon: (
        <ManageCategoryIcon
          stroke={`${theme === 'dark' ? '#fff' : '#402A87'}`}
        />
      ),
      text: (
        <Link className={`categories-link ${theme}`} to="../categories">
          <FormattedMessage id="ManageCategories" />
        </Link>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
      },
      show: allowPermision(role, [userRoles.Admin, userRoles.Accountant])
    }
  ];

  const intl = useIntl();
  const addNewAssetText = intl.formatMessage({ id: 'AddNewAsset' });
  const moreText = intl.formatMessage({ id: 'more' });
  const addCategoryText = intl.formatMessage({ id: 'AddQuickCategory' });

  const AddNewAssetModalTitle = firstCharToUpperCase(addNewAssetText);

  enum ToolbarSearchOptions {
    AssetName = 'AssetName',
    Model = 'Model',
    Assignee = 'Assignee',
    SerialNumber = 'SerialNumber',
    InventoryNumber = 'InventoryNumber',
    Category = 'Category',
    PartNumber = 'PartNumber',
    InvoiceNumber = 'InvoiceNumber'
  }

  const SearchOptions = !isAllowedUserRole
    ? (({ InvoiceNumber, ...otherOptions }) => otherOptions)(
        ToolbarSearchOptions
      )
    : ToolbarSearchOptions;

  return (
    <>
      <ModalComponent
        alignSelf="middle"
        className="inventory-modal"
        isMaskClosable={
          !(modalProps.scope === 'edit' || modalProps.scope === 'add')
        }
        title={modalProps.modalTitle}
        formKeyId={modalProps.formKeyId}
        children={
          modalProps.formKeyId === 'hardwareForm' ? (
            <HardwareForm
              scope={modalProps.scope}
              assetId={modalProps.hardwareAssetId}
              assignPerson={modalProps.assignPerson}
            />
          ) : modalProps.formKeyId === 'assetCategoryForm' ? (
            <AssetCategoryForm
              scope={modalProps.scope}
              categoryType="Hardware"
            />
          ) : (
            <AssignForm
              scope={modalProps.scope}
              assetId={modalProps.hardwareAssetId}
              assignPerson={modalProps.assignPerson}
            />
          )
        }
        submitBtnText={modalProps.submitButtonText}
        cancelBtnText={
          modalProps.scope === 'view' ? (
            <FormattedMessage id="close" />
          ) : (
            modalProps.cancelButtonText
          )
        }
        displayFooterSubmitButton={!(modalProps.scope === 'view')}
        editButton={
          modalProps.scope === 'view' &&
          allowPermision(role, [
            userRoles.Admin,
            userRoles.Accountant
          ]) ? (
            <ButtonCustom
              className={`text-bold-normal btn-primary-custom ${theme}`}
              key="editHardwareViewButton"
              type="primary"
              onClick={e => {
                setModalProps(state => ({
                  ...state,
                  modalTitle: <FormattedMessage id="EditAsset" />,
                  className: 'mobile-modal',
                  scope: 'edit',
                  cancelButtonText: <FormattedMessage id="cancel" />,
                  submitButtonText: <FormattedMessage id="save" />,
                  formKeyId: 'hardwareForm'
                }));
              }}
            >
              <FormattedMessage id="edit" />
            </ButtonCustom>
          ) : null
        }
        footerButtons={
          isMobile ? (
            modalProps.scope === 'view' &&
            allowPermision(role, [
              userRoles.Admin,
              userRoles.Accountant
            ]) ? (
              <>
                {modalProps.hardwareAsigneeName &&
                modalProps.hardwareAsigneeType !== 'None' ? (
                  <>
                    <div className="inventory_footer_buttons">
                      <ButtonCustom
                        className={`text-bold-normal btn-default-custom  ${theme}`}
                        style={{ maxWidth: '100%' }}
                        onClick={() => {
                          setModalProps(state => ({
                            ...state,
                            scope: 'deassignAsset',
                            modalTitle: <FormattedMessage id="UnassignAsset" />,
                            submitButtonText: (
                              <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                            ),
                            cancelButtonText: <FormattedMessage id="cancel" />,
                            formKeyId: 'AssignForm',
                            assignPerson: modalProps.hardwareAsigneeName
                          }));
                        }}
                      >
                        <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                      </ButtonCustom>
                    </div>
                  </>
                ) : (
                  <div className="inventory_footer_buttons">
                    <ButtonCustom
                      className={`text-bold-normal btn-default-custom  ${theme}`}
                      key="editHardwareViewButton"
                      style={{ maxWidth: '100%' }}
                      onClick={() => {
                        setModalProps(state => ({
                          ...state,
                          scope: 'assignColleague',
                          hardwareAssetId: modalProps.hardwareAssetId,
                          modalTitle: (
                            <FormattedMessage id="AssignToAColleague" />
                          ),
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                      }}
                    >
                      <FormattedMessage id="table_SoftwHardwBtn_colleague_assign" />
                    </ButtonCustom>

                    <ButtonCustom
                      className={`text-bold-normal btn-default-custom ant-dropdown-trigger  ${theme}`}
                      style={{ maxWidth: '100%' }}
                      onClick={() => {
                        setModalProps(state => ({
                          ...state,
                          scope: 'assignTeam',
                          ardwareAssetId: modalProps.hardwareAssetId,
                          className: 'mobile-modal',
                          modalTitle: <FormattedMessage id="AssignToATeam" />,
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                      }}
                    >
                      <FormattedMessage id="table_SoftwHardwBtn_team_assign" />
                    </ButtonCustom>
                  </div>
                )}
              </>
            ) : null
          ) : null
        }
      />

      <>
        <SearchToolbarComponent
          displaySearchIcon={false}
          initialSelected={intl.formatMessage({
            id: `inventoryHardware_table_${ToolbarSearchOptions.SerialNumber}`
          })}
          searchOptions={Object.keys(SearchOptions).map(value => ({
            value: value as keyof typeof SearchOptions,
            label: intl.formatMessage({
              id: `inventoryHardware_table_${value}`
            })
          }))}
          buttons={[
            {
              icon: <PlusCircleOutlined />,
              text: (
                <span>
                  <FormattedMessage id="addItem" />
                </span>
              ),
              action: () => {
                setModalProps(state => ({
                  ...state,
                  scope: 'add',
                  hardwareAssetId: null,
                  modalTitle: AddNewAssetModalTitle,
                  cancelButtonText: <FormattedMessage id="cancel" />,
                  submitButtonText: <FormattedMessage id="add" />,
                  formKeyId: 'hardwareForm'
                }));
                toggleModal();
              },
              show: allowPermision(role, [
                userRoles.Admin,
                userRoles.Accountant
              ])
            },
            {
              icon: <ExportSvg />,
              text: (
                <span>
                  <FormattedMessage id="export" />
                </span>
              ),
              show: allowPermision(role, [
                userRoles.Admin,
                userRoles.Accountant
              ])
            },
            {
              text: moreText,
              isDropdown: true,
              dropdownOverlay: (
                <CategoryMenu
                  setIsOpenModal={toggleModal}
                  setModalProps={setModalProps}
                />
              ),
              show: allowPermision(role, [
                userRoles.Admin,
                userRoles.Accountant
              ])
            }
          ]}
          displaySearchInput={true}
          onChangeInputSearch={handleSearchToolbar}
        />
      </>
      <br />

      <HardwareTable
        params={params}
        results={results}
        hardwareData={hardwareData}
        stateActions={{ setModalProps }}
        isModalOpen={isModalOpen}
        setIsModalOpen={toggleModal}
        status={status}
      />

      <ModalContextProvider>
        <ModalComponent
          maskStyle={{ opacity: 0 }}
          className="top-role-popup"
          isOpen={isTopRoleModalOpen}
          setIsOpen={setIsTopRoleModalOpen}
          footerButtons={false}
          displayFooterCloseButton={false}
          displayFooterSubmitButton={false}
          maskClosable={true}
        >
          <section className="top-role-popup-buttons">
            <button style={{ background: 'none', border: 'none' }}>
              <CustomSwitch
                onChange={() => {
                  localStorage.setItem(
                    'displayHardwareList',
                    `${
                      window.localStorage.getItem('displayHardwareList') ===
                      'Hardware'
                        ? 'Software'
                        : 'Hardware'
                    }`
                  );
                  navigate('/inventory/software');
                  setIsTopRoleModalOpen(false);
                }}
                values={[
                  {
                    id: 0,
                    value: 'Software',
                    icon: <SoftwareListComponent />
                  },
                  {
                    id: 1,
                    value: 'Hardware',
                    icon: <HardwareListComponent />
                  }
                ]}
                defaultValue={
                  localStorage.getItem('displayHardwareList') &&
                  localStorage.getItem('displayHardwareList') === 'Hardware'
                    ? 'Hardware'
                    : 'Software'
                }
              />
            </button>

            {topRoleButtons.map((button, index) =>
              button.show ? (
                button.isUploadButton ? (
                  <Upload
                    key="upload"
                    multiple={button.isMultipleUpload ?? false}
                    beforeUpload={button.beforeUploadAction}
                    accept={button.uploadAccept}
                    maxCount={button.uploadMaxCount}
                    showUploadList={button.uploadShowList}
                  >
                    <ButtonComponent
                      className={`text-bold-normal btn-default-custom ${theme}`}
                      icon={button.icon}
                    >
                      {button.text}
                    </ButtonComponent>
                    {button.uploadInProgress && (
                      <Spin size="small" className={'uploader-spinner'} />
                    )}
                  </Upload>
                ) : button.isDropdown ? (
                  <Dropdown
                    overlayStyle={{ fontWeight: 500 }}
                    overlay={button.dropdownOverlay}
                    trigger={['click']}
                    key={index}
                  >
                    <Space>
                      <ButtonComponent
                        className={`text-bold-normal btn-default-custom ${theme}`}
                        key={index}
                      >
                        {button.text}
                      </ButtonComponent>
                    </Space>
                  </Dropdown>
                ) : (
                  <ButtonComponent
                    key={`button-component-${index}`}
                    type={button.type}
                    icon={
                      button.icon ? (
                        button.icon
                      ) : button.iconSrc ? (
                        <embed src={button.iconSrc} />
                      ) : null
                    }
                    className={
                      button.className
                        ? button.className
                        : `text-bold-normal btn-default-custom ${theme}`
                    }
                    onClick={button.action}
                  >
                    {button.text}
                  </ButtonComponent>
                )
              ) : null
            )}
          </section>
        </ModalComponent>
      </ModalContextProvider>
    </>
  );
};

export default ViewHardware;
