import { Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import './index.less';
import { FormattedMessage } from 'react-intl';
import { convertToLocalDate } from 'components/DateParser';
import { getFiltersBySpecificField } from './utils';
import _sort from 'utils/sorter';

const { Text } = Typography;

export const columnsRequestsTimeManagement = (
  data,
  role,
  params,
  isModalOpen,
  setIsModalOpen,
  hookUseStateActions,
  dataSource,
  modalOpenValues,
  teamsFilter,
  setModalOpenValues
): ColumnsType<any> => {
    
  const policiesFilter = params?.policies?.map((policy) => ({
    text: <FormattedMessage id={policy} />,
    value: policy
  }));

  const statusFilter = params?.statuses?.map((status) => ({
    text: <FormattedMessage id={status} />,
    value: status
  }));

  return [
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Type" />,
      dataIndex: 'requestType',
      key: 'requestType',
      filters: policiesFilter,
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={<FormattedMessage id={record.requestType} />}
        >
          <Text
            className="text-medium-big pointer-link line-clamp-1"
            underline
            onClick={() =>
              params.redirectColleagues({
                requestType: record.requestType,
                status: record.status,
                requestId: record.requestId
              })
            }
          >
            <FormattedMessage id={record.requestType} />
          </Text>
        </Tooltip>
      )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Amount" />,
      dataIndex: 'daysTaken',
      key: 'daysTaken',
      sorter: true,
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false
      },
      width: '170px',
      render: (_, record) => {
        let hoursResults;
        const requestTypes = record.requestType;
        const hourlyBasedTimeOffRequestType = [
          'PersonalLeave',
          'Overtime',
          'LeaveOffsetting'
        ].includes(requestTypes);
        if (hourlyBasedTimeOffRequestType) {
          const parseStartDate = convertToLocalDate(record.startDate).second(0);
          const parseEndDate = convertToLocalDate(record.endDate).second(0);
  
          const clockInOutDifference = Math.round(
            parseEndDate.diff(parseStartDate, 'minute', true)
          );
  
          const clockInOutHoursSpentDiff = parseEndDate.diff(
            parseStartDate,
            'hours'
          );
  
          const clockInOutRemainedMinutesDiff = clockInOutDifference % 60;
          hoursResults = !record.endDate ? (
            <FormattedMessage id="OngoingRequestText" />
          ) : (
            clockInOutHoursSpentDiff +
            'h ' +
            clockInOutRemainedMinutesDiff +
            'm, '
          );
        } else {
          hoursResults =
            record.daysTaken === 0 ? (
              <FormattedMessage id="OngoingRequestText" />
            ) : (
              <p>
                {record.daysTaken}{' '}
                {record.daysTaken === 1 ? (
                  <FormattedMessage id="day" />
                ) : (
                  <FormattedMessage id="days" />
                )}
              </p>
            );
        }
        return (
          <div className="line-clamp-1">
            {hoursResults}
            {hourlyBasedTimeOffRequestType && (
              <span>
                {convertToLocalDate(record.startDate).format('HH')}
                <span className={'minutes'}>
                  {convertToLocalDate(record.startDate).format('mm')}
                </span>{' '}
                - {convertToLocalDate(record.endDate).format('HH')}
                <span className={'minutes'}>
                  {convertToLocalDate(record.endDate).format('mm')}
                </span>
              </span>
            )}
          </div>
        );
      }
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_From" />,
      dataIndex: 'startDate',
      key: 'startDate',
      showSorterTooltip: false,
      sorter: true,
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={dayjs(record.startDate).format('DD/MM/YYYY')}
        >
          <p className="line-clamp-1">
            {dayjs(record.startDate).format('DD/MM/YYYY')}
          </p>
        </Tooltip>
      )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Until" />,
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={
            record.endDate ? (
              convertToLocalDate(record.endDate).format('DD/MM/YYYY')
            ) : (
              <FormattedMessage id="OngoingRequestText" />
            )
          }
        >
          <p className="line-clamp-1">
            {record.endDate ? (
              convertToLocalDate(record.endDate).format('DD/MM/YYYY')
            ) : (
              <FormattedMessage id="OngoingRequestText" />
            )}
          </p>
        </Tooltip>
      )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Status" />,
      dataIndex: 'requestStatus',
      key: 'requestStatus',
      filters: statusFilter,
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={<FormattedMessage id={record.status} />}
        >
          <p className="line-clamp-1">
            {<FormattedMessage id={record.status} />}
          </p>
        </Tooltip>
      )
    }
  ];  
};
