import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const methodUpdateReducer = createSlice({
  name: 'methodUpdate',
  initialState: {},
  reducers: {
    setMethodUpdate(state, action: PayloadAction<boolean>) {
      return { ...state, isMethodUpdated: action.payload };
    },
    setFormValidation(state, action: PayloadAction<boolean>) {
      return { ...state, isFormValid: action.payload };
    }
  }
});

export const { setMethodUpdate, setFormValidation } =
  methodUpdateReducer.actions;
export default methodUpdateReducer.reducer;
