import ContentLoader from 'react-content-loader';

export const CalendarSkeleton = (props) => {
  const arr: JSX.Element[] = [];

  const daysInWeek = 7;
  const weeks = 5;
  const borderRadius = props.borderradius || 10;
  const calendarCellWidth = props.calendarcellwidth || 120;
  const gutter = props.gutter || 5;
  const headerCellheight = props.headercellheight || 33;
  const calendarCellheight = props.calendarcellheight || 100;
  let prevY = 0;
  for (let week = 0; week <= weeks; week++) {
    for (let day = 0; day < daysInWeek; day++) {
      const key = `${week}-${day}`;
      if (week === 0) {
        arr.push(
          <rect
            key={key}
            x={(calendarCellWidth + gutter) * day}
            y={0}
            rx={borderRadius}
            ry={borderRadius}
            width={calendarCellWidth}
            height={headerCellheight}
          />
        );
      } else {
        arr.push(
          <rect
            key={key}
            x={(calendarCellWidth + gutter) * day}
            y={prevY + gutter}
            rx={borderRadius}
            ry={borderRadius}
            width={calendarCellWidth}
            height={calendarCellheight}
          />
        );
      }
    }
    prevY += (week === 0 ? headerCellheight : calendarCellheight) + gutter;
  }
  return (
    <ContentLoader
      speed={1}
      width={'100%'}
      height={'100%'}
      // viewBox="0 20 100% 100%"
      backgroundColor={props.backgroundColor || '#ffffff33'}
      foregroundColor={props.foregroundColor || '#BDA7E833'}
      {...props}
    >
      {arr}
    </ContentLoader>
  );
};

export const CalendarDayViewSkeleton = (props) => {
  const arr: JSX.Element[] = [];

  const daysInWeek = 7;
  const borderRadius = props.borderradius || 10;
  const calendarCellWidth = props.calendarcellwidth || 120;
  const gutter = props.gutter || 5;
  const headerCellheight = props.headercellheight || 33;
  const calendarCellheight = props.calendarcellheight || 100;
  let prevY = 0;
  for (let day = 0; day < daysInWeek; day++) {
    const key = `${day}`;
    arr.push(
      <rect
        key={key}
        x={(calendarCellWidth + gutter) * day}
        y={prevY + gutter}
        rx={borderRadius}
        ry={borderRadius}
        width={calendarCellWidth}
        height={calendarCellheight}
      />
    );
    prevY += (calendarCellheight) + gutter;
  }
  return (
    <ContentLoader
      speed={1}
      width={'100%'}
      height={'100%'}
      // viewBox="0 20 100% 100%"
      backgroundColor={props.backgroundColor || '#ffffff33'}
      foregroundColor={props.foregroundColor || '#BDA7E833'}
      {...props}
    >
      {arr}
    </ContentLoader>
  );
}

export const CalendarWeekViewSkeleton = (props) => {
  const arr: JSX.Element[] = [];

  const daysInWeek = 7;
  const weeks = 12;
  const borderRadius = props.borderradius || 10;
  const calendarCellWidth = props.calendarcellwidth || 120;
  const gutter = props.gutter || 5;
  const headerCellheight = props.headercellheight || 33;
  const calendarCellheight = props.calendarcellheight || 100;
  let prevY = 0;
  for (let week = 0; week <= weeks; week++) {
    for (let day = 0; day < daysInWeek; day++) {
      const key = `${week}-${day}`;
      if (week === 0) {
        arr.push(
          <rect
            key={key}
            x={(calendarCellWidth + gutter) * day}
            y={0}
            rx={borderRadius}
            ry={borderRadius}
            width={calendarCellWidth}
            height={headerCellheight}
          />
        );
      } else {
        arr.push(
          <rect
            key={key}
            x={(calendarCellWidth + gutter) * day}
            y={prevY + gutter}
            rx={borderRadius}
            ry={borderRadius}
            width={calendarCellWidth}
            height={calendarCellheight}
          />
        );
      }
    }
    prevY += (week === 0 ? headerCellheight : calendarCellheight) + gutter;
  }
  return (
    <ContentLoader
      speed={1}
      width={'100%'}
      height={'100%'}
      // viewBox="0 20 100% 100%"
      backgroundColor={props.backgroundColor || '#ffffff33'}
      foregroundColor={props.foregroundColor || '#BDA7E833'}
      {...props}
    >
      {arr}
    </ContentLoader>
  );
};