import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { ITicket } from 'services/providers/TicketingProvider/types';

const TicketLabel: React.FC<ITicket> = (props) => {
  const { ticketId, status, title } = props;
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  return (
    <li
      className="ticket_label_row"
      onClick={() => navigate(`/ticketing/viewTicket?ticketId=${ticketId}`)}
    >
      <p className="ticket_label_text">{title}</p>
      <span
        className="ticket_label_badge"
        style={{
          color: theme === 'light' ? '#402A87' : '#FFFFFF',
        }}
      >
        <FormattedMessage id={`Ticketing_${status}`} />
      </span>
    </li>
  );
};

export default TicketLabel;
