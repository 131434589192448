import { PhoneNumberUtil } from 'google-libphonenumber';
import { useState } from 'react';

const usePhoneValidation = () => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [isValid, setIsValid] = useState(true);

  const validatePhone = (phone) => {
    if(phone === '')
    return;

    const defaultPhoneValue = phoneUtil.parseAndKeepRawInput(
      phone && phone.replace(/\D/g, '').length < 6 ? phone + '0000' : phone //concatenation with '0000' allow library to determine country code
    );
    const parsedCountryCode = defaultPhoneValue.getCountryCode();
    const textCountryCode = parsedCountryCode.toString();
    if (
      phone &&
      phone.length > 0 &&
      phone.replace(/\D/g, '').length !== textCountryCode.length
    ) {
      try {
        const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);
        setIsValid(phoneUtil.isValidNumber(parsedPhone));
      } catch (error) {
        setIsValid(false);
      }
    } else {
      setIsValid(true);
    }
  };

  const prepareNumberBeforeSubmit = (currentPhoneInputValue) => {
// If the number is not mandatory, I check for an empty string or only a country code. Return an empty string for these cases, otherwise return the full valid number.
    const phoneNumber = currentPhoneInputValue.replace(/\D/g, '');
    const defaultPhoneValue = phoneUtil.parseAndKeepRawInput(
      phoneNumber && phoneNumber.length < 6 ? currentPhoneInputValue + '0000' : currentPhoneInputValue
    );
    const textCountryCode = defaultPhoneValue.getCountryCode().toString();

    return (phoneNumber.length === textCountryCode.length || currentPhoneInputValue === '') ? '' : phoneNumber;

  }

  return {
    isValid,
    validatePhone,
    prepareNumberBeforeSubmit
  };
};

export { usePhoneValidation };
