export const DownloadIconSVG = ({ onClick = null }) => {
  return (
    <span
      role="img"
      aria-label="downloadIcon"
      className="anticon anticon-downloadIcon"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
      >
        <path
          d="M4.60937 8.90929H2.92857C1.86345 8.90929 1 8.18286 1 7.11774C1 6.05262 1.86345 5.18917 2.92857 5.18917C3.001 5.18917 3.0725 5.19317 3.14286 5.20095V5.18917H3.16938C3.15188 5.04878 3.14286 4.90574 3.14286 4.7606C3.14286 2.86705 4.67788 1.33203 6.57143 1.33203C7.85369 1.33203 8.97154 2.03593 9.55965 3.07841C9.70337 3.05727 9.85041 3.04632 10 3.04632C11.6569 3.04632 13 4.38946 13 6.04632C13 7.55766 11.8824 8.67095 10.4286 8.8789H8.875M6.78571 5.40346V12.0008M6.78571 12.0008L5.26562 10.5195M6.78571 12.0008L8.26562 10.5195"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
