import { useEffect, useState } from 'react';

export const useIsDragging = () => {
  const [isDragging, setIsDragging] = useState(false);

  const dragEvent = event => {
    if (event.dataTransfer.types.includes('Files')) {
      setIsDragging(true);
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const onWindowBlur = () => {
      setIsDragging(false);
    };

    window.addEventListener('dragover', dragEvent);
    window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('dragover', dragEvent);
      window.removeEventListener('blur', onWindowBlur);
    };
  }, []);

  return { isDragging, stopDragging };
};
