import { Typography } from 'antd';
import styles from './ticketHeader.module.less';
import Divider from 'components/Divider';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const TicketHeader: React.FC = () => {
  const { selectedTicket: ticket } = useSelector(
    (state: RootState) => state.ticketing
  );
  return (
    <div className={styles.header}>
      <Typography.Title className={styles.headerTitle} level={4}>
        #{ticket?.ticketNumber} - {ticket?.title}
      </Typography.Title>
      <Divider spacing={16} />
    </div>
  );
};
export default TicketHeader;
