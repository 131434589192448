import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InventoryGateway = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const inventoryLocalStorage = window.localStorage.getItem('displayHardwareList');
    navigate(`/inventory/${(inventoryLocalStorage && inventoryLocalStorage === 'Software') ? 'software': 'hardware'}`);
  }, []);

  return (null);
};

export default InventoryGateway;
