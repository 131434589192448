export const ImportSvg = () => {
  return (
    <span role='img' aria-label='import' className='anticon anticon-import'>
      <svg
        width='1em'
        height='1em'
        viewBox='0 0 20 22'
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.59961 14.2197L1.59961 17.9256C1.59961 18.4873 1.82086 19.0259 2.21469 19.423C2.60851 19.8202 3.14265 20.0433 3.69961 20.0433H16.2996C16.8566 20.0433 17.3907 19.8202 17.7845 19.423C18.1784 19.0259 18.3996 18.4872 18.3996 17.9256V14.2197M10.0008 1.95654V13.9565M10.0008 13.9565L14.8008 9.37139M10.0008 13.9565L5.20078 9.37139'
          stroke='inherit'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
};
