import { UploadFile } from 'antd';

export const validateFileType = (
  { type, name }: UploadFile,
  allowedTypes?: string
) => {
  if (!allowedTypes) {
    return true;
  }

  if (type) {
    return allowedTypes.includes(type);
  }
};
