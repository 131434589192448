import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useModalContext } from 'components/Modal/ModalProvider';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setFormValidation } from 'redux/slices/methodUpdateSlice';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import { onFinish } from './utils';

type StateActionsFormProps = { modalProps: any; requestId: number };

const StateActionsForm: FC<StateActionsFormProps> = ({
  modalProps,
  requestId,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { toggleModal, toggleRequestModal } = useModalContext();

  const onFinishFormActions = {
    dispatch,
    toggleModal,
  };

  return (
    <Form
      form={form}
      id="stateActionForm"
      key="stateActionFormKey"
      name="basic"
      onFinish={(onFinishData) => {
        const data = { ...onFinishData, requestId };

        dispatch(setFormValidation(true));

        onFinish(modalProps, data, onFinishFormActions);
        if (modalProps?.setStateForOpenModal) {
          modalProps?.setStateForOpenModal(false);
          toggleRequestModal()
        }
      }}
      onFinishFailed={(validationError) => {
        if (modalProps?.setStateForOpenModal) {
          modalProps?.setStateForOpenModal(true);
        }
        onFormFinishFailedValidation(validationError, dispatch);
      }}
    >
      <ColFormItemComponent span={24}>
        <p>{modalProps?.confirmMessage}</p>
      </ColFormItemComponent>
      <ColFormItemComponent
        span={24}
        name="notes"
        rules={[
          {
            required: true,
            message: <FormattedMessage id="PleaseProvideReasonHere" />,
          },
        ]}
      >
        <TextArea />
      </ColFormItemComponent>
    </Form>
  );
};

export default StateActionsForm;
