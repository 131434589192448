import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'redux/types';

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setLoggedUser(state, action: PayloadAction<IUser>) {
      return { ...state, loggedUser: action.payload };
    },
    setAuth0User(state, action: PayloadAction<IUser>) {
      return { ...state, user: action.payload };
    },
    setUserRole(state, action: PayloadAction<string>) {
      return {
        ...state,
        userRole: action.payload ? action.payload : 'Admin'
      };
    }
  }
});

export const { setAuth0User, setUserRole, setLoggedUser } = userSlice.actions;
export default userSlice.reducer;
