import React from 'react';

type TimeOffDownloadIconProps = {
  stroke?: string;
};

export const TimeOffDownloadIcon: React.FC<TimeOffDownloadIconProps> = ({
  stroke
}) => {
  const currentColor = 'currentColor';
  const strokeColor = stroke ? stroke : currentColor;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.60937 9.24278H2.92857C1.86345 9.24278 1 8.51636 1 7.45124C1 6.38612 1.86345 5.52267 2.92857 5.52267C3.001 5.52267 3.0725 5.52666 3.14286 5.53444V5.52267H3.16938C3.15188 5.38227 3.14286 5.23924 3.14286 5.0941C3.14286 3.20055 4.67788 1.66553 6.57143 1.66553C7.85369 1.66553 8.97154 2.36943 9.55965 3.4119C9.70337 3.39076 9.85041 3.37981 10 3.37981C11.6569 3.37981 13 4.72296 13 6.37981C13 7.89116 11.8824 9.00445 10.4286 9.2124H8.875M6.78571 5.73696V12.3343M6.78571 12.3343L5.26562 10.853M6.78571 12.3343L8.26562 10.853"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
