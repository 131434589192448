import {
  useIntlContext,
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import FilterIcon from 'Icons/FilterIcon';
import { Dropdown, Input, InputProps, InputRef, Space, Spin } from 'antd';
import Upload from 'antd/es/upload/Upload';
import searchIcon from 'assets/search.svg';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import SingleSelectDropdown from '../SingleSelectDropdown';
import './SearchToolbar.less';

export interface ISearchToolbarComponentProps<T> extends InputProps {
  searchInput?: ReactNode;
  searchOptions?: { value: T; label: T }[];
  initialSelected?: string;
  displaySearchInput?: boolean;
  displaySearchIcon?: boolean;
  displayToolbarButtons?: boolean;
  className?: string;
  onChangeInputSearch?: (event: any, columnSearch: string) => void;
  buttons: {
    show?: boolean;
    className?: string;
    type?: string;
    icon?: JSX.Element;
    iconSrc?: any;
    text: ReactNode;
    action?: any;
    isUploadButton?: boolean;
    isExportButton?: boolean;
    exportInProgress?: boolean;
    isMultipleUpload?: boolean;
    uploadAccept?: string;
    uploadMaxCount?: number;
    uploadInProgress?: boolean;
    uploadShowList?: boolean;
    beforeUploadAction?: any;
    isDropdown?: boolean;
    dropdownOverlay?: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >;
    isDisableButton?: boolean;
  }[];
}

const SearchToolbarComponent = <T extends string>({
  searchOptions = [],
  initialSelected = null,
  buttons,
  displaySearchInput,
  displaySearchIcon,
  className,
  onChangeInputSearch = () => {},
  displayToolbarButtons = true,
  children,
  ...props
}: ISearchToolbarComponentProps<T>) => {
  const { theme } = useThemeContext();
  const { locale } = useIntlContext();
  const { isMobile, isTabletDevice } = useMobileContext();

  const searchInputRef = useRef<InputRef>(null);
  const searchToolbarRef = useRef(null);

  const intl = useIntl();

  const placeholderText = intl.formatMessage({ id: 'typeHere' });
  const searchByText = intl.formatMessage({ id: 'searchBy' });

  const [selectedValue, setSelectedValue] = useState<string | null>(
    initialSelected
  );
  const [selectedSearchBy, setSelectedSearchBy] = useState<string | null>(
    initialSelected
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const ButtonComponent = BaseButton(ButtonSimple);

  useEffect(() => {
    setSelectedValue(initialSelected);
    setSelectedSearchBy(initialSelected);
    setTimeout(() => {
      onChangeInputSearch(searchValue, initialSelected);
    }, 0);
  }, [locale]);

  const handleInputChange = event => {
    const inputElement = document.getElementById(
      'search-select'
    ) as HTMLElement;
    inputElement.blur();
  };

  const handleChange = (selectedLabel: string) => {
    setSelectedValue(selectedLabel);
    setSelectedSearchBy(selectedLabel);
    onChangeInputSearch(searchValue, selectedLabel);
  };

  const adjustSearchToolbarLayout = () => {
    if (searchToolbarRef.current !== null) {
      const children = searchToolbarRef.current.children[0];
      if (children.children.length > 3) {
        children.classList.add('no_gap');
      }
    }
  };

  useEffect(() => {
    if (isMobile) adjustSearchToolbarLayout();
  }, [children]);

  return (
    <div
      ref={searchToolbarRef}
      className={`search-toolbar-component ${theme} ${className || ''} `}
    >
      {(displaySearchIcon ?? true) && (
        <div className="toolbar-search-icon">
          <embed
            src={searchIcon}
            onClick={() => searchInputRef.current.input.focus()}
          />
        </div>
      )}

      {children}

      {searchOptions?.length ? (
        <div className="search-toolbar_filters_container">
          <SingleSelectDropdown
            value={selectedValue}
            preffixText={searchByText}
            label={
              isMobile && isFocused ? (
                <FilterIcon stroke={theme === 'dark' ? '#fff' : '#402A87'} />
              ) : null
            }
            showSuffixIcon={isMobile && isFocused ? false : true}
            theme={theme}
            options={searchOptions}
            useLabelForSelection={true}
            dropdownAlign={{ offset: [53, -14] }}
            onChange={option => handleChange(option)}
          />
        </div>
      ) : null}

      {displaySearchInput ?? true ? (
        <Input
          ref={searchInputRef}
          bordered={false}
          placeholder={placeholderText}
          className={`input-search-component ${theme} `}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={event => {
            setSearchValue(event.target.value);
            onChangeInputSearch(event.target.value, selectedSearchBy);
          }}
          value={searchValue}
          {...props}
        />
      ) : null}

      {displayToolbarButtons ? (
        <div
          className="toolbar-buttons"
          style={{
            display: isMobile || isTabletDevice ? 'none' : 'flex',
            gap: '12px'
          }}
        >
          {buttons.map((button, index) =>
            button.show ? (
              button.isUploadButton ? (
                <Upload
                  key="upload"
                  multiple={button.isMultipleUpload ?? false}
                  beforeUpload={button.beforeUploadAction}
                  accept={button.uploadAccept}
                  maxCount={button.uploadMaxCount}
                  showUploadList={button.uploadShowList}
                  disabled={button.isDisableButton}
                >
                  <ButtonComponent
                    className={`text-bold-normal btn-default-custom ${theme}`}
                    icon={button.icon}
                    disabled={button.isDisableButton}
                  >
                    {button.text}
                  </ButtonComponent>
                  {button.uploadInProgress && (
                    <Spin size="small" className={'uploader-spinner'} />
                  )}
                </Upload>
              ) : button.isExportButton? (
                <>
                  {button.exportInProgress && (
                    <Spin size="small" className={'uploader-spinner'} />
                  )}
                    <ButtonComponent
                    className={`text-bold-normal btn-default-custom ${theme}`}
                    icon={button.icon}
                    disabled={button.isDisableButton}
                    onClick={button.action}
                    >
                      {button.text}
                    </ButtonComponent>

                </>
              ): button.isDropdown ? (
                <Dropdown
                  overlayStyle={{ fontWeight: 500 }}
                  overlay={button.dropdownOverlay}
                  trigger={['click']}
                  key={index}
                >
                  <Space>
                    <ButtonComponent
                      className={`text-bold-normal btn-default-custom ${theme}`}
                      key={index}
                    >
                      {button.text}
                    </ButtonComponent>
                  </Space>
                </Dropdown>
              ) : (
                <ButtonComponent
                  key={`button-component-${index}`}
                  type={button.type}
                  icon={
                    button.icon ? (
                      button.icon
                    ) : button.iconSrc ? (
                      <embed src={button.iconSrc} />
                    ) : null
                  }
                  className={
                    button.className
                      ? button.className
                      : `text-bold-normal btn-default-custom ${theme}`
                  }
                  onClick={button.action}
                >
                  {button.text}
                </ButtonComponent>
              )
            ) : null
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SearchToolbarComponent;
