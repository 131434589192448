import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { IModalComponentProps } from 'components/Modal';
import ThemedIcon from 'components/UIComponents/Icon';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import _sort from 'utils/sorter';
import { WorkQueueForm } from '../../../components/SetitingsComponents/WorkQueue/WorkQueueForm';

export const useWorkQueueTableColumns = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<IModalComponentProps>>,
  editModalWidth: number | string,
  deleteModalWidth: number | string
): ColumnsType<any> => {
  const { isMobile, isTabletDevice } = useMobileContext();
  // Row Actions
  // Remove category open modal and set modal props
  const handleDeleteClick = record => {
    if (record?.isAssignedToTicket) {
      setModalProps(state => ({
        ...state,
        scope: 'remove',
        key: 'workQueueForm',
        workQueueId: record?.workQueueId,
        displayFooterSubmitButton: false,
        displayFooterCloseButton: true,
        workQueueName: record?.name,
        modalTitle: (
          <FormattedMessage id="Settings_WorkQueues_Remove_Work_Queue" />
        ),
        cancelButtonText: <FormattedMessage id="cancel" />,
        width: deleteModalWidth,
        children: (
          <>
            <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
              <FormattedMessage id="WorkQueueCantRemoveConfirmText" />
            </p>
          </>
        )
      }));
    } else {
      setModalProps(state => ({
        ...state,
        scope: 'remove',
        key: 'workQueueForm',
        workQueueId: record?.workQueueId,
        disableSubmitButton: true,
        workQueueName: record?.name,
        modalTitle: (
          <FormattedMessage id="Settings_WorkQueues_Remove_Work_Queue" />
        ),
        cancelButtonText: <FormattedMessage id="cancel" />,
        submitButtonText: <FormattedMessage id="remove" />,
        width: deleteModalWidth,
        displayFooterSubmitButton: true,
        displayFooterCloseButton: true,
        children: (
          <>
            <WorkQueueForm
              scope={'remove'}
              workQueueId={record.workQueueId}
              workQueueName={record.workQueueName}
            />
          </>
        )
      }));
    }

    setIsOpen(true);
  };
  const handleEditClick = record => {
    setModalProps(state => ({
      ...state,
      scope: 'edit',
      key: 'workQueueForm',
      workQueueId: record?.workQueueId,
      workQueueName: record?.name,
      modalTitle: <FormattedMessage id="Settings_WorkQueues_Edit_Work_Queue" />,
      cancelButtonText: <FormattedMessage id="cancel" />,
      submitButtonText: <FormattedMessage id="save" />,
      displayFooterSubmitButton: true,
      displayFooterCloseButton: true,
      width: editModalWidth,
      children: (
        <WorkQueueForm
          scope={'edit'}
          workQueueId={record.workQueueId}
          workQueueName={record.name}
        />
      )
    }));
    setIsOpen(true);
  };

  //------End Row Actions
  const tableColumns = [
    {
      title: <FormattedMessage id="Settings_WorkQueues_Name" />,
      dataIndex: 'name',
      ellipsis: true,
      sorter: (a, b) => _sort(a.name, b.name, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => (
        <Link to={`/settings/viewWorkQueue?queueId=${record.workQueueId}`}>
          <p> {record.name}</p>{' '}
        </Link>
      )
    },

    {
      title: <FormattedMessage id="Settings_WorkQueues_configurations" />,
      dataIndex: 'configurationsCount',

      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <p>{record?.configurationsCount}</p>
    },
    {
      title: <FormattedMessage id="Settings_WorkQueues_Workers" />,
      dataIndex: 'workerCount',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      className: 'hoverEdit',
      render: (_, record) => (
        <>
          <p>{record?.workerCount}</p>
          <div className={'showEdit'} style={{ display: 'none' }}>
            <div className="actionButtons">
              <ButtonWithExpandedText
                displayedText={<FormattedMessage id="edit" />}
                embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                key="edit-category-btn"
                type="link"
                onClick={() => handleEditClick(record)}
              />
              <ButtonWithExpandedText
                embedSrc={<ThemedIcon icon={TimeOffDeleteIcon} />}
                displayedText={<FormattedMessage id="remove" />}
                type="link"
                onClick={() => handleDeleteClick(record)}
              />
            </div>
          </div>
        </>
      )
    }
  ];
  return [...tableColumns];
};
