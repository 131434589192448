import * as React from 'react';

import { Form, Input, Row } from 'antd';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import TextArea from 'antd/es/input/TextArea';
import TextPhoneInput from 'components/UIComponents/InputPhoneComponent/TextPhoneInput';
import { useCompanySettings } from 'settings';

interface IProps {
  colleagueData: any;
}

const ViewColleague: React.FC<IProps> = React.memo(({ colleagueData }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { theme } = useThemeContext();
  const { phoneCode } = useCompanySettings();

  const phoneNumber =
    colleagueData?.phoneNumber.length > 0
      ? colleagueData.phoneNumber
      : phoneCode.replace(/[()]/g, '');
  return (
    <Form
      form={form}
      id="viewColleague"
      layout="horizontal"
      size="middle"
      labelAlign="left"
      labelWrap
      labelCol={{ span: 12 }}
    >
      <ColFormItemComponent
        isDisabled
        span={24}
        label={<FormattedMessage id="table_colleaguesCol_Full_Name" />}
        isReadOnly={`col-form-item-component-view ${theme}`}
      >
        <Input
          value={`${colleagueData.lastName} ${colleagueData.firstName}`}
          disabled
        />
      </ColFormItemComponent>

      <ColFormItemComponent
        span={24}
        label={<FormattedMessage id="table_colleaguesCol_Position" />}
        isReadOnly={`col-form-item-component-view ${theme}`}
      >
        <Input
          value={intl.formatMessage({ id: colleagueData?.position })}
          disabled
        />
      </ColFormItemComponent>

      <ColFormItemComponent
        span={24}
        label={<FormattedMessage id="table_colleaguesCol_Teams" />}
        isReadOnly={`col-form-item-component-view ${theme}`}
      >
        <TextArea
          autoSize={true}
          minLength={1}
          maxLength={51}
          disabled
          value={Object.entries(colleagueData?.teams || {})
            .map(([teamId, teamName]) => teamName)
            .join(', ')}
        />
      </ColFormItemComponent>

      <a href={`mailto:${colleagueData.email}`}>
        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="table_colleaguesCol_Email" />}
          isReadOnly={`col-form-item-component-view ${theme}`}
        >
          <Input value={colleagueData.email} />
        </ColFormItemComponent>
      </a>
      <a href={`tel:+${colleagueData.phoneNumber}`}>
        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="table_colleaguesCol_Phone_number" />}
          isReadOnly={`col-form-item-component-view ${theme}`}
        >
          <TextPhoneInput crudPhone={phoneNumber} tagType={'input'} />
        </ColFormItemComponent>
      </a>
    </Form>
  );
});

export default ViewColleague;
