import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { BaseButton, ButtonSimple } from 'components/Buttons';
import {
  useMobileContext,
  useThemeContext,
} from 'AppProvider/ConfigProviderSettings';
import IssueCollectorForm from './IssueCollectorForm';

import ModalComponent from 'components/Modal';

const IssueCollector: React.FC<any> = ({ setShowReportIssue }) => {
  const { theme } = useThemeContext();
  const ButtonComponent = BaseButton(ButtonSimple);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setShowReportIssue(true);
  };

  return (
    <>
      <ButtonComponent
        type="primary"
        className={`text-bold-normal btn-primary-custom ${theme}`}
        onClick={showModal}
        style={{ width: '100%', padding: '4px 7px', marginTop: "10px" }}
      >
        <FormattedMessage id="reportIssue" />
      </ButtonComponent>
    </>
  );
};

export default IssueCollector;
