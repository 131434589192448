import { Form, Input, theme } from 'antd';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useAuth } from 'services/providers/AuthProvider';
import { allowPermision, adminRoles, getCurrentUserRole } from 'utils/userManagement';

const ViewMemberForm = ({ modalProps }) => {
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, adminRoles);
  return (
    <Form
      id="viewMemberForm"
      key="viewMemberFormKey"
      labelCol={{ span: 6 }}
      colon={false}
      // WILL BE MANAGED FROM MODAL
      disabled={true}
    >
      {/* Name and LastName*/}
      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewMembersForm_name"/>}
      >
        <Input disabled value={modalProps?.employeeName} />
      </ColFormItemComponent>
      {/* official function*/}
      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewMembersForm_function" />}
      >
        <Input disabled value={modalProps?.employeePosition} />
      </ColFormItemComponent>
      {/* Email*/}
      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewMembersForm_email" />}
      >
        <Input disabled value={modalProps?.email} />
      </ColFormItemComponent>
      {/* Phone number*/}
      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewMembersForm_phone_number" />}
      >
        <Input disabled value={modalProps?.phoneNumber} />
      </ColFormItemComponent>

      {/* Date Assigned*/}
      {isAdminUserRole && (
        <ColFormItemComponent
          isReadOnly={`col-form-item-component-view ${theme}`}
          span={24}
          label={<FormattedMessage id="label_ViewMembersForm_data_assignee" />}
        >
          <Input
            disabled
            value={
              dayjs(modalProps?.dateAssigned).isValid()
                ? dayjs(modalProps?.dateAssigned).format('DD-MM-YYYY')
                : '-'
            }
          />
        </ColFormItemComponent>
      )}
    </Form>
  );
};

export default ViewMemberForm;
