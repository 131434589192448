import * as React from 'react';
import PlaceholderWrapper from '../UI/PlaceholderWrapper';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export const DashboardPagePlaceholder: React.FC = props => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      viewBox={`0 0 ${deviceWidth - 143} ${deviceHeight - 90 - 32}`}
      height={`${deviceHeight - 90 - 32}px`}
      width={`${deviceWidth - 143}px`}
      {...props}
    >
      <rect x="0" y="0" ry="20" rx="20" width="100%" height="49%" />
      <rect x="0" y="51%" ry="20" rx="20" width="49%" height="49%" />
      <rect x="50%" y="51%" ry="20" rx="20" width="24.5%" height="49%" />
      <rect x="75.5%" y="51%" ry="20" rx="20" width="24.5%" height="49%" />
    </PlaceholderWrapper>
  );
};

export const ProfilePagePlaceholder: React.FC = props => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      viewBox={`0 0 ${deviceWidth - 143} ${deviceHeight - 90 - 32}`}
      height={`${deviceHeight - 90 - 32}px`}
      width={`${deviceWidth - 143}px`}
      {...props}
    >
      <rect x="0" y="0" ry="20" rx="20" width="49.4%" height="49%" />

      <rect x="50.4%" y="0" ry="20" rx="20" width="49.4%" height="49%" />
      <rect x="50.4%" y="51%" ry="20" rx="20" width="49.4%" height="49%" />

      <rect x="0" y="51%" ry="20" rx="20" width="24.25%" height="49%" />
      <rect x="25.2%" y="51%" ry="20" rx="20" width="24.25%" height="49%" />
    </PlaceholderWrapper>
  );
};

export const TableComponentPlaceholder: React.FC<any> = props => {
  const { count } = props;

  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {new Array(count).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x={`${(100 / count) * i}%`}
            y="2%"
            rx="5"
            ry="5"
            width={`${100 / count - 1}%`}
            height="8%"
          />
        );
      })}

      {[...Array(12)].map((_, i) =>
        [...Array(count)].map((_, j) => (
          <rect
            key={`${i}-${j}`}
            x={`${(100 / count) * j}%`}
            y={`${(100 / 15) * i + 15}%`}
            width={`${getRandomArbitrary(0, 100 / count - 1)}%`}
            height={`5%`}
            rx="5"
            ry="5"
          />
        ))
      )}
    </PlaceholderWrapper>
  );
};

export const VacantionsComponentPlaceholder: React.FC<any> = props => {
  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {new Array(25).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="0"
            y={`${(100 / 25) * i}%`}
            rx="0"
            ry="0"
            width="15%"
            height="3%"
          />
        );
      })}

      {[...Array(25)].map((_, i) =>
        [...Array(26)].map((_, j) => (
          <rect
            key={`${i}-${j}`}
            x={`${(100 / 31) * j + 16}%`}
            y={`${(100 / 25) * i}%`}
            width={`${100 / 25 - 1}%`}
            height={`3%`}
            rx="0"
            ry="0"
          />
        ))
      )}
    </PlaceholderWrapper>
  );
};
export const AttendanceComponentPlaceholder: React.FC<any> = props => {
  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {new Array(13).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="0"
            y={`${(100 / 13) * i}%`}
            rx="4"
            ry="4"
            width="9.4%"
            height="7%"
          />
        );
      })}

      {[...Array(13)].map((_, i) =>
        [...Array(28)].map((_, j) => (
          <rect
            key={`${i}-${j}`}
            x={`${(100 / 31) * j + 9.6}%`}
            y={`${(100 / 13) * i}%`}
            width={`${100 / 25 - 1}%`}
            height={`7%`}
            rx="4"
            ry="4"
          />
        ))
      )}
    </PlaceholderWrapper>
  );
};

export const TableWithSearchPagePlaceholder: React.FC = props => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      viewBox={`0 0 ${deviceWidth - 143} ${deviceHeight - 90 - 32}`}
      height="100%"
      width={`${deviceWidth - 143}px`}
      {...props}
    >
      <rect x="0" y="0" rx="20" ry="20" width="100%" height="6%" />
      <rect x="0" y="8%" rx="20" ry="20" width="100%" height="92%" />
    </PlaceholderWrapper>
  );
};

export const ReportsPagePlaceholder: React.FC = props => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      viewBox={`0 0 ${deviceWidth - 143} ${deviceHeight - 90 - 32}`}
      height={`${deviceHeight - 90 - 32}px`}
      width={`${deviceWidth - 143 - 16 - 48}px`}
      {...props}
    >
      <rect x="0" y="0" ry="20" rx="20" width="100%" height="5%" />
      <rect x="0" y="6%" ry="20" rx="20" width="100%" height="94%" />
    </PlaceholderWrapper>
  );
};

export const TimesheetComponentPlaceholder: React.FC<any> = props => {
  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {new Array(16).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="0"
            y={`${(100 / 20) * i}%`}
            rx="0"
            ry="0"
            width="15%"
            height="4%"
          />
        );
      })}

      {[...Array(16)].map((_, i) =>
        [...Array(31)].map((_, j) => (
          <rect
            key={`${i}-${j}`}
            x={`${(100 / 21) * j + 16}%`}
            y={`${(100 / 20) * i}%`}
            width="4%"
            height="4%"
            rx="0"
            ry="0"
          />
        ))
      )}
    </PlaceholderWrapper>
  );
};

export const GridViewPagePlaceholder: React.FC = props => {
  return (
    <PlaceholderWrapper height="100%" width="100%">
      <rect x="0" y="0" ry="20" rx="20" width="24%" height="24%" />
      <rect x="25%" y="0" ry="20" rx="20" width="24%" height="24%" />
      <rect x="50%" y="0" ry="20" rx="20" width="24%" height="24%" />
      <rect x="75%" y="0" ry="20" rx="20" width="24%" height="24%" />

      <rect x="0" y="25%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="25%" y="25%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="50%" y="25%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="75%" y="25%" ry="20" rx="20" width="24%" height="24%" />

      <rect x="0" y="50%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="25%" y="50%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="50%" y="50%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="75%" y="50%" ry="20" rx="20" width="24%" height="24%" />

      <rect x="0" y="75%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="25%" y="75%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="50%" y="75%" ry="20" rx="20" width="24%" height="24%" />
      <rect x="75%" y="75%" ry="20" rx="20" width="24%" height="24%" />
    </PlaceholderWrapper>
  );
};

export const ReportsGridViewPlaceholder: React.FC = props => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper height={`${deviceHeight - 90 - 32}px`} width="100%">
      <rect x="0" y="0" ry="20" rx="20" width="33%" height="33%" />
      <rect x="34%" y="0" ry="20" rx="20" width="33%" height="33%" />
      <rect x="68%" y="0" ry="20" rx="20" width="33%" height="33%" />

      <rect x="0" y="35%" ry="20" rx="20" width="33%" height="33%" />
      <rect x="34%" y="35%" ry="20" rx="20" width="33%" height="33%" />
      <rect x="68%" y="35%" ry="20" rx="20" width="33%" height="33%" />

      <rect x="0" y="70%" ry="20" rx="20" width="33%" height="33%" />
      <rect x="34%" y="70%" ry="20" rx="20" width="33%" height="33%" />
      <rect x="68%" y="70%" ry="20" rx="20" width="33%" height="33%" />
    </PlaceholderWrapper>
  );
};

export const EditTeamFormPlaceholder: React.FC = props => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      viewBox={`0 0 ${deviceWidth - 143} ${deviceHeight - 90 - 32}`}
      height={`${deviceHeight - 90 - 32}px`}
      width={`${deviceWidth - 143}px`}
      {...props}
    >
      <rect x="0" y="0" ry="20" rx="20" width="100%" height="8%" />
      <rect x="0" y="10%" ry="20" rx="20" width="100%" height="20%" />
      <rect x="0" y="32%" ry="20" rx="20" width="100%" height="68%" />
    </PlaceholderWrapper>
  );
};

export const TicketingDetailedViewPlaceholder: React.FC = props => {
  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {new Array(9).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="0"
            y={`${(100 / 9) * i}%`}
            rx="20"
            ry="20"
            width="25%"
            height="10%"
          />
        );
      })}
      <rect x="26%" y="0" ry="20" rx="20" width="56%" height="100%" />
      <rect x="83%" y="0" ry="20" rx="20" width="17%" height="100%" />
    </PlaceholderWrapper>
  );
};

export const ConfigurationFormPlaceholder: React.FC = props => {
  return (
    <PlaceholderWrapper height="32px" width="100%" {...props}>
      
      <rect x="5%" y="0" ry="10" rx="10" width="90%" height="32px" />
      
    </PlaceholderWrapper>
  );
};

