import { useAuth } from 'services/providers/AuthProvider';
import { useEffect } from 'react';

const LogoutRedirect = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout({
      returnTo: `${process.env.REACT_APP_DOMAIN}/login`,
    });
  }, []);

  return <></>;
};

export default LogoutRedirect;
