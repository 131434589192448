import Divider from 'components/Divider';
import styles from './ticketSenderBar.module.less';
import { Input, Typography, Upload } from 'antd';
import { SendIconSVG } from 'Icons/Ticketing/sendIcon';
import { AttachIconSVG } from 'Icons/Ticketing/attachIcon';
import { useContext, useState } from 'react';
import UploadAttachmentItem from './components/UploadAttachmentItem';
import { useAuth } from 'services/providers/AuthProvider';
import { TicketingService } from 'services';
import { useSelector } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { FormattedMessage } from 'react-intl';
import {
  notifyWithError,
  notifyWithErrorOnMaxSizeUploadFile
} from 'utils/notificationsUtils';
import useIsUpdateOngoing from '../../../../../../hooks/useIsUpdateOngoing';
import { RootState, useAppDispatch } from 'redux/store';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { getTicketById } from 'redux/slices/ticketingSlice/actions';
import { useIsDragging } from './utils';
import { LastCommentRefContext } from '..';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import UserAvatar from 'components/Avatar';

const TicketSenderBar = ({}) => {
  const [fileList, setFileList] = useState([]);
  const { selectedTicket } = useSelector((state: RootState) => state.ticketing);
  const [input, setInput] = useState<string>('');

  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { theme } = useThemeContext();
  const { isDragging, stopDragging } = useIsDragging();

  const lastCommentRef = useContext(LastCommentRefContext);

  const allowSendComment = input.length > 0 || fileList?.length > 0;

  const removeFile = fileToRemove => {
    setFileList(files => files.filter(item => item?.uid !== fileToRemove?.uid));
  };
  const { observeUpdateEnd, isUpdateOnGoing } = useIsUpdateOngoing(data => {
    dispatch(setMethodUpdate(true));
    dispatch(getTicketById(selectedTicket?.ticketId));
    setFileList([]);
    setInput('');
  });
  const onFileUpload = uploadedFile => {
    if (fileList && fileList.length > 5 - 1) {
      notifyWithError({
        message: (
          <FormattedMessage
            id="MaximumFilesToUploadErrorMessage"
            values={{ maxFilesPerRequest: 5 }}
          />
        ),
        description: null
      });
      return false;
    }
    if (uploadedFile.size && uploadedFile.size >= 5 * 1000000) {
      notifyWithErrorOnMaxSizeUploadFile(5);
      return false;
    }
    setFileList([...fileList, uploadedFile]);
    return false;
  };

  const onCommentInput = e => {
    setInput(e.target.value);
  };
  const sendComment = e => {
    //ignore sending if shift key is pressed
    if (e.shiftKey) return;

    if (allowSendComment) {
      const formData = new FormData();
      formData.append('ticketId', String(selectedTicket?.ticketId));
      formData.append('message', input);
      fileList?.forEach(file => {
        formData.append('attachments', file as Blob);
      });
      observeUpdateEnd(
        async () => await TicketingService.addTicketComment(formData)
      );
    }
  };

  return (
    <div
      className={styles.senderBar}
      style={
        isUpdateOnGoing
          ? {
              opacity: '0.4',
              pointerEvents: 'none'
            }
          : null
      }
    >
      <Divider spacing={16} />

      {isDragging && (
        <Dragger
          accept="image/*, video/*, .pdf"
          multiple={false}
          onDrop={e => {
            stopDragging();
          }}
          beforeUpload={onFileUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            <FormattedMessage id="DraggerAttachFiles" />
          </p>
          <p className="ant-upload-hint">
            <FormattedMessage id="DraggerAttachFilesHint" />
          </p>
        </Dragger>
      )}
      <div className={styles.senderBarWrapper}>
        <UserAvatar size={26} />
        <div
          className={
            styles.messageBox +
            (input.length > 0
              ? ` ${styles.messageBoxWithInput}`
              : ` ${styles.messageBoxDefault}`)
          }
        >
          <Input.TextArea
            onChange={onCommentInput}
            value={input}
            maxLength={2000}
            onPressEnter={sendComment}
            onPaste={event => {
              const file = event?.clipboardData?.files[0];

              if (file) {
                setFileList(state => [...state, file]);
              }
            }}
            className={styles.inputBar}
            autoSize={{ minRows: 1, maxRows: 4 }}
            onFocus={() => (lastCommentRef as any)?.current?.showLastComment()}
          />
          <div className={styles.messageTools}>
            <>
              <Upload
                itemRender={() => <></>}
                fileList={fileList}
                multiple={true}
                accept="image/*, video/*, .pdf, .doc, .docx, .xls, .xlsx"
                maxCount={5}
                beforeUpload={onFileUpload}
              >
                <Typography.Text className={styles.icon + ` ${theme}`}>
                  <AttachIconSVG />
                </Typography.Text>
              </Upload>
              <Typography.Text
                onClick={sendComment}
                className={
                  !allowSendComment
                    ? styles.icon__disabled
                    : styles.icon + ` ${theme}`
                }
              >
                <SendIconSVG />
              </Typography.Text>
            </>
          </div>
        </div>
      </div>
      <div className={styles.fileListWrapper}>
        {fileList?.map((file, index) => (
          <UploadAttachmentItem
            key={file?.uid}
            file={file}
            onRemove={removeFile}
          />
        ))}
      </div>
    </div>
  );
};
export default TicketSenderBar;
