import { PayloadAction } from '@reduxjs/toolkit';
import { loadState, saveState } from 'context/utils';
import { TimeOffRequestsAndResponse } from '../types';
import { TimeOffPersonalActions } from './actions';

const stateKey = 'timeOffPersonalRequests';
const currentState = loadState(stateKey);
const serializedState = currentState ? JSON.parse(currentState) : null;

export const initialState: TimeOffRequestsAndResponse = {
  pagination: {
    resultSize: serializedState?.pagination?.resultSize || 20,
    pageNumber: 1
  }
};

export const personalTimeOffReducer = (
  state: TimeOffRequestsAndResponse = initialState,
  action: PayloadAction<any>
) => {
  switch (action.type) {
    case TimeOffPersonalActions.UPDATE_TIME_OFF_PERSONAL_TABLE_FILTER:
      const pagination = {
        pagination: {
          ...state.pagination,
          resultSize: action.payload.pagination.resultSize,
          pageNumber: action.payload.pagination.pageNumber
        }
      };
      const updatedState = {
        ...state,
        filterBy: {
          nextApproverIds: action.payload?.filterBy?.nextApproverIds
        },
        ...pagination
      };
      saveState(stateKey, pagination);
      return updatedState;

    case TimeOffPersonalActions.RESET_TIME_OFF_PERSONAL_STATE:
      const resetState = {
        ...initialState,
        pagination: {
          ...initialState.pagination,
          resultSize: state.pagination.resultSize
        }
      };
      saveState(stateKey, resetState);
      return resetState;

    default:
      return state;
  }
};
