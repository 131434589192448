import { createAsyncThunk } from '@reduxjs/toolkit';
import { TicketingService } from '../../../services';
import {
  AssignmentTypes,
  TicketDto,
  TicketPriority,
  TicketStatus,
  TicketType
} from 'pages/Ticketing/types';
import { setPaginationSize } from '../ticketingFilters';

type PaginatedTicketsRequest = {
  filterBy?: {
    priorities: Array<TicketPriority> | null;
    types: Array<TicketType> | null;
    assignTypes: Array<AssignmentTypes>;
    statuses: Array<TicketStatus> | null;
  };
  sortBy?: {
    ticketId: 'asc' | 'desc';
  };
  pagination?: {
    resultSize: number;
    pageNumber: number;
  };
};

type FetchMoreTicketsRequest = PaginatedTicketsRequest & {
  tickets: TicketDto[];
  howMany: number;
};
export const getMyLastTickets = createAsyncThunk(
  '/Ticket/getMyLastTickets',
  async (ticketType: string, { rejectWithValue }) => {
    try {
      const res = await TicketingService.getMyLastTickets({ ticketType });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTicketPriority = createAsyncThunk(
  '/Ticket/updatePriority',
  async (
    ticket: { ticketId: number | string; priority: TicketPriority },
    { rejectWithValue }
  ) => {
    try {
      const res = await TicketingService.updatePriority({
        ticketId: ticket.ticketId,
        priority: ticket.priority
      });
      return res.data as TicketDto;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getAllTickets = createAsyncThunk(
  '/Ticket/getAllTickets',
  async (
    options: PaginatedTicketsRequest = {
      pagination: {
        pageNumber: 1,
        resultSize:
          JSON.parse(localStorage.getItem('tablePagination'))?.[
            'ticketingTable'
          ] || 20
      }
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await TicketingService.getAllTickets(options);
      dispatch(setPaginationSize(options?.pagination?.resultSize));

      return { data: res.data?.responseData, totalCount: res.data?.totalCount };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getTicketById = createAsyncThunk(
  '/Ticket/getTicket',
  async (ticketId: number, { rejectWithValue }) => {
    try {
      const res = await TicketingService.getTicket({ ticketId: ticketId });
      return res.data as TicketDto;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchMoreTickets = createAsyncThunk(
  '/Ticket/getMoreTickets',
  async (options: FetchMoreTicketsRequest, { rejectWithValue, dispatch }) => {
    try {
      const data = options.tickets;
      dispatch(setPaginationSize(options?.howMany));
      const response = await TicketingService.getAllTickets({
        pagination: {
          lastId: data[data.length - 1]?.ticketId,
          lastDate: data[data.length - 1]?.createdOn,
          resultSize: options.howMany
        },
        filterBy: options?.filterBy,
        sortBy: options?.sortBy
      });

      return response.data?.responseData;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
