import * as React from 'react';
import { Button } from 'antd';

import '../buttonStyles.less';

/**
 * Asynchronous buttons with loading state
 */
export const AsyncButton: React.FC<any> = ({ children, ...props }) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Button
      {...props}
      // don't forget to make your onClick async!
      onClick={async (e) => {
        if (props.onClick) {
          // This is the only reliable way to check if a function is asynchronous
          const onClickIsPromise =
            props.onClick.constructor.name === 'AsyncFunction';
          // We only set loading if the function is actually async
          // to avoid useless re-renders
          onClickIsPromise && setLoading(true);
          // We can await onclick even if it's not asynchronous
          // it won't change its behavior
          await props.onClick(e);
          onClickIsPromise && setLoading(false);
        }
      }}
    >
      {children}
    </Button>
  );
};

