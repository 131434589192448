import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { ArrowUnion } from 'Icons/General/arrowUnion';
import { Form, Input, Row, Select, Upload } from 'antd';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { SelectDropdown } from 'components/Dropdown';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import {
  onFinish,
  ParseEmployeeOptions,
  parseEmployeesWithoutTLForOtherTeams
} from 'components/Team/TeamForm/utils';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useAuth } from 'services/providers/AuthProvider';
import { useCompanySettings } from 'settings';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import { useTeamViewContext } from '../TeamViewContext/TeamViewContext';
import './verticalTabelStyles.less';
import { useSafeIntl } from '../../UIComponents/SafeFormattedMessage';
import ImgCrop from 'antd-img-crop';
import { getFileUploadFormData } from '../../../utils/uploadFileRequestWithFormData';
import { TeamsService } from '../../../services';
import { setMethodUpdate } from '../../../redux/slices/methodUpdateSlice';
import {
  notifyWithError,
  notifyWithSuccessfulDataUpdated
} from '../../../utils/notificationsUtils';
import ViewTeamImage from '../../../pages/Team/ViewTeamPage/ViewTeamImage/ViewTeamImage';

const { Option } = Select;

export const EditTeamForm = props => {
  const {
    teamId,
    edit,
    details,
    form,
    checkTeamLead,
    setCheckTeamLead,
    setCheckIfAlreadyInEdit
  } = props;
  const { countryCode } = useCompanySettings();
  //for RO, allow selecting TL, the members that are already TL for other teams
  const displayEmployeesWithAlreadyExistingTLForOtherTeams =
    countryCode === 'RO';
  const displayAllOptionsForTeamLead = countryCode === 'MD';
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const generalRootState = useSelector((state: RootState) => state);
  const [displayedFormFields, setDisplayedFormFields] = useState<any>(null);
  const [imageForm] = Form.useForm();

  const defaultDepartments =
    generalRootState.general.enums &&
    Object.keys(generalRootState?.general?.enums?.Department).length
      ? generalRootState.general.enums.Department
      : [];

  const Teams =
    generalRootState.teams.teams &&
    Object.keys(generalRootState?.teams?.teams).length
      ? generalRootState.teams.teams
      : [];

  const uniqueMembersFromAllTeams = details[0].members.filter(
    (a, i) =>
      details[0].members.findIndex(s => a.employeeId === s.employeeId) === i
  );
  //employees with no teams
  const employeesWithoutTLForOtherTeams = parseEmployeesWithoutTLForOtherTeams(
    uniqueMembersFromAllTeams,
    Teams
  );

  const navigate = useNavigate();
  const employeeOptions = ParseEmployeeOptions(
    displayAllOptionsForTeamLead
      ? uniqueMembersFromAllTeams
      : displayEmployeesWithAlreadyExistingTLForOtherTeams
        ? uniqueMembersFromAllTeams
        : employeesWithoutTLForOtherTeams
  );

  const onFinishFormActions = {
    dispatch
  };

  useEffect(() => {
    if (!edit && details[0]?.leadEmployeeId === null && checkTeamLead) {
      setCheckTeamLead(false);
      setCheckIfAlreadyInEdit(false);
    }
    setCheckIfAlreadyInEdit(true);
    setDisplayedFormFields(Object.keys(details[0]));
    form.setFieldsValue({
      dateCreated: details[0]?.dateCreated
        ? dayjs(details[0]?.dateCreated)
        : null
    });
  }, []);

  const teamLeadSelectRef = useRef(details[0]?.leadEmployeeId);

  const toggleDisplayedFormFields = (formItemName: string) => {
    const shouldFormItemBeHidden = !(
      displayedFormFields && displayedFormFields.includes(formItemName)
    );

    return {
      hidden: shouldFormItemBeHidden
    };
  };

  const ButtonComponent = BaseButton(ButtonSimple);
  const {
    setDisplayMembers,
    displayMembers,
    displayContacts,
    setDisplayContacts
  } = useTeamViewContext();
  const { isMobile } = useMobileContext();

  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);

  const isAdminUserRole = allowPermision(userRole, [
    userRoles.Admin,
    userRoles.HR
  ]);
  const isTeamLead =
    allowPermision(userRole, userRoles.TL) &&
    user.employeeId === details[0]?.leadEmployeeId;
  const intl = useIntl();
  const safeIntl = useSafeIntl();
  const allDepartments = defaultDepartments.includes(details[0].department)
    ? [...defaultDepartments]
    : [...defaultDepartments, details[0].department];
  const leadEmployeePosition =
    details[0].leadEmployeeName.length > 1
      ? details[0].members.find(
          employee => employee.employeeName === details[0].leadEmployeeName
        ).employeePosition
      : null;

  const [fileList, setFileList] = useState([]);

  const uploadProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    beforeUpload(file) {
      setFileList([file]);
      return false;
    }
  };
  // const beforeCrop = (file: File) => validFile(file);
  const uploader = (
    <Upload {...uploadProps} accept=".jpg, .jpeg, .png, .heic, .heif"></Upload>
  );

  const handleFormFinish = async () => {
    try {
      // setLoading("pending");
      let parsedData = new FormData();
      parsedData = getFileUploadFormData(
        null,
        fileList.length > 0
          ? {
              formDataFileName: 'file',
              uploadFiles: fileList
            }
          : null
      );

      parsedData.append('teamId', teamId);

      const res = await TeamsService.updateTeamPicture(parsedData).then(res => {
        if (res.data) {
          dispatch(setMethodUpdate(true));
          notifyWithSuccessfulDataUpdated(
            '',
            <FormattedMessage id="MyProfile_Avatar_send_succes" />
          );
        } else {
          notifyWithError({
            message: <FormattedMessage id="UserProfile_ImageInvalidFormat" />,
            description: (
              <FormattedMessage id="UserProfile_ImageInvalid_Description" />
            )
          });
          return;
        }
      });
    } catch (error) {
      console.error('Error updating profile picture:', error);
      notifyWithError({
        message: 'Error updating profile picture',
        description: error.message
      });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTeamImageModalOpen, setIsTeamImageModalOpen] = useState(false);

  const [teamImage, setTeamImage] = useState(null);

  useEffect(() => {
    setTeamImage(details[0].teamImageUrl);
  }, []);
  return (
    <TableLayoutPage
      displayButton={false}
      style={
        !(displayMembers || displayContacts)
          ? {
              marginTop: '16px',
              position: 'relative',
              zIndex: '1',
              height: isMobile ? 'calc(100% - 80px)' : ''
            }
          : { display: 'none' }
      }
      title={
        <div className={'team-table-header'}>
          <span className={'team-header-name'}>{details[0].name}</span>
          {(isAdminUserRole || isTeamLead) && !isMobile && (
            <span
              className={'team-table-image'}
              onClick={() => {
                setIsTeamImageModalOpen(true);
              }}
            >
              <FormattedMessage id={'team_image'} />
            </span>
          )}
        </div>
      }
      displayToggle={false}
    >
      {/*{isModalOpen  &&*/}
      <ViewTeamImage
        teamImageSrc={teamImage}
        isModalOpen={isTeamImageModalOpen}
        teamName={details[0].name}
        setIsModalOpen={setIsTeamImageModalOpen}
      />
      <Form
        form={imageForm}
        id="editAvatarForm"
        labelWrap
        labelCol={{ span: 10 }}
        onFinish={handleFormFinish}
      >
        <ImgCrop
          rotationSlider
          aspectSlider
          showReset
          modalClassName={`${theme} change_avatar_modal`}
          modalCancel={intl.formatMessage({
            id: 'cancel'
          })}
          resetText={intl.formatMessage({
            id: 'btn_reset'
          })}
          modalOk={intl.formatMessage({
            id: 'save'
          })}
          modalTitle={intl.formatMessage({
            id: 'UpladAvatarCropModalTitle'
          })}
          onModalOk={() => {
            setTimeout(() => {
              imageForm.submit();
            }, 100);
          }}
        >
          {uploader}
        </ImgCrop>
      </Form>

      <div>
        <Form
          form={form}
          id="teamForm"
          labelWrap={true}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={(data: any) => {
            data.leadEmployeeId = teamLeadSelectRef.current;
            data.teamImageUrl = teamImage;
            onFinish('edit', data, teamId, onFinishFormActions);
          }}
          onFinishFailed={validationError =>
            onFormFinishFailedValidation(validationError, dispatch)
          }
        >
          {details.map((item, index) => (
            <div
              className={'team-details'}
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
              key={`team-details-${index}`}
            >
              <Row gutter={[24, 16]}>
                <ColFormItemComponent
                  name="name"
                  label={<FormattedMessage id="label_AddMemberTeamForm_team" />}
                  initialValue={item.name}
                  isDisabled={!edit}
                  {...toggleDisplayedFormFields('name')}
                  className={`
                  ${
                    !edit
                      ? `col-form-item-component-view ${theme}`
                      : `col-form-item-component ${theme} `
                  }`}
                  rules={setFormItemRules({
                    isRequired: edit,
                    requiredMessage: intl.formatMessage({
                      id: 'EditTeamNameValidation'
                    }),
                    hasPattern: true,
                    pattern:
                      /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF\s.,'"-]*$/,
                    patternMessage: intl.formatMessage({
                      id: 'TeamsNameCharactersValidation'
                    }),
                    isMaxLengthRequired: true,
                    maxLengthRequired: 25,
                    maxLengthRequiredMessage: intl.formatMessage({
                      id: '25CharactersInputValidation'
                    })
                  })}
                >
                  <Input disabled={!edit} minLength={1} maxLength={26} />
                </ColFormItemComponent>
                <ColFormItemComponent
                  {...toggleDisplayedFormFields('leadEmployeeName')}
                  label={
                    <FormattedMessage id="label_AddMemberTeamForm_team_lead/PM" />
                  }
                  name="leadEmployeeName"
                  className={`
                  ${
                    !edit
                      ? `col-form-item-component-view ${theme}`
                      : `col-form-item-component ${theme}`
                  }`}
                  initialValue={
                    leadEmployeePosition
                      ? `${item.leadEmployeeName}, ${safeIntl.formatMessageSafely({ id: leadEmployeePosition })}`
                      : ''
                  }
                  isDisabled={!edit}
                >
                  <SelectDropdown
                    placement_bottom={true}
                    suffixIcon={edit ? <ArrowUnion /> : <></>}
                    // className={isAdminUserRole ? 'hover-teamlead-input' : ''}
                    // open
                    onClick={() =>
                      item.leadEmployeeId &&
                      isAdminUserRole &&
                      !edit &&
                      navigate(
                        `/allUsersList/viewProfile?employeeId=${item.leadEmployeeId}`
                      )
                    }
                    disabled={!edit}
                    onSelect={e => {
                      teamLeadSelectRef.current = e;
                    }}
                    onClear={() => (teamLeadSelectRef.current = null)}
                  >
                    {employeeOptions}
                  </SelectDropdown>
                </ColFormItemComponent>
              </Row>
              <Row gutter={[24, 16]}>
                <ColFormItemComponent
                  name="description"
                  label={
                    <FormattedMessage id="label_AddMemberTeamForm_description" />
                  }
                  {...toggleDisplayedFormFields('description')}
                  initialValue={item.description}
                  className={`
                  ${
                    !edit
                      ? `col-form-item-component-view ${theme}`
                      : `col-form-item-component ${theme}`
                  }`}
                  isDisabled={!edit}
                  rules={setFormItemRules({
                    isRequired: false,
                    isMaxLengthRequired: true,
                    maxLengthRequired: 200,
                    maxLengthRequiredMessage: intl.formatMessage({
                      id: 'TeamDescriptionLengthValidation'
                    }),
                    hasPattern: true,
                    pattern: /^(?!\s *$)/,
                    patternMessage: intl.formatMessage({
                      id: 'AssetNameCharactersValidation'
                    })
                  })}
                >
                  <Input disabled={!edit} maxLength={201} />
                </ColFormItemComponent>
                <ColFormItemComponent
                  className={`
                  ${
                    !edit
                      ? `col-form-item-component-view ${theme}`
                      : `col-form-item-component ${theme}`
                  }`}
                  name="department"
                  label={
                    <FormattedMessage id="label_AddMemberTeamForm_department" />
                  }
                  initialValue={item.department}
                  isDisabled={!edit}
                  {...toggleDisplayedFormFields('department')}
                >
                  <SelectDropdown
                    placement_bottom={true}
                    disabled={!edit}
                    suffixIcon={edit ? <ArrowUnion /> : <></>}
                  >
                    {allDepartments.map((department, index) => {
                      return (
                        <Option
                          key={`team-form-department-${
                            item.department ?? department
                          }-${index}`}
                          value={department}
                        >
                          {intl.formatMessage({ id: department })}
                        </Option>
                      );
                    })}
                  </SelectDropdown>
                </ColFormItemComponent>
              </Row>
            </div>
          ))}
        </Form>
      </div>
      <div className="edit-btns-container">
        {!edit ? (
          <div className={'buttons-container'}>
            <ButtonComponent
              className={`view-members-btn ant-btn-primary btn-primary-custom ${theme} `}
              onClick={() => setDisplayContacts(true)}
            >
              <FormattedMessage id="ViewContacts" />
            </ButtonComponent>
            <ButtonComponent
              className={`view-members-btn ant-btn-primary btn-primary-custom ${theme} `}
              onClick={() => setDisplayMembers(true)}
            >
              <FormattedMessage id="ViewMembers" />
            </ButtonComponent>
          </div>
        ) : (
          <>
            <ButtonComponent
              className={`cancel-btn ant-btn ant-btn-default text-bold-normal btn-default-custom ${theme}`}
              onClick={props.onCancelEditDetails}
            >
              <FormattedMessage id="cancel" />
            </ButtonComponent>
            <ButtonComponent
              type="primary"
              className={`text-bold-normal btn-primary-custom ${theme}`}
              onClick={props.onSaveDetails}
            >
              <FormattedMessage id="save" />
            </ButtonComponent>
          </>
        )}
      </div>
    </TableLayoutPage>
  );
};
