import { useRef } from 'react';
import ItemRender from './ItemRender';

const PanelDropDownFilter = ({
  dataForOptions,
  preventDeselectAll,
  selectOption,
  filtervalue,
}) => {
  const listRef = useRef<any>();

  
  return (
    <div
      className="select-panel"
      role="listbox"
      ref={listRef}
    >
      <ul
        className="list-options"
      >
        {Object.entries(dataForOptions).map(([key, value]) => (
          <ItemRender
            key={key}
            optionkey={key}
            value={value}
            filtervalue={filtervalue}
            selectOption={selectOption}
            checked={filtervalue.includes(key)}
            preventDeselectAll={preventDeselectAll}
          />
        ))}
      </ul>
    </div>
  );
};

export default PanelDropDownFilter;
