import { Form, Input, Select } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { onFinish } from './utils';
import { AssetCategoryService } from 'services';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { useDispatch } from 'react-redux';
import { useModalContext } from 'components/Modal/ModalProvider';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import TextArea from 'antd/es/input/TextArea';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { SelectDropdown } from 'components/Dropdown';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { CategoryFormPlaceholder } from 'components/Placeholders';

interface IAssetCategory {}

type AssetCategoryFormProps = {
  scope: string;
  formData?: IAssetCategory;
  categoryId?: number | string;
  categoryType?: string;
  setDisableDelete?: Dispatch<SetStateAction<boolean>>;
};

const { Option } = Select;

const AssetCategoryForm: FC<AssetCategoryFormProps> = ({
  scope,
  categoryId,
  categoryType,
  setDisableDelete
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isAssetTypeDisabled, setIsAssetTypeDisabled] =
    useState<boolean>(false);

  const intl = useIntl();

  const { toggleModal } = useModalContext();
  const [assetsCount, setAssetsCount] = useState<number>(0);
  const [isViewMode, setIsViewMode] = useState<boolean>();
  const [newCategoryId, setNewCategoryId] = useState<number | string>(
    categoryId
  );
  const [newCategoryParams, setNewCategoryParams] = useState({
    categoryId: newCategoryId,
    extended: true
  });

  const disableDeleteBtn = (shouldBeDisabled: boolean) =>
    !!setDisableDelete
      ? setDisableDelete(shouldBeDisabled)
      : console.log('no disable method was provided');

  const assetCategoryTypes = ['Hardware', 'Software'];

  const { data, status } = useApiRequestHook<any>(
    AssetCategoryService.getAssetCategory,
    'true',
    [],
    newCategoryParams,
    null,
    [],
    scope === 'add'
  );

  useEffect(() => {
    if (status === 'success') {
      setNewCategoryId(null);
    }
  }, [status]);

  useEffect(() => {
    setNewCategoryParams({ categoryId: newCategoryId, extended: true });
  }, [newCategoryId]);

  const onFinishFormActions = { dispatch, toggleModal };

  useEffect(() => {
    const fields = {
      name: data.name,
      details: data.details,
      assetType: data.assetType
    };

    if (scope === 'add') {
      setNewCategoryId(null);
      setIsViewMode(false);
      if (
        !categoryType ||
        (categoryType !== 'Hardware' && categoryType !== 'Software')
      ) {
        return;
      }

      form.setFieldValue('assetType', categoryType);
      return;
    }

    if (scope === 'edit' && data && data.assetsCount > 0) {
      setIsAssetTypeDisabled(true);
      setNewCategoryId(categoryId);
    }
    if (scope === 'edit') {
      setIsViewMode(false);
      setNewCategoryId(categoryId);
    }
    if (scope === 'view') {
      setIsAssetTypeDisabled(true);
      setIsViewMode(true);
      setNewCategoryId(categoryId);
    }

    disableDeleteBtn(
      scope === 'remove' && data && data.assetsCount > 0 ? true : false
    );

    if (status !== 'success') {
      return;
    }

    setAssetsCount(data.assetsCount);
    form.setFieldsValue(fields);
  }, [data, status, scope]);

  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();

  return (
    <ComponentWithStatus
      status={scope === 'add' ? 'success' : status}
      // status="pending"
      Placeholder={<CategoryFormPlaceholder />}
    >
      <Form
        form={form}
        id="assetCategoryForm"
        layout="horizontal"
        labelAlign="left"
        labelCol={{ span: 6 }}
        onFinish={data => {
          if (scope === 'remove' && assetsCount) {
            console.log('can not remove this category');
            return;
          }

          onFinish(scope, data, categoryId, onFinishFormActions);
        }}
        onFinishFailed={validationError =>
          onFormFinishFailedValidation(validationError, dispatch)
        }
      >
        {scope === 'remove' ? (
          <>
            {assetsCount ? (
              <p
                style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}
              >
                <FormattedMessage id="UnassignAssetsBeforeDeletingText" />
              </p>
            ) : (
              <p
                style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}
              >
                <FormattedMessage
                  id="CategoryRemoveConfirmText"
                  values={{
                    categoryName: data.name
                  }}
                />
              </p>
            )}
          </>
        ) : (
          <>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="label_InventoryCategoryForm_name" />}
              name="name"
              isReadOnly={
                isMobile && isViewMode
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'CategoryNameValidation'
                }),
                hasPattern: true,
                pattern: /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF ]+$/,
                patternMessage: intl.formatMessage({
                  id: 'InventoryCategoryNameCharactersValidation'
                }),
                isMaxLengthRequired: true,
                maxLengthRequired: 25,
                maxLengthRequiredMessage: intl.formatMessage({
                  id: '25CharactersInputValidation'
                })
              })}
            >
              <Input disabled={isViewMode} minLength={1} maxLength={26} />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="label_InventoryCategoryForm_type" />}
              name="assetType"
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'CategoriesTypeValidation'
                })
              })}
            >
              {!isViewMode ? (
                <SelectDropdown
                  showSearch={false}
                  allowClear={false}
                  disabled={isViewMode}
                  placement_bottom={true}
                >
                  {assetCategoryTypes.map((category, index) => {
                    const categoryTypes = category;

                    return (
                      <Option key={index} value={category}>
                        {categoryTypes}
                      </Option>
                    );
                  })}
                </SelectDropdown>
              ) : (
                <Input disabled={isViewMode} minLength={1} maxLength={26} />
              )}
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              isReadOnly={
                isMobile && isViewMode
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              label={
                <FormattedMessage id="table_InventoryCategoryCol_Details" />
              }
              name="details"
              rules={setFormItemRules({
                isRequired: false,
                isMaxLengthRequired: true,
                maxLengthRequired: 500,
                maxLengthRequiredMessage: intl.formatMessage({
                  id: 'AssetDetailsLengthValidation'
                })
              })}
            >
              <TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: isMobile ? 3 : 4
                }}
                minLength={1}
                maxLength={501}
                disabled={isViewMode}
              />
            </ColFormItemComponent>
          </>
        )}
      </Form>
    </ComponentWithStatus>
  );
};

export default AssetCategoryForm;
