import { ColumnsType } from 'antd/es/table';
import { getCurrentEmployeePosition } from 'components/Employee/EmployeeForm/utils';
import dayjs from 'dayjs';
import { getTitleColumns } from 'components/TitlesColumns/utils';
import { titlesKeysofColumnsMembers } from './utils';
import { Link } from 'react-router-dom';
export const columnsMobileTeamView = (
  data = null,
  role,
  params,
  isModalOpen,
  setIsOpen,
  stateActions,
  dataSource
): ColumnsType<any> => {
  const tableColumns = [
    {
      title: getTitleColumns('employeeName', 'members'),
      dataIndex: 'employeeName',
      width: '50%',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        return ['Admin', 'HR'].includes(role) ? (
          // <Link
          //   to={`/allUsersList/viewProfile?employeeId=${record.employeeId}`}
          // >
          //   {record?.employeeName}
          // </Link>
          <p className={'ellipsis-cell-text'}>{record?.employeeName}</p>
        ) : (
          <p className={'ellipsis-cell-text'}>{record?.employeeName}</p>
        );
      },
    },
    {
      title: getTitleColumns('employeePosition', 'members'),
      ellipsis: {
        showTitle: false,
      },
      dataIndex: 'employeePosition',
      render: (_, record) => (
        <span>{getCurrentEmployeePosition(record?.employeePosition)}</span>
      ),
      width: '50%',
    },
  ];
  const dataSourceKeys = dataSource.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter((key) =>
    titlesKeysofColumnsMembers.some((item) => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  return tableColumns.filter(checkForDataDisplay);
};
