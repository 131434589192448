import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Col, ColProps, Form, FormItemProps } from 'antd';
import { FC } from 'react';

import './ColFormItemComponent.less';

interface IColFormItemComponentProps extends FormItemProps, ColProps {
  children?: any;
  onReset?: any;
  isDisabled?: boolean;
  isReadOnly?: string;
  hideWhen?: () => boolean;
  valuePropName?: string;
  wrapperCol?: any;
  validateTrigger?: any;
  initialValue?: any;
  order?: number;
}

const { Item } = Form;

const ColFormItemComponent: FC<IColFormItemComponentProps> = ({
  children,
  isDisabled,
  isReadOnly,
  hideWhen = () => false, //pass function which hides the field on certain conditions if the condition returns TRUE
  valuePropName,
  wrapperCol,
  validateTrigger,
  initialValue,
  order,
  ...props
}) => {
  const { theme } = useThemeContext();

  const disabledClass = isDisabled ? 'disabled' : '';
  const userClass = props.className ?? '';

  const className = isReadOnly
    ? `${isReadOnly} ${disabledClass} ${theme}`
    : `col-form-item-component ${theme} ${disabledClass} ${userClass}`;

  return (
    !hideWhen() && (
      <Col
        {...props}
        xs={{ span: 24 }}
        sm={24}
        md={24}
        lg={props.span ? props.span : 12}
        xl={props.span ? props.span : 12}
        style={{
          order: order ? order : undefined,
        }}
      >
        <Item
          className={className}
          label={props.label}
          wrapperCol={wrapperCol}
          valuePropName={valuePropName}
          validateTrigger={validateTrigger}
          initialValue={initialValue}
          {...props}
        >
          {children}
        </Item>
      </Col>
    )
  );
};
export default ColFormItemComponent;
