import { downloadFile } from 'utils/downloadFiles';
import { IRequestError } from 'services/types';
import { Buffer } from 'buffer';
import { FormattedMessage } from 'react-intl';
import { convertToLocalDate } from 'components/DateParser';

export function computeHoursAndMinutes(record) {
  const parseStartDate = convertToLocalDate(record.startDate).second(0);
  const parseEndDate = convertToLocalDate(record.endDate).second(0);

  const clockInOutDifference = Math.round(
    parseEndDate.diff(parseStartDate, 'minute', true)
  );

  const clockInOutHoursSpentDiff = parseEndDate.diff(parseStartDate, 'hours');

  const clockInOutRemainedMinutesDiff = clockInOutDifference % 60;
  let hoursResults =
    clockInOutHoursSpentDiff + 'h ' + clockInOutRemainedMinutesDiff + 'm';
  return hoursResults;
}

export const downloadTimeOffRequestTemplate = (
  templateRequest: Promise<{
    data: unknown;
    error: IRequestError;
    headers?: unknown;
  }>
) =>
  templateRequest.then((res) => {
    if (!res.data || res.error) {
      return;
    }

    const byteArray = new Uint8Array(res.data as any);
    const blob = new Blob([byteArray]);
    const url = window.URL.createObjectURL(blob);
    const fileName = Buffer.from(res.headers['content-disposition'], 'base64');
    downloadFile(url, fileName);
  });

export const timeOffRequestTableFilters = [
  {
    name: 'Status',
    filters: [
      'AllStatuses',
      'AwaitingDecision',
      'Unfinished',
      'Pending',
      'Approved',
      'Confirmed',
      'Revocation',
      'Draft',
    ],
  },
  {
    name: 'Types',
    filters: [
      'AllTypes',
      'Vacation',
      'Medical',
      'Paternity',
      'Maternity',
      'Childcare',
      'WorkFromHome',
      'ForgotBadge',
      'Unpaid',
      'PersonalLeave',
      'LeaveOffsetting',
      'Overtime',
      'BloodDonation',
      'Bereavement',
      'Marriage',
    ],
  },
  {
    name: 'Period',
    filters: ['AllPeriods', 'Today', 'ThisWeek', 'ThisMonth', 'range'],
  },
];
export const titlesKeyColumnsPersonalRequests = [
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Requester" />,
    dataIndex: 'requestedForEmployee',
  },
  {
    title: <FormattedMessage id="teams" />,
    dataIndex: 'teams',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Type" />,
    dataIndex: 'publicName',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Amount" />,
    dataIndex: 'daysTaken',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_From" />,
    dataIndex: 'startDate',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Until" />,
    dataIndex: 'endDate',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Status" />,
    dataIndex: 'status',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Next_Approver" />,
    dataIndex: 'primaryApproverEmployee',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Notes" />,
    dataIndex: 'notes',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_SubmittedOn" />,
    dataIndex: 'requestedDate',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Documents" />,
    dataIndex: 'templateForRequest',
  },
];
