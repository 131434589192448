import { Tooltip, Typography } from 'antd';
import { headerTranslations } from 'pages/TimeManagement/TimeSheet/utils';

const { Text } = Typography;

export const addingAdditionalColumns = (
  currentColumns,
  newColumns = [{}],
  countryCode: string
) => {
  const additionals = newAggregatedColumns(currentColumns, countryCode);

  return newColumns.map(newValues => {
    const filteredAdditionals = additionals.filter(
      allAdditionalColumns => newValues === allAdditionalColumns.key
    );

    if (filteredAdditionals.length === 0) {
      return {}; // default value
    }

    return filteredAdditionals.reduce((acc, cur) => ({ ...acc, ...cur }), {});
  });
};

export const newAggregatedColumns = (data, countryCode: string) => {
  const isRO = countryCode === 'RO';
  return Object.entries(data).map(([key, value]) => {
    return {
      title: (
        <div className="cell-padding">
          <Tooltip title={headerTranslations[value as string] || value}>
            <Text
              className={`text-bold-normal text-align-center text_color_timesheet_text line-clamp-2 line-normal`}
            >
              {headerTranslations[value as string] || value}
            </Text>
          </Tooltip>
        </div>
      ),
      key: value as string,
      className: `column_additional_timesheet more_column`,
      width: 120,
      render: (_, record) => ({
        cellRowClassName: `more`,
        /*REMARK FOR THE FUTURE TO AVOID WASTING TIME AS I DID: for Jivy, the new agg columns (the ones that are added via filters) 
          take their source from data.aggregatedColumns while gambit takes from data.torColumns AND data.aggregatedColumns*/
        content: (
          <div className="additional_cell" data-col={value as string}>
            <Text className={`text-medium-normal text_color_timesheet_text`}>
              {!isRO
                ? record[value as string]
                : record[value as string] ?? record[value as string]}
            </Text>
          </div>
        )
      })
    };
  });
};
