import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { SelectDropdown } from 'components/Dropdown';
import { useModalContext } from 'components/Modal/ModalProvider';
import {
  onFinish,
  parseAssignOptions
} from 'components/Software/SoftwareForm/utils';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateAssetsStore } from 'redux/slices/assetsSlice';
import { setFormValidation } from 'redux/slices/methodUpdateSlice';
import { AssetsService, TeamsService } from 'services';
import { useCompanySettings } from 'settings';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';

type AssignProps = {
  scope: string;
  assetId: number | null;
  assignPerson?: object | null;
};

const { Option } = Select;

const AssignForm: FC<AssignProps> = ({ scope, assetId, assignPerson }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { countryCode } = useCompanySettings();
  const isRO = countryCode === 'RO';
  const { toggleModal } = useModalContext();
  const [assignedId, setAssignedId] = useState<string | number>(null);
  const [assignedName, setAssignedName] = useState<string>('');
  const [isScopeDeassigned, setIsScopeDeassigned] = useState<boolean>(false);
  const [selectedTemplateFileName, setSelectedTemplateFileName] =
    useState<string>(null);
  const [options, setOptions] = useState<ReactNode>(null);
  const [inventoryTemplates, setInventoryTemplates] = useState<any>([]);
  const [dropDownIsOpen, setDropDownIsOpen] = useState<boolean>(false);
  const [assetResult, setAssetResult] = useState<any>(null);

  const [status, setStatus] = useState('pending');

  const intl = useIntl();
  const { theme } = useThemeContext();

  const assignType =
    scope === 'assignColleague'
      ? 'Employee'
      : scope === 'assignTeam'
        ? 'Team'
        : '';

  const assetParams = { assetId, extended: true };

  useEffect(() => {
    const fetchAssetsById = () => {
      setStatus('pending');
      AssetsService.getAsset('true', {
        assetId: assetId.toString(),
        extended: true
      })
        .then(res => {
          if (res) {
            setAssetResult(res);
          }
          setStatus('success');
        })
        .catch(err => {
          console.error(err);
          setStatus('error');
        });
    };

    fetchAssetsById();
  }, []);

  useEffect(() => {
    setIsScopeDeassigned(scope === 'deassignAsset' ? true : false);

    if (status !== 'success') {
      return;
    }

    parseAssignOptions(scope, {
      assigneeId: assetResult.data?.assigneeId,
      assigneeName: assetResult.data?.assigneeName
    }).then(res => {
      setOptions(res);
    });

    const fields = {
      assigneeId: assetResult.data.assigneeId ?? '',
      assignerId: assetResult.data.assigneeId ?? ''
    };

    setAssignedId(assetResult.data.assigneeId);
    setAssignedName(assetResult.data.assigneeName);

    form.setFieldsValue(fields);
  }, [status, scope]);

  useEffect(() => {
    const updateInventoryTemplates = async () => {
      const inventoryTemplates = await AssetsService.getInventoryTemplates();
      setInventoryTemplates(
        !inventoryTemplates.error ? inventoryTemplates.data : []
      );
    };

    updateInventoryTemplates();
  }, []);

  const onFinishFormActions = {
    dispatch,
    toggleModal
  };
  const getEmployeeId = async () => {
    let employeeId = assignedId;

    if (scope === 'assignTeam') {
      const team = await TeamsService.getTeam(null, {
        teamId: assignedId,
        extended: 'false'
      });

      if (team.error) {
        return employeeId;
      }

      employeeId = team.data?.leadEmployeeId;
    }

    return employeeId;
  };
  return (
    <Form
      form={form}
      name="basic"
      id="AssignForm"
      labelAlign="left"
      labelWrap
      labelCol={{ span: 8, offset: 1 }}
      wrapperCol={{ span: 16 }}
      onFinish={async data => {
        const { assigneeId, assignerId, generateDocument, ...assetFormData } =
          data;
        // const userEmail = await getEmployeeEmail();
        dispatch(setFormValidation(true));

        const employeeId = await getEmployeeId();

        onFinish(
          scope,
          {
            assignType,
            [isScopeDeassigned === false ? 'assigneeId' : 'assignerId']:
              assignedId,
            generateDocument: generateDocument ? true : false,
            ...assetFormData
          },
          assetId,
          {
            ...onFinishFormActions,
            triggerOtherActionsWithResult: () => {
              dispatch(
                updateAssetsStore({
                  assetId,
                  assignType: assignType,
                  assigneeId: isScopeDeassigned === false ? assigneeId : null,
                  assigneeName:
                    isScopeDeassigned === false ? assignedName : null
                })
              );
            }
          },
          {
            fileName: selectedTemplateFileName,
            employeeId: employeeId,
            teamId: assignType === 'Team' ? assignedId : null
          }
        );
      }}
      onFinishFailed={validationError =>
        onFormFinishFailedValidation(validationError, dispatch)
      }
    >
      <ColFormItemComponent
        span={24}
        label={
          scope === 'assignColleague'
            ? intl.formatMessage({ id: 'label_AssignForm_Colleague' })
            : scope === 'assignTeam'
              ? intl.formatMessage({ id: 'label_AssignForm_Team' })
              : intl.formatMessage({ id: 'AssignedTo' })
        }
        // isReadOnly={`col-form-item-component-view ${theme}`}
        name={!isScopeDeassigned ? 'assigneeId' : 'assignerId'}
        initialValue={isScopeDeassigned ? assignedId : ''}
      >
        <>
          {!isScopeDeassigned ? (
            <SelectDropdown
              placement_bottom={true}
              value={assignedId}
              disabled={isScopeDeassigned}
              onChange={(e, option: any) => {
                setAssignedId(e);

                const assignedName = option.children.split(',')[0].trim();
                setAssignedName(assignedName);
              }}
            >
              {options}
            </SelectDropdown>
          ) : (
            <ColFormItemComponent
              span={24}
              isReadOnly={`col-form-item-component-view ${theme}`}
            >
              <Input
                autoComplete="off"
                disabled
                value={assignPerson?.toString()}
              />
            </ColFormItemComponent>
          )}
        </>
      </ColFormItemComponent>
      <ColFormItemComponent
        span={24}
        label={<FormattedMessage id="label_AssignForm_Notes" />}
        name="notes"
        initialValue={
          isScopeDeassigned
            ? assignPerson && Object.keys(assignPerson).length
              ? assignPerson[0].notes
              : ''
            : ''
        }
      >
        <TextArea
          autoSize={{
            minRows: 3,
            maxRows: 4
          }}
        />
      </ColFormItemComponent>
      <ColFormItemComponent
        span={24}
        label={<FormattedMessage id="label_AssignForm_Generate_document" />}
        valuePropName="checked"
        name="generateDocument"
        initialValue={false}
      >
        <SelectDropdown
          className="form-select generate_document_field"
          placement_bottom={true}
          dropdownStyle={{ animationDuration: '0s' }}
          onDropdownVisibleChange={() => setDropDownIsOpen(!dropDownIsOpen)}
          getPopupContainer={container => container}
          onChange={e => {
            setSelectedTemplateFileName(e.slice(0, -2));
          }}
        >
          {inventoryTemplates.map((template, index) => (
            <Option key={`${template}-${index}`}>
              {intl.formatMessage({
                id:
                  template && typeof template === 'string'
                    ? template
                      .substring(0, template.length - 5)
                      .split(' ')
                      .join(' ')
                      .replace(/\s/g, '')
                    : template
              })}
            </Option>
          ))}
        </SelectDropdown>
      </ColFormItemComponent>
    </Form>
  );
};

export default AssignForm;
