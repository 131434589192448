import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';

import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { MultipleDropDownFilter } from 'components/UIComponents/MultipleDropDownFilter';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import useTimeOffPolicies from 'hooks/useTimeOffPolicies';
import { ReportService } from 'services';
import {
  IAvailableReports,
  IReport
} from 'services/providers/ReportProvider/types';
import { useReportsFilterContext } from '../ReportsFilterContext';
import SavePresetModal from '../ReportsSavePresetModal';
import './ReportsDetailsToolbar.less';
import {
  StatusEmployeeFilter,
  generateBodyParamsByFilters,
  setColumnsToGenerate
} from './utils';
import SingleSelectDropdown from 'components/UIComponents/SingleSelectDropdown';
import useOutsideClick from 'hooks/useOutsideClick.hook';
import { RequestStatusFilterOptions } from '../../../pages/TimeManagement/ColleaguesTimeOffRequests/ColleaguesTimeOffToolbar/utils';

export const ReportsDetailsToolbar = ({
  state,
  defaultFilterOptions,
  cols,
  reportType
}) => {
  const location = useLocation();
  const intl = useIntl();
  const { theme } = useThemeContext();
  const { isMobile } = useMobileContext();

  const [optionsDisplaySelectedColumns, setOptionsDisplaySelectedColumns] =
    useState([]);
  const [selectedOptionsRequestType, setSelectedOptionsRequestType] = useState(
    defaultFilterOptions
      .filter(item => item.columnName === 'RequestType')
      .flatMap(item => item.filterOptions.split(',').map(item => item))
  );
  const [selectedOptionsEmployeeStatus, setSelectedOptionsEmployeeStatus] =
    useState(
      defaultFilterOptions
        .filter(item => item.columnName === 'EmployeeStatus')
        .flatMap(item => item.filterOptions.split(',').map(item => item))
    );

  const [selectedOptionsColumns, setSelectedOptionsColumns] = useState(
    cols || []
  );
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState(
    state?.range || null
  );
  const [showSavePreset, setShowSavePreset] = useState<boolean>(false);

  const {
    presetTableFilters,
    setRequestTypeFilter,
    setEmployeeStatusFilter,
    setRangeFilter,
    rangeFilter,
    setDefaultRangeFilter,
    setColumnsFilter,
    setGenerateAllByFilters,
    generateAllByFilters
  } = useReportsFilterContext();

  const { mapPolicies } = useTimeOffPolicies();

  const datePeriodLabel = intl.formatMessage({ id: 'DatePeriod' });
  const filterByLabel = intl.formatMessage({ id: 'FilterBy' });
  const defaultLabel = `${filterByLabel}: ${datePeriodLabel}`;

  // AntD Components
  const { RangePicker } = DatePicker;

  //  extract state  from redux
  const rootSelector = useSelector((data: RootState) => data);

  let { data: reports } = useApiRequestHook<IAvailableReports[]>(
    ReportService.getAvailableReports,
    null,
    [],
    {}
  );

  //  get the path name of page
  const path = location.pathname.split('/').pop();
  useEffect(() => {
    //set options for filter by columns
    if (reports && reports.some(report => report.allColumnsAvailable)) {
      setOptionsDisplaySelectedColumns(
        getAvailableColumnsForCurrentRaport(reports, path)
      );
    }
  }, [reports]);

  useEffect(() => {
    if (state?.range) {
      setRangeFilter(state.range);
      if (state.range.startDate !== null && state.range.endDate !== null) {
        setDateRangeFilterValue([
          dayjs(state.range.startDate),
          dayjs(state.range.endDate)
        ]);
      } else {
        setRangeFilter(null);
      }
    }
  }, [state?.requestType]);

  // extract available columns which will be options for displayselectedfiltercolumns
  const getAvailableColumnsForCurrentRaport = (reportsData, current_path) => {
    const report = reportsData?.find(item => item.reportName === current_path);
    if (report) {
      return report.allColumnsAvailable.reduce((result, column) => {
        result[column.name] = column.name;
        return result;
      }, {});
    } else {
      return {}; // Return an empty object if the report is not found
    }
  };

  const displaySelected = () => {
    // Set selected options from filters to context
    setRequestTypeFilter(selectedOptionsRequestType);
    setEmployeeStatusFilter(selectedOptionsEmployeeStatus);

    // Set columns to generate from selected options
    const selectedColumnsValues = setColumnsToGenerate(selectedOptionsColumns);
    setColumnsFilter(selectedColumnsValues);

    // Generate the request body params
    const bodyParams = generateBodyParamsByFilters(
      presetTableFilters?.reportType || state?.reportType,
      selectedColumnsValues,
      selectedOptionsRequestType,
      selectedOptionsEmployeeStatus,
      rangeFilter
    );

    setGenerateAllByFilters(bodyParams);
  };

  // save selected filters to preset
  const savePresetFilters = () => {
    displaySelected();
    setShowSavePreset(true);
  };
  const requestTypeOptionData = mapPolicies();

  //get range date of datepicker filter
  const onCalendarChange = useCallback(
    dates => {
      setDateRangeFilterValue(dates);
      setDefaultRangeFilter(dayjs(dates));

      if (dates === null) {
        setRangeFilter({
          startDate: null,
          endDate: null
        });
      } else {
        if (dates && dates.length > 1 && dates[0] && dates[1]) {
          setRangeFilter({
            startDate: dayjs(dates[0].startOf('day')).toDate(),
            endDate: dayjs(dates[1].endOf('day')).toDate()
          });
        }
      }
    },
    [setDefaultRangeFilter, setRangeFilter]
  );
  const AllTypes = { SelectedAllTypes: 'AllTypes' };
  const AllStatuses = { SelectedAllStatuses: 'AllStatuses' };
  const AllColumns = { SelectedAllStatuses: 'AllColumns' };

  const [isDropped, setIsDropped] = useState<boolean>(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const ddRef = useRef<HTMLFieldSetElement | null>(null);
  useOutsideClick(
    ddRef,
    () => isDropped && !isPickerOpen && setIsDropped(false)
  );

  return (
    <>
      <SavePresetModal
        isEdit={state ? state.isEdit : false}
        selectedColumns={selectedOptionsColumns}
        showSavePreset={showSavePreset}
        setShowSavePreset={setShowSavePreset}
        defaultPresetName={state ? state.presetName : ''}
        defaultPresetId={state ? state.presetId : null}
        presets={state ? state.presets : []}
      />
      <SearchToolbarComponent
        key="ReportDetails"
        displaySearchIcon={false}
        displaySearchInput={false}
        children={
          <div className="search-toolbar_filters_container">
            {reportType !== 'PersonalLeaves' ? (
              <>
                {requestTypeOptionData && (
                  <MultipleDropDownFilter
                    placeholder={intl.formatMessage({ id: 'RequestType' })}
                    dataForOptions={requestTypeOptionData}
                    textAllSelect={AllTypes}
                    dropdownAlign={{ offset: [53, -14] }}
                    value={selectedOptionsRequestType}
                    onChangeFunction={setSelectedOptionsRequestType}
                    instanceId="RequestType"
                  />
                )}
              </>
            ) : null}

            <MultipleDropDownFilter
              placeholder={intl.formatMessage({ id: 'employeeStatus' })}
              dataForOptions={StatusEmployeeFilter}
              textAllSelect={AllStatuses}
              dropdownAlign={{ offset: [53, 0] }}
              value={selectedOptionsEmployeeStatus}
              onChangeFunction={setSelectedOptionsEmployeeStatus}
              instanceId="EmployeeStatus"
            />

            <SingleSelectDropdown
              className="dd-reports-date-range"
              dropdownAlign={{ offset: [53, 0] }}
              label={
                rangeFilter?.startDate
                  ? `${datePeriodLabel}: ${dayjs(rangeFilter.startDate).format(
                      'DD/MM/YYYY'
                    )} - ${dayjs(rangeFilter.endDate).format('DD/MM/YYYY')}`
                  : defaultLabel
              }
              isDropped={isDropped}
              ref={ddRef}
              theme={theme}
              value={rangeFilter as string}
              onDropChange={() => setIsDropped(!isDropped)}
            >
              <li className="dd__item">
                <RangePicker
                  popupClassName={theme}
                  dropdownClassName={theme}
                  bordered={false}
                  inputReadOnly={true}
                  separator={false}
                  className="reports-picker"
                  format="DD/MM/YYYY"
                  size="small"
                  value={dateRangeFilterValue ?? null}
                  onOpenChange={(status: boolean) => setIsPickerOpen(status)}
                  onChange={dates => {
                    onCalendarChange(dates);
                    setIsDropped(!isDropped);
                    setIsPickerOpen(false);
                  }}
                />
              </li>
            </SingleSelectDropdown>

            <MultipleDropDownFilter
              placeholder={intl.formatMessage({ id: 'Columns' })}
              // textAllSelect={AllColumns}
              dataForOptions={optionsDisplaySelectedColumns}
              dropdownAlign={{ offset: [53, isMobile ? null : 0] }}
              value={selectedOptionsColumns}
              onChangeFunction={setSelectedOptionsColumns}
              instanceId="Columns"
            />
          </div>
        }
        buttons={[
          {
            text:
              state && state.isEdit ? (
                <FormattedMessage id="saveChanges" />
              ) : (
                <FormattedMessage id="savePreset" />
              ),
            show: true,
            action: () => savePresetFilters()
          },
          {
            text: <FormattedMessage id="btnText_generate" />,
            show: true,
            action: () => displaySelected()
          }
        ]}
      />
    </>
  );
};
