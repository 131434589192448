import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { SelectDropdown } from 'components/Dropdown';
import { handleDecimalsForIntegerValue } from 'components/Hardware/HardwareForm/utils';
import { useModalContext } from 'components/Modal/ModalProvider';
import { AssetFormPlaceholder } from 'components/Placeholders';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { getCategoryOptions } from 'pages/Inventory/Categories/utils';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  addNewItemToAssets,
  updateAssetsStore
} from 'redux/slices/assetsSlice';
import { setFormValidation } from 'redux/slices/methodUpdateSlice';
import { RootState } from 'redux/store';
import { AssetsService } from 'services';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import SoftwareHistory from './SoftwareHistory';
import {
  onFinish,
  ParseOptionsFrequency,
  useParseCurrencyOptions
} from './utils';
import {
  allowPermision,
  getCurrentUserRole,
  userRoles
} from '../../../utils/userManagement';
import { useAuth } from '../../../services/providers/AuthProvider';

interface ISoftware {}

type SoftwareFormProps = {
  scope: string;
  formData?: ISoftware;
  assetId?: number | string;
  assignPerson?: object | null;
};

const SoftwareForm: React.FC<SoftwareFormProps> = ({
  scope,
  assetId,
  assignPerson
}) => {
  const { isMobile, deviceWidth } = useMobileContext();
  const [isFormDisabled, setIsFormDisabled] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [showHistoryTable, setShowHistoryTable] =
    React.useState<boolean>(false);
  const [historyData, setHistoryData] = React.useState<any[]>([]);
  const [data, setData] = React.useState(null);
  const [price, setPrice] = React.useState<string>(null);

  const intl = useIntl();
  const none = intl.formatMessage({ id: 'None' });
  const monthlyFrequency = intl.formatMessage({ id: 'Monthly' });
  const yearlyFrequency = intl.formatMessage({ id: 'Yearly' });
  const oneTimeFrequency = intl.formatMessage({ id: 'OneTime' });
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const hasPermissionToSeExtended = allowPermision(userRole, [
    userRoles.Admin,
    userRoles.Accountant
  ]);
  const initialFrequency: string[] = ['None', 'Monthly', 'Yearly', 'OneTime'];
  const defaultFrequency: string[] = [
    none,
    monthlyFrequency,
    yearlyFrequency,
    oneTimeFrequency
  ];
  const { toggleModal } = useModalContext();
  const currencies = useParseCurrencyOptions();

  const categories =
    hasPermissionToSeExtended && getCategoryOptions({ assetType: 'Software' });

  const assetParams = { assetId: assetId, extended: true };

  const { data: assetResult, status } = useApiRequestHook<any>(
    AssetsService.getAsset,
    'true',
    [],
    assetParams,
    null,
    [],
    !assetId // ignoreHookExecution is true when assetId is 0
  );

  React.useEffect(() => {
    const getHistory = async () => {
      const assetHistoryResponse = await AssetsService.getAssetHistory({
        assetId: assetId as number
      });
      setHistoryData(assetHistoryResponse?.data ?? []);
    };

    if (hasPermissionToSeExtended) getHistory();
  }, [assetId]);

  const generalRootState = useSelector((state: RootState) => state);

  const generalRootStateExists =
    generalRootState.general && Object.keys(generalRootState.general).length;

  const generalRootStateEnums = generalRootStateExists
    ? generalRootState.general.enums
    : [];

  const frequenciesValues = generalRootStateExists
    ? generalRootStateEnums.PurchaseFrequency
    : defaultFrequency;
  const frequencies = ParseOptionsFrequency(frequenciesValues);

  const noSelectedFieldText = intl.formatMessage({
    id: 'label_SoftwHardForm_placeholder_None'
  });

  React.useEffect(() => {
    form.setFieldValue('currency', 'EUR');

    if (scope === 'add') {
      return;
    }

    setIsFormDisabled(scope === 'view' ? true : false);

    if (status !== 'success') {
      return;
    }

    const assetData = assetResult;

    setData(assetData);

    const fields = {
      model: assetData?.model,
      categoryId: assetData?.categoryName || noSelectedFieldText,
      details: assetData?.details,
      price: assetData?.price,
      type: assetData?.type,
      currency: assetData?.currency || 'EUR',
      frequency: intl.formatMessage({
        id: `${assetData?.frequency}`,
        defaultMessage: 'None'
      }),
      assigneeName: assetData?.assigneeName
    };

    form.setFieldsValue(fields);
  }, [assetResult, scope]);

  const onFinishFormActions = {
    dispatch,
    toggleModal,
    triggerOtherActionsWithResult: res => {
      if (scope === 'add') {
        dispatch(addNewItemToAssets(res?.data));
      } else if (scope === 'edit') {
        dispatch(updateAssetsStore(res?.data));
      }
    }
  };
  const ButtonCustom = BaseButton(ButtonSimple);
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  return (
    <ComponentWithStatus
      status={scope === 'add' ? 'success' : status}
      Placeholder={<AssetFormPlaceholder />}
    >
      <Form
        form={form}
        id="AssetSoftwareForm"
        key="hardwareFormKey"
        layout="horizontal"
        name="basic"
        labelWrap
        labelCol={{ span: 9, offset: 1 }}
        labelAlign="left"
        onFinish={data => {
          const { price, ...softwareData } = data;

          const payload =
            price === '' || !price || price.length === 0 ? softwareData : data;

          payload.categoryId =
            typeof payload.categoryId === 'string'
              ? categories
                  .filter(
                    category => category.props.children === payload.categoryId
                  )
                  .map(value => value.props && Number(value.props.value))[0]
              : payload.categoryId;

          if (defaultFrequency.includes(payload.frequency)) {
            const index = defaultFrequency.findIndex(
              freq => freq === payload.frequency
            );
            payload.frequency = initialFrequency[index];
          }

          dispatch(setFormValidation(true));
          onFinish(scope, payload, assetId, onFinishFormActions);
        }}
        onFinishFailed={validationError =>
          onFormFinishFailedValidation(validationError, dispatch)
        }
        disabled={isFormDisabled}
      >
        <div className="ant-form-two-columns-row">
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            label={<FormattedMessage id="label_SoftwHardForm_category" />}
            name="categoryId"
            isDisabled={isFormDisabled}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
          >
            {['edit', 'add'].includes(scope) ? (
              <SelectDropdown
                placement_bottom={true}
                listHeight={isMobile ? 111 : 88}
              >
                {categories}
              </SelectDropdown>
            ) : (
              <Input
                value={intl.formatMessage({
                  id: 'label_SoftwHardForm_placeholder_None'
                })}
              />
            )}
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            offset={1}
            label={<FormattedMessage id="label_SoftwHardForm_type" />}
            name="type"
            initialValue="Software"
            isReadOnly={`col-form-item-component-view ${theme}`}
            isDisabled
            rules={setFormItemRules({
              isRequired: !isFormDisabled,
              requiredMessage: intl.formatMessage({
                id: 'CategoriesTypeValidationRequired'
              })
            })}
          >
            <Input disabled />
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            label={<FormattedMessage id="label_softwareForm_asset_name" />}
            name="model"
            isDisabled={isFormDisabled}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            rules={setFormItemRules({
              isRequired: !isFormDisabled,
              requiredMessage: intl.formatMessage({
                id: 'AssetNameInputValidation'
              }),
              hasPattern: true,
              pattern: /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF \\/\n'",.-]*$/,
              patternMessage: intl.formatMessage({
                id: 'InventoryAssetsNameCharactersValidation'
              }),
              isMaxLengthRequired: true,
              maxLengthRequired: 50,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetNameLengthValidation'
              })
            })}
          >
            <TextArea autoSize={true} minLength={1} maxLength={51} />
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            offset={1}
            label={<FormattedMessage id="label_softwareForm_details" />}
            name="details"
            isDisabled={isFormDisabled}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            rules={setFormItemRules({
              isRequired: false,
              isMaxLengthRequired: true,
              maxLengthRequired: 500,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetDetailsLengthValidation'
              })
            })}
          >
            <TextArea
              autoSize={{
                minRows: 1,
                maxRows: 6
              }}
              minLength={1}
              maxLength={501}
            />
          </ColFormItemComponent>
          {hasPermissionToSeExtended && (
            <ColFormItemComponent
              className={`col-form-item-component ${theme}`}
              style={{ maxHeight: '40px' }}
              label={<FormattedMessage id="label_SoftwHardForm_price" />}
              isDisabled={isFormDisabled}
              isReadOnly={
                isFormDisabled ? `col-form-item-component-view ${theme}` : ``
              }
            >
              <div className="ant-form-item-control-two-inputs">
                <ColFormItemComponent
                  className={`col-form-item-component ${theme}`}
                  name="price"
                  rules={setFormItemRules({
                    isRequired: false,
                    hasPattern: true,
                    pattern: /^(\d+)(,\d{0,2}|\.\d{0,2})?$/,
                    patternMessage: intl.formatMessage({
                      id: 'AssetPriceNumbersValidation'
                    })
                  })}
                  span={24}
                  isDisabled={isFormDisabled}
                  isReadOnly={
                    isFormDisabled
                      ? `col-form-item-component-view ${theme}`
                      : ``
                  }
                >
                  <Input
                    style={{
                      width: deviceWidth > 769 ? '232px' : '100%'
                    }}
                    value={price}
                    onChange={e =>
                      setPrice(
                        /^(\d+)(,\d{0,2}|\.\d{0,2})?$/.test(
                          e.currentTarget.value
                        )
                          ? e.currentTarget.value
                          : null
                      )
                    }
                    onBlur={e => {
                      setPrice(
                        handleDecimalsForIntegerValue(e.currentTarget.value)
                      );
                    }}
                  />
                </ColFormItemComponent>

                <ColFormItemComponent
                  className={`col-form-item-component ${theme}`}
                  name="currency"
                  rules={setFormItemRules({
                    isRequired: price ? true : false,
                    requiredMessage: 'Please select a currency.'
                  })}
                  isDisabled={isFormDisabled}
                  isReadOnly={
                    isFormDisabled
                      ? `col-form-item-component-view ${theme}`
                      : ``
                  }
                >
                  {['edit', 'add'].includes(scope) ? (
                    <SelectDropdown
                      allowClear={false}
                      placement_bottom={scope === 'edit' ? true : false}
                      dropdownStyle={{ animationDuration: '0s' }}
                      defaultValue={'EUR'}
                      showSearch={false}
                      listHeight={isMobile ? 111 : 88}
                      style={{
                        width: '80px'
                      }}
                    >
                      {currencies}
                    </SelectDropdown>
                  ) : (
                    <Input
                      style={{
                        width: '80px'
                      }}
                    />
                  )}
                </ColFormItemComponent>
              </div>
            </ColFormItemComponent>
          )}

          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            offset={1}
            label={<FormattedMessage id="Frequency" />}
            isDisabled={isFormDisabled}
            name="frequency"
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
          >
            {['edit', 'add'].includes(scope) ? (
              <SelectDropdown
                listHeight={isMobile ? 111 : 88}
                placement_bottom={scope === 'edit' ? true : false}
              >
                {frequencies}
              </SelectDropdown>
            ) : (
              <Input />
            )}
          </ColFormItemComponent>

          {(scope === 'edit' || scope === 'view') && (
            <ColFormItemComponent
              className={`col-form-item-component ${theme}`}
              label={<FormattedMessage id="label_SoftwHardForm_assignee" />}
              name="assigneeName"
              isDisabled
              isReadOnly={`col-form-item-component-view ${theme}`}
              onClick={() => {
                if (data && data?.assigneeId) {
                  toggleModal();
                  navigate(
                    `/allUsersList/viewProfile?employeeId=${data?.assigneeId}`
                  );
                }
              }}
            >
              <Input disabled />
            </ColFormItemComponent>
          )}
        </div>
      </Form>

      {historyData?.length && scope === 'view' ? (
        <div className="inventory-modal-history">
          <ButtonCustom
            className={`text-bold-normal btn-default-custom ${theme}`}
            key="hardwareHistoryButton"
            icon={showHistoryTable ? <MinusOutlined /> : <PlusOutlined />}
            onClick={() => setShowHistoryTable(state => !state)}
          >
            {showHistoryTable
              ? intl.formatMessage({ id: 'label_History_toggler_collapse' })
              : intl.formatMessage({ id: 'label_History_toggler_expand' })}
          </ButtonCustom>

          {showHistoryTable && <SoftwareHistory historyData={historyData} />}
        </div>
      ) : null}
    </ComponentWithStatus>
  );
};

export default SoftwareForm;
