import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ICompanySettings } from 'redux/types';

export const useCompanySettings = (): ICompanySettings | null => {
  const generalRootState = useSelector((state: RootState) => state);
  const companySettings =
    JSON.parse(sessionStorage.getItem('companySettings')) ??
    (generalRootState?.companySettings?.settings as ICompanySettings);
  return companySettings;
};

interface LeaveOffSetting {
  ForbidOffsetForFutureHours: boolean;
}

interface TimeOffPoliciesSettings {
  LeaveOffSettting: LeaveOffSetting;
}

interface CountrySettings {
  TimeOffPoliciesSettings: TimeOffPoliciesSettings;
}

interface Settings {
  [key: string]: CountrySettings;
}

// This is a temporary solution for the settings module
export const settingModule: Settings = {
  MD: {
    TimeOffPoliciesSettings: {
      LeaveOffSettting: {
        ForbidOffsetForFutureHours: true
      }
    }
  },
  RO: {
    TimeOffPoliciesSettings: {
      LeaveOffSettting: {
        ForbidOffsetForFutureHours: false
      }
    }
  }
};
