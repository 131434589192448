import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { AddUsersSvg } from 'Icons/addUsersComponent';
import { InputRef } from 'antd';
import ModalComponent from 'components/Modal';
import { useModalContext } from 'components/Modal/ModalProvider';
import TeamsList from 'components/Team/TeamsList';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import TeamAddPage from 'pages/Team/TeamAddPage';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TeamsService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { ITeam } from 'services/providers/EmployeeProvider/types';
import { firstCharToUpperCase } from 'utils/lang/utils';
import normalizeString from 'utils/normalizeString';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';
import { ToolbarSearchOptions } from './types';
import { titlesKeysofColumnsTeams } from './utils';

const TeamsPage = () => {
  const { user } = useAuth();
  const { toggleModal } = useModalContext();
  const { isMobile } = useMobileContext();
  const role = getCurrentUserRole(user);
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<InputRef>(null);
  const { data, status } = useApiRequestHook<ITeam[]>(
    TeamsService.getAllTeams,
    'true',
    [],
    {}
  );
  const generalRootState = useSelector((state: RootState) => state);
  const generalRootStateExists =
    generalRootState.user && Object.keys(generalRootState.user).length;

  const loogedUserId = generalRootStateExists
    ? generalRootState.user.loggedUser.employeeId
    : '';

  const [TeamsData, setTeamsData] = useState<any[]>([]);

  const params = {
    searchInput,
    searchText,
    setSearchText,
    searchedColumn,
    setSearchedColumn
  };

  const handleSearchToolbar = (value, columnSearch) => {
    setSearchText(value);

    const currValue = value;

    const colToBeSearched =
      titlesKeysofColumnsTeams.filter(
        item =>
          intl.formatMessage({ id: item.title.props.id ?? null }) ===
          columnSearch
      )[0] || null;

    const currKey = colToBeSearched?.dataIndex ?? '';
    setSearchedColumn(currKey);

    const filteredData = data.filter(entry => {
      if (currValue === '') {
        // when current value on search is initial is empty
        return entry;
      }
      if (
        entry[currKey] === null ||
        entry[currKey] === undefined ||
        entry[currKey].trim() === ''
      ) {
        // when values from api is empty | null | undefined
        return false;
      }

      return typeof entry[currKey] === 'object'
        ? Object.values(entry[currKey]).filter((e: string) =>
            normalizeString(e).startsWith(normalizeString(currValue))
          ).length
        : normalizeString(entry[currKey]).includes(normalizeString(currValue));
    });

    setTeamsData(filteredData);
  };

  useEffect(() => {
    setTeamsData(data);
  }, [data]);

  const intl = useIntl();

  const createTeam = intl.formatMessage({ id: 'createTeam' });
  const cancelText = intl.formatMessage({ id: 'cancel' });
  const createText = intl.formatMessage({ id: 'create' });

  return (
    <>
      <ModalComponent
        title={firstCharToUpperCase(createTeam)}
        formKeyId="teamForm"
        submitBtnText={createText}
        cancelBtnText={cancelText}
        isMaskClosable={false}
        children={<TeamAddPage />}
      />
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={true}
        className=""
        displayToolbarButtons={!isMobile}
        onChangeInputSearch={handleSearchToolbar}
        buttons={[
          {
            icon: <AddUsersSvg />,
            text: <span>{createTeam}</span>,
            action: toggleModal,
            show: allowPermision(role, adminRoles)
          }
        ]}
        initialSelected={intl.formatMessage({
          id: `teams_tableCol_${ToolbarSearchOptions.Team}`
        })}
        searchOptions={Object.keys(ToolbarSearchOptions).map(value => ({
          value: value as ToolbarSearchOptions,
          label: intl.formatMessage({ id: `teams_tableCol_${value}` })
        }))}
      />
      <br className='break-line' />
      <TeamsList
        teamsData={TeamsData}
        statusTeam={status}
        params={params}
        employeId={loogedUserId}
      />
    </>
  );
};

export default TeamsPage;
