import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { LogoTimily } from 'Icons/Logos/LogoTimily';
import { StatusProfileSvg } from 'Icons/statusProfile';
import { Badge, Card, Col, Row, Typography } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import avatar from 'assets/avatar.png';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useCompanySettings } from 'settings';
import HeaderMenuButton from './HeaderMenuButton';
import './HeaderStyles.less';
import NotificationsComponent from './Notifications';
import ProfileDropdown from './ProfileDropdown';

export const HeaderPart = () => {
  const { Meta } = Card;
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const userStore = useSelector((state: RootState) => state.user?.loggedUser);
  const { countryCode } = useCompanySettings();
  const { Text } = Typography;



  const navigateToDashboard = () => {
    navigate('/dashboard');
  };
  const propsLogo = {
    onClick: navigateToDashboard
  };

  return (
    <Header className={`set-header header-round ${theme}`}>
      <Row className="header-inner" justify="space-between" align="middle">
        <Col className="header-mobile-col">
          <HeaderMenuButton />
        </Col>
        <Col>
          <LogoTimily {...propsLogo} />
        </Col>
        <Col>
          <Row
            className="header-mobile-row"
            justify="space-between"
            align="middle"
          >
            <div className="welcome-text" style={{ paddingRight: 24 }}>
              <Text className={`text-bold-extra typography_text ${theme}`}>
                <FormattedMessage
                  id="greeting"
                  values={{ name: userStore?.firstName }}
                />
                !
              </Text>
            </div>

            <NotificationsComponent />

            <div className="header-avatar-area">
              <Meta
                avatar={
                  <Badge
                    count={
                      <StatusProfileSvg
                        style={{ transform: 'translate(10%, 150%)' }}
                      />
                    }
                  >
                    <ProfileDropdown
                      profilePictureSrc={userStore?.externalAvatarUrl ?? avatar}
                    />
                  </Badge>
                }
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};
