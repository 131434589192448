import { commonRequest } from 'services/generic';

/**
 * This class provides methods to interact with the server for Ticketing Work Queue.
 */
class TicketingWorkQueueProvider {

  //getAllWorkQueues
  /**
   * GET All Work Queues
   * @returns {Promise<any>} - A promise that resolves to the response from the server.
   */
  async getAllWorkQueues(): Promise<any> {
    return await commonRequest<any>(
      'GET',
      {},
      'WorkQueue/getAllWorkQueues'
    );
  }

  //getWorkQueueById
  /**
   * GET Work Queue By Id
   * @param {number} workQueueId - The id of the work queue.
   * @returns {Promise<any>} - A promise that resolves to the response from the server.
   */
  async getWorkQueueById(workQueueId: number): Promise<any> {
    return await commonRequest<any>(
      'GET',
      { workQueueId },
      'WorkQueue/getWorkQueueById?id=' + workQueueId
    );
  }
  
  /**
   * POST Work Queue
   * @param {FormData} payload - The payload contains FormData of the work queue.
   * @returns {Promise<any>} - A promise that resolves to the response from the server.
   */
  async addWorkQueue(payload: FormData): Promise<any> {
    const payloadData = { payload };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'WorkQueue/addWorkQueue'
    );
  }

  /**
   * POST Update Work Queue
   * @param {Object} payload - The payload contains the id and name of the work queue to be updated.
   * @param {number} payload.workQueueId - The id of the work queue to be updated.
   * @param {string} payload.name - The name of the work queue to be updated.
   * @returns {Promise<any>} - A promise that resolves to the response from the server.
   */
  async updateWorkQueue(payload: {
    workQueueId: number;
    name: string;
  }): Promise<any> {
    const payloadData = { payload };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'WorkQueue/updateWorkQueue'
    );
  }

  /**
   * POST Remove Work Queue
   *
   * @param {number} workQueueId - The id of the work queue to be removed.
   * @returns {Promise<any>} A promise that resolves to the response from the server.
   */
  async removeWorkQueue(payload: string, workQueueId: number): Promise<any> {
    const payloadData: { workQueueId: number } = { workQueueId };

    return await commonRequest<any>(
      'POST',
      payloadData,
      '/WorkQueue/deleteWorkQueue/' + workQueueId
    );
  }

  //Add Worker
  async addWorker(payload: {
    workQueueId: number;
    employeeId: number;
  }): Promise<any> {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      `/WorkQueue/${payload.workQueueId}/assignEmployee/${payload.employeeId}`
    );
  }

  //Remove Worker
  async removeWorker(payload: {
    workQueueId: number;
    employeeId: number;
  }): Promise<any> {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      `/WorkQueue/${payload.workQueueId}/unassignEmployee/${payload.employeeId}`
    );
  }

  // getAllConfigurations
  async getWorkQueueConfigurationCombinations(): Promise<any> {
    return await commonRequest<any>('GET', '', '/WorkQueue/getWorkQueueConfigurationCombinations');
  }

  //addConfiguration
  async addConfiguration(payload: any): Promise<any> {
    const payloadData = { payload };
    return await commonRequest<any>('POST', payloadData, '/WorkQueue/addConfiguration');
  }
//remove Configuration
  async removeConfiguration(payload: {
    configurationId: number;
  }): Promise<any> {
    const payloadData = { payload };
    return await commonRequest<any>('POST', payloadData, `/WorkQueue/removeConfiguration/${payload?.configurationId}`);
  }
  
}
export default TicketingWorkQueueProvider;

