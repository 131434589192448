import { BereavementComponent } from 'Icons/TimeManagementWidget/bereavementComponent';
import { BloodDonationComponent } from 'Icons/TimeManagementWidget/bloodDonationComponent';
import { ChildcareComponent } from 'Icons/TimeManagementWidget/childcareComponent';
import ForgotBadgeComponent from 'Icons/TimeManagementWidget/forgotBadge';
import { MarriageComponent } from 'Icons/TimeManagementWidget/marriageComponent';
import { MaternityComponent } from 'Icons/TimeManagementWidget/maternityComponent';
import { OffsettingLeaveComponent } from 'Icons/TimeManagementWidget/offsettingLeaveComponent';
import { OvertimeComponent } from 'Icons/TimeManagementWidget/overtimeComponent';
import { PaternityComponent } from 'Icons/TimeManagementWidget/paternityComponent';
import { PersonalLeaveComponent } from 'Icons/TimeManagementWidget/personalLeaveComponent';
import { SickLeaveComponent } from 'Icons/TimeManagementWidget/sickLeaveComponent';
import { UnpaidComponent } from 'Icons/TimeManagementWidget/unpaidComponent';
import { VacationComponent } from 'Icons/TimeManagementWidget/vacationComponent';
import { WftComponent } from 'Icons/TimeManagementWidget/wfhComponent';
import { FormattedMessage } from 'react-intl';
import { IPolicyConfiguration, PolicyTypes } from './types';
import { MilitaryTrainingComponent } from 'Icons/TimeManagementWidget/militaryTraining';
import { TwoOrMoreChildrenComponent } from 'Icons/TimeManagementWidget/twoOrMoreChildren';
import { BusinessTripComponent } from 'Icons/TimeManagementWidget/businessTrip';
import { FirstDayOfSchoolComponent } from 'Icons/TimeManagementWidget/firstDayOfSchool';
import { LastDayOfSchoolComponent } from 'Icons/TimeManagementWidget/lastDayOfSchool';
import { ChildcareLeaveUnpaidComponent } from 'Icons/TimeManagementWidget/childcareLeaveUnpaid';
import { BirthOfAChildComponent } from 'Icons/TimeManagementWidget/birthOfAChild';
import { SpecialComponent } from 'Icons/TimeManagementWidget/specialComponent';

const specialPolicyItem = [
  {
    icon: <SpecialComponent />,
    title: <FormattedMessage id="Special" />,
    request: 'Special',
    allowUnfinishedRequests: true,
    policyType: PolicyTypes.FullDay,
    isDocumentUploadRequired: true,
    allowEditRequest: true
  }
];

export const PolicyConfig: IPolicyConfiguration = {
  MD: {
    DefaultTimeOffRequests: [
      {
        icon: <VacationComponent />,
        title: <FormattedMessage id="Vacation" />,
        request: 'Vacation',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <WftComponent />,
        title: <FormattedMessage id="WorkFromHomeShort" />,
        request: 'WorkFromHome',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false,
        isSingleDayRequest: true
      },
      {
        icon: <PersonalLeaveComponent />,
        title: <FormattedMessage id="PersonalLeave" />,
        request: 'PersonalLeave',
        allowUnfinishedRequests: false,
        isHidden: false, //HIDDEN AS PER DANIEL REQUEST ON MD ONLY
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <OffsettingLeaveComponent />,
        title: <FormattedMessage id="LeaveOffsetting" />,
        request: 'LeaveOffsetting',
        allowUnfinishedRequests: false,
        isHidden: false, //HIDDEN AS PER DANIEL REQUEST ON MD ONLY
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <SickLeaveComponent />,
        title: <FormattedMessage id="Medical" />,
        request: 'Medical',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: true
      },
      {
        icon: <UnpaidComponent />,
        title: <FormattedMessage id="Unpaid" />,
        request: 'Unpaid',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },

      {
        icon: <PaternityComponent />,
        title: <FormattedMessage id="Paternity" />,
        request: 'Paternity',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },
      {
        icon: <MaternityComponent />,
        title: <FormattedMessage id="Maternity" />,
        request: 'Maternity',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },
      {
        icon: <ChildcareComponent />,
        title: <FormattedMessage id="Childcare" />,
        request: 'Childcare',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },

      {
        icon: <OvertimeComponent />,
        title: <FormattedMessage id="Overtime" />,
        request: 'Overtime',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <BloodDonationComponent />,
        title: <FormattedMessage id="BloodDonation" />,
        request: 'BloodDonation',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },
      {
        icon: <BereavementComponent />,
        title: <FormattedMessage id="Bereavement" />,
        request: 'Bereavement',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },
      {
        icon: <MarriageComponent />,
        title: <FormattedMessage id="Marriage" />,
        request: 'Marriage',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },

      {
        icon: <ForgotBadgeComponent />,
        title: <FormattedMessage id="ForgotBadge" />,
        request: 'ForgotBadge',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false,
        isSingleDayRequest: true
      },
      {
        icon: <MilitaryTrainingComponent />,
        title: <FormattedMessage id="MilitaryTraining" />,
        request: 'MilitaryTraining',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isAdminOnly: true,
        isSpecialRequest: true
      },
      {
        icon: <TwoOrMoreChildrenComponent />,
        title: <FormattedMessage id="TwoOrMoreChildren" />,
        request: 'TwoOrMoreChildren',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isAdminOnly: true,
        isSpecialRequest: true
      },
      {
        icon: <BusinessTripComponent />,
        title: <FormattedMessage id="BusinessTrip" />,
        request: 'BusinessTrip',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: false,
        allowEditRequest: false,
        isAdminOnly: true,
        isSpecialRequest: true
      },
      {
        icon: <FirstDayOfSchoolComponent />,
        title: <FormattedMessage id="FirstDayOfSchool" />,
        request: 'FirstDayOfSchool',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSingleDayRequest: true,
        isSpecialRequest: true
      },
      {
        icon: <LastDayOfSchoolComponent />,
        title: <FormattedMessage id="LastDayOfSchool" />,
        request: 'LastDayOfSchool',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSingleDayRequest: true,
        isSpecialRequest: true
      },
      {
        icon: <ChildcareLeaveUnpaidComponent />,
        title: <FormattedMessage id="ChildcareLeaveUnpaid" />,
        request: 'ChildcareLeaveUnpaid',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },
      {
        icon: <BirthOfAChildComponent />,
        title: <FormattedMessage id="BirthOfAChild" />,
        request: 'BirthOfAChild',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },
      ...specialPolicyItem,
    ]
  },
  RO: {
    DefaultTimeOffRequests: [
      {
        icon: <VacationComponent />,
        title: <FormattedMessage id="Vacation" />,
        request: 'Vacation',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <WftComponent />,
        title: <FormattedMessage id="WorkFromHomeShort" />,
        request: 'WorkFromHome',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <PersonalLeaveComponent />,
        title: <FormattedMessage id="PersonalLeave" />,
        request: 'PersonalLeave',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <OffsettingLeaveComponent />,
        title: <FormattedMessage id="LeaveOffsetting" />,
        request: 'LeaveOffsetting',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <SickLeaveComponent />,
        title: <FormattedMessage id="Medical" />,
        request: 'Medical',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: true
      },
      {
        icon: <UnpaidComponent />,
        title: <FormattedMessage id="Unpaid" />,
        request: 'Unpaid',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },

      {
        icon: <PaternityComponent />,
        title: <FormattedMessage id="Paternity" />,
        request: 'Paternity',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },
      {
        icon: <MaternityComponent />,
        title: <FormattedMessage id="Maternity" />,
        request: 'Maternity',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false, isSpecialRequest: true
      },
      {
        icon: <ChildcareComponent />,
        title: <FormattedMessage id="Childcare" />,
        request: 'Childcare',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true
      },

      {
        icon: <OvertimeComponent />,
        title: <FormattedMessage id="Overtime" />,
        request: 'Overtime',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.TimeBased,
        isDocumentUploadRequired: false,
        allowEditRequest: false
      },
      {
        icon: <BloodDonationComponent />,
        title: <FormattedMessage id="BloodDonation" />,
        request: 'BloodDonation',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true,
      },
      {
        icon: <BereavementComponent />,
        title: <FormattedMessage id="Bereavement" />,
        request: 'Bereavement',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true,
      },
      {
        icon: <MarriageComponent />,
        title: <FormattedMessage id="Marriage" />,
        request: 'Marriage',
        allowUnfinishedRequests: true,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: true,
        allowEditRequest: false,
        isSpecialRequest: true,
      },

      {
        icon: <ForgotBadgeComponent />,
        title: <FormattedMessage id="ForgotBadge" />,
        request: 'ForgotBadge',
        allowUnfinishedRequests: false,
        isHidden: false,
        policyType: PolicyTypes.FullDay,
        isDocumentUploadRequired: false,
        allowEditRequest: false,
        isSingleDayRequest: true
      },
      ...specialPolicyItem,

    ]
  }
};
