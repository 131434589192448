export const HardwareListComponent = () => {
  return (
    <span role='img' aria-label='import' className='anticon anticon-import'>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7 9.25V10.75M4.75 11.5H9.25M2.5 9.25H11.5C12.3284 9.25 13 8.57843 13 7.75V2.5C13 1.67157 12.3284 1 11.5 1H2.5C1.67157 1 1 1.67157 1 2.5V7.75C1 8.57843 1.67157 9.25 2.5 9.25Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
};
