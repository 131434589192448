import FilterColumn from './TableComponents/FilterColumn';
import FilterSort from './TableComponents/FilterSort';

const TableHeaderCell = ({
  title,
  className,
  entireItem,
  setFilteredResults,
  dataSorce,
  setSorted
}) => {
  const handleFilterApply = (selectedOptions: string[]) => {
    setFilteredResults(selectedOptions);
  };

  return (
    <th
      className={`fixed fixed-top table-component-header-cell  ${
        className ?? ``
      }`}
      style={{ width: entireItem.width ? `${entireItem.width}px` : 'auto' }}
    >
      <div>
        {entireItem.filters ? (
          <div className="filtered-column">
            <div style={{ width: '100%' }}>{title}</div>
            {entireItem.filters && (
              <FilterColumn
                options={entireItem.filters}
                onFilterApply={handleFilterApply}
              />
            )}
            {entireItem.sorter? <FilterSort  entireItem={entireItem} dataSorce={dataSorce} setSorted={setSorted}/> : null}
          </div>
        ) : (
          <>{title}</>
        )}
      </div>
    </th>
  );
};

export default TableHeaderCell;
