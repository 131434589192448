import { ColumnsType } from 'antd/es/table';
import { FormattedMessage } from 'react-intl';
export const columnsMobileCategories = (): ColumnsType<any> => [
  {
    title: <FormattedMessage id="table_InventoryCategoryCol_Name" />,
    dataIndex: 'name',
    render: (_, record) => {
      return (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {record.name}
          </span>
        </div>
      );
    },
    width: '33%'
  },
  {
    title: <FormattedMessage id="table_InventoryCategoryCol_Type" />,
    dataIndex: 'assetType',
    width: '33%'
  },
  {
    title: <FormattedMessage id="table_InventoryCategoryCol_Count" />,
    dataIndex: 'assetsCount',
    width: '33%'
  }
];
