import { Landscape } from 'Icons/OrientateDevice/orientationLandscape';
import { useEffect, useState } from 'react';
import { Portrait } from 'Icons/OrientateDevice/orientationPortrait';
import { OrientationMapping } from './utils';
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
import 'Icons/OrientateDevice/orientationDeviceStyles.less';

const OrientationDevice = (props) => {
  const [orientation, setOrientation] = useState(
    OrientationMapping[window.orientation]
  );
  const landscapeOrienttion = 'Landscape';
  const portraitOrientation = 'Portrait';
  const [isPortrait, setIsPortrait] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  const orientationChangeHandler = () => {
    setOrientation(OrientationMapping[Math.abs(window.orientation)]);
  };
  useEffect(() => {
    setOrientation(OrientationMapping[Math.abs(window.orientation)]);
    window.addEventListener('orientationchange', orientationChangeHandler);
    return () =>
      window.removeEventListener('orientationchange', orientationChangeHandler);
  }, [window.orientation]);

  useEffect(() => {
    setIsPortrait(orientation === portraitOrientation);
    setIsLandscape(orientation === landscapeOrienttion);
    
  }, [orientation]);

  useEffect(() => {
    const mainSection = document.querySelector('.modal_requests') as HTMLElement;
    if (mainSection) {
      mainSection.style.opacity = isLandscape ? '0' : '1';
    }
  }, [isLandscape]);
  

  let portraitForTablet = !isDesktop && isPortrait && isTablet;
  let landscapeForMobile = !isDesktop && isLandscape && isMobileOnly;
  return (
    <>
      <div 
      className='device_detectiction'
        style={{
          display: portraitForTablet || landscapeForMobile ? 'flex' : 'none',
        }}
      >
        {(portraitForTablet && <Portrait />) ||
          (landscapeForMobile && <Landscape />)}
      </div>
      <div>{props.children}</div>
    </>
  );
};
export default OrientationDevice;
