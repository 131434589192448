export const CopyRightIcon = ({ stroke }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.625 9.75L1.875 9.75C1.39175 9.75 1 9.35825 1 8.875L1 3C1 1.89543 1.89543 1 3 1L8.875 1C9.35825 1 9.75 1.39175 9.75 1.875L9.75 3.625M8 15L13.25 15C14.2165 15 15 14.2165 15 13.25L15 8C15 7.0335 14.2165 6.25 13.25 6.25L8 6.25C7.0335 6.25 6.25 7.0335 6.25 8L6.25 13.25C6.25 14.2165 7.0335 15 8 15Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
