import { FC } from 'react';
import { Layout, Row, Typography, Col } from 'antd';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import './TableLayoutPage.less';
import {
  getCurrentUserRole,
  allowPermision,
  adminRoles,
  rolesAllow
} from 'utils/userManagement';
import LayoutPageMobileComponent from './LayoutPageMobileComponent';
import { useAuth } from 'services/providers/AuthProvider';
import { SegmentedLabeledOption } from 'antd/es/segmented';

type LayoutPageWithTitleProps = {
  title?: string | JSX.Element;
  children: any;
  displayToggle?: boolean;
  displayButton?: boolean;
  switchCheckedChildren?: React.ReactNode;
  switchCheckedSrc?: string;
  switchUncheckedChildren?: React.ReactNode;
  switchUncheckedSrc?: string;
  localStorageValue?: any;
  onChangeToggle?: any;
  onClickToggle?: any;
  isSwitchChecked?: boolean;
  options?: SegmentedLabeledOption[];
  defaultValue?: string;
  style?: React.CSSProperties;
  childrenButton?: React.ReactNode;
  className?: string;
  renderCustomButton?: React.ReactNode;
};

const { Title } = Typography;

export const TableLayoutPage: FC<LayoutPageWithTitleProps> = ({
  title,
  displayToggle,
  displayButton,
  style,
  defaultValue,
  children,
  childrenButton,
  className,
  renderCustomButton
}) => {
  const { theme } = useThemeContext();
  const { isMobile } = useMobileContext();
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);

  return (
    <Layout
      className={`layout-page-with-title ${
        defaultValue === 'Cards' ? 'layout-table-grid-view' : ''
      } ${className}  ${theme}`}
      style={style}
    >
      <Row className="table-heading align-center-flex" align="middle">
        <Col style={{ position: 'relative', flex: '1 0 0'}}>
          <Title className={`align-padding-title ${theme}`} level={3}>
            {title}
          </Title>
          {renderCustomButton}
        </Col>
        {displayToggle &&
          (!isMobile || !allowPermision(userRole, adminRoles)) && (
            <>{childrenButton}</>
          )}
        {allowPermision(userRole, rolesAllow) && isMobile && displayButton && (
          <LayoutPageMobileComponent />
        )}
      </Row>
      {children}
    </Layout>
  );
};
