export const ReportsSvg = () => {
  return (
    <span
      role="img"
      aria-label="reports"
      className="anticon anticon-time"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.2001 14.3999H3.20009C2.31643 14.3999 1.60009 13.6836 1.6001 12.7999L1.60016 3.19997C1.60017 2.31631 2.31651 1.59998 3.20016 1.59998H10.4003C11.284 1.59998 12.0003 2.31632 12.0003 3.19998V6.39997M11.6001 11.566V11.5239M4.40035 4.79998H9.20035M4.40035 7.19997H9.20035M4.40035 9.59997H6.80035M14.4001 11.6C14.4001 11.6 13.736 13.5598 11.6001 13.5255C9.4642 13.4913 8.8001 11.6 8.8001 11.6C8.8001 11.6 9.43728 9.60596 11.6001 9.60596C13.7629 9.60596 14.4001 11.6 14.4001 11.6Z"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
