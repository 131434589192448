import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ArrowUnion } from 'Icons/General/arrowUnion';
import { Select } from 'antd';
import { MultipleDropDownFilter } from 'components/UIComponents/MultipleDropDownFilter';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  getAssignmentTypesDefinition,
  getTicketCategoriesDefinition,
  getTicketStatusTypesDefinition,
  getTicketUrgencyTypesDefinition
} from '../utils';
import { useFiltersChangeEvents } from './utils';
import SingleSelectDropdown from 'components/UIComponents/SingleSelectDropdown';
import { AssignmentTypes } from '../types';
import OptionButton from 'components/UIComponents/OptionButton';
import React from 'react';
import useOutsideClick from 'hooks/useOutsideClick.hook';
const { Option } = Select;

export default function TicketingToolbarFilters(props) {
  const { theme } = useThemeContext();
  const {
    onAssigneeChanged,
    onCategoriesChanged,
    onStatusChanged,
    onUrgencyChanged
  } = useFiltersChangeEvents();
  const intl = useIntl();

  const urgencyTypes = getTicketUrgencyTypesDefinition();
  const statusTypes = getTicketStatusTypesDefinition();
  const categoriesTypes = getTicketCategoriesDefinition();
  const assignmentTypes = getAssignmentTypesDefinition();

  const rootSelector = useSelector((data: RootState) => data);

  const filterByLabel = intl.formatMessage({ id: 'FilterBy' });
  const assignmentLabel = intl.formatMessage({
    id: 'TicketingToolbarAssigneeSelect_assignment'
  });
  const categoryLabel = intl.formatMessage({
    id: 'TicketingToolbarSelect_category'
  });
  const urgencyLabel = intl.formatMessage({
    id: 'TicketingToolbarSelect_urgency'
  });
  const statusLabel = intl.formatMessage({
    id: 'TicketingToolbarSelect_status'
  });

  return (
    <div
      className="ticketing-filters search-toolbar_filters_container"
      style={{ marginLeft: '14px' }}
    >
      {/* Assigned filters */}
      <SingleSelectDropdown
        dropdownAlign={{ offset: [53, -14] }}
        label={`${rootSelector.ticketingFilters.assignmentType === null
            ? `${filterByLabel}: ${assignmentLabel}`
            : `${assignmentLabel}: ${intl.formatMessage({
              id:
                'TicketingToolbarAssigneeSelect__' +
                rootSelector.ticketingFilters.assignmentType?.[0]
            })}`
          }`}
        value={rootSelector.ticketingFilters.assignmentType?.[0]}
        theme={theme}
        options={assignmentTypes.map(option => ({
          value: option,
          label: intl.formatMessage({
            id: `TicketingToolbarAssigneeSelect__${option}`
          })
        }))}
        onChange={option => onAssigneeChanged(option ? [option] : null)}
      />

      {/* Urgency select filters */}
      <MultipleDropDownFilter
        placeholder={urgencyLabel}
        dataForOptions={urgencyTypes}
        dropdownAlign={{ offset: [53, 0] }}
        value={[]}
        onChangeFunction={selected => {
          onUrgencyChanged(selected ? selected : null);
        }}
        instanceId="Urgency"
      />

      {/* Category select filters */}
      <SingleSelectDropdown
        dropdownAlign={{ offset: [53, 0] }}
        label={`${rootSelector.ticketingFilters.categoriesTypes === null
            ? props.defaultCategory
              ? `${categoryLabel}: ${intl.formatMessage({
                id: 'TicketingCategory_' + props.defaultCategory
              })}`
              : `${filterByLabel}: ${categoryLabel}`
            : `${categoryLabel}: ${intl.formatMessage({
              id:
                'TicketingCategory_' +
                rootSelector.ticketingFilters.categoriesTypes?.[0]
            })}`
          }`}
        value={rootSelector.ticketingFilters.categoriesTypes?.[0]}
        theme={theme}
        options={categoriesTypes.map(option => ({
          value: option,
          label: intl.formatMessage({ id: `TicketingCategory_${option}` })
        }))}
        onChange={option => onCategoriesChanged(option ? [option] : null)}
      />

      {/* Status select filters */}
      <MultipleDropDownFilter
        placeholder={statusLabel}
        dataForOptions={statusTypes}
        dropdownAlign={{ offset: [53, null] }}
        value={[]}
        onChangeFunction={selected => {
          onStatusChanged(selected ? selected : null);
        }}
        instanceId="Status"
      />
    </div>
  );
}
