import { createContext, useContext, useState } from 'react';
import { defaultTimeOffPolicies } from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm/utils';
import { useCompanySettings } from 'settings';

const useReportsFilterProviderContext = () => {

  const { countryCode } = useCompanySettings();
  const defaultTypeFilters = defaultTimeOffPolicies(countryCode);

  const defaultStatusFilters = [
    'Employee',
    'Internship',
    'Deactivated',
    'None',
    'OnBoarding',
  ];

  type RangeFilterType = {
    startDate?: Date;
    endDate?: Date;
  };

  const [displayedValues, setDisplayedValues] =
  useState(null);

  const [defaultRangeFilter, setDefaultRangeFilter] = useState(null);

  const [reportType, setReportType] = useState<string>('');

  const [requestColumnsFilter, setColumnsFilter] =
    useState<any>(defaultTypeFilters);

  const [requestTypeFilter, setRequestTypeFilter] =
    useState<string[]>(defaultTypeFilters);

  const [employeeStatusFilter, setEmployeeStatusFilter] =
    useState<string[]>(defaultStatusFilters);
    
    const [rangeFilter, setRangeFilter] = useState<RangeFilterType>({
      startDate: null,
      endDate: null,
    });

    const [generateAllByFilters, setGenerateAllByFilters] =
      useState<any>({});


  const presetTableFilters = {
    reportType: reportType,
    requestTypes: requestTypeFilter,
    employeeStatus: employeeStatusFilter,
    range: rangeFilter,
    defaultRange: defaultRangeFilter,
    selectedColumns: requestColumnsFilter,
  };

  return {
    displayedValues,
    presetTableFilters,
    requestTypeFilter,
    employeeStatusFilter,
    rangeFilter,
    defaultTypeFilters,
    defaultStatusFilters,
    reportType,
    requestColumnsFilter,
    generateAllByFilters,
    setDisplayedValues,
    setRequestTypeFilter,
    setEmployeeStatusFilter,
    setRangeFilter,
    setReportType,
    defaultRangeFilter,
    setDefaultRangeFilter,
    setColumnsFilter,
    setGenerateAllByFilters
    
  };
};

const ReportsFilterProviderContext = createContext(
  {} as ReturnType<typeof useReportsFilterProviderContext>
);

export const useReportsFilterContext = () =>
  useContext(ReportsFilterProviderContext);

export const ReportsFiltersProvider = ({ children }) => {
  return (
    <ReportsFilterProviderContext.Provider
      value={useReportsFilterProviderContext()}
    >
      {children}
    </ReportsFilterProviderContext.Provider>
  );
};
