import { Col, Row, Typography } from 'antd';
import { useClockOutContext } from 'pages/TimeManagement/PersonalTimeOffRequests/PersonalTImeOffRequestsProvider';
import { ClockInOut } from './clockInOutButtons';
import TimeComponent from 'components/TimeComponent';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'components/Widgets/TimeManagementWidget/timeStyles.less';
import duration from 'dayjs/plugin/duration';
import { FormattedMessage } from 'react-intl';
import { useMobileContext, useThemeContext } from 'AppProvider/ConfigProviderSettings';

export const TimeManagementDetails = ({
  openModal,
  setModalOpen,
  enableClockIn,
  lastClockIn,
  workingHours,
  scope,
}) => {
  dayjs.extend(duration);
  const { Text } = Typography;
  const { clockOutProps } = useClockOutContext();
  const { isMobile } = useMobileContext();
  const [clockedOut, setClockedOut] = useState<any>(
    clockOutProps.isClockedOut ? dayjs(clockOutProps.clockedOut) : dayjs()
  );
  const [getInterval, setIntervalValue] = useState<any>();
  const [timeClocked, setTimeClocked] = useState<{
    hours: number;
    minutes: number;
  }>({
    hours: clockOutProps?.timeWorked?.hours ?? 0,
    minutes: clockOutProps?.timeWorked?.minutes ?? 0,
  });

  const [clockInOutHoursDiff, setClockInOutHoursDiff] = useState<any>();
  const [clockInOutMinutesDiff, setclockInOutMinutesDiff] = useState<any>();
  const [timeDinamically, setTimeDinamically] = useState<any>('--:--');
  const isClockedIntTime = lastClockIn && !lastClockIn?.clockOutTime;
  const timeHoursWorked = dayjs(workingHours, 'HH:mm:ss').format('HH');
  const timeMinutesWorked = dayjs(workingHours, 'HH:mm:ss').format('mm');

  const { theme } = useThemeContext();

  //time static when stay in page
  const [initialDate, setInitialDate] = useState<Date>(
    isClockedIntTime ? new Date() : null
  );
  //time dynamically for each minute
  let workingTimeDynamically = new Date();

  //load data from SS
  useEffect(() => {
    setInitialDate(new Date());
  }, [lastClockIn]);

  useEffect(() => {
    const clockInParse = dayjs.tz(dayjs.utc(clockOutProps.clockedIn), dayjs.tz.guess()).second(0);
    const clockInOutDifference = Math.round(
      clockedOut.second(0).diff(clockInParse, 'minute', true)
    );

    const clockInOutHoursSpentDiff = clockedOut
      .second(0)
      .diff(clockInParse, 'hours');

    setClockInOutHoursDiff(clockInOutHoursSpentDiff);

    const clockInOutRemainedMinutesDiff = clockInOutDifference % 60;

    setclockInOutMinutesDiff(clockInOutRemainedMinutesDiff);

    setTimeClocked({
      hours: clockInOutHoursSpentDiff ?? 0,
      minutes: clockInOutRemainedMinutesDiff ?? 0,
    });

    const interval = setInterval(() => {
      if (clockedOut.format('HH:mm') !== dayjs().format('HH:mm')) {
        setClockedOut(dayjs());
      }
    }, 1000);

    setIntervalValue(interval);

    if (getInterval !== interval) {
      clearInterval(getInterval);
    }

    return () => clearInterval(interval);
  }, [clockedOut, clockOutProps]);

  //each minute of time now
  useEffect(() => {
    if (!!initialDate && isClockedIntTime) {
      // time to navigate in page & time now
      if (workingTimeDynamically !== initialDate) {
        const initialDateParse = dayjs.tz(dayjs.utc((initialDate)), dayjs.tz.guess())
          .second(0);
        const timeDynamically = dayjs(workingTimeDynamically);
        const timeDiff = timeDynamically.diff(initialDateParse);
        const timeDuration = dayjs.duration(timeDiff);

        const addTimeByApiAndDinamical = timeDuration
          .add(parseInt(timeHoursWorked), 'hours')
          .add(parseInt(timeMinutesWorked), 'minutes')
          .format('HH:mm');

        setTimeDinamically(addTimeByApiAndDinamical);
      }
    }
  }, [workingTimeDynamically, initialDate]);

  //time from api
  let workingHoursByApi;
  if (timeHoursWorked === '00' && timeMinutesWorked === '00') {
    workingHoursByApi = '--:--';
  } else {
    workingHoursByApi = timeHoursWorked + ':' + timeMinutesWorked;
  }

  //time dynamically on each minute
  const workingHoursDynamically = timeDinamically;

  // check if time on clock out not exists
  // set time dinamically on each minute
  // otherwise set time from api

  const workingHoursByEmployee = isClockedIntTime
    ? workingHoursDynamically
    : workingHoursByApi;

  const paramsTimer = {
    timeClocked: timeClocked,
    clockInOutHoursSpentDiff: clockInOutHoursDiff,
    clockInOutRemainedMinutesDiff: clockInOutMinutesDiff,
    clockedOut: clockedOut,
    setClockedOut: setClockedOut,
  };

  return (
    <Row
      align="top"
      justify="space-between"
      className="flex-widget-management"
      style={
        isMobile
          ? { display: 'block', height: 'max-content' }
          : { width: '100%', height: '50px' }
      }
    >
      <Col>
        <Row
          style={isMobile ? { display: 'flex', justifyContent: 'center' } : {}}
        >
          <Text className={`text-bold-extra typography_header ${theme}`}>
            <FormattedMessage id="timeManagement" />
          </Text>
        </Row>
        <Row style={isMobile ? { display: 'flex', justifyContent: 'center' } : {}}>
          <Text className={`text-medium-normal typography_text ${theme}`}>
            <TimeComponent />
          </Text>
        </Row>
      </Col>

      <ClockInOut
        scope={scope}
        paramsTimer={paramsTimer}
        enableClockIn={enableClockIn}
        lastClockIn={lastClockIn}
        openModal={openModal}
        setModalOpen={setModalOpen}
        workingHours={workingHours}
        workingHoursByEmployee={workingHoursByEmployee}
      />
    </Row>
  );
};
