import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { IModalComponentProps } from 'components/Modal';
import ThemedIcon from 'components/UIComponents/Icon';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import _sort from 'utils/sorter';

export const useSubCategoryTableColumns = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<IModalComponentProps>>,
  editModalWidth: number | string,
  deleteModalWidth: number | string,
): ColumnsType<any> => {
  const { isMobile, isTabletDevice } = useMobileContext();
  const intl = useIntl();

  // Row Actions
  // Edit category open modal and set modal props

  const handleEditClick = record => {
    setModalProps(state => ({
      ...state,
      scope: 'edit',
      categoryId: record.categoryId,
      allowDelete: true,
      subCategory: record,
      displayFooterSubmitButton: true,
      displayFooterCloseButton: true,
      modalTitle: <FormattedMessage id="EditSubCategoryModalTitle" />,
      cancelButtonText: <FormattedMessage id="cancel" />,
      submitButtonText: <FormattedMessage id="save" />,
      width: editModalWidth
    }));
    setIsOpen(true);
  };
  // Remove category open modal and set modal props
  const handleDeleteClick = record => {
    setModalProps(state => ({
      ...state,
      scope: 'remove',
      allowDelete: record.isAssignedToTicket ? false : true,
      categoryId: record.categoryId,
      subCategory: record,
      displayFooterSubmitButton: record.isAssignedToTicket ? false : true,
      displayFooterCloseButton: true,
      modalTitle: <FormattedMessage id="RemoveSubCategoryModalTitle" />,
      cancelButtonText: <FormattedMessage id="cancel" />,
      submitButtonText: <FormattedMessage id="remove" />,
      width: deleteModalWidth
    }));
    setIsOpen(true);
  };

  //------End Row Actions

  const tableColumns = [
    //Subcategory Name
    {
      title: <FormattedMessage id="SettingsSubcategory_Name" />,
      dataIndex: 'name',
      sorter: (a, b) => _sort(a.name, b.name, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <p> {record.name}</p>
    },
    {
      title: <FormattedMessage id="SettingsWorkQueues_Translations" />,
      dataIndex: 'key',

      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <p> {intl.formatMessage({ id: record.key, defaultMessage: record.name })}</p>
    },
    {
      title: <FormattedMessage id="SettingsWorkQueues_Work_Queue" />,
      dataIndex: 'workQueue',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      className: 'hoverEdit',
      render: (_, record) => (
        <>
          <p>{record?.workQueue ? record?.workQueue?.name : '-'}</p>

          <div className={'showEdit'} style={{ display: 'none' }}>
            <div className="actionButtons">
              <ButtonWithExpandedText
                displayedText={<FormattedMessage id="edit" />}
                embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                key="edit-category-btn"
                type="link"
                onClick={() => handleEditClick(record)}
              />
              <ButtonWithExpandedText
                embedSrc={<ThemedIcon icon={TimeOffDeleteIcon} />}
                displayedText={<FormattedMessage id="remove" />}
                type="link"
                onClick={() => handleDeleteClick(record)}
              />
            </div>
          </div>
        </>
      )
    }
  ];
  return [...tableColumns];
};
