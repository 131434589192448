import React from 'react';

import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

interface ThemedIconProps {
  icon: React.ElementType;
}

const ThemedIcon: React.FC<ThemedIconProps> = ({ icon: Icon, ...props }) => {
  const { theme } = useThemeContext();
  const color = theme === 'dark' ? '#ffffff' : '#402a87';

  return <Icon {...props} stroke={color} />;
};

export default ThemedIcon;
