import { useState } from 'react';

/** Custom hook to track updates during a certain async operation. */

export default function useIsUpdateOngoing(
  onSuccess: (data) => void = () => {},
  onFailed: (error) => void = () => {}
) {
  const [isUpdateOnGoing, setIsUpdateOnGoing] = useState(false);

  const startUpdate = () => {
    setIsUpdateOnGoing(true);
  };
  /** observes the result returned by the async callback, and runs onSuccess or onFailed, based on the promise status */
  const observeUpdateEnd = (callback: () => Promise<any>) => {
    startUpdate();

    Promise.allSettled([callback()]).then((values) => {
      values.forEach((promise) => {
        if (promise.status === 'fulfilled') {
          onSuccess && onSuccess(promise.value);
        } else {
          onFailed && onFailed(promise.reason);
        }

        setIsUpdateOnGoing(false);
      });
    });
  };

  return { observeUpdateEnd, isUpdateOnGoing };
}
