import { Provider } from 'react-redux';
import store from 'redux/store';
import { IAppProvider } from './types';
import { AppContextCombinedProvider } from './CombinedProviders';

const AppProvider = (props: IAppProvider) => {
  const { children } = props;
  return (
      <Provider store={store}>
        <AppContextCombinedProvider>{children}</AppContextCombinedProvider>
      </Provider>
  );
};

export default AppProvider;
