import React from 'react';
import ContentLoader from 'react-content-loader';

export const EventListSkeleton = (props) => {
  return (
    <ContentLoader
      speed={1}
      width={'100%'}
      height={'300'}
      // viewBox="0 0 100% 100%"
      backgroundColor={props.backgroundColor || '#ffffff33'}
      foregroundColor={props.foregroundColor || '#BDA7E833'}
      {...props}
    >
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="45" />
      <rect x="0" y="50" rx="10" ry="10" width="100%" height="45" />
      <rect x="0" y="100" rx="10" ry="10" width="100%" height="45" />
      <rect x="0" y="150" rx="10" ry="10" width="100%" height="45" />
      <rect x="0" y="200" rx="10" ry="10" width="100%" height="45" />
    </ContentLoader>
  );
};
