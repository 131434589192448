import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Form, Input, Select } from 'antd';
import { SelectDropdown } from 'components/Dropdown';
import { useModalContext } from 'components/Modal/ModalProvider';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { onFinish } from './utils';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
type WorkQueueFormProps = {
  scope: string,
  workQueueId: number | null,
  workQueueName: string;
};

export const WorkQueueForm: React.FC<WorkQueueFormProps> = ({
  scope,
  workQueueId,
  workQueueName
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { toggleModal } = useModalContext();
  const [form] = Form.useForm();
  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();
  const onFinishFormActions = { dispatch, toggleModal };
  return (
    <Form
      form={form}
      id="workQueueForm"
      layout="horizontal"
      labelAlign="left"
      labelCol={{ span: 6 }}
      initialValues={
        scope !== 'add'
          ? {
            name: workQueueName,
          }
          : null
      }
      onFinish={data => {
        onFinish(scope, data, workQueueId, onFinishFormActions);
      }}
      onFinishFailed={validationError =>
        onFormFinishFailedValidation(validationError, dispatch)
      }
    >
      {scope === 'remove' ? (
        <>
          <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
            <FormattedMessage
              id="WorkQueueRemoveConfirmText"
              values={{
                queueName: workQueueName
              }}
            />
          </p>
        </>)
        : (
          <>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="SettingsCategoryForm_Name" />}
              name="name"
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'WorkQueueInputNameValidation'
                })
              })}
              isReadOnly={
                isMobile
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
            >
              <Input minLength={1} />
            </ColFormItemComponent>
          </>
        )}
    </Form>
  );
};
