import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import * as React from 'react';

import ContentLoader from 'react-content-loader';

const PlaceholderWrapper: React.FC<any> = props => {
  const { children } = props;
  const { theme } = useThemeContext();
  return (
    <ContentLoader
      speed={1}
      backgroundColor={
        theme === 'light' ? 'rgba(189, 167, 232, 0.2)' : '#ffffff33'
      }
      foregroundColor="#BDA7E833"
      {...props}
    >
      {children}
    </ContentLoader>
  );
};

export default PlaceholderWrapper;
