import * as React from 'react';

import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

import RequestButton from './RequestButton';
import { MobileCardRequestProps } from './interfaces/MobileCardRequest.interface';
import { parseTimeOffStatistics } from 'components/Widgets/TimeManagementWidget/utils';

import styles from './style.module.less';
import Statistics from './Statistics';

const MobileCardRequest: React.FC<MobileCardRequestProps> = props => {
  const { data } = props;

  const { theme } = useThemeContext();

  const statisticsData = (!!data && parseTimeOffStatistics(data)) || [];

  return (
    <div className={`${styles.wrapper} ${theme}`}>
      <div className={styles.col_container_info}>
        <div className={styles.col}>
          <div className={styles.header}>
            <div className={styles.header__icon}>{props.icon}</div>
            <div className={styles.header__title}>{props.title}</div>
          </div>
        </div>

      </div>
        <div className={styles.col}>
          <RequestButton
            index={props.index}
            setselecteditem={props.setselecteditem}
            setModalOpen={props.setModalOpen}
            setModalProps={props.setModalProps}
            request={props.request}
            scope={props.scope}
            title={props.title}
            allowUnfinishedRequests={props.allowUnfinishedRequests}
            filteredrequests={props.filteredrequests}
          />
        </div>
      <div className={styles.col}>
        <Statistics data={statisticsData} request={props.request} />
      </div>
    </div>
  );
};

export default MobileCardRequest;
