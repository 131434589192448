import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { DashboardSvg } from 'Icons/Menu/dashboard';
import { InventorySvg } from 'Icons/Menu/inventory';
import { ProfileSvg } from 'Icons/Menu/profile';
import { HeaderMenuContext } from 'components/Layout/Header/HeaderMenuContext';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { items } from '../../Menu/MenuItems';

const HeaderMenuButton = () => {
  const [headerIcon, setHeaderIcon] = useState<ReactNode>(<DashboardSvg />);
  const { isOpen, handleIsOpen } = useContext(HeaderMenuContext);
  const { theme } = useThemeContext();

  const location = useLocation();
  const pathname = location.pathname;
  const menuItems = items();

  useEffect(() => {
    if (pathname.includes('viewProfile')) {
      setHeaderIcon(<ProfileSvg />);
    } else if (pathname.includes('inventory')) {
      setHeaderIcon(<InventorySvg />);
    } else {
      const foundItem = menuItems.find(item => pathname.startsWith(String(item.key)));
      if (foundItem) {
        setHeaderIcon(foundItem.icon);
      };
    };
  }, [location]);

  return (
    <button
      id='side-navigation-toggle--small'
      aria-controls='side-navigation-bar--small'
      aria-expanded={isOpen}
      className={`header-mobile-menu ${theme}`}
      onClick={handleIsOpen}
    >
      {headerIcon}
    </button>
  );
};

export default HeaderMenuButton;
