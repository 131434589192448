
interface SwitchElement {
  id: number;
  value?: string;
  text?: string;
  icon?: any;
}
export interface SwitchProps {
  values: [SwitchElement, SwitchElement];
  defaultValue?: string;
  onChange?: () => void;
}

export const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
};