import MainRequest from 'services/request';
import { IApiResponse, IRequestOptions } from 'services/types';

const request = new MainRequest();

export const commonRequest = <T>(
  method: string,
  payloadData: any,
  url: string
): IApiResponse<T> => {
  const { headers, payload, anonymous, parameters, customResponseType } =
    payloadData ?? {};

  const options: IRequestOptions = {
    customResponseType,
    headers,
    payload,
    anonymous,
    parameters
  };

  switch (method) {
    case 'POST':
      return request.post(url, options);
    case 'GET':
      return request.get(url, options);
    case 'UPDATE':
      return request.update(url, options);
    case 'DELETE':
      return request.delete(url, options);
  }
};
