export const ArrowLeft = (props) => {

  const { className, ...otherProps } = props;

  return (
    <span
      aria-label='wfh'
      className={`anticon anticon-wfh ${className || ''}`.trim()}
      role='img'
      {...otherProps}
    >
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ verticalAlign: 'bottom' }}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.8033 10.2612C8.39889 10.6692 8.39891 11.3308 8.8033 11.7388L11.7322 14.694C12.1366 15.102 12.7923 15.102 13.1967 14.694C13.6011 14.2859 13.6011 13.6244 13.1967 13.2164L11 11L13.1967 8.78361C13.6011 8.37557 13.6011 7.71404 13.1967 7.30602C12.7923 6.898 12.1366 6.89798 11.7322 7.30602L8.8033 10.2612Z'
          fill='currentColor'
        />
      </svg>
    </span>
  )
};
