import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Empty, Table } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import {
  FilterValue,
  GetRowKey,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig
} from 'antd/lib/table/interface';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useCompanySettings } from 'settings';
import { ArrowLeft } from '../../Icons/Documents/arrowLeft';
import { ArrowRight } from '../../Icons/Documents/arrowRight';
import { LocalStorageTableHelper as utils } from './tableUtils';

import './TableComponent.less';
import './tableStyles.less';

export interface TableParams {
  pagination?: TablePaginationConfig;
  filters?: Record<string, FilterValue>;
  sorter?: SorterResult<any> | SorterResult<any>[];
  extra?: TableCurrentDataSource<any>;
}

type TableComponentProps = {
  rowKey: GetRowKey<any> | string;
  bordered?: boolean;
  tableSize?: SizeType;
  columns: (...props) => ColumnsType<any>;
  dataSource: any[];
  data?: any;
  params?: any;
  role?: string;
  rowClassName?: string;
  isModalOpen?: boolean;
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
  hookUseStateActions?: any; // Dispatch<SetStateAction<any>>[];
  title?: FC;
  baseTitle?: string;
  scroll?: object;
  onRow?: any;
  style?: React.CSSProperties;
  modalOpenValues?: any;
  setModalOpenValues?: Dispatch<SetStateAction<any>>;
  setPage?: any;
  page?: number;
  className?: string;
  pagination?: any;
  setDataCurrent?: Dispatch<SetStateAction<any[]>>;
  isSorterData?: boolean;
  setTotalRows?: any;
  teamsFilter?: any;
  nextApproverFilter?: any;
  onPaginationChange?: any;
  onNrItemsPerPageChange?: any;
  footer?: any;
  totalItems?: number;
  showSorterTooltip?: boolean;
  pageSize?: number;
  intl?: any;
  onChange?: (props: TableParams) => void;
  context?: string;
};

const TableComponent: FC<TableComponentProps> = ({
  teamsFilter,
  nextApproverFilter,
  rowKey,
  bordered,
  tableSize,
  data,
  role,
  params,
  dataSource,
  rowClassName,
  columns,
  isModalOpen,
  setIsModalOpen,
  hookUseStateActions,
  title,
  baseTitle,
  scroll,
  onRow,
  style,
  modalOpenValues,
  setModalOpenValues,
  setPage,
  page,
  className,
  pagination,
  setDataCurrent,
  isSorterData,
  setTotalRows,
  onPaginationChange,
  onNrItemsPerPageChange,
  footer,
  totalItems,
  showSorterTooltip = true,
  pageSize,
  intl,
  onChange,
  context
}) => {
  
  const [currentPageSize, setCurrentPageSize] = React.useState(utils.getPaginationTableSize(
    className,
    dataSource?.length
  ));

  React.useEffect(() => {
    if (className && dataSource && dataSource.length) {
      setCurrentPageSize(utils.getPaginationTableSize(
        className,
        dataSource?.length
      ));
    };
  }, [className, dataSource]);

  React.useEffect(() => {
    if (pageSize) {
      utils.setPaginationTableSize(
        className,
        pageSize
      );
      setCurrentPageSize(pageSize);
    };
  }, [pageSize]);

  const { theme } = useThemeContext();
  const { countryCode } = useCompanySettings();
  useEffect(() => {
    const tableRows = document.querySelectorAll('tr');

    let incrementBy = 0.1;
    let columnOpacity = 0.1;

    switch (tableRows.length) {
      case 10:
        incrementBy = 0.1;
        break;
      case 20:
        incrementBy = 0.05;
        break;
      case 50:
        incrementBy = 0.025;
        break;
      case 100:
        incrementBy = 0.025;
        break;
      default:
        incrementBy = 0.1;
    }

    tableRows.forEach((row, rowIndex) => {
      row.querySelectorAll('td').forEach((column, columnIndex) => {
        // window
        //   .getComputedStyle(column, '::before')
        //   .setProperty('opacity', columnOpacity.toString());
      });
      columnOpacity += incrementBy;
    });
  }, []);

  const { deviceOS, isMobile, isTabletDevice, isSmallLaptop } =
    useMobileContext();

  // Set Number of rows when filters are modified from search toolbar and the data sorce length === 0
  if (
    dataSource &&
    dataSource?.length === 0 &&
    typeof setTotalRows === 'function'
  ) {
    setTotalRows(0);
  }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <ArrowLeft />;
    }
    if (type === 'next') {
      return <ArrowRight />;
    }
    return originalElement;
  };

  const removeFocusFromPagination = () => {
    const pagination = document.querySelector(
      '.ant-pagination-options-size-changer'
    ) as HTMLElement;

    if (pagination) {
      pagination.addEventListener('click', () => {
        if (pagination.classList.contains('ant-select-focused'))
          pagination.classList.remove('ant-select-focused');
      });
    }
  };

  removeFocusFromPagination();
  return (
    context === 'colleaguesRequests' ? (
      dataSource?.length ? (
        <Table
          showSorterTooltip={showSorterTooltip}
          className={`table-component ${theme} ${className ?? ``}`}
          style={{ ...style }}
          tableLayout="fixed"
          pagination={
            !pagination
              ? {
                  current: page,
                  pageSize: currentPageSize,
                  className: `${deviceOS === 'IOS' ? 'pagination-ios' : ''}`,
                  hideOnSinglePage: false,
                  showSizeChanger: true,
                  size: 'small',
                  showTotal: (total, range) => {
                    // Set Number of rows when filters inside table are modified and result of filters has number of Rows  > 0
                    setTimeout(() => {
                      if (typeof setTotalRows === 'function') {
                        return setTotalRows(total); // store the total count of filtred rows in a hook in parent component
                      }
                    }, 0);
                    return '';
                  },
                  total: totalItems || undefined,
                  onChange(currentPage, pageSize) {
                    onPaginationChange && onPaginationChange(currentPage, pageSize);
                    onNrItemsPerPageChange && onNrItemsPerPageChange(pageSize);
                    utils.setPaginationTableSize(className, pageSize);
                    setCurrentPageSize(pageSize);
                    return page ? setPage(currentPage) : '';
                  },
                  itemRender: itemRender
                }
              : false
          }
          rowKey={rowKey}
          bordered={bordered ?? false}
          columns={columns(
            data,
            role,
            params,
            isModalOpen,
            setIsModalOpen,
            hookUseStateActions,
            dataSource,
            modalOpenValues,
            teamsFilter,
            nextApproverFilter,
            setModalOpenValues,
            isTabletDevice,
            isSmallLaptop,
            intl,
            countryCode
          )}
          size={tableSize ?? 'small'}
          scroll={scroll}
          dataSource={dataSource}
          rowClassName={rowClassName}
          onRow={onRow}
          onChange={(pagination, filters, sorter, extra) => {
            onChange && onChange({ pagination, filters, sorter, extra });
    
            // Set Number of rows when filters inside table are modified and result of filters has number of Rows  > 0
            if (
              extra.currentDataSource?.length === 0 &&
              typeof setTotalRows === 'function'
            ) {
              setTotalRows(0);
            }
            return setDataCurrent ? setDataCurrent(extra?.currentDataSource) : {};
          }}
          footer={footer}
        />
      ) : (
        <Empty description={false} />
      )
    ) : (
      <Table
        showSorterTooltip={showSorterTooltip}
        className={`table-component ${theme} ${className ?? ``}`}
        style={{ ...style }}
        tableLayout="fixed"
        pagination={
          !pagination
            ? {
                current: page,
                pageSize: currentPageSize,
                className: `${deviceOS === 'IOS' ? 'pagination-ios' : ''}`,
                hideOnSinglePage: false,
                showSizeChanger: true,
                size: 'small',
                showTotal: (total, range) => {
                  // Set Number of rows when filters inside table are modified and result of filters has number of Rows  > 0
                  setTimeout(() => {
                    if (typeof setTotalRows === 'function') {
                      return setTotalRows(total); // store the total count of filtred rows in a hook in parent component
                    }
                  }, 0);
                  return '';
                },
                total: totalItems || undefined,
                onChange(currentPage, pageSize) {
                  onPaginationChange && onPaginationChange(currentPage, pageSize);
                  onNrItemsPerPageChange && onNrItemsPerPageChange(pageSize);
                  utils.setPaginationTableSize(className, pageSize);
                  setCurrentPageSize(pageSize);
                  return page ? setPage(currentPage) : '';
                },
                itemRender: itemRender
              }
            : false
        }
        rowKey={rowKey}
        bordered={bordered ?? false}
        columns={columns(
          data,
          role,
          params,
          isModalOpen,
          setIsModalOpen,
          hookUseStateActions,
          dataSource,
          modalOpenValues,
          teamsFilter,
          nextApproverFilter,
          setModalOpenValues,
          isTabletDevice,
          isSmallLaptop,
          intl,
          countryCode
        )}
        size={tableSize ?? 'small'}
        scroll={scroll}
        dataSource={dataSource}
        rowClassName={rowClassName}
        onRow={onRow}
        onChange={(pagination, filters, sorter, extra) => {
          onChange && onChange({ pagination, filters, sorter, extra });
  
          // Set Number of rows when filters inside table are modified and result of filters has number of Rows  > 0
          if (
            extra.currentDataSource?.length === 0 &&
            typeof setTotalRows === 'function'
          ) {
            setTotalRows(0);
          }
          return setDataCurrent ? setDataCurrent(extra?.currentDataSource) : {};
        }}
        footer={footer}
      />
    )
  )
};

export default TableComponent;
