import { ColumnsType } from 'antd/es/table';
import {
  headerTranslations,
  headerTranslationsInitialColumns,
  torColsTableFilters,
} from '../utils';

export const initialAggregatedColumns = (aggColumnsWithData: Set<string>, countryCode: string): ColumnsType<any> => {
  const isRO = countryCode === 'RO';

  //TO DO: aggregated columns with data for Gambit
  return isRO
    ? Array.from(aggColumnsWithData)
        .filter((value) =>
          Object.keys(headerTranslations).includes(value as string)
        )
        .map((item) => {
          return {
            title: () => (
              <p
                className="text-color-center"
                style={{ overflowWrap: 'break-word' }}
              >
                {headerTranslations[item as string] || ''}
              </p>
            ),
            key: item as string,

            width: 120,
            render: (_, record) => {
              return (
                <span data-col={item} className="cell-padding-center">
                  {record.aggregatedColumns[item as string]}
                </span>
              );
            },
          };
        })
        .filter((record) => record !== undefined)
    : Object.entries(headerTranslationsInitialColumns)
        .map(([key]) => {
          if (aggColumnsWithData.has(key))
            return {
              title: () => (
                <p
                  className="text-color-center"
                  style={{ overflowWrap: 'break-word' }}
                >
                  {headerTranslations[key] || ''}
                </p>
              ),
              key: key,
              width: 100,
              render: (_, record) => {
                return (
                  <span data-col={key} className="cell-padding-center">
                    {record.aggregatedColumns[key]}
                  </span>
                );
              },
            };
        })
        .filter((record) => record !== undefined);
};

export const newAggregatedColumns = (data, countryCode: string): ColumnsType<any> => {
  const isRO = countryCode === 'RO';
  return Object.entries(data).map(([key, value]) => {
    return {
      title: () => (
        <p className="text-color-center" style={{ overflowWrap: 'break-word' }}>
          {headerTranslations[value as string] || value}
        </p>
      ),
      key: value as string,
      width: 120,
      render: (_, record) => {
        /*REMARK FOR THE FUTURE TO AVOID WASTING TIME AS I DID: for Jivy, the new agg columns (the ones that are added via filters) 
        take their source from data.aggregatedColumns while gambit takes from data.torColumns AND data.aggregatedColumns*/
        return (
          <span data-col={value as string} className="cell-padding-center">
            {!isRO
              ? record.aggregatedColumns[value as string]
              : record.torColumns[value as string] ??
                record.aggregatedColumns[value as string]}
          </span>
        );
      },
    };
  });
};

export const torAggregatedColumns = (torColumns): ColumnsType<any> => {
  return torColumns.map((value) => {
    return {
      title: () =>
        value !== 'PersonalLeave' ? (
          <p
            className="text-color-center"
            style={{ overflowWrap: 'break-word' }}
          >
            {value}
          </p>
        ) : (
          <p className="text-color-center" style={{ whiteSpace: 'nowrap' }}>
            {headerTranslations[value]}
          </p>
        ),
      key: value,
      ...torColsTableFilters(value),
      width: 60,
      render: (_, record) => {
        return (
          <span data-col={value} className="cell-padding-center">
            {record.torColumns[value]}
          </span>
        );
      },
    };
  });
};
