import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const notificationReducer = createSlice({
  name: 'notification',
  initialState: {},
  reducers: {
    setNotificationProperties(
      state,
      action: PayloadAction<{
        isNotificationDisplayed: boolean;
        requestStatus: string;
        notificationMessage?: string;
      }>
    ) {
      return {
        ...state,
        isNotificationDisplayed: action.payload.isNotificationDisplayed,
        requestStatus: action.payload.requestStatus,
        notificationMessage: action.payload.notificationMessage ?? ''
      };
    }
  }
});

export const { setNotificationProperties } = notificationReducer.actions;
export default notificationReducer.reducer;
