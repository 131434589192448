import { ArrowLeft } from 'Icons/Documents/arrowLeft';
import React from 'react';

interface ItemTypeProps {
  preffixIcon: JSX.Element;
  key: string;
  label: string;
}

type PermittedElementTags = 'div' | 'li';

interface DropdownData {
  isDropdownOpen: boolean;
  ref: React.RefObject<HTMLDivElement | HTMLLIElement | null>;
  dropdownHeight: number;
  dropdownWidth: number;
}

interface DropdownProps {
  Tag?: PermittedElementTags | React.ComponentType<any>;
  title?: React.ReactNode;
  items?: ItemTypeProps[];
  isDropdownOpen?: boolean;
  preffixIcon?: React.ReactNode;
  style?: React.CSSProperties;
  suffixIcon?: React.ReactNode;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  onChange?: (props: DropdownData) => void;
  onClick?: () => void;
}

const Dropdown = ({
  Tag = 'div',
  title,
  items,
  isDropdownOpen = null,
  preffixIcon,
  style,
  suffixIcon = null,
  children,
  className = '',
  onChange,
  onClick
}: DropdownProps) => {
  const ref = React.useRef<HTMLDivElement | HTMLLIElement | null>(null);

  const [_isDropdownOpen, setDropdownOpen] = React.useState<boolean>(false);

  const handleToggleDropdown = React.useCallback(() => {
    setDropdownOpen(prevState => !prevState);
    onClick && onClick();
  }, [onClick]);

  React.useEffect(() => {
    if (isDropdownOpen !== null) {
      setDropdownOpen(isDropdownOpen);
    }
  }, [isDropdownOpen]);

  React.useEffect(() => {
    if (ref?.current && onChange) {
      onChange({
        isDropdownOpen: _isDropdownOpen,
        ref: ref,
        dropdownHeight: ref.current.offsetHeight,
        dropdownWidth: ref.current.offsetWidth
      });
    }
  }, [_isDropdownOpen]);

  return (
    <Tag ref={ref} style={style} className={`dropdown ${className}`}>
      <button
        aria-expanded={_isDropdownOpen}
        className="dropdown__toggle"
        onClick={handleToggleDropdown}
      >
        {preffixIcon ? (
          <span className="dropdown__toggle-icon">{preffixIcon}</span>
        ) : null}
        <span className="dropdown__toggle-title">{title}</span>
        <ArrowLeft className="dropdown__toggle-arrow" />
      </button>
      {_isDropdownOpen ? (
        <ul aria-hidden={!_isDropdownOpen} className="dropdown-menu">
          {React.Children.count(children) > 0 ? children : null}
        </ul>
      ) : null}
    </Tag>
  );
};

export default Dropdown;
