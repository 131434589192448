import * as React from 'react';
import { Avatar } from 'antd';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { getInitials } from './utils';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

import './index.less';

interface IProps {
  size?: number;
  src?: string;
}

const UserAvatar: React.FC<IProps> = ({ size, src }) => {
  const { theme } = useThemeContext();
  const userStore = useSelector((state: RootState) => state.user?.loggedUser);

  return (
    <React.Fragment>
      <Avatar
        size={size || 32}
        src={src || userStore?.externalAvatarUrl}
        className={`avatar ${theme}`}
      >
        {getInitials(userStore?.lastName, userStore?.firstName)}
      </Avatar>
    </React.Fragment>
  );
};

export default UserAvatar;
