import {
  ConfigProviderSettings,
  CustomIntlContextProvider,
  IntlContextProvider,
  MobileContextProvider,
  ThemeContextProvider
} from 'AppProvider/ConfigProviderSettings';
import { ColleaguesModalContextProvider } from 'components/AllUsersList/ColleaguesModalContext';
import { FeatureTogglePovider } from 'components/FeatureToggle/FeatureToggleProvider';
import { ConfirmationModalContextProvider } from 'components/Modal/ConfirmModal/ConfirmationModalProvider';
import { ModalContextProvider } from 'components/Modal/ModalProvider';
import { PreviewMediaOverlayProvider } from 'components/PreviewMediaOverlay/context';
import { TicketingModalContextProvider } from 'components/Ticketing/TicketingModalContext';
import { SwitchContextProvider } from 'components/UIComponents/SwitchComponent/SwitchContextProvider';
import { ProfileTimeOffProvider } from 'context/Profile/context';
import { TimeOffPersonalProvider } from 'context/TimeManagement/PersonalRequests/context';
import { TimeOffColleaguesProvider } from 'context/TimeManagement/ColleaguesRequests/context';
import { CalendarModalContextProvider } from 'pages/Calendar/CalendarModalContext';
import { ClockOutContextProvider } from 'pages/TimeManagement/PersonalTimeOffRequests/PersonalTImeOffRequestsProvider';
import { ComponentProps, FC } from 'react';
import { AuthContextProvider } from 'services/providers/AuthProvider';

const combineComponents = (...components: FC[]): FC<any> => {
  return components.reduce(
    (AccumulatedComponents: FC<any>, CurrentComponent: FC<any>) => {
      return ({ children }: ComponentProps<FC<any>>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

const appProviders = [
  AuthContextProvider,
  ThemeContextProvider,
  CustomIntlContextProvider,
  IntlContextProvider,
  ConfigProviderSettings,
  ModalContextProvider,
  ConfirmationModalContextProvider,
  ClockOutContextProvider,
  SwitchContextProvider,
  MobileContextProvider,
  TimeOffColleaguesProvider,
  CalendarModalContextProvider,
  PreviewMediaOverlayProvider,
  TicketingModalContextProvider,
  ColleaguesModalContextProvider,
  FeatureTogglePovider,
  ProfileTimeOffProvider,
  TimeOffPersonalProvider
];
export const AppContextCombinedProvider = combineComponents(...appProviders);
