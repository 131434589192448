export const CheckMark = ({ stroke }) => {
  return (
    <span role="img" aria-label="addItem" className="anticon flag-icon">
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 1L3.03374 7L1 4.95476"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
