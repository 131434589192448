import { downloadFile } from './downloadFiles';
import { Buffer } from 'buffer';
const getFileNameFromHeaders = (headers) => {
  var filename = '';
  var disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return Buffer.from(filename, 'base64');
};
export const downloadAndParseFile = (
  templateRequest: Promise<{
    data: unknown;
    error: unknown;
    headers?: unknown;
  }>
) =>
  templateRequest.then((res) => {
    if (!res.data || res.error) {
      return;
    }

    const byteArray = new Uint8Array(res.data as any);
    const blob = new Blob([byteArray]);
    const url = window.URL.createObjectURL(blob);

    downloadFile(url, getFileNameFromHeaders(res.headers));
  });
