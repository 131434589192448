import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { IModalComponentProps } from 'components/Modal';
import { WorkQueueConfigurationForm } from 'components/SetitingsComponents/WorkQueue/WorkQueueConfigurationForm';
import ThemedIcon from 'components/UIComponents/Icon';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import _sort from 'utils/sorter';

export const useConfigurationsTableColumns = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<IModalComponentProps>>,
  editModalWidth: number | string,
  deleteModalWidth: number | string,
  queueIdFromURL: number
): ColumnsType<any> => {
  const { isMobile, isTabletDevice } = useMobileContext();
  const intl = useIntl();
  // Row Actions
  // Remove category open modal and set modal props
  const handleDeleteClick = record => {
    setModalProps(state => ({
      ...state,
      scope: 'remove',
      workQueueId: record?.workQueueId,
      modalTitle: "Remove Work Queue",
      cancelButtonText: <FormattedMessage id="cancel" />,
      submitButtonText: <FormattedMessage id="remove" />,
      width: deleteModalWidth
    }));
    setModalProps(state => ({
      ...state,
      categoryId: null,
      key: 'workQueueConfigurationFormModal',
      submitButtonText: <FormattedMessage id="remove" />,
      cancelButtonText: <FormattedMessage id="close" />,
      modalTitle: <FormattedMessage id="Settings_WorkQueues__Modal_title_Remove_Configuration" />,
      width: deleteModalWidth,
      formKeyId: 'workQueueConfigurationForm',
      children: (
        <WorkQueueConfigurationForm
          scope={'remove'}
          workQueueId={Number(queueIdFromURL)}
          configurationId={record?.workQueueConfigurationId}
          configurationName={record?.ticketCategoryKey}
        />
      )
    }));
    setIsOpen(true);
  };

  //------End Row Actions
  const tableColumns = [
    {
      title: <FormattedMessage id="SettingsSubcategory_Category" />,
      dataIndex: 'ticketCategoryName',
      sorter: (a, b) => _sort(a.ticketCategoryName, b.category, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => 
     <p> {intl.formatMessage({id: record?.ticketCategoryKey, defaultMessage: record.ticketCategoryName})} </p>
    },
   
    {
      title: <FormattedMessage id="Settings_WorkQueues_Label_Subcategory" />,
      dataIndex: 'ticketSubCategoryName',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      className: 'hoverEdit',
      render: (_, record) => (
        <>
       
            <p> {record.ticketSubCategoryId ? intl.formatMessage({id: record?.ticketSubCategoryKey, defaultMessage: record.ticketSubCategoryName}) : '-'}</p>
            <div className={'showEdit'} style={{ display: 'none' }}>
            <div className="actionButtons">
              <ButtonWithExpandedText
                embedSrc={<ThemedIcon icon={TimeOffDeleteIcon} />}
                displayedText={<FormattedMessage id="remove" />}
                type="link"
                onClick={() => handleDeleteClick(record)}
              />
            </div>
          </div>
         
        </>
      )
    }
  ];
  return [...tableColumns];
};
