export const TimeSvg = () => {
  return (
    <span
      role="img"
      aria-label="time"
      className="anticon anticon-time"
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.67781 9.59997L7.27781 8.79997V5.45481M13.6778 7.99997C13.6778 4.46535 10.8124 1.59998 7.27781 1.59998C3.74318 1.59998 0.877808 4.46535 0.877808 7.99997C0.877808 11.5346 3.74318 14.4 7.27781 14.4C9.64671 14.4 11.715 13.1129 12.8216 11.2M11.9221 7.29764L13.5221 8.89764L15.1221 7.29764"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
