import { Button, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { getColumnSearchProps } from 'components/SearchTable';
import { getTitleColumns } from 'components/TitlesColumns/utils';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import _sort from 'utils/sorter';
import { allowPermision, rolesAllow, userRoles } from 'utils/userManagement';
import { getFilters, getFiltersWithSearch } from '../utils';
import { titlesKeysofColumnsHardware } from './utils';

import ThemedIcon from 'components/UIComponents/Icon';
import { MinusCircleContained } from 'Icons/Inventory/MinusCircleContained';
import { ColleagueAssignIcon } from 'Icons/Inventory/ColleagueAssignIcon';
import { TeamAssignIcon } from 'Icons/Inventory/TeamAssignIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';

const { Text } = Typography;

const getAssigneePerson = data => {
  const assignee =
    data && data.length
      ? data.reduce((initialValue, currentValue) => {
          if (
            currentValue.activeAssignment === true &&
            currentValue.assignType !== 'None'
          ) {
            return [currentValue];
          }
          return initialValue;
        }, {})
      : '';

  return assignee;
};

export const columnsHardware = (
  data,
  role = null,
  params,
  isOpen,
  setIsOpen,
  stateActions,
  dataSource
): ColumnsType<any> => {
  const tableColumnsHardware = [
    {
      title: getTitleColumns('assetName', 'hardware'),
      dataIndex: 'assetName',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.assetName, b.assetName, 'string'),
      ...getColumnSearchProps('assetName', params),
      render: (_, record, index) => {
        return (
          record.assetId && (
            <Button
              key={`view-hardware-btn-${index}`}
              className="full-content-width-button"
              type="link"
              onClick={() => {
                stateActions?.setModalProps(state => ({
                  ...state,
                  scope: 'view',
                  hardwareAssetId: parseInt(record?.assetId ?? index + 1),
                  modalTitle: <FormattedMessage id="ViewAsset" />,
                  cancelButtonText: <FormattedMessage id="cancel" />,
                  formKeyId: 'hardwareForm'
                }));
                setIsOpen();
              }}
            >
              <Tooltip placement="top" title={record.assetName}>
                <Text underline className="text-medium-big ellipsis-cell-text">
                  {record.assetName}
                </Text>
              </Tooltip>
            </Button>
          )
        );
      }
    },
    {
      title: getTitleColumns('model', 'hardware'),
      dataIndex: 'model',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.model, b.model, 'string'),
      filterSearch: true,
      width: '10%',
      render: (_, record) => (
        <Tooltip placement="top" title={record.model ?? ''}>
          <Text className="text-medium-big line-clamp-1">
            {record.model ?? ''}
          </Text>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('partNumber', 'hardware'),
      dataIndex: 'partNumber',
      ...getColumnSearchProps('partNumber', params),
      render: (_, record) => (
        <Tooltip placement="top" title={record.partNumber}>
          <p className="line-clamp-1">{record.partNumber}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('categoryName', 'hardware'),
      dataIndex: 'categoryName',
      filters: getFilters(data, 'categoryName'),
      onFilter: (value: string, record) => {
        if (value === 'blank') {
          return record?.categoryName === null;
        }
        return record?.categoryName?.startsWith(value) || false;
      },
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.categoryName, b.categoryName, 'string'),
      render: (_, record) => (
        <Tooltip placement="top" title={record?.categoryName}>
          <p className="line-clamp-1">{record.categoryName ?? ''}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('details', 'hardware'),
      dataIndex: 'details',
      onFilter: (value: string, record) => record.details.indexOf(value) === 0,
      render: (_, record) => (
        <Tooltip placement="top" title={record.details}>
          <p className="line-clamp-1">{record.details}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('serialNumber', 'hardware'),
      dataIndex: 'serialNumber',
      ...getColumnSearchProps('serialNumber', params),
      render: (_, record) => (
        <Tooltip placement="top" title={record.serialNumber}>
          <p className="line-clamp-1">{record.serialNumber}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('inventoryNumber', 'hardware'),
      dataIndex: 'inventoryNumber',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.inventoryNumber, b.inventoryNumber, 'string'),
      ...getColumnSearchProps('inventoryNumber', params),
      ellipsis: {
        showTitle: false
      },

      render: (_, record) => (
        <Tooltip placement="top" title={record.inventoryNumber}>
          <p className="line-clamp-1">{record.inventoryNumber}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('invoiceNumber', 'hardware'),
      dataIndex: 'invoiceNumber',
      ellipsis: {
        showTitle: false
      },
      render: (_, record) => (
        <Tooltip placement="top" title={record.invoiceNumber}>
          <p className="line-clamp-1">{record.invoiceNumber}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('datePurchased', 'hardware'),
      dataIndex: 'datePurchased',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.datePurchased, b.datePurchased, 'date'),
      className: `${allowPermision(role, [userRoles.Admin, userRoles.Accountant]) ? 'hoverEdit' : ''}`,
      render: (_, record) => {
        return (
          <>
            <Tooltip
              placement="top"
              title={
                dayjs(record.datePurchased).isValid()
                  ? dayjs(record.datePurchased).format('DD-MM-YYYY')
                  : null
              }
            >
              <p className="line-clamp-1">
                {dayjs(record.datePurchased).isValid()
                  ? dayjs(record.datePurchased).format('DD-MM-YYYY')
                  : null}
              </p>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: getTitleColumns('assigneeName', 'hardware'),
      dataIndex: 'assigneeName',
      key: 'assigneeName',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.assigneeName, b.assigneeName, 'string'),
      filters: getFiltersWithSearch(data),
      onFilter: (value: string, record) => {
        if (value === 'blank') {
          return record?.assigneeName === null || record?.assigneeName === '';
        }
        return record?.assigneeName?.startsWith(value) || false;
      },
      filterSearch: true,
      width: '20%',
      className: `${allowPermision(role, [userRoles.Admin, userRoles.Accountant]) ? 'hoverEdit' : ''}`,
      render: (_, record, index) => {
        return (
          <>
            <div className={'hideContent line-clamp-1'}>
              {record.assignType === 'Team' ? (
                <>
                  {record.assigneeName ? (
                    <>
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            {record?.assigneeName}{' '}
                            <FormattedMessage id="team" />
                          </>
                        }
                      >
                        {record.assigneeName} (<FormattedMessage id="team" />)
                      </Tooltip>
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <Tooltip placement="top" title={record.assigneeName || '-'}>
                  {record.assigneeName || '-'}
                </Tooltip>
              )}
            </div>
            <div
              className={
                allowPermision(role, [userRoles.Accountant, userRoles.Admin])
                  ? 'showEdit'
                  : ''
              }
              style={{ display: 'none' }}
            >
              <div className="actionButtons">
                {record.assigneeName && record.assignType !== 'None' ? (
                  <>
                    <ButtonWithExpandedText
                      displayedText={
                        <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                      }
                      embedSrc={<ThemedIcon icon={MinusCircleContained} />}
                      type="link"
                      onClick={() => {
                        stateActions?.setModalProps(state => ({
                          ...state,
                          scope: 'deassignAsset',
                          hardwareAssetId: parseInt(
                            record?.assetId ?? index + 1
                          ),
                          modalTitle: <FormattedMessage id="UnassignAsset" />,
                          submitButtonText: (
                            <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                          ),
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm',
                          assignPerson: record.assigneeName
                        }));
                        setIsOpen();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ButtonWithExpandedText
                      displayedText={
                        <FormattedMessage id="table_SoftwHardwBtn_colleague_assign" />
                      }
                      embedSrc={<ThemedIcon icon={ColleagueAssignIcon} />}
                      type="link"
                      onClick={() => {
                        stateActions?.setModalProps(state => ({
                          ...state,
                          scope: 'assignColleague',
                          hardwareAssetId: parseInt(
                            record?.assetId ?? index + 1
                          ),
                          modalTitle: (
                            <FormattedMessage id="AssignToAColleague" />
                          ),
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                        setIsOpen();
                      }}
                    />
                    <ButtonWithExpandedText
                      displayedText={
                        <FormattedMessage id="table_SoftwHardwBtn_team_assign" />
                      }
                      embedSrc={<ThemedIcon icon={TeamAssignIcon} />}
                      type="link"
                      onClick={() => {
                        stateActions?.setModalProps(state => ({
                          ...state,
                          scope: 'assignTeam',
                          hardwareAssetId: parseInt(
                            record?.assetId ?? index + 1
                          ),
                          modalTitle: <FormattedMessage id="AssignToATeam" />,
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                        setIsOpen();
                      }}
                    />
                  </>
                )}
                <ButtonWithExpandedText
                  displayedText={<FormattedMessage id="edit" />}
                  embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                  key={`edit-hardware-btn-${index}`}
                  type="link"
                  onClick={() => {
                    stateActions?.setModalProps(state => ({
                      ...state,
                      scope: 'edit',
                      hardwareAssetId: parseInt(record?.assetId ?? index + 1),
                      modalTitle: <FormattedMessage id="EditAsset" />,
                      className: 'mobile-modal',
                      cancelButtonText: <FormattedMessage id="cancel" />,
                      submitButtonText: <FormattedMessage id="save" />,
                      formKeyId: 'hardwareForm',
                      assignPerson: record.assign
                    }));
                    setIsOpen();
                  }}
                />
              </div>
            </div>
          </>
        );
      }
    }
  ];

  const dataSourceKeys = dataSource?.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
    titlesKeysofColumnsHardware.some(item => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  return tableColumnsHardware.filter(checkForDataDisplay);
};
