import React from 'react';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import GridViewStyles from 'components/UIViews/GridView/GridView.module.less';

interface GridViewProps {
  style?: React.CSSProperties;
  title?: React.ReactElement;
  header?: React.ReactNode;
  body?: React.ReactNode;
};

const GridView = ({ title, header, style, body }: GridViewProps) => {
  const { theme } = useThemeContext();

  return (
    <section
      className={`${GridViewStyles['grid-view']} ${GridViewStyles[theme]}`}
      style={style}
    >
      {
        header ? (
          <>{header}</>
        ) : (
          <>
            {
              title ? (
                <div className={GridViewStyles['header']}>
                  <h2 className={GridViewStyles['title']}>{title}</h2>
                </div>
              ) : null
            }
          </>
        )
      }
      {body ? (<>{body}</>) : null}
    </section>
  );
};

export default GridView;
