import { analyzePromise } from 'services/utils/analyzePromise';
import axios from 'services/library/axios';
import { IRequestOptions } from 'services/types';
import 'services/interceptor/';
import { getAuthToken } from 'services/providers/AuthProvider';
export default class MainRequest {
  defHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Expose-Headers': 'Content-Disposition',
  };

  request = (method: string) => {
    return (
      url: string,
      {
        headers,
        payload,
        anonymous,
        parameters,
        customResponseType,
      }: IRequestOptions = {}
    ): Awaited<Promise<any>> =>
      new Promise((resolve, reject) => {
        const customHeaders = headers ?? {};
        const data = payload ? { data: payload } : {};
        const params = parameters ?? {};
        const responseType = customResponseType ?? 'json';
        const token = anonymous
          ? {}
          : { Authorization: 'Bearer ' + getAuthToken() };
        const reqOptions = {
          url,
          method,
          headers: { ...this.defHeaders, ...customHeaders, ...token },
          params,
          responseType,
          ...data,
        };

        const result = axios.request(reqOptions);
        analyzePromise(resolve, reject, result);
      });
  };

  get = this.request('GET');
  post = this.request('POST');
  update = this.request('PUT');
  delete = this.request('DELETE');
}
