import { Avatar, Typography } from 'antd';
import styles from './ticketComment.module.less';
import dayjs from 'dayjs';
import TicketAttachments from '../TicketAttachments';
import { useAuth } from 'services/providers/AuthProvider';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { getFileType } from 'pages/Ticketing/utils';
import { TicketingService } from 'services';
import { download_files } from 'utils/downloadFiles';
import { getTimezoneString } from 'utils/displayDate';

const TicketComment = forwardRef<any, any>(({ comment }, lastCommentRef) => {
  const auth = useAuth();
  const parentRef = useRef(null);

  const isAuthorSender =
    comment?.author?.email?.toLowerCase() === auth.user.email.toLowerCase();

  const commentContentSidePositionStyle = {
    alignSelf: isAuthorSender ? 'self-end' : 'self-start'
  };
  const commentContainerSidePositionStyle = isAuthorSender
    ? {
        marginLeft: 'auto'
      }
    : {
        marginRight: 'auto'
      };

  useImperativeHandle(
    lastCommentRef,
    () => ({
      showLastComment: () => {
        parentRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }),
    []
  );

  return (
    <div
      className={styles.container}
      ref={parentRef}
      style={commentContainerSidePositionStyle}
    >
      {!comment?.groupByTime && (
        <div
          className={styles.commentHeader}
          ref={lastCommentRef}
          style={{
            ...commentContentSidePositionStyle,
            flexDirection: isAuthorSender ? 'row-reverse' : 'row'
          }}
        >
          <Avatar
            size={26}
            src={comment.author.externalAvatarUrl}
            className={`${isAuthorSender ? 'isSenderComment' : ''}`}
          />
          <Typography.Title level={5}>
            {comment.author.lastName} {comment.author.firstName}
          </Typography.Title>
          <Typography>
            {dayjs(comment?.createdOn).format('DD/MM/YYYY HH:mm') +
              ' ' +
              getTimezoneString(dayjs(comment?.createdOn))}
          </Typography>
        </div>
      )}
      {comment?.message?.length > 0 && (
        <div
          className={`${styles.commentContent} ${
            isAuthorSender ? 'isSender' : ''
          }`}
          style={commentContentSidePositionStyle}
        >
          <Typography>{comment.message}</Typography>
        </div>
      )}
      {comment?.attachmentNames?.length > 0 && (
        <div
          className={styles.attachments}
          style={commentContentSidePositionStyle}
        >
          {/* {console.log(comment.attachmentNames)} */}
          <TicketAttachments
            perPage={
              parentRef?.current?.offsetWidth
                ? Math.round(parentRef?.current?.offsetWidth / 200)
                : 3
            }
            attachmentMaxWidth={120}
            isCommentAttachment={true}
            attachmentFiles={comment.attachmentNames.map(
              (attachment, fileOrder) => ({
                fileName: attachment,
                fileType: getFileType(attachment),
                fileOrder,
                onDownload: () => {
                  TicketingService.getTicketCommentAttachmentUrl({
                    commentId: comment?.commentId,
                    fileOrder: fileOrder
                  }).then(response =>
                    download_files([
                      {
                        link: response.data,
                        fileName: (response.data as string)
                          .split('/')
                          .at(-1)
                          .split('?sv=')[0]
                      }
                    ])
                  );
                }
              })
            )}
          />
        </div>
      )}
    </div>
  );
});
export default TicketComment;
