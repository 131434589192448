import dayjs, { Dayjs } from 'dayjs';
import {
  EventRecurrence,
  EventResponseDto,
  EventTimeFormat,
  EventTypes,
  ICalendarEventsPerDayResponse,
} from '../types';

export const getEventsPerDay = (
  value: Dayjs,
  data: ICalendarEventsPerDayResponse
): EventResponseDto[] => {
  const eventsPerDay = Object.keys(data)?.length
    ? data[dayjs(value).format('YYYY-MM-DDT00:00:00')]
    : [];
  return eventsPerDay;
};

export const isAllDayEvent = (event) =>
  event?.timeOffType ||
  event?.eventType === EventTypes.Holiday ||
  event?.timeFormat === EventTimeFormat.AllDay ||
  (event?.timeFormat as string) === 'None';

export const isCompanyOrHolidayEvent = (event: EventResponseDto) =>
  [EventTypes.Holiday, EventTypes.CompanyEvent].includes(
    event?.eventType as EventTypes
  );
export const isPersonalEventOfCurrentUser = (
  event: EventResponseDto,
  userId: number
) =>
  event?.eventType === EventTypes.PersonalEvent && event?.employeeId === userId;

export const isEventRecurrent = (event: EventResponseDto) =>
  ![EventRecurrence.DoesNotRepeat, 'None', null].includes(event?.recurrence);
