export const TimeOffApproveRevocationIcon = ({ stroke }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50001 13H2.5C1.67157 13 0.999995 12.3284 1 11.5L1.00006 2.5C1.00006 1.67157 1.67164 1 2.50006 1H9.25025C10.0787 1 10.7503 1.67157 10.7503 2.5V6.62502M7.75025 10.875L9.12526 12.25L12.1253 9.2499M3.62524 4.00001H8.12525M3.62524 6.25001H8.12525M3.62524 8.50002H5.87525"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
