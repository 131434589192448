import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { ArrowRight } from 'Icons/TimeManagementWidget/arrowRight';
import { Button, Col, Typography } from 'antd';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import { TimesheetComponentPlaceholder } from 'components/Placeholders';
import { TableCustom } from 'components/UIComponents/TableCustom';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { getNextMonth, getPreviousMonth } from '../utils';
import { generateColumns, getDataOfEmployees } from './utils';
import { useCompanySettings } from 'settings';
import { isMobile } from 'react-device-detect';
import { useColleaguesRequestsFilterContext } from '../../../../context/TimeManagement/ColleaguesRequests/context';
import { TimeOffColleaguesActions } from '../../../../context/TimeManagement/ColleaguesRequests/actions';

export const TimeSheetTableEntireComponents = ({
  setDateOfMonth,
  dateOfMonth,
  data,
  params,
  hookUseStateActions,
  months,
  status,
  setStatus
}) => {
  const { Title } = Typography;
  const { Text } = Typography;
  const navigate = useNavigate();
  const { deviceHeight, isMobile } = useMobileContext();
  const { theme } = useThemeContext();
  const { countryCode } = useCompanySettings();
  const intl = useIntl();
  const [datewasChanged, setDateWasChanged] = useState(false);

  const prevMonth = () => {
    const prevDate = getPreviousMonth(dateOfMonth.date);
    const month = dayjs(prevDate).format('MMMM');
    const year = dayjs(prevDate).year();

    setDateOfMonth({
      date: prevDate,
      month: month,
      year: year
    });
    setStatus('pending');
    triggerMethodUpdate();
  };

  const nextMonth = () => {
    const nextDate = getNextMonth(dateOfMonth.date);
    const month = dayjs(nextDate).format('MMMM');
    const year = dayjs(nextDate).year();

    setDateOfMonth({
      date: nextDate,
      month: month,
      year: year
    });
    setDateWasChanged(true);
    setStatus('pending');
    triggerMethodUpdate();
  };
  const { timeOffColleaguesDispatch } = useColleaguesRequestsFilterContext();
  const dispatch = useDispatch();

  const triggerMethodUpdate = () => {
    dispatch(setMethodUpdate(true));
    setDateWasChanged(true);
  };
  const displayAwaitingRequests = () => {
    const startOfMonth = dayjs(dateOfMonth.date)
      .startOf('month')
      .format('YYYY-MM-DDT00:00:00[Z]')
      .toString();
    const endOfMonth = dayjs(dateOfMonth.date)
      .endOf('month')
      .format('YYYY-MM-DDT00:00:00[Z]')
      .toString();

    timeOffColleaguesDispatch({
      type: TimeOffColleaguesActions.UPDATE_REQUEST_ID,
      payload: {
        requestId: null
      }
    });

    // Compute The End of the Month
    // const endOfMonth = dayjs.utc(dayjs().month(month).year(year)).toISOString();
    const searchParams = {
      customStatuses: 'AwaitingDecision',
      startDate: startOfMonth,
      endDate: endOfMonth
    };
    const pathname = `../../timeManagement/colleaguesRequests`;
    navigate({
      pathname,
      search: createSearchParams(searchParams).toString()
    });
  };

  const month = months[dayjs(dateOfMonth.date).get('month')];

  const handleMouseEnter = columnIndex => {
    const cells = document.querySelectorAll(
      `.table-component-header-cell:nth-child(${columnIndex + 1
      }), .table-component-col:nth-child(${columnIndex + 1
      }), .table-component-body-cell:nth-child(${columnIndex + 1})`
    );

    cells.forEach(cell => {
      cell.classList.add('highlight');
    });
  };

  const handleMouseLeave = () => {
    // Logic to remove highlight from all cells
    const cells = document.querySelectorAll(
      '.table-component-header-cell, .table-component-col, .table-component-body-cell'
    );

    cells.forEach(cell => {
      cell.classList.remove('highlight');
    });
  };

  const MemoizedTable = useMemo(
    () => (
      <>
        <ComponentWithStatus
          status={status}
          Placeholder={
            <TimesheetComponentPlaceholder
              height={`calc(${deviceHeight}px - 24.8em)`}
            />
          }
        >
          {data && data.length > 0 && (
            <TableCustom
              columns={generateColumns(
                data,
                params,
                hookUseStateActions,
                countryCode
              )}
              data={getDataOfEmployees(data)}
              className={`table_timeSheet`}
              columnRowClassName="header_col_timesheet"
              style={{
                maxHeight: isMobile
                  ? `calc(100vh - 25em)`
                  : `calc(100vh - 24.8em)`
              }}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          )}
        </ComponentWithStatus>
      </>
    ),
    [
      data,
      theme,
      params?.value,
      intl.locale,
      dateOfMonth?.date,
      status === 'error'
    ]
  );

  // Check 1: If dateOfMonthValue.month is not equal to the current month
  const isCurrentMonth =
    dateOfMonth.month === dayjs().startOf('month').format('MMMM').toString();

  // Check 2: If dateOfMonthValue.month is not equal to the previous month
  const previousMonth = dayjs()
    .startOf('month')
    .subtract(1, 'month')
    .format('MMMM')
    .toString();
  const isPreviousMonth = dateOfMonth.month === previousMonth;

  return (
    <TableLayoutPage
      className='timisheet_table_layout_page'
      title={<FormattedMessage id="table_TimesheetTitle_timesheet" />}
      displayToggle
      style={{
        minHeight: `calc(${deviceHeight}px - 185px)`
      }}
      childrenButton={
        <>
          <Col>
            <div className="flex-center-container">
              <Button
                type="text"
                icon={<ArrowLeft />}
                onClick={prevMonth}
                className="arrows-calendar"
              />
              <Title
                level={3}
                className={`align-padding-title ${theme} month-title `}
              >
                {month}, {dateOfMonth.year}
              </Title>
              <Button
                type="text"
                icon={<ArrowRight />}
                onClick={nextMonth}
                className="arrows-calendar"
              />
            </div>
          </Col>
          <Col flex="1 0 0">
            <div className="flex-center-container">
              {params.openTORCount && !!params.openTORCount ? (
                <Text
                  className="text-bold-normal text-align-end pointer"
                  style={{ lineHeight: 'normal' }}
                  onClick={displayAwaitingRequests}
                >
                  <FormattedMessage
                    id={
                      params.openTORCount === 1
                        ? 'TimeSheetSingleAttentionText'
                        : params.openTORCount > 1
                          ? 'TimeSheetAttentionText'
                          : undefined
                    }
                    values={{
                      requestCount: params.openTORCount ?? '10',
                      br: <br />
                    }}
                  />
                </Text>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </>
      }
    >
      {status === 'error' ? (
        <div className={`error-container ${theme} `}>
          <Text className="error_failed text-align-center">
            <FormattedMessage id="ServerErrorMessage_Something_went_wrong" />
          </Text>
        </div>
      ) : (
        <>
          {datewasChanged && !(isCurrentMonth || isPreviousMonth) ? (
            <>
              <ComponentWithStatus
                status={status}
                Placeholder={
                  <TimesheetComponentPlaceholder
                    height={`calc(${deviceHeight}px - 185px)`}
                  />
                }
              >
                {data && data.length > 0 && (
                  <TableCustom
                    columns={generateColumns(
                      data,
                      params,
                      hookUseStateActions,
                      countryCode
                    )}
                    data={getDataOfEmployees(data)}
                    className={`table_timeSheet`}
                    columnRowClassName="header_col_timesheet"
                    style={{
                      maxHeight: isMobile
                        ? `calc(100vh - 25em)`
                        : `calc(100vh - 24.8em)`
                    }}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                  />
                )}
              </ComponentWithStatus>
            </>
          ) : (
            <>{MemoizedTable}</>
          )}
        </>
      )}
    </TableLayoutPage>
  );
};
