export const EditIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39449 1.7071C9.78482 1.31654 10.4178 1.31628 10.8085 1.7065L12.2923 3.18862C12.6831 3.579 12.6834 4.21232 12.2929 4.60304L4.84546 12.0545C4.70617 12.1939 4.52883 12.289 4.33568 12.3279L1 13L1.67333 9.66827C1.71229 9.47552 1.80721 9.29854 1.94622 9.15945L9.39449 1.7071Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
