export interface ModalProps {
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  requestType?: string;
  messageSuccessfully?: string | JSX.Element;
  okText?: string | JSX.Element;
  cancelText?: string | JSX.Element;
  confirmRequestType?: string;
  hasToggleModal?: boolean;
  setStateForOpenModal?: any;
}

export const DATE_FORMAT = "DD/MM/YYYY";