import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Form, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { SelectDropdown } from 'components/Dropdown';
import { useModalContext } from 'components/Modal/ModalProvider';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TicketingWorkQueueService } from 'services';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import { onFinish } from './utils';

import ComponentWithStatus from 'components/ComponentWithStatus';
import { ConfigurationFormPlaceholder } from 'components/Placeholders/Page';
import '../WorkQueue.less';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';

type WorkQueueConfigurationFormProps = {
  scope: string;
  workQueueId: number | null;
  configurationId?: number | null;
  configurationName?: string | null;
};

export const WorkQueueConfigurationForm: React.FC<
  WorkQueueConfigurationFormProps
> = ({ scope, workQueueId, configurationId, configurationName }) => {
  const dispatch = useDispatch();
  const { toggleModal } = useModalContext();
  const [form] = Form.useForm();
  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();
  const [categories, setCategories] = useState([]);
  const onFinishFormActions = { dispatch, toggleModal };
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const { data, status } = useApiRequestHook<any[]>(
    TicketingWorkQueueService.getWorkQueueConfigurationCombinations,
    'true',
    [],
    {},
    null,
    [],
    scope !== 'add',
    false
  );

  const ITEM_HEIGHT = 37;
  const MAX_ITEMS = 4;
  const intl = useIntl();

  const listHeight =
    data?.length > MAX_ITEMS
      ? ITEM_HEIGHT * MAX_ITEMS
      : ITEM_HEIGHT * data?.length;
  useEffect(() => {
    if (data && data.length > 0 && status === 'success') {
      setCategories(data);
    }
  }, [data, status]);

  const filterOption = (input, option) => {
    const optionChildren = option.children?.props?.children || [];

    const categoryName = optionChildren[1]?.props?.id || '';
    const subCategoryName = optionChildren[4]?.props?.id || '';

    return (
      categoryName.toLowerCase().includes(input.toLowerCase()) ||
      subCategoryName.toLowerCase().includes(input.toLowerCase())
    );
  };

  return (
    <Form
      form={form}
      id="workQueueConfigurationForm"
      layout="horizontal"
      labelAlign="left"
      style={{ width: '100%', maxWidth: '100%' }}
      labelCol={{ span: 6 }}
      onFinish={data => {
        const values = scope === 'add' ? JSON.parse(data.configuration) : [];
        onFinish(scope, values, onFinishFormActions, configurationId);
      }}
      onFinishFailed={validationError =>
        onFormFinishFailedValidation(validationError, dispatch)
      }
    >
      {scope === 'remove' ? (
        <>
          <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
            <FormattedMessage id="ConfigurationRemoveConfirmText" />
          </p>
        </>
      ) : (
        <>
          <ComponentWithStatus
            ignoreAnimation={true}
            status={status}
            Placeholder={<ConfigurationFormPlaceholder />}
          >
            <div
              className="member-select"
              style={{
                ...{
                  minHeight: isDropdownOpened
                    ? `${listHeight + ITEM_HEIGHT}px`
                    : `0px`
                }
              }}
            >
              <ColFormItemComponent
                span={24}
                name="configuration"
                className={`col-form-item-component setting_configuration ${theme}`}
              >
                <SelectDropdown
                  showSearch={true}
                  allowClear={true}
                  placement_bottom={true}
                  onDropdownVisibleChange={() =>
                    setTimeout(
                      () => setIsDropdownOpened(!isDropdownOpened),
                      300
                    )
                  }
                  open={isDropdownOpened}
                  filterOption={filterOption}
                >
                  {categories.map((category, index) => {
                    const optionValues = {
                      ticketCategoryId: category?.category?.categoryId,
                      ticketSubCategoryId: category?.subCategory?.subCategoryId,
                      workQueueId: workQueueId
                    };
                    return (
                      <Select.Option
                        key={index}
                        value={JSON.stringify(optionValues)}
                        disabled={category?.workQueue !== null}
                      >
                        <div className="option_container_container">
                          {' '}
                          {intl.formatMessage({id: category?.category?.key, defaultMessage: category?.category?.name})}
                          {' '}
                          {category?.subCategory?.key && ' → '}
                          {category?.subCategory?.key && intl.formatMessage({id: category?.subCategory?.key, defaultMessage: category?.subCategory?.name})}
                          {' '}
                          {category?.workQueue?.name ? (
                            <>
                              {' → Queue: ' }
                              &nbsp;
                              <div className="option_container_body">
                                <SafeFormattedMessage
                                  id={category?.workQueue?.name}
                                />
                              </div>
                            </>
                          ) : <>{' → Queue: -' }</>}
                        </div>
                      </Select.Option>
                    );
                  })}
                </SelectDropdown>
              </ColFormItemComponent>
            </div>
          </ComponentWithStatus>
        </>
      )}
    </Form>
  );
};
