export const ColleaguesSvg = () => {
  return (
    <span
      role="img"
      aria-label="colleagues"
      className="anticon anticon-colleagues"
    >
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6837 12.7138L10.6839 10.5713C10.684 9.3878 9.72465 8.42836 8.5412 8.42836H3.74304C2.55975 8.42836 1.60047 9.38753 1.60034 10.5708L1.6001 12.7138M14.3999 12.7139L14.4001 10.5714C14.4002 9.38791 13.4408 8.42847 12.2574 8.42847M10.271 1.70695C10.7972 2.09737 11.1382 2.72328 11.1382 3.42883C11.1382 4.13438 10.7972 4.76029 10.271 5.15071M8.3293 3.42871C8.3293 4.6121 7.36998 5.57142 6.18659 5.57142C5.00321 5.57142 4.04389 4.6121 4.04389 3.42871C4.04389 2.24533 5.00321 1.28601 6.18659 1.28601C7.36998 1.28601 8.3293 2.24533 8.3293 3.42871Z"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
