export const ImageIconSVG = ({ stroke = 'currentColor' }) => {
  return (
    <span
      role="img"
      aria-label="detailedView"
      className="anticon anticon-detailedView"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
      >
        <path
          d="M1.46967 15.4697C1.17678 15.7626 1.17678 16.2374 1.46967 16.5303C1.76256 16.8232 2.23744 16.8232 2.53033 16.5303L1.46967 15.4697ZM6 12L6.53033 11.4697C6.23744 11.1768 5.76256 11.1768 5.46967 11.4697L6 12ZM8 14L7.46967 14.5303C7.76256 14.8232 8.23744 14.8232 8.53033 14.5303L8 14ZM12.5 9.5L13.0303 8.96967C12.7374 8.67678 12.2626 8.67678 11.9697 8.96967L12.5 9.5ZM15.9697 14.0303C16.2626 14.3232 16.7374 14.3232 17.0303 14.0303C17.3232 13.7374 17.3232 13.2626 17.0303 12.9697L15.9697 14.0303ZM2.53033 16.5303L6.53033 12.5303L5.46967 11.4697L1.46967 15.4697L2.53033 16.5303ZM5.46967 12.5303L7.46967 14.5303L8.53033 13.4697L6.53033 11.4697L5.46967 12.5303ZM8.53033 14.5303L13.0303 10.0303L11.9697 8.96967L7.46967 13.4697L8.53033 14.5303ZM11.9697 10.0303L15.9697 14.0303L17.0303 12.9697L13.0303 8.96967L11.9697 10.0303ZM4 2.25H14V0.75H4V2.25ZM16.25 4.5V14.5H17.75V4.5H16.25ZM14 16.75H4V18.25H14V16.75ZM1.75 14.5V4.5H0.25V14.5H1.75ZM4 16.75C2.75736 16.75 1.75 15.7426 1.75 14.5H0.25C0.25 16.5711 1.92893 18.25 4 18.25V16.75ZM16.25 14.5C16.25 15.7426 15.2426 16.75 14 16.75V18.25C16.0711 18.25 17.75 16.5711 17.75 14.5H16.25ZM14 2.25C15.2426 2.25 16.25 3.25736 16.25 4.5H17.75C17.75 2.42893 16.0711 0.75 14 0.75V2.25ZM4 0.75C1.92893 0.75 0.25 2.42893 0.25 4.5H1.75C1.75 3.25736 2.75736 2.25 4 2.25V0.75ZM6.25 6C6.25 6.41421 5.91421 6.75 5.5 6.75V8.25C6.74264 8.25 7.75 7.24264 7.75 6H6.25ZM5.5 6.75C5.08579 6.75 4.75 6.41421 4.75 6H3.25C3.25 7.24264 4.25736 8.25 5.5 8.25V6.75ZM4.75 6C4.75 5.58579 5.08579 5.25 5.5 5.25V3.75C4.25736 3.75 3.25 4.75736 3.25 6H4.75ZM5.5 5.25C5.91421 5.25 6.25 5.58579 6.25 6H7.75C7.75 4.75736 6.74264 3.75 5.5 3.75V5.25Z"
          fill={stroke}
        />
      </svg>
    </span>
  );
};
