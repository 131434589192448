export const splitCamelCaseWordIntoWords = (word: string) =>
  word && /[A-Z]+.*[A-Z]+/.test(word)
    ? word.match(/[A-Z][a-z]+/g).join(' ')
    : word;

export const generateHash = (stringValue: string) => {
  var hash = 5381, i = stringValue.length;
  while(i)
    hash = (hash * 33) ^ stringValue.charCodeAt(--i)
  return hash >>> 0;
};