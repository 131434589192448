export const UnreadNotificationsSvg = () => {
  return (
    <span
      role='img'
      aria-label='notification'
      className='anticon anticon-notification'
    >
      <svg
        width='22'
        height='30'
        viewBox='0 0 22 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d="M18 7.5C18 9.43 16.43 11 14.5 11C12.57 11 11 9.43 11 7.5C11 5.57 12.57 4.00001 14.5 4.00001C16.43 4.00001 18 5.57 18 7.5ZM16 12.79C15.5 12.92 15 13 14.5 13C13.0421 12.9974 11.6447 12.417 10.6138 11.3862C9.58295 10.3553 9.00264 8.95788 9 7.5C9 6.03 9.58 4.70001 10.5 3.71001C10.3185 3.48755 10.0897 3.30838 9.83021 3.18555C9.57072 3.06272 9.28709 2.99934 9 3.00001C7.9 3.00001 7 3.90001 7 5.00001V5.29C4.03 6.17 2 8.90001 2 12V18L0 20V21H18V20L16 18V12.79ZM9 24C10.11 24 11 23.11 11 22H7C7 22.5304 7.21071 23.0391 7.58579 23.4142C7.96086 23.7893 8.46957 24 9 24Z" fill="currentColor"/>
        <g filter="url(#filter0_d_3515_27442)">
          <circle cx="14.5" cy="7.5" r="3.5" fill="#EB5757"/>
          <circle cx="14.5" cy="7.5" r="3.25" stroke="#402A87" strokeWidth="0.5"/>
        </g>
        <defs>
          <filter id="filter0_d_3515_27442" x="7" y="0" width="15" height="15" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.168627 0 0 0 0 0.529412 0 0 0 0.4 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3515_27442"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3515_27442" result="shape"/>
          </filter>
        </defs>
      </svg>
    </span>
  );
};

export const NotificationsSvg = () => {
  return (
    <span
      role='img'
      aria-label='notification'
      className='anticon anticon-notification'
    >
      <svg
        width="22" height="24" viewBox="0 0 22 24"
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M8.75546 0.0149844C7.76969 0.136535 7 0.982748 7 2V2.29C4.03 3.17 2 5.9 2 9V9.79V15L0 17V18H18V17L16 15V9.79V9C16 5.9 13.97 3.17 11 2.29V2C11 0.982746 10.2303 0.136531 9.24453 0.0149837C9.16362 0.0048427 9.08195 -0.000185935 9 5.25268e-06C8.91805 -0.00018594 8.83637 0.00484293 8.75546 0.0149844ZM11 19C11 20.11 10.11 21 9 21C7.89 21 7 20.11 7 19H11Z" fill="currentColor"/>
      </svg>
    </span>
  );
};
