import {
  EventDto,
  EventUpdateDto,
  EventResponseDto,
  ICalendarEventsPerDayResponse,
  IEventsFilter,
} from 'pages/Calendar/types';
import { commonRequest } from 'services/generic';
import dayjs from 'dayjs';
export default class EventProvider {
  async getAllEvents() {
    return await commonRequest('GET', {}, 'Event/getEvents');
  }
  async getCalendarDays(payload: IEventsFilter) {
    const payloadData = { payload };

    return await commonRequest<ICalendarEventsPerDayResponse>(
      'POST',
      payloadData,
      'Event/getCalendarDays'
    );
  }

  async getCalendarEvents(payload: IEventsFilter) {
    const payloadData = { payload };

    return await commonRequest<EventResponseDto[]>(
      'POST',
      payloadData,
      'Event/getCalendarEvents'
    );
  }

  async addEvent(payload: EventUpdateDto) {
    const payloadData = { payload };

    return await commonRequest<EventUpdateDto>(
      'POST',
      payloadData,
      'Event/addEvent'
    );
  }

  async getEvent(payload: any) {
    const endpointRecurringEvent = `/Event/getEvent?eventId=${
      payload.eventId
    }&recurrentEventStartDate=${dayjs(payload?.recurrentEventStartDate).format(
      'YYYY-MM-DD'
    )}`;

    const endpointRegularEvent = `/Event/getEvent?eventId=${payload.eventId}`;

    return await commonRequest<EventResponseDto>(
      'POST',
      null,
      payload?.recurrentEventStartDate
        ? endpointRecurringEvent
        : endpointRegularEvent
    );
  }
  async deleteEvent(payload: {
    eventId: string | number;
    deleteRecurringSeries: boolean;
    recurrentEventStartDate?: dayjs.Dayjs;
  }) {
    return await commonRequest<any>('POST', { payload }, `/Event/deleteEvent`);
  }

  async updateEvent(payload: EventUpdateDto) {
    const payloadData = { payload };

    return await commonRequest<EventUpdateDto>(
      'POST',
      payloadData,
      'Event/updateEvent'
    );
  }
}
