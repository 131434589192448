import { motion } from 'framer-motion';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useThemeContext } from '../../AppProvider/ConfigProviderSettings';
import { useEffectWithSkipFirstMount } from '../../hooks/useEffectWithSkipFirstMount';

import { DarkThemeComponent } from '../../Icons/Theme/darkThemeComponent';
import { LightThemeComponent } from '../../Icons/Theme/lightThemeComponent';

import './index.less';
import { useRef, useState } from 'react';

const ThemeSwitcher: React.FC = () => {
  const { theme, toggleTheme } = useThemeContext();

  const [isOn, setIsOn] = React.useState<boolean>(() => window.localStorage?.getItem('enableDarkTheme') === 'Dark');
  const toggleSwitch = () => setIsOn(!isOn);

  useEffectWithSkipFirstMount(() => {
    window.localStorage?.setItem('enableDarkTheme', isOn ? 'Dark' : 'Light');
    // Why setTimeout? Well, the toggle of theme is a heavy change which block the page for a few ms, therefore some animations do not happen
    // the theme switcher for example.
    setTimeout(() => toggleTheme(), 300);
  }, [isOn]);

  const [isHovered, setIsHovered] = useState(false);

  const variants = {
    hover: {
      opacity: 1,
      transition: {duration: 1}
    },
    initial :{
      opacity: 0.3
    }
  }

  return (
    <React.Fragment>
      <div onMouseOver={() => setIsHovered(true)}
           onMouseOut={() => setIsHovered(false)}
           className={`switch theme-switch ${theme} unselectable`}
           data-ison={isOn}
           onClick={toggleSwitch}
      >
        {isOn && <motion.div
          className='second'
          variants={variants}
          animate={isHovered ? 'hover' : 'initial'}
          whileTap={{ scale: 0.9 }}
        >
          <LightThemeComponent />
        </motion.div>}

        {!isOn ?
          // Light Theme
          <motion.div className={`handle ${theme}`} layout transition={spring}>
            <LightThemeComponent />
            <FormattedMessage id='lightTheme' />
          </motion.div>
          :
          // Dark Theme
          <motion.div className={`handle ${theme}`} layout transition={spring}>
            <FormattedMessage id='darkTheme' />
            <DarkThemeComponent />
          </motion.div>
        }

        {!isOn && <motion.div
          className='second'
          variants={variants}
          animate={isHovered ? 'hover' : 'initial'}
          whileTap={{ scale: 0.9 }}
        >
          <DarkThemeComponent />
        </motion.div>}
      </div>
    </React.Fragment>
  );
};

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30
};

export default ThemeSwitcher;
