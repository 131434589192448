import axios from 'services/library/axios';
import { EventUpdateDto } from 'pages/Calendar/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { checkAuthTokenSilently } from 'services/providers/AuthProvider';
dayjs.extend(utc);

// the issue is that when datestring is coming from SS, even thou it's assumed to be local, and it gets wrongly converted to UTC, so we need to double convert it
const parsedEventDatesToLocal = (event) => ({
  startDate: dayjs(event?.startDate).isValid()
    ? dayjs(event?.startDate + 'Z').toISOString()
    : null,

  endDate: dayjs(event?.endDate).isValid()
    ? dayjs(event?.endDate + 'Z').toISOString()
    : null,
});


axios.interceptors.request.use(async (req) => {
  // Aim of the logic bellow is to check on initiating requests to the platform
  // if authentication tokens are not expired, in case of expire they are renewed silently
  switch (req.url) {
    case 'Users/authenticate':
    case 'Users/refreshToken':
      break;
    default:
      await checkAuthTokenSilently();
      break;
  }
  
  return req;
})

axios.interceptors.response.use(
  (res) => {
    switch (res.config.url) {
      case 'Event/getCalendarEvents': {
        const data = res.data as EventUpdateDto[];

        return {
          ...res,
          data: [
            ...data?.map((event) => {
              return {
                ...event,
                ...parsedEventDatesToLocal(event),
              };
            }),
          ],
        };
      }
      default: {
        return res;
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
