import { Button, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import _sort from 'utils/sorter';
import { getColumnSearchProps } from 'components/SearchTable';
import { getTitleColumns } from 'components/TitlesColumns/utils';
import { titlesKeysofColumnsHardware } from './utils';
import { FormattedMessage } from 'react-intl';
import { allowPermision, userRoles } from '../../../utils/userManagement';

const { Text } = Typography;

const getAssigneePerson = data => {
  const assignee =
    data && data.length
      ? data.reduce((initialValue, currentValue) => {
          if (
            currentValue.activeAssignment === true &&
            currentValue.assignType !== 'None'
          ) {
            return [currentValue];
          }
          return initialValue;
        }, {})
      : '';

  return assignee;
};

export const columnsMobileHardware = (
  data,
  role = null,
  params,
  isOpen,
  setIsOpen,
  stateActions,
  dataSource
): ColumnsType<any> => {
  const tableColumnsMobileHardware = [
    {
      title: getTitleColumns('assetName', 'hardware'),
      dataIndex: 'assetName',
      sorter: (a, b) => _sort(a.assetName, b.assetName, 'string'),
      ...getColumnSearchProps('assetName', params),
      render: (_, record, index) => {
        return (
          record.assetId && (
            <Button
              key={`view-hardware-btn-${index}`}
              className="full-content-width-button"
              type="link"
              onClick={() => {
                stateActions?.setModalProps(state => ({
                  ...state,
                  scope: 'view',
                  hardwareAssetId: parseInt(record?.assetId ?? index + 1),
                  modalTitle: <FormattedMessage id="ViewAsset" />,
                  cancelButtonText: <FormattedMessage id="cancel" />,
                  formKeyId: 'hardwareForm'
                }));
                setIsOpen();
              }}
            >
              <Tooltip placement="top" title={record.assetName}>
                <Text underline className="text-medium-big ellipsis-cell-text">
                  {record.assetName}
                </Text>
              </Tooltip>
            </Button>
          )
        );
      }
    },
    {
      title: getTitleColumns('inventoryNumber', 'hardware'),
      dataIndex: 'inventoryNumber',
      ...getColumnSearchProps('inventoryNumber', params),
      width: '40%',
      render: (_, record) => {
        return <p className="line-clamp-1">{record.inventoryNumber}</p>;
      }
    }
  ];
  const dataSourceKeys = dataSource.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
    titlesKeysofColumnsHardware.some(item => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  return tableColumnsMobileHardware.filter(checkForDataDisplay);
};
