import { UploadOutlined } from '@ant-design/icons';
import {
  useIntlContext,
  useThemeContext,
} from 'AppProvider/ConfigProviderSettings';
import { ExportSvg } from 'Icons/ExportComponent';
import { Form } from 'antd';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ModalComponent from 'components/Modal';
import { useModalContext } from 'components/Modal/ModalProvider';
import LockForm from 'components/TimeManagement/LockForm';
import { MultipleDropDownFilter } from 'components/UIComponents/MultipleDropDownFilter';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import dayjs from 'dayjs';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  setFormValidation,
  setMethodUpdate,
} from 'redux/slices/methodUpdateSlice';
import { TimeSheetService } from 'services';
import {
  IModelTimesheet,
  ITimeSheet,
} from 'services/providers/TimeSheetProvider/types';
import { useCompanySettings } from 'settings';
import { firstCharToUpperCase } from 'utils/lang/utils';
import { notifyWithErrorOnMaxSizeUploadFile } from 'utils/notificationsUtils';
import DayEditForm from './TimeSheetEdit';
import { onSubmit } from './TimeSheetEdit/utils';
import { TimeSheetTable } from './TimeSheetTable/timeSheetTable';
import './index.less';
import {
  downloadTimeSheetFile,
  filterDataForNextConflict,
  getDataFromDateWithConflict,
  getfilterDataConficts,
  headerTranslations,
  headerTranslationsInitialColumns,
  removeDataResolved,
} from './utils';

export const hoverColumnDate = (params) => ({
  onMouseEnter: (event) => {
    const hoverDates = event.target?.dataset?.col;
    const indexColumnOfDate = hoverDates
      ? hoverDates
      : event.target?.children[0]?.dataset.col ?? 1;
    const indexColumn = +indexColumnOfDate;
    params.setIndexColumnHovered(indexColumn);
  },
});

export const ViewTimeSheet = () => {
  const { countryCode } = useCompanySettings();
  const isRO = countryCode === 'RO';
  const { toggleModal, setIsModalOpen } = useModalContext();
  const [modalProps, setModalProps] = useState({
    formKeyId: '',
    submitButtonResolveText: '',
    submitButtonResolveAndNextText: '',
    cancelButtonText: '',
    modalTitle: '',
    employeeId: 0,
    employeeName: '',
    data: {
      employeeId: 0,
      dateOnly: '',
      letter: '',
      conflicts: [],
    },
  });

  const [months, setMonths] = useState(dayjs.months());
  const [page, setPage] = useState(1);
  const [value, setValueColumns] = useState<any[]>([]);
  const intl = useIntl();

  const currentDate = dayjs();

  const currentMonth = currentDate.format('MMMM');
  const currentDateObj = currentDate;
  const currentYear = currentDate.year();

  const [dateOfMonth, setDateOfMonth] = useState({
    month: currentMonth,
    date: currentDateObj,
    year: currentYear,
  });

  const { theme } = useThemeContext();
  const [indexColumnHovered, setIndexColumnHovered] = useState<number>(-1);

  const [loadTableWithData, setLoadTableWithData] = useState<boolean>(false);
  const [isDisableButtonResolve, setDisableButtonResolve] =
    useState<boolean>(true);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [
    filterDataByEmployeeDatesWithConflicts,
    setFilterDataByEmployeeDatesWithConflicts,
  ] = useState<any>({});

  const { data, status } = useApiRequestHook<IModelTimesheet>(
    TimeSheetService.getTimeSheet,
    { dateOnly: dayjs(dateOfMonth.date).format('YYYY-MM-DD') },
    [],
    {}
  );

  const dispatch = useDispatch();

  const triggerMethodUpdate = () => {
    dispatch(setMethodUpdate(true));
  };

  const { locale } = useIntlContext();

  useEffect(() => {
    dayjs.locale(locale);
    const months = dayjs.months();
    setMonths(months);
  }, [locale]);

  useEffect(() => {
    if (status === 'success') {
      const employees = data.employees;
      const filterDataConficts = getfilterDataConficts(employees);
      setFilterDataByEmployeeDatesWithConflicts(filterDataConficts);
    }
  }, [data, status]);


  const SetOfColsWithAggregatedDataHigherThanZero = useMemo(() => {
    const AggColSet = new Set<string>();
    if (status === 'success') {
      data.employees?.map((item) =>
        Object.entries(item?.aggregatedColumns).map((column) => {
          if ((column[1] as number) > 0 || !isRO) {
            AggColSet.add(column[0]);
          }
        })
      );
    }

    return AggColSet;
  }, [data, status]);

  const params = {
    indexColumnHovered,
    setIndexColumnHovered,
    value,
    page,
    setDisableButtonResolve,
    totalsColumns: data.totalsColumns,
    weekends: data.weekends,
    torColumns: data.torColumns,
    openTORCount: data.openTORCount,
    aggColumnsWithData: SetOfColsWithAggregatedDataHigherThanZero,
    //need one more param current day
    currentDay: dateOfMonth.date,
  };

  const options = {};

  /**For Gambit, initialize filters with all data.torColumns, eventually from the SetOfColsWithAggregatedDataHigherThanZero,
   * check which column from data.totalColumns is missing(meaning it has no values), then add it to the filters
   *
   * For Jivy, initialize filters with data.totalsColumns */
  if (isRO) {
    data?.torColumns?.map((columnName) => {
      options[columnName] = intl.formatMessage({ id: columnName });
    });

    data?.totalsColumns?.map((columnName) => {
      if (!SetOfColsWithAggregatedDataHigherThanZero.has(columnName)) {
        Object.entries(headerTranslations).map(([keyHeader, valueHeader]) => {
          if (keyHeader === columnName) {
            options[keyHeader] = valueHeader;
          }
        });
      }
    });
  } else {
    data?.totalsColumns &&
      Object.entries(data?.totalsColumns).map(
        ([key, value]) =>
          (!Object.keys(headerTranslationsInitialColumns).includes(value) ||
            !SetOfColsWithAggregatedDataHigherThanZero.has(value)) &&
          Object.entries(headerTranslations).map(([keyHeader, valueHeader]) => {
            if (keyHeader === value) {
              options[keyHeader] = valueHeader;
            }
          })
      );
  }

  const hookUseStateActions = { setModalProps, toggleModal };
  const ButtonCustom = BaseButton(ButtonSimple);
  const [form] = Form.useForm();

  const ButtonResolve = ({ isDisableButtonResolve, submitButtonText }) => {
    return (
      <Form.Item shouldUpdate className="align-button-submit">
        {() => (
          <ButtonCustom
            className={`text-bold-normal btn-primary-custom resolve_btns ${theme}`}
            type="primary"
            form="dayEditForm"
            key="submitModalButton"
            htmlType="submit"
            disabled={isDisableButtonResolve}
          >
            {submitButtonText}
          </ButtonCustom>
        )}
      </Form.Item>
    );
  };

  const ButtonResolveAndNext = ({
    isDisableButtonResolve,
    submitButtonResolveAndNextText,
    employeeId,
  }) => {
    return (
      <Form.Item shouldUpdate className="align-button-submit-next">
        {() => (
          <ButtonCustom
            className={`text-bold-normal btn-primary-custom resolve_btns ${theme}`}
            type="primary"
            key="submitModalButton"
            onClick={() => {
              if (
                !form.getFieldsError().some((item) => item.errors.length > 0)
              ) {
                setLoadTableWithData(true);
                const results = form.getFieldsValue();
                onSubmit({ results, employeeId });
                setIsModalOpen(true);
                params.setDisableButtonResolve(true);
                const datesByCurrentEmployee =
                  filterDataByEmployeeDatesWithConflicts[employeeId];

                const { dateNext, currentEmployeeId } =
                  filterDataForNextConflict(
                    modalProps.employeeId,
                    modalProps.data.dateOnly,
                    datesByCurrentEmployee
                  );

                const {
                  dayWithConflictNext,
                  employeeNameNext,
                  employeeNextId,
                } = getDataFromDateWithConflict(
                  dateNext,
                  currentEmployeeId,
                  data?.employees
                );

                const refUpdatedData = removeDataResolved(
                  modalProps.employeeId,
                  modalProps.data.dateOnly,
                  filterDataByEmployeeDatesWithConflicts
                );

                setFilterDataByEmployeeDatesWithConflicts(refUpdatedData);

                setModalProps((state) => ({
                  ...state,
                  data: dayWithConflictNext[0],
                  employeeName: employeeNameNext,
                  employeeId: employeeNextId,
                  formKeyId: 'dayEditForm',
                  modalTitle: viewConflictText,
                  submitButtonResolveText: resolveText,
                  submitButtonResolveAndNextText: resolveAndNextText,
                  cancelButtonText: cancelText,
                }));
              }
            }}
            disabled={isDisableButtonResolve}
          >
            {submitButtonResolveAndNextText}
          </ButtonCustom>
        )}
      </Form.Item>
    );
  };

  const lockTimeSheetText = intl.formatMessage({
    id: 'lockTimesheet',
  });
  const viewConflictText = intl.formatMessage({
    id: 'ViewConflict',
  });
  const resolveText = intl.formatMessage({
    id: 'resolve',
  });
  const resolveAndNextText = intl.formatMessage({
    id: 'resolveAndNext',
  });
  const cancelText = intl.formatMessage({
    id: 'cancel',
  });

  const lockTimesheetModalTitle = firstCharToUpperCase(lockTimeSheetText);

  return (
    <>
      <ModalComponent
        formKeyId={modalProps.formKeyId}
        title={modalProps.modalTitle}
        displayFooterSubmitButton={false}
        isMaskClosable={modalProps.formKeyId === 'lockForm' ? true : false}
        footerButtons={
          modalProps.formKeyId === 'dayEditForm' ? (
            <div className="btns_row">
              <div className="btn_col_default">
                <ButtonCustom
                  className={`text-bold-normal btn-default-custom ${theme}`}
                  style={{ width: '100%', maxWidth: '100%' }}
                  key="closeModalButton"
                  onClick={() => {
                    dispatch(setMethodUpdate(false));
                    dispatch(setFormValidation(false));
                    toggleModal();
                    if (loadTableWithData) {
                      setLoadTableWithData(false);
                      triggerMethodUpdate();
                    }
                  }}
                >
                  {modalProps.cancelButtonText ?? 'close'}
                </ButtonCustom>
                <ButtonResolve
                  submitButtonText={modalProps.submitButtonResolveText}
                  isDisableButtonResolve={isDisableButtonResolve}
                />
              </div>
              <div className="btn_col_additional">
                {((filterDataByEmployeeDatesWithConflicts[
                  modalProps.employeeId
                ] &&
                  filterDataByEmployeeDatesWithConflicts[modalProps.employeeId]
                    .length > 1) ||
                  (filterDataByEmployeeDatesWithConflicts[
                    modalProps.employeeId
                  ] &&
                    filterDataByEmployeeDatesWithConflicts[
                      modalProps.employeeId
                    ].length === 1 &&
                    filterDataByEmployeeDatesWithConflicts[
                      modalProps.employeeId
                    ][0] !== modalProps.data.dateOnly)) && (
                  <ButtonResolveAndNext
                    submitButtonResolveAndNextText={
                      modalProps.submitButtonResolveAndNextText
                    }
                    isDisableButtonResolve={isDisableButtonResolve}
                    employeeId={modalProps.employeeId}
                  />
                )}
              </div>
            </div>
          ) : null
        }
        displayFooterCloseButton={false}
        children={
          modalProps.formKeyId === 'lockForm' ? (
            <LockForm />
          ) : (
            <DayEditForm
              form={form}
              key="timesheet"
              setDisableButtonResolve={setDisableButtonResolve}
              data={modalProps.data}
              employeeName={modalProps.employeeName}
              employeeId={modalProps.employeeId}
            />
          )
        }
        submitBtnText={modalProps.submitButtonResolveText}
        cancelBtnText={modalProps.cancelButtonText}
      />

      <div className="timeSheetLayout">
        <SearchToolbarComponent
          key="TimeSheet"
          displaySearchIcon={false}
          displaySearchInput={false}
          children={
            <div className="search-toolbar_filters_container">
              <MultipleDropDownFilter
                placeholder={intl.formatMessage({ id: 'AddColumns' })}
                dataForOptions={options}
                value={value}
                dropdownAlign={{ offset: [53, -14] }}
                onChangeFunction={(newValue: string[]) => {
                  setValueColumns(newValue);
                }}
                instanceId="AddColumns"
              />
            </div>
          }
          buttons={[
            // !NOTE: at a later stage it would be managed from Settings
            !isRO && {
              icon: <UploadOutlined />,
              text: (
                <span>
                  <FormattedMessage id="Upload" />
                </span>
              ),
              isUploadButton: true,
              isMultipleUpload: false,
              uploadAccept:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
              uploadMaxCount: 1,
              uploadInProgress: uploadInProgress,
              uploadShowList: !uploadInProgress,
              beforeUploadAction: async (uploadedFile) => {
                // check for file size of the uploaded file to be less than 5mb
                if (uploadedFile.size && uploadedFile.size >= 5 * 1000000) {
                  notifyWithErrorOnMaxSizeUploadFile(5);
                  return false;
                }
                const formData = new FormData();
                formData.append('file', uploadedFile);
                setUploadInProgress(true);
                try {
                  await TimeSheetService.uploadTimeAttendance(formData);
                } finally {
                  setUploadInProgress(false);
                }

                triggerMethodUpdate();
                return false;
              },
              show: true,
              isDisableButton: isRO,
            },
            //* This button is disable for a moment
            // {
            //   icon: (
            //     <img
            //       className="icon-lock"
            //       src={lockTimesheetSvg}
            //       alt="lock timesheet"
            //     />
            //   ),
            //   text: <FormattedMessage id="lockTimesheet" />,
            //   action: () => {
            //     setModalProps((state) => ({
            //       ...state,
            //       formKeyId: 'lockForm',
            //       modalTitle: lockTimesheetModalTitle,
            //       submitButtonText: 'lock',
            //       cancelButtonText: cancelText,
            //     }));

            //     toggleModal();
            //   },
            //   show: true,
            // },
            {
              icon: <ExportSvg />,
              text: (
                <span>
                  <FormattedMessage id="export" />
                </span>
              ),
              action: () =>
                downloadTimeSheetFile(
                  TimeSheetService.getTimeSheetFile({
                    dateOnly: dayjs(dateOfMonth.date).format('YYYY-MM-DD'),
                  })
                ),
              show: true,
            },
          ]}
        />
        <br />
        {/* {status === 'success' && ( // to be remove and find another method */}
        <TimeSheetTable
          setDateOfMonth={setDateOfMonth}
          dateOfMonth={dateOfMonth}
          setIndexColumnHovered={setIndexColumnHovered}
          data={data.employees as ITimeSheet[]}
          params={params}
          setPage={setPage}
          page={page}
          hookUseStateActions={hookUseStateActions}
          months={months}
          status={status}
        />
       {/* )}  */}
      </div>
    </>
  );
};
