import React from 'react';

const manageCategoryIcon = ({ stroke }) => {
  return (
    <span role="img" aria-label="export" className="anticon anticon-export">
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.196 4L5.99986 1L0.803711 4V10L5.99986 13L11.196 10V4ZM5.99986 9C7.10443 9 7.99986 8.10457 7.99986 7C7.99986 5.89543 7.10443 5 5.99986 5C4.89529 5 3.99986 5.89543 3.99986 7C3.99986 8.10457 4.89529 9 5.99986 9Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default manageCategoryIcon;
