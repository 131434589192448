import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { IAppProvider } from 'AppProvider/types';
import TimilyLogoLoading from 'Icons/Logos/LogoTimily/TimilyLogoLoading';
import { Layout } from 'antd';
import { HeaderMenuContext } from 'components/Layout/Header/HeaderMenuContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLoggedUser } from 'redux/slices/userSlice';
import { EmployeeServices } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { useCompanySettings } from 'settings';
import ContentPart from './Content';
import { HeaderPart } from './Header';
import MenuPart from './Menu';
import './index.less';

const LayoutPage = (children: IAppProvider) => {
  const { isLoading, getAccessTokenSilently, logout, setUser } = useAuth();
  const logoutAndRedirectToAuthLoginPage = () => {
    logout({
      returnTo: `${process.env.REACT_APP_DOMAIN}/login`
    });
  };

  const { isMobile, deviceWidth, deviceHeight } = useMobileContext();

  const dispatch = useDispatch();
  const { countryCode } = useCompanySettings();
  const location = useLocation();
  const { theme } = useThemeContext();
  const [isTokenAvailable, setIsTokenAvailable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(isOpen => !isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location, deviceWidth, deviceHeight]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const handleTokenAuthentication = async () => {
      const accessToken = getAccessTokenSilently();
      if (!accessToken) {
        logoutAndRedirectToAuthLoginPage();
        return;
      }

      const getEmployeeProfile = async () => {
        try {
          const employeeProfile =
            await EmployeeServices.getEmployeeProfile('false');
          employeeProfile && dispatch(setLoggedUser(employeeProfile.data));
          setUser(employeeProfile.data);
        } catch (e) {
          logoutAndRedirectToAuthLoginPage();
        }
      };

      await getEmployeeProfile();
      setIsTokenAvailable(true);
    };

    handleTokenAuthentication();
  }, [isLoading]);

  return (
    <>
      <HeaderMenuContext.Provider value={{ isOpen, handleIsOpen }}>
        {isTokenAvailable ? (
          <Layout
            className={`layoutContent ${theme}`}
            style={isMobile ? { maxHeight: deviceHeight } : {}}
          >
            <HeaderPart />
            <Layout
              style={isMobile ? { maxHeight: deviceHeight - 70 } : {}}
              className="layoutStyle"
            >
              <div className={`bg-circle circle1 ${theme}`}></div>
              <div className={`bg-circle circle2 ${theme}`}></div>
              <MenuPart />
              <ContentPart {...children} />
            </Layout>
            {/* <FooterPart /> */}
          </Layout>
        ) : (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ repeat: Infinity, repeatDelay: 5 }}
              style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <TimilyLogoLoading />
            </motion.div>
          </AnimatePresence>
        )}
      </HeaderMenuContext.Provider>
    </>
  );
};

export default LayoutPage;
