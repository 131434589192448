import { commonRequest } from 'services/generic';
import { ITimeSheet, ITimeSheetNew, IVacation } from './types';
import dayjs from 'dayjs';

export default class TimeSheetProvider {
  async getTimeSheet(payload: string) {
    const payloadData = { payload };
    return await commonRequest<ITimeSheet[]>(
      'POST',
      payloadData,
      'TimeOff/getTimeSheetOld'
    );
  }

  async getTimeSheetNew(payload: string) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      'TimeOff/getTimeSheet'
    );
  }

  async getColleaguesMonthEvents(payload: string) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      'TimeOff/getColleaguesMonthEvents'
    );
  }

  async getTimeSheetFile(payload: object) {
    const payloadData = { payload: payload, customResponseType: 'arraybuffer' };
    return await commonRequest(
      'POST',
      payloadData,
      'TimeOff/downloadTimeSheet'
    );
  }

  async uploadTimeAttendance(payload: FormData) {
    const customHeaders = {
      'X-TimeZone-Id': dayjs.tz.guess()
    };
    const payloadData = { payload: payload, headers: customHeaders };
    return await commonRequest(
      'POST',
      payloadData,
      'TimeOff/UploadTimeAttendance'
    );
  }

  async solveConflict(payload: object) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'TimeOff/solveConflict');
  }
}
