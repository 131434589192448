import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const generalSlice = createSlice({
  name: 'general',
  initialState: {},
  reducers: {
    setDefaultEnumValues(state, action: PayloadAction<any>) {
      return { ...state, enums: action.payload };
    },
    setRequestStatus(state, action: PayloadAction<any>) {
      return { ...state, lastRequestStatus: action.payload };
    },
    setEvents(state, action: PayloadAction<any>) {
      return { ...state, events: action.payload };
    },
  },
});

export const { setDefaultEnumValues, setRequestStatus, setEvents } = generalSlice.actions;
export default generalSlice.reducer;
