import React from 'react';

export const DownCircleOutlined = (props) => {
  return (
    <span
      role="img"
      aria-label="import"
      className="anticon anticon-import"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7388 14.1967C12.3308 14.6011 11.6692 14.6011 11.2612 14.1967L8.30602 11.2678C7.898 10.8634 7.89798 10.2077 8.30602 9.8033C8.71406 9.39889 9.37559 9.39891 9.78361 9.8033L12 12L14.2164 9.8033C14.6244 9.39889 15.286 9.39891 15.694 9.8033C16.102 10.2077 16.102 10.8634 15.694 11.2678L12.7388 14.1967Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};
