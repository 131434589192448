import { ColumnsType } from 'antd/es/table';
import {
  getKeyOrTitleSelects,
  getTitleColumns
} from 'components/TitlesColumns/utils';
import _sort from 'utils/sorter';
import { getColumnSearchProps } from 'components/SearchTable';
import { Button, Tooltip, Typography } from 'antd';
import { getFilterCustomForStatus } from '../utils';
import { titlesKeysofColumnsSoftware } from './utils';
import { FormattedMessage } from 'react-intl';
import { allowPermision, userRoles } from '../../../utils/userManagement';

const { Text } = Typography;

export const columnsMobileSoftware = (
  data,
  role = null,
  params,
  isOpen,
  setIsOpen,
  stateActions,
  dataSource
): ColumnsType<any> => {
  const tableColumnsMobileSoftware = [
    {
      title: getTitleColumns('model', 'software'),
      dataIndex: 'model',
      key: 'model',
      sorter: (a, b) => _sort(a.model, b.model, 'string'),
      filterSearch: true,
      ...getColumnSearchProps('model', params),
      render: (_, record, index) =>
        record.assetId ? (
          <Button
            type="link"
            className="full-content-width-button"
            key={`view-hardware-btn-${index}`}
            onClick={() => {
              stateActions?.setModalProps(state => ({
                ...state,
                scope: 'view',
                assetId: parseInt(record?.assetId ?? index + 1),
                modalTitle: <FormattedMessage id="ViewAsset" />,
                cancelButtonText: <FormattedMessage id="cancel" />,
                formKeyId: 'AssetSoftwareForm'
              }));
              setIsOpen();
            }}
          >
            <Tooltip placement="bottomLeft" title={record.model}>
              <Text underline className="text-medium-big ellipsis-cell-text">
                {record.model}
              </Text>
            </Tooltip>
          </Button>
        ) : (
          <Button
            type="link"
            className="full-content-width-button cursor-text-button"
          >
            <Tooltip placement="top" title={record.model}>
              <Text className="text-medium-big ellipsis-cell-text">
                {record.model}
              </Text>
            </Tooltip>
          </Button>
        )
    },
    {
      title: getTitleColumns('frequency', 'software'),
      dataIndex: 'frequency',
      key: 'frequency',
      filters: getFilterCustomForStatus(data, 'frequency', 'frequencySoft'),
      onFilter: (value: string, record) =>
        record.frequency.indexOf(value) === 0,
      sorter: (a, b) => _sort(a.frequency, b.frequency, 'string'),
      render: (_, record) => {
        return (
          <p className="line-clamp-1">
            {getKeyOrTitleSelects(record.frequency, 'frequencySoft')}
          </p>
        );
      },
      width: '40%'
    }
  ];
  const dataSourceKeys = dataSource.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
    titlesKeysofColumnsSoftware.some(item => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  return tableColumnsMobileSoftware.filter(checkForDataDisplay);
};

function etFiltersWithSearch(data: any) {
  throw new Error('Function not implemented.');
}
