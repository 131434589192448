export const Portrait = () => {
  return (
    <div className="container-orientation">
      <div
        role="img"
        aria-label="anticonLandscape"
        className="anticon orientation-portrait"
      >
        <svg
          viewBox="0 0 257 280"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="transform-rotation"
        >
          <path
            d="M200.03 117.099L198.207 114.191C199.435 113.417 199.537 110.845 199.537 108.049V77.1283C199.537 73.5828 199.303 71.5405 198.07 70.9063L199.634 67.8502C202.964 69.563 202.964 73.9349 202.964 77.1283V108.049C202.964 111.259 202.964 115.254 200.03 117.102V117.099Z"
            fill="white"
          />
          <path
            d="M56.4362 90.3687C53.0693 88.249 53.0693 83.55 53.0693 79.7724V69.4381C53.0693 65.4782 53.0693 60.5471 56.887 58.5846L58.4509 61.6407C56.8073 62.4846 56.496 65.0313 56.496 69.4356V79.7699C56.496 83.3677 56.628 86.4288 58.2592 87.455L56.4362 90.3637V90.3687Z"
            fill="white"
          />
          <path
            d="M56.4362 126.33C53.0693 124.21 53.0693 119.511 53.0693 115.734V105.399C53.0693 101.44 53.0693 96.5084 56.887 94.5459L58.4509 97.602C56.8073 98.4459 56.496 100.993 56.496 105.397V115.731C56.496 119.329 56.628 122.39 58.2592 123.416L56.4362 126.325V126.33Z"
            fill="white"
          />
          <path
            d="M177.547 280H78.9714C66.2806 280 55.9556 269.648 55.9556 256.925L55.9556 23.0753C55.9556 10.3517 66.2806 0 78.9714 0H177.547C190.238 0 200.563 10.3517 200.563 23.0753L200.563 256.925C200.563 269.648 190.238 280 177.547 280ZM78.9714 3.43307C68.1683 3.43307 59.3823 12.2442 59.3823 23.0728L59.3823 256.925C59.3823 267.756 68.1708 276.564 78.9714 276.564H177.547C188.35 276.564 197.136 267.753 197.136 256.925L197.136 23.0753C197.136 12.2442 188.348 3.43555 177.547 3.43555H78.9714V3.43307Z"
            fill="white"
          />
          <path
            d="M177.505 273.131H79.0138C69.9115 273.131 62.5077 265.708 62.5077 256.583L62.5077 23.4124C62.5077 14.2866 69.9115 6.86369 79.0138 6.86369H177.505C186.604 6.86369 194.011 14.2866 194.011 23.4124L194.011 256.583C194.011 265.706 186.607 273.131 177.505 273.131ZM79.0138 8.58394C70.8579 8.58394 64.2211 15.2379 64.2211 23.4149L64.2211 256.583C64.2211 264.76 70.8579 271.414 79.0138 271.414H177.505C185.66 271.414 192.297 264.76 192.297 256.583L192.297 23.4124C192.297 15.2354 185.66 8.5815 177.505 8.5815H79.0138V8.58394Z"
            fill="white"
          />
          <path
            d="M220.573 228.596C220.152 228.596 219.734 228.441 219.402 228.132C218.713 227.483 218.675 226.396 219.323 225.705C241.101 202.395 253.094 171.956 253.094 140C253.094 108.044 241.101 77.6053 219.325 54.2952C218.678 53.6036 218.713 52.515 219.405 51.8683C220.095 51.2192 221.181 51.2541 221.826 51.9482C244.199 75.8974 256.521 107.17 256.521 140.003C256.521 172.835 244.199 204.105 221.826 228.057C221.489 228.419 221.031 228.601 220.575 228.601L220.573 228.596Z"
            fill="white"
          />
          <path
            d="M233.079 228.596H220.573C219.626 228.596 218.859 227.827 218.859 226.878V214.34C218.859 213.391 219.626 212.622 220.573 212.622C221.519 212.622 222.286 213.391 222.286 214.34V225.161H233.079C234.026 225.161 234.793 225.93 234.793 226.878C234.793 227.827 234.026 228.596 233.079 228.596Z"
            fill="white"
          />
          <path
            d="M35.9457 228.596C35.4875 228.596 35.0317 228.414 34.6955 228.052C12.3222 204.103 0 172.83 0 140C0 107.17 12.3222 75.8973 34.6955 51.9456C35.343 51.254 36.4263 51.2166 37.1162 51.8657C37.806 52.5149 37.8433 53.601 37.1959 54.2926C15.4202 77.6052 3.42672 108.044 3.42672 140C3.42672 171.956 15.4202 202.392 37.1983 225.705C37.8458 226.396 37.8085 227.485 37.1187 228.132C36.7874 228.441 36.3666 228.596 35.9482 228.596H35.9457Z"
            fill="white"
          />
          <path
            d="M35.9457 67.3784C34.9994 67.3784 34.2323 66.6094 34.2323 65.6606V54.8395H23.4392C22.4928 54.8395 21.7258 54.0705 21.7258 53.1217C21.7258 52.1729 22.4928 51.4039 23.4392 51.4039H35.9457C36.892 51.4039 37.6591 52.1729 37.6591 53.1217V65.6606C37.6591 66.6094 36.892 67.3784 35.9457 67.3784Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
