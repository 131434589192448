import { FC, useState } from 'react';
import { Avatar, Dropdown, DropdownProps, MenuProps } from 'antd';
import {
  useIntlContext,
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import ConfirmModalComponent from 'components/Modal/ConfirmModal';
import { useAuth } from 'services/providers/AuthProvider';
import { useConfirmationModalContext } from 'components/Modal/ConfirmModal/ConfirmationModalProvider';
import 'components/UIComponents/SwitchComponent/SwitchComponent.less';
import './ProfileDropdown.less';
import { LogOutComponent } from 'Icons/LogOut/logOutComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import LanguageSelect from 'components/LanguageSelect';
import IssueCollector from 'components/IssueCollector';
import IssueCollectorForm from '../../../IssueCollector/IssueCollectorForm';
import ModalComponent from '../../../Modal';
import * as React from 'react';
import ThemeSwitcher from '../../../ThemeSwitcher';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';

interface ProfileDropdownProps extends DropdownProps {
  profilePictureSrc: React.ReactNode;
}

const getMenuProps = ({
  theme,
  toggleTheme,
  setOnOkConfirmModalPromise,
  getLocalStorage,
  setLocalStorage,
  toggleModal,
  setShowReportIssue
}: {
  theme: string;
  toggleTheme: () => void;
  setOnOkConfirmModalPromise?: () => void;
  getLocalStorage?: string | null;
  setLocalStorage?: (value: any) => void;
  toggleModal?: () => void;
  setShowReportIssue?: () => void;
}): MenuProps['items'] => [
  {
    key: '1',
    label: <ThemeSwitcher />
  },
  {
    key: '2',
    label: (
      <div className="language-select-area">
        <LanguageSelect />
      </div>
    ),
    children: [
      {
        className: 'language-select-hidden-children',
        key: '',
        label: ''
      }
    ]
  },
  {
    key: '3',
    label: (
      <div>
        <IssueCollector setShowReportIssue={setShowReportIssue} />
      </div>
    )
  },
  {
    label: (
      <>
        <div className={`logInOut ${theme}`}>
          <LogOutComponent />
          <span className="paddingLogOut">
            <FormattedMessage id="logOut" />
          </span>
        </div>
      </>
    ),
    key: '4',
    onClick: () => {
      setOnOkConfirmModalPromise();
      toggleModal();
    }
  }
];

const ProfileDropdown: FC<ProfileDropdownProps> = ({ profilePictureSrc }) => {
  const { theme, toggleTheme } = useThemeContext();
  const { isMobile, isSmallLaptop, isTabletDevice } = useMobileContext();

  const { logout } = useAuth();
  const { setOnOkModalPromise } = useConfirmationModalContext();
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showReportIssue, setShowReportIssue] = useState<boolean>(false);
  // const [showDropdown, setShowDropdown] = useState(null);
  const getLocalStorage = window.localStorage.getItem('enableDarkTheme');
  const setLocalStorage = value =>
    window.localStorage.setItem('enableDarkTheme', value);
  const setOnOkConfirmModalPromise = () =>
    setOnOkModalPromise(
      () => () =>
        new Promise(() =>
          logout({ returnTo: `${process.env.REACT_APP_DOMAIN}/login` })
        )
    );
  const { locale } = useIntlContext();

  const intl = useIntl();
  const logoutTitle = intl.formatMessage({
    id: 'logOut'
  });

  return (
    <>
      {showLogoutModal && (
        <ConfirmModalComponent
          className="logout-modal modal-component"
          title={<FormattedMessage id="LogoutModalTitle" />}
          showConfirmModal={showLogoutModal}
          setShowConfirmModal={setShowLogoutModal}
          confirmContent={<FormattedMessage id="logOutConfirm" />}
          width="20em"
          okText={null}
          cancelText={null}
          showclosableBtn={false}
        />
      )}
      {showReportIssue && (
        <ModalComponent
          formKeyId="issueCollectorForm"
          displayFooter={true}
          isOpen={showReportIssue}
          setIsOpen={setShowReportIssue}
          maskClosable={true}
          title={capitalizeFirstLetter(
            intl.formatMessage({ id: 'reportIssue' })
          )}
          submitBtnText={<FormattedMessage id="submit" />}
          width={isMobile ? '100%' : 'auto'}
          alignSelf="middle"
        >
          <IssueCollectorForm hideModal={setShowReportIssue} theme={theme} />
        </ModalComponent>
      )}
      <Dropdown
        className={`profile-dropdown-component ${theme}`}
        overlayClassName="profile-dropdown-container"
        key="profile-dropdown-component"
        menu={{
          className: `profile-menu-items-component ${locale} ${theme}`,
          items: getMenuProps({
            theme,
            toggleTheme,
            setOnOkConfirmModalPromise,
            getLocalStorage,
            setLocalStorage,
            toggleModal: () => setShowLogoutModal(true),
            setShowReportIssue: () => setShowReportIssue(true)
          })
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
      >
        <a onClick={e => e.preventDefault()}>
          <Avatar
            className={`image-on-profile ${theme}`}
            size={41}
            src={profilePictureSrc}
          />
        </a>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
