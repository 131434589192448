import React, { useEffect, useMemo, useState } from 'react';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

import './TableCustom.less';
import { PaginationComponent } from '../Pagination';
import { isMobile } from 'react-device-detect';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

interface TableCustomProps {
  columns: any[];
  data: any[];
  className?: string;
  rowClassName?: string;
  columnRowClassName?: string;
  style?: React.CSSProperties;
  handleMouseEnter?: any;
  handleMouseLeave?: any;
  pagination?: boolean;
}

export const TableCustom: React.FC<TableCustomProps> = ({
  columns,
  data,
  className,
  rowClassName,
  columnRowClassName,
  style,
  handleMouseEnter,
  handleMouseLeave,
  pagination = true,
}) => {
  //filer logic
  const [filteredResults, setFilteredResults] = useState<string[]>([]);
  //sort logic
  const [sortedASCorDESCdata, setSortedASCorDESCdata] = useState([]);

  //get current theme
  const { theme } = useThemeContext();

  //logic for pagination
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(() => {
    // Initialize pageSize from localStorage or use default value (20)
    const storedPageSize = localStorage.getItem(className ?? 'pageSize');
    return storedPageSize ? parseInt(storedPageSize, 10) : 20;
  });
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    // Save pageSize to localStorage whenever it changes
    localStorage.setItem(className ?? 'pageSize', pageSize.toString());
  }, [pageSize]);

  useEffect(() => {
    if (isMobile) {
      setPageSize(20);
    }
  }, []);

  const onChangePage = (page: number, pageSize: number) => {
    setCurrent(page);
    setPageSize(pageSize);
  };

  useMemo(() => {
    //here is the sorting and filtering logic of the table rows
    let result = [];
    let dataForTable = [];

    //assuming we start the sorting and want to sort in filtered data
    if (sortedASCorDESCdata && sortedASCorDESCdata.length > 0) {
      //if we want both sort  and filter
      dataForTable = sortedASCorDESCdata;
      setRowsData(sortedASCorDESCdata);
    } else {
      //if we want only  to filter
      dataForTable = data;
    }

    //assuming we dont start filtered but only want to sort the data
    if (
      filteredResults.length === 0 &&
      sortedASCorDESCdata &&
      sortedASCorDESCdata.length > 0
    ) {
      //if only want to sort
      result = sortedASCorDESCdata;
    } else {
      //if we want to filter
      dataForTable.forEach(item => {
        columns.forEach(column => {
          if (column.onFilter && column.onFilter(filteredResults, item)) {
            result.push(item);
          }
        });
      });
    }
    setRowsData(result);
  }, [columns, data, filteredResults, sortedASCorDESCdata]);

  const dataOfRows = rowsData.length > 0 ? rowsData : data;
  const displayedData = dataOfRows.slice(startIndex, endIndex);

  return (
    <>
      <div className="table-component-container" style={style || {}}>
        <table className={`table-component-custom ${theme} ${className ?? ``}`}>
          <thead className="table-component-thead">
            <TableHeader
              columns={columns}
              setFilteredResults={setFilteredResults}
              columnRowClassName={columnRowClassName}
              dataSorce={data}
              setSorted={setSortedASCorDESCdata}
            />
          </thead>
          <tbody className="table-component-tbody">
            <TableRow
              data={displayedData}
              columns={columns}
              filteredResults={filteredResults}
              rowClassName={rowClassName}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </tbody>
          <colgroup>
            {columns.map(column => (
              // <col key={column?.key} className={`table-component-col ${column?.classNameTagCol?? ''}`} />
              <col key={column?.key} className={`table-component-col `} />
            ))}
          </colgroup>
        </table>
      </div>
      <div
        style={{
          marginTop: '12px'
        }}
      >
        {pagination && (
          <PaginationComponent
            current={current}
            changePagination={onChangePage}
            total={dataOfRows.length}
            pageSize={pageSize}
            showSizeChanger={true}
          />
          
        )}
      </div>
    </>
  );
};
