import { FormattedMessage } from 'react-intl';
import { EventsStatusDefinitions, EventsTypes, multipleEvents } from '../types';
import { getFormatTimeOfHours, getMonthNameByNumber } from '../utils';
import dayjs from 'dayjs';
import { BadgeSvg } from 'Icons/General/badge';
import { legentColors } from 'pages/TimeManagement/Vacation/types';

export const columnsAttendanceReport = (params, hookUseStateActions) => {
  return [
    {
      title: (
        <div className="th_month_column">
          {' '}
          <FormattedMessage id="AttendanceRepportTitle_Month" />
        </div>
      ),
      dataIndex: 'month',
      key: 'month',
      ellipsis: true,
      width: 96,
      render: (_, record, index) => {
        return (
          <div className="month_cell">
            {getMonthNameByNumber(index, params?.locale)}
          </div>
        );
      }
    },
    ...datesColumns(params, hookUseStateActions)
  ];
};

const datesColumns = (params, hookUseStateActions) => {
  return [...Array(31)].map((_, index) => ({
    title: <div className="th_days_column">{index + 1}</div>,
    dataIndex: index.toString(),
    key: index.toString(),
    render: (_, record) => {
      const intl = params?.intl;
      const isRO = params?.countryCode === 'RO';
      const backgroundColor =
        getCellColor(
          record?.attendanceDays[index]?.events,
          record?.attendanceDays[index]?.dateOnly,
          params?.theme
        ) ??
        (params?.theme === 'dark' ? 'rgba(189, 167, 232, 0.1)' : '#402A870D');
      // check if is today  date
      const isToday =
        record?.attendanceDays[index]?.dateOnly &&
        dayjs().isSame(dayjs(record?.attendanceDays[index]?.dateOnly), 'day')
          ? 'current_day_cell'
          : '';
      // check  if cell has conflict
      const cellHasConflict =
        record?.attendanceDays[index]?.conflictType === 'ConflictGeneral' ||
        record?.attendanceDays[index]?.conflictType === 'ConflictHours';

      const value = getFormatTimeOfHours(
        record?.attendanceDays[index]?.timeSheetHours
      );
      return {
        cellRowClassName: `hours_cell ${isToday}`,
        content: (
          <>
            {/* {cellHasConflict && (<div className='badge_cell'> <BadgeSvg /></div>)} */}

            <div
              className="hours_cell_data"
              style={{
                background: backgroundColor,
                color: cellHasConflict ? 'red' : '',
                fontWeight: cellHasConflict ? '700' : ''
              }}
              onClick={e => {
                if (isRO && record?.attendanceDays[index]?.events?.length === 0)
                  return;
                if (record?.attendanceDays[index] === undefined) return;

                hookUseStateActions?.setModalProps(state => ({
                  ...state,
                  data: record?.attendanceDays[index],
                  formKeyId: 'dayEditForm',
                  modalTitle: intl.formatMessage({ id: 'ViewConflict' }),
                  cancelButtonText: intl.formatMessage({ id: 'close' })
                }));

                hookUseStateActions?.toggleModal();
              }}
            >
              <span className="cell_value_string">
                {value === '─' ? (
                  value
                ) : (
                  <>
                    <span>
                      {value.hours}
                      <sup style={{ verticalAlign: 'top', fontSize: '8px' }}>
                        {value.minutes}
                      </sup>
                    </span>
                  </>
                )}
              </span>
            </div>
          </>
        )
      };
    }
  }));
};

const getCellColor = (events, date, theme) => {
  if (!events || events.length === 0) {
    // Handle the case where there are no events
    return theme === 'light'
      ? 'rgba(189, 167, 232, 0.1)'
      : 'rgba(189, 167, 232, 0.1)'; // Default colors based on theme
  }

  const priorityOrder = Object.values(EventsTypes);

  // Sort the events by the priority defined in priorityOrder
  const sortedEvents = [...events].sort((a, b) => {
    return (
      priorityOrder.indexOf(a.eventType) - priorityOrder.indexOf(b.eventType)
    );
  });

  // Get the highest priority event
  const highestPriorityEvent = sortedEvents[0];

  const matchingEvents = sortedEvents.filter(event =>
    multipleEvents.includes(event.eventType)
  );

  if (matchingEvents.length >= 2) {
    return legentColors.find(request => request.request === 'OverlappingEvents')
      ?.color;
  } else {
    return theme === 'light'
      ? (EventsStatusDefinitions[highestPriorityEvent.eventType]?.cellColor ??
          'rgba(189, 167, 232, 0.1)')
      : (EventsStatusDefinitions[highestPriorityEvent.eventType]
          ?.darkcellColor ?? 'rgba(189, 167, 232, 0.1)');
  }
};
