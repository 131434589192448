import { commonRequest } from 'services/generic';

export default class CompanySettingsProvider {
  async getCompanySettings() {
    return await commonRequest('GET', {}, 'CompanySettings/getCompanySettings');
  }

  async getCompanySettingsExtended() {
    return await commonRequest('GET', {}, 'CompanySettings/getCompanySettingsExtended')
  }

  async updateCompanySettingsExtended(payload: any) {
    const payloadData = { payload };
    return await commonRequest("POST", payloadData, 'CompanySettings/updateCompanySettingsExtended')
  }

  async updateCompanySettings(payload: any) {
    const payloadData = { payload };
    return await commonRequest("POST", payloadData, 'CompanySettings/updateCompanySettings')
  }

  async getFullCompanySettings() {
    return await commonRequest('GET', {}, 'CompanySettings/getFullCompanySettings');
  }
}
