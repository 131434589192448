import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { EditComponent } from 'Icons/editButtonComponent';
import { Form, Upload, UploadFile } from 'antd';
import ImgCrop from 'antd-img-crop';
import avatar from 'assets/avatar.png';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { EmployeeProfilePicturePlaceholder } from 'components/Placeholders';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { EmployeeServices } from 'services';
import {
  notifyWithError,
  notifyWithErrorOnMaxSizeUploadFile,
  notifyWithSuccessfulDataUpdated
} from 'utils/notificationsUtils';
import { getFileUploadFormData } from 'utils/uploadFileRequestWithFormData';

import './ProfileAvatar.less';

type UFile = UploadFile<ResponseType>;
export type { UFile, UploadFile };

export const ProfileAvatar = ({ scope, userId, data }) => {
  const { theme } = useThemeContext();
  const intl = useIntl();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState("success");
  const dispatch = useDispatch();
  const beforeCrop = (file: File) => validFile(file);


  const validFile = (file: UFile | File) => {
    const acceptedFileTypes = ['.jpg', '.jpeg', '.png', '.heic', '.heif'];
    const isValidFileType = acceptedFileTypes.some(type =>
      file.name.toLowerCase().endsWith(type)
    );

    if (!isValidFileType) {
      notifyWithError({
        message: <FormattedMessage id="UserProfile_ImageInvalidFormat" />,
        description: (
          <FormattedMessage id="UserProfile_ImageInvalid_Description" />
        )
      });
    }

    if (file && file.size && file.size >= 10 * 1000000) {
      notifyWithErrorOnMaxSizeUploadFile(10);
      return false;
    }

    return isValidFileType;
  };

  const uploadProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    beforeUpload(file) {
      setFileList([file]);
      return false;
    }
  };

  const handleFormFinish = async () => {
    try {
      setLoading("pending");
      let parsedData = new FormData();
      parsedData = getFileUploadFormData(
        null,
        fileList.length > 0
          ? {
            formDataFileName: 'file',
            uploadFiles: fileList
          }
          : null
      );

      parsedData.append('employeeId', data?.employeeId);

      const res = await EmployeeServices.updateProfilePicture(parsedData).then(
        (res) => {
          setLoading("success");
          if (res.data) {
            dispatch(setMethodUpdate(true));
            notifyWithSuccessfulDataUpdated(
              '',
              <FormattedMessage id="MyProfile_Avatar_send_succes" />
            );
          }
          else {
            notifyWithError({
              message: <FormattedMessage id="UserProfile_ImageInvalidFormat" />,
              description: (
                <FormattedMessage id="UserProfile_ImageInvalid_Description" />
              )
            });
            return;
          }
        }

      );

    } catch (error) {
      console.error('Error updating profile picture:', error);
      notifyWithError({
        message: 'Error updating profile picture',
        description: error.message
      });
    }
  };
  const uploader = (
    <Upload {...uploadProps} accept=".jpg, .jpeg, .png, .heic, .heif">
      <EditComponent className="change_avatar_btn" />
    </Upload>
  );

  return (
    <ComponentWithStatus
      status={loading}
      Placeholder={<EmployeeProfilePicturePlaceholder />}
    >
      <div className="profile_body">
        <div className="avatar_frame">
          <div className="avatar_back_color">
            <img
              className="picture_avatar"
              src={data?.externalAvatarUrl ?? avatar}
              alt="Avatar"
            />
          </div>
        </div>
        <Form
          className="form_avatar"
          form={form}
          id="editAvatarForm"
          labelWrap
          labelCol={{ span: 10 }}
          onFinish={handleFormFinish}
        >
          <ColFormItemComponent span={24} name="file">
            <ImgCrop
              rotationSlider
              aspectSlider
              showReset
              beforeCrop={beforeCrop}
              modalClassName={`${theme} change_avatar_modal`}
              modalCancel={intl.formatMessage({
                id: 'cancel'
              })}
              resetText={intl.formatMessage({
                id: 'btn_reset'
              })}
              modalOk={intl.formatMessage({
                id: 'save'
              })}
              modalTitle={intl.formatMessage({
                id: 'UpladAvatarCropModalTitle'
              })}
              onModalOk={() => {
                setTimeout(() => {
                  form.submit();
                }, 100);
              }}
            >
              {uploader}
            </ImgCrop>
          </ColFormItemComponent>
        </Form>
      </div>
    </ComponentWithStatus>
  );
};
