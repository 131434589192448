import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAsset } from '../../../services/providers/InventoryProvider/Assets/types';

const assetsSlice = createSlice({
  name: 'assets',
  initialState: [] as IAsset[],
  reducers: {
    setAssets(state, action: PayloadAction<IAsset[]>) {
      return action.payload;
    },
    updateAssetsStore(state, action: PayloadAction<any>) {
      const updatedAsset = action.payload;
      const assetIndex = state.findIndex(
        asset => asset.assetId === updatedAsset.assetId
      );

      if (assetIndex !== -1) {
        state[assetIndex] = {
          ...state[assetIndex],
          ...updatedAsset
        };
      }
    },
    addNewItemToAssets(state, action: PayloadAction<IAsset>) {
      state.push(action.payload);
    }
  }
});

export const { setAssets, updateAssetsStore, addNewItemToAssets } =
  assetsSlice.actions;
export default assetsSlice.reducer;
