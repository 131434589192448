import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { ArrowRight } from 'Icons/TimeManagementWidget/arrowRight';
import { Badge, BadgeProps, Divider, Typography } from 'antd';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import ComponentWithStatus from 'components/ComponentWithStatus';
import StyledCalendar from 'components/StyledCalendar';
import { defaultTimeOffPolicies } from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm/utils';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ro';
import dayLocaleData from 'dayjs/plugin/localeData';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TimeOffService } from 'services';

import {
  useIntlContext,
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';

import './CalendarWidget.less';
import { EventListItem } from './EventListItem';
import {
  CalendarWidgetPlaceholder,
  MobileCalendarWidgetPlaceholder
} from 'components/Placeholders';
import { EventDto } from 'pages/Calendar/types';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import {
  useSafeIntl,
  useTranslationExistenceChecker
} from '../../UIComponents/SafeFormattedMessage';
import { useCompanySettings } from 'settings';

dayjs.extend(dayLocaleData);
dayjs().localeData();

type GetCalendarMonthResponse = {
  [key: string]: EventDto[];
};

const CalendarWidget = () => {
  const { theme } = useThemeContext();
  const { isMobile } = useMobileContext();
  const { locale } = useIntlContext();
  const { countryCode } = useCompanySettings();
  // Two policies that should be highlighted with a blue dot
  const vacationTimeOffPolicies =
    defaultTimeOffPolicies(countryCode)?.filter(
      policy =>
        policy !== 'PersonalLeave' &&
        policy !== 'Overtime' &&
        policy !== 'LeaveOffsetting'
    ) || [];

  useEffect(() => {
    dayjs.extend(dayLocaleData);
    setSelectedDay(selectedDay.locale(locale));
  }, [locale]);
  const translationChecker = useTranslationExistenceChecker();
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [selectedDay, setSelectedDay] = useState(dayjs());
  const [timeSheetDays, setTimeSheetDays] =
    useState<GetCalendarMonthResponse>();
  const [eventList, setEventList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());

  const { data, status } = useApiRequestHook<any>(
    TimeOffService.getCalendarMonth,
    selectedDate,
    {},
    {},
    null,
    [selectedDate],
    false,
    true
  );

  useEffect(() => {
    if (status === 'success' && data) {
      setTimeSheetDays(data?.calendarDays);
    }
  }, [data, status]);

  const ref = useRef<boolean>(true);

  const onCellSelect = (value: Dayjs) => {
    if (ref.current) {
      setSelectedDay(dayjs(value));
    }

    ref.current = true;
  };

  const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
    setSelectedDate(value.startOf('month').format('YYYY-MM-DD'));
    setSelectedMonth(value.month());

    const day = dayjs(value);

    if (mode === 'month') {
      ref.current = false;
    }

    if (!dayjs().isSame(day, 'month')) {
      setSelectedDay(() => dayjs(value).startOf('month'));
    } else {
      setSelectedDay(dayjs(new Date()));
    }
  };

  const getListData = (value: Dayjs) => {
    const listData = [];

    for (const day in timeSheetDays) {
      const date = dayjs(day).format('DD/MM/YYYY');
      const valueDate = value.format('DD/MM/YYYY');

      if (date === valueDate) {
        timeSheetDays[day].forEach(e => {
          // Multiple Days Request
          if (
            !dayjs(e.startDate).isSame(e.endDate, 'day') &&
            [...vacationTimeOffPolicies, 'WorkFromHome'].includes(e.timeOffType)
          ) {
            listData.push({ type: 'vacation' });
          }

          // Single Day Request
          if (
            (dayjs(e.startDate).isSame(e.endDate, 'day') && 'PersonalLeave',
            [...vacationTimeOffPolicies, 'WorkFromHome'].includes(
              e.timeOffType
            ))
          ) {
            listData.push({ type: 'vacationSingle' });
          } else if (
            !['Overtime', 'PersonalLeave', 'LeaveOffsetting'].includes(
              e.timeOffType
            )
          ) {
            // Other Calendar Events
            listData.push({ type: e.eventType });
          }

          // Time Range Request
          if (
            ['Overtime', 'PersonalLeave', 'LeaveOffsetting'].includes(
              e.timeOffType
            )
          ) {
            listData.push({ type: 'TimeRange', index: 0 });
          }
        });
      }
    }

    return listData;
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);

    const filteredListData = listData.filter(
      (item, index, arr) =>
        arr.findIndex(
          obj => obj.type === item.type && obj.index === item.index
        ) === index
    );

    return (
      <>
        {filteredListData.map((item, index) => (
          <div key={index}>
            <div className="custom-cell-dot">
              {item.type !== 'vacation' && (
                <Badge
                  className={`${item.type}`}
                  status={item.type as BadgeProps['status']}
                  text={item.content}
                />
              )}
            </div>
            {item.type === 'vacation' && (
              <div className="custom-cell">
                <div className="blue-line"></div>
              </div>
            )}
            {item.type === 'vacationSingle' && (
              <div className="custom-cell">
                <div className="blue-line single"></div>
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  const getEventList = () => {
    for (const key in timeSheetDays) {
      const date = dayjs(key).format('DD/MM/YYYY');
      if (selectedDay.format('DD/MM/YYYY') === date) {
        const updatedEventList = timeSheetDays[key].map(event => {
          if (event.eventType === 'Holiday') {
            const eventName = translationChecker.doesTranslationExist({
              id: event.eventName
            });
            return {
              ...event,
              eventName: eventName
            };
          }
          return event;
        });
        setEventList(updatedEventList);
        return;
      }
    }
  };

  useEffect(() => {
    getEventList();
  }, [timeSheetDays]);

  useEffect(() => {
    getEventList();
  }, [selectedDay]);

  return (
    <ComponentWithStatus
      status={status}
      // status={'pending'}
      Placeholder={
        isMobile ? (
          <MobileCalendarWidgetPlaceholder />
        ) : (
          <CalendarWidgetPlaceholder />
        )
      }
    >
      <div className={`calendar-widget ${theme}`}>
        <div className="calendar-widget-row">
          <div className="calendar-widget-col">
            <StyledCalendar
              fullscreen={false}
              dateCellRender={dateCellRender}
              value={selectedDay}
              onSelect={onCellSelect}
              onChange={() => dayjs().clone().month(selectedMonth)}
              headerRender={({ value, onChange, onTypeChange }) => {
                const year = value.year();
                const month = value.month();

                return (
                  <div>
                    <div className="calendar-widget-heading">
                      <ArrowLeft
                        className={`calendarArrow  ${theme}`}
                        style={{ paddingInline: '0px' }}
                        onClick={() => {
                          const prevMonth = value.clone().month(month - 1);
                          onChange(prevMonth);
                          setSelectedMonth(month - 1);
                        }}
                      />
                      <Typography.Title level={5} className="monthYearTitle">
                        {dayjs().month(selectedMonth).format('MMMM') +
                          ', ' +
                          year}
                      </Typography.Title>
                      <ArrowRight
                        className={`calendarArrow  ${theme}`}
                        style={{ paddingInline: '0px' }}
                        onClick={() => {
                          const nextMonth = value.clone().month(month + 1);
                          onChange(nextMonth);
                          setSelectedMonth(month + 1);
                        }}
                      />
                    </div>
                  </div>
                );
              }}
              onPanelChange={onPanelChange}
            />{' '}
          </div>
          {!isMobile && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Divider type="vertical" className={'divider'} />
            </div>
          )}
          <div className="calendar-widget-col calendar-events-container">
            <div className="calendar-events-heading">
              <Typography.Title level={5} style={{lineHeight: "19.5px"}}>
                {selectedDay.format('dddd D')}
              </Typography.Title>
            </div>
            <div
              className={`calendar-event-list${
                eventList.length ? '' : ' center'
              }`}
            >
              <ComponentWithStatus
                status={eventList ? 'success' : 'pending'}
                ignoreAnimation={true}
              >
                {eventList.length ? (
                  eventList.map((event, index) => (
                    <EventListItem
                      key={index}
                      event={event}
                      date={selectedDay.format('DD/MM/YYYY')}
                      theme={theme}
                    />
                  ))
                ) : (
                  <h1 className="calendar-no-events-text">
                    <FormattedMessage id="noEventsText" />
                  </h1>
                )}
              </ComponentWithStatus>
            </div>
          </div>
        </div>
      </div>
    </ComponentWithStatus>
  );
};

export default CalendarWidget;
