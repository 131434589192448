export const GoogleIconComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path d="M18.2898 9.19945C18.2898 8.462 18.2299 7.92385 18.1004 7.36578H9.67944V10.6943H14.6224C14.5227 11.5214 13.9846 12.7672 12.7887 13.6042L12.7719 13.7157L15.4345 15.7783L15.619 15.7967C17.3131 14.2321 18.2898 11.93 18.2898 9.19945Z" fill="#4285F4"/>
      <path d="M9.6794 17.9692C12.101 17.9692 14.134 17.1719 15.6189 15.7967L12.7887 13.6042C12.0313 14.1324 11.0148 14.5011 9.6794 14.5011C7.30759 14.5011 5.29454 12.9365 4.57695 10.774L4.47176 10.7829L1.7032 12.9255L1.66699 13.0262C3.14189 15.9561 6.17146 17.9692 9.6794 17.9692Z" fill="#34A853"/>
      <path d="M4.57696 10.7739C4.38762 10.2158 4.27804 9.61786 4.27804 9.00002C4.27804 8.38211 4.38762 7.7842 4.567 7.22613L4.56198 7.10728L1.75872 4.93024L1.66701 4.97386C1.05913 6.18969 0.710327 7.55501 0.710327 9.00002C0.710327 10.445 1.05913 11.8103 1.66701 13.0261L4.57696 10.7739Z" fill="#FBBC05"/>
      <path d="M9.6794 3.49885C11.3636 3.49885 12.4996 4.22633 13.1474 4.83428L15.6787 2.36278C14.1241 0.917769 12.101 0.0308228 9.6794 0.0308228C6.17146 0.0308228 3.14189 2.04387 1.66699 4.97375L4.56698 7.22601C5.29454 5.06347 7.30759 3.49885 9.6794 3.49885Z" fill="#EB4335"/>
    </svg>
  );
};
