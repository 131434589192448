import dayjs from 'dayjs';

const _sort = (a, b, sortType, sorter = 'ascend') => {
  if (!a) a = '';
  if (!b) b = '';

  if (sortType === 'string') {
    return a && b
      ? a.localeCompare(b, 'ro', { sensitivity: 'base' })
      : b.localeCompare(a, 'ro', { sensitivity: 'base' });
  }

  if (sortType === 'date') {
    // Custom sort function for dates, handling empty values
    const dateA = a !== '' ? dayjs(a) : null;
    const dateB = b !== '' ? dayjs(b) : null;

    if (!dateA && !dateB) return 0; // Both dates are empty
    if (!dateA) return -1; // Only dateA is empty, push it to the bottom
    if (!dateB) return 1; // Only dateB is empty, push it to the bottom

    return dateA.isBefore(dateB) ? -1 : 1; // Compare valid date values
  }

  return a > b;
};

export default _sort;
