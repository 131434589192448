import employeeReducer from 'redux/slices/employeeSlice';
import userReducer from 'redux/slices/userSlice';
import generalReducer from 'redux/slices/generalSlice';
import teamReducer from 'redux/slices/teamSlice';
import methodUpdateReducer from 'redux/slices/methodUpdateSlice';
import notificationReducer from 'redux/slices/notificationSlice';
import colleaguesMonthEventsReducer from 'redux/slices/colleaguesMonthEventsSlice';
import ticketingReducer from 'redux/slices/ticketingSlice';
import ticketingFiltersReducer from 'redux/slices/ticketingFilters';
import documentsReducer from 'redux/slices/documentsSlice';
import companySettingsReducer from 'redux/slices/companySettingsSlice';
import assetsReducer from 'redux/slices/assetsSlice';

const reducer = {
  user: userReducer,
  employees: employeeReducer,
  general: generalReducer,
  teams: teamReducer,
  methodUpdate: methodUpdateReducer,
  notification: notificationReducer,
  colleaguesMonthEvents: colleaguesMonthEventsReducer,
  ticketing: ticketingReducer,
  ticketingFilters: ticketingFiltersReducer,
  documents: documentsReducer,
  companySettings: companySettingsReducer,
  assets: assetsReducer
};

export default reducer;
