// Request Status Options
export enum RequestStatusFilterOptions {
  // AllStatuses = 'AllStatuses',
  AwaitingDecision = 'AwaitingDecision',
  Unfinished = 'Unfinished',
  Pending = 'Pending',
  Approved = 'Approved',
  Confirmed = 'Confirmed',
  RevokePending = 'RevokeFilter',
  Draft = 'Draft',
}

export enum RequestDateFilterOptions {
  AllPeriods = 'AllPeriods',
  Today = 'Today',
  ThisWeek = 'ThisWeek',
  ThisMonth = 'ThisMonth',
  TimeRange = 'TimeRange',
}

export enum AditionalFileds {
  AllTypes = 'AllTypes',
}
