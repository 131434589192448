import { TimeOffService } from 'services';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';

export const onFinish = (modalProps, data, actions) => {
  const scope = modalProps?.confirmRequestType;

  const formActions = {
    deny: TimeOffService.deny,
    denyRevoke: TimeOffService.deny,
    revoke: TimeOffService.revoke,
    // Add toggle flag for multiple visible modals
    hasToggleModal: modalProps?.hasToggleModal,
  };

  onFormFinishWithPassedValidation(
    formActions,
    scope,
    data,
    actions,
    getSelectedValue(scope, modalProps)
  );
};

const getSelectedValue = (scope, modalProps) => {
  switch (scope) {
    case 'cancel':
      return getSuccessMessages.success_canceled_request;
    case 'revoke':
      return modalProps.confirmRequestRevocation
        ? getSuccessMessages.success_revocation_request
        : getSuccessMessages.success_revoked_request;
    case 'deny':
      return getSuccessMessages.success_denied_request;
    case 'denyRevoke':
      return getSuccessMessages.success_revocation_request_denied;
  }
};
