import React from 'react';
import ColFormItemComponent from '../../../components/UIComponents/Form/ColComponent';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const ColFormItemComponentWithTooltip = (
  { tooltip,
    children,
    ...rest
  }) => {
  return (
    <div className={'label_with_tooltip'}>
      <ColFormItemComponent
        {...rest}
        label={
          <span className={`label_with_tooltip_label`}>
            {rest.label}
            <Tooltip title={tooltip}>
              <QuestionCircleOutlined style={{ marginRight: 4 }} />
            </Tooltip>
          </span>
        }
      >
        {children}
      </ColFormItemComponent>
    </div>
  );
};
