import '../EmployeeProfileCard.less';
import { ProfileAvatar } from './ProfileAvatar';

export const ProfileUserMobile = ({ scope, employeeId, data }) => {
  return (
    <div className="wrapper-on-profile">
      <div className="background-on-profile">
        <div className="img-wrapper-mobile">
          <div style={{ padding: 20 }}>
            <ProfileAvatar scope={scope} data={data} userId={employeeId} />
          </div>
        </div>
      </div>
    </div>
  );
};
