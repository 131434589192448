export enum TimeOffColleaguesActions {
  INIT_STATE = 'INIT_STATE',
  FETCH_PENDING = 'FETCH_PENDING',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_ERROR = 'FETCH_ERROR',
  UPDATE_TOOLBAR_STATUS_FILTER = 'UPDATE_TOOLBAR_STATUS_FILTER',
  UPDATE_TOOLBAR_POLICY_FILTER = 'UPDATE_TOOLBAR_POLICY_FILTER',
  UPDATE_TOOLBAR_PERIOD_FILTER = 'UPDATE_TOOLBAR_PERIOD_FILTER',
  UPDATE_TOOLBAR_CRITERIA_FILTER = 'UPDATE_TOOLBAR_CRITERIA_FILTER',
  UPDATE_TABLE_PAGE_NUMBER = 'UPDATE_TABLE_PAGE_NUMBER',
  UPDATE_TABLE_RESULT_SIZE = 'UPDATE_TABLE_RESULT_SIZE',
  UPDATE_TABLE_FILTER = 'UPDATE_TABLE_FILTER',
  UPDATE_REQUEST_ID = 'UPDATE_REQUEST_ID'
}
