import { CheckCircleFilled, DownloadOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import type { SelectProps, UploadProps } from 'antd';
import { Button, DatePicker, Form, Input, Select, Tooltip, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Buffer } from 'buffer';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { DateToBeginningOfLocalDate } from 'components/DateParser';
import { SelectDropdown } from 'components/Dropdown/index';
import { useModalContext } from 'components/Modal/ModalProvider';
import {
  EmployeeFormPlaceholder,
  MobileEmployeeFormPlaceholder
} from 'components/Placeholders';
import { getTitleSelects } from 'components/TitlesColumns/utils';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
import DropdownInputPhone from 'components/UIComponents/InputPhoneComponent/DropdownInputPhone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useGetSearchParam from 'hooks/useGetSearchParams';
import { usePhoneValidation } from 'hooks/useValidPhoneNumber';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setFormValidation } from 'redux/slices/methodUpdateSlice';
import { RootState } from 'redux/store';
import { EmployeeDocumentService, EmployeeServices } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import { useCompanySettings } from 'settings';
import { downloadFile } from 'utils/downloadFiles';
import { notifyWithErrorOnMaxSizeUploadFile } from 'utils/notificationsUtils';
import { keyDownNumbersOnly } from 'utils/numberUtils';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import { adminRoles, allowPermision } from 'utils/userManagement';
import './EmployeeForm.less';
import { getCurrentEmployeePosition, onFinish } from './utils';

type EmployeeFormProps = {
  scope: string;
  employeeId?: string | number | { searchParamName: string };
};

const { Option } = Select;

const EmployeeForm: FC<EmployeeFormProps> = props => {
  const { scope, employeeId } = props;
  dayjs.extend(utc);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { toggleModal } = useModalContext();
  const [employeeIdentifier, setEmployeeIdentifier] = useState<
    string | number | { searchParamName: string }
  >(employeeId);
  const [phoneNumber, setPhoneNumber] = useState<string>(null);
  const { isValid, validatePhone, prepareNumberBeforeSubmit } =
    usePhoneValidation();
  const [daysAllowed, setDaysAllowed] = useState<number>(null);
  const [data, setData] = useState<IEmployee>(null);
  const [status, setStatus] = useState<string>(null);
  const [displayedFormFields, setDisplayedFormFields] = useState<any>(null);
  const dateFormat = 'DD/MM/YYYY';
  const { user } = useAuth();
  const isAdminUserRole = allowPermision(user.role, adminRoles);
  const generalRootState = useSelector((state: RootState) => state);
  const { theme } = useThemeContext();
  const { isMobile, isTabletDevice, isSmallLaptop } = useMobileContext();
  const [downloadSignatureDisplay, setDownloadSignatureDisplay] =
    useState<boolean>(false);
  const [signatureFile, setSignatureFile] = useState<any>();
  const [signatureButtonText, setSignatureButtonText] =
    useState<string>('Upload');

  const [contractType, setContractType] = useState(null);
  const { countryCode, email, emailProvider, emailType, office } =
    useCompanySettings();

  const Teams =
    generalRootState.teams.teams &&
    Object.keys(generalRootState.teams.teams).length
      ? generalRootState.teams.teams
      : [];

  const options: SelectProps['options'] = [];

  Teams.map(teams => {
    options.push({
      label: teams.name,
      value: teams.teamId,
      key: teams.teamId
    });
  });

  const defaultFunctionsEmployee: string[] = [
    'BusinessAnalyst',
    'Cleaning',
    'Copywriter',
    'Developer'
  ];

  const defaultDepartments: string[] = [
    'None',
    'SoftwareDevelopment',
    'QualityAssurance',
    'DevOps',
    'SoftwareArchitectureAndBusinessAnalysis'
  ];

  const defaultStatusEmployee: string[] = ['None', 'OnBoarding', 'Internship'];

  const generalRootStateExists =
    generalRootState.general && Object.keys(generalRootState.general).length;

  const generalRootStateEnums = generalRootStateExists
    ? generalRootState.general.enums
    : [];

  const departmentsEmployee = generalRootStateExists
    ? generalRootStateEnums.Department
    : defaultDepartments;

  const employeePositions = generalRootStateExists
    ? generalRootStateEnums.EmployeeFunction
    : defaultFunctionsEmployee;

  const statusEmployee = generalRootStateExists
    ? generalRootStateEnums.EmployeeStatus.filter(status => status !== 'None')
    : defaultStatusEmployee;

  const roleEmployee = generalRootStateExists
    ? generalRootStateEnums.UserRoles.filter(status => status !== 'None')
    : [];

  const genderEmployee = generalRootStateExists
    ? generalRootStateEnums.Gender
    : [];

  const workingScheduleEmployee = generalRootStateExists
    ? generalRootStateEnums.WorkingSchedule
    : [];

  const contractTypeEmployee = generalRootStateExists
    ? generalRootStateEnums.ContractType
    : [];

  const employeeIdFromParams = useGetSearchParam(
    'employeeId',
    'number'
  ).parsedParam;

  const getEmployeesProfile = async scope => {
    const parameters = {
      employeeId: employeeIdentifier
    };

    const profile =
      scope === 'editMyProfile'
        ? await EmployeeServices.getEmployeeProfile('false')
        : await EmployeeServices.getEmployee(null, parameters);

    if (profile.error) {
      setData(null);
      setStatus('error');
      return;
    }

    setDisplayedFormFields(Object.keys(profile.data));
    setData(profile.data);
    setStatus('success');
  };

  const toggleDisplayedFormFields = (formItemName: string) => {
    if (scope !== 'editMyProfile') {
      return;
    }

    const shouldFormItemBeHidden = !(
      displayedFormFields && displayedFormFields.includes(formItemName)
    );

    return {
      hidden: shouldFormItemBeHidden
    };
  };

  const updateDaysAllowed = (e: React.FormEvent<HTMLInputElement>) => {
    const daysAllowed = parseInt(e.currentTarget.value);
    e.currentTarget.value = Number.isNaN(daysAllowed)
      ? ''
      : daysAllowed.toString();
    setDaysAllowed(daysAllowed);
  };

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    beforeUpload(file) {
      if (file && file.size && file.size >= 5 * 1000000) {
        notifyWithErrorOnMaxSizeUploadFile(5);
        form.validateFields(['signature']);
        setDownloadSignatureDisplay(false);
        return false;
      }

      setDownloadSignatureDisplay(true);
      setSignatureFile(file);
      setSignatureButtonText('Uploaded');
      return false;
    }
  };

  const downloadSignatureSample = async () => {
    const formData = new FormData();
    if (signatureFile) {
      formData.append(`file`, signatureFile as Blob);
    }
    var response =
      await EmployeeDocumentService.downloadSignatureSample(formData);
    if (response && response.data) {
      const byteArray = new Uint8Array(response.data as any);
      const blob = new Blob([byteArray]);
      const url = window.URL.createObjectURL(blob);
      const fileName = Buffer.from(
        (response as any).headers['content-disposition'],
        'base64'
      );
      downloadFile(url, fileName);
    }
  };

  useEffect(() => {
    scope !== 'add' && getEmployeesProfile(scope);
  }, []);

  useEffect(() => {
    if (scope === 'add') {
      return;
    }

    if (!employeeId) {
      setEmployeeIdentifier(employeeIdFromParams);
    }

    if (
      (scope !== 'edit' &&
        scope !== 'editMyProfile' &&
        scope !== 'ColleagueProfile') ||
      status !== 'success'
    ) {
      return;
    }

    const fieldsToUpdate = {
      employeeId: !employeeIdFromParams ? employeeId : employeeIdFromParams,
      birthDate: data.birthDate ? dayjs(data?.birthDate) : null,
      dateAccountActivation: data.dateAccountActivation
        ? dayjs(data?.dateAccountActivation)
        : null,
      department: data.department,
      email: data.email,
      employeePersonalId: data.employeePersonalId?.toString(),
      endDate: data.endDate ? dayjs(data?.endDate) : null,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      phoneNumber: data.phoneNumber ? data.phoneNumber?.toString() : null,
      position: data.position,
      officialFunction: data.officialFunction,
      workingSchedule: data.workingSchedule,
      contractType: data.contractType,
      revokeDate: data.revokeDate ? dayjs(data?.revokeDate) : null,
      role: data.role,
      signature: data.signature,
      startDate: data.startDate ? dayjs(data?.startDate) : null,
      status: data.status,
      teamIds:
        data.teams && Object.keys(data.teams).length
          ? Object.entries(data.teams).map(([teamId]) => teamId)
          : [],
      doorCardId: data.doorCardId?.toString(),
      totalDaysAllowed: data.totalDaysAllowed?.toString()
    };

    setPhoneNumber(data.phoneNumber);
    setDaysAllowed(data.totalDaysAllowed);
    form.setFieldsValue(fieldsToUpdate);
  }, [scope, status]);

  const onFinishFormActions = {
    dispatch,
    toggleModal
  };

  const capitalizeInputFirstLetter = (e: React.FormEvent<HTMLInputElement>) =>
    (e.currentTarget.value = e.currentTarget.value.length
      ? e.currentTarget.value.slice(0, 1).toUpperCase() +
        e.currentTarget.value.slice(1)
      : e.currentTarget.value);

  const intl = useIntl();
  const ButtonCustom = BaseButton(ButtonSimple);
  const phoneIntputChange = phoneValue => {
    setPhoneNumber(phoneValue);
    validatePhone(phoneValue); // check if valid format phone number
  };
  return (
    <ComponentWithStatus
      status={status || scope === 'add' ? 'success' : 'pending'}
      Placeholder={
        isMobile || isTabletDevice ? (
          <MobileEmployeeFormPlaceholder />
        ) : (
          <EmployeeFormPlaceholder />
        )
      }
    >
      <Form
        form={form}
        colon={false}
        id="employeeForm"
        layout="horizontal"
        size="middle"
        labelAlign="left"
        labelWrap
        className="employee_form"
        onFinish={data => {
          const {
            dateAccountActivation,
            birthDate,
            revokeDate,
            startDate,
            endDate,
            phoneNumber,
            ...sendData
          } = data;

          dispatch(setFormValidation(true));
          onFinish(
            scope,
            {
              dateAccountActivation: dateAccountActivation
                ? DateToBeginningOfLocalDate(dateAccountActivation)
                : null,
              birthDate: birthDate
                ? DateToBeginningOfLocalDate(birthDate)
                : null,
              revokeDate: revokeDate
                ? DateToBeginningOfLocalDate(revokeDate)
                : null,
              startDate: startDate
                ? DateToBeginningOfLocalDate(startDate)
                : null,
              endDate: endDate ? DateToBeginningOfLocalDate(endDate) : null,
              phoneNumber: phoneNumber
                ? prepareNumberBeforeSubmit(phoneNumber)
                : '',
              ...sendData
            },
            !employeeIdFromParams ? employeeId : employeeIdFromParams,
            onFinishFormActions,
            isAdminUserRole,
            [signatureFile]
          );
        }}
        onFinishFailed={validationError =>
          onFormFinishFailedValidation(validationError, dispatch)
        }
      >
        {/* - GROUP 1: Personal information */}

        <div className="form-block-layout">
          <div className="block-title">
            <FormattedMessage
              id="group_employee_personal_information"
              defaultMessage="Personal Information"
            />
          </div>

          {/* -- FIELD: First Name */}

          <div className="block-content-wrapper">
            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_first_name" />}
              name="firstName"
              {...toggleDisplayedFormFields('firstName')}
              isDisabled={!isAdminUserRole}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'EmployeeFirstNameInputValidation'
                }),
                hasPattern: true,
                pattern:
                  /^[a-zA-ZÀ-ž\u00C0-\u024F\u1E00-\u1EFF]*(?!.*--)(?!.*\s-)(?!.*-\s)(?!.*\s{2})[a-zA-ZÀ-ž\u00C0-\u024F\u1E00-\u1EFF\s-]*[a-zA-ZÀ-ž\u00C0-\u024F\u1E00-\u1EFF]$/,
                patternMessage: intl.formatMessage({
                  id: 'EmployeeNameLastNameCharactersValidation'
                })
              })}
            >
              <Input
                onInput={capitalizeInputFirstLetter}
                disabled={!isAdminUserRole}
                minLength={1}
                maxLength={25}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Last Name  */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_last_name" />}
              name="lastName"
              isDisabled={!isAdminUserRole}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              {...toggleDisplayedFormFields('lastName')}
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'EmployeeLastNameInputValidation'
                }),
                hasPattern: true,
                pattern:
                  /^[a-zA-ZÀ-ž\u00C0-\u024F\u1E00-\u1EFF]*(?!.*--)(?!.*\s-)(?!.*-\s)(?!.*\s{2})[a-zA-ZÀ-ž\u00C0-\u024F\u1E00-\u1EFF\s-]*[a-zA-ZÀ-ž\u00C0-\u024F\u1E00-\u1EFF]$/,
                patternMessage: intl.formatMessage({
                  id: 'EmployeeNameLastNameCharactersValidation'
                })
              })}
            >
              <Input
                autoComplete="off"
                minLength={1}
                maxLength={25}
                disabled={!isAdminUserRole}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Gender */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_gender" />}
              name="gender"
              {...toggleDisplayedFormFields('gender')}
              id="genderFormItem"
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              isDisabled={!isAdminUserRole}
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'EmployeeGenderInputValidation'
                })
              })}
            >
              <SelectDropdown
                placement_bottom={true}
                disabled={!isAdminUserRole}
              >
                {genderEmployee.map(gender => (
                  <Option key={gender} value={gender}>
                    {intl.formatMessage({ id: gender })}
                  </Option>
                ))}
              </SelectDropdown>
            </ColFormItemComponent>

            {/* -- FIELD: Birth Date */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_birthdate" />}
              name="birthDate"
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view  ${theme}`
                  : `col-form-item-component  ${theme}`
              }
              isDisabled={!isAdminUserRole}
              {...toggleDisplayedFormFields('birthDate')}
            >
              <DatePicker
                inputReadOnly={true}
                getPopupContainer={container => container}
                disabled={!isAdminUserRole}
                style={{ width: '100%' }}
                format={dateFormat}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Email  */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_email" />}
              name="email"
              isDisabled={!isAdminUserRole}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              {...toggleDisplayedFormFields('email')}
              rules={setFormItemRules({
                requiredMessage: !isNaN(+email)
                  ? intl.formatMessage({ id: 'PleaseInsertEmailValidation' })
                  : intl.formatMessage(
                      { id: 'EmployeeEmailInputValidation' },
                      { companyEmail: email }
                    ),
                hasPattern: true,
                pattern: !isNaN(+email)
                  ? new RegExp(
                      `^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w\\w+)+$`
                    )
                  : new RegExp(`^\\w+[\\w-_.+]*${emailType}${emailProvider}$`),
                patternMessage: !isNaN(+email)
                  ? intl.formatMessage({ id: 'PleaseProvideEmailValidation' })
                  : intl.formatMessage(
                      { id: 'EmployeeEmailCompanyInputValidation' },
                      { companyEmail: email }
                    )
              })}
            >
              <Input
                disabled={!isAdminUserRole}
                // *To do: for later implementation
                // onInput={(e) => {
                //   e.currentTarget.value =
                //     e.currentTarget.value.length &&
                //     !e.currentTarget.value.includes('@')
                //       ? e.currentTarget.value + '@jivygroup.com'
                //       : e.currentTarget.value;
                // }}
                minLength={6}
                maxLength={100}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Personal ID */}

            <ColFormItemComponent
              name="employeePersonalId"
              label={<FormattedMessage id="label_employee_personal_id" />}
              isDisabled={!isAdminUserRole}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              {...toggleDisplayedFormFields('employeePersonalId')}
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'EmployeePersonalIDInputValidation'
                }),
                hasPattern: true,
                isRequired: countryCode === 'RO' ? false : true,
                pattern: /^[0-9]*$/,
                patternMessage: intl.formatMessage({
                  id: 'PleaseInsertOnlyNumbersValidation'
                }),
                isSpecificLengthRequired: true,
                requiredLength: 13,
                specificLengthRequiredMessage: intl.formatMessage({
                  id: 'PersonalIDLengthValidationMessage'
                })
              })}
            >
              <Input
                disabled={!isAdminUserRole}
                minLength={13}
                maxLength={13}
                onKeyDown={keyDownNumbersOnly}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Phone Number */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_phone_number" />}
              name="phoneNumber"
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              {...toggleDisplayedFormFields('phoneNumber')}
              rules={[
                ...setFormItemRules({ isRequired: false }),
                {
                  validator(_, value) {
                    if (!isValid) {
                      return Promise.reject(
                        intl.formatMessage({
                          id: 'FieldValidationErrorDescription'
                        })
                      );
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <DropdownInputPhone
                value={phoneNumber}
                onChange={phoneIntputChange}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Signature */}

            {countryCode === 'RO' &&
              (isAdminUserRole || scope === 'editMyProfile') && (
                <ColFormItemComponent
                  className={
                    !isAdminUserRole
                      ? `col-form-item-component-view ${theme}`
                      : `col-form-item-component ${theme}`
                  }
                  label={<FormattedMessage id="label_employee_signature" />}
                  name="signature"
                >
                  <div className="signature-upload-wrapper">
                    <div className="upload-action">
                      <ImgCrop
                        rotationSlider
                        aspectSlider
                        showReset
                        modalClassName={`${theme} signature-modal`}
                        modalCancel={intl.formatMessage({
                          id: 'cancel'
                        })}
                        resetText={intl.formatMessage({
                          id: 'btn_reset'
                        })}
                        modalOk={intl.formatMessage({
                          id: 'save'
                        })}
                        modalTitle={intl.formatMessage({
                          id: 'SignatureCropModalTitle'
                        })}
                      >
                        <Upload
                          {...uploadProps}
                          accept=".jpg, .jpeg, .png, .svg"
                        >
                          <ButtonCustom
                            className={`text-bold-normal btn-upload-custom  ${theme}`}
                          >
                            {signatureButtonText}
                          </ButtonCustom>
                        </Upload>
                      </ImgCrop>

                      {!!form.getFieldValue('signature') && (
                        <CheckCircleFilled />
                      )}
                    </div>

                    {downloadSignatureDisplay && (
                      <Button
                        onClick={e => downloadSignatureSample()}
                        icon={<DownloadOutlined />}
                        className={`text-bold-normal btn-default-custom  ${theme}`}
                      >
                        <span>
                          {' '}
                          <FormattedMessage id="DownloadSignatureButtonText" />
                        </span>
                      </Button>
                    )}
                  </div>
                </ColFormItemComponent>
              )}
          </div>
        </div>

        {/* - GROUP 2: Agreement information */}

        <div className="form-block-layout">
          <div className="block-title">
            <FormattedMessage
              id="group_employee_agreement_information"
              defaultMessage="Agreement information"
            />
          </div>

          <div className="block-content-wrapper">
            {/* -- FIELD: Start Date */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_start_date" />}
              name="startDate"
              dependencies={['endDate', 'startDate']}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              isDisabled={!isAdminUserRole}
              {...toggleDisplayedFormFields('startDate')}
              rules={[
                ...setFormItemRules({
                  requiredMessage: intl.formatMessage({
                    id: 'EmployeeStartDateInputValidation'
                  }),
                  hasPattern: false
                })
              ]}
            >
              <DatePicker
                inputReadOnly={true}
                getPopupContainer={container => container}
                disabled={!isAdminUserRole}
                className={
                  !isAdminUserRole
                    ? `col-form-item-component-view ${theme}`
                    : `col-form-item-component ${theme}`
                }
                style={{ width: '100%' }}
                format={dateFormat}
              />
            </ColFormItemComponent>

            {/* -- FIELD: End Date */}

            {isAdminUserRole && (
              <ColFormItemComponent
                isDisabled={!isAdminUserRole}
                className={
                  !isAdminUserRole
                    ? `col-form-item-component-view  ${theme}`
                    : `col-form-item-component  ${theme}`
                }
                label={<FormattedMessage id="label_employee_end_date" />}
                name="endDate"
                dependencies={['contractType', 'startDate']}
                rules={[
                  //EndDate

                  ...setFormItemRules({
                    isRequired: [
                      'LabourContractEndDate',
                      'LabourContractHomeEndDate',
                      'InternshipContract'
                    ].includes(form.getFieldValue('contractType')),
                    requiredMessage: intl.formatMessage({
                      id: 'EmployeeFormValidationEndDateOnSelectContractTypeDefinitePeriod'
                    })
                  }),
                  {
                    validator(_, value) {
                      const startDate = form.getFieldValue('startDate');
                      const endDate = form.getFieldValue('endDate');

                      if (endDate && endDate < startDate) {
                        return Promise.reject(
                          intl.formatMessage({
                            id: 'EndDateSmallerThanStartDateValidation'
                          })
                        );
                      } else {
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
                {...toggleDisplayedFormFields('endDate')}
              >
                <DatePicker
                  inputReadOnly={true}
                  disabled={!isAdminUserRole}
                  getPopupContainer={container => container}
                  style={{ width: '100%' }}
                  format={dateFormat}
                />
              </ColFormItemComponent>
            )}

            {/* -- FIELD: Employee Status */}

            <ColFormItemComponent
              span={12}
              label={<FormattedMessage id="label_employee_status" />}
              name="status"
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              isDisabled={!isAdminUserRole}
              dependencies={['contractType', 'status']}
              {...toggleDisplayedFormFields('status')}
              rules={[
                //Status
                ...setFormItemRules({
                  requiredMessage: intl.formatMessage({
                    id: 'EmployeeStatusInputValidation'
                  })
                }),
                {
                  validator(_, value) {
                    const contractType = form.getFieldValue('contractType');
                    const status = form.getFieldValue('status');

                    if (
                      status !== 'Internship' &&
                      status !== 'Deactivated' &&
                      contractType === 'InternshipContract'
                    ) {
                      return Promise.reject(
                        intl.formatMessage({
                          id: 'EmployeeFormValidationStatusIsNotIntershipAndContractTypeIstIntership'
                        })
                      );
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <SelectDropdown
                placement_bottom={true}
                disabled={!isAdminUserRole}
                onChange={value => {
                  if (value === 'Internship') {
                    form.setFieldValue('role', 'Intern');
                    form.setFieldValue('contractType', 'InternshipContract');
                  }
                }}
              >
                {statusEmployee.map((status, index) => {
                  return (
                    <Option key={index} value={status}>
                      {intl.formatMessage({ id: status })}
                    </Option>
                  );
                })}
              </SelectDropdown>
            </ColFormItemComponent>

            {/* -- FIELD: Contract type */}

            {isAdminUserRole && (
              <ColFormItemComponent
                label={<FormattedMessage id="label_employee_contract_type" />}
                name="contractType"
                id="contractTypeFormItem"
                className={
                  !isAdminUserRole
                    ? `col-form-item-component-view ${theme}`
                    : `col-form-item-component ${theme}`
                }
                isDisabled={!isAdminUserRole}
                {...toggleDisplayedFormFields('contractType')}
                dependencies={['status', 'contractType']}
                rules={[
                  //ContractType
                  ...setFormItemRules({
                    isRequired: isAdminUserRole,
                    requiredMessage: intl.formatMessage({
                      id: 'ContractTypeInputValidation'
                    })
                  }),
                  {
                    validator(_, value) {
                      const status = form.getFieldValue('status');
                      const contractType = form.getFieldValue('contractType');
                      if (
                        value !== null &&
                        status === 'Internship' &&
                        contractType !== 'InternshipContract'
                      ) {
                        return Promise.reject(
                          intl.formatMessage({
                            id: 'EmployeeFormValidationContractTypeIsNotIntershipAndStatusIsIntership'
                          })
                        );
                      } else {
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
              >
                <SelectDropdown
                  placement_bottom={true}
                  disabled={!isAdminUserRole}
                  onChange={value => {
                    if (value === 'InternshipContract') {
                      form.setFieldValue('status', 'Internship');
                      form.setFieldValue('role', 'Intern');
                    }
                    setContractType(value);
                  }}
                >
                  {contractTypeEmployee.map((contractType, index) => {
                    return (
                      <Option key={contractType} value={contractType}>
                        <Tooltip
                          placement="top"
                          title={intl.formatMessage({
                            id: contractType
                          })}
                        >
                          <div style={{ width: '100%' }}>
                            {intl.formatMessage({ id: contractType })}
                          </div>
                        </Tooltip>
                      </Option>
                    );
                  })}
                </SelectDropdown>
              </ColFormItemComponent>
            )}

            {/* -- FIELD: Working Schedule */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_work_schedule" />}
              name="workingSchedule"
              id="workingScheduleFormItem"
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              isDisabled={!isAdminUserRole}
              {...toggleDisplayedFormFields('workingSchedule')}
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'EmployeeScheduleInputValidation'
                })
              })}
            >
              <SelectDropdown
                placement_bottom={true}
                disabled={!isAdminUserRole}
              >
                {workingScheduleEmployee.map(workingSchedule => {
                  return (
                    <Option key={workingSchedule} value={workingSchedule}>
                      {intl.formatMessage({ id: workingSchedule })}
                    </Option>
                  );
                })}
              </SelectDropdown>
            </ColFormItemComponent>

            {/* -- FIELD: Office */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_office" />}
              isDisabled={true}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
            >
              <Input
                autoComplete="off"
                minLength={1}
                maxLength={25}
                disabled={!isAdminUserRole}
                value={office}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Official Function */}

            <ColFormItemComponent
              span={12}
              label={<FormattedMessage id="label_employee_official_function" />}
              name="officialFunction"
              isDisabled={!isAdminUserRole}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view  ${theme}`
                  : `col-form-item-component  ${theme}`
              }
              {...toggleDisplayedFormFields('officialFunction')}
              rules={setFormItemRules({
                isRequired: false,
                isMinLengthRequired: true,
                isMaxLengthRequired: true,
                hasPattern: true,
                pattern: /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF]{2}.*$/,
                patternMessage: intl.formatMessage({
                  id: 'OfficialFunctionFirstCharacterValidation'
                }),
                minLengthRequired: 2,
                maxLengthRequired: 100,
                minLengthRequiredMessage: intl.formatMessage({
                  id: 'OfficialFunctionMinLengthValidation'
                }),
                maxLengthRequiredMessage: intl.formatMessage({
                  id: 'OfficialFunctionMaxLengthValidation'
                })
              })}
            >
              <Input
                autoComplete="off"
                disabled={!isAdminUserRole}
                minLength={2}
                maxLength={101}
              />
            </ColFormItemComponent>

            {/* -- FIELD: Employee Position */}

            <ColFormItemComponent
              label={<FormattedMessage id="label_employee_position" />}
              name="position"
              isDisabled={!isAdminUserRole}
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              {...toggleDisplayedFormFields('position')}
              rules={setFormItemRules({
                requiredMessage: intl.formatMessage({
                  id: 'EmployeeJobInputValidation'
                })
              })}
            >
              <SelectDropdown
                placement_bottom={true}
                disabled={!isAdminUserRole}
              >
                {employeePositions.map((position, index) => (
                  <Option key={index} value={position}>
                    {getCurrentEmployeePosition(position)}
                  </Option>
                ))}
              </SelectDropdown>
            </ColFormItemComponent>

            {/* -- FIELD: Department */}

            <ColFormItemComponent
              id="departmentFormItem"
              label={<FormattedMessage id="label_employee_department" />}
              name="department"
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view  ${theme}`
                  : `col-form-item-component  ${theme}`
              }
              isDisabled={!isAdminUserRole}
              {...toggleDisplayedFormFields('department')}
            >
              <SelectDropdown
                placement_bottom={true}
                disabled={!isAdminUserRole}
                dropdownStyle={{ animationDuration: '0s' }}
              >
                {departmentsEmployee.map((department, index) => {
                  const departaments = getTitleSelects(
                    department,
                    'departaments'
                  );
                  return (
                    <Option
                      key={`department-${department}-${index}`}
                      value={department}
                    >
                      {departaments}
                    </Option>
                  );
                })}
              </SelectDropdown>
            </ColFormItemComponent>

            {/* -- FIELD: Teams */}

            <ColFormItemComponent
              id="teamFormItem"
              label={<FormattedMessage id="label_employee_team" />}
              name="teamIds"
              className={
                !isAdminUserRole
                  ? `col-form-item-component-view  ${theme}`
                  : `col-form-item-component  ${theme}`
              }
              isDisabled={!isAdminUserRole}
              {...toggleDisplayedFormFields('teams')}
            >
              <SelectDropdown
                placement_bottom={true}
                maxTagCount={'responsive'}
                mode="multiple"
                disabled={!isAdminUserRole}
                placeholder={intl.formatMessage({
                  id: 'PleaseSelectPlaceholder'
                })}
              >
                {options.map(option => (
                  <Option
                    key={option.key}
                    value={option.key.toString()}
                    label={option.label}
                  >
                    {option.label}
                  </Option>
                ))}
              </SelectDropdown>
            </ColFormItemComponent>

            {/* -- FIELD: Vacation Days */}

            {isAdminUserRole && (
              <ColFormItemComponent
                isDisabled={!isAdminUserRole}
                className={
                  !isAdminUserRole
                    ? `col-form-item-component-view ${theme}`
                    : `col-form-item-component ${theme}`
                }
                label={<FormattedMessage id="label_employee_vacation_days" />}
                name="totalDaysAllowed"
                {...toggleDisplayedFormFields('totalDaysAllowed')}
                rules={setFormItemRules({
                  isRequired: isAdminUserRole,
                  requiredMessage: intl.formatMessage({
                    id: 'EmployeeVacationDaysAllowedInputValidation'
                  }),
                  isMaxLengthRequired: true,
                  maxLengthRequired: 3,
                  maxLengthRequiredMessage:
                    'Please note that the vacation days allowed should not exceed three digits.'
                })}
              >
                <Input
                  disabled={!isAdminUserRole}
                  value={daysAllowed}
                  onInput={updateDaysAllowed}
                  onChange={updateDaysAllowed}
                  onKeyDown={keyDownNumbersOnly}
                  minLength={1}
                  maxLength={3}
                />
              </ColFormItemComponent>
            )}
          </div>
        </div>

        {/* - GROUP 3: Account information */}

        {isAdminUserRole && (
          <div className="form-block-layout">
            <div className="block-title">
              <FormattedMessage
                id="group_employee_account_information"
                defaultMessage="Account information"
              />
            </div>

            <div className="block-content-wrapper">
              {/* -- FIELD: Role */}

              {isAdminUserRole && (
                <ColFormItemComponent
                  label={<FormattedMessage id="label_employee_role" />}
                  name="role"
                  {...toggleDisplayedFormFields('role')}
                  isDisabled={!isAdminUserRole}
                  className={
                    !isAdminUserRole
                      ? `col-form-item-component-view ${theme}`
                      : `col-form-item-component ${theme}`
                  }
                  validateTrigger="onChange"
                  dependencies={['contractType', 'status', 'role']}
                  rules={[
                    //Role
                    ...setFormItemRules({
                      isRequired: isAdminUserRole,
                      requiredMessage: intl.formatMessage({
                        id: 'EmployeeRoleInputValidation'
                      })
                    }),
                    {
                      validator(_, value) {
                        const contractType = form.getFieldValue('contractType');
                        const status = form.getFieldValue('status');
                        const role = form.getFieldValue('role');

                        if (
                          role !== 'Intern' &&
                          contractType === 'InternshipContract' &&
                          status === 'Internship'
                        ) {
                          return Promise.reject(
                            intl.formatMessage({
                              id: 'EmployeeRoleInputValidationContractTypeIntershipAndStatus'
                            })
                          );
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <SelectDropdown
                    disabled={!isAdminUserRole}
                    showSearch={false}
                  >
                    {roleEmployee.map(employeeRole => {
                      const employeeRoleValue = getTitleSelects(
                        employeeRole,
                        'userRole'
                      );
                      return (
                        <Select.Option
                          key={`employeeRole-${employeeRole}`}
                          value={employeeRole}
                        >
                          {employeeRoleValue}
                        </Select.Option>
                      );
                    })}
                  </SelectDropdown>
                </ColFormItemComponent>
              )}

              {/* -- FIELD: Employee Door Card ID */}

              <ColFormItemComponent
                label={<FormattedMessage id="label_employee_door_card_id" />}
                name="doorCardId"
                {...toggleDisplayedFormFields('doorCardId')}
                className={
                  !isAdminUserRole
                    ? `col-form-item-component-view ${theme}`
                    : `col-form-item-component ${theme}`
                }
                isDisabled={!isAdminUserRole}
                rules={setFormItemRules({
                  isRequired: countryCode === 'RO' ? false : isAdminUserRole,
                  requiredMessage: intl.formatMessage({
                    id: 'EmployeeDoorCardInputValidation'
                  }),
                  hasPattern: true,
                  pattern: /^[0-9]*$/,
                  patternMessage: intl.formatMessage({
                    id: 'EmployeeDoorCardNumberValidation'
                  }),
                  isMaxLengthRequired: true,
                  maxLengthRequired: 4,
                  maxLengthRequiredMessage: intl.formatMessage({
                    id: 'EmployeeDoorCardLengthValidation'
                  })
                })}
              >
                <Input
                  disabled={!isAdminUserRole}
                  minLength={1}
                  maxLength={4}
                  onKeyDown={keyDownNumbersOnly}
                />
              </ColFormItemComponent>

              {/* -- FIELD: Account Activation Date */}

              {isAdminUserRole && (
                <ColFormItemComponent
                  isDisabled={!isAdminUserRole}
                  className={
                    !isAdminUserRole
                      ? `col-form-item-component-view  ${theme}`
                      : `col-form-item-component  ${theme}`
                  }
                  label={
                    <FormattedMessage id="label_employee_activation_date" />
                  }
                  name="dateAccountActivation"
                  {...toggleDisplayedFormFields('dateAccountActivation')}
                >
                  <DatePicker
                    inputReadOnly={true}
                    disabled={!isAdminUserRole}
                    getPopupContainer={container => container}
                    style={{ width: '100%' }}
                    format={dateFormat}
                  />
                </ColFormItemComponent>
              )}

              {/* -- FIELD: Revoke Date */}

              <ColFormItemComponent
                label={<FormattedMessage id="label_employee_revoke_date" />}
                name="revokeDate"
                {...toggleDisplayedFormFields('revokeDate')}
                isDisabled={!isAdminUserRole}
                className={
                  !isAdminUserRole
                    ? `col-form-item-component-view  ${theme}`
                    : `col-form-item-component  ${theme}`
                }
              >
                <DatePicker
                  inputReadOnly={true}
                  disabled={!isAdminUserRole}
                  getPopupContainer={container => container}
                  style={{ width: '100%' }}
                  format={dateFormat}
                />
              </ColFormItemComponent>
            </div>
          </div>
        )}
      </Form>
    </ComponentWithStatus>
  );
};

export default EmployeeForm;
