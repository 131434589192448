import React from 'react';
import { useAuth } from 'services/providers/AuthProvider';
import {
  allowPermision,
  getCurrentUserRole,
  rolesAllow,
  userRoles
} from 'utils/userManagement';
import { colleaguesTimeOffReducer, defaultState, initialState } from './reducers';
import { TimeOffColleaguesContext } from '../types';
import { TimeOffColleaguesActions } from './actions';

const TimeOffColleaguesProviderContext = React.createContext<TimeOffColleaguesContext>(
  {} as TimeOffColleaguesContext
);

export const TimeOffColleaguesProvider = ({ children }) => {
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const isTeamLeaderRole = userRole === userRoles.TL;

  const [
    timeOffColleaguesState,
    timeOffColleaguesDispatch
  ] = React.useReducer(colleaguesTimeOffReducer, initialState);


  React.useEffect(() => {
    const { filterBy, filters } = defaultState[
      allowPermision(userRole, rolesAllow) || isTeamLeaderRole
        ? 'admin'
        : 'employee'
    ];

    timeOffColleaguesDispatch({
      type: TimeOffColleaguesActions.INIT_STATE,
      payload: {
        filterBy: {
          ...initialState.filterBy,
          ...filterBy
        },
        filters: {
          ...initialState.filters,
          ...filters
        },
        pagination: {
          ...initialState.pagination,
          resultSize: initialState?.pagination?.resultSize,
          pageNumber: initialState?.pagination?.pageNumber
        }
      },
    });
  }, [userRole]);

  return (
    <TimeOffColleaguesProviderContext.Provider
      value={{ timeOffColleaguesState, timeOffColleaguesDispatch }}
    >
      {children}
    </TimeOffColleaguesProviderContext.Provider>
  );
};

export const useColleaguesRequestsFilterContext = () => {
  return React.useContext(TimeOffColleaguesProviderContext);
};
