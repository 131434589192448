import { AssetsService } from 'services';

export const onFinish = (scope, data, assetId) => {
  data = { assetId, ...data };

  const formActions = {
    add: AssetsService.addAsset,
    edit: AssetsService.updateAsset
  };

  formActions[scope](data).then((res) => {

  });
};

export const handleDecimalsForIntegerValue = (value: string) => {
  if (
    typeof value === 'string' &&
    Number.isNaN(parseFloat(value.replace(',', '.')))
  ) {
    return value;
  }

  const splitValueWithDecimals = value.replace(',', '.').split('.');

  const isValueWithTwoDecimals =
    splitValueWithDecimals.length === 2 &&
    splitValueWithDecimals[1].length === 2
      ? true
      : false;

  return isValueWithTwoDecimals
    ? value
    : splitValueWithDecimals.length === 2
    ? splitValueWithDecimals[1].length
      ? splitValueWithDecimals[1].length === 1
        ? value + '0'
        : value + '00'
      : value + '00'
    : splitValueWithDecimals.length === 1 && !splitValueWithDecimals[0].length
    ? value
    : value + ',00';
};
