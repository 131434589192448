import { JsonEditor as Editor } from 'jsoneditor-react18';
import useApiRequestHook from '../../../hooks/useApiRequest.hook';
import { CompanySettingsService } from '../../../services';
import { Form } from 'antd';
import { PlatformSettingsPlaceHolder } from '../../../components/Placeholders/Form';
import React from 'react';
import ComponentWithStatus from 'components/ComponentWithStatus';

export const JsonEditor = ({setRawData}) => {
  const [form] = Form.useForm();

  const { data, status } = useApiRequestHook<any>(
    CompanySettingsService.getFullCompanySettings,
    null,
    null,
    {}
  );

  return (
    <Form key={'raw-edit'} form={form} style={{width: '100%'}}>
      <ComponentWithStatus
        status={status}
        Placeholder={<PlatformSettingsPlaceHolder boxheight={'312px'} />}
      >
        {data && <Editor value={data} onChange={val => setRawData(val)}  schema={null}/>}
      </ComponentWithStatus>
    </Form>
  );
};
