import '@splidejs/react-splide/css/core';
import './splideCarousel.less';
import {
  Splide,
  SplideProps,
  SplideSlide,
  SplideTrack
} from '@splidejs/react-splide';
import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { ArrowRight } from 'Icons/TimeManagementWidget/arrowRight';
import { useRef, useState } from 'react';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ArrowLeftRounded } from 'Icons/TimeManagementWidget/arrowLeftRounded';
import { ArrowRightRounded } from 'Icons/TimeManagementWidget/arrowRightRounded';

interface CustomProps extends SplideProps {
  children?: Array<JSX.Element>;
  type?: number;
}
export default function SplideCustomCarousel(props: CustomProps) {
  const [currentActiveDotIndex, setCurrentActiveDotIndex] = useState(0);
  const [hideArrowsValue, setHideArrows] = useState(false);
  const { theme } = useThemeContext();
  const splideRef = useRef<any>(null);

  const { options } = props;
  const hideArrows = () => {
    if (options.perMove >= props.children?.length) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Splide
      className="splide-carousel"
      hasTrack={false}
      style={{
        position: 'relative'
      }}
      onMoved={(splide, newIndex, prevIndex, destIndex) => {
        const { perPage } = splide.options;
        const { length } = props.children;
        if (length - newIndex < destIndex) {
          setHideArrows(true);
        } else {
          setHideArrows(false);
        }

        if (newIndex === 0) {
          setCurrentActiveDotIndex(0);
          return;
        } else if (newIndex < length - perPage) {
          setCurrentActiveDotIndex(1);
          return;
        } else {
          setCurrentActiveDotIndex(2);
        }
      }}
      {...props}
    >
      <SplideTrack>
        {props?.children?.map((item, index) => (
          <SplideSlide key={index}>{item}</SplideSlide>
        ))}
      </SplideTrack>

      <div
        className="splide__arrows"
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {props?.type === 3 ? ( //desktop arrows Time off Request Widget
          <div className={`rounded_arrows_container ${theme}`}>
            <ArrowLeftRounded
              style={{
                opacity: currentActiveDotIndex === 0 || hideArrows() ? '0' : '1'
              }}
              className={`rounded-circle-arrow splide__arrow splide__arrow--prev ${theme}`}
              onClick={() => splideRef.current?.splide.go('<')}
            />
            <ArrowRightRounded
              style={{
                opacity: hideArrowsValue === true || hideArrows() ? '0' : '1'
              }}
              className={`rounded-circle-arrow splide__arrow splide__arrow--next ${theme}`}
              onClick={() => splideRef.current?.splide.go('>')}
            />
          </div>
        ) : props?.type === 2 ? ( //mobile arrows Time off Request Widget
          <div className="splide-custom-arrows-container type-2">
            <ArrowLeftRounded
              style={{
                opacity: currentActiveDotIndex === 0 ? '0' : '1'
              }}
              className={`rounded-circle-arrow splide__arrow splide__arrow--prev ${theme}`}
            />

            <ArrowRightRounded
              style={{
                opacity: currentActiveDotIndex === 2 ? '0' : '1'
              }}
              className={`rounded-circle-arrow splide__arrow splide__arrow--next ${theme}`}
            />
          </div>
        ) : props?.type === 4 ? (
          //desktop arrows Ticketing  Widget
          <div className={`splide-custom-arrows-container type-4 ${theme}`}>
            <ArrowLeftRounded
              className={`rounded-circle-arrow splide__arrow splide__arrow--prev ${theme}`}
              onClick={() => splideRef.current?.splide.go('<')}
            />
            <ArrowRightRounded
              className={`rounded-circle-arrow splide__arrow splide__arrow--next ${theme}`}
              onClick={() => splideRef.current?.splide.go('>')}
            />
          </div>
        ) : (
          <div className="splide-custom-arrows-container">
            <ArrowLeft className="splide__arrow splide__arrow--prev" />
            <ul
              className={`custom-pagination-dots ${theme}`}
              style={{ margin: 0, marginBlockEnd: 0, marginBlockStart: 0 }}
            >
              {/* As per designer request, we have only 3 dots to display */}
              <li
                className={`custom-carousel-pagination-button ${
                  currentActiveDotIndex === 0 ? 'is-active' : ''
                }`}
              ></li>
              <li
                className={`custom-carousel-pagination-button ${
                  currentActiveDotIndex === 1 ? 'is-active' : ''
                }`}
              ></li>
              <li
                className={`custom-carousel-pagination-button ${
                  currentActiveDotIndex === 2 ? 'is-active' : ''
                }`}
              ></li>
            </ul>

            <ArrowRight className="splide__arrow splide__arrow--next" />
          </div>
        )}
      </div>
    </Splide>
  );
}
