import { PolicyConfig } from 'AppProvider/ConfigManager/config';
import { useAuth } from 'services/providers/AuthProvider';
import { useCompanySettings } from 'settings';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';

import { CardRequest } from 'components/UIComponents/CardRequest';

import SplideCustomCarousel from 'components/SplideCarousel';
import { useEffect, useRef, useState } from 'react';
import './reqyestsStyles.less';

export const Requests = ({
  data,
  scope,
  openModal,
  setModalOpen,
  setModalProps,
  requestFormProps
}: {
  data: any;
  scope?: string;
  openModal?: any;
  setModalOpen?: any;
  setModalProps?: any;
  requestFormProps?: any;
}) => {
  const { countryCode } = useCompanySettings();

  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, adminRoles);
  const parentRef = useRef(undefined);

  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(3);

  const filteredRequests = PolicyConfig[
    countryCode
  ]?.DefaultTimeOffRequests.filter(
    request =>
      !request.isHidden &&
      (isAdminUserRole ? true : !request.isAdminOnly) &&
      request.isSpecialRequest !== true
  );

  const specialFiltredRequests = PolicyConfig[
    countryCode
  ]?.DefaultTimeOffRequests.filter(
    request =>
      !request.isHidden &&
      (isAdminUserRole ? true : !request.isAdminOnly) &&
      request.isSpecialRequest === true
  );
  const carouselItemWidth = 170;

  useEffect(() => {
    const updateItemsPerPage = () => {
      const parentWidth = parentRef.current?.offsetWidth;
      if (parentWidth) {
        setItemsPerPage(Math.round(parentWidth / carouselItemWidth));
      }
    };

    // Update itemsPerPage on mount and when the window is resized
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, [carouselItemWidth]);

  const [hoveredItem, setHoveredItem] = useState<number>(-1);

  return (
    <div
      ref={parentRef}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <SplideCustomCarousel
        style={{ height: '100%' }}
        options={{
          easing: 'ease-in-out',
          speed: 800,
          perMove: itemsPerPage,
          // perPage: itemsPerPage,
          autoWidth: true,
          drag: false
        }}
        type={3}
      >
        {filteredRequests?.map(
          ({ icon, title, request, allowUnfinishedRequests }, index) => {
            const isLastInPage = (index + 1) % itemsPerPage === 0;
            return (
              <div className="time-management-requests-body" key={index}>
                <CardRequest
                  key={index}
                  scope={scope}
                  selecteditem={selectedItem}
                  setselecteditem={setSelectedItem}
                  openModal={openModal}
                  setModalOpen={setModalOpen}
                  setModalProps={setModalProps}
                  index={index}
                  icon={icon}
                  title={title}
                  request={request}
                  allowunfinishedrequests={allowUnfinishedRequests}
                  data={data}
                  filteredrequests={specialFiltredRequests}
                  onHover={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(-1)}
                  isHovered={hoveredItem === index}
                />
                {/* {!isLastInPage && index < filteredRequests.length - 1 && ( */}
                {index < filteredRequests.length - 1 && (
                  <div className="devider_container">
                    <div className="divider-title-and-value " key={index}></div>
                  </div>
                )}
              </div>
            );
          }
        )}
      </SplideCustomCarousel>
    </div>
  );
};
