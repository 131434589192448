import { Checkbox, Tooltip } from 'antd';
import { IntlShape } from 'react-intl';

export interface PlaceholderOptions {
  placeholder: string;
  selectedOptions: any;
  dataForOptions: any;
  intl: IntlShape;
  disableTitleOfFilter: boolean;
}
export interface RenderOptions {
  dataForOptions: any;
  handleCheckboxClick: any;
  handleOptionClick: any;
  selectedOptions: any[];
  intl: IntlShape;
}

export interface PlaceholderResult {
  placeholder: string;
}

export const getTranslationForSelectedOption = (criteria, data) => {
  for (const criterion of criteria) {
    if (data[criterion] !== undefined) {
      return data[criterion];
    }
  }
  return null;
};

export const getPlaceholder = ({
  placeholder,
  selectedOptions,
  dataForOptions,
  intl,
  disableTitleOfFilter,
}: PlaceholderOptions): PlaceholderResult => {
  const filterDefaultPlaceholderText = intl.formatMessage({ id: 'FilterBy' });
  const totalOptions = Object.values(dataForOptions).length;
  if (selectedOptions?.length === 1) {
    const optionText = getTranslationForSelectedOption(
      selectedOptions,
      dataForOptions
    );

    const formattedOptionText =
      typeof optionText === 'string'
        ? intl.formatMessage({ id: optionText })
        : optionText?.props?.id
        ? intl.formatMessage({ id: optionText.props.id })
        : '';

    return {
      placeholder: `${placeholder}: ${formattedOptionText} ${selectedOptions?.length}/${totalOptions}`,
    };
  } else if (selectedOptions?.length > 1) {
    return {
      placeholder: `${placeholder} ${selectedOptions?.length}/${totalOptions}`,
    };
  } else {
    return {
      placeholder: `${
        disableTitleOfFilter ? '' : filterDefaultPlaceholderText + ':'
      } ${placeholder}`,
    };
  }
};

export const renderContent = (content, isEllipsisActive) => {
  return isEllipsisActive ? (
    <Tooltip placement="top" title={content}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
