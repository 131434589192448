import * as React from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'services/providers/AuthProvider';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { useModalContext } from 'components/Modal/ModalProvider';
import { useConfirmationModalContext } from 'components/Modal/ConfirmModal/ConfirmationModalProvider';
import { useCompanySettings } from 'settings';
import { RootState } from 'redux/store';
import { TimeOffService } from 'services';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { DATE_FORMAT, ModalProps } from './utils';
import { TimeOffRequest } from 'services/providers/TimeOffProvider/types';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { ApproveAndConfirmButtonPolicies } from '../../TimeOffRequestTable/RequestActionButton';
import { policiesWithUpload } from '../../TimeOffRequestForm/utils';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from '../../../../../utils/userManagement';
import { BaseButton } from 'components/Buttons';
import { AsyncButton } from 'components/Buttons/AsyncButton';
import ModalComponent from '../../../../Modal';
import StateActionsForm from '../../TimeOffRequestTable/StateActionsForm';
import { TimeOffCancelIcon } from 'Icons/TimeOffActionIcons/TimeOffCancelIcon';
import { TimeOffSubmitIcon } from 'Icons/TimeOffActionIcons/TimeOffSubmitIcon';
import { TimeOffApproveIcon } from 'Icons/TimeOffActionIcons/TimeOffApproveIcon';
import { notifyWithSuccessfulDataUpdated } from '../../../../../utils/notificationsUtils';
import { TimeOffRevokeIcon } from 'Icons/TimeOffActionIcons/TimeOffRevokeIcon';
import { TimeOffDenyRevocationIcon } from 'Icons/TimeOffActionIcons/TimeOffDenyRevocationIcon';
import { TimeOffApproveRevocationIcon } from 'Icons/TimeOffActionIcons/TimeOffApproveRevocationIcon';
import { TimeOffDenyIcon } from 'Icons/TimeOffActionIcons/TimeOffDenyIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { TimeOffMarkAsConfirmedIcon } from 'Icons/TimeOffActionIcons/TimeOffMarkAsConfirmedIcon';

interface IProps {
  setIsEdit?: any;
  modalProps?: TimeOffRequest;
  setConfirmModalProps?: any;
}

const colorscheme = {
  light: '#402A87',
  dark: '#FFFFFF'
};

const ViewRequestButtons: React.FC<IProps> = props => {
  const { setIsEdit, modalProps, setConfirmModalProps } = props;

  const {
    requestId,
    status,
    requestType,
    startDate,
    endDate,
    publicName,
    daysTaken,
    requestedByEmployeeEmail,
    requestedForEmployee,
    secondaryApproverEmployee,
    isSigned
  } = modalProps;

  const location = useLocation();
  const formData = new FormData();
  const dispatch = useDispatch();
  const [modalState, setModalState] = React.useState(null);
  const [isStateModalOpen, setIsStateModalOpen] =
    React.useState<boolean>(false);
  const [requestScope, setRequestScope] = React.useState<string>(null);

  React.useEffect(() => {
    if (location.pathname.includes('personalRequests')) {
      setRequestScope('personal');
    } else {
      setRequestScope('colleagues');
    }
  }, [location.pathname]);

  const { user } = useAuth();
  const { theme } = useThemeContext();
  const { toggleRequestModal, setIsModalOpen } = useModalContext();
  const { setIsConfirmModalOpen, setOnOkModalPromise } =
    useConfirmationModalContext();

  const formattedStartDate = dayjs(startDate).format(DATE_FORMAT);
  const formattedEndDate = dayjs(endDate).format(DATE_FORMAT);

  formData.append('requestId', requestId?.toString());

  const CustomButton = BaseButton(AsyncButton);
  const { countryCode } = useCompanySettings();
  const isRO = countryCode === 'RO';
  const userRole = getCurrentUserRole(user);

  const userId = useSelector(
    (state: RootState) => state.user?.loggedUser?.employeeId
  );

  const isAdminUserRole = allowPermision(userRole, adminRoles);

  const userHasAccess =
    requestScope === 'personal' ||
    (requestScope === 'colleagues' &&
      user?.email === requestedByEmployeeEmail) ||
    isAdminUserRole;

  const checkSecondaryApprovers = (record, employeeId) => {
    return record.secondaryApproverEmployees
      ?.map(item => {
        if (item.hasApproved === null) {
          return item.employeeId;
        }
      })
      .includes(employeeId);
  };

  const userIsReviewer =
    isAdminUserRole ||
    checkSecondaryApprovers(modalProps, userId) ||
    userId === modalProps?.primaryApproverEmployee?.employeeId ||
    userId === modalProps?.secondaryApproverEmployee?.employeeId;

  // Modal Props Here 🚀

  const onCancelModalProps: ModalProps = {
    title: <FormattedMessage id="CancelRequestModalTitle" />,
    message: <FormattedMessage id="CancelRequestModalMessage" />,
    requestType: 'cancel',
    messageSuccessfully: getSuccessMessages.success_canceled_request
  };

  const onSubmitModalProps: ModalProps = {
    title: <FormattedMessage id="SubmitRequestModalTitle" />,
    message: (
      <FormattedMessage
        id="SubmitRequestModalMessage"
        values={{
          requestType: publicName,
          days: daysTaken === 1 ? 'day' : 'days',
          startDate: formattedStartDate,
          endDate: endDate
        }}
      />
    ),
    requestType: 'submit',
    messageSuccessfully: getSuccessMessages.success_submited_request,
    okText: <FormattedMessage id="yes" />,
    cancelText: <FormattedMessage id="no" />,
    setStateForOpenModal: setIsStateModalOpen
  };

  const onApproveModalProps: ModalProps = {
    title: <FormattedMessage id="ApproveRequestModalTitle" />,
    message: (
      <FormattedMessage
        id="ApproveRequestModalMessage"
        values={{
          requestType: publicName,
          days: `${daysTaken === 1 ? 'day' : 'days'}, ${
            daysTaken === 1
              ? `for ${formattedStartDate}`
              : `from ${formattedStartDate} until ${formattedEndDate}`
          }.`
        }}
      />
    ),
    requestType: 'approve',
    messageSuccessfully: getSuccessMessages.success_approved_request,
    okText: <FormattedMessage id="yes" />,
    cancelText: <FormattedMessage id="no" />
  };

  const onConfirmModalProps: ModalProps = {
    title: <FormattedMessage id="ConfirmRequestModalTitle" />,
    message: <FormattedMessage id="MarkAsConfirmedModalMessage" />,
    requestType: 'markAsConfirmed',
    messageSuccessfully: getSuccessMessages.success_confirmed_mark_request
  };

  const onConfirmNotSignedModalProps: ModalProps = {
    title: <FormattedMessage id="Warning" />,
    message: <FormattedMessage id="MarkAsConfirmedNotSignedModalMessage" />,
    requestType: 'markAsConfirmed',
    messageSuccessfully: getSuccessMessages.success_confirmed_mark_request
  };

  const onApproveAndConfirmNotSignedModalProps: ModalProps = {
    title: <FormattedMessage id="Warning" />,
    message: <FormattedMessage id="ApproveAndConfirmedNotSignedModalMessage" />,
    requestType: 'markAsConfirmed',
    messageSuccessfully:
      getSuccessMessages.success_approved_and_confirmed_request
  };

  const onApproveAndConfirmModalProps: ModalProps = {
    title: <FormattedMessage id="Warning" />,
    message: <FormattedMessage id="ApproveAndConfirmMessage" />,
    requestType: 'markAsConfirmed',
    messageSuccessfully:
      getSuccessMessages.success_approved_and_confirmed_request
  };

  const onRevokeModalProps: ModalProps = {
    title: <FormattedMessage id="RevokeRequestModalTitle" />,
    message:
      requestedByEmployeeEmail === requestedForEmployee?.email ? (
        <FormattedMessage
          id="RevokeRequestModalMessage"
          values={{ requestType: <FormattedMessage id={publicName} /> }}
        />
      ) : (
        <FormattedMessage
          id="RevokeModalMessage"
          values={{ requestType: <FormattedMessage id={publicName} /> }}
        />
      ),
    requestType: 'revoke',
    messageSuccessfully: getSuccessMessages.success_revoked_request,
    confirmRequestType: 'revoke',
    hasToggleModal: false,
    setStateForOpenModal: setIsStateModalOpen
  };

  const onDenyRevokeModalProps: ModalProps = {
    title: <FormattedMessage id="DenyRequestModalTitle" />,
    message: <FormattedMessage id="DenyRequestModalMessage" />,
    requestType: 'denyRevoke',
    messageSuccessfully: getSuccessMessages.success_revocation_request_denied,
    okText: <FormattedMessage id="yes" />,
    cancelText: <FormattedMessage id="no" />,
    confirmRequestType: 'denyRevoke',
    hasToggleModal: false,
    setStateForOpenModal: setIsStateModalOpen
  };

  const onApproveRevokeModalProps: ModalProps = {
    title: <FormattedMessage id="ApproveRequestModalTitle" />,
    message: <FormattedMessage id="ApproveRevocationRequestModalMessage" />,
    requestType: 'approveRevoke',
    messageSuccessfully:
      getSuccessMessages.success_revocation_request_confirmed,
    okText: <FormattedMessage id="yes" />,
    cancelText: <FormattedMessage id="no" />,
    confirmRequestType: 'approveRevoke'
  };

  // I'm waiting for buttons translations , i will remove this comment after
  const onDenyModalProps: ModalProps = {
    title: <FormattedMessage id="DenyRequestModalTitle" />,
    message: <FormattedMessage id="DenyRequestModalMessage" />,
    requestType: 'deny',
    okText: <FormattedMessage id="submit" />,
    cancelText: <FormattedMessage id="cancel" />,
    confirmRequestType: 'deny',
    hasToggleModal: false,
    setStateForOpenModal: setIsStateModalOpen
  };

  // Modal Buttons Here 🚀

  const CancelButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setConfirmModalProps(onCancelModalProps);
          setIsConfirmModalOpen(true);
          setOnOkModalPromise(
            () => () =>
              TimeOffService.cancel({
                requestId: requestId
              }).then(() => {
                dispatch(setMethodUpdate(true));
                toggleRequestModal();
              })
          );
        }}
      >
        <span>
          <TimeOffCancelIcon stroke={colorscheme[theme]} />
          {status === 'Draft' ? (
            <FormattedMessage id="delete" />
          ) : (
            <FormattedMessage id="cancel" />
          )}
        </span>
      </CustomButton>
    );
  };

  const SubmitButton: React.FC<any> = props => {
    return (
      <CustomButton
        type="primary"
        className={`text-bold-normal btn-primary-custom ${theme}`}
        disabled={
          (policiesWithUpload(countryCode).includes(modalProps.requestType) &&
            modalProps.attachmentUrls === '') ||
          modalProps.additionalFields?.some(
            (field: any) => field?.value === null
          )
        }
        onClick={() => {
          setConfirmModalProps(onSubmitModalProps);
          setIsStateModalOpen(true);
          setOnOkModalPromise(() =>
            TimeOffService.submit(formData).then(() => {
              dispatch(setMethodUpdate(true));
              toggleRequestModal();
              notifyWithSuccessfulDataUpdated(
                onSubmitModalProps.messageSuccessfully
              );
            })
          );
        }}
      >
        <span>
          <TimeOffSubmitIcon stroke="#FFFFFF" />
          <FormattedMessage id="submit" />
        </span>
      </CustomButton>
    );
  };

  const ApproveButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setConfirmModalProps(onApproveModalProps);
          setIsConfirmModalOpen(true);
          setOnOkModalPromise(() =>
            TimeOffService.approveTimeOff({
              requestId: requestId
            }).then(() => {
              dispatch(setMethodUpdate(true));
              toggleRequestModal();
              notifyWithSuccessfulDataUpdated(
                onApproveModalProps.messageSuccessfully
              );
            })
          );
        }}
      >
        <span>
          <TimeOffApproveIcon stroke={colorscheme[theme]} />
          <FormattedMessage id="approve" />
        </span>
      </CustomButton>
    );
  };

  const ConfirmButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setConfirmModalProps(
            isRO && !isSigned
              ? onConfirmNotSignedModalProps
              : onConfirmModalProps
          );
          setIsConfirmModalOpen(true);
          setOnOkModalPromise(
            () => () =>
              TimeOffService.confirm({
                requestId: requestId
              }).then(() => {
                dispatch(setMethodUpdate(true));
                toggleRequestModal();
              })
          );
        }}
      >
        <span>
          <TimeOffMarkAsConfirmedIcon stroke={colorscheme[theme]} />
          <p
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '20vw'
            }}
          >
            <FormattedMessage id="markAsConfirmed" />
          </p>
        </span>
      </CustomButton>
    );
  };

  const ApproveAndConfirmButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setConfirmModalProps(
            isRO && !isSigned
              ? onApproveAndConfirmNotSignedModalProps
              : onApproveAndConfirmModalProps
          );
          setIsConfirmModalOpen(true);
          setOnOkModalPromise(
            () => () =>
              TimeOffService.confirm({
                requestId: requestId
              }).then(() => {
                dispatch(setMethodUpdate(true));
                toggleRequestModal();
              })
          );
        }}
      >
        <span>
          <TimeOffApproveIcon stroke={colorscheme[theme]} />
          <FormattedMessage id="approveNconfirm" />
        </span>
      </CustomButton>
    );
  };

  const RevokeButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setModalState(modalProps ? onRevokeModalProps : null);
          setIsStateModalOpen(true);
          setIsModalOpen(false);
        }}
      >
        <span>
          <TimeOffRevokeIcon fill={colorscheme[theme]} />
          <FormattedMessage id="revoke" />
        </span>
      </CustomButton>
    );
  };

  const DenyRevokeButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setModalState(onDenyRevokeModalProps);
          setIsStateModalOpen(true);
          setIsModalOpen(false);
        }}
      >
        <span>
          <TimeOffDenyRevocationIcon stroke={colorscheme[theme]} />
          <FormattedMessage id="denyRevokation" />
        </span>
      </CustomButton>
    );
  };

  const ApproveRevokeButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setConfirmModalProps(onApproveRevokeModalProps);
          setIsConfirmModalOpen(true);
          setOnOkModalPromise(() =>
            TimeOffService.approveRevoke({
              requestId: requestId
            }).then(() => {
              dispatch(setMethodUpdate(true));
              toggleRequestModal();
              notifyWithSuccessfulDataUpdated(
                onApproveRevokeModalProps.messageSuccessfully
              );
            })
          );
        }}
      >
        <span>
          <TimeOffApproveRevocationIcon stroke={colorscheme[theme]} />
          <FormattedMessage id="confirmRevokation" />
        </span>
      </CustomButton>
    );
  };

  const DenyButton: React.FC = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => {
          setModalState(onDenyModalProps);
          setIsStateModalOpen(true);
        }}
      >
        <span>
          <TimeOffDenyIcon stroke={colorscheme[theme]} />
          <FormattedMessage id="deny" />
        </span>
      </CustomButton>
    );
  };

  const EditButton: React.FC = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => setIsEdit(true)}
      >
        <span>
          <TimeOffEditIcon stroke={colorscheme[theme]} />
          <FormattedMessage id="edit" />
        </span>
      </CustomButton>
    );
  };

  const SignButton: React.FC = props => {
    const dispatch = useDispatch();
    const [requestSubmitted, setRequestSubmitted] =
      React.useState<boolean>(false);

    return (
      <CustomButton
        type="primary"
        disabled={requestSubmitted}
        className={`text-bold-normal btn-primary-custom ${theme}`}
        onClick={() => {
          setRequestSubmitted(true);
          TimeOffService.sign({
            requestId: modalProps.requestId
          }).then(res => {
            dispatch(setMethodUpdate(true));
            notifyWithSuccessfulDataUpdated(
              '',
              getSuccessMessages.success_sign_request
            );
            toggleRequestModal();
            setRequestSubmitted(!!res);
          });
        }}
      >
        <span>
          <TimeOffEditIcon stroke="#FFFFFF" />
          <FormattedMessage id="sign" />
        </span>
      </CustomButton>
    );
  };

  const CloseButton: React.FC<any> = props => {
    return (
      <CustomButton
        className={`text-bold-normal btn-default-custom ${theme}`}
        onClick={() => toggleRequestModal()}
      >
        <FormattedMessage id="close" />
      </CustomButton>
    );
  };

  return (
    <>
      {modalState && (
        <ModalComponent
          formKeyId="stateActionForm"
          isOpen={isStateModalOpen}
          setIsOpen={setIsStateModalOpen}
          children={
            <>
              {modalState.message}
              <StateActionsForm
                modalProps={modalState}
                requestId={requestId}
                key="stateActionForm"
              />
            </>
          }
          isMaskClosable={false}
          title={modalState.title}
          cancelBtnText={modalState.cancelText}
          submitBtnText={modalState.okText}
          displayFooterSubmitButton={true}
        />
      )}

      <section className="request-modal-buttons">
        {/* TL + Admin + Next Approver Buttons */}

        {userIsReviewer && (
          <>
            {status === 'Pending' && (
              <>
                {ApproveAndConfirmButtonPolicies.includes(requestType) &&
                  isAdminUserRole && (
                    <div className="request-modal-btn-row view-request-single-btn-row">
                      <ApproveAndConfirmButton />
                    </div>
                  )}
                <div className="request-modal-btn-row">
                  <ApproveButton />
                  <DenyButton />
                </div>
              </>
            )}

            {status === 'RevokePending' && (
              <div className="request-modal-btn-row">
                <ApproveRevokeButton />
                <DenyRevokeButton />
              </div>
            )}

            {status === 'ApprovedSecondary' && isAdminUserRole && (
              <>
                <div className="request-modal-btn-row view-request-single-btn-row">
                  <ApproveAndConfirmButton />
                </div>
                <div className="request-modal-btn-row">
                  <ApproveButton />
                  <DenyButton />
                </div>
              </>
            )}

            {status === 'Approved' && isAdminUserRole && (
              <div className="request-modal-btn-row">
                <ConfirmButton className="ellipses_text" />
                <RevokeButton />
              </div>
            )}

            {/* in the "confirmed" status, tl and employee should not have the remove button */}
            {status === 'Confirmed' && isAdminUserRole && (
              <div className="request-modal-btn-row view-request-single-btn-row ">
                <RevokeButton />
              </div>
            )}
          </>
        )}

        {/* User Buttons */}

        {userHasAccess && (
          <>
            {!modalProps.isSigned &&
              !userIsReviewer &&
              isRO &&
              !['Draft', 'Unfinished', 'Confirmed'].includes(status) && (
                <div className="request-modal-btn-row">
                  <SignButton />
                </div>
              )}

            {['Draft', 'Unfinished', 'Pending'].includes(status) && (
              <>
                {status === 'Unfinished' && (
                  <div className="request-modal-btn-row">
                    <SubmitButton />
                    <EditButton />
                  </div>
                )}
                <div className="request-modal-btn-row">
                  {status === 'Draft' && <SubmitButton />}
                  <CancelButton />
                </div>
              </>
            )}

            {status === 'Approved' && !isAdminUserRole && (
              <div className="request-modal-btn-row view-request-single-btn-row ">
                <RevokeButton />
              </div>
            )}
          </>
        )}

        {/* Close Button : Always Display */}

        <div className="request-modal-btn-row view-request-single-btn-row">
          <CloseButton />
        </div>
      </section>
    </>
  );
};

export default ViewRequestButtons;
