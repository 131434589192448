import { commonRequest } from 'services/generic';
import { GetReport, IAvailableReports, IReport, ReportPreset } from './types';

type GetVacationPayload = {
  reportType: string;
  from: Date | string;
  to: Date | string;
};

export default class ReportProvider {
  // Get getAvailableReports  //
  async getAvailableReports(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IAvailableReports[]>('GET', payloadData, '/Report/getAvailableReports');
  }
  // Get specific raport  //
  async getReport(payload: GetReport) {
    const payloadData = { payload };
    return await commonRequest<IReport>('POST', payloadData, '/Report/getReport');
  }

 async addPreset(payload: ReportPreset){
    const payloadData = {payload};
    return await commonRequest('POST', payloadData, '/Report/addPreset')
 }

 async getPresets(){
    return await commonRequest<ReportPreset>('GET', null, '/Report/getReportsPresets')
 }

 async updatePreset(payload: ReportPreset){
    const payloadData = {payload};
    return await commonRequest("POST", payloadData, `/Report/updatePreset`)
 }

 async deletePreset(payload: {id: number}){
    return await commonRequest('POST', null, `/Report/deletePreset?id=${payload.id}`)
 }

  async getVacations(payload: GetVacationPayload) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, '/Report/getReport');
  }

}
