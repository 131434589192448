import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  rolesAllow,
  userRoles
} from 'utils/userManagement';
import {
  calendarMenuItem,
  colleaguesMenuItem,
  dashboardMenuItem,
  inventoryMenuItem,
  personalTimeOffRequestsMenuItem,
  reportsItem,
  settingsMenuItem,
  teamsMenuItem,
  ticketingMenuItem,
  timeManagementItem,
  viewProfileMenuItem
} from '../Menu/MenuItems';
import { CustomMenuItemType } from './CustomMenuItemType';
import DesktopMenu from './Desktop';
import MobileMenu from './Mobile';
import { DropdownProvider } from './components/Dropdown/DropdownContext';

const MenuPart = () => {
  const { theme } = useThemeContext();
  const { isMobile } = useMobileContext();

  const location = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState<string>(
    location.pathname
  );

  React.useEffect(() => {
    if (!location) {
      return;
    }

    if (
      currentLocation !== location.pathname ||
      currentLocation === '/inventory/software' ||
      currentLocation === '/inventory/hardware'
    ) {
      const path =
        location.pathname === '/' ||
        location.pathname === '' ||
        location.pathname === '/redirect'
          ? '/dashboard'
          : location.pathname;

      setCurrentLocation(path);
    }
  }, [location, currentLocation]);

  const generalRootState = useSelector((state: RootState) => state);
  const personalEmployeeId: number =
    generalRootState.user.loggedUser &&
    Object.keys(generalRootState.user.loggedUser).length
      ? generalRootState.user.loggedUser.employeeId
      : 1;

  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const userHasTlRole = userRole === userRoles.TL;
  const userHasEmployeeRole = userRole === userRoles.Employee;
  const userHasInternRole = userRole === userRoles.Intern;

  const isInternalEmployeeAndNotExternal: boolean =
    allowPermision(userRole, rolesAllow) ||
    userHasTlRole ||
    userHasInternRole ||
    userHasEmployeeRole;

  const hasTeamOrHasRequiredRoles: boolean =
    allowPermision(userRole, rolesAllow) || Object.keys(user.teams).length != 0;

  const isAdminAndNotTeamLead: boolean =
    !userHasTlRole && allowPermision(userRole, rolesAllow);

  /** Represents the rendered menu items*/
  const menuItems = React.useMemo<CustomMenuItemType[]>(
    () => [
      dashboardMenuItem,
      viewProfileMenuItem(personalEmployeeId),
      colleaguesMenuItem,
      teamsMenuItem(location.pathname),
      inventoryMenuItem(location.pathname),

      ...timeManagementItem(isAdminAndNotTeamLead, hasTeamOrHasRequiredRoles),

      ...(isAdminAndNotTeamLead ? [reportsItem(location.pathname)] : []),

      calendarMenuItem,
      ...(allowPermision(userRole, userRoles.Admin)
        ? [...settingsMenuItem(allowPermision(userRole, userRoles.Admin))]
        : []),

      ticketingMenuItem(location.pathname)
    ],
    [
      isAdminAndNotTeamLead,
      isInternalEmployeeAndNotExternal,
      personalEmployeeId,
      location.pathname
    ]
  );

  return (
    <DropdownProvider>
      {isMobile ? (
        <MobileMenu
          theme={theme}
          items={menuItems}
          currentLocation={currentLocation}
        />
      ) : (
        <DesktopMenu
          theme={theme}
          items={menuItems}
          currentLocation={currentLocation}
        />
      )}
    </DropdownProvider>
  );
};

export default MenuPart;
