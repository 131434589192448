import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import {
  GridViewPagePlaceholder,
  ReportsGridViewPlaceholder
} from 'components/Placeholders';
import { ReportsFiltersProvider } from 'components/ReportsDetails/ReportsFilterContext';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { ReportService } from 'services';
import {
  IAvailableReports,
  ReportPreset
} from 'services/providers/ReportProvider/types';
import ReportItem from '../../components/ReportsView/ReportItem';
import { useState } from 'react';
import GridLayout from '../UILayout/GridLayout';
import EmployeeGridStyles from '../Employee/EmployeeGrid/EmployeeGrid.module.less';
import ReportsGridStyles from '../ReportsView/ReportsGrid.module.less';
import GridView from '../UIViews/GridView';

const ReportsView = () => {
  const { isMobile } = useMobileContext();
  // Get available reports from api
  let { data: availableReports, status } = useApiRequestHook<
    IAvailableReports[]
  >(ReportService.getAvailableReports, null, [], {});

  let { data: presets } = useApiRequestHook<ReportPreset[]>(
    ReportService.getPresets,
    null,
    [],
    {}
  );

  // Create an empty array
  while (availableReports.length !== 12) {
    availableReports.push({
      reportName: null,
      allColumnsAvailable: null
    });
  }

  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();
  return (
    <GridView
      style={{
        height: `calc(${deviceHeight}px - 122px)`
      }}
      body={
        <ComponentWithStatus status={status} Placeholder={<GridViewPagePlaceholder />}>
          <ReportsFiltersProvider>
            <GridLayout
              style={{
                height: `calc(${deviceHeight}px - 188px)`
              }}
              className={ReportsGridStyles['layout']}
              GridItem={(element: any) => (
                <ReportItem
                  className={`${ReportsGridStyles['card']}`}
                  params={{
                    state: element.reportName,
                    title: element.reportName,
                    presets: presets
                  }}
                />
              )}
              gridItems={availableReports}
            />
          </ReportsFiltersProvider>
        </ComponentWithStatus>
      }
    ></GridView>
  );
};

export default ReportsView;
