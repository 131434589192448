export const TrashIconSVG = ({ onClick = null }) => {
  return (
    <span
      role="img"
      aria-label="detailedView"
      className="anticon anticon-detailedView"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M1.70581 3.11765H12.9999M5.23522 1H9.47052M5.9411 10.1765V5.94118M8.76463 10.1765V5.94118M9.82346 13H4.88228C4.10258 13 3.47052 12.3679 3.47052 11.5882L3.14821 3.85292C3.1315 3.45189 3.4521 3.11765 3.85348 3.11765H10.8523C11.2536 3.11765 11.5742 3.45189 11.5575 3.85292L11.2352 11.5882C11.2352 12.3679 10.6032 13 9.82346 13Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
