import { Typography } from 'antd';
import { TicketStatus } from 'pages/Ticketing/types';
import { FormattedMessage } from 'react-intl';

export const getSelectOptions = (currentStatus: TicketStatus) => {
  if (currentStatus === TicketStatus.New) {
    return [
      {
        value: TicketStatus.New,
        label: (
          <Typography>
            <FormattedMessage id="New" />
          </Typography>
        )
      },
      {
        value: TicketStatus.Open,
        label: (
          <Typography>
            <FormattedMessage id="Open" />
          </Typography>
        )
      }
    ];
  }

  if (
    currentStatus === TicketStatus.Open ||
    currentStatus === TicketStatus.Resolved ||
    currentStatus === TicketStatus.Closed
  ) {
    return [
      {
        value: TicketStatus.Open,
        label: (
          <Typography>
            <FormattedMessage id="Open" />
          </Typography>
        )
      },
      {
        value: TicketStatus.Resolved,
        label: (
          <Typography>
            <FormattedMessage id="Resolved" />
          </Typography>
        )
      }
    ];
  }
};
