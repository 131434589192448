import { useEffect, useLayoutEffect, useRef } from 'react';

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
/**Custom hook which wraps the useEffect functionality, but providing the option to skip the first mount. */

export const useEffectWithSkipFirstMount = (
  callback: () => any,
  dependencies: Array<any>,
  skipFirstMount = true
) => {
  const firstRender = useIsMount();
  const effectCb = () => {
    if (
      !firstRender ||
      dependencies?.length === 0 ||
      skipFirstMount === false
    ) {
      return callback();
    }
  };

  const hook = useEffect(effectCb, dependencies);
  return hook;
};