import { Card, Typography, Row, Col } from 'antd';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { FC } from 'react';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import { useCompanySettings } from 'settings';
import { getCurrentEmployeePosition } from '../EmployeeForm/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import './EmployeeProfileCard.less';
import TextPhoneInput from 'components/UIComponents/InputPhoneComponent/TextPhoneInput';

type EmployeeInfoFieldsProps = { data: IEmployee };
const { Text } = Typography;
const { Meta } = Card;

const EmployeeInfoFields: FC<EmployeeInfoFieldsProps> = ({ data }) => {
  const intl = useIntl();
  const { office } = useCompanySettings();

  const jobFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_position" />
  );
  const teamsFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Teams" />
  );
  const officeFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Office" />
  );
  const emailFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Email" />
  );
  const phoneFieldTitle = (
    <FormattedMessage id="label_profileCardemployee_Phone" />
  );
  const phoneNotProvidedText = intl.formatMessage({
    id: 'label_profileCardemployee_Not_phone_number_provided'
  });

  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();

  const teamsNamesArray =
    data.teams && Object.keys(data.teams).length > 0
      ? Object.entries(data.teams).map(([teamId, teamName]) => teamName ?? '')
      : [];

  const notYetInTeamText = (
    <FormattedMessage id="label_profileCardemployee_Not_yet_in_team" />
  );

  const employeeInfoMetas = [
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-normal` : ''}`}>
          {jobFieldTitle}
        </Text>
      ),
      infoText: (
        <Text className={`${!isMobile ? `text-medium-normal` : ''}`}>
          {getCurrentEmployeePosition(data.position)}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-normal` : ''}`}>
          {teamsFieldTitle}
        </Text>
      ),
      infoText: !teamsNamesArray.length ? (
        <Text className="text-medium-normal">{notYetInTeamText}</Text>
      ) : teamsNamesArray.length === 1 ? (
        <Text className="text-medium-normal">{teamsNamesArray.join('')}</Text>
      ) : (
        <Text className={`${!isMobile ? `text-medium-normal` : ''}`}>
          {teamsNamesArray.join(', ')}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-normal` : ''}`}>
          {officeFieldTitle}
        </Text>
      ),
      infoText: (
        <Text className={`${!isMobile ? `text-medium-normal` : ''}`}>
          {office}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-normal` : ''}`}>
          {emailFieldTitle}
        </Text>
      ),
      infoText: (
        <Text className={`${!isMobile ? `text-medium-normal` : ''}`}>
          {data.email}
        </Text>
      )
    },
    {
      title: (
        <Text className={`${!isMobile ? `text-bold-normal` : ''}`}>
          {phoneFieldTitle}
        </Text>
      ),
      infoText:
        data.phoneNumber.length !== 0 ? (
          <Text className={`${!isMobile ? `text-medium-normal` : ''}`}>
            <TextPhoneInput crudPhone={data.phoneNumber} />
          </Text>
        ) : (
          <Text className={`${!isMobile ? `text-medium-normal` : ''}`}>
            {phoneNotProvidedText}
          </Text>
        )
    }
  ];
  const lastFirstName = [
    {
      infoText: (
        <Typography.Paragraph
          ellipsis={{ rows: 1, expandable: false }}
          className="text-bold-extra color-inherit name-lastname user_name"
        >
          {data.lastName + ' ' + data.firstName}
        </Typography.Paragraph>
      )
    }
  ];

  return (
    <Col className="full-content">
      <Row justify="end" className="wrapper-employee-name">
        <Col span={24}>
          {lastFirstName.map(({ infoText }, index) => (
            <div key={`employee-name-${index}`}>{infoText}</div>
          ))}
        </Col>
      </Row>
      <br />
      {employeeInfoMetas.map(({ title, infoText }, index) => (
        <Meta
          key={`employee-info-card-meta-${index}`}
          title={title}
          className={`employee-info-row profile-card-info-row info-employee-color ${theme}`}
          description={infoText ? <>{infoText}</> : <p>NaN</p>}
        />
      ))}
    </Col>
  );
};

export default EmployeeInfoFields;
