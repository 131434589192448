import * as React from 'react';
export const EditIconSimpleSvg = () => {
  return (
    <span role="img" aria-label="addUser" className="anticon anticon-addUser">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.89449 1.2071C10.2848 0.816544 10.9178 0.816278 11.3085 1.2065L12.7923 2.68862C13.1831 3.079 13.1834 3.71232 12.7929 4.10304L5.34546 11.5545C5.20617 11.6939 5.02883 11.789 4.83568 11.8279L1.5 12.5L2.17333 9.16827C2.21229 8.97552 2.30721 8.79854 2.44622 8.65945L9.89449 1.2071Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
