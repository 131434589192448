import { Dispatch, SetStateAction } from 'react';

export type TimeOffRequestFormProps = {
  context?: string;
  requestType: string;
  scope: string;
  timeOffId?: string | number | null;
  requestTypeOff?: string | null;
  employeeId?: number | string;
  // title: ReactNode
  setModalProps?: Dispatch<SetStateAction<any>>;
  setIsEdit?: Dispatch<SetStateAction<any>>;
  setViewRequestModal?: Dispatch<SetStateAction<any>>;
};

export type TimeOffStatisticsResultData = {
  enableClockIn: boolean;
  statistics: any[];
};

export type AdditionalFieldSchema = {
  name: string;
  displayName: string;
  fieldType: string;
  isRequired: boolean;
  maxLength: number | null;
  order?: number | null;
};

export enum DaysAllowedType {
  None = 0,
  CalendarDays = 1,
  WorkingDays = 2,
  CalendarDaysNoWeekends = 3,
  CalendarDaysNoHolidays = 4,
}
