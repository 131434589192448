import { Button, Col, Typography } from 'antd';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { ArrowRight } from 'Icons/TimeManagementWidget/arrowRight';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import TableComponent from 'components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { RootState } from 'redux/store';
import { EventsStatusDefinitions, legentColors } from '../types';
import { columnsVacation } from './columnsVacation';
import { useEffect, useRef, useState } from 'react';
import ComponentWithStatus from 'components/ComponentWithStatus';
import {
  TableComponentPlaceholder,
  VacantionsComponentPlaceholder
} from 'components/Placeholders';
import { TableCustom } from 'components/UIComponents/TableCustom';

export const VacationTable = ({
  setDateOfMonth,
  dateOfMonth,
  months,
  data,
  status
}) => {
  const { Title } = Typography;
  const legendRef = useRef(null);
  const [isHeightGreater, setIsHeightGreater] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (legendRef.current) {
        const height = legendRef.current.offsetHeight;
        setIsHeightGreater(height > 15);
      }
    };

    checkHeight(); // Check on initial render

    // Create a ResizeObserver to observe changes to the element's size
    const observer = new ResizeObserver(() => {
      checkHeight(); // Check on size change
    });

    if (legendRef.current) {
      observer.observe(legendRef.current);
    }

    // Clean up the observer on unmount
    return () => {
      if (legendRef.current) {
        observer.unobserve(legendRef.current);
      }
    };
  }, []);


  const { isMobile, deviceHeight } = useMobileContext();
  const scrollY = `${deviceHeight - 360}px`;

  const { theme } = useThemeContext();

  const dispatch = useDispatch();
  const triggerMethodUpdate = () => {
    dispatch(setMethodUpdate(true));
  };

  const updateMonth = (offset: number) => {
    const newDate = dayjs(dateOfMonth.date).add(offset, 'month');
    const month = newDate.format('MMMM');
    const year = newDate.year();

    setDateOfMonth({
      date: newDate,
      month: month,
      year: year
    });

    triggerMethodUpdate();
  };

  const month = months[dayjs(dateOfMonth.date).get('month')];

  const generalRootState = useSelector((state: RootState) => state);
  let dataSource = [];

  if (
    generalRootState?.colleaguesMonthEvents?.colleaguesMonthEvents?.employees
  ) {
    dataSource =
      generalRootState?.colleaguesMonthEvents?.colleaguesMonthEvents?.employees;
  }
  let eventDay = [];

  if (
    generalRootState?.colleaguesMonthEvents?.colleaguesMonthEvents?.eventsByDay
  ) {
    eventDay =
      generalRootState?.colleaguesMonthEvents?.colleaguesMonthEvents
        ?.eventsByDay;
  }

  const params = {
    eventsByDay: eventDay,
    theme: theme
  };

  type EditableTableProps = Parameters<typeof TableComponent>[0];
  type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
  return (
    <TableLayoutPage
      title={<FormattedMessage id="VacationsMenuItemTrans" />}
      style={{
        height: `calc(${deviceHeight}px - 185px)`,
        position: 'relative'
      }}
      displayToggle
      className="vacation_table_layout_page"
      childrenButton={
        <>
          <Col>
            <div className="flex-center-container">
              <Button
                type="text"
                icon={<ArrowLeft />}
                onClick={() => {
                  updateMonth(-1);
                }}
                className="arrows-calendar"
              />
              <Title
                level={3}
                className={`align-padding-title ${theme} month-title `}
              >
                {month}, {dateOfMonth.year}
              </Title>
              <Button
                type="text"
                icon={<ArrowRight />}
                onClick={() => {
                  updateMonth(1);
                }}
                className="arrows-calendar"
              />
            </div>
          </Col>
        </>
      }
    >
      <ComponentWithStatus
        status={status}
        Placeholder={<VacantionsComponentPlaceholder />}
      >
        <TableCustom
          columns={columnsVacation(params)}
          data={data}
          className={`table_vacation`}
          columnRowClassName="header_col_vacation"
          style={{
            maxHeight: isMobile
              ? `calc(${deviceHeight}px - 28.9em)`
              : isHeightGreater ? `calc(${deviceHeight}px - 25.5em)` : `calc(${deviceHeight}px - 23.9em)`,
            marginRight: !isMobile && '-15px'
          }}
        />
      </ComponentWithStatus>

      <div className={`container_legend ${theme}`} style={{ opacity: status !== 'success' ? 0 : 1 }}>
        <div
          className="legend"
          ref={legendRef}
          style={{ bottom: '5vh', maxWidth: '80%', }}
        >
          {legentColors
            .filter(item => item.request !== 'BadgeForgot')
            .map((item, key) => (
              <div className="items_legend" key={key}>
                <div
                  className="symbol"
                  style={{ background: item?.color }}
                ></div>
                <div className={`description ${theme}`}>
                  <FormattedMessage id={item?.request} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </TableLayoutPage>
  );
};
