import { Link } from 'react-router-dom';
import { IEmployee, IRole } from 'services/providers/EmployeeProvider/types';
import {
  adminRoles,
  allowPermision,
  rolesAllow,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import _sort from 'utils/sorter';
import { getColumnSearchProps } from 'components/SearchTable';
import type { ColumnsType } from 'antd/es/table';
import TableComponent from 'components/Table';
import ModalComponent from 'components/Modal';
import EditProfilePage from 'pages/Profile/EditProfilePage';
import { useState } from 'react';
import { useModalContext } from 'components/Modal/ModalProvider';
import {
  getTitleColumns,
  getTitleSelects
} from 'components/TitlesColumns/utils';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { columnsMobileColleagues } from './columnsMobileColleagues';
import { Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { getFilterCustomForStatus, getFilters } from 'pages/Inventory/utils';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { titlesKeysofColumnsColleagues } from './utils';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFiltersPosition } from '../utils';
import { useColleaguesContext } from '../ColleaguesModalContext';
import { useAuth } from 'services/providers/AuthProvider';
import TextPhoneInput from 'components/UIComponents/InputPhoneComponent/TextPhoneInput';
import { TableComponentPlaceholder } from 'components/Placeholders';
import ThemedIcon from 'components/UIComponents/Icon';
import { CopyRightIcon } from 'Icons/Colleagues/CopyRightIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';

const { Text } = Typography;
const getTeamsFilters = data => {
  const result = [];
  if (data.length) {
    data
      .map((employee: IEmployee) => employee.teams)
      .map(employeeTeams => {
        if (Object.keys(employeeTeams).length > 0) {
          Object.entries(employeeTeams).map(([teamId, teamName]) => {
            if (!result.find(teamItem => teamItem.key === teamId)) {
              result.push({
                text: teamName,
                value: teamName,
                key: teamId
              });
            }
          });
        }
      });
  }
  result.push({
    text: 'blank',
    value: 'blank'
  });
  return result;
};

//prevents from throwing exceptions due to unexisting dynamically generated translations
const SafeFormattedMessage = props => {
  const intl = useIntl();
  if (intl.messages[props?.id]) {
    return <FormattedMessage {...props} />;
  }
  return props.id ?? props.defaultMessage ?? null;
};
const columns = (
  data,
  role,
  params,
  isModalOpen,
  setIsModalOpen,
  stateActions,
  dataSource
): ColumnsType<IEmployee> => {
  const tableColumns = [
    {
      title: <FormattedMessage id="table_colleaguesCol_Full_Name" />,
      dataIndex: 'fullName',
      sorter: (a, b) => _sort(a.lastName, b.lastName, 'string'),
      ...getColumnSearchProps('fullName', params),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false
      },
      render: (_, record) =>
        allowPermision(role, rolesAllow) ? (
          <Link
            to={`viewProfile?employeeId=${record.employeeId}`}
            style={{ display: 'flex', whiteSpace: 'nowrap' }}
          >
            <Tooltip placement="top" title={`${record.fullName}`}>
              <Text underline className="text-medium-big ellipsis-cell-text">
                {record.fullName}
              </Text>
            </Tooltip>
          </Link>
        ) : (
          <Tooltip
            placement="top"
            className="ellipsis-cell-text"
            title={`${record.fullName}`}
          >
            {record.fullName}
          </Tooltip>
        ),
      width: 'auto',
      className: 'flex-1'
    },
    {
      title: getTitleColumns('position', 'colleagues'),
      dataIndex: 'position',
      filters: getFiltersPosition(data, 'position'),
      onFilter: (value: string, record) =>
        record.position?.indexOf(value) === 0,
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={<SafeFormattedMessage id={record.position} />}
        >
          <div className="line-clamp-1">
            <SafeFormattedMessage id={record.position} />
          </div>
        </Tooltip>
      ),
      width: '10%'
    },
    {
      title: getTitleColumns('teams', 'colleagues'),
      dataIndex: 'teams',
      filters: getTeamsFilters(data),
      onFilter: (value: string, record) => {
        if (value === 'blank') {
          return Object.keys(record.teams).length === 0;
        }
        return (record && Object.values(record.teams).includes(value)) || false;
      },
      className: `${allowPermision(role, rolesAllow) ? 'hoverEdit' : ''}`,
      render: (_, record) => {
        return (
          <div className="line-clamp-1">
            <Tooltip
              placement="top"
              title={Object.values(record.teams).join(', ')}
            >
              {record.teams && Object.keys(record.teams).length ? (
                Object.entries(record.teams).map(
                  ([teamId, teamName], index) => {
                    return allowPermision(role, rolesAllow) ? (
                      <Link
                        key={index}
                        to={`../teams/viewTeam?teamId=${teamId}`}
                        replace={false}
                        onClick={() =>
                          localStorage.setItem('team-id', `${teamId}`)
                        }
                      >
                        {index > 0 ? ', ' + teamName : teamName.toString()}
                      </Link>
                    ) : (
                      <>{index > 0 ? ', ' + teamName : teamName.toString()}</>
                    );
                  }
                )
              ) : (
                <p>-</p>
              )}
            </Tooltip>
          </div>
        );
      },
      width: 'auto'
    },
    {
      title: getTitleColumns('email', 'colleagues'),
      dataIndex: 'email',
      className: `${'hoverEdit'}`,
      render: (_, record) => (
        <>
          <div className={'hideContent'}>
            <Tooltip placement="top" title={record?.email}>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: 'block'
                }}
              >
                {record.email}
              </span>
            </Tooltip>
          </div>
          <div className={'showEdit'} style={{ display: 'none' }}>
            <div className="actionButtons">
              <ButtonWithExpandedText
                displayedText={
                  <FormattedMessage id="table_colleaguesBtn_copy_email" />
                }
                embedSrc={<ThemedIcon icon={CopyRightIcon} />}
                onClick={() => navigator.clipboard.writeText(record.email)}
              />
            </div>
          </div>
        </>
      ),
      width: 'auto'
    },
    {
      title: getTitleColumns('phoneNumber', 'colleagues'),
      dataIndex: 'phoneNumber',
      render: (_, record) => <TextPhoneInput crudPhone={record.phoneNumber} />,
      width: '17ch'
    },
    {
      title: getTitleColumns('status', 'colleagues'),
      dataIndex: 'status',
      filters: getFilterCustomForStatus(data, 'status', 'statusEmployee'),
      defaultFilteredValue: [
        'None',
        'OnBoarding',
        'Internship',
        'Employee',
        'External',
        'MaternityChildCareLeave'
      ],
      onFilter: (value: string, record) => record.status.indexOf(value) === 0,
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={getTitleSelects(record.status, 'statusEmployee')}
        >
          <p className={'line-clamp-1'}>
            {getTitleSelects(record.status, 'statusEmployee')}
          </p>
        </Tooltip>
      ),
      width: '10%'
    },
    {
      title: getTitleColumns('startDate', 'colleagues'),
      dataIndex: 'startDate',
      render: (_, record) => (
        <div className="line-clamp-1">
          {dayjs(record.startDate).isValid()
            ? dayjs(record.startDate).format('DD-MM-YYYY')
            : '-'}
        </div>
      ),
      width: '15ch'
    },
    {
      title: getTitleColumns('endDate', 'colleagues'),
      dataIndex: 'endDate',
      className: `${allowPermision(role, rolesAllow) ? 'hoverEdit' : ''}`,
      render: (_, record) => (
        <>
          <div
            className={allowPermision(role, rolesAllow) ? 'hideContent' : ''}
          >
            <div className={'line-clamp-1'}>
              {dayjs(record.endDate).isValid()
                ? dayjs(record.endDate).format('DD-MM-YYYY')
                : '-'}
            </div>
          </div>
          <div
            className={allowPermision(role, rolesAllow) ? 'showEdit' : ''}
            style={{ display: 'none' }}
          >
            <div className="actionButtons">
              <ButtonWithExpandedText
                displayedText={<FormattedMessage id="btn_edit" />}
                embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                onClick={() => {
                  stateActions?.setEmployeeId(record.employeeId);
                  stateActions?.setIsAnotherModalOpen(false);
                  setIsModalOpen(true);
                }}
              />
            </div>
          </div>
        </>
      ),
      width: '15ch'
    }
  ];

  const dataSourceKeys = dataSource.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
    titlesKeysofColumnsColleagues.some(item => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  const checkForUserRoleIntern = (a: any) =>
    !(role === IRole[IRole.Intern] && a.dataIndex === 'phoneNumber');

  return tableColumns
    .filter(checkForDataDisplay)
    .filter(checkForUserRoleIntern);
};

export const TableColleagues = (props: any) => {
  const {
    role,
    data,
    params,
    isAnotherModalOpen,
    setIsAnotherModalOpen,
    setDataCurrentFilterSort,
    setTotalRows,
    status
  } = props;
  const { user } = useAuth();
  const { isModalOpen, toggleModal } = useModalContext();
  const [employeeId, setEmployeeId] = useState<number>(0);
  const { isMobile, deviceHeight, deviceOS } = useMobileContext();
  const { setColleagueData, colleaguesModalProps, openColleagueModal } =
    useColleaguesContext();

  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, adminRoles);

  const intl = useIntl();

  const cancelBtnText = intl.formatMessage({ id: 'cancel' });
  const scrollY = `${deviceHeight - 360}px`;

  return (
    <ComponentWithStatus
      status={status}
      Placeholder={<TableComponentPlaceholder count={isMobile ? 2 : 8} />}
    >
      {!isAnotherModalOpen && (
        <ModalComponent
          isMaskClosable={false}
          formKeyId="employeeForm"
          title={<FormattedMessage id="EditColleagueModalTitle" />}
          submitBtnText={<FormattedMessage id="save" />}
          cancelBtnText={cancelBtnText}
          className="mobile-modal"
          children={<EditProfilePage scope="edit" employeeId={employeeId} />}
        />
      )}

      <ModalComponent {...colleaguesModalProps} />

      {isMobile ? (
        <TableComponent
          style={{
            height: `calc(${deviceHeight}px - 22.7em)`,
            minHeight: `calc(${deviceHeight}px - 22.7em)`
          }}
          scroll={{
            x: 'calc(100vw - 220px)',
            y: `calc(${deviceHeight}px - 22.8em)`
          }}
          rowKey="employeeId"
          columns={columnsMobileColleagues}
          data={data}
          role={role}
          params={params}
          dataSource={data}
          isModalOpen={isModalOpen}
          setIsModalOpen={toggleModal}
          hookUseStateActions={{ setEmployeeId, setIsAnotherModalOpen }}
          className="allUsersTable"
          setDataCurrent={setDataCurrentFilterSort}
          setTotalRows={setTotalRows}
          onRow={record => {
            return {
              onClick: !allowPermision(role, [
                userRoles.Admin,
                userRoles.HR,
                userRoles.Accountant
              ])
                ? () => {
                    setColleagueData(record);
                    openColleagueModal();
                  }
                : null
            };
          }}
        />
      ) : (
        <TableComponent
          scroll={{
            x: 'calc(100vw - 213px)',
            y: 'calc(100vh - 22em - 24px)'
          }}
          style={{
            height: 'calc(100vh - 23em)',
            minHeight: 'calc(100vh - 21em)'
          }}
          rowKey="employeeId"
          rowClassName={'hoverRow'}
          columns={columns}
          context='colleaguesRequests'
          data={data}
          role={role}
          params={params}
          dataSource={data}
          isModalOpen={isModalOpen}
          setIsModalOpen={toggleModal}
          hookUseStateActions={{ setEmployeeId, setIsAnotherModalOpen }}
          className="allUsersTable"
          setDataCurrent={setDataCurrentFilterSort}
          setTotalRows={setTotalRows}
        />
      )}
    </ComponentWithStatus>
  );
};
