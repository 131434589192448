import { useMobileContext, useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ArrowLeft } from 'Icons/Documents/arrowLeft';
import { ArrowRight } from 'Icons/Documents/arrowRight';
import { Pagination } from 'antd';
import GridLayoutStyles from 'components/UILayout/GridLayout/GridLayout.module.less';
import React from 'react';

interface GridLayoutProps {
  style?: React.CSSProperties;
  className?: string;
  gridItems: Array<object>;
  GridItem: ({ ...props }: object) => React.ReactElement;
};

const GridLayout = ({ style, className, gridItems, GridItem }: GridLayoutProps) => {

  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();

  const [current, setCurrent] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);

  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedData = gridItems.slice(startIndex, endIndex);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrent(page);
    setPageSize(pageSize);
  };

  React.useEffect(() => {
    if (isMobile) {
      setPageSize(20);
    };
  }, []);

  return (
    <div style={style} className={`${GridLayoutStyles['grid-layout']} ${GridLayoutStyles[theme]} ${className}`}>
      <ul className={GridLayoutStyles['body']}>
        {
          displayedData && displayedData.length ? displayedData.map((item, index) => (
            <React.Fragment key={`GridLayout__${index}`}>
              {
                GridItem(item)
              }
            </React.Fragment>
          )) : null
        }
      </ul>
      <Pagination
        current={current}
        onChange={handlePageChange}
        total={gridItems.length}
        pageSize={pageSize}
        showSizeChanger={true}
        pageSizeOptions={['10', '20', '50', '100']}
        className={GridLayoutStyles['pagination']}
        prevIcon={<ArrowLeft />}
        nextIcon={<ArrowRight />}
      ></Pagination>
    </div>
  );
};

export default GridLayout;
