export const ProfileSvg = () => {
  return (
    <span
      role="img"
      aria-label="profile"
      className="anticon anticon-profile"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.6001 12.8C3.90754 12.4556 5.34746 10.8711 5.76943 10.8711H10.231C10.8425 10.8711 12.0907 12.1846 12.4001 12.6476M14.4001 7.99997C14.4001 11.5346 11.5347 14.4 8.0001 14.4C4.46548 14.4 1.6001 11.5346 1.6001 7.99997C1.6001 4.46535 4.46548 1.59998 8.0001 1.59998C11.5347 1.59998 14.4001 4.46535 14.4001 7.99997ZM10.2927 5.81861C10.2927 4.59761 9.2619 3.59998 8.00029 3.59998C6.73871 3.59998 5.70792 4.59761 5.70792 5.81861C5.70792 7.03962 6.73871 8.03725 8.00029 8.03725C9.26187 8.03725 10.2927 7.03962 10.2927 5.81861Z"
          stroke="currentColor"
          strokeWidth="1.2"
        />
      </svg>
    </span>
  );
};
