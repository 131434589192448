import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Form, Input, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useModalContext } from 'components/Modal/ModalProvider';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { TimeOffColleaguesActions } from 'context/TimeManagement/ColleaguesRequests/actions';
import { useColleaguesRequestsFilterContext } from 'context/TimeManagement/ColleaguesRequests/context';
import { defaultState as dS } from 'context/TimeManagement/ColleaguesRequests/reducers';
import dayjs from 'dayjs';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import { useAuth } from '../../../../services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  rolesAllow,
  userRoles
} from '../../../../utils/userManagement';
import { conflictTypes } from '../TimeSheetTable/columnsTimeSheet';
import '../index.less';
import { aproximateHour, getEventsDay } from '../utils';
import {
  isEventTimeOffRequestBased,
  onSubmit,
  parseHourMinutes
} from './utils';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { RootState } from 'redux/store';

type DayEditFormProps = {
  data: any;
  employeeName: string;
  employeeId: number;
  children?: ReactNode;
  setDisableButtonResolve?: any;
  onFinishFormActions?: any;
  form?: any;
};

//is new form beacause new api was provided with refactor timesheet for inputs data in form
const DayEditFormNew: FC<DayEditFormProps> = ({
  data,
  employeeName,
  employeeId,
  setDisableButtonResolve,
  form
}) => {
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const [isUserSettingTime, setIsUserSettingTime] = useState<boolean>(false);
  const [hoursInputValue, setHoursInputValue] = useState<string>(null);
  const [minutesInputValue, setMinutesInputValue] = useState<string>(null);
  const [disableValueWorkedHours, setDisableValueWorkedHours] =
    useState<boolean>(false);
  const methodUpdateSelector = useSelector(
    (state: RootState) => state.methodUpdate
  );

  const { theme } = useThemeContext();
  const { deviceWidth } = useMobileContext();
  const { toggleModal } = useModalContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const valueInputRef = useRef(null);
  const isNotActiveUser = (data?.events || []).some(event =>
    ['TerminatedContract', 'EmployeeNotEmployed'].includes(event.type)
  );

  const { timeOffColleaguesDispatch } = useColleaguesRequestsFilterContext();

  useEffect(() => {
    setDisableValueWorkedHours(isNotActiveUser);

    let notes;
    if (data.events) {
      notes = data.events
        .filter(({ type }) => type === 'ClockInOutHR')
        .map(({ description }) => description)[0];
    } else notes = [];
    const fields = {
      dateOnly: data?.dateOnly
        ? dayjs(data.dateOnly).format('MMMM D, YYYY')
        : null,
      employeeName: employeeName,
      notes: notes,
      timeclockedMinutes: '',
      timeclockedHour: ''
    };

    if (conflictTypes.includes(data?.conflictType))
      valueInputRef.current.focus();

    form.setFieldsValue(fields);
  }, [data, employeeName, form]);

  const onFinishFormActions = {
    dispatch,
    toggleModal,
    triggerOtherActionsWithResult: () => trigerDataFromTimeSheet()
  };
  const isUserAllowedToEdit = allowPermision(userRole, [
    userRoles.HR,
    userRoles.Accountant
  ]);
  const eventText = intl.formatMessage({
    id: 'label_ViewConflictForm_Event'
  });

  const trigerDataFromTimeSheet = () => {
    dispatch(setMethodUpdate(!methodUpdateSelector.isMethodUpdated));
  };

  return (
    <Form
      form={form}
      key="DayEditForm"
      id="dayEditForm"
      colon={false}
      name="basic"
      labelAlign="left"
      labelWrap
      className="day_edit_form"
      onFinish={results => {
        onSubmit({ results, employeeId, onFinishFormActions });
      }}
      onFinishFailed={validationError =>
        onFormFinishFailedValidation(validationError, dispatch)
      }
    >
      <Row key="Date">
        <ColFormItemComponent
          label={<FormattedMessage id="label_ViewConflictForm_Date" />}
          name="dateOnly"
          className={`col-form-item-component-view ${theme}`}
          span={24}
          isDisabled
        >
          <Input autoComplete="off" disabled={true} />
        </ColFormItemComponent>
      </Row>
      <Row key="Employee">
        <ColFormItemComponent
          label={<FormattedMessage id="label_ViewConflictForm_Employee" />}
          name="employeeName"
          span={24}
          className={`col-form-item-component-view ${theme}`}
          onClick={() => {
            toggleModal();
            navigate(`/allUsersList/viewProfile?employeeId=${employeeId}`);
          }}
          isDisabled
        >
          <Input autoComplete="off" readOnly className="employee-item" />
        </ColFormItemComponent>
      </Row>
      {data?.events?.map(
        (
          {
            requestId,
            description,
            type,
            workingHours,
            startDate,
            endDate,
            requestStatus
          },
          index
        ) => {
          const aproxHours = aproximateHour(workingHours, false);

          const displayEvents = getEventsDay(
            type,
            requestStatus,
            description,
            parseHourMinutes(aproxHours),
            startDate,
            endDate,
            intl
          )[type];

          return (
            <Row key={index}>
              <ColFormItemComponent
                label={`${eventText} ${index + 1}`}
                span={24}
                isDisabled
                isReadOnly={`col-form-item-component-view ${theme}`}
                onClick={() => {
                  if (
                    isEventTimeOffRequestBased(type, description, requestId)
                  ) {
                    toggleModal();
                    timeOffColleaguesDispatch({
                      type: TimeOffColleaguesActions.UPDATE_REQUEST_ID,
                      payload: {
                        requestId: requestId
                      }
                    });
                    if (!window.location.href.includes('colleaguesRequests'))
                      navigate(
                        '/timeManagement/colleaguesRequests?requestId=' +
                          requestId
                      );
                  }
                }}
              >
                <TextArea
                  value={displayEvents}
                  autoSize
                  readOnly
                  className={`event-description ${
                    isEventTimeOffRequestBased(type, description, requestId) &&
                    'link'
                  }`}
                />
              </ColFormItemComponent>
            </Row>
          );
        }
      )}
      <Row key="Value" wrap={false}>
        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="label_ViewConflictForm_Value" />}
          className={`star-red-custom col-form-item-component ${theme}`}
          rules={[{ required: true }]}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns:
                deviceWidth > 769 ? '150px 150px' : 'auto auto',
              columnGap: '20px'
            }}
            className="two-inpunts-on-row"
          >
            <ColFormItemComponent
              name="timeclockedHour"
              className={`col-form-item-component ${theme}`}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="PleaseConfirmHourTextTimesheet" />
                  )
                },
                {
                  pattern: /^(0?[0-9]|1[0-9]|2[0-3])$/,
                  message: (
                    <FormattedMessage id="TimeHourValidatioInputMessage" />
                  )
                }
              ]}
            >
              {/* 
              ! Start Work Here
              TODO:
              - [✔] amount cannot be negative (only natural numbers are allowed)
              - [✔] the validation error message should expand in the row, not go on a separate one
              */}
              <Input
                type="number"
                min={0}
                style={{
                  border: '20px',
                  width: deviceWidth > 769 ? '150px' : '100%'
                }}
                value={isUserSettingTime ? hoursInputValue : ''}
                addonAfter="h"
                ref={valueInputRef}
                disabled={disableValueWorkedHours || isUserAllowedToEdit}
                onChange={e => {
                  if (!isUserSettingTime) {
                    setIsUserSettingTime(true);
                  }
                  setHoursInputValue(e.currentTarget.value);

                  setDisableButtonResolve(!(e.currentTarget.value !== ''));
                }}
              />
            </ColFormItemComponent>
            <ColFormItemComponent
              name="timeclockedMinutes"
              className={`col-form-item-component ${theme}`}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  pattern: /^(?:[0-9]|[1-5][0-9]|59)$/,
                  message: (
                    <FormattedMessage id="TimeMinutesValidatioInputMessage" />
                  )
                }
              ]}
            >
              <Input
                type="number"
                min={0}
                value={isUserSettingTime ? minutesInputValue : ''}
                addonAfter="m"
                disabled={disableValueWorkedHours || isUserAllowedToEdit}
                onChange={e => {
                  if (!isUserSettingTime) {
                    setIsUserSettingTime(true);
                  }
                  setMinutesInputValue(e.currentTarget.value);

                  setDisableButtonResolve(
                    !(
                      e.currentTarget.value !== '' &&
                      form.getFieldValue('timeclockedHour') !== ''
                    )
                  );
                }}
                style={{
                  width: deviceWidth > 769 ? '150px' : '100%'
                }}
              />
            </ColFormItemComponent>
          </div>
        </ColFormItemComponent>
      </Row>
      <Row key="Notes">
        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="label_ViewConflictForm_Notes" />}
          name="notes"
        >
          <TextArea maxLength={500} disabled={isUserAllowedToEdit} />
        </ColFormItemComponent>
      </Row>
    </Form>
  );
};

export default DayEditFormNew;
