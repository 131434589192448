import { DateToBeginningOfLocalDate } from "components/DateParser";


export const getListOfTimeOffEmployeeStatus = (generalRootSelector): [] => {
  return Object.keys(generalRootSelector).length
    ? generalRootSelector.enums?.EmployeeStatus &&
      Array.isArray(generalRootSelector.enums?.EmployeeStatus)
      ? generalRootSelector.enums?.EmployeeStatus.filter(
          (policy) => policy !== 'None'
        )
      : []
    : [];
};

export const createcolumnFilters = (selectedColumnsValues, selectedOptionsEmployeeStatus, selectedOptionsRequestType) => {
  return selectedColumnsValues.columnFilters.filter(
    (filter) =>
      filter.columnName !== 'EmployeeStatus' &&
      filter.columnName !== 'RequestType'
  )
    .concat(
      createFilter(
        'EmployeeStatus',
        selectedOptionsEmployeeStatus,
        selectedColumnsValues
      ),
      createFilter(
        'RequestType',
        selectedOptionsRequestType,
        selectedColumnsValues
      )
    );
}


export const generateBodyParamsByFilters = (
  nameRaport,
  selectedColumnsValues,
  selectedOptionsRequestType,
  selectedOptionsEmployeeStatus,
  range
) => {
  let from = null;
  let to = null;

  const columnFilters = createcolumnFilters(selectedColumnsValues, selectedOptionsEmployeeStatus, selectedOptionsRequestType)
    
if (range?.startDate !== null && range?.endDate !== null) {
  from = DateToBeginningOfLocalDate(range?.startDate);
  to = DateToBeginningOfLocalDate(range?.endDate);
}

  return {
    reportName: nameRaport,
    from,
    to,
    columnFilters,
  };
};

const createFilter = (columnName, selectedOptions, selectedColumnsValues) => {
  const includeInResult = selectedColumnsValues.columnFilters.some(
    (filter) => filter.columnName === columnName
  );
  if (includeInResult === false && selectedOptions.length === 0) {
    return [];
  } else {
    if (selectedOptions.length > 0) {
      return {
        columnName,
        includeInResult,
        filterOperation: 'In',
        filterValue: selectedOptions.join(','),
      };
    } else {
      return {
        columnName,
        includeInResult,
      };
    }
  }
};

  //prepare structure for body paramas api for param columnFilters
  export const setColumnsToGenerate = (selectedOptionsColumns) => {
  const columnFilters = selectedOptionsColumns.map((columnName) => ({
    columnName,
    includeInResult: true,
  }));

  return { columnFilters };
};

// Request Types Options
export enum timeOffPoliciesTitlesFilter {
  Vacation = 'Vacation',
  Medical = 'Medical',
  Paternity = 'Paternity',
  Maternity = 'Maternity',
  Childcare = 'Childcare',
  WorkFromHome = 'WorkFromHome',
  Unpaid = 'Unpaid',
  PersonalLeave = 'PersonalLeave',
  LeaveOffsetting = 'LeaveOffsetting',
  Overtime = 'Overtime',
  BloodDonation = 'BloodDonation',
  Bereavement = 'Bereavement',
  Marriage = 'Marriage',
  ForgotBadge = 'ForgotBadge',
  MilitaryTraining = 'MilitaryTraining',
  TwoOrMoreChildren = 'TwoOrMoreChildren',
  BusinessTrip = 'BusinessTrip',
  FirstDayOfSchool = 'FirstDayOfSchool',
  LastDayOfSchool = 'LastDayOfSchool',
  ChildcareLeaveUnpaid = 'ChildcareLeaveUnpaid',
  BirthOfAChild = 'BirthOfAChild',
} 

// Employee Status Options
export enum StatusEmployeeFilter {
  OnBoarding = 'OnBoarding',
  Internship = 'Internship',
  Employee = 'Employee',
  External = 'External',
  MaternityChildCareLeave = 'MaternityChildCareLeave',
  Deactivated = 'Deactivated'

} 
