import { FC } from 'react';
import ModalComponent, { IModalComponentProps } from 'components/Modal';
import AddEmployeePage from 'pages/Employee/AddEmployeePage';
import { FormattedMessage } from 'react-intl';

interface IAddEmployeeModalProps extends IModalComponentProps {}

const AddEmployeeModal: FC<IAddEmployeeModalProps> = ({
  title,
  submitBtnText,
  cancelBtnText
}) => {
  return (
    <ModalComponent
      isMaskClosable={false}
      submitBtnText={submitBtnText ?? <FormattedMessage id="add" />}
      cancelBtnText={cancelBtnText ?? <FormattedMessage id="cancel" />}
      title={title ?? <FormattedMessage id="AddColleague" />}
      children={<AddEmployeePage />}
      formKeyId="employeeForm"
    />
  );
};

export default AddEmployeeModal;
