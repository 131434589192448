import { Typography } from 'antd';
import dayjs from 'dayjs';

const { Text } = Typography;
export const conflictTypes = ['ConflictGeneral', 'ConflictHours'];

export const generateDaysColumns = (
  data,
  params,
  hookUseStateActions,
  countryCode: string
) => {
  const isRO = countryCode === 'RO';
  const intl = params?.intl;
  const currentDate = dayjs(params.currentDay).locale(intl?.locale);
  const daysInMonth = [];
  const numDaysInMonth = currentDate.daysInMonth();

  for (let day = 1; day <= numDaysInMonth; day++) {
    const date = currentDate.date(day);
    const dayNumber = date.format('D');
    const dayName = date.format('dd');

    const fullDetailsDay = currentDate
      .date(day - 1)
      .format('YYYY-MM-DDTHH:mm:ss');
    const isWeekend = dayNumber => {
      return dayjs(dayNumber).day() === 0 || dayjs(dayNumber).day() === 6;
    };

    const getClassNameByEventsOrWeekendBody = (dayRecord, cellDayNumber) => {
      if (conflictTypes.includes(dayRecord?.conflictType)) {
        return 'conflict_day';
      } else if (dayRecord?.conflictType === 'ConflictSolved') {
        return 'conflictSolved_day';
      } else if (
        dayRecord?.events?.filter(event => event.type === 'Holiday').length > 0
      ) {
        return 'holiday_day';
      } else if (
        isWeekend(cellDayNumber) &&
        dayRecord?.events?.filter(
          event =>
            event.type === 'TerminatedContract' ||
            event.type === 'EmployeeNotEmployed'
        ).length === 0
      ) {
        return 'weekend_day';
      } else {
        return 'ordinary_day';
      }
    };

    const getClassNameByEventsOrWeekendHeader = (day, events, date) => {
      if (
        events?.length > 0 &&
        events.find(
          event =>
            new Date(event.eventDate).toISOString().split('T')[0] ===
              day.split('T')[0] && event.eventType === 'Holiday'
        )
      ) {
        return 'holiday_day';
      } else if (isWeekend(date)) {
        return 'weekend_day';
      } else {
        return 'ordinary_day';
      }
    };

    daysInMonth.push({
      key: dayNumber,
      title: (
        <div className="name_days_of_month">
          <Text className={`text-bold-normal text-align-center `}>
            {dayNumber}
            <br /> {dayName}
          </Text>
        </div>
      ),
      className: `column_days_timesheet ${getClassNameByEventsOrWeekendHeader(
        fullDetailsDay,
        params?.events,
        date
      )}`,
      width: 0,
      // The `render` function returns an object. The first key is responsible for the className of the td element to avoid using non-supported :has CSS rule.
      // The second key, `content`, is responsible for rendering the content of the column.
      render: (_, record) => ({
        cellRowClassName: `days_in_month ${getClassNameByEventsOrWeekendBody(
          record[dayNumber],
          date
        )}`,

        content: (
          <Text
            onClick={e => {
              if (isRO && record[dayNumber]?.events?.length === 0) return;
              params.setDisableButtonResolve(true);

              hookUseStateActions?.setModalProps(state => ({
                ...state,
                data: record[dayNumber]?.data,
                employeeName: record?.EmployeeInfo?.employeeName,
                employeeId: record?.EmployeeInfo?.employeeId,
                formKeyId: 'dayEditForm',
                modalTitle: intl.formatMessage({ id: 'ViewConflict' }),
                submitButtonResolveText: intl.formatMessage({ id: 'resolve' }),
                submitButtonResolveAndNextText: intl.formatMessage({
                  id: 'resolveAndNext'
                }),
                cancelButtonText: intl.formatMessage({ id: 'cancel' })
              }));

              hookUseStateActions?.toggleModal();
            }}
            data-col={dayNumber}
            key={dayNumber}
            className={`text-medium-normal days_cell_text text_color_timesheet_text`}
          >
            {isRO && isWeekend && record[dayNumber]?.letter === 'R'
              ? ''
              : record[dayNumber]?.letter}
          </Text>
        )
      })
    });
  }
  return daysInMonth;
};
