import { TimeOffService } from 'services';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import { getFileUploadFormData } from 'utils/uploadFileRequestWithFormData';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
export const onSubmitTimeOffRequestForm = async ({
  scope,
  formData,
  timeOffId,
  actions,
  status,
  requestType,
  ...props
}) => {
  const createdDate = new Date().toISOString();
  const { notes, requestedForId, additionalFields } = formData;
  const { fileList, employeeId, isRegularFormSubmit, ...requestProps } = props;
  const requestedFor =
    scope === 'requestFor' || scope === 'ColleagueProfile'
      ? employeeId
        ? employeeId
        : requestedForId
      : null;

  formData = {
    timeOffId,
    requestType,
    notes,
    createdDate,
    requestedFor,
    additionalFields,
    status,
    ...requestProps,
  };

  const formActions = {
    request: TimeOffService.addTimeOffRequest,
    requestFor: TimeOffService.addTimeOffRequestFor,
    ColleagueProfile: TimeOffService.addTimeOffRequestFor,
  };

  if ((status === 'Unfinished' && timeOffId) || scope === 'draft') {
    const { timeOffId, createdDate, status, ...updateRequestData } = formData;

    const aggUpdateFormData = getFileUploadFormData(
      {
        timeOffRequestUpdate: {
          ...updateRequestData,
          status: status,
          requestId: timeOffId,
        },
      },
      fileList
        ? {
            formDataFileName: 'files',
            uploadFiles: fileList,
          }
        : null
    );

    TimeOffService.updateTimeOffRequest(aggUpdateFormData).then((res) => {
      notifyWithSuccessfulDataUpdated(
        status === 'Draft'
          ? getSuccessMessages.success_updated_draft_request
          : getSuccessMessages.success_updated_request
      );
      actions?.setIsEdit ? actions.setIsEdit(false) : actions.toggleModal();

      actions?.setIsEdit && actions?.setViewRequestModal(false);
      actions.dispatch(setMethodUpdate(true));
    });
    return;
  }

  const aggregatedFormData = getFileUploadFormData(
    { timeOffRequestUpdate: formData, filesToRemove: props?.filesToRemove },
    fileList
      ? {
          formDataFileName: 'files',
          uploadFiles: fileList,
        }
      : null
  );

  const getMessagesSuccess =
    status === 'Draft'
      ? getSuccessMessages.success_draft_request
      : getSuccessMessages.success_submited_request;

  onFormFinishWithPassedValidation(
    formActions,
    scope,
    aggregatedFormData,
    actions,
    getMessagesSuccess
  );
};
