import * as React from 'react';
export const ListViewComponent = () => {
  return (
    <span role='img' aria-label='addUser' className='anticon anticon-addUser'>
      <svg
        width='12'
        height='12'
        viewBox='0 0 14 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.41333 1H13M4.41333 5.32H13M4.41333 9.64H13M1 1V1.00853M1 5.32V5.32853M1 9.64V9.64853'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
};
