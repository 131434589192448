import { Space, theme } from 'antd';
import TicketCard from './components/TicketCard';
import styles from './cardsSection.module.less';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { LabelTag } from 'components/UIComponents/LabelTag';
import { fetchMoreTickets } from 'redux/slices/ticketingSlice/actions';
import { FormattedMessage } from 'react-intl';

import {
  AssignmentTypes,
  TicketPriority,
  TicketStatus,
  TicketType
} from 'pages/Ticketing/types';
import { areFiltersSelected } from 'redux/slices/ticketingFilters/selectors';
import { useThemeContext } from '../../../../../AppProvider/ConfigProviderSettings';

const FETCH_MORE_TICKETS_COUNT = 10;

const TicketsCardsSection = () => {
  const { allTickets: data, totalNumberOfTickets } = useSelector(
    state => (state as any).ticketing
  );
  const { theme } = useThemeContext();
  const { ticketingFilters } = useSelector((state: RootState) => state);
  const isFiltersActive = useSelector(areFiltersSelected);
  const dispatch = useDispatch<AppDispatch>();
  const displayMoreTicketsLabelCheck =
    totalNumberOfTickets > ticketingFilters?.paginationSize &&
    totalNumberOfTickets > data.length;

  return (
    <div className={data && data.length > 0 ? styles.wrap : styles.container}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {data &&
          data.map((ticket, index) => (
            <Link
              key={ticket?.ticketId + '-' + index}
              to={`?ticketId=${ticket?.ticketId}`}
            >
              <TicketCard ticket={ticket} />
            </Link>
          ))}
        {displayMoreTicketsLabelCheck ? (
          <div className={styles.showMoreLinkWrapper}>
            <LabelTag
              className={`wrap primary-${theme}`}
              onClick={() => {
                dispatch(
                  fetchMoreTickets({
                    tickets: data,
                    howMany: FETCH_MORE_TICKETS_COUNT,
                    filterBy: isFiltersActive
                      ? {
                          priorities:
                            ticketingFilters?.urgencyTypes as TicketPriority[],
                          assignTypes:
                            ticketingFilters?.assignmentType as AssignmentTypes[],
                          statuses:
                            ticketingFilters?.statusTypes as TicketStatus[],
                          types:
                            ticketingFilters?.categoriesTypes as TicketType[]
                        }
                      : undefined
                  })
                );
              }}
              level={5}
            >
              <FormattedMessage
                id="ShowMoreTickets"
                values={{
                  nrItems:
                    totalNumberOfTickets - data.length < 10
                      ? totalNumberOfTickets - data.length
                      : FETCH_MORE_TICKETS_COUNT
                }}
              />
            </LabelTag>
          </div>
        ) : null}
      </Space>
    </div>
  );
};
export default TicketsCardsSection;
