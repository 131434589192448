import { getExtension } from 'utils/getExtension';
import {
  AssignmentTypes,
  TicketDto,
  TicketPriority,
  TicketStatus,
  TicketType
} from './types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import './ticketingPage.less';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

export const TicketPriorityElement: React.FC<{ priority: TicketPriority }> = ({
  priority
}) => {
  const { theme } = useThemeContext();

  const ticketPriorityMap = {
    [TicketPriority.Medium]: {
      color: '#219653',
      text: <FormattedMessage id="TicketingTablePriority_Medium" />
    },
    [TicketPriority.Low]: {
      color: '#5AB0FF',
      text: <FormattedMessage id="TicketingTablePriority_Low" />
    },
    [TicketPriority.High]: {
      color: '#F2C94C',
      text: <FormattedMessage id="TicketingTablePriority_High" />
    },
    [TicketPriority.Critical]: {
      color: '#EB5757',
      text: <FormattedMessage id="TicketingTablePriority_Critical" />
    }
  };
  return (
    <div
      style={{
        color: ticketPriorityMap[priority]?.color,
        fontWeight: '700' ?? 'inherit',
        paddingRight: '8px'
      }}
      className={`detailed__view__prop ${theme}`}
    >
      {ticketPriorityMap[priority]?.text}
    </div>
  );
};

export const getAssignmentTypesDefinition = () => {
  return Object.keys(AssignmentTypes).filter(
    key => key !== AssignmentTypes.None
  );
};

export const getTicketUrgencyTypesDefinition = () => {
  return Object.fromEntries(
    Object.entries(TicketPriority).filter(
      ([key]) => key !== TicketPriority.None
    )
  );
};

export const getTicketCategoriesDefinition = () => {
  return Object.keys(TicketType).filter(key => key !== TicketType.None);
};

export const getTicketStatusTypesDefinition = () => {
  return Object.fromEntries(
    Object.entries(TicketStatus).filter(([key]) => key !== TicketStatus.None)
  );
};

export const formatTicketTitle = (ticket: TicketDto) => (
  <Link to={`/ticketing/viewTicket?ticketId=${ticket?.ticketId}`}>
    {'#' + ticket.ticketNumber + ' - ' + ticket.title}
  </Link>
);

export const formatAssigneeName = (ticket: TicketDto) =>
  ticket?.assignee
    ? ticket?.assignee?.lastName + ' ' + ticket?.assignee?.firstName
    : undefined;

export const getFileType = (path: string) => {
  const imageFormats: string[] = [
    'jpeg',
    'jpg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'webp',
    'svg',
    'ico',
    'jfif'
    // Add more image formats as needed
  ];
  const videoFormats: string[] = [
    'mp4',
    'avi',
    'mkv',
    'mov',
    'wmv',
    'flv',
    'webm',
    'm4v',
    '3gp',
    'mpeg',
    'mpg'
    // Add more video formats as needed
  ];

  if (imageFormats.includes(getExtension(path))) {
    return 'image';
  }

  if (videoFormats.includes(getExtension(path))) {
    return 'video';
  }

  return 'file';
};

export const sortByPriority = (a: TicketDto, b: TicketDto) => {
  const priorityMap = {
    [TicketPriority.Critical]: 1,
    [TicketPriority.High]: 2,
    [TicketPriority.Medium]: 3,
    [TicketPriority.Low]: 4,
    [TicketPriority.None]: 5
  };
  return priorityMap[a.priority] - priorityMap[b.priority];
};

export const sortByStatus = (a: TicketDto, b: TicketDto) => {
  const statusMap = {
    [TicketStatus.New]: 0,
    [TicketStatus.Open]: 1,
    [TicketStatus.Resolved]: 2,
    [TicketStatus.Closed]: 3,
    [TicketStatus.None]: 4
  };
  return statusMap[a.status] - statusMap[b.status];
};
