import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import React from 'react';
import AttachmentItem from './AttachmentItem';
import styles from './ticketAttachments.module.less';

const TicketAttachments: React.FC<{
  attachmentFiles: Array<{
    fileName: string;
    fileType: string;
    onDownload: () => void;
  }>;
  gap?: number;
  perPage?: number;
  attachmentMaxWidth?: number;
  isCommentAttachment?: boolean;
}> = ({
  attachmentFiles,
  gap = 10,
  perPage = 1, // doesn't work with Splide's `autoWidth`
  attachmentMaxWidth = 120,
  isCommentAttachment = false,
}) => {
  const ticketAttachmentRef = React.useRef<HTMLDivElement | null>(null);
  const [arrows, setArrows] = React.useState<boolean>(false);

  const resizeWindowHorizontally = (entries: ResizeObserverEntry[]) => {
    const { width } = entries[0].contentRect;

    const TOTAL_GAP: number = attachmentFiles?.length * gap;
    const TOTAL_WIDTH_OF_ITEMS: number =
      attachmentFiles?.length * attachmentMaxWidth + TOTAL_GAP;

    if (TOTAL_WIDTH_OF_ITEMS > width) {
      return setArrows(true);
    }

    return setArrows(false);
  };

  React.useEffect(() => {
    if (attachmentFiles?.length === 1) {
      return setArrows(false);
    }

    const observer = new ResizeObserver(resizeWindowHorizontally);

    if (ticketAttachmentRef?.current) {
      observer.observe(ticketAttachmentRef.current);
    }

    return () => observer.disconnect();
  }, [attachmentFiles?.length, resizeWindowHorizontally]);

  return (
    <div ref={ticketAttachmentRef}>
      <Splide
        className={styles.splideCarousel}
        hasTrack={false}
        options={{
          arrows: arrows,
          arrowPath:
            'M0.803304 3.2612C0.398887 3.66925 0.39891 4.33078 0.803304 4.7388L3.73223 7.69398C4.13663 8.102 4.79228 8.10202 5.1967 7.69398C5.60111 7.28593 5.60109 6.62441 5.1967 6.21639L3 4L5.1967 1.78361C5.60111 1.37557 5.60109 0.714042 5.1967 0.306023C4.7923 -0.101996 4.13665 -0.102019 3.73223 0.306023L0.803304 3.2612Z',
          classes: {
            arrows: `splide__arrows`,
            arrow: `splide__arrow ${styles.arrow}`,
            prev: `splide__arrow--prev ${styles.arrow} ${styles.prev}`,
            next: `splide__arrow--next ${styles.arrow} ${styles.next}`,
          },
          autoWidth: true,
          direction: 'ltr',
          focus: 0,
          gap,
          omitEnd: true,
          pagination: false,
          // perPage,
          trimSpace: true,
        }}
      >
        <div
          style={{
            marginLeft: arrows ? '54px' : '5px',
            marginRight: arrows ? '54px' : '0px',
          }}
        >
          <SplideTrack className={styles.customTrack}>
            {attachmentFiles?.map((item, index) => (
              <SplideSlide key={index}>
                <AttachmentItem
                  {...{ isCommentAttachment }}
                  attachmentMaxWidth={attachmentMaxWidth}
                  {...item}
                />
              </SplideSlide>
            ))}
          </SplideTrack>
        </div>
      </Splide>
    </div>
  );
};

export default TicketAttachments;
