import * as React from 'react';
import { Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import { useAuth } from 'services/providers/AuthProvider';
import { useModalContext } from 'components/Modal/ModalProvider';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { RequestButtonProps } from './interfaces/RequestButton.interface';

import styles from './style.module.less';

const RequestButton: React.FC<RequestButtonProps> = ({
  index,
  setselecteditem,
  setModalOpen,
  setModalProps,
  request,
  scope,
  title,
  allowUnfinishedRequests,
  filteredrequests
}) => {
  const intl = useIntl();
  const { user } = useAuth();
  const { theme } = useThemeContext();
  const { toggleModal } = useModalContext();

  const userRole = getCurrentUserRole(user);
  const requestText = intl.formatMessage({ id: 'request' });
  const cancelBtnText = intl.formatMessage({ id: 'cancel' });
  const sendUnfinishedText = intl.formatMessage({ id: 'sendUnfinished' });

  const requestprops = [
    {
      formKeyId: 'timeOffForm',
      requestType: 'personal',
      modalTitle: <FormattedMessage id="RequestTimeOffModalTitle" />,
      submitBtnText: requestText,
      cancelBtnText: cancelBtnText,
      additionalBtnText: sendUnfinishedText,
      scope: scope === 'ColleagueProfile' ? 'ColleagueProfile' : 'request',
      isTimeOffRequestForm: true,
      displayRequestButton: true,
      title,
      requestTypeOff: request,
      displayFooterAdditionalButton: allowUnfinishedRequests
    }
  ];

  const specialRequestProps = [
    {
      formKeyId: 'timeOffForm',
      requestType: 'special',
      modalTitle: <FormattedMessage id="RequestSpecialTimeOffModalTitle" />,
      submitBtnText: requestText,
      cancelBtnText: cancelBtnText,
      additionalBtnText: sendUnfinishedText,
      scope: scope === 'ColleagueProfile' ? 'ColleagueProfile' : 'request',
      isTimeOffRequestForm: true,
      displayRequestButton: true,
      title,
      requestTypes: filteredrequests,
      displayFooterAdditionalButton: allowUnfinishedRequests
    }
  ];

  let isNotAccountantOrColleagueProfile =
    userRole !== userRoles.Accountant || scope !== 'ColleagueProfile';

  const handleClick = () => {
    setselecteditem(index);

    if (isNotAccountantOrColleagueProfile) {
      setModalOpen({
        editProfile: false,
        clockInclockOut: false,
        request:
          allowPermision(user.role, [userRoles.Admin, userRoles.HR]) ||
          scope === 'MyProfile'
      });

      if (request === 'Special') {
        setModalProps(...specialRequestProps);
      } else {
        setModalProps(...requestprops);
      }

      toggleModal();
    }
  };

  return (
    <Button
      type="primary"
      className={`${styles.button} ${theme} text-bold-normal btn-primary-custom`}
      onClick={handleClick}
    >
      {intl.formatMessage({ id: 'request' })}
    </Button>
  );
};

export default RequestButton;
