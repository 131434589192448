export const ListViewIconSVG = () => {
  return (
    <span role="img" aria-label="listView" className="anticon anticon-listView">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M4.41333 1.67578H13M4.41333 5.99578H13M4.41333 10.3158H13M1 1.67578V1.68431M1 5.99578V6.00431M1 10.3158V10.3243"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
