import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import TextArea from 'antd/es/input/TextArea';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import ModalComponent from 'components/Modal';
import { useReportsFilterContext } from '../ReportsFilterContext';
import { notifyWithError } from 'utils/notificationsUtils';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { FormattedMessage, useIntl } from 'react-intl';
import { useIntlContext } from 'AppProvider/ConfigProviderSettings';
import { ReportService } from '../../../services';
import { generateBodyParamsByFilters, setColumnsToGenerate, createcolumnFilters } from '../ReportsDetailsToolbar/utils';

import './index.less';

const SavePresetModal = ({
  showSavePreset,
  setShowSavePreset,
  isEdit,
  defaultPresetName,
  defaultPresetId,
  presets,
  selectedColumns
}) => {
  const [form] = Form.useForm();

  const { locale } = useIntlContext();

  const [presetName, setPresetName] = useState<string>(defaultPresetName || '');

  const [savePresetText, setSavePresetText] = useState('');

  const { presetTableFilters, reportType } = useReportsFilterContext();

  const selectedColumnsValues = setColumnsToGenerate(
    selectedColumns ? selectedColumns.map((item) => item) : presetTableFilters?.selectedColumns?.map((item) => item)
  );

  const stateData = generateBodyParamsByFilters(
    presetTableFilters.reportType,
    selectedColumnsValues,
    presetTableFilters.requestTypes,
    presetTableFilters.employeeStatus,
    presetTableFilters.range
  );


  const currentPreset = {
    presetId: defaultPresetId,
    presetName: presetName,
    filters: JSON.stringify(createcolumnFilters(selectedColumnsValues, presetTableFilters.employeeStatus, presetTableFilters.requestTypes)),
    reportName: presetTableFilters.reportType
  };

  const savePreset = () => {
    if (presetName === '') {
      notifyWithError({
        message: <FormattedMessage id="PresetNameErrorMessage" />,
        description: null,
      });
      return;
    }
    if (presets.length === 5) {
      notifyWithError({
        message: <FormattedMessage id="UnableToSavePresetErrorMessage" />,
        description: (
          <FormattedMessage id="UnableToSavePresetErrorDescription" />
        ),
      });
      return;
    }
    if (presetName !== '' && !presets.some(preset => preset.presetName === presetName)) {

      ReportService.addPreset(currentPreset);
      notifyWithSuccessfulDataUpdated(getSuccessMessages.success_preset_saved);
    } else {
      notifyWithError({
        message: <FormattedMessage id="PresetNameAlredyUsedErrorMessage" />,
        description: null,
      });
      return false;
    }

    setShowSavePreset(false);
  };

  const editPreset = () => {
    if (presetName === '') {
      notifyWithError({
        message: <FormattedMessage id="PresetNameErrorMessage" />,
        description: null,
      });
      return;
    }
    if (presetName !== '' && presetName === defaultPresetName) {

      ReportService.updatePreset(currentPreset);
      notifyWithSuccessfulDataUpdated(getSuccessMessages.success_preset_edited);
    } else if (
      presetName !== defaultPresetName &&
      !presets.some(preset => preset.presetName === presetName)
    ) {

      ReportService.updatePreset(currentPreset);
      notifyWithSuccessfulDataUpdated(getSuccessMessages.success_preset_edited);
    }
    setShowSavePreset(false);
  };

  const intl = useIntl();

  useEffect(() => {
    if (locale === 'en') {
      setSavePresetText(intl.formatMessage({ id: 'savePreset' }));
    } else {
      setSavePresetText(intl.formatMessage({ id: 'save' }));
    }
  }, [locale]);
  const cancelText = intl.formatMessage({ id: 'cancel' });

  return (
    <ModalComponent
      title={
        !isEdit ? (
          <FormattedMessage id="SaveReportPresets" />
        ) : (
          <FormattedMessage id="SaveReportPresetChanges" />
        )
      }
      isOpen={showSavePreset}
      setIsOpen={setShowSavePreset}
      className="save_preset"
      submitBtnText={
        !isEdit ? savePresetText : <FormattedMessage id="saveChanges" />
      }
      cancelBtnText={cancelText}
      onSubmit={!isEdit ? () => savePreset() : () => editPreset()}
    >
      <Form
        form={form}
        id="stateActionForm"
        key="stateActionFormKey"
        className="save_preset_form"
        name="basic"
        onFinishFailed={(validationError) =>
          onFormFinishFailedValidation(validationError, null)
        }
      >
        <ColFormItemComponent span={24}>
          <p style={{ marginBlock: '0px' }}>
            {isEdit ? (
              <FormattedMessage id="savePresetChangesConfirm" />
            ) : (
              <FormattedMessage id="providePresetName" />
            )}
          </p>
        </ColFormItemComponent>
        <ColFormItemComponent
          span={24}
          name="notes"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="providePresetNameWarning" />,
            },
          ]}
        >
          <TextArea
            defaultValue={presetName}
            value={presetName}
            required={true}
            maxLength={500}
            onChange={(e) => setPresetName(e.target.value.trim())}
          />
        </ColFormItemComponent>
      </Form>
    </ModalComponent>
  );
};

export default SavePresetModal;
