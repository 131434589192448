import { Col, Row, Typography } from 'antd';
import {
  useMobileContext,
  useThemeContext,
} from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ModalComponent from 'components/Modal';
import { ClockInOutService } from 'services';
import { IMyClockInOut } from 'services/providers/ClockInOutProvider/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import ClockOutForm from 'components/TimeManagement/TimeOffRequest/TimeOffClock/ClockoutForm';
import TimeOffRequestForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm';
import StateActionsForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestTable/StateActionsForm';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';
import dayjs from 'dayjs';
import { useModalContext } from 'components/Modal/ModalProvider';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { useClockOutContext } from 'pages/TimeManagement/PersonalTimeOffRequests/PersonalTImeOffRequestsProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { firstCharToLowerCase } from 'utils/lang/utils';

export type PersonalTimeOffModalProps = {
  isTimeOffRequestForm?: boolean;
  formKeyId: string;
  modalTitle: string;
  submitBtnText: string;
  cancelBtnText: string;
  requestType?: string;
  scope?: string;
  timeOffId?: number;
  displayRequestButton?: boolean;
  workingHoursByEmployee?: any;
};

export const ClockInOut = ({
  openModal,
  setModalOpen,
  enableClockIn,
  lastClockIn,
  workingHours,
  paramsTimer,
  workingHoursByEmployee,
  scope,
}) => {
  const { Text } = Typography;
  const ButtonCustom = BaseButton(ButtonSimple);
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const { clockOutProps, setClockOutProps } = useClockOutContext();

  const methodUpdateSelector = useSelector(
    (state: RootState) => state.methodUpdate
  );

  const intl = useIntl();
  const requestTimeOffText = intl.formatMessage({
    id: 'RequestTimeOffModalTitle',
  });
  const submitAttendanceText = intl.formatMessage({ id: 'SubmitAttendance' });
  const submitText = intl.formatMessage({ id: 'submit' });
  const cancelText = intl.formatMessage({ id: 'cancel' });
  const requestText = intl.formatMessage({ id: 'request' });

  const requestFormProps = {
    formKeyId: 'timeOffForm',
    requestType: 'personal',
    modalTitle: requestTimeOffText,
    submitBtnText: requestText,
    cancelBtnText: submitText,
    scope: 'request',
    isTimeOffRequestForm: true,
    displayRequestButton: true,
  };
  const [modalProps, setModalProps] =
    useState<PersonalTimeOffModalProps>(requestFormProps);

  useEffect(() => {
    if (
      lastClockIn &&
      (lastClockIn.actionId !== clockOutProps.actionId ||
        lastClockIn !== clockOutProps.clockedIn ||
        lastClockIn !== clockOutProps.clockedOut)
    ) {
      setClockOutProps({
        actionId: lastClockIn.actionId,
        isClockedIn: lastClockIn != null,
        clockedIn: lastClockIn && lastClockIn.clockInTime,
        clockedOut: lastClockIn && lastClockIn.clockOutTime,
        isClockedOut: lastClockIn && lastClockIn.clockOutTime,
        timeWorked: workingHours,
        clockedInOutData: lastClockIn,
      });
    }
  }, [
    methodUpdateSelector.isMethodUpdated,
    clockOutProps.actionId,
    clockOutProps.clockedIn,
    clockOutProps.clockedOut,
    lastClockIn,
  ]);

  const { toggleModal } = useModalContext();

  const { isMobile } = useMobileContext();

  const initializeClockInHandler = async () => {
    try {
      const clockInResult = await ClockInOutService.clockIn();
      if (clockInResult.error) {
        throw new Error(
          `Couldn't initialize clock in, exception: ${clockInResult.error}`
        );
      }

      const clockInData = clockInResult.data as IMyClockInOut;

      setClockOutProps({
        ...clockOutProps,
        actionId: clockInData.actionId,
        isClockedIn: true,
        isClockedOut: false,
        clockedIn: clockInData.clockInTime,
        clockedOut: null,
      });

      dispatch(setMethodUpdate(true));

      notifyWithSuccessfulDataUpdated(
        getSuccessMessages.success_clocked_in,
        ''
      );
    } catch (err) {
      console.error(err);
    }
  };
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  return (
    <>
      <Col className="clock-in-out-col">
        {openModal.clockInclockOut && (
          <ModalComponent
            formKeyId={modalProps.formKeyId}
            className='clock-in-out-modal'
            disableSubmitButton={disableSubmitButton}
            children={
              modalProps.formKeyId === 'clockoutForm' ? (
                <ClockOutForm paramsTimer={paramsTimer} setDisableSubmitButton={setDisableSubmitButton} />
              ) : modalProps.formKeyId === 'timeOffForm' ? (
                <TimeOffRequestForm
                  requestType={modalProps.requestType}
                  scope={modalProps.scope}
                  timeOffId={modalProps.timeOffId}
                />
              ) : (
                <StateActionsForm
                  key="stateActionFormKey"
                  requestId={modalProps.timeOffId}
                  modalProps={modalProps}
                />
              )
            }
            isMaskClosable={modalProps.scope === 'view'}
            title={modalProps.modalTitle}
            cancelBtnText={modalProps.cancelBtnText}
            submitBtnText={modalProps.submitBtnText}
            displayFooterSubmitButton={modalProps.displayRequestButton}
          />
        )}

        <Row
          className="clock-in-out-row"
          style={
            isMobile
              ? { display: 'flex', justifyContent: 'center', margin: '20px 0' }
              : {}
          }
        >
          {(enableClockIn || workingHoursByEmployee !== '--:--') && (
            <div className="clockin-out-button-wrapper">
              <ButtonCustom
                style={{ pointerEvents: 'none' }}
                type="primary"
                className={`btn-primary-custom-yellow text-color-violet ${theme}`}
              >
                <FormattedMessage id="Worked" />
              </ButtonCustom>
              <div className="hour">
                <Text className="text-bold-small text-color-violet">
                  {workingHoursByEmployee}
                </Text>
              </div>
            </div>
          )}
          {scope === 'MyProfile' && enableClockIn && (
            <>
              <div className="clockin-out-button-wrapper clock-in-button">
                {isMobile ? (
                  <ButtonCustom
                    type="primary"
                    className={`btn-primary-custom-green ${theme}`}
                    disabled={lastClockIn && !lastClockIn.clockOutTime}
                    onClick={initializeClockInHandler}
                  >
                    <FormattedMessage id="MobileIn" />
                  </ButtonCustom>
                ) : (
                  <ButtonCustom
                    type="primary"
                    className={`btn-primary-custom-green ${theme}`}
                    disabled={lastClockIn && !lastClockIn.clockOutTime}
                    onClick={initializeClockInHandler}
                  >
                    {lastClockIn && !lastClockIn.clockOutTime ? (
                      <span style={{ marginRight: 2 }}>
                        <FormattedMessage id="ClockedIn" />
                      </span>
                    ) : (
                      <span style={{ marginRight: 2 }}>
                        <FormattedMessage id="ClockIn" />
                      </span>
                    )}
                  </ButtonCustom>
                )}
                <div className="hour">
                  <Text
                    className={`text-bold-small text-color-violet ${theme}`}
                  >
                    {(lastClockIn?.clockInTime &&
                      dayjs
                        .tz(
                          dayjs.utc(lastClockIn.clockInTime),
                          dayjs.tz.guess()
                        )
                        .format('HH:mm')) ??
                      `--:-- `}
                  </Text>
                </div>
              </div>
              <div className="clockin-out-button-wrapper">
                {isMobile ? (
                  <ButtonCustom
                    type="primary"
                    className={`btn-primary-custom-red ${theme}`}
                    disabled={
                      !lastClockIn || (lastClockIn && lastClockIn.clockOutTime)
                    }
                    onClick={() => {
                      setModalOpen({
                        editProfile: false,
                        clockInclockOut: true,
                        request: false,
                      });
                      toggleModal();
                      setModalProps({
                        formKeyId: 'clockoutForm',
                        isTimeOffRequestForm: false,
                        modalTitle: submitAttendanceText,
                        submitBtnText: submitText,
                        cancelBtnText: cancelText,
                        displayRequestButton: true,
                        requestType: 'personal',
                      });
                    }}
                  >
                    <FormattedMessage id="MobileOut" />
                  </ButtonCustom>
                ) : (
                  <ButtonCustom
                    type="primary"
                    className={`btn-primary-custom-red ${theme} clockin-out-button-wrapper`}
                    disabled={
                      !lastClockIn || (lastClockIn && lastClockIn.clockOutTime)
                    }
                    onClick={() => {
                      setModalOpen({
                        editProfile: false,
                        clockInclockOut: true,
                        request: false,
                      });
                      toggleModal();
                      setModalProps({
                        formKeyId: 'clockoutForm',
                        isTimeOffRequestForm: false,
                        modalTitle: submitAttendanceText,
                        submitBtnText: submitText,
                        cancelBtnText: cancelText,
                        displayRequestButton: true,
                        requestType: 'personal',
                      });
                    }}
                  >
                    {!lastClockIn ||
                      (lastClockIn && lastClockIn.clockOutTime) ? (
                      <span style={{ marginRight: 2 }}>
                        <FormattedMessage id="ClockedOut" />
                      </span>
                    ) : (
                      <span style={{ marginRight: 2 }}>
                        <FormattedMessage id="ClockOut" />
                      </span>
                    )}
                  </ButtonCustom>
                )}
                <div className="hour">
                  <Text
                    className={`text-bold-small text-color-violet ${theme}`}
                  >
                    {(lastClockIn?.clockOutTime &&
                      dayjs
                        .tz(
                          dayjs.utc(lastClockIn.clockOutTime),
                          dayjs.tz.guess()
                        )
                        .format('HH:mm')) ??
                      `--:-- `}
                  </Text>
                </div>
              </div>
            </>
          )}
        </Row>
      </Col>
    </>
  );
};
