import * as React from 'react';

const usePreviewMediaOverlayContext = () => {
  const [previewItem, setPreviewItem] = React.useState<React.ReactNode>(null);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState<boolean>();

  const closePreview = () => {
    setPreviewItem(null);
    setIsPreviewOpen(false);
  };

  const openPreview = (element: React.ReactNode) => {
    setPreviewItem(element);
    setIsPreviewOpen(true);
  };

  const closePreviewOnEscapePressed = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      closePreview();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keydown', closePreviewOnEscapePressed);

    return () => {
      document.removeEventListener('keydown', closePreviewOnEscapePressed);
    };
  }, []);
  return {
    closePreview,
    openPreview,
    isPreviewOpen,
    previewItem,
  };
};

const PreviewMediaOverlayContext = React.createContext(
  {} as ReturnType<typeof usePreviewMediaOverlayContext>
);

export const usePreviewMediaOverlay = () => {
  return React.useContext(PreviewMediaOverlayContext);
};

export const PreviewMediaOverlayProvider = ({ children }) => {
  return (
    <PreviewMediaOverlayContext.Provider
      value={usePreviewMediaOverlayContext()}
    >
      {children}
    </PreviewMediaOverlayContext.Provider>
  );
};
