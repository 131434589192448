import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { ColumnsType } from 'antd/es/table';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { ReportsDetailsToolbar } from './ReportsDetailsToolbar';
import { useReportsFilterContext } from './ReportsFilterContext';
import { ReportsTable } from './ReportsTable';

const ReportsDetails = ({ data, status }) => {
  const location = useLocation();
  const report = data;
  const {state} = location;
  const { deviceHeight } = useMobileContext();
  const {setReportType } = useReportsFilterContext();
  const cols = report.columns.map(item => item.name)

  const [reportData, setReportData] = useState({
    title: null,
    type: '',
  });


  useEffect(() => {
    if (location.pathname === '/reports/Vacations') {
      setReportData({
        title: <FormattedMessage id="Vacations" />,
        type: 'Vacations',
      });
      setReportType('Vacations');
    } else if (location.pathname === '/reports/PersonalLeaves') {
      setReportData({
        title: <FormattedMessage id="table_Reports_PersonalLeave_Title" />,
        type: 'PersonalLeaves',
      });
      setReportType('PersonalLeaves');
    }
  }, []);

  return (
    <>
      <ReportsDetailsToolbar state={state} defaultFilterOptions={report.filterOptions} cols={cols} reportType={reportData.type} />
      <br />
      <TableLayoutPage
        title={reportData.title}
        style={{
          height: `calc(${deviceHeight}px - 185px)`,
        }}
      >
        <ReportsTable
          columns={cols}
          data={data}
          reportType={reportData.type}
          status={status}
        />
      </TableLayoutPage>
    </>
  );
};

export default ReportsDetails;
