export const Filter = (props) => (
  <span role="img" aria-label="filter" className="filter_icon" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.6 12H17.4M4.2 7.2H19.8M10.2 16.8H13.8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
