export const DetailedViewIconSVG = () => {
  return (
    <span
      role="img"
      aria-label="detailedView"
      className="anticon anticon-detailedView"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M5.125 1V13M7.75 3.25H10M7.75 5.5H10M7.75 7.75H8.125M3.25 13H10.75C11.9926 13 13 11.9926 13 10.75V3.25C13 2.00736 11.9926 1 10.75 1H3.25C2.00736 1 1 2.00736 1 3.25V10.75C1 11.9926 2.00736 13 3.25 13Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};
