import React from 'react';
import { initialState, profileTimeOffReducer } from './reducers';
import { IProfileTimeOffContext } from './types';

const ProfileTimeOffProviderContext = React.createContext<IProfileTimeOffContext>(
  {} as IProfileTimeOffContext
);

export const ProfileTimeOffProvider = ({ children }) => {
  const [
    profileTimeOffState,
    profileTimeOffDispatch
  ] = React.useReducer(profileTimeOffReducer, initialState);

  return (
    <ProfileTimeOffProviderContext.Provider
      value={{ profileTimeOffState, profileTimeOffDispatch }}
    >
      {children}
    </ProfileTimeOffProviderContext.Provider>
  );
};

export const useProfileTimeOffContext = () => {
  return React.useContext(ProfileTimeOffProviderContext);
};
