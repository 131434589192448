export const Landscape = () => {
  return (
    <div className="container-orientation">
      <div
        role="img"
        aria-label="anticonLandscape"
        className="anticon orientation-landscape"
      >
        <svg
          viewBox="0 0 280 257"
          fill="none"
          className="transform-rotation"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M117.099 56.4912L114.191 58.3141C113.417 57.0864 110.845 56.9843 108.049 56.9843L77.1283 56.9843C73.5828 56.9843 71.5405 57.2184 70.9063 58.4511L67.8502 56.8872C69.563 53.5576 73.9349 53.5576 77.1283 53.5576L108.049 53.5576C111.259 53.5576 115.254 53.5576 117.102 56.4912H117.099Z"
            fill="white"
          />
          <path
            d="M90.3687 200.085C88.249 203.452 83.55 203.452 79.7724 203.452H69.4381C65.4782 203.452 60.5471 203.452 58.5846 199.634L61.6407 198.07C62.4846 199.714 65.0313 200.025 69.4356 200.025H79.7699C83.3677 200.025 86.4288 199.893 87.455 198.262L90.3637 200.085H90.3687Z"
            fill="white"
          />
          <path
            d="M126.33 200.085C124.21 203.452 119.511 203.452 115.734 203.452H105.399C101.44 203.452 96.5084 203.452 94.5459 199.634L97.602 198.07C98.4459 199.714 100.993 200.025 105.397 200.025H115.731C119.329 200.025 122.39 199.893 123.416 198.262L126.325 200.085H126.33Z"
            fill="white"
          />
          <path
            d="M280 78.9742V177.55C280 190.24 269.648 200.565 256.925 200.565L23.0753 200.565C10.3517 200.565 0 190.24 0 177.55V78.9742C0 66.2834 10.3517 55.9584 23.0753 55.9584L256.925 55.9584C269.648 55.9584 280 66.2834 280 78.9742ZM3.43307 177.55C3.43307 188.353 12.2442 197.139 23.0728 197.139L256.925 197.139C267.756 197.139 276.564 188.35 276.564 177.55V78.9742C276.564 68.171 267.753 59.3851 256.925 59.3851L23.0753 59.3851C12.2442 59.3851 3.43555 68.1735 3.43555 78.9742V177.55H3.43307Z"
            fill="white"
          />
          <path
            d="M273.131 79.0165V177.507C273.131 186.61 265.708 194.013 256.583 194.013L23.4124 194.013C14.2866 194.013 6.86369 186.61 6.86369 177.507V79.0165C6.86369 69.9168 14.2866 62.5105 23.4124 62.5105L256.583 62.5105C265.706 62.5105 273.131 69.9143 273.131 79.0165ZM8.58394 177.507C8.58394 185.663 15.2379 192.3 23.4149 192.3L256.583 192.3C264.76 192.3 271.414 185.663 271.414 177.507V79.0165C271.414 70.8606 264.76 64.2239 256.583 64.2239L23.4124 64.2239C15.2354 64.2239 8.5815 70.8606 8.5815 79.0165V177.507H8.58394Z"
            fill="white"
          />
          <path
            d="M228.596 35.9482C228.596 36.3691 228.441 36.7874 228.132 37.1186C227.483 37.8085 226.396 37.8458 225.705 37.1983C202.395 15.4202 171.956 3.42672 140 3.42672C108.044 3.42672 77.6053 15.4202 54.2952 37.1958C53.6036 37.8433 52.515 37.8085 51.8683 37.1162C51.2192 36.4263 51.2541 35.3405 51.9482 34.6955C75.8974 12.3222 107.17 -1.90735e-05 140.003 -1.90735e-05C172.835 -1.90735e-05 204.105 12.3222 228.057 34.6955C228.419 35.0317 228.601 35.49 228.601 35.9457L228.596 35.9482Z"
            fill="white"
          />
          <path
            d="M228.596 23.4419V35.9484C228.596 36.8947 227.827 37.6617 226.878 37.6617H214.34C213.391 37.6617 212.622 36.8947 212.622 35.9484C212.622 35.002 213.391 34.235 214.34 34.235H225.161V23.4419C225.161 22.4955 225.93 21.7285 226.878 21.7285C227.827 21.7285 228.596 22.4955 228.596 23.4419Z"
            fill="white"
          />
          <path
            d="M228.596 220.575C228.596 221.034 228.414 221.489 228.052 221.826C204.103 244.199 172.83 256.521 140 256.521C107.17 256.521 75.8973 244.199 51.9456 221.826C51.254 221.178 51.2166 220.095 51.8657 219.405C52.5149 218.715 53.601 218.678 54.2926 219.325C77.6052 241.101 108.044 253.094 140 253.094C171.956 253.094 202.392 241.101 225.705 219.323C226.396 218.675 227.485 218.713 228.132 219.402C228.441 219.734 228.596 220.155 228.596 220.573V220.575Z"
            fill="white"
          />
          <path
            d="M67.3784 220.575C67.3784 221.522 66.6094 222.289 65.6606 222.289H54.8395V233.082C54.8395 234.028 54.0705 234.795 53.1217 234.795C52.1729 234.795 51.4039 234.028 51.4039 233.082V220.575C51.4039 219.629 52.1729 218.862 53.1217 218.862H65.6606C66.6094 218.862 67.3784 219.629 67.3784 220.575Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
