import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { EventsStatusDefinitions, legentColors } from '../types';
import { ModalToolTip } from './modalToolTip';
import { redirect } from 'react-router-dom';

//get colors for header by event like holiday, weekend or ordinary day
export const getHeaderColorColumnByEvent = (events, date) => {
  if (events.some(event => event.eventType === 'Holiday')) {
    return 'th_holiday';
  } else if (dayjs(date).day() === 0 || dayjs(date).day() === 6) {
    return 'th_weekend';
  } else {
    return 'vacation_fixed_cell';
  }
};

export const columnsVacation = params => {
  const eventsByDay = params?.eventsByDay;
  const theme = params?.theme;

  return [
    {
      title: (
        <div className="th_employee_details text-bold-big txt_color vacation_fixed_cell">
          <FormattedMessage id="table_colleaguesCol_Full_Name" />
        </div>
      ),
      dataIndex: 'firstName',
      key: 'firstName',
      width: 200,
      render: (_, record) => (
        <Tooltip
          overlayClassName="tooltip-vacation"
          title={
            <>
              {record.lastName} {record.firstName}
            </>
          }
        >
          <div className="td_employee_details text-medium-big txt_color vacation_fixed_cell ">
            <div className="truncate">
              {record.lastName} {record.firstName}
            </div>
          </div>
        </Tooltip>
      )
    },
    ...datesColumns(eventsByDay, theme)
  ];
};

const datesColumns = (eventsByDay, theme) => {
  const currentDate = dayjs();

  const dates = Object.keys(eventsByDay);
  const dayName = date => dayjs(date).format('dd');
  return dates.map(date => ({
    title: (
      <div
        className={`th_days_name text-bold-normal txt_color ${getHeaderColorColumnByEvent(
          eventsByDay[date],
          date
        )}`}
      >
        {' '}
        <div style={{ width: '20px' }}>{dayName(date)}</div>
      </div>
    ),
    key: date,
    className: `${
      currentDate.isSame(dayjs(date), 'day') ? 'current_day_in_month' : ''
    }`,
    render: (_, record) => {
      const events = eventsByDay[date].filter(
        event =>
          event.employeeId === record.employeeId ||
          event.eventType === 'Holiday'
      );

      const placement = dayjs(date).isSame(dayjs(date).endOf('month'), 'day')
        ? 'topRight'
        : 'top';
      return {
        cellRowClassName: `${
          currentDate.isSame(dayjs(date), 'day') ? 'current_day_in_month' : ''
        } ${
          eventsByDay[date].some(event => event.eventType === 'Holiday')
            ? 'publicHoliday'
            : dayjs(date).day() === 0 || dayjs(date).day() === 6
            ? 'td_weekend'
            : ''
        }`,
        content: (
          <>
            {events.length > 0 ? (
              <Tooltip
                overlayClassName="red"
                overlayInnerStyle={{ boxShadow: 'none' }}
                showArrow={false}
                color="none"
                placement={placement}
                align={{ offset: [0, 8] }}
                title={<ModalToolTip eventsData={events} />}
              >
                <div
                  key={date}
                  data-col={date}
                  style={{
                    background: getCellColor(events, date, theme)
                  }}
                  className="td_days_number text-medium-normal txt_color vacation_ordinary"
                >
                  {new Date(date).getDate()}
                </div>
              </Tooltip>
            ) : (
              <div
                key={date}
                style={{
                  background: getCellColor(events, date, theme)
                }}
                data-col={date}
                className="td_days_number text-medium-normal txt_color vacation_ordinary"
              >
                {new Date(date).getDate()}
              </div>
            )}
          </>
        )
      };
    }
  }));
};

const getCellColor = (events, date, theme) => {
  let result = null;
  if (
    events.length > 1
  ) {
    const OverlappingEventsColor = legentColors.find(
      request => request.request === 'OverlappingEvents'
    )?.color;
    return theme === 'light' ? OverlappingEventsColor : OverlappingEventsColor;
  } else if (
    events.length > 0 &&
    events.some(item => item.requestStatus !== 'Confirmed') &&
    !events.some(item => item.eventType === 'Holiday') 
  ) {
    const PendingRequest = legentColors.find(
      request => request.request === 'PendingRequest'
    )?.color;
    return theme === 'light' ? PendingRequest : PendingRequest;
  } else if (
    events.length > 0 &&
    events.some(item => item.requestStatus === 'Confirmed') &&
    !events.some(item => item.eventType === 'Holiday')
  ) {
    result = events.map(item => {
      return theme === 'light'
        ? EventsStatusDefinitions[item.eventType]?.cellColor ?? '#46FFB1'
        : EventsStatusDefinitions[item.eventType]?.darkcellColor ?? '#46FFB1';
    });

    return result;
  }
  return null;
};

