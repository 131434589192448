import React from 'react';

type TimeOffDeleteIconProps = {
  stroke?: string;
};

export const TimeOffDeleteIcon: React.FC<TimeOffDeleteIconProps> = ({
  stroke
}) => {
  const currentColor = 'currentColor';
  const strokeColor = stroke ? stroke : currentColor;

  return (
    <svg
      width="14"
      height="18"
      viewBox="0 -1 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="trash_icon"
    >
      <path
        d="M1.35291 3.11765H12.647M4.88232 1H9.11761M5.5882 10.1765V5.94118M8.41173 10.1765V5.94118M9.47055 13H4.52938C3.74968 13 3.11761 12.3679 3.11761 11.5882L2.79531 3.85292C2.7786 3.45189 3.0992 3.11765 3.50058 3.11765H10.4994C10.9007 3.11765 11.2213 3.45189 11.2046 3.85292L10.8823 11.5882C10.8823 12.3679 10.2502 13 9.47055 13Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
