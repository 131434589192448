import { useModalContext } from 'components/Modal/ModalProvider';

const LayoutPageMobileComponent = () => {
  const { setIsTopRoleModalOpen } = useModalContext();

  return (
    <button
      className="mobile-dots-button"
      onClick={() => setIsTopRoleModalOpen(true)}
    >
      <svg
        width="6"
        height="16"
        viewBox="0 0 6 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.00002 4.8001C2.11637 4.8001 1.40002 4.08375 1.40002 3.2001C1.40002 2.31644 2.11637 1.6001 3.00002 1.6001C3.88368 1.6001 4.60002 2.31644 4.60002 3.2001C4.60002 4.08375 3.88368 4.8001 3.00002 4.8001Z"
          stroke="#BDA7E8"
          strokeWidth="1.5"
        />
        <path
          d="M3.00002 9.6001C2.11637 9.6001 1.40002 8.88375 1.40002 8.0001C1.40002 7.11644 2.11637 6.4001 3.00002 6.4001C3.88368 6.4001 4.60002 7.11644 4.60002 8.0001C4.60002 8.88375 3.88368 9.6001 3.00002 9.6001Z"
          stroke="#BDA7E8"
          strokeWidth="1.5"
        />
        <path
          d="M3.00002 14.4001C2.11637 14.4001 1.40002 13.6838 1.40002 12.8001C1.40002 11.9164 2.11637 11.2001 3.00002 11.2001C3.88368 11.2001 4.60002 11.9164 4.60002 12.8001C4.60002 13.6838 3.88368 14.4001 3.00002 14.4001Z"
          stroke="#BDA7E8"
          strokeWidth="1.5"
        />
      </svg>
    </button>
  );
};

export default LayoutPageMobileComponent;
