import { responseError, responseWithData } from 'services/mappers/apiResponse';
import { notifyWithAxiosAPIError } from 'utils/notificationsUtils';

export const analyzePromise = (resolve, reject, result) => {
  result
    .then((res) => {
      resolve(responseWithData(res.data, res.headers));
    })
    .catch((axiosError) => {
      //----https://jivygroupmd.atlassian.net/browse/IN-783
      if (axiosError?.response?.data?.errors?.length > 0) {
        axiosError?.response?.data?.errors?.map((err) => {
          if (err?.field === 'EmailUpdateLogout') {
            window.location.href = '/logout';
          }
        });
      }
      //-----
      const errorResponse = axiosError.response
        ? responseError(axiosError.response?.data)
        : axiosError;

      errorResponse.errorMessage = axiosError?.message ?? axiosError;
      notifyWithAxiosAPIError(errorResponse);
      reject(errorResponse);
    });
};
