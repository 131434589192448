import { useEffect, useRef } from 'react';
import { DatePicker } from 'antd';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
const { RangePicker } = DatePicker;
export default function DateRangePicker(props) {
  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);
  const { theme } = useThemeContext();

  useEffect(() => {
    const wrapper = document?.querySelector(
      '#custom-ant-date-range-picker-wrapper'
    );
    const pickerInputsHTML = wrapper?.querySelectorAll('.ant-picker-input');

    startDateInputRef.current = pickerInputsHTML && pickerInputsHTML[0];
    endDateInputRef.current = pickerInputsHTML && pickerInputsHTML[1];
    const config = { attributes: true, attributeOldValue: true };

    // Callback function to execute when mutations are observed
    const OnActiveInputClassObserver = (callback) => (mutationList) => {
      for (let mutation of mutationList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          if (
            mutation.target.classList.contains('ant-picker-input-active') &&
            !mutation.oldValue.includes('ant-picker-input-active')
          ) {
            callback();
          }
        }
      }
    };

    const startDatePickerObserver = new MutationObserver(
      OnActiveInputClassObserver(props?.onStartDateSelected)
    );
    const endDatePickerObserver = new MutationObserver(
      OnActiveInputClassObserver(props?.onEndDateSelected)
    );

    startDatePickerObserver.observe(startDateInputRef.current, config);
    endDatePickerObserver.observe(endDateInputRef.current, config);

    //cleanup
    return () => {
      // Stop observing
      startDatePickerObserver.disconnect();
      endDatePickerObserver.disconnect();
    };
  }, []);

  return (
    <div id="custom-ant-date-range-picker-wrapper">
      <RangePicker popupClassName={theme} {...props} />
    </div>
  );
}
