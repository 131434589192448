import * as React from 'react';

import { useAuth } from 'services/providers/AuthProvider';
import SplideCustomCarousel from 'components/SplideCarousel';
import { PolicyConfig } from 'AppProvider/ConfigManager/config';
import { useCompanySettings } from 'settings';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';
// import MobileCardRequest from 'components/UIComponents/CardRequest/mobile';
import MobileCardRequest from 'components/UIComponents/MobileCardRequest';

interface IMobileRequestsProps {
  data: any;
  scope?: string;
  openModal?: any;
  setModalOpen?: any;
  setModalProps?: any;
  requestFormProps?: any;
}

interface IRequestItem {
  icon: any;
  title: string;
  request: any;
  allowUnfinishedRequests: boolean;
}

const MobileRequests: React.FC<IMobileRequestsProps> = props => {
  const {
    data,
    scope,
    openModal,
    setModalOpen,
    setModalProps,
    requestFormProps
  } = props;

  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const { countryCode } = useCompanySettings();
  const isAdminUserRole = allowPermision(userRole, adminRoles);

  const [selectedItem, setSelectedItem] = React.useState<number>(0);

  const filteredRequests = PolicyConfig[
    countryCode
  ]?.DefaultTimeOffRequests.filter(
    request =>
      !request.isHidden &&
      (isAdminUserRole ? true : !request.isAdminOnly) &&
      request.isSpecialRequest !== true
  );

  const specialFiltredRequests = PolicyConfig[
    countryCode
  ]?.DefaultTimeOffRequests.filter(
    request =>
      !request.isHidden &&
      (isAdminUserRole ? true : !request.isAdminOnly) &&
      request.isSpecialRequest === true
  );

  let content = filteredRequests?.map((request: IRequestItem, index: number) => {
    return (
      <MobileCardRequest
        key={index}
        scope={scope}
        selecteditem={selectedItem}
        setselecteditem={setSelectedItem}
        openModal={openModal}
        setModalOpen={setModalOpen}
        setModalProps={setModalProps}
        index={index}
        icon={request.icon}
        title={request.title}
        request={request.request}
        allowUnfinishedRequests={request.allowUnfinishedRequests}
        data={data}
        filteredrequests={specialFiltredRequests}
      />
    );
  });

  return (
    <div
      // ref={parentRef}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <SplideCustomCarousel

        options={{
          easing: 'ease-in-out',
          speed: 800,
          perMove: 1,
          perPage: 1,
          drag: false
        }}
        type={2}
      >
        {content}
      </SplideCustomCarousel>
    </div>
  );
};

export default MobileRequests;
