import { FormattedMessage } from 'react-intl';
import { EmployeeServices } from 'services';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import { splitCamelCaseWordIntoWords } from 'utils/stringOperationsManager';

export const onFinish = (
  scope,
  data,
  employeeId,
  actions,
  isAdminUserRole: boolean,
  files
) => {
  data = { employeeId, ...data };

  const onFinishActions = {
    add: EmployeeServices.addEmployee,
    edit: EmployeeServices.updateEmployee,
    ColleagueProfile: EmployeeServices.updateEmployee,
    editMyProfile: isAdminUserRole
      ? EmployeeServices.updateMyProfileExtended
      : EmployeeServices.updateMyProfile
  };

  const formData = new FormData();
  if (files && files.length > 0) {
    formData.append(`files`, files[0] as Blob);
  }

  const jsonData = { employeeUpdate: data };

  if (jsonData && Object.keys(jsonData).length) {
    for (const key in jsonData) {
      formData.append(key, JSON.stringify(jsonData[key]));
    }
  }

  const getMessages =
    scope === 'add'
      ? getSuccessMessages.success_added_profile
      : scope === 'editMyProfile'
      ? getSuccessMessages.success_updated_profile
      : getSuccessMessages.success_updated_user;

  onFormFinishWithPassedValidation(
    onFinishActions,
    scope,
    formData,
    actions,
    getMessages
  );
};

export const getCurrentEmployeePosition = (position: string) => {
  switch (position) {
    case 'OfficeAdmin':
      return 'Office Admin, HR Manager';
    case 'BusinessAnalyst':
    case 'QuantitativeAnalyst':
    case 'DevelopmentManager':
    case 'TechSupport':
    case 'ProjectManager':
      return splitCamelCaseWordIntoWords(position);
    case 'HRSpecialist':
      return 'HR Specialist';
    case 'QAEngineer':
      return 'QA Engineer';
    case 'UIDesign':
      return 'UI Design';
    case 'ChiefExecutiveOfficer':
      return 'Chief Executive Officer';
    case 'QualityAssuranceManager':
      return 'Quality Assurance Manager';
    case 'OfficeManager':
      return 'Office Manager';
    case 'DigitalDeveloper':
      return 'Digital Developer';
    case 'QualityAnalyst':
      return 'Quality Analyst';
    default:
      return position;
  }
};

export const worksScheduleEmployee = [
  {
    title: 'Full time',
    dataIndex: 'FullTime'
  },
  {
    title: 'Hourly',
    dataIndex: 'Hourly'
  },
  {
    title: 'Part Time',
    dataIndex: 'PartTime'
  },
  {
    title: 'Shifts',
    dataIndex: 'Shifts'
  }
];

export const contractTypeEmployee = [
  {
    title: 'Contract individual de muncă - perioadă nedeterminată',
    dataIndex: 'LabourContractInfinite'
  },
  {
    title: 'Contract individual de muncă - perioadă determinată ',
    dataIndex: 'LabourContractEndDate'
  },
  {
    title: 'Contract de ucenicie (de practică)',
    dataIndex: 'InternshipContract'
  },
  {
    title: 'Contract de muncă prin cumul',
    dataIndex: 'CumulativeLabourContract'
  },
  {
    title: 'Contract de prestări servicii ale persoanei fizice',
    dataIndex: 'ServicesAgreement'
  },
  {
    title: 'LabourContractRemote',
    dataIndex: 'LabourContractRemote'
  },
  {
    title: 'LabourContractHomeInfinite',
    dataIndex: 'LabourContractHomeInfinite'
  },
  {
    title: 'LabourContractHomeEndDate',
    dataIndex: 'LabourContractHomeEndDate'
  },
  {
    title: 'LabourContractForDisadvantagedYouth',
    dataIndex: 'LabourContractForDisadvantagedYouth'
  }
];

export const statusEmployee = [
  {
    title: <FormattedMessage id="None" />,
    dataIndex: 'None'
  },
  {
    title: <FormattedMessage id="Onboarding" />,
    dataIndex: 'OnBoarding'
  },
  {
    title: <FormattedMessage id="Internship" />,
    dataIndex: 'Internship'
  },
  {
    title: <FormattedMessage id="Employee" />,
    dataIndex: 'Employee'
  },
  {
    title: <FormattedMessage id="External" />,
    dataIndex: 'External'
  },
  {
    title: <FormattedMessage id="MaternityChildCareLeave" />,
    dataIndex: 'MaternityChildCareLeave'
  },
  {
    title: <FormattedMessage id="Deactivated" />,
    dataIndex: 'Deactivated'
  }
];

export const departaments = [
  {
    title: <FormattedMessage id="None" />,
    dataIndex: 'None'
  },
  {
    title: 'Software development',
    dataIndex: 'SoftwareDevelopment'
  },

  {
    title: 'Quality assurance',
    dataIndex: 'QualityAssurance'
  },
  {
    title: 'DevOps',
    dataIndex: 'DevOps'
  },

  {
    title: 'Software architecture and business analysis',
    dataIndex: 'SoftwareArchitectureAndBusinessAnalysis'
  },
  {
    title: 'HR',
    dataIndex: 'HR'
  },
  {
    title: 'Administrativ',
    dataIndex: 'Administrative'
  },
  {
    title: 'Analiza',
    dataIndex: 'Analysis'
  },
  {
    title: 'Dezvoltare',
    dataIndex: 'Development'
  },
  {
    title: 'Testare',
    dataIndex: 'Testing'
  }
];

export const userRole = [
  {
    title: <FormattedMessage id="None" />,
    dataIndex: 'None'
  },
  {
    title: <FormattedMessage id="employeeFormOptionForRole_Accountant" />,
    dataIndex: 'Accountant'
  },

  {
    title: <FormattedMessage id="employeeFormOptionForRole_Admin" />,
    dataIndex: 'Admin'
  },
  {
    title: <FormattedMessage id="employeeFormOptionForRole_Employee" />,
    dataIndex: 'Employee'
  },

  {
    title: <FormattedMessage id="employeeFormOptionForRole_External" />,
    dataIndex: 'External'
  },
  {
    title: <FormattedMessage id="employeeFormOptionForRole_HR" />,
    dataIndex: 'HR'
  },
  {
    title: <FormattedMessage id="employeeFormOptionForRole_Intern" />,
    dataIndex: 'Intern'
  },
  {
    title: <FormattedMessage id="employeeFormOptionForRole_TL" />,
    dataIndex: 'TL'
  }
];

export enum UsersRoles {
  None = 'None',
  Accountant = 'employeeFormOptionForRole_Accountant',
  Admin = 'employeeFormOptionForRole_Admin',
  Employee = 'employeeFormOptionForRole_Employee',
  External = 'employeeFormOptionForRole_External',
  HR = 'employeeFormOptionForRole_HR',
  Intern = 'employeeFormOptionForRole_Intern',
  TL = 'employeeFormOptionForRole_TL'
}
