export const UserProfileMinus = ({ stroke }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L1.00025 10.7497C1.00039 9.5072 1.99221 8.5 3.21563 8.5H7.27692M13 9.25H9.67692M8.38462 3.25C8.38462 4.49264 7.39275 5.5 6.16923 5.5C4.94571 5.5 3.95385 4.49264 3.95385 3.25C3.95385 2.00736 4.94571 1 6.16923 1C7.39275 1 8.38462 2.00736 8.38462 3.25Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
