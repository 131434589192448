export class LocalStorageTableHelper {
  private static localStoragePaginationKey = 'tablePagination';

  public static setPaginationTableSize = (
    tableName: string,
    tableSize: number
  ) => {
    if (tableName.includes(' ')) tableName = tableName.split(' ')[0];
    var paginationObject = this.getPaginationObject();
    if (paginationObject[tableName] !== `${tableSize}`) {
      paginationObject[tableName] = `${tableSize}`;
      localStorage.setItem(
        this.localStoragePaginationKey,
        JSON.stringify(paginationObject)
      );
    }
  };

  public static getPaginationTableSize = (
    tableName: string,
    totalRows: number
  ): number => {
    if (tableName.includes(' ')) tableName = tableName.split(' ')[0];
    return this.getPaginationObject()[tableName] ?? (totalRows >= 10 ? 20 : 10);
  };

  private static getPaginationObject = () => {
    let paginationObject: Object;
    try {
      paginationObject = JSON.parse(
        localStorage.getItem(this.localStoragePaginationKey) || '{}'
      );
    } finally {
      return paginationObject || {};
    }
  };
}
