export const loadState = (key: string) => {
  try {
    const stringifiedState = localStorage.getItem(key);
    if (stringifiedState === null) {
      return undefined;
    };
    return stringifiedState;
  } catch (error) {
    return undefined;
  };
};

export const saveState = (key: string, state) => {
  const currentState = loadState(key);
  const serializedState = JSON.stringify(state);
  if (currentState && serializedState && currentState === serializedState) {
    return;
  };
  
  localStorage.setItem(key, serializedState);
};
