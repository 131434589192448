import { useAuth } from 'services/providers/AuthProvider';
import { FC, Dispatch, SetStateAction, useState } from 'react';
import {
  allowPermision,
  rolesAllow,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import TableComponent from 'components/Table';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import { useNavigate } from 'react-router-dom';
import { SoftwareListComponent } from 'Icons/Inventory/softwareListComponent';
import { HardwareListComponent } from 'Icons/Inventory/hardwareListComponent';
import { columnsMobileHardware } from './columnsMobileHardware';
import { columnsHardware } from './columnsHardware';
import { useIntl } from 'react-intl';
import CustomSwitch from '../../../components/Switch';
import { useMobileContext } from '../../../AppProvider/ConfigProviderSettings';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableComponentPlaceholder } from 'components/Placeholders';

type HardwareTableProps = {
  isModalOpen?: boolean;
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
  stateActions?: any;
  params?: any;
  hardwareData?: any[];
  results?: any[];
  status?: string;
};

const HardwareTable: FC<HardwareTableProps> = ({
  stateActions,
  isModalOpen,
  params,
  hardwareData,
  results,
  setIsModalOpen,
  status
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { isMobile, deviceOS, deviceHeight } = useMobileContext();

  const role = getCurrentUserRole(user);

  const intl = useIntl();

  const hardwareListTitle = intl.formatMessage({
    id: 'table_hardwareTitle_Hardware_list'
  });

  const [totalRows, setTotalRows] = useState(0);

  return (
    <TableLayoutPage
      displayToggle={
        !allowPermision(role, [userRoles.Admin, userRoles.Accountant]) ||
        !isMobile
      }
      isSwitchChecked
      displayButton={allowPermision(role, [
        userRoles.Admin,
        userRoles.Accountant
      ])}
      style={{
        height: `calc(${deviceHeight}px - 185px)`
      }}
      title={`${hardwareListTitle} (${totalRows ?? 0})`}
      childrenButton={
        <CustomSwitch
          values={[
            {
              id: 0,
              value: 'Software',
              icon: <SoftwareListComponent />
            },
            {
              id: 1,
              value: 'Hardware',
              icon: <HardwareListComponent />
            }
          ]}
          defaultValue={'Hardware'}
          onChange={() => {
            localStorage.setItem('displayHardwareList', 'software');
            navigate('/inventory/software');
          }}
        />
      }
    >
      {isMobile ? (
        <ComponentWithStatus
          status={status}
          Placeholder={<TableComponentPlaceholder count={2} />}
        >
          <TableComponent
            style={{
              minHeight: `calc(${deviceHeight}px - 22em)`
            }}
            scroll={
              !isMobile
                ? {
                  x: 'calc(100vw - 220px)',
                  y: `calc(${deviceHeight}px - 42em)`
                }
                : {
                  x: 'calc(100vw - 213px)',
                  y: `calc(${deviceHeight}px - 24.1em)`
                }
            }
            baseTitle={hardwareListTitle}
            rowKey="assetId"
            columns={columnsMobileHardware}
            params={params}
            data={hardwareData}
            role={role}
            dataSource={hardwareData}
            rowClassName={allowPermision(role, rolesAllow) ? 'hoverRow' : ''}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => {
              if (!isModalOpen) {
                setIsModalOpen(!isModalOpen);
              }
            }}
            hookUseStateActions={stateActions}
            setTotalRows={setTotalRows}
            className="hardwareTable"
          />
        </ComponentWithStatus>
      ) : (
        <ComponentWithStatus
          status={status}
          Placeholder={<TableComponentPlaceholder count={8} />}
        >
          <TableComponent
            scroll={{
              x: 'calc(100vw - 213px)',
              y: 'calc(100vh - 332px)'
            }}
            style={{
              height: 'calc(100vh - 24.5em)',
              minHeight: 'calc(100vh - 24.5em)'
            }}
            baseTitle={hardwareListTitle}
            rowKey="assetId"
            columns={columnsHardware}
            params={params}
            data={hardwareData}
            role={role}
            dataSource={hardwareData}
            rowClassName={allowPermision(role, rolesAllow) ? 'hoverRow' : ''}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => {
              if (!isModalOpen) {
                setIsModalOpen(!isModalOpen);
              }
            }}
            hookUseStateActions={stateActions}
            className="hardwareTable"
            setTotalRows={setTotalRows}
          />
        </ComponentWithStatus>
      )}
    </TableLayoutPage>
  );
};

export default HardwareTable;
