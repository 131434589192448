import { CloseOutlined } from '@ant-design/icons';
import { usePreviewMediaOverlay } from './context';
import styles from './previewMediaOverlay.module.less';

const PreviewMediaOverlay = () => {
  const { previewItem, closePreview } = usePreviewMediaOverlay();
  return (
    <div className={styles.wrap} onClick={() => closePreview()}>
      <CloseOutlined
        className={styles.closeBtn}
        onClick={() => closePreview()}
      />
      <div className={styles.previewItem}>{previewItem}</div>
    </div>
  );
};
export default PreviewMediaOverlay;
