import { titlesKeysofColumnsColleagues } from 'components/AllUsersList/Table/utils';
import {
  departaments,
  statusEmployee,
  worksScheduleEmployee,
  contractTypeEmployee,
  userRole
} from 'components/Employee/EmployeeForm/utils';
import { MedicalTypeOptions, timeOffPoliciesTitles } from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm/utils';
import { titlesKeyColumnsPersonalRequests } from 'components/TimeManagement/TimeOffRequest/TimeOffRequestTable/utils';
import { titlesKeysofColumnsHardware } from 'pages/Inventory/Hardware/utils';
import {
  frequencySoftware,
  titlesKeysofColumnsSoftware,
} from 'pages/Inventory/Software/utils';
import { titlesKeysofColumnsTeams } from 'pages/Team/TeamsPage/utils';
import { titlesKeysofColumnsMembers } from 'pages/Team/ViewTeamPage/utils';

export const getTitleColumns = (keyColumn, dataTitles) => {
  return (
    columnsTitles[dataTitles].filter(
      (item) => item.dataIndex === keyColumn ?? item
    )[0]?.title ?? ''
  );
};
export const getKeyTitleColumns = (titleColumn, dataTitles) => {
  return (
    columnsTitles[dataTitles].filter(
      (item) => item.title === titleColumn ?? item
    )[0]?.dataIndex ?? ''
  );
};
const columnsTitles = {
  colleagues: titlesKeysofColumnsColleagues,
  software: titlesKeysofColumnsSoftware,
  hardware: titlesKeysofColumnsHardware,
  teams: titlesKeysofColumnsTeams,
  members: titlesKeysofColumnsMembers,
  personalRequests: titlesKeyColumnsPersonalRequests,
};

/// for dropdowns
export const getTitleSelects = (keyColumn, dataTitles) => {
  return (
    selectsTitles[dataTitles].filter(
      (item) => item.dataIndex === keyColumn ?? item
    )[0]?.title ?? ''
  );
};
export const getKeySelects = (titleColumn, dataTitles) => {
  return (
    selectsTitles[dataTitles].filter(
      (item) => item.title === titleColumn ?? item
    )[0]?.dataIndex ?? ''
  );
};
export const getKeyOrTitleSelects = (titleOrKeyColumn, dataTitles) => {
  return (
    selectsTitles[dataTitles].filter((item) => {
      return item.title === titleOrKeyColumn
        ? item.title === titleOrKeyColumn ?? item
        : item.dataIndex === titleOrKeyColumn ?? item;
    })[0]?.title ?? ''
  );
};
const selectsTitles = {
  workShedule: worksScheduleEmployee,
  statusEmployee: statusEmployee,
  departaments: departaments,
  frequencySoft: frequencySoftware,
  timeOffPolicies: timeOffPoliciesTitles,
  contractType: contractTypeEmployee,
  userRole: userRole,
  medicalType: MedicalTypeOptions,
};

export const getColumns = (array, data) =>
  array.filter((column) =>
    Object.keys(data.length && data[0]).some((key) => key === column.dataIndex)
  );
