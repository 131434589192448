import EmployeeForm from 'components/Employee/EmployeeForm';
import { FC } from 'react';

type AddEmployeePageProps = { employeeId?: number | string };

const AddEmployeePage: FC<AddEmployeePageProps> = ({ employeeId }) => {
  return <EmployeeForm scope='add' employeeId={employeeId} />;
};

export default AddEmployeePage;
