import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { IModalComponentProps } from 'components/Modal';
import { WorkerForm } from 'components/SetitingsComponents/WorkQueue/WorkerForm';
import ThemedIcon from 'components/UIComponents/Icon';
import { FormattedMessage } from 'react-intl';
import _sort from 'utils/sorter';

export const useWorkersTableColumns = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<IModalComponentProps>>,
  editModalWidth: number | string,
  deleteModalWidth: number | string,
  queueIdFromURL: number
): ColumnsType<any> => {
  const { isMobile, isTabletDevice } = useMobileContext();
  // Row Actions
  // Remove category open modal and set modal props
  const handleDeleteClick = record => {
    setModalProps(state => ({
        ...state,
        key:'workerFormModal',
        formKeyId: 'workerForm',
        cancelButtonText: <FormattedMessage id="cancel" />,
        submitButtonText: <FormattedMessage id="remove" />,
        modalTitle: <FormattedMessage id="Settings_WorkQueues__Modal_title_Remove_Worker" />,
        width: deleteModalWidth,
        children: (
          <WorkerForm 
            scope={"remove"}
            workerId={record?.employeeId}
            queueId={Number(queueIdFromURL)}
            workerName={`${record?.firstName} ${record?.lastName}`}
            workers={[]}
          />
        )
      }));
    setIsOpen(true);
  };

  //------End Row Actions
  const tableColumns = [
    {
      title:  <FormattedMessage id="table_colleaguesCol_Full_Name" />,
      dataIndex: 'firstName',
      sorter: (a, b) => _sort(a.lastName, b.lastName, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => 
     <p> {record.firstName + ' ' + record.lastName}</p>
    },
    {
      title: <FormattedMessage id="colleagues_tableCol_Email" />,
      sorter: (a, b) => _sort(a.email, b.email, 'string'),
      dataIndex: 'email',
      width: isTabletDevice || isMobile ? '10%' : '35%',
 
      render: (_, record) => (
        <>
       
            <p>{record?.email}</p>
        </>
      )
    },
    {
      title: <FormattedMessage id="colleagues_tableCol_Position" />,
      dataIndex: 'position',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      className: 'hoverEdit',
      render: (_, record) => (
        <>
       
            <p><FormattedMessage id={record?.position} /></p>
            <div className={'showEdit'} style={{ display: 'none' }}>
            <div className="actionButtons">
              <ButtonWithExpandedText
                embedSrc={<ThemedIcon icon={TimeOffDeleteIcon} />}
                displayedText={<FormattedMessage id="remove" />}
                type="link"
                onClick={() => handleDeleteClick(record)}
              />
            </div>
          </div>
         
        </>
      )
    }
  ];
  return [...tableColumns];
};
