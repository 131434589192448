import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Form, Select } from 'antd';
import { SelectDropdown } from 'components/Dropdown';
import { useModalContext } from 'components/Modal/ModalProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
//   import { onFinish } from './utils';
import FormItem from 'antd/es/form/FormItem';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import { onFinish } from './utils';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { useState } from 'react';
import { EmployeeServices } from 'services';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { ConfigurationFormPlaceholder } from 'components/Placeholders/Page';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import { getCurrentEmployeePosition } from 'components/Employee/EmployeeForm/utils';
type WorkerFormProps = {
  scope: string;
  workerId?: number | null;
  queueId: number | null;
  workers?: any[];
  workerName?: string | null;
};

export const WorkerForm: React.FC<WorkerFormProps> = ({
  scope,
  workerId,
  queueId,
  workers,
  workerName
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { toggleModal } = useModalContext();
  const [form] = Form.useForm();

  const { theme } = useThemeContext();
  const onFinishFormActions = { dispatch, toggleModal };

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

const { data : employees, status } = useApiRequestHook<any[]>(
    EmployeeServices.getAllEmployeesList,
    'true',
    [],
    {},
    null,
    [],
    scope !== 'add',
    false
  );
  
  const workerIds = new Set(workers?.map(worker => worker?.employeeId));
  const filteredEmployees = employees?.filter(
    employee => !workerIds.has(employee?.employeeId)
  );

  const ITEM_HEIGHT = 37;
  const MAX_ITEMS = 4;

  const listHeight =
    filteredEmployees?.length > MAX_ITEMS
      ? ITEM_HEIGHT * MAX_ITEMS
      : ITEM_HEIGHT * filteredEmployees?.length;

  return (
    <Form
      form={form}
      id="workerForm"
      layout="horizontal"
      style={{ width: '100%', maxWidth: '100%' }}
      labelAlign="left"
      labelCol={{ span: 6 }}
      onFinish={data => {
        const fomValues = {
          ...data,
          employeeId: scope === 'remove' ? workerId : data?.employeeId,
          workQueueId: queueId
        };
        onFinish(scope, fomValues, onFinishFormActions);
      }}
      onFinishFailed={validationError =>
        onFormFinishFailedValidation(validationError, dispatch)
      }
    >
      {scope === 'remove' ? (
        <>
          <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
            <FormattedMessage
              id="WorkerRemoveConfirmText"
              values={{
                workerName: workerName
              }}
            />
          </p>
        </>
      ) : (
        <ComponentWithStatus
        ignoreAnimation={true}
        status={status}
        Placeholder={<ConfigurationFormPlaceholder />}
      >
        <>
                    <div
              className="member-select"
              style={{
                ...{
                  minHeight: isDropdownOpened
                    ? `${listHeight + ITEM_HEIGHT}px`
                    : `0px`
                }
              }}
            >

          <ColFormItemComponent
            span={24}
           
            name="employeeId"
            className={`col-form-item-component setting_worker ${theme}`}

            >
            <SelectDropdown
              showSearch={true}
              allowClear={true}
              placement_bottom={true}
              onDropdownVisibleChange={() =>
                setTimeout(
                  () => setIsDropdownOpened(!isDropdownOpened),
                  300
                )
              }
              open={isDropdownOpened}
              filterOption={(input, option) => {
           
                
                return (option.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {filteredEmployees.map((employee, index) => {
                const employeeId = employee?.employeeId;

                return (
                  
                  <Select.Option key={index} value={employeeId}>
                   {`${employee?.lastName} ${employee?.firstName},  ${getCurrentEmployeePosition(employee?.position)}`}
                  </Select.Option>
                );
              })}
            </SelectDropdown>
            </ColFormItemComponent>
            </div>
        </>
        </ComponentWithStatus>
      )}
    </Form>
  );
};
