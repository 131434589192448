export const gradients = [
  'radial-gradient(circle, #f25fa6, #ee559e, #ea4b96, #e6408d, #e23485, #df2d80, #dc257c, #d91c77, #d71a75, #d51872, #d21570, #d0136e)',
  'radial-gradient(circle, #8ded23, #85df22, #7dd221, #76c41f, #6eb71e, #69af1c, #64a71b, #5f9f19, #5b9b16, #589713, #549310, #518f0d)',
  'radial-gradient(circle, #356eda, #2f64c8, #295ab7, #2350a6, #1d4695, #1a418d, #163c84, #13377c, #12357a, #113477, #0f3275, #0e3173)',
  'radial-gradient(circle, #fdb572, #fbb06b, #f9ac64, #f7a75d, #f5a256, #f29f52, #f09b4d, #ed9849, #e99547, #e59244, #e18f42, #dd8c40)',
  'radial-gradient(circle, #d3ea44, #cbe33b, #c4dc32, #bcd627, #b5cf1b, #afc915, #aac40d, #a4be03, #9fb805, #9ab107, #95ab09, #90a50b)',
  'radial-gradient(circle, #8dc6fa, #84c2fb, #7bbefc, #72bafd, #68b6fe, #63b2fb, #5eaff8, #59abf5, #59a7ee, #59a3e6, #589fdf, #589bd8)',
  'radial-gradient(circle, #ef6b6b, #ed605f, #ea5553, #e74947, #e33c3a, #df3433, #dc2c2b, #d82323, #d41f1f, #d01b1a, #cb1616, #c71111)',
  'radial-gradient(circle, #fd9473, #fe8c68, #ff845d, #ff7c52, #ff7347, #fe6d3f, #fc6738, #fb6030, #f95b2a, #f75523, #f44f1c, #f24914)',
  'radial-gradient(circle, #07dc62, #09d05f, #0cc45c, #0fb958, #12ad55, #14a653, #159f50, #17984e, #18944d, #198f4c, #198b4a, #1a8749)'
]

export const teamCardBgColor = [
  'purple-bg',
  'medium-green-bg',
  'dark-blue-bg',
  'beige-bg',
  'light-green-bg',
  'light-blue-bg',
  'red-bg',
  'orange-bg',
  'dark-green-bg'
]

export const getGradientIndex = (str) => {
  const firstLetter = str?.charAt(0)?.toLowerCase();
  const alphabetPosition = firstLetter?.charCodeAt(0) - 97; // 'a' is 97 in ASCII
  return alphabetPosition % gradients.length;
}