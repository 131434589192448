import { AppRouter, Routes } from 'routing/routesService';
import { routes } from 'routing/routes';
import { WrapperLayout } from 'components/Layout/wrapperMenu';
import OrientationDevice from 'components/OrientationDevice';
import Bugsnag from '@bugsnag/js';
import PreviewMediaOverlay from 'components/PreviewMediaOverlay';
import { usePreviewMediaOverlay } from 'components/PreviewMediaOverlay/context';
import { useAuth } from 'services/providers/AuthProvider';

Bugsnag.start({ apiKey: process.env.REACT_APP_EXCEPTION_HANDLING_TOKEN });

const App = () => {
  const { isAuthenticated, user } = useAuth();
  const { isPreviewOpen } = usePreviewMediaOverlay();
  return (
    <AppRouter routes={routes} isAuth={isAuthenticated} userRole={ user?.role || '' }>
      {isAuthenticated ? (
        <OrientationDevice>
          {isPreviewOpen && <PreviewMediaOverlay />}
          <WrapperLayout>
            <Routes />
          </WrapperLayout>
        </OrientationDevice>
      ) : (
        <Routes />
      )}
    </AppRouter>
  );
};
export default App;
