export const PrevisiousYearsIcon = (props) => (
  <span role="img" aria-label="wfh" className="anticon " {...props}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08333 6.25L10.75 8.125M10.75 8.125L9.08333 10M10.75 8.125H4M4 3.62537H10.25M13 3.25V10.75C13 11.9926 11.9926 13 10.75 13H3.25C2.00736 13 1 11.9926 1 10.75V3.25C1 2.00736 2.00736 1 3.25 1H10.75C11.9926 1 13 2.00736 13 3.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
