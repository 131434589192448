import { Collapse, Row, Button, Col } from 'antd';
import { EmployeeDocumentService } from 'services';
import { FormattedMessage } from 'react-intl';
import { EmployeeDocumentStatus } from 'services/providers/EmployeeDocumentProvider/utils';
import {
  useMobileContext,
  useThemeContext,
} from 'AppProvider/ConfigProviderSettings';
import { useModalContext } from 'components/Modal/ModalProvider';
import { useConfirmationModalContext } from 'components/Modal/ConfirmModal/ConfirmationModalProvider';
import { DocumentFields } from '../utils';
import ConfirmModalComponent from 'components/Modal/ConfirmModal';
import { useAppDispatch } from 'redux/store';
import {
  setCurrentEditDocumentId,
  setDocumentsFormView,
} from 'redux/slices/documentsSlice';
import './documentItem.less';
const DocumentItem = ({ document }) => {
  const { theme } = useThemeContext();
  const { setIsModalOpen } = useModalContext();
  const { setIsConfirmModalOpen } = useConfirmationModalContext();
  const dispatch = useAppDispatch();

  const setDocumentFormScope = (scope: 'view' | 'create' | 'edit') => {
    dispatch(setDocumentsFormView(scope));
  };
  const setEditDocumentId = (id: number) => {
    dispatch(setCurrentEditDocumentId(id));
  };
  const { isMobile } = useMobileContext();

  const EditDocumentButton = () => (
    <Button
      className={`text-bold-normal btn-primary-custom action-btn ${theme}`}
      type="primary"
      onClick={() => {
        setDocumentFormScope('edit');
        setEditDocumentId(document?.employeeDocumentId);
      }}
    >
      <FormattedMessage id="edit" />
    </Button>
  );
  const DeleteDocumentButton = () => (
    <Button
      className={`text-bold-normal 
      } btn-default-custom action-btn ${theme}`}
      onClick={() => {
        setIsConfirmModalOpen(true);
      }}
    >
      <FormattedMessage id="delete" />
    </Button>
  );

  return (
    <div className="documentItem">
      <ConfirmModalComponent
        confirmContent={
          <FormattedMessage id="DocumentsDeleteDocumentConfirmMessage" />
        }
        setMessageSuccessfully={
          <FormattedMessage id="DocumentArchivedSuccessfully" />
        }
        onOkModalAction={() =>
          EmployeeDocumentService.updateEmployeeDocumentStatus({
            employeeDocumentId: document.employeeDocumentId,
            employeeDocumentStatus: EmployeeDocumentStatus.Archived,
          }).then((res) => {
            if (!res.error) {
              setIsModalOpen(false);
            }
          })
        }
      />
      <DocumentFields document={document} />

      <div className={`${isMobile ? 'mobile' : ''} actionBtns-wrap`}>
        <DeleteDocumentButton />
        <EditDocumentButton />
      </div>
    </div>
  );
};
export default DocumentItem;
