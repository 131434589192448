import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import './index.less';
import { FormattedMessage } from 'react-intl';
import { convertToLocalDate } from 'components/DateParser';

const { Text } = Typography;

export const columnsRequestsMobileTimeManagement = (
  data = null,
  role = null,
  params,
  isModalOpen,
  setIsOpen,
  stateActions
): ColumnsType<any> => [
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Type" />,

    dataIndex: 'requestType',
    key: 'requestType',
    render: (_, record) => (
      <Text
        className="text-medium-big pointer-link line-clamp-2"
        underline
        onClick={() =>
          params.redirectColleagues({
            requestType: record.requestType,
            status: record.status,
            requestId: record.requestId,
          })
        }
      >
        <FormattedMessage id={record.requestType} />
      </Text>
    ),
    width: '40%',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Period" />,
    dataIndex: 'daysTaken',
    key: 'daysTaken',
    render: (_, record) => {
      return record.daysTaken === 0 ? (
        <p className="line-clamp-1">
          <FormattedMessage id="OngoingRequestText" />
        </p>
      ) : (
        <p className={'line-clamp-2'}>
          {record.daysTaken}{' '}
          {record.daysTaken === 1 ? (
            <FormattedMessage id="day" />
          ) : (
            <FormattedMessage id="days" />
          )}
        </p>
      );
    },
    width: '28%',
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Status" />,
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => {
      return (
        <p className="line-clamp-1">
          <FormattedMessage id={record.status} />
        </p>
      );
    },
    width: 'auto',
  },
];
