const isValidUrl = (urlString: string): boolean => {
  var inputElement = document.createElement('input');
  inputElement.type = 'url';
  inputElement.value = urlString;

  if (!inputElement.checkValidity()) {
    return false;
  } else {
    return true;
  }
};

export default isValidUrl;
