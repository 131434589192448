import { PayloadAction } from '@reduxjs/toolkit';
import { ProfileTimeOffActions } from './actions';
import { TimeOffRequests } from 'models/TimeManagement';
import { loadState, saveState } from 'context/utils';

const stateKey = 'profileTimeOffRequests';
const currentState = loadState(stateKey);
const serializedState = currentState ? JSON.parse(currentState) : null;

export const initialState: TimeOffRequests = {
  pagination: {
    resultSize: serializedState?.pagination?.resultSize || 20,
    pageNumber: 1
  }
};

export const profileTimeOffReducer = (
  state: TimeOffRequests = initialState,
  action: PayloadAction<any>
) => {
  switch (action.type) {
    case ProfileTimeOffActions.UPDATE_TABLE_FILTER:
      const pagination = {
        pagination: {
          ...state.pagination,
          resultSize: action.payload?.pagination?.resultSize,
          pageNumber: action.payload?.pagination?.pageNumber
        }
      };
      const updatedState = {
        ...state,
        filterBy: {
          statuses: action.payload?.filterBy?.statuses,
          policies: action.payload?.filterBy?.policies
        },
        sortBy: {
          daysTaken: action.payload?.sortBy?.daysTaken,
          startDate: action.payload?.sortBy?.startDate,
          endDate: action.payload?.sortBy?.endDate
        },
        ...pagination
      };
      saveState(stateKey, pagination);
      return updatedState;

    case ProfileTimeOffActions.RESET_TABLE_FILTER:
      const resetState = {
        ...initialState,
        pagination: {
          ...initialState.pagination,
          resultSize: state.pagination.resultSize
        }
      };
      saveState(stateKey, resetState);
      return resetState;

    default:
      return state;
  }
};
