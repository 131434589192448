export enum EventsTypes {
  PersonalLeave = 'PersonalLeave',
  BloodDonation = 'BloodDonation',
  Medical = 'Medical',
  Vacation = 'Vacation',
  WorkFromHome = 'WorkFromHome',
  ForgotBadge = 'ForgotBadge',
  Unpaid = 'Unpaid',
  Paternity = 'Paternity',
  Childcare = 'Childcare',
  Maternity = 'Maternity',
  LeaveOffsetting = 'LeaveOffsetting',
  Overtime = 'Overtime',
  Bereavement = 'Bereavement',
  Marriage = 'Marriage',
  MilitaryTraining = 'MilitaryTraining',
  TwoOrMoreChildren = 'TwoOrMoreChildren',
  BusinessTrip = 'BusinessTrip',
  FirstDayOfSchool = 'FirstDayOfSchool',
  LastDayOfScool = 'LastDayOfScool',
  ChildcareLeaveUnpaid = 'ChildcareLeaveUnpaid',
  BirthOfAChild = 'BirthOfAChild',
  Offsetting = 'Offsetting',
  Offset = 'Offset',
  Draft = 'Draft',
  Holiday = 'Holiday',
  WeekEnd = 'WeekEnd',
  DefaultSystemValue = 'DefaultSystemValue',
  Absence = 'Absence',
  OffsetNotDuringClockedHours = 'OffsetNotDuringClockedHours',
  ClockInOutSystem = 'ClockInOutSystem',
  ClockInOutEmployee = 'ClockInOutEmployee',
  RevokedDeniedWorkFromHome = 'RevokedDeniedWorkFromHome',
  ClockInOutDoor = 'ClockInOutDoor',
  OffsetDuringWorkSchedule = 'OffsetDuringWorkSchedule',
  OffsetNoMinimumWorkingHours = 'OffsetNoMinimumWorkingHours'
}

type Color =
  | `rgb(${number}, ${number}, ${number})`
  | `rgba(${number}, ${number}, ${number}, ${number})`
  | `#${string}`;

export type IEventsTypes = {
  cellColor?: Color;
  darkcellColor?: Color;
};

export const EventsStatusDefinitions: Record<EventsTypes, IEventsTypes> = {
  [EventsTypes.Vacation]: {
    cellColor: 'rgba(90, 176, 255, 1)',
    darkcellColor: 'rgba(90, 176, 255, 1)'
  },
  [EventsTypes.Unpaid]: {
    cellColor: 'rgba(220, 31, 122, 1)',
    darkcellColor: 'rgba(220, 31, 122, 1)'
  },
  [EventsTypes.Medical]: {
    cellColor: 'rgba(242, 156, 76, 1)',
    darkcellColor: 'rgba(242, 156, 76, 1)'
  },
  [EventsTypes.Childcare]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Holiday]: {
    cellColor: '#EB5757',
    darkcellColor: '#EB5757'
  },
  [EventsTypes.PersonalLeave]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.Offset]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.BloodDonation]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Draft]: {
    cellColor: 'rgba(220, 31, 122, 1)',
    darkcellColor: 'rgba(220, 31, 122, 1)'
  },
  [EventsTypes.ForgotBadge]: {
    cellColor: 'rgba(152, 206, 0, 1)',
    darkcellColor: 'rgba(152, 206, 0, 1)'
  },
  [EventsTypes.Paternity]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Overtime]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.Maternity]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.LeaveOffsetting]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.Offsetting]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.Bereavement]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Marriage]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.MilitaryTraining]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.TwoOrMoreChildren]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.BusinessTrip]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.FirstDayOfSchool]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.LastDayOfScool]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.ChildcareLeaveUnpaid]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.BirthOfAChild]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.WorkFromHome]: {
    cellColor: 'rgba(217, 63, 255, 1)',
    darkcellColor: 'rgba(217, 63, 255, 1)'
  },
  [EventsTypes.WeekEnd]: {
    cellColor: '#BDA7E899',
    darkcellColor: '#BDA7E899'
  },

  [EventsTypes.DefaultSystemValue]: {
    cellColor: 'rgba(189, 167, 232, 0.1)',
    darkcellColor: 'rgba(189, 167, 232, 0.1)'
  },
  [EventsTypes.Absence]: {
    cellColor: 'rgba(189, 167, 232, 0.1)',
    darkcellColor: 'rgba(189, 167, 232, 0.1)'
  },
  [EventsTypes.OffsetNotDuringClockedHours]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.OffsetDuringWorkSchedule]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.OffsetNoMinimumWorkingHours]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.ClockInOutSystem]: {
    cellColor: 'rgba(189, 167, 232, 0.1)',
    darkcellColor: 'rgba(189, 167, 232, 0.1)'
  },
  [EventsTypes.RevokedDeniedWorkFromHome]: {
    cellColor: 'rgba(189, 167, 232, 0.1)',
    darkcellColor: 'rgba(189, 167, 232, 0.1)'
  },
  [EventsTypes.ClockInOutEmployee]: {
    cellColor: 'rgba(189, 167, 232, 0.1)',
    darkcellColor: 'rgba(189, 167, 232, 0.1)'
  },
  [EventsTypes.ClockInOutDoor]: {
    cellColor: 'rgba(189, 167, 232, 0.1)',
    darkcellColor: 'rgba(189, 167, 232, 0.1)'
  }
};

export const multipleEvents = [
  'Holiday',
  'PersonalLeave',
  'Offsetting',
  'BloodDonation',
  'Medical',
  'Vacation',
  'WorkFromHome',
  'ForgotBadge',
  'Unpaid',
  'Paternity',
  'Childcare',
  'Maternity',
  'LeaveOffsetting',
  'Overtime',
  'Bereavement',
  'Marriage',
  'SpecialLeave',
  'MilitaryTraining',
  'TwoOrMoreChildren',
  'BusinessTrip',
  'FirstDayOfSchool',
  'LastDayOfScool',
  'ChildcareLeaveUnpaid',
  'BirthOfAChild'
];
