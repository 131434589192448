import { commonRequest } from 'services/generic';
import { IEmployeeDocument, IEmployeeDocumentForUpdatingStatus } from './types';

export default class EmployeeDocumentProvider {
  async getAllEmployeeDocuments(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IEmployeeDocument[]>(
      'POST',
      payloadData,
      'EmployeeDocument/getAllEmployeeDocuments'
    );
  }

  async getEmployeeDocuments(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IEmployeeDocument>(
      'POST',
      payloadData,
      'EmployeeDocument/getEmployeeDocument'
    );
  }

  async getEmployeeDocumentsByType(payload: {
    parameters: { employeeId: string; documentType: string };
  }) {
    return await commonRequest<IEmployeeDocument>(
      'POST',
      payload,
      'EmployeeDocument/getEmployeeDocumentsByType'
    );
  }
  async addEmployeeDocument(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<IEmployeeDocument>(
      'POST',
      payloadData,
      'EmployeeDocument/addEmployeeDocument'
    );
  }

  async getDocumentAttachmentUrl(payload: {
    parameters: { documentId: string | number };
  }) {
    return await commonRequest<IEmployeeDocument>(
      'POST',
      payload,
      'EmployeeDocument/getDocumentAttachmentUrl'
    );
  }

  async updateEmployeeDocumentStatus(
    payload: IEmployeeDocumentForUpdatingStatus
  ) {
    const payloadData = { payload };
    return await commonRequest<IEmployeeDocument>(
      'POST',
      payloadData,
      'EmployeeDocument/updateEmployeeDocumentStatus'
    );
  }

  async updateEmployeeDocument(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<IEmployeeDocument>(
      'POST',
      payloadData,
      'EmployeeDocument/updateEmployeeDocument'
    );
  }

  async downloadSignatureSample(payload: any) {
    const payloadData = { payload, customResponseType: 'arraybuffer' };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'EmployeeDocument/downloadSignatureSample'
    );
  }

  EmployeeDocumentType;
}
