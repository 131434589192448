import { PlusCircleOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import ModalComponent from 'components/Modal';
import { SettingsSubCategoryForm } from 'components/SetitingsComponents/Category/SettingsSubCategoryForm';
import TableComponent from 'components/Table';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSubCategoryTableColumns } from './useSubCategoryTableColumns';
import { Children, useEffect, useState } from 'react';
import { useModalContext } from 'components/Modal/ModalProvider';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useApiRequestHook from '../../../../hooks/useApiRequest.hook';
import { TicketingSettingsService } from '../../../../services';
import { IExtendedTicketSubCategory } from '../../../../services/providers/TicketingSettingsProvider/types';
import { TableComponentPlaceholder } from 'components/Placeholders';

let deleteModalWidth: string;
let editModalWidth: string;
let addMoldalWidth: string;

const categoryWidth = () => {
  if (window.innerWidth <= 768) {
    editModalWidth = '100%';
    addMoldalWidth = '100%';
    deleteModalWidth = '100%';
  } else {
    editModalWidth = '30%';
    addMoldalWidth = '30%';
    deleteModalWidth = '20%';
  }
};

export const ViewCategoryById = () => {
  const { deviceHeight, isMobile, deviceOS } = useMobileContext();
  const intl = useIntl();
  const { isModalOpen, toggleModal, setIsModalOpen } = useModalContext();
  const navigate = useNavigate();
  const [subCategories, setSubCategories] = useState(null);

  const ButtonComponent = BaseButton(ButtonSimple);
  const { theme } = useThemeContext();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const categoryIds = searchParams.get('categoryId');

  const [modalWidth, setModalWidth] = useState<string>();
  const [modalProps, setModalProps] = useState({
    scope: 'add',
    modalTitle: null,
    categoryId: null,
    subCategory: null,
    submitButtonText: <FormattedMessage id="submit" />,
    cancelButtonText: <FormattedMessage id="cancel" />,
    width: modalWidth,
    displayFooterSubmitButton: true,
    displayFooterCloseButton: true,
    children: null,
    allowDelete: true
  });
  const categoryTableColumnsArray = useSubCategoryTableColumns(
    setIsModalOpen,
    setModalProps,
    editModalWidth,
    deleteModalWidth
  );

  const { data, status } = useApiRequestHook<IExtendedTicketSubCategory[]>(
    TicketingSettingsService.getExtendedSubcategories,
    state?.categoryId || categoryIds,
    [],
    {}
  );

  useEffect(() => {
    if (data) {
      setSubCategories(data);
    }
  }, [data]);

  useEffect(() => {
    if (isMobile) {
      setModalWidth('100%');
    } else {
      setModalWidth('45%');
    }
    categoryWidth();
  }, [isMobile]);

  const handleClick = () => {
    if (state === '/settings/viewCategory') {
      navigate('/settings/ticketingCategories');
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="configurationContainer">
      <ModalComponent
        key="ticketingSubCategoryModal"
        title={modalProps.modalTitle}
        formKeyId="ticketingSubCategoryForm"
        displayFooterSubmitButton={modalProps?.displayFooterSubmitButton}
        cancelBtnText={modalProps.cancelButtonText}
        submitBtnText={modalProps.submitButtonText}
      >
        {modalProps?.allowDelete === true ? (
          <SettingsSubCategoryForm
            scope={modalProps.scope}
            subCategory={modalProps.subCategory}
            categoryId={state?.categoryId || categoryIds}
          />
        ) : (
          <>
            <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
              <FormattedMessage id="SubCategoryCantRemoveConfirmText" />
            </p>
          </>
        )}
      </ModalComponent>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        className="padding-search"
        buttons={[
          {
            icon: <PlusCircleOutlined />,
            text: (
              <span>
                {intl
                  .formatMessage({ id: 'Settings_WorkQueues_Add_SubCategory' })
                  .toLowerCase()}
              </span>
            ),
            action: () => {
              setModalProps(state => ({
                ...state,
                scope: 'add',
                assetId: null,
                displayFooterSubmitButton: true,
                displayFooterCloseButton: true,
                allowDelete: true,
                modalTitle: (
                  <FormattedMessage id="Settings_WorkQueues_Add_SubCategory" />
                ),
                submitButtonText: <FormattedMessage id="add" />,
                cancelButtonText: <FormattedMessage id="close" />,
                formKeyId: 'ticketingSubCategoryForm'
              }));
              toggleModal();
            },
            show: true
          }
        ]}
        children={
          <>
            {' '}
            <ButtonComponent
              className={`text-bold-normal btn-default-custom ${theme}`}
              onClick={handleClick}
            >
              <FormattedMessage id="back" />
            </ButtonComponent>
          </>
        }
      />
      <br />
      <TableLayoutPage
        title={<FormattedMessage id="Settings_Subcategories_list" />}
        renderCustomButton
        displayToggle
        style={{
          height: `calc(${deviceHeight}px - 185px)`,
          overflow: 'hidden'
        }}
      >
        <ComponentWithStatus
          ignoreAnimation={true}
          status={status}
          Placeholder={<TableComponentPlaceholder count={3} />}
        >
          <TableComponent
            showSorterTooltip={false}
            tableSize="large"
            style={{
              height: `calc(${deviceHeight}px - 22em)`,
              minHeight: `calc(${deviceHeight}px - 22em)`
            }}
            scroll={{
              x: 'calc(100vw - 213px)',
              y: isMobile
                ? deviceOS === 'IOS'
                  ? 'calc(100vh - 30.8em)'
                  : 'calc(100vh - 28.8em)'
                : 'calc(100vh - 24.5em)'
            }}
            rowKey={'subCategoryId'}
            className="subcategory_settings_table"
            rowClassName={'hoverRow'}
            dataSource={subCategories}
            data={subCategories}
            columns={() => categoryTableColumnsArray}
          />
        </ComponentWithStatus>
      </TableLayoutPage>
    </div>
  );
};
