import { commonRequest } from 'services/generic';
import { IEmployee, IEmployeeImportFile } from './types';

export default class EmployeeProvider {
  async getEmployee(payload: string, parameters: object) {
    const payloadData = { payload, parameters };

    return await commonRequest<IEmployee>(
      'POST',
      payloadData,
      'Employee/GetEmployeeProfile'
    );
  }

  async getImportTemplateFile(payload: object) {
    const payloadData = { payload: payload, customResponseType: 'arraybuffer' };
    return await commonRequest(
      'GET',
      payloadData,
      'Employee/getImportTemplateFile'
    );
  }
  async importFromFile(payload: FormData) {
    const payloadData = { payload: payload };
    return await commonRequest<IEmployeeImportFile>(
      'POST',
      payloadData,
      'Employee/importFromFile'
    );
  }

  async getEmployeeProfile(payload: string) {
    const payloadData = { payload };

    return await commonRequest<IEmployee>(
      'POST',
      payloadData,
      'Employee/getMyProfile'
    );
  }

  async updateEmployee(payload: IEmployee) {
    const payloadData = { payload };

    return await commonRequest<IEmployee>(
      'POST',
      payloadData,
      'Employee/updateEmployee'
    );
  }

  async updateMyProfile(payload: IEmployee) {
    const payloadData = { payload };

    return await commonRequest<IEmployee>(
      'POST',
      payloadData,
      'Employee/updateMyProfile'
    );
  }

  async updateMyProfileExtended(payload: IEmployee) {
    const payloadData = { payload };

    return await commonRequest<IEmployee>(
      'POST',
      payloadData,
      'Employee/updateMyProfileExtended'
    );
  }

  async getAll(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IEmployee[]>(
      'POST',
      payloadData,
      '/Employee/getAllEmployees'
    );
  }

  async getAllEmployeesList(payload: string) {
    const payloadData = { payload };
    return await commonRequest<IEmployee[]>(
      'GET',
      payloadData,
      '/Employee/getAllEmployeesList'
    );
  }
  async addEmployee(payload: IEmployee) {
    const payloadData = { payload };
    return await commonRequest<IEmployee[]>(
      'POST',
      payloadData,
      '/Employee/addEmployee'
    );
  }

  async updateProfilePicture(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      '/Employee/updateProfilePicture'
    );
  }  
}
