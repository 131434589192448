import React from 'react';

const useResizeObserver = <T extends Element>(
  callback: (entries: ResizeObserverEntry[]) => void,
  target: T | null = null
) => {
  const ref = React.useRef<ResizeObserver | null>(null);

  React.useEffect(() => {
    if (!target) {
      return;
    }

    const observer = new ResizeObserver(callback);
    ref.current = observer;

    observer.observe(target);

    return () => {
      if (ref.current) {
        ref.current.disconnect();
      }
    };
  }, [callback, target]);

  return ref;
};

export default useResizeObserver;
