import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

/**Converts date string to UTC regardless of timezone */
export const DateToUTC = date => {
  return dayjs(new Date(date)).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
};

export const convertToLocalDate = (date: string | dayjs.Dayjs | Date) =>
  dayjs.tz(dayjs.utc(date), dayjs.tz.guess());

export const convertToUTC = (date: dayjs.Dayjs) =>
  dayjs(date).subtract(dayjs().utcOffset(), 'minutes');

/** This function returns a datetime string without hours, of the beginning of the local date but parsed as UTC.
 * this is meant to exclude cases when you submit a request at 2023-12-11 02:00GMT+3 local time, and it gets converted to 2023-12-10 23:00 UTC previous day.
 * In this case, the function will return 2023-12-11 00:00:00Z as UTC
 */

export const DateToBeginningOfLocalDate = (
  date: string | Date | dayjs.Dayjs
) => {
  return convertToLocalDate(date).format('YYYY-MM-DDT00:00:00[Z]');
};
