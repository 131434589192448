import React from 'react';
import TableHeaderCell from './TableHeaderCell';

interface TableHeaderProps {
  columns: any[];
  setFilteredResults: (results: string[]) => void;
  columnRowClassName?: string;
  dataSorce: any;
  setSorted?: any;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  columns,
  setFilteredResults,
  columnRowClassName,
  dataSorce,
  setSorted
}) => {
  return (
    <tr className={`table-component-header-row ${columnRowClassName ?? ``}`}>
      {columns.map((item) => (
        <TableHeaderCell
          key={item.key}
          title={item.title}
          className={item.className}
          entireItem={item}
          setFilteredResults={setFilteredResults}
          dataSorce={dataSorce}
          setSorted={setSorted}
        />
      ))}
    </tr>
  );
};

export default TableHeader;
