import { darkTheme, useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import '../EmployeeProfileCard.less';

import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentItems } from './DocumentItems';
import './papersWidgetStyles.less';
import ComponentWithStatus from '../../../ComponentWithStatus';
import { PapersListWidgetPlaceholder } from '../../../Placeholders';
import { PapersWidgetPlaceholder } from '../../../Placeholders/Widgets';

type EmployeeInfoFieldsProps = {
  data: any;
  scope?: string;
  firstNameEmployee?: any;
  gender: string;
  employeeId?: any;
  openModal: any;
  setModalOpen: any;
};

const { Text } = Typography;

export const PapersWidget: FC<EmployeeInfoFieldsProps> = props => {
  const { data, scope, firstNameEmployee, openModal, setModalOpen, employeeId } =
    props;

  const { theme } = useThemeContext();

  const intl = useIntl();

  const { isMobile } = useMobileContext();

  const EmployeePapers = intl.formatMessage(
    { id: 'EmployeePapers' },
    { name: firstNameEmployee }
  );

  return (
    <ComponentWithStatus status={data ? 'success' : 'pending'} Placeholder={<PapersWidgetPlaceholder />}>
      <div className="full-content documents-widget" key="widget-team">
        <Row
          justify="space-between"
          className="team-align"
          style={isMobile ? { display: 'flex' } : {}}
        >
          <Col
            className="align-border-padding"
            style={isMobile ? { paddingBottom: '0' } : { paddingBottom: '11px' }}
          >
            <Text
              className={`text-bold-bigger align-border-padding typography_text ${theme}`}
              style={{ lineHeight: 1.25 }}
            >
              {scope !== 'ColleagueProfile' ? (
                <FormattedMessage id="myPapersText" />
              ) : (
                EmployeePapers
              )}
            </Text>
          </Col>
          <Col></Col>
        </Row>

        <Row
          align="middle"
          justify={'center'}
          className={`documents-container align-border-padding title-padding-height ${theme}`}
        >
          <DocumentItems
            scope={scope}
            openModal={openModal}
            setModalOpen={setModalOpen}
            employeeId={employeeId}
          />
        </Row>
      </div>
    </ComponentWithStatus>

  );
};
