import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Calendar } from 'antd';
import './StyledCalendar.less';
export default function StyledCalendar(props) {
  const { theme } = useThemeContext();

  return (
    <Calendar
      {...props}
      className={`calendar-widget-inner ${theme} ${props.className} `}
      fullscreen={false}
    />
  );
}
