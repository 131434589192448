export const SendIconSVG = () => {
  return (
    <span
      role="img"
      aria-label="detailedView"
      className="anticon anticon-detailedView"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <path
          opacity="1"
          d="M12.669 1.83099L6.00403 8.49597M1.54443 5.14704L11.9231 1.54627C12.5623 1.32453 13.1755 1.93773 12.9537 2.57688L9.35296 12.9556C9.10628 13.6666 8.10785 13.6861 7.83361 12.9852L6.18555 8.77353C6.10324 8.56318 5.93682 8.39676 5.72647 8.31445L1.51477 6.66639C0.813933 6.39215 0.833427 5.39372 1.54443 5.14704Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};
