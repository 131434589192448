import { useModalContext } from 'components/Modal/ModalProvider';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { colleagueRequestModalProps } from 'pages/TimeManagement/ColleaguesTimeOffRequests/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';

import './VacationToolbar.less';

const VacationToolbar: React.FC<any> = props => {
  const { user, adminRoles, setModalProps } = props;
  const { toggleModal } = useModalContext();
  return (
    <>
      <SearchToolbarComponent
        className="vacation_module_search_toolbar"
        key="VacationModule"
        displaySearchIcon={false}
        displaySearchInput={false}
        buttons={[
          {
            text: <FormattedMessage id="RequestLeaveOnBehalfOfColleague" />,
            action: () => {
              setModalProps(colleagueRequestModalProps);
              toggleModal();
            },
            show: allowPermision(getCurrentUserRole(user), [
              userRoles.Admin,
              userRoles.HR
            ])
          }
        ]}
      />
    </>
  );
};

export default VacationToolbar;
