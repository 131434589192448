import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { IModalComponentProps } from 'components/Modal';

import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import ViewColleague from '../ViewColleague';

interface IColleaguesModalContext {
  setColleagueData: any;
  colleaguesModalProps: any;
  openColleagueModal: any;
  closeColleagueModal: any;
}

const useColleaguesModalContext = (): IColleaguesModalContext => {
  const [colleagueData, setColleagueData] = React.useState<string | number>('');
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const { theme } = useThemeContext();
  const { isMobile, isTabletDevice } = useMobileContext();

  /** Opens the Modal where the create/view/update event form is rendered
   * @param {'incident' | 'service'} scope - the type of the form
   * @param {EventResponseDto} eventObject - the Event object that contains the details needed to view/edit event
   */
  const openColleagueModal = () => {
    setModalOpen(true);
  };

  const closeColleagueModal = () => {
    setModalOpen(false);
  };

  const colleaguesModalProps: IModalComponentProps = {
    isOpen: modalOpen,
    setIsOpen: setModalOpen,
    isMaskClosable: false,
    modalKey: 'viewColleagueModal',
    formKeyId: 'viewColleague',
    className: 'mobile-modal',

    displayFooterSubmitButton: false,
    cancelBtnText: <FormattedMessage id={'close'} />,

    title: (
      <div
        style={{ width: '100%', textAlign: 'center' }}
        className={`modal-title ${theme}`}
      >
        <FormattedMessage id={'ViewColleagueModalTitle'} />
      </div>
    ),

    onCancelPressed: () => closeColleagueModal(),
    afterClose: () => closeColleagueModal(),

    children: <ViewColleague colleagueData={colleagueData} />
  };

  return {
    setColleagueData,
    colleaguesModalProps,
    openColleagueModal,
    closeColleagueModal
  };
};

const ColleaguesModalContext = React.createContext(
  {} as ReturnType<typeof useColleaguesModalContext>
);

export const useColleaguesContext = () => {
  return React.useContext(ColleaguesModalContext);
};

export const ColleaguesModalContextProvider = ({ children }) => {
  return (
    <ColleaguesModalContext.Provider value={useColleaguesModalContext()}>
      {children}
    </ColleaguesModalContext.Provider>
  );
};
