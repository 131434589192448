export const TeamsSvg = () => {
  return (
    <span
      role="img"
      aria-label="teams"
      className="anticon anticon-teams"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.6001 14.4L1.60037 11.9997C1.60052 10.6743 2.67499 9.59997 4.00037 9.59997H8.8M10.8001 11.6L11.6001 12.4L14.4001 9.59997M11.6001 1.59998C12.5709 2.14416 13.2001 3.01656 13.2001 3.99998C13.2001 4.98339 12.5709 5.85579 11.6001 6.39997M9.6001 3.99998C9.6001 5.32546 8.52558 6.39997 7.2001 6.39997C5.87461 6.39997 4.8001 5.32546 4.8001 3.99998C4.8001 2.67449 5.87461 1.59998 7.2001 1.59998C8.52558 1.59998 9.6001 2.67449 9.6001 3.99998Z"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
