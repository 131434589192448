export const TimeOffRevokeIcon = ({ fill, stroke = '#fff' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.65165 5.40901C9.94454 5.11612 9.94454 4.64124 9.65165 4.34835C9.35876 4.05546 8.88388 4.05546 8.59099 4.34835L9.65165 5.40901ZM4.34835 8.59099C4.05546 8.88388 4.05546 9.35876 4.34835 9.65165C4.64124 9.94454 5.11612 9.94454 5.40901 9.65165L4.34835 8.59099ZM8.59099 9.65165C8.88388 9.94454 9.35876 9.94454 9.65165 9.65165C9.94454 9.35876 9.94454 8.88388 9.65165 8.59099L8.59099 9.65165ZM5.40901 4.34835C5.11612 4.05546 4.64124 4.05546 4.34835 4.34835C4.05546 4.64124 4.05546 5.11612 4.34835 5.40901L5.40901 4.34835ZM12.25 7C12.25 9.8995 9.8995 12.25 7 12.25V13.75C10.7279 13.75 13.75 10.7279 13.75 7H12.25ZM7 12.25C4.1005 12.25 1.75 9.8995 1.75 7H0.25C0.25 10.7279 3.27208 13.75 7 13.75V12.25ZM1.75 7C1.75 4.1005 4.1005 1.75 7 1.75V0.25C3.27208 0.25 0.25 3.27208 0.25 7H1.75ZM7 1.75C9.8995 1.75 12.25 4.1005 12.25 7H13.75C13.75 3.27208 10.7279 0.25 7 0.25V1.75ZM8.59099 4.34835L6.46967 6.46967L7.53033 7.53033L9.65165 5.40901L8.59099 4.34835ZM6.46967 6.46967L4.34835 8.59099L5.40901 9.65165L7.53033 7.53033L6.46967 6.46967ZM9.65165 8.59099L7.53033 6.46967L6.46967 7.53033L8.59099 9.65165L9.65165 8.59099ZM7.53033 6.46967L5.40901 4.34835L4.34835 5.40901L6.46967 7.53033L7.53033 6.46967Z"
        fill={fill || stroke}
      />
    </svg>
  );
};
