import { Typography } from 'antd';
import TicketCommentSection from './Layout/TicketCommentSection';
import TicketHeader from './Layout/TicketHeader';
import styles from './ticketsMainSection.module.less';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import TicketSenderBar from './TicketSenderBar';
import { TicketDto } from 'pages/Ticketing/types';
import { useAuth } from 'services/providers/AuthProvider';
import { createContext, useRef } from 'react';

export const LastCommentRefContext = createContext(null);

const TicketsMainSection = () => {
  const { selectedTicket } = useSelector((state: RootState) => state.ticketing);
  const { user } = useAuth();

  // helper ref which is used to reference the last comment element
  //see TicketComment component useImperativeHandle
  const lastCommentRef = useRef<any>(null);

  const displayCommentBarCheck = () => {
    const isUserAssignedToTicket =
      (selectedTicket as TicketDto)?.assignee?.email.toLowerCase() ===
      user?.email;
    const isUserTheRequester =
      (selectedTicket as TicketDto)?.requester?.email.toLowerCase() ===
      user?.email;
    const isTicketClosedOrResolved = ['Closed', 'Resolved'].includes(
      selectedTicket?.status
    );

    return (
      (isUserAssignedToTicket || isUserTheRequester) &&
      !isTicketClosedOrResolved
    );
  };

  return (
    <div className={styles.wrap}>
      {selectedTicket ? (
        <LastCommentRefContext.Provider value={lastCommentRef}>
          <TicketHeader />
          <TicketCommentSection />
          {displayCommentBarCheck() && <TicketSenderBar />}
        </LastCommentRefContext.Provider>
      ) : (
        <div className={styles.noTicketsSelectedMessage}>
          <Typography.Title>
            <FormattedMessage id="NoTicketSelected" />
          </Typography.Title>
        </div>
      )}
    </div>
  );
};
export default TicketsMainSection;
