import { useAuth } from 'services/providers/AuthProvider';
import { FC, useEffect, useState } from 'react';
import {
  adminRoles,
  allowPermision,
  rolesAllow,
  userRoles
} from 'utils/userManagement';
import type { ColumnsType } from 'antd/es/table';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { useNavigate } from 'react-router-dom';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import TableComponent from 'components/Table';
import ComponentWithStatus from 'components/ComponentWithStatus';
import ModalComponent from 'components/Modal';
import AssetCategoryForm from 'components/Categories/CategoryForm';
import { AssetCategoryService } from 'services';
import { useModalContext } from 'components/Modal/ModalProvider';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { columnsMobileCategories } from './columnsMobileCategories';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { FormattedMessage, useIntl } from 'react-intl';
import { firstCharToUpperCase } from 'utils/lang/utils';
import { TableComponentPlaceholder } from 'components/Placeholders';
import ThemedIcon from 'components/UIComponents/Icon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { Tooltip } from 'antd';

type CategoriesProps = { categoryId?: string | number; formScoping?: string };

let deleteModalWidth: string;
let editModalWidth: string;
let addMoldalWidth: string;
let isMobileModal: boolean;

const categoryWidth = () => {
  if (window.innerWidth <= 768) {
    editModalWidth = '100%';
    addMoldalWidth = '100%';
    deleteModalWidth = '100%';
  } else {
    editModalWidth = '30%';
    addMoldalWidth = '30%';
    deleteModalWidth = '20%';
  }
};

const columns = (
  data = null,
  role = null,
  params,
  isModalOpen,
  setIsOpen,
  stateActions
): ColumnsType<any> => [
  {
    title: <FormattedMessage id="table_InventoryCategoryCol_Name" />,
    dataIndex: 'name',
    render: (_, record) => (
      <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
        <Tooltip placement="top" title={record?.name}>
          <span className="line-clamp-1"> {record?.name}</span>
        </Tooltip>
      </div>
    )
  },
  {
    title: <FormattedMessage id="table_InventoryCategoryCol_Details" />,
    dataIndex: 'details',
    render: (_, record) => {
      return (
        <>
          <Tooltip placement="top" title={record?.details}>
            <span className="line-clamp-1">
              {record.details?.length > 45
                ? record.details.slice(0, 45) + '...'
                : record.details}
            </span>
          </Tooltip>
        </>
      );
    }
  },
  {
    title: <FormattedMessage id="table_InventoryCategoryCol_Type" />,
    dataIndex: 'assetType'
  },
  {
    title: <FormattedMessage id="table_InventoryCategoryCol_Count" />,
    dataIndex: 'assetsCount',
    width: '16.5%',
    className: `${allowPermision(role, rolesAllow) ? 'hoverEdit' : ''}`,
    render: (_, record, index) => {
      return (
        <>
          <div
            className={allowPermision(role, rolesAllow) ? 'hideContent' : ''}
          >
            {record.assetsCount}
          </div>

          <div
            className={allowPermision(role, rolesAllow) ? 'showEdit' : ''}
            style={{ display: 'none' }}
          >
            <div className="actionButtons">
              <ButtonWithExpandedText
                style={isMobileModal ? { height: 'max-content' } : {}}
                displayedText={<FormattedMessage id="edit" />}
                embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                key="edit-category-btn"
                type="link"
                onClick={() => {
                  stateActions?.setModalProps(state => ({
                    ...state,
                    scope: 'edit',
                    categoryId: record.categoryId,
                    modalTitle: (
                      <FormattedMessage id="EditCategoryModalTitle" />
                    ),
                    cancelButtonText: <FormattedMessage id="cancel" />,
                    submitButtonText: <FormattedMessage id="save" />,
                    width: editModalWidth
                  }));
                  setIsOpen();
                }}
              />
              <ButtonWithExpandedText
                embedSrc={<ThemedIcon icon={TimeOffDeleteIcon} />}
                displayedText={<FormattedMessage id="remove" />}
                type="link"
                onClick={() => {
                  stateActions?.setModalProps(state => ({
                    ...state,
                    scope: 'remove',
                    categoryId: record.categoryId,
                    modalTitle: (
                      <FormattedMessage id="RemoveCategoryModalTitle" />
                    ),
                    cancelButtonText: <FormattedMessage id="cancel" />,
                    submitButtonText: <FormattedMessage id="remove" />,
                    width: { deleteModalWidth }
                  }));
                  setIsOpen();
                }}
              />
            </div>
          </div>
        </>
      );
    }
  }
];

const ViewManageCategories: FC<CategoriesProps> = ({
  categoryId,
  formScoping = 'add'
}) => {
  const {
    isModalOpen,
    toggleModal,
    setIsTopRoleModalOpen,
    isTopRoleModalOpen
  } = useModalContext();
  const [isDisableDelete, setDisableDelete] = useState(null);
  const { data, status } = useApiRequestHook<any>(
    AssetCategoryService.getAllAssetCategories,
    'true',
    [],
    {}
  );
  const [modalWidth, setModalWidth] = useState<string>();

  const { deviceHeight, isMobile, deviceOS } = useMobileContext();

  useEffect(() => {
    if (isMobile) {
      setModalWidth('100%');
    } else {
      setModalWidth('45%');
    }
    categoryWidth();
  }, [isMobile]);

  const [modalProps, setModalProps] = useState({
    scope: formScoping,
    modalTitle: null,
    categoryId: categoryId,
    submitButtonText: <FormattedMessage id="submit" />,
    cancelButtonText: <FormattedMessage id="cancel" />,
    width: modalWidth
  });

  const { user } = useAuth();
  const role = user?.role;

  const navigate = useNavigate();
  const goToInventoryPage = () => navigate('/inventory/gateway');
  const { theme } = useThemeContext();
  const ButtonComponent = BaseButton(ButtonSimple);
  const [isTableModal, setIsTableModal] = useState<boolean>(false);
  const [isCategoryID, setIsCategoryID] = useState<any>(false);

  const intl = useIntl();
  const addCategoryText = intl.formatMessage({
    id: 'AddCategory'
  });
  const addCategoryModalTitle = firstCharToUpperCase(addCategoryText);

  return (
    <>
      <ModalComponent
        key="assetCategoryModal"
        title={modalProps.modalTitle}
        formKeyId="assetCategoryForm"
        children={
          <AssetCategoryForm
            scope={modalProps.scope}
            categoryId={modalProps.categoryId}
            setDisableDelete={setDisableDelete}
          />
        }
        submitBtnText={modalProps.submitButtonText}
        displayFooterSubmitButton={
          modalProps.scope === 'add'
            ? true
            : modalProps.scope === 'view'
              ? false
              : !isDisableDelete
        }
        isMaskClosable={isDisableDelete}
        cancelBtnText={intl.formatMessage({ id: 'cancel' })}
        footerButtons={
          modalProps.scope === 'view' ? (
            <>
              <ButtonComponent
                className={`text-bold-normal btn-primary-custom ${theme}`}
                key="editHardwareViewButton"
                type="primary"
                onClick={() => {
                  setModalProps(state => ({
                    ...state,
                    scope: 'edit',
                    modalTitle: (
                      <FormattedMessage id="EditCategoryModalTitle" />
                    ),
                    categoryId: isCategoryID,
                    cancelButtonText: <FormattedMessage id="cancel" />,
                    submitButtonText: <FormattedMessage id="save" />,
                    width: '100%'
                  }));
                  setIsTableModal(false);
                }}
              >
                <FormattedMessage id="edit" />
              </ButtonComponent>
              <ButtonComponent
                className={`text-bold-normal btn-default-custom ${theme}`}
                onClick={() => {
                  setModalProps(state => ({
                    ...state,
                    scope: 'remove',
                    categoryId: isCategoryID,
                    modalTitle: (
                      <FormattedMessage id="RemoveCategoryModalTitle" />
                    ),
                    cancelButtonText: <FormattedMessage id="cancel" />,
                    submitButtonText: <FormattedMessage id="delete" />,
                    width: '100%'
                  }));
                  if (status !== 'success') {
                    return;
                  }
                  setIsTableModal(false);
                }}
              >
                <FormattedMessage id="remove" />
              </ButtonComponent>
            </>
          ) : null
        }
      />
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        children={
          <ButtonComponent
            className={`text-bold-normal btn-default-custom ${theme}`}
            style={{ marginLeft: '2em' }}
            onClick={goToInventoryPage}
          >
            <FormattedMessage id="back" />
          </ButtonComponent>
        }
        buttons={[
          {
            text: (
              <span>
                <FormattedMessage id="add" />
              </span>
            ),
            action: () => {
              setModalProps(state => ({
                ...state,
                scope: 'add',
                categoryId: null,
                submitButtonText: <FormattedMessage id="add" />,
                cancelButtonText: <FormattedMessage id="close" />,
                modalTitle: addCategoryModalTitle,
                width: addMoldalWidth
              }));

              if (status !== 'success') {
                return;
              }

              toggleModal();
            },

            show: allowPermision(role, rolesAllow)
          }
        ]}
      />
      <br />
      <TableLayoutPage
        title={
          <FormattedMessage id="table_InventoryCategoryTitle_Categories" />
        }
        displayButton
        displayToggle
        style={{
          height: `calc(${deviceHeight}px - 185px)`
        }}
      >
        {isMobile ? (
          <>
            <ModalComponent
              className="top-role-popup"
              setIsOpen={setIsTopRoleModalOpen}
              isOpen={isTopRoleModalOpen}
              displayFooter={false}
            >
              <section className="top-role-popup-buttons">
                <ButtonComponent
                  className={`text-bold-normal btn-default-custom ${theme}`}
                  onClick={() => {
                    setModalProps(state => ({
                      ...state,
                      scope: 'add',
                      modalTitle: addCategoryModalTitle,
                      categoryId: isCategoryID,
                      cancelButtonText: <FormattedMessage id="cancel" />,
                      submitButtonText: <FormattedMessage id="add" />,
                      width: '100%'
                    }));
                    if (status !== 'success') {
                      return;
                    }
                    setIsTopRoleModalOpen(false);
                    toggleModal();
                  }}
                >
                  <FormattedMessage id="AddCategory" />
                </ButtonComponent>
              </section>
            </ModalComponent>
            <ComponentWithStatus
              status={status}
              Placeholder={<TableComponentPlaceholder count={3} />}
            >
              <TableComponent
                style={{
                  minHeight: 'calc(100vh - 42em)'
                }}
                scroll={
                  !isMobile
                    ? { x: 'calc(100vw - 220px)', y: 'calc(100vh - 42em)' }
                    : { x: 'calc(100vw - 213px)', y: deviceOS === 'IOS' ? 'calc(100dvh - 29.8em)' : 'calc(100dvh - 28em)' }
                }
                rowKey="categoryId"
                columns={columnsMobileCategories}
                dataSource={data}
                data={data}
                role={role}
                rowClassName={
                  allowPermision(role, rolesAllow) ? 'hoverRow' : ''
                }
                isModalOpen={isModalOpen}
                setIsModalOpen={() => {
                  if (!isModalOpen) {
                    toggleModal();
                  }
                }}
                hookUseStateActions={{
                  setModalProps
                }}
                className="categoriesTable"
                onRow={record => {
                  if (
                    allowPermision(role, [
                      userRoles.Admin,
                      userRoles.Accountant
                    ])
                  ) {
                    return {
                      onClick: e => {
                        setModalProps(state => ({
                          ...state,
                          scope: 'view',
                          categoryId: record.categoryId,
                          modalTitle: (
                            <FormattedMessage id="ViewCategoryModalTitle" />
                          ),
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          submitButtonText: <FormattedMessage id="save" />,
                          width: '100% !important'
                        }));
                        if (status !== 'success') {
                          return;
                        }
                        setIsCategoryID(record.categoryId);
                        toggleModal();
                      }
                    };
                  } else return;
                }}
              />
            </ComponentWithStatus>
          </>
        ) : (
          <ComponentWithStatus
            status={status}
            Placeholder={<TableComponentPlaceholder count={4} />}
          >
            <TableComponent
              scroll={{ x: 'calc(100vw - 213px)', y: 'calc(100vh - 23em)' }}
              style={{
                height: 'calc(100vh - 22em)',
                minHeight: 'calc(100vh - 22em)'
              }}
              rowKey="categoryId"
              columns={columns}
              dataSource={data}
              data={data}
              role={role}
              rowClassName={allowPermision(role, rolesAllow) ? 'hoverRow' : ''}
              isModalOpen={isModalOpen}
              setIsModalOpen={() => {
                if (!isModalOpen) {
                  toggleModal();
                }
              }}
              hookUseStateActions={{
                setModalProps
              }}
              className="categoriesTable"
            />
          </ComponentWithStatus>
        )}
      </TableLayoutPage>
      {/*<ModalComponent*/}
      {/*  displayFooter={false}*/}
      {/*  className='top-role-popup'*/}
      {/*  isOpen={isTableModal}*/}
      {/*  setIsOpen={setIsTableModal}*/}
      {/*  footerButtons={false}*/}
      {/*  displayFooterCloseButton={false}*/}
      {/*  displayFooterSubmitButton={false}*/}
      {/*  maskClosable={true}*/}
      {/*>*/}
      {/*  <section className='top-role-popup-buttons'>*/}
      {/*    <ButtonComponent*/}
      {/*      className={`text-bold-normal btn-default-custom ${theme}`}*/}
      {/*      style={{ marginBottom: '12px' }}*/}
      {/*      onClick={() => {*/}
      {/*        setModalProps((state) => ({*/}
      {/*          ...state,*/}
      {/*          scope: 'view',*/}
      {/*          categoryId: isCategoryID,*/}
      {/*          modalTitle: <FormattedMessage id='ViewCategoryModalTitle' />,*/}
      {/*          cancelButtonText: <FormattedMessage id='cancel' />,*/}
      {/*          submitButtonText: <FormattedMessage id='save' />,*/}
      {/*          width: '100% !important'*/}
      {/*        }));*/}
      {/*        if (status !== 'success') {*/}
      {/*          return;*/}
      {/*        }*/}
      {/*        setIsTableModal(false);*/}
      {/*        toggleModal();*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <FormattedMessage id='ViewOrEditBtnText' />*/}
      {/*    </ButtonComponent>*/}
      {/*    <ButtonComponent*/}
      {/*      className={`text-bold-normal btn-default-custom ${theme}`}*/}
      {/*      style={{ marginTop: '12px' }}*/}
      {/*      onClick={() => {*/}
      {/*        setModalProps((state) => ({*/}
      {/*          ...state,*/}
      {/*          scope: 'remove',*/}
      {/*          categoryId: isCategoryID,*/}
      {/*          modalTitle: <FormattedMessage id='RemoveCategoryModalTitle' />,*/}
      {/*          cancelButtonText: <FormattedMessage id='cancel' />,*/}
      {/*          submitButtonText: <FormattedMessage id='delete' />,*/}
      {/*          width: '100%'*/}
      {/*        }));*/}
      {/*        if (status !== 'success') {*/}
      {/*          return;*/}
      {/*        }*/}
      {/*        setIsTableModal(false);*/}
      {/*        toggleModal();*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <FormattedMessage id='remove' />*/}
      {/*    </ButtonComponent>*/}
      {/*  </section>*/}
      {/*</ModalComponent>*/}
    </>
  );
};

export default ViewManageCategories;
