import * as React from 'react';
export const AddUserSvg = () => {
  return (
    <span role='img' aria-label='addUser' className='anticon anticon-addUser'>
      <svg
        width='1em'
        height='1em'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.400391 20.5998C0.400329 21.1521 0.847993 21.5998 1.40028 21.5999C1.95256 21.6 2.40033 21.1523 2.40039 20.6L0.400391 20.5998ZM1.4008 16.9995L2.4008 16.9996L1.4008 16.9995ZM11.6004 14.3999C12.1527 14.3999 12.6004 13.9522 12.6004 13.3999C12.6004 12.8476 12.1527 12.3999 11.6004 12.3999V14.3999ZM20.6004 15.5999C21.1527 15.5999 21.6004 15.1522 21.6004 14.5999C21.6004 14.0476 21.1527 13.5999 20.6004 13.5999V15.5999ZM15.2004 13.5999C14.6481 13.5999 14.2004 14.0476 14.2004 14.5999C14.2004 15.1522 14.6481 15.5999 15.2004 15.5999V13.5999ZM16.9004 17.2998C16.9004 17.852 17.3481 18.2998 17.9004 18.2998C18.4527 18.2998 18.9004 17.852 18.9004 17.2998H16.9004ZM18.9004 11.8998C18.9004 11.3475 18.4527 10.8998 17.9004 10.8998C17.3481 10.8998 16.9004 11.3475 16.9004 11.8998H18.9004ZM12.4004 4.9999C12.4004 6.43584 11.2363 7.5999 9.80039 7.5999V9.5999C12.3409 9.5999 14.4004 7.54041 14.4004 4.9999H12.4004ZM9.80039 7.5999C8.36445 7.5999 7.20039 6.43584 7.20039 4.9999H5.20039C5.20039 7.54041 7.25988 9.5999 9.80039 9.5999V7.5999ZM7.20039 4.9999C7.20039 3.56396 8.36445 2.3999 9.80039 2.3999V0.399902C7.25988 0.399902 5.20039 2.45939 5.20039 4.9999H7.20039ZM9.80039 2.3999C11.2363 2.3999 12.4004 3.56396 12.4004 4.9999H14.4004C14.4004 2.45939 12.3409 0.399902 9.80039 0.399902V2.3999ZM2.40039 20.6L2.4008 16.9996L0.400795 16.9994L0.400391 20.5998L2.40039 20.6ZM5.00079 12.3999C2.46049 12.3999 0.401081 14.4591 0.400795 16.9994L2.4008 16.9996C2.40096 15.5638 3.56497 14.3999 5.00079 14.3999V12.3999ZM5.00079 14.3999H11.6004V12.3999H5.00079V14.3999ZM20.6004 13.5999H17.9004V15.5999H20.6004V13.5999ZM17.9004 13.5999H15.2004V15.5999H17.9004V13.5999ZM18.9004 17.2998V14.5999H16.9004V17.2998H18.9004ZM18.9004 14.5999V11.8998H16.9004V14.5999H18.9004Z'
          fill='currentColor'
        />
      </svg>
    </span>
  );
};
