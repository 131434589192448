import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

const useGetSearchParam = (
  searchParamName,
  convertTo: 'number' | 'object' | 'default'
) => {
  const [searchParams] = useSearchParams();

  const parsedParam = useMemo(() => {
    let param = searchParams.get(searchParamName);

    if (convertTo === 'number') {
      return +param;
    } else if (convertTo === 'object') {
      return { searchParamName: param };
    } else {
      return param;
    }
  }, []);

  return { parsedParam };
};

export default useGetSearchParam;
