import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Form, Input } from 'antd';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FormattedMessage } from 'react-intl';
import { onFinishSubCategories } from '../utils';
import { useDispatch } from 'react-redux';
import { useModalContext } from '../../../Modal/ModalProvider';
type SettingsSubCategoryFormProps = {
  scope: string;
  categoryId: number | string | null;
  subCategory: any;
};

export const SettingsSubCategoryForm: React.FC<
  SettingsSubCategoryFormProps
> = ({ scope, categoryId, subCategory }) => {
  const [form] = Form.useForm();
  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const { toggleModal } = useModalContext();
  const onFinishFormActions = { dispatch, toggleModal };

  return (
    <Form
      form={form}
      id="ticketingSubCategoryForm"
      layout="horizontal"
      labelAlign="left"
      labelCol={{ span: 6 }}
      onFinish={data => {
        onFinishSubCategories(
          scope,
          data,
          categoryId,
          subCategory,
          onFinishFormActions
        );
      }}
      initialValues={
        scope !== 'add'
          ?
            {
              name: subCategory.name,
              key: subCategory.key
            }
          : null
      }
    >
      {scope === 'remove' ? (
        <>
          <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
            <FormattedMessage
              id="SubCategoryRemoveConfirmText"
              values={{
                subcategoryName: subCategory?.name
              }}
            />
          </p>
        </>
      ) : (
        <>
          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="SettingsSubcategory_Name" />}
            name="name"
            isReadOnly={
              isMobile
                ? `col-form-item-component-view ${theme}`
                : `col-form-item-component ${theme}`
            }
            rules={[
              {
                required: true,
                message: <FormattedMessage id="provide_subcategory_name_warning" />,
              },
            ]}
            required={true}
          >
            <Input minLength={1} />
          </ColFormItemComponent>
        </>
      )}
    </Form>
  );
};
