import { IRole } from 'services/providers/EmployeeProvider/types';
import { EventTypes } from '../types';

export const EventTypesManipulatePerRole: Record<
  Exclude<IRole, 'None'>,
  { Mutate: Array<EventTypes>; View: Array<EventTypes> }
> = {
  Admin: {
    Mutate: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ],
    View: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ]
  },

  Accountant: {
    Mutate: [EventTypes.PersonalEvent],
    View: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ]
  },
  HR: {
    Mutate: [EventTypes.PersonalEvent],
    View: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ]
  },
  TL: {
    Mutate: [EventTypes.PersonalEvent, EventTypes.TeamEvent],
    View: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ]
  },
  Employee: {
    Mutate: [EventTypes.PersonalEvent],
    View: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ]
  },
  Intern: {
    Mutate: [EventTypes.PersonalEvent],
    View: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ]
  },
  External: {
    Mutate: [EventTypes.PersonalEvent],
    View: [
      EventTypes.Holiday,
      EventTypes.PersonalEvent,
      EventTypes.TeamEvent,
      EventTypes.CompanyEvent
    ]
  }
};
