import React from "react";

interface MenuProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

const Menu = ({ style, children, className = "" }: MenuProps) => {
  return (
    <nav style={style} className={`navbar ${className}`}>
      {children}
    </nav>
  );
};

export default Menu;
