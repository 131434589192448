import { PayloadAction } from '@reduxjs/toolkit';
import { saveState } from 'context/utils';
import { LoadingStatus } from 'models/enums/LoadingStatus';
import {
  TimeOffColleaguesDefaultState,
  TimeOffRequestsAndResponse
} from '../types';
import { TimeOffColleaguesActions } from './actions';

export const timeOffColleaguesRequestsStateKey = 'timeOffColleaguesRequests';
export const initialState: TimeOffRequestsAndResponse = {
  filterBy: {
    policies: null,
    period: {
      type: 'AllPeriods',
      startDate: null,
      endDate: null,
      customTimeRangeCriteria: 'ContainDatesFrom'
    }
  },
  filters: {
    policies: null,
    period: null
  },
  sortBy: null,
  pagination: {
    resultSize: 20,
    pageNumber: 1
  }
};

export const defaultState: TimeOffColleaguesDefaultState = {
  admin: {
    filterBy: {
      customStatuses: ["Pending", "RevokePending", "Unfinished"]
    },
    filters: {
      customStatuses: ["Pending", "RevokePending", "Unfinished"]
    }
  },
  employee: {
    filterBy: {
      customStatuses: null
    },
    filters: {
      customStatuses: null
    },
    sortBy: {
      startDate: 'Descend'
    }
  }
};

export const colleaguesTimeOffReducer = (
  state: TimeOffRequestsAndResponse,
  { type, payload }: PayloadAction<any>
) => {
  switch (type) {
    case TimeOffColleaguesActions.INIT_STATE:
      saveState(timeOffColleaguesRequestsStateKey, {
        pagination: {
          resultSize: payload?.pagination?.resultSize
        }
      });
      return {
        ...payload
      };

    case TimeOffColleaguesActions.FETCH_PENDING:
      return {
        ...state,
        status: LoadingStatus.Pending
      };

    case TimeOffColleaguesActions.FETCH_SUCCESS:
      const data = {
        ...state,
        ...payload,
        status: LoadingStatus.Success
      };
      return data;

    case TimeOffColleaguesActions.FETCH_ERROR:
      return {
        ...state,
        ...payload,
        status: LoadingStatus.Error
      };

    case TimeOffColleaguesActions.UPDATE_TOOLBAR_STATUS_FILTER:
      return {
        ...state,
        filterBy: {
          ...state?.filterBy,
          customStatuses: payload?.filterBy?.customStatuses
        },
        filters: {
          ...state?.filters,
          customStatuses: payload?.filters?.customStatuses
        },
        pagination: {
          resultSize: state?.pagination?.resultSize,
          pageNumber: 1
        },
        requestId: payload?.requestId
      };

    case TimeOffColleaguesActions.UPDATE_TOOLBAR_POLICY_FILTER:
      return {
        ...state,
        filterBy: {
          ...state?.filterBy,
          policies: payload?.filterBy?.policies
        },
        filters: {
          ...state?.filters,
          policies: payload?.filters?.policies
        },
        pagination: {
          resultSize: state?.pagination?.resultSize,
          pageNumber: 1
        },
        requestId: payload?.requestId
      };

    case TimeOffColleaguesActions.UPDATE_TOOLBAR_PERIOD_FILTER:
      return {
        ...state,
        filterBy: {
          ...state?.filterBy,
          period: {
            type: payload?.filterBy?.period?.type,
            startDate: payload?.filterBy?.period?.startDate,
            endDate: payload?.filterBy?.period?.endDate,
            customTimeRangeCriteria:
              state?.filterBy?.period?.customTimeRangeCriteria
          }
        },
        filters: {
          ...state?.filters,
          period:
            payload?.filters?.period?.type === 'AllPeriods'
              ? null
              : {
                  type: payload?.filters?.period?.type,
                  startDate: payload?.filters?.period?.startDate,
                  endDate: payload?.filters?.period?.endDate,
                  customTimeRangeCriteria:
                    state?.filterBy?.period?.customTimeRangeCriteria
                }
        },
        pagination: {
          resultSize: state?.pagination?.resultSize,
          pageNumber: 1
        },
        requestId: payload?.requestId
      };

    case TimeOffColleaguesActions.UPDATE_TOOLBAR_CRITERIA_FILTER:
      return {
        ...state,
        filterBy: {
          ...state?.filterBy,
          period: {
            ...state?.filterBy?.period,
            customTimeRangeCriteria:
              payload?.filterBy?.period?.customTimeRangeCriteria
          }
        },
        filters: {
          ...state?.filters,
          period:
            state?.filters?.period === null
              ? null
              : {
                  ...state?.filters?.period,
                  customTimeRangeCriteria:
                    payload?.filterBy?.period?.customTimeRangeCriteria
                }
        },
        pagination: {
          resultSize: state?.pagination?.resultSize,
          pageNumber: 1
        },
        requestId: payload?.requestId
      };

    case TimeOffColleaguesActions.UPDATE_TABLE_FILTER:
      return {
        ...state,
        filterBy: {
          ...state?.filterBy,
          requesterName: payload?.filterBy?.requesterName,
          teamIds: payload?.filterBy?.teamIds,
          nextApproverIds: payload?.filterBy?.nextApproverIds
        },
        filters: {
          ...state?.filters,
          requesterName: payload?.filters?.requesterName,
          teamIds: payload?.filters?.teamIds,
          nextApproverIds: payload?.filters?.nextApproverIds
        },
        pagination: {
          resultSize: state?.pagination?.resultSize,
          pageNumber: payload.pagination.pageNumber
        },
        requestId: payload?.requestId
      };

    case TimeOffColleaguesActions.UPDATE_TABLE_PAGE_NUMBER:
      return {
        ...state,
        filterBy: {
          ...state?.filterBy
        },
        filters: {
          ...state?.filters
        },
        pagination: {
          ...state?.pagination,
          pageNumber: payload?.pagination?.pageNumber
        }
      };
    case TimeOffColleaguesActions.UPDATE_TABLE_RESULT_SIZE:
      saveState(timeOffColleaguesRequestsStateKey, {
        pagination: {
          resultSize: payload?.pagination?.resultSize
        }
      });
      return {
        ...state,
        filterBy: {
          ...state?.filterBy
        },
        filters: {
          ...state?.filters
        },
        pagination: {
          ...state?.pagination,
          resultSize: payload?.pagination?.resultSize
        }
      };

    case TimeOffColleaguesActions.UPDATE_REQUEST_ID:
      return {
        ...state,
        requestId: payload?.requestId
      };

    default:
      return state;
  }
};
