import { Button, Col, Typography } from 'antd';
import { useIntlContext, useMobileContext, useThemeContext } from 'AppProvider/ConfigProviderSettings';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import { AttendanceComponentPlaceholder } from 'components/Placeholders/Page';
import { TableCustom } from 'components/UIComponents/TableCustom';
import dayjs from 'dayjs';
import { ArrowLeft } from 'Icons/Documents/arrowLeft';
import { ArrowRight } from 'Icons/TimeManagementWidget/arrowRight';
import { EventsStatusDefinitions, legentColors } from 'pages/TimeManagement/Vacation/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCompanySettings } from 'settings';
import { columnsAttendanceReport } from './columnsAttendanceReport';


const AttendanceTable = ({ data, status, dateOfMonth, setDateOfMonth, hookUseStateActions }) => {
  const { locale } = useIntlContext();
  const { Title } = Typography;
  const { isMobile, deviceHeight } = useMobileContext();
  const scrollY = `${deviceHeight - 360}px`;
  const { theme } = useThemeContext();
  const intl = useIntl();
  const { countryCode } = useCompanySettings();

  const params = {
    locale: locale,
    theme: theme,
    intl: intl,
    countryCode: countryCode
  };

  const updateYear = (offset: number) => {
    // Add or subtract years based on the offset
    const newDate = dayjs(dateOfMonth.date).add(offset, 'year');
    const month = newDate.format('MMMM');
    const year = newDate.year();

    setDateOfMonth({
      date: newDate,
      month: month,
      year: year
    });
  };


  return (
    <TableLayoutPage
      title={<FormattedMessage id="AttendanceRepportTitle_Attendances" />}
      style={{
        height: `calc(${deviceHeight}px - 125px)`,
        gap: '16px',
      }}
      displayToggle
      className={`attendance_table_layout_page ${theme}`}
      childrenButton={
        <>
          <Col>
            <div className="flex-center-container">
              <Button
                type="text"
                icon={<ArrowLeft />}
                onClick={() => {
                  updateYear(-1);
                }}
                className="arrows-calendar"
              />
              <Title
                level={3}
                className={`align-padding-title ${theme} month-title `}
              >
                <FormattedMessage id={"AsideMenuBarAndItems_year"} />{' '}
                {dateOfMonth.year}
              </Title>
              <Button
                type="text"
                icon={<ArrowRight />}
                onClick={() => {
                  updateYear(1);
                }}
                className="arrows-calendar"
              />
            </div>
          </Col>
        </>
      }
    >
      <ComponentWithStatus
        status={status}
        Placeholder={<AttendanceComponentPlaceholder />}
      >
        <TableCustom
          columns={columnsAttendanceReport(params, hookUseStateActions)}
          data={data?.months || []}
          pagination={false}
          className={`table_attendance ${theme}`}
          columnRowClassName="header_col_attendance"
          style={{
            height: "100%"
          }}
        />
      </ComponentWithStatus>

      <div className="legend" style={{ bottom: '5vh' }}>
          {legentColors.filter((item) => item.request !== "PendingRequest").map((item, key) => (
            <div className="items_legend" key={key}>
              <div
                className="symbol"
                style={{ background: item?.color }}
              ></div>
              <div className={`description ${theme}`}>
                <FormattedMessage id={item?.request} />
              </div>
            </div>
          ))} 
        
      </div>
    </TableLayoutPage>
  );
};

export default AttendanceTable;