import { AdditionalFieldSchema, DaysAllowedType } from './types';
import { adminRoles, allowPermision, userRoles } from 'utils/userManagement';
import dayjs from 'dayjs';
import { getTimeDiffDaysBasedOnCalendarType } from './utils';
import { IntlShape } from 'react-intl';
import { useAuth } from '../../../../services/providers/AuthProvider';

export const validateDates = (
  value,
  requestStatus: string,
  daysAllowed: number,
  timeOffRequestType: string,
  isRegularFormSubmit: boolean,
  intl,
  daysAllowedType: DaysAllowedType,
  rootSelector
) => {
  const isDraftRequest = requestStatus === 'Draft' ? true : false;
  const bothDatesValidationErrorText = intl.formatMessage({
    id: 'ValidateStartAndEndDateErrorMessage'
  });
  const startDateValidationErrorText = intl.formatMessage({
    id: 'ValidateStartDateErrorMessage'
  });
  const endDateValidationErrorText = intl.formatMessage({
    id: 'ValidateEndDateErrorMessage'
  });

  if (!value) {
    return Promise.reject(new Error(bothDatesValidationErrorText));
  }

  if (!value[0]) {
    return Promise.reject(new Error(startDateValidationErrorText));
  }
  const doesPolicyAllowBlankEndDate = [
    'Medical',
    'Childcare',
    'Bereavement',
  ].includes(timeOffRequestType);
  const isUnfinishedRequest = !isRegularFormSubmit;
  
  if (
    !value[1] &&
    ((!isUnfinishedRequest && isDraftRequest && !doesPolicyAllowBlankEndDate) ||
      (!isUnfinishedRequest) ||
      (isUnfinishedRequest && !doesPolicyAllowBlankEndDate))
  ) {
    return Promise.reject(new Error(endDateValidationErrorText));
  }

  const dateDifferenceBetweenDates =
    value && value[0] && value[1]
      ? getTimeDiffDaysBasedOnCalendarType(
          [value[0], value[1]],
          daysAllowedType,
          rootSelector
        )
      : 0;

  const allowedDaysErrorMessage = intl.formatMessage(
    {
      id: 'ExceededAllowedDaysErrorMessage'
    },
    {
      difference: dateDifferenceBetweenDates?.toString(),
      allowed: daysAllowed?.toString()
    }
  );

  if (daysAllowed === null || dateDifferenceBetweenDates <= daysAllowed) {
    return Promise.resolve();
  }

  return Promise.reject(new Error(allowedDaysErrorMessage));
};

export const validateSingleDate = (
  value,
  intl: IntlShape,
  daysAllowed: number
) => {
  const dateValidateionErrorMessage = intl.formatMessage({
    id: 'DateSubmittingErrorMessage'
  });

  const allowedDaysErrorMessage = intl.formatMessage(
    {
      id: 'ExceededDaysErrorMessage'
    },
    {
      allowed: daysAllowed?.toString()
    }
  );

  if (daysAllowed <= 0 && daysAllowed !== null) {
    return Promise.reject(new Error(allowedDaysErrorMessage));
  }

  if (!value) {
    return Promise.reject(new Error(dateValidateionErrorMessage));
  }
  return Promise.resolve();
};

export const validateAdditionalFields = (
  value,
  field: AdditionalFieldSchema,
  intl
) => {
  const validationErrorMessage = intl.formatMessage({
    id: 'ValidationAdditionalFieldsErrorMessage',
    values: field.displayName
  });

  return (field.isRequired &&
    (field.fieldType === 'timeRange'
      ? value && value[0] && value[1]
      : value)) ||
    !field.isRequired
    ? Promise.resolve()
    : Promise.reject(new Error(validationErrorMessage));
};

export const allowedDateSelectionOnTimeBasedRequest = (
  current: dayjs.Dayjs,
  userRole: string,
  countryCode: string,
  scope: string,
  requestedFor: object,
  requester: object
) =>
  allowPermision(userRole, userRoles.Admin) ||
  (userRole === userRoles.HR && 'ColleagueProfile' === scope) ||
  (requestedFor && requestedFor['id'] !== requester['employeeId'])
    ? false
    : current && countryCode === 'RO'
      ? current < dayjs().startOf('month')
      : current < dayjs().startOf('day');

export const allowedTimeSelection = (
  current: dayjs.Dayjs,
  selectedDate: dayjs.Dayjs,
  requestTypeoff: string,
  tenantCountryCode: string,
  userRole: string,
  scope: string,
  requestedFor: object,
  requester: object
): boolean => {
  const allowedRetroActiveTimeRequestPolicies = ['LeaveOffsetting', 'Overtime'];

  const selectedDateTime = dayjs(selectedDate)
    .set('hour', current.get('hour'))
    .set('minute', current.get('minute'));
  return allowedRetroActiveTimeRequestPolicies.includes(requestTypeoff) ||
    tenantCountryCode === 'RO' ||
    allowPermision(userRole, userRoles.Admin) ||
    (userRole === userRoles.HR && 'ColleagueProfile' === scope) ||
    (requestedFor && requestedFor['id'] !== requester['employeeId'])
    ? false
    : selectedDateTime < dayjs();
};

export const allowedDateRangePickerSelection = (
  current: dayjs.Dayjs,
  isStartDateSelected: boolean,
  tenantCountryCode: string,
  requestTypeoff: string,
  workingSchedule: string,
  userRole: string,
  scope: string,
  requestedFor: object,
  requester: object
) => {
  //For employee gambit User can select start date only working days.
  if (
    current &&
    tenantCountryCode === 'RO' &&
    (!allowPermision(userRole, [userRoles.Admin, userRoles.HR]) ||
      (userRole === userRoles.HR && 'request' === scope) ||
      (requestedFor && requestedFor['id'] === requester['employeeId']))
  )
    return (
      current < dayjs().startOf('month') ||
      (isStartDateSelected && (current.day() === 0 || current.day() === 6))
    );

  if (
    allowPermision(userRole, userRoles.Admin) ||
    (userRole === userRoles.HR && 'ColleagueProfile' === scope) ||
    (requestedFor && requestedFor['id'] !== requester['employeeId'])
  ) {
    return false; //allow retroactive requests for admin for requestOnBehalf
  }

  if (workingSchedule !== 'FullTime' && requestTypeoff === 'WorkFromHome')
    return current < dayjs().startOf('day');

  if (requestTypeoff === 'WorkFromHome')
    return (
      current < dayjs().startOf('day') ||
      current.day() === 0 ||
      current.day() === 6
    );

  return (
    current < dayjs().startOf('day') ||
    (isStartDateSelected && (current.day() === 0 || current.day() === 6))
  );
};
