import { Typography } from 'antd';
import styles from './previewToolbar.module.less';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

const PreviewToolbar = ({ children }) => {
  const { theme } = useThemeContext();

  return (
    <>
      <div className={styles.headerOverlay + ` ${theme}`}>
        <div style={{ flexGrow: 1 }}></div>
        <Typography.Text>{children}</Typography.Text>
      </div>
      <div className={styles.overlay}></div>
    </>
  );
};
export default PreviewToolbar;
