export const DarkThemeComponent = (props) => {
  return (
    <span
      role="img"
      aria-label="import"
      className="anticon anticon-import dark-theme-icon"
      {...props}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4 9.75971C13.7985 9.94213 13.1603 10.0402 12.4992 10.0402C8.88723 10.0402 5.95912 7.11212 5.95912 3.50012C5.95912 2.83931 6.05713 2.20139 6.23939 1.6001C3.55444 2.41435 1.6001 4.90879 1.6001 7.85969C1.6001 11.4717 4.5282 14.3998 8.1402 14.3998C11.0914 14.3998 13.586 12.4451 14.4 9.75971Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
