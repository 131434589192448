export const OffsettingLeaveComponent = () => {
  return (
    <span role='img' aria-label='vacantion' className='anticon anticon-vacantion'>
      <svg width='2.5rem'
        height='2.5rem'
        viewBox='0 0 41 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M0 18.9999C0 9.03599 8.07937 0.958984 18.0454 0.958984C24.7256 0.958984 30.5571 4.58827 33.6761 9.97884C33.8761 10.3244 33.7581 10.7666 33.4125 10.9666C33.0669 11.1665 32.6247 11.0485 32.4247 10.7029C29.5536 5.74076 24.1888 2.40477 18.0454 2.40477C8.8775 2.40477 1.44578 9.83483 1.44578 18.9999C1.44578 28.1649 8.8775 35.595 18.0454 35.595C27.2133 35.595 34.645 28.1649 34.645 18.9999C34.645 18.6006 34.9686 18.277 35.3679 18.277C35.4976 18.277 35.6194 18.3112 35.7247 18.371L34.9465 17.593L31.127 21.4116C30.8446 21.6939 30.3869 21.6938 30.1046 21.4114C29.8224 21.1291 29.8224 20.6714 30.1048 20.3891L34.4354 16.0596C34.7177 15.7774 35.1753 15.7774 35.4576 16.0596L39.7882 20.3891C40.0705 20.6714 40.0706 21.1291 39.7883 21.4114C39.5061 21.6938 39.0484 21.6939 38.766 21.4116L35.9967 18.6429C36.0566 18.7483 36.0908 18.8701 36.0908 18.9999C36.0908 28.9638 28.0114 37.0408 18.0454 37.0408C8.07937 37.0408 0 28.9638 0 18.9999ZM18.0409 11.7827C18.4401 11.7827 18.7638 12.1064 18.7638 12.5056V21.0364L24.7638 23.0364C25.1425 23.1626 25.3472 23.572 25.2209 23.9508C25.0947 24.3295 24.6853 24.5342 24.3066 24.408L17.8123 22.2432C17.5171 22.1448 17.318 21.8686 17.318 21.5574V12.5056C17.318 12.1064 17.6417 11.7827 18.0409 11.7827Z" fill="currentColor"/>
      </svg>
    </span>
  );
};
