import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { CustomMenuItemType } from 'components/Layout/Menu/CustomMenuItemType';
import 'components/Layout/Menu/Menu.less';
import Dropdown from 'components/Layout/Menu/components/Dropdown';
import { DropdownContext } from 'components/Layout/Menu/components/Dropdown/DropdownContext';
import Menu from 'components/Layout/Menu/components/Menu';
import useResizeObserver from 'hooks/useResizeObserver.hook';
import React from 'react';

interface DesktopMenuProps {
  theme: 'dark' | 'light';
  items: CustomMenuItemType[];
  style?: React.CSSProperties;
  className?: string;
  currentLocation: string;
}

interface DropdownData {
  isDropdownOpen: boolean;
  ref: React.RefObject<HTMLElement>;
  dropdownHeight: number;
  dropdownWidth: number;
}

const DesktopMenu = ({
  theme,
  items = [],
  style,
  className = '',
  currentLocation
}: DesktopMenuProps) => {
  // Constants
  const ITEM_HEIGHT = 56;
  const TOTAL_GAP = items?.length * 8;
  const TOTAL_SPACE = 96;

  // Context
  const { isTabletDevice, deviceWidth, deviceHeight } = useMobileContext();
  const { dropdownKey, setDropdownKey } = React.useContext(DropdownContext);

  // States
  const [isExtended, setIsExtended] = React.useState<boolean>(false);
  const [perPage, setPerPage] = React.useState<number>(0);
  const [arrows, setArrows] = React.useState<boolean>(false);
  const [dropdownData, setDropdownData] = React.useState<DropdownData>({
    isDropdownOpen: false,
    ref: null,
    dropdownHeight: ITEM_HEIGHT,
    dropdownWidth: ITEM_HEIGHT
  });

  // Refs
  const asideElementRef = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    return () => {
      setIsExtended(false);
      setDropdownKey(null);
    };
  }, [currentLocation, deviceWidth, deviceHeight]);

  React.useEffect(() => {
    if (isExtended) {
      const handleResize = () => {
        setIsExtended(false);
        setDropdownKey(null);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isExtended]);

  const handleSubmenuChange = (dropdownProps: DropdownData): void => {
    setDropdownData(dropdownProps);
  };

  const handleWindowVerticalResize = (entries: ResizeObserverEntry[]): void => {
    // Extract the height of the
    const { height } = entries[0].contentRect;
    // Calculate the total height of all items
    const TOTAL_HEIGHT_OF_ITEMS: number =
      items.length * ITEM_HEIGHT +
      (dropdownData.dropdownHeight - ITEM_HEIGHT) +
      TOTAL_GAP;
    // Calculate the available height in the sidebar
    const availableHeight: number = height - TOTAL_SPACE;
    // Determine if arrows are needed based on the total height of items and available sidebar height
    const arrows: boolean = TOTAL_HEIGHT_OF_ITEMS > availableHeight + 8;
    // Update the state of arrows
    setArrows(arrows);
    // Calculate the number of items per page
    const perPage: number = Math.trunc(
      (height -
        TOTAL_SPACE -
        TOTAL_GAP -
        (dropdownData.isDropdownOpen ? dropdownData.dropdownHeight : 0)) /
        ITEM_HEIGHT
    );
    // Update the state of items per page
    setPerPage(perPage);
  };

  useResizeObserver(handleWindowVerticalResize, asideElementRef?.current);

  const handleMouseEvents = (() => {
    let timeoutId = null;

    const handleMouseClick = () => {
      setIsExtended(true);
    };

    const handleMouseEnter = () => {
      if (!isTabletDevice) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setIsExtended(true);
        }, 100);
      };
    };

    const handleMouseLeave = () => {
      clearTimeout(timeoutId);
      setIsExtended(false);
      setDropdownKey(null);
    };

    return {
      onClick: handleMouseClick,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave
    }
  })();

  return (
    <aside
      className={`side-navigation-bar--medium ${className} ${theme}`}
      ref={asideElementRef}
      aria-expanded={isExtended}
      style={{
        ...style,
        width: '88px',
        flex: '0 0 88px',
        maxWidth: '88px',
        minWidth: '88px'
      }}
      {...handleMouseEvents}
    >
      <Menu>
        <Splide
          options={{
            wheel: true,
            // trimSpace: true,
            omitEnd: true,
            pagination: false,
            perMove: 1,
            perPage: perPage,
            arrowPath:
              'M5.8033 8.7388C5.39889 8.33075 5.39891 7.66922 5.8033 7.2612L8.73223 4.30602C9.13663 3.898 9.79228 3.89798 10.1967 4.30602C10.6011 4.71407 10.6011 5.37559 10.1967 5.78361L8 8L10.1967 10.2164C10.6011 10.6244 10.6011 11.286 10.1967 11.694C9.7923 12.102 9.13665 12.102 8.73223 11.694L5.8033 8.7388Z',
            arrows: arrows,
            autoHeight: true,
            direction: 'ttb',
            drag: false,
            gap: '8px',
            height: 'auto'
            // slideFocus: true,
            // updateOnMove: true,
          }}
        >
          {items?.map((item, index) => (
            <SplideSlide key={`${item?.key}-${index}`}>
              {Array.isArray(item?.['children']) ? (
                <Dropdown
                  title={item?.['label']}
                  isDropdownOpen={dropdownKey === item?.key}
                  preffixIcon={item?.icon}
                  className={
                    currentLocation.includes(item?.key?.toString())
                      ? 'nav__item nav__item--active'
                      : 'nav__item'
                  }
                  onChange={handleSubmenuChange}
                  onClick={() =>
                    setDropdownKey(
                      dropdownKey === item?.key ? null : String(item?.key)
                    )
                  }
                >
                  {item['children'].map(
                    (child, index) =>
                      ![undefined, null].includes(child) && (
                        <li
                          key={`${child?.key}-${index}`}
                          className={
                            currentLocation === child?.key
                              ? 'dropdown__item dropdown__item--active'
                              : 'dropdown__item'
                          }
                        >
                          {child?.label}
                        </li>
                      )
                  )}
                </Dropdown>
              ) : (
                <div
                  className={
                    currentLocation === item?.key?.toString()
                      ? 'nav__item nav__item--active'
                      : 'nav__item'
                  }
                >
                  {item?.icon}
                  {item?.['label']}
                </div>
              )}
            </SplideSlide>
          ))}
        </Splide>
      </Menu>
    </aside>
  );
};

export default DesktopMenu;
