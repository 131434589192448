import { EventTypes, EventResponseDto } from 'pages/Calendar/types';
import { Col, Row } from 'antd';
import React from 'react';
import './eventsList.less';
import { EventItem } from './EventItem';
interface IEventList {
  data?: EventResponseDto[];
}
/** EventsList component
 * @param {IEventList} props.data - represents the array of events of EventDto type received as response from api/Event/getCalendarDays
 * @returns {Array<EventItem>} - an array of EventItem components that are either collapsible (multiple events of the same type), or non collapsible single event of corresponding eventType
 *
 * @see EventItem component description to understand when elements are collapsible and not
 */
const EventsList: React.FC<IEventList> = ({ data = [] }) => {
  const eventItemList: Array<JSX.Element> = [];
  const eventsGroupedByCategory = new Map<
    EventTypes,
    Array<EventResponseDto>
  >();
  data.forEach(event =>
    eventsGroupedByCategory.set(EventTypes[event.eventType], [
      ...(eventsGroupedByCategory.get(EventTypes[event.eventType]) || []),
      event
    ])
  );

  eventsGroupedByCategory.forEach((eventsList, eventType, map) => {
    eventItemList.push(
      eventsList.length > 1 ? (
        <EventItem
          key={eventType}
          {...{ eventsList }}
          eventObject={eventsList[0]}
          collapsible={true}
        />
      ) : (
        <EventItem
          key={eventType}
          eventObject={eventsList[0]}
          collapsible={false}
        />
      )
    );
  });

  return (
    <Row className="eventList-stack ">
      <Col flex="auto">
        <div className="eventList-container">{eventItemList}</div>
      </Col>
    </Row>
  );
};

export default EventsList;
