import { FormattedMessage, useIntl } from 'react-intl';
import ModalComponent from '../../../../components/Modal';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import './ViewTeamImage.less';
import * as React from 'react';
import { BaseButton, ButtonSimple } from '../../../../components/Buttons';
import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { getGradientIndex, gradients } from '../../../../utils/teamsGradients';



const ViewTeamImage = ({ isModalOpen, setIsModalOpen, teamImageSrc, teamName }) => {
  const ButtonCustom = BaseButton(ButtonSimple);
  const intl = useIntl();
  const { theme } = useThemeContext();

  const handleUploadClick = () => {
    setIsModalOpen(false)
    const inputElement = document.querySelector('.ant-upload input') as HTMLInputElement;
    inputElement.click();
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return <>
    <ModalComponent
      open={isModalOpen}
      setIsOpen={setIsModalOpen}
      isOpen={isModalOpen}
      style={{opacity: 0}}
      width={448}
      className="mobile-modal"
      title={capitalizeFirstLetter(intl.formatMessage({id: "team_image"}))}
      displayFooterCloseButton={false}
      displayFooterSubmitButton={false}
      formKeyId="employeeForm"
      afterClose={() => {
        setIsModalOpen(false)
      }}
      isMaskClosable={false}
      onCancel={handleCancel}
      footerButtons={
        <div className={'view-team-footer-buttons'}>
          <ButtonCustom
            className={`text-bold-normal btn-default-custom ${theme} cancel-btn`}
            key="closeModalButton"
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
          { <FormattedMessage id={'cancel'}/>}
          </ButtonCustom>
            <div className={'custom-buttons-container'}>
              <ButtonCustom
                      className={`text-bold-normal btn-default-custom  ${theme}`}
                      key="closeModalButton"
                      onClick={handleUploadClick}
                    >
                      { <FormattedMessage id={'upload'}/>}
                    </ButtonCustom>
              <ButtonCustom
                className={`text-bold-normal btn-primary-custom ${theme}`}
                type="primary"
                key="submitModalButton"
                htmlType="submit"
                id="submitButton"
                onClick={() => {
                  setIsModalOpen(false)
                }}
              >
                { <FormattedMessage id={'save'}/>}
              </ButtonCustom>
          </div>
        </div>
      }
    >
      <div className={'team-image'}>
        {teamImageSrc ?
          <img src={teamImageSrc} /> :
          <div style={{
              height: '300px',
              backgroundImage: gradients[getGradientIndex(teamName)]
            }}
          >
          </div>}
        <div className={"gradient-bg"}>
          <span className={'team-name'}>{teamName}</span>
        </div>
      </div>

    </ModalComponent>
  </>
}

export default ViewTeamImage