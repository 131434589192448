export const TimeOffMarkAsConfirmedIcon = ({ stroke }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.24995 5.49997L5.89395 8.49996L4.74998 7.47734M12.9999 3.24999L12.9999 10.75C12.9999 11.9926 11.9926 13 10.7499 13H3.24999C2.00735 13 1 11.9926 1 10.75V3.24999C1 2.00735 2.00735 1 3.24999 1H10.7499C11.9926 1 12.9999 2.00735 12.9999 3.24999Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};