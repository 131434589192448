import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { CardViewComponent } from 'Icons/Colleagues/cardViewComponent';
import { ListViewComponent } from 'Icons/Colleagues/listViewComponent';
import { AddUsersSvg } from 'Icons/addUsersComponent';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import LayoutPageMobileComponent from 'components/LayoutPageWithTitle.tsx/LayoutPageMobileComponent';
import ModalComponent from 'components/Modal';
import {
  ModalContextProvider,
  useModalContext
} from 'components/Modal/ModalProvider';
import {
  GridViewPagePlaceholder,
  TableComponentPlaceholder
} from 'components/Placeholders';
import CustomSwitch from 'components/Switch';
import TableComponent from 'components/Table';
import { useSwitchContext } from 'components/UIComponents/SwitchComponent/SwitchContextProvider';
import GridView from 'components/UIViews/GridView';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setTeams } from 'redux/slices/teamSlice';
import { RootState } from 'redux/store';
import { TeamsService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  rolesAllow
} from 'utils/userManagement';
import GridViewStyles from 'components/UIViews/GridView/GridView.module.less';
import TeamGrid from '../TeamGrid';
import { useTeamViewContext } from '../TeamViewContext/TeamViewContext';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { MyTeam } from 'services/providers/EmployeeProvider/types';
import { getTitleColumns } from 'components/TitlesColumns/utils';
import { Tooltip } from 'antd';
import { titlesKeysofColumnsTeams } from 'pages/Team/TeamsPage/utils';
import { getColumnSearchProps } from 'components/SearchTable';
import { ColumnsType } from 'antd/lib/table';

interface ButtonsType {
  show?: boolean;
  className?: string;
  type?: string;
  icon?: JSX.Element;
  iconSrc?: any;
  text: ReactNode;
  action?: any;
  isUploadButton?: boolean;
  isMultipleUpload?: boolean;
  uploadAccept?: string;
  uploadMaxCount?: number;
  uploadInProgress?: boolean;
  uploadShowList?: boolean;
  beforeUploadAction?: any;
  isDropdown?: boolean;
  dropdownOverlay?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
}

const TeamsList = ({ teamsData, statusTeam, params, employeId }) => {
  const { data } = useApiRequestHook<MyTeam[]>(
    TeamsService.getMyTeams,
    null,
    [],
    {},
    null,
    [],
    false,
    true
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const { theme } = useThemeContext();
  const { isToggleChecked, toggleSwitch } = useSwitchContext();
  const { isMobile, deviceHeight } = useMobileContext();
  const { user } = useAuth();
  const {
    isTopRoleModalOpen,
    setIsTopRoleModalOpen,
    toggleModal,
    toggleTopRoleModal
  } = useModalContext();
  const { setDisplayMembers } = useTeamViewContext();

  const methodUpdateSelector = useSelector(
    (state: RootState) => state.methodUpdate
  );

  const [page, setPage] = useState(1);

  const teamsId: number[] =
    data && data.length && data.map(employee => employee.teamId);

  const role = getCurrentUserRole(user);
  const userRole = getCurrentUserRole(user);
  const isAllowedToAccessAllTeams = allowPermision(userRole, rolesAllow);

  const ButtonComponent = BaseButton(ButtonSimple);

  const topRoleButtons: ButtonsType[] = [
    {
      icon: <AddUsersSvg />,
      // text: 'create team',
      text: (
        <span>
          <FormattedMessage id="createTeam" />
        </span>
      ),
      action: () => {
        toggleTopRoleModal();
        toggleModal();
      },
      show: allowPermision(role, adminRoles)
    }
  ];

  useEffect(() => {
    if (methodUpdateSelector.isMethodUpdated === false) {
      dispatch(setTeams(teamsData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsData, methodUpdateSelector.isMethodUpdated]);

  useEffect(() => {
    if (location.pathname === '/teams') {
      setDisplayMembers(false);
    }
  });

  const DISPLAY_MODE_KEY = 'displayTeamsAsGrid';

  const getDisplayMode = () => {
    const mode = window.localStorage.getItem(DISPLAY_MODE_KEY);
    return mode ?? 'List';
  };

  const toggleDisplayMode = () => {
    const currentMode = getDisplayMode();
    const newMode = currentMode === 'List' ? 'Cards' : 'List';
    window.localStorage.setItem(DISPLAY_MODE_KEY, newMode);

    toggleSwitch();
  };

  const localStorageListInventaory = getDisplayMode();

  useEffect(() => {
    if (localStorageListInventaory != null) {
      if (
        (isToggleChecked && localStorageListInventaory === 'List') ||
        (!isToggleChecked && localStorageListInventaory === 'Cards')
      ) {
        toggleSwitch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageListInventaory]);

  const columns = (
    data,
    role,
    params,
    isModalOpen,
    setIsModalOpen,
    stateActions,
    dataSource
  ): ColumnsType<any> => {
    //In Future need to check access data for ROLE
    const additionalCustomColumns = [
      {
        title: getTitleColumns('countMembers', 'teams'),
        key: 'countMembers',
        render: (_, record) => <p>{record.membersCount}</p>
      }
    ];

    const tableColumns = [
      {
        title: getTitleColumns('name', 'teams'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name', '_'),
        render: (_, record) => {
          const isEmployeeInTeam = teamsId && teamsId.includes(record.teamId);

          return (
            <div className={'line-clamp-1'}>
              <Tooltip placement="top" title={record?.name}>
                {isEmployeeInTeam || role ? (
                  <Link
                    onClick={() => {
                      localStorage.setItem('team-id', record.teamId);
                    }}
                    to={`viewTeam?teamId=${record.teamId}`}
                  >
                    {record.name}
                  </Link>
                ) : (
                  <>{record.name}</>
                )}
              </Tooltip>
            </div>
          );
        }
      },
      {
        title: getTitleColumns('leadEmployeeName', 'teams'),
        dataIndex: 'leadEmployeeName',
        key: 'leadEmployeeName',
        render: (_, record) => {
          return (
            <div className={'line-clamp-1'}>
              <Tooltip placement="top" title={record?.leadEmployeeName}>
                {role ? (
                  <Link
                    to={`/allUsersList/viewProfile?employeeId=${record.leadEmployeeId}`}
                  >
                    {record.leadEmployeeName}
                  </Link>
                ) : (
                  <div
                    style={{
                      width: '180px',
                      display: 'flex',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <span
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {record.leadEmployeeName ?? ''}
                    </span>
                  </div>
                )}
              </Tooltip>
            </div>
          );
        }
      }
    ];

    const dataSourceKeys = dataSource.length && dataSource[0];

    let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
      titlesKeysofColumnsTeams.some(item => key === item.dataIndex)
    );

    const checkForDataDisplay = (column: any) =>
      keysDataSource.includes(column.dataIndex);

    return [
      ...tableColumns.filter(checkForDataDisplay),
      ...additionalCustomColumns
    ];
  };

  const columnsMobileTeams = (
    data,
    role,
    params,
    isModalOpen,
    setIsModalOpen,
    stateActions,
    dataSource
  ): ColumnsType<any> => {
    //In Future need to check access data for ROLE
    const additionalCustomColumns = [
      {
        title: getTitleColumns('countMembers', 'teams'),
        key: 'countMembers',
        width: '25%',
        ellipsis: {
          showTitle: false
        },
        render: (_, record) => <p>{record.membersCount}</p>
      }
    ];

    const tableColumns = [
      {
        title: getTitleColumns('name', 'teams'),
        dataIndex: 'name',
        key: 'name',
        width: '40%',
        ellipsis: {
          showTitle: false
        },
        render: (_, record) => {
          const isEmployeeInTeam = teamsId && teamsId.includes(record.teamId);

          return isEmployeeInTeam || role ? (
            <Link
              className="teams-link"
              onClick={() => localStorage.setItem('team-id', record.teamId)}
              to={`viewTeam?teamId=${record.teamId}`}
            >
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {record.name}
                </span>
              </div>
            </Link>
          ) : (
            <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
               <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {record.name}
                </span>
            </div>
          );
        }
      },
      {
        title: getTitleColumns('leadEmployeeName', 'teams'),
        dataIndex: 'leadEmployeeName',
        key: 'leadEmployeeName',
        width: '35%',
        ellipsis: {
          showTitle: false
        },
        render: (_, record) => {
          return role ? (
            <Link
              to={`/allUsersList/viewProfile?employeeId=${record.leadEmployeeId}`}
            >
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {record.leadEmployeeName}
                </span>
              </div>
            </Link>
          ) : (
            <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {record.leadEmployeeName ?? ''}
              </span>
            </div>
          );
        }
      }
    ];

    const dataSourceKeys = dataSource.length && dataSource[0];

    let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
      titlesKeysofColumnsTeams.some(item => key === item.dataIndex)
    );

    const checkForDataDisplay = (column: any) =>
      keysDataSource.includes(column.dataIndex);

    return [
      ...tableColumns.filter(checkForDataDisplay),
      ...additionalCustomColumns
    ];
  };

  return (
    <>
      <GridView
        style={{
          height: `calc(${deviceHeight}px - 186px)`
        }}
        header={
          <div className={GridViewStyles['header']}>
            <h2 className={GridViewStyles['title']}>
              <FormattedMessage id="table_teamsTitle_Teams"></FormattedMessage>
            </h2>
            {!isMobile || !allowPermision(userRole, adminRoles) ? (
              <CustomSwitch
                values={[
                  {
                    id: 0,
                    value: 'List',
                    icon: <ListViewComponent />
                  },
                  {
                    id: 1,
                    value: 'Cards',
                    icon: <CardViewComponent />
                  }
                ]}
                defaultValue={getDisplayMode()}
                onChange={toggleDisplayMode}
              ></CustomSwitch>
            ) : null}
            {isMobile && allowPermision(userRole, adminRoles) ? (
              <LayoutPageMobileComponent></LayoutPageMobileComponent>
            ) : null}
          </div>
        }
        body={
          <div
            style={{
              height: !isMobile
                ? `calc(${deviceHeight}px - 292px)`
                : `calc(${deviceHeight}px - 296px)`
            }}
          >
            {!isToggleChecked ? (
              <ComponentWithStatus
                status={statusTeam}
                Placeholder={<TableComponentPlaceholder count={3} />}
              >
                <TableComponent
                  scroll={
                    isMobile
                      ? { y: `calc(${deviceHeight}px - 22.7em)` }
                      : {
                        x: 'calc(100vw - 213px)',
                        y: 'calc(100vh - 305px - 24px)'
                      }
                  }
                  style={
                    isMobile
                      ? {
                        height: `calc(${deviceHeight}px - 22em)`,
                        minHeight: `calc(${deviceHeight}px - 22em)`
                      }
                      : {
                        height: 'calc(100vh - 24.5em)',
                        minHeight: 'calc(100vh - 24.5em)'
                      }
                  }
                  rowKey="teamId"
                  columns={columnsMobileTeams}
                  data={employeId}
                  dataSource={teamsData}
                  role={isAllowedToAccessAllTeams}
                  setPage={setPage}
                  page={page}
                  className="teamsTable"
                />
              </ComponentWithStatus>
            ) : (
              <ComponentWithStatus
                status={statusTeam}
                Placeholder={<GridViewPagePlaceholder />}
              >
                <TeamGrid teamGridItems={teamsData} />
              </ComponentWithStatus>
            )}
          </div>
        }
      ></GridView>

      <ModalContextProvider>
        <ModalComponent
          maskStyle={{ opacity: 0 }}
          className="top-role-popup"
          isOpen={isTopRoleModalOpen}
          setIsOpen={setIsTopRoleModalOpen}
          isMaskClosable={true}
          closable={true}
          onCancel={() => setIsTopRoleModalOpen(false)}
        >
          <section className="top-role-popup-buttons">
            <button style={{ background: 'none', border: 'none' }}>
              <CustomSwitch
                values={[
                  {
                    id: 0,
                    value: 'List',
                    icon: <ListViewComponent />
                  },
                  {
                    id: 1,
                    value: 'Cards',
                    icon: <CardViewComponent />
                  }
                ]}
                defaultValue={getDisplayMode()}
                onChange={() => {
                  toggleDisplayMode();
                  setTimeout(() => setIsTopRoleModalOpen(false), 0);
                }}
              />
            </button>
            {topRoleButtons.map((button, index) =>
              button.show ? (
                <ButtonComponent
                  key={`button-component-${index}`}
                  type={button.type}
                  icon={
                    button.icon ? (
                      button.icon
                    ) : button.iconSrc ? (
                      <embed src={button.iconSrc} />
                    ) : null
                  }
                  className={
                    button.className
                      ? button.className
                      : `text-bold-normal btn-default-custom ${theme}`
                  }
                  style={{ margin: '0' }}
                  onClick={button.action}
                >
                  {button.text}
                </ButtonComponent>
              ) : null
            )}
          </section>
        </ModalComponent>
      </ModalContextProvider>
    </>
  );
};

export default TeamsList;
