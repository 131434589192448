import { createContext, useContext, useState } from 'react';

export interface IClockOutContext {
  actionId?: number;
  clockedIn?: Date;
  clockedOut?: Date;
  isClockedIn?: boolean;
  isClockedOut?: boolean;
  timeWorked?: { hours: number; minutes: number };
  clockedInOutData?: any;
}

const useClockOutComponent = () => {
  const [clockOutProps, setClockOutProps] = useState<IClockOutContext>({
    actionId: null,
    clockedIn: null,
    clockedOut: null,
    clockedInOutData: null,
    isClockedIn: false,
    isClockedOut: false,
    timeWorked: { hours: null, minutes: null }
  });

 
  return {
    clockOutProps,
    setClockOutProps
  };
};

const ClockOutContext = createContext(
  {} as ReturnType<typeof useClockOutComponent>
);

export const  ClockOutContextProvider = ({ children }) => {
  return (
    <ClockOutContext.Provider value={useClockOutComponent()}>
      {children}
    </ClockOutContext.Provider>
  );
};

export const useClockOutContext = () => useContext(ClockOutContext);
