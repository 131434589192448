import { ITimeSheet } from 'services/providers/TimeSheetProvider/types';
import { addingAdditionalColumns, getTeamsFilters } from '../utils';
import { ColumnsType } from 'antd/es/table';
import { Tooltip, Typography } from 'antd';
import '../index.less';
import { hoverColumnDate } from '..';
import {
  initialAggregatedColumns,
} from './additionalColumns';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { firstCharToUpperCase } from '../../../../utils/lang/utils';

export const conflictTypes = ['ConflictGeneral', 'ConflictHours'];

const { Text, Paragraph } = Typography;

// TODO Optimize this part with hovering (state)
export const columnsTimeSheet = (
  data,
  role = null,
  params,
  isOpen,
  setIsOpen,
  stateActions,
  dataSource = null,
  modalOpenValues = null,
  teamsFilter = null,
  setModalOpenValues = null,
  isTabletDevice = null,
  isSmallLaptop = null,
  intl = null,
  countryCode: string        
): ColumnsType<ITimeSheet> => {
  const isRO = countryCode === 'RO';
  return [
    {
      title: () => <p className="text-color-header alignTextCenter">Nr.</p>,
      width: 42,
      dataIndex: 'employeeId',
      key: 'employeeId',
      fixed: 'left',
      render: (_, record, index) => (
        <div className="cell-padding">
          {(params.page - 1) * 10 + (index + 1)}
        </div>
      ),
    },
    {
      title: (
        <Text className="text-bold-normal text-color-header text-align-center">
          <FormattedMessage id="table_TimesheetCol_LastName" />,{' '}
          <FormattedMessage id="table_TimesheetCol_FirstName" /> <br />{' '}
          <FormattedMessage id="table_TimesheetCol_Position" /> /{' '}
          <FormattedMessage id="table_TimesheetCol_Team" />
        </Text>
      ),
      filters: getTeamsFilters(data),
      onFilter: (value: string, record) =>
        record && Object.values(record.teams).indexOf(value) !== -1,
      dataIndex: 'employeeName',
      key: 'employeeName',
      width: 200,
      fixed: 'left',
      filterSearch: true,
      render: (_, record) => (
        <div className="cell-padding">
          <div
            style={{
              display: 'flex',
              maxWidth: '22ch',
            }}
          >
            <Tooltip placement="top" title={record?.employeeName}>
              <Link
                to={`/allUsersList/viewProfile?employeeId=${record.employeeId}`}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {record.employeeName}
              </Link>
            </Tooltip>
          </div>
          <Paragraph
            ellipsis={true}
            style={{ lineHeight: 1, color: '#BDA7E8' }}
          >
            <Typography.Text className="text-medium-middle-small">
              {`${
                record.employeeFunction ?? (
                  <FormattedMessage id="TimeSheetTableRowCell_not_job_provided" />
                )
              } 
              `}
            </Typography.Text>
            <Tooltip
              placement="top"
              title={
                Object.values(record.teams) &&
                Object.values(record.teams).length > 0 ? (
                  Object.values(record.teams).map((team, index) =>
                    index > 0 ? `, ` + team : team
                  )
                ) : (
                  <FormattedMessage id="NoTeamsToDisplay" />
                )
              }
            >
              <Text
                className="text-medium-middle-small "
                style={{ color: '#BDA7E8' }}
              >
                <>
                  {Object.values(record.teams) &&
                  Object.values(record.teams).length > 0 ? (
                    Object.values(record.teams).map((team, index) =>
                      index > 0 ? `, ` + team : team
                    )
                  ) : (
                    <FormattedMessage id="NoTeamsToDisplay" />
                  )}
                </>
              </Text>
            </Tooltip>
          </Paragraph>
        </div>
      ),
    },
    ...datesColumns(data, params, stateActions, countryCode),
    ...initialAggregatedColumns(params.aggColumnsWithData, countryCode),
    ...addingAdditionalColumns(
      isRO
        ? [...params.torColumns, ...params.totalsColumns]
        : params.totalsColumns,
      params.value, //new columns,
      countryCode
    ),
  ];
};

const datesColumns = (data, params, stateActions, countryCode: string): ColumnsType<any> => {
  return data[0].timeSheetDays.map((day, indexDay) => {
    const dateTitle = new Date(day.dateOnly).getDate();
    const daysTitle = firstCharToUpperCase(
      new Date(day.dateOnly)
        .toLocaleDateString(useIntl().locale, { weekday: 'short' })
        .slice(0, 2)
    );
    var opts = {};
    const isWeekend = day.events.filter(
      (event) => event.eventType === 'WeekEnd'
    ).length;
    const isMD = countryCode === 'MD';
    const isRO = countryCode === 'RO';
    if (isMD && isWeekend) {
      opts['type'] = 'success';
    }
    return {
      title: () => (
        <>
          <Text {...opts}>{dateTitle}</Text>
          <br />
          <Text {...opts}>{daysTitle}</Text>
        </>
      ),
      key: indexDay,
      width: 40, //${weekends.filter((day) => new Date(day).getDate() === dateTitle).length ? 'weekend' : ''}
      className: `${
        isRO
          ? day.events.filter((event) => event.eventType === 'Holiday').length
            ? 'holiday-timesheet'
            : isWeekend
            ? 'weekend-timesheet'
            : ''
          : ''
      } 
                  ${
                    params.indexColumnHovered === indexDay ? 'highlighted' : ''
                  } ${indexDay === 0 ? 'lineOfHeaders' : ''} ${
        indexDay + ' - ' + params.indexColumnHovered
      }`,
      onCell: () => hoverColumnDate(params),
      render: (_, record) => {
        const dataRecord = record.timeSheetDays.filter(
          (days) =>
            new Date(days.dateOnly).getDate() ===
            new Date(day.dateOnly).getDate()
        );
        return (
          <Text
            {...opts}
            onClick={(e) => {
              if (isRO && dataRecord[0].events?.length === 0) return;
              params.setDisableButtonResolve(true);

              stateActions?.setModalProps((state) => ({
                ...state,
                data: dataRecord[0],
                employeeName: record.employeeName,
                employeeId: record.employeeId,
                formKeyId: 'dayEditForm',
                modalTitle: useIntl().formatMessage({ id: 'ViewConflict' }),
                submitButtonResolveText: useIntl().formatMessage({
                  id: 'resolve',
                }),
                submitButtonResolveAndNextText: useIntl().formatMessage({
                  id: 'resolveAndNext',
                }),
                cancelButtonText: useIntl().formatMessage({ id: 'cancel' }),
              }));

              stateActions?.toggleModal();
            }}
            data-col={indexDay}
            key={indexDay + 1}
            style={{
              display: 'block',
              textAlign: 'center',
              paddingBlock: '10px',
            }}
            className={
              conflictTypes.includes(dataRecord[0].conflictType)
                ? 'conflict'
                : dataRecord[0].conflictType === 'ConflictSolved'
                ? 'conflictSolved'
                : ''
            }
          >
            {isRO && isWeekend && dataRecord[0].letter === 'R'
              ? ''
              : dataRecord[0].letter}
          </Text>
        );
      },
    };
  });
};
