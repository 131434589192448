export const VideoIconSVG = ({ stroke = 'currentColor' }) => {
  return (
    <span
      role="img"
      aria-label="detailedView"
      className="anticon anticon-detailedView"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
      >
        <path
          d="M5 17V2M13 17V2M1 9.5H17M1 5.5H5M13 5.5H17M1 13.5H5M13 13.5H17M1 14.5001L1 4.5C1 2.84315 2.34315 1.5 4 1.5L14 1.5C15.6569 1.5 17 2.84315 17 4.5V14.5001C17 16.1569 15.6569 17.5001 14 17.5001H4C2.34315 17.5001 1 16.1569 1 14.5001Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
