import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { Row } from 'antd';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import {
  SmallRequestsWidgetPlaceholder,
  TableComponentPlaceholder
} from 'components/Placeholders';
import TableComponent, { TableParams } from 'components/Table';
import { ProfileTimeOffActions } from 'context/Profile/actions';
import { useProfileTimeOffContext } from 'context/Profile/context';
import dayjs from 'dayjs';
import useApiRequest from 'hooks/useApiRequest.hook';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { TimeOffService } from 'services';
import { TimeOffRequestResponse } from 'services/providers/TimeOffProvider/types';
import { TimeOffColleaguesActions } from '../../../context/TimeManagement/ColleaguesRequests/actions';
import { useColleaguesRequestsFilterContext } from '../../../context/TimeManagement/ColleaguesRequests/context';
import { columnsRequestsTimeManagement } from './columnsTable';
import { columnsRequestsMobileTimeManagement } from './columnsTableMobile';

import './index.less';

export const RequestManagement = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const employeeIdSearch = searchParams.get('employeeId') ?? 1;

  const { isMobile, deviceHeight } = useMobileContext();
  const { profileTimeOffState, profileTimeOffDispatch } =
    useProfileTimeOffContext();

  const widgetRequestWTitle = intl.formatMessage({
    id: 'table_ColleaguesRequestsTitle_Requests'
  });
  const currentYear = dayjs().get('year');

  React.useEffect(() => {
    return () => {
      profileTimeOffDispatch({
        type: ProfileTimeOffActions.RESET_TABLE_FILTER
      });
    };
  }, []);

  const { data: responseData, status } = useApiRequest<TimeOffRequestResponse>(
    TimeOffService.getEmployeeTimeOffRequests,
    {
      payload: profileTimeOffState,
      parameters: {
        employeeId: Number(employeeIdSearch)
      }
    },
    [],
    {},
    null,
    [profileTimeOffState, profileTimeOffDispatch],
    false,
    true
  );

  const handleTableChange = (props: TableParams) => {
    const { pagination, filters, sorter } = props;
    profileTimeOffDispatch({
      type: ProfileTimeOffActions.UPDATE_TABLE_FILTER,
      payload: {
        filterBy: {
          statuses: (filters?.['requestStatus'] as Array<string>) || null,
          policies: (filters?.['requestType'] as Array<string>) || null
        },
        sortBy: {
          daysTaken: sorter?.['field'] === 'daysTaken' ? sorter?.['order'] : null,
          startDate: sorter?.['field'] === 'startDate' ? sorter?.['order'] : null,
          endDate: sorter?.['field'] === 'endDate' ? sorter?.['order'] : null
        },
        pagination: {
          pageNumber: pagination.current,
          resultSize: pagination.pageSize
        }
      }
    });
  };

  const { timeOffColleaguesDispatch } = useColleaguesRequestsFilterContext();

  const displayAwaitingRequestsManagement = props => {
    const { requestId } = props;

    timeOffColleaguesDispatch({
      type: TimeOffColleaguesActions.UPDATE_REQUEST_ID,
      payload: {
        requestId: requestId
      }
    });
    const searchParams = {
      requestId: requestId
    };
    const pathname = `../../timeManagement/colleaguesRequests`;
    navigate({
      pathname,
      // state: searchParams
      search: createSearchParams(searchParams).toString()
    });
  };

  const parameters = {
    redirectColleagues: displayAwaitingRequestsManagement,
    policies: responseData?.policies,
    statuses: responseData?.statuses
  };

  return (
    <Row
      key="requestManagement"
      className="full-content"
      justify="space-around"
      style={{ width: '100%', position: 'relative' }}
    >
      <TableLayoutPage
        style={{
          height: isMobile ? `calc(${deviceHeight}px - 90px - 32px)` : ''
        }}
        title={widgetRequestWTitle}
        key={currentYear}
        className="height-inherit"
      >
        <ComponentWithStatus
          key="requestManagementStatus"
          status={status}
          Placeholder={
            isMobile ? (
              <TableComponentPlaceholder count={3} />
            ) : (
              <SmallRequestsWidgetPlaceholder count={5} />
            )
          }
        >
          <TableComponent
            key={
              isMobile
                ? 'requestManagementTableMobile'
                : 'requestManagementTable'
            }
            className={!isMobile ? 'request-management desktop' : ''}
            params={parameters}
            scroll={
              isMobile
                ? {
                  x: 'calc(100vw - 213px)',
                  y: `calc(${deviceHeight}px - 21em)`
                }
                : {
                  y: 'calc(100%)',
                  x: 'calc(100vh - 39em)'
                }
            }
            style={
              isMobile
                ? {
                  height: `calc(${deviceHeight}px - 22.7em)`,
                  minHeight: `calc(${deviceHeight} - 22.7em)`
                }
                : {
                  maxHeight: 'calc(100% - 90px)'
                }
            }
            tableSize="small"
            rowKey="requestId"
            columns={
              isMobile
                ? columnsRequestsMobileTimeManagement
                : columnsRequestsTimeManagement
            }
            dataSource={responseData?.requests?.responseData}
            data={responseData?.requests?.responseData}
            totalItems={responseData?.requests?.totalCount}
            pageSize={profileTimeOffState.pagination?.resultSize}
            onChange={(props: TableParams) => handleTableChange(props)}
          ></TableComponent>
        </ComponentWithStatus>
      </TableLayoutPage>
    </Row>
  );
};
