import { createContext, useContext, useState, useEffect } from 'react';

const useConfirmModalComponentContext = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [onOkModalPromise, setOnOkModalPromise] =
    useState<() => Promise<any>>(null);

  function toggleModal() {
    setIsConfirmModalOpen((prev) => !prev);
  }

  useEffect(() => {
    return () => {
      setIsConfirmModalOpen(false);
    };
  }); //close modal on unmount

  return {
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    toggleModal,
    onOkModalPromise,
    setOnOkModalPromise,
  };
};

const ConfirmationModalContext = createContext(
  {} as ReturnType<typeof useConfirmModalComponentContext>
);

export const ConfirmationModalContextProvider = ({ children }) => {
  return (
    <ConfirmationModalContext.Provider
      value={useConfirmModalComponentContext()}
    >
      {children}
    </ConfirmationModalContext.Provider>
  );
};

export const useConfirmationModalContext = () =>
  useContext(ConfirmationModalContext);
