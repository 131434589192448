import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import 'components/Typography/titleComponentStyles.less';
import App from 'App';
import AppProvider from 'AppProvider';
import {BrowserRouter} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <AppProvider>
            <App/>
        </AppProvider>
    </BrowserRouter>
);
