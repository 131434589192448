import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVacation } from 'services/providers/TimeSheetProvider/types';

const colleaguesMonthEventsSlice = createSlice({
  name: 'colleaguesMonthEvents',
  initialState: {},
  reducers: {
    setColleaguesMonthEvents(state, action: PayloadAction<IVacation>) {
      return { ...state, colleaguesMonthEvents: action.payload };
    }
  }
});

export const { setColleaguesMonthEvents } = colleaguesMonthEventsSlice.actions;
export default colleaguesMonthEventsSlice.reducer;
