export const LightThemeComponent = (props) => {
  return (
    <span role='img' aria-label='import' className='anticon anticon-import marginIcon' {...props} >
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.0001 2.04148V1.6001M8.0001 14.4001V13.9587M13.9587 8.0001H14.4001M1.6001 8.0001H2.04148M12.2139 3.78677L12.526 3.47467M3.4742 12.5256L3.7863 12.2135M12.2139 12.2134L12.526 12.5255M3.4742 3.47463L3.7863 3.78674M11.5225 7.97596C11.5225 9.9261 9.94161 11.507 7.99148 11.507C6.04134 11.507 4.46044 9.9261 4.46044 7.97596C4.46044 6.02582 6.04134 4.44493 7.99148 4.44493C9.94161 4.44493 11.5225 6.02582 11.5225 7.97596Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </svg>
    </span>
  );
};
