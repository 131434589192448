import { FormattedMessage } from 'react-intl';

export const titlesKeysofColumnsHardware = [
  {
    title: <FormattedMessage id="inventoryHardware_table_AssetName" />,
    dataIndex: 'assetName',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_Model" />,
    dataIndex: 'model',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_PartNumber" />,
    dataIndex: 'partNumber',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_Category" />,
    dataIndex: 'categoryName',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_Details" />,
    dataIndex: 'details',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_SerialNumber" />,
    dataIndex: 'serialNumber',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_InvoiceNumber" />,
    dataIndex: 'invoiceNumber',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_InventoryNumber" />,
    dataIndex: 'inventoryNumber',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_DatePurchased" />,
    dataIndex: 'datePurchased',
  },
  {
    title: <FormattedMessage id="inventoryHardware_table_Assignee" />,
    dataIndex: 'assigneeName',
  },
];
