import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { AssetCategoryService } from 'services';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import { useIntl } from 'react-intl';

export const onFinish = (scope, data, categoryId, actions) => {
  const formActions = {
    add: AssetCategoryService.addAssetCategory,
    edit: AssetCategoryService.updateAssetCategory,
  };

  if (scope === 'remove') {
    AssetCategoryService.deleteAssetCategory(null, {
      categoryId: categoryId,
    }).then((res) => {
      if (!actions) {
        return;
      }

      if (actions.dispatch && typeof actions.dispatch === 'function') {
        actions.dispatch(setMethodUpdate(true));
      }
      if (actions.toggleModal && typeof actions.toggleModal === 'function') {
        actions.toggleModal()
      }
      notifyWithSuccessfulDataUpdated(
        '',
        useIntl().formatMessage({ id: 'success_removed_category' })
      );
    });
    return;
  }

  data = categoryId ? { categoryId, ...data } : data;
  const getMessages =
    scope === 'add'
      ? getSuccessMessages.success_added_category
      : getSuccessMessages.success_edited_category;
  onFormFinishWithPassedValidation(
    formActions,
    scope,
    data,
    actions,
    getMessages
  );
};
