import { Dayjs } from 'dayjs';

export enum EventTypes {
  Holiday = 'Holiday',
  PersonalEvent = 'PersonalEvent',
  TeamEvent = 'TeamEvent',
  CompanyEvent = 'CompanyEvent',
  TimeOffEvent = 'TimeOffEvent'
}

type Color =
  | `rgb(${number}, ${number}, ${number})`
  | `rgba(${number}, ${number}, ${number}, ${number})`
  | `#${string}`;

export type IEventDefinition = {
  badgeColor?: Color;
};
/**
 * Represents a map which contains certain configurations depending on event category, such as badgeColor or other properties
 */
export const EventDefinitions: Record<EventTypes, IEventDefinition> = {
  [EventTypes.Holiday]: {
    badgeColor: 'rgb(235, 87, 87)'
  },
  [EventTypes.PersonalEvent]: {
    badgeColor: 'rgb(70, 255, 177)'
  },
  [EventTypes.TeamEvent]: {
    badgeColor: 'rgb(242, 201, 76)'
  },
  [EventTypes.CompanyEvent]: {
    badgeColor: 'rgb(33, 150, 83)'
  },
  [EventTypes.TimeOffEvent]: {
    badgeColor: 'rgb(90, 176, 255)'
  }
};

export const EventDefinitionsHardOpacity: Record<EventTypes, IEventDefinition> =
  {
    [EventTypes.Holiday]: {
      badgeColor: 'rgba(235, 87, 87, 0.6)'
    },
    [EventTypes.PersonalEvent]: {
      badgeColor: 'rgba(70, 255, 177, 0.6)'
    },
    [EventTypes.TeamEvent]: {
      badgeColor: 'rgba(242, 201, 76, 0.6)'
    },
    [EventTypes.CompanyEvent]: {
      badgeColor: 'rgba(33, 150, 83, 0.6)'
    },
    [EventTypes.TimeOffEvent]: {
      badgeColor: 'rgba(90, 176, 255, 0.6)'
    }
  };
export const EventDefinitionsLowOpacity: Record<EventTypes, IEventDefinition> =
  {
    [EventTypes.Holiday]: {
      badgeColor: 'rgba(235, 87, 87, 0.8)'
    },
    [EventTypes.PersonalEvent]: {
      badgeColor: 'rgba(70, 255, 177, 0.8)'
    },
    [EventTypes.TeamEvent]: {
      badgeColor: 'rgba(242, 201, 76, 0.8)'
    },
    [EventTypes.CompanyEvent]: {
      badgeColor: 'rgba(33, 150, 83, 0.8)'
    },
    [EventTypes.TimeOffEvent]: {
      badgeColor: 'rgba(90, 176, 255, 0.8)'
    }
  };
export enum EventsViewType {
  Personal = 'Personal',
  Team = 'Team',
  Company = 'Company'
}

export enum ViewPeriodMode {
  DayGridMonth = 'dayGridMonth',
  TimeGridWeek = 'timeGridWeek',
  TimeGridDay = 'timeGridDay'
}

export interface ICalendarViewOptions {
  eventViewType: EventsViewType;
  eventType: EventTypes[];
  viewPeriodMode: ViewPeriodMode;
}

export enum EventTimeFormat {
  AllDay = 'AllDay',
  TimeRange = 'TimeRange'
}

export interface EventDto {
  eventId?: string | number | null;
  eventType: EventTypes;
  timeOffType?: string;
  eventName: string;
  startDate: Dayjs;
  endDate?: Dayjs;
  teamId?: string | number | null;
  teamName?: string | null;
  employeeId?: string | number | null;
  employeeName?: string;
  isExtendedViewAllowed?: boolean;
  timeFormat: EventTimeFormat;
  hasDetails: boolean;
}

export interface EventUpdateDto extends EventDto {
  memberIds: number[];
  recurrence: EventRecurrence;
  recurrenceEndDate: Date | null;
  details?: string | null;
  location?: string | null;
  updateRecurringSeries: boolean;
}

export type EventResponseDto = EventDto &
  Omit<EventUpdateDto, 'memberIds'> & {
    members: Array<{
      employeeId: string | number;
      firstName: string;
      lastName: string;
      email: string;
    }>;
  };

export enum EventRecurrence {
  DoesNotRepeat = 'DoesNotRepeat',
  Daily = 'Daily',
  EveryWorkday = 'EveryWorkday',
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
  MonthlyOnDay = 'MonthlyOnDay',
  MonthlyOnDate = 'MonthlyOnDate',
  Yearly = 'Yearly'
}

export interface IEventsFilter {
  dateOnly: string | Dayjs;
  eventTypes: Array<EventTypes>;
  eventsViewType: EventsViewType;
  calendarViewType: 'Month' | 'Day' | 'Week' | 'Year';
}
export type ICalendarEventsPerDayResponse = Record<string, EventResponseDto[]>;
