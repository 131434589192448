import { Form } from 'antd';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type LockForm = {};

const LockForm: FC<LockForm> = () => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      id="lockForm"
      layout="vertical"
      wrapperCol={{ span: 11, offset: 1 }}
      labelCol={{ span: 8 }}
    >
      <p>
        <FormattedMessage id="lockTimesheetConfirm" />
      </p>
    </Form>
  );
};

export default LockForm;
