import { hubsBaseUrl } from 'services/configs/servicesConfig';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { INotification } from './types';
import { getAuthToken } from '../AuthProvider';
export default class NotificationsProvider {
    public connection: HubConnection;
    private isConnectionAllive = (): boolean => this.connection && this.connection.state === 'Connected';
    
    public constructor() {
      this.connection = new HubConnectionBuilder()
        .withUrl(`${hubsBaseUrl}notification`, { accessTokenFactory: () => getAuthToken() })
        .withAutomaticReconnect()
        .build();
    }

    public subscribeToGetNotifications = async(onReceiveNotifications: { (notifications: INotification[]): void; }) => {
      await this.startConnectionIfItNeeds();
      
      if(this.isConnectionAllive()) {
        this.connection.invoke("GetNotifications");
        this.connection.on('ReceiveMessage', onReceiveNotifications);
      }
    }

    public markNotificationAsRead = async (notificationId: number) =>  {
      if(this.isConnectionAllive()) {
        await this.connection.invoke("MarkNotificationAsRead", notificationId);
      }
    }

    public markAllAsRead = async () => {
      if(this.isConnectionAllive()) {
        await this.connection.invoke("MarkAllAsRead");
      }
    }

    private startConnectionIfItNeeds = async() => {
      if (!this.isConnectionAllive()) {
        await this.connection.start();
      }
    }
}
