export const AttachIconSVG = () => {
  return (
    <span
      role="img"
      aria-label="detailedView"
      className="anticon anticon-detailedView"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="15"
        viewBox="0 0 8 15"
        fill="none"
      >
        <path
          opacity="1"
          d="M6.8559 4.20662L6.8559 10.434C6.8559 12.1289 5.68498 13.5 3.95686 13.5C2.2619 13.5 1.14429 12.1621 1.14429 10.434L1.14429 3.43377C1.14429 2.3624 2.00669 1.5 3.07806 1.5C4.14943 1.5 5.01183 2.3624 5.01183 3.43377L5.01184 10.5569C5.01184 11.0909 4.57895 11.5238 4.04495 11.5238C3.51095 11.5238 3.07806 11.0909 3.07806 10.5569L3.07806 4.20662"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};
