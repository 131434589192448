import React from 'react'
import OptionButton from 'components/UIComponents/OptionButton';
import dropdownStyles from '../Dropdown.module.less';
import { ArrowUnion } from 'Icons/General/arrowUnion';
import useOutsideClick from 'hooks/useOutsideClick.hook';
import { Tooltip } from 'antd';

interface OptionTypeProps<T> {
  value: string;
  label: T;
};

interface MultipleSelectProps<T extends string> {
  defaultOptions?: string[];
  onOptionChange?: (options: string[]) => void;
  options?: OptionTypeProps<T>[];
  isClearable?: boolean;
  label?: string;
  preffixText?: string;
  allOption?: OptionTypeProps<T>;
  theme?: 'dark' | 'light';
  className?: string;
  style?: React.CSSProperties;
  dropdownAlign?: { offset: [number, number] };
  arrow?: React.ReactNode;
};

const MultipleSelect = <T extends string>({
  defaultOptions = [],
  onOptionChange,
  options = [],
  isClearable = false,
  label = null,
  preffixText = null,
  allOption,
  theme,
  className,
  style,
  dropdownAlign = { offset: [0, 0] },
  arrow
}: MultipleSelectProps<T>) => {

  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
  const [
    selectedOptions,
    setSelectedOptions
  ] = React.useState<Set<string>>(new Set(defaultOptions));
  const dropdownRef = React.useRef<HTMLFieldSetElement | null>(null);

  useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  React.useEffect(() => {
    if (defaultOptions === null || defaultOptions.length === options.length) {
      const optionKeys = options.map(option => option.value);
      setSelectedOptions(new Set(optionKeys));
    } else {
      setSelectedOptions(new Set(defaultOptions));
    }
  }, [defaultOptions, options]);

  const handleSingleSelection = (selectedOption: OptionTypeProps<T>) => {
    const updatedValues = new Set(selectedOptions);
    if (updatedValues.has(selectedOption.value)) {
      updatedValues.delete(selectedOption.value);
    } else {
      updatedValues.add(selectedOption.value);
    };

    onOptionChange && onOptionChange(Array.from(updatedValues));
    setSelectedOptions(updatedValues);
  };

  const handleAllSelection = () => {
    if (selectedOptions.size === options.length) {
      onOptionChange && onOptionChange([]);
      setSelectedOptions(new Set());
    } else {
      onOptionChange && onOptionChange(options?.map(option => option.value));
      setSelectedOptions(new Set(options?.map(option => option.value)));
    };
  };

  const handleClearSelection = () => {
    if (selectedOptions.size > 0) {
      onOptionChange && onOptionChange([]);
      setSelectedOptions(new Set());
    };
  };

  const generateLabel = () => {
    
    if (label) return label;
    
    const selectedOptionsArray = Array.from(selectedOptions);
    const selectedOptionsCount = selectedOptionsArray.length;
    const selectionRatio = `${selectedOptionsCount}/${options.length}`;
    
    if (selectedOptionsCount === 0) return `${preffixText}`;
    if (selectedOptionsCount === 1) return `${preffixText}: ${selectedOptionsArray[0]} ${selectionRatio}`;
    if (selectedOptionsCount > 1) return `${preffixText} ${selectionRatio}`;
  };

  return (
    <fieldset
      className={`${dropdownStyles.select} ${dropdownStyles[theme]}`}
      style={{ ...style }}
      ref={dropdownRef}
    >
      <button
        aria-expanded={isDropdownOpen}
        aria-controls="MultipleSelect_content"
        className={dropdownStyles.header}
        type='button'
        onClick={() => setIsDropdownOpen((prevState) => !prevState)}
      >
        <Tooltip placement="top" title={generateLabel()}>
          <h4 className={dropdownStyles.title}>
            {generateLabel()}
          </h4>
        </Tooltip>
        <ArrowUnion
          className={`${dropdownStyles.arrow} ${isDropdownOpen ? dropdownStyles.arrow__show : ''}`}
        ></ArrowUnion>
      </button>
      {
        isDropdownOpen ? (
          <div
            className={dropdownStyles.content}
            id="MultipleSelect_content"
            style={{
              top: dropdownAlign.offset[0],
              left: dropdownAlign.offset[1],
              right: dropdownAlign.offset[1]
            }}
          >
            <ul className={dropdownStyles.menu}>
              {
                allOption ? (
                  <li className={`${dropdownStyles.item} ${selectedOptions.size === options.length
                    ? dropdownStyles.item__selected
                    : ''
                    }`
                  }
                  >
                    <OptionButton
                      value={allOption.label}
                      type='checkbox'
                      showShape={true}
                      theme={theme}
                      checked={selectedOptions.size === options.length}
                      onChange={handleAllSelection}
                    />
                  </li>
                ) : null
              }

              {options && options.map((option, index) => (
                <li
                  key={`MultipleSelect_${index}`}
                  className={`${dropdownStyles.item} ${selectedOptions.has(option.value)
                    ? dropdownStyles.item__selected
                    : ''
                    }`
                  }
                >
                  <OptionButton
                    value={option.label}
                    type='checkbox'
                    showShape={true}
                    theme={theme}
                    checked={selectedOptions.has(option.value)}
                    onChange={() => handleSingleSelection(option)}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : null
      }
    </fieldset>
  );
};

export default MultipleSelect;
