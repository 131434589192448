import { getListOfTimeOffPolicies } from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const useTimeOffPolicies = () => {
  
  const rootSelector = useSelector((state: RootState) => state);
  const listOfTimeOffPolicies = getListOfTimeOffPolicies(rootSelector.general);

  const mapPolicies = () => {
    return listOfTimeOffPolicies.reduce((acc, policy) => {
      acc[policy] = policy;
      return acc;
    }, {});
  };

  return {
    mapPolicies
  };
};

export default useTimeOffPolicies;
