import { LoadingOutlined } from '@ant-design/icons';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { IAppProvider } from 'AppProvider/types';
import { Layout } from 'antd';
import { TeamViewContextProvider } from 'components/Team/TeamViewContext/TeamViewContext';
import './index.css';
export interface LayoutProps {
  children: React.ReactNode;
}
const ContentPart = ({ children }: IAppProvider) => {

  const { isMobile } = useMobileContext();
  const { Content } = Layout;

  return (
    <Layout id="mainSection">
      <TeamViewContextProvider>
        <Content className="contentStyle">
          <div className="contentHeight">
            {children ? children : <LoadingOutlined />}
          </div>
        </Content>
      </TeamViewContextProvider>
    </Layout>
  );
};

export default ContentPart;
