import {
  Employee, 
  PagedResponse,
  TicketBaseDto,
  TicketDto,
  TicketPriority,
  TicketStatus,
} from 'pages/Ticketing/types';
import { commonRequest } from '../../generic';
import { ITicket, ITicketCategory, ITicketSubcategory } from './types';

class TicketingProvider {
  /**
   * GET Last Tickets Preview
   *
   * @typedef {{ticketType: string}} ticketType
   * @param {ticketType} parameters - The {@link ticketType} to be retrieved
   *
   * @returns {Array} Returns the list of the last 10 tickets / reports
   */
  async getMyLastTickets(parameters: any) {
    const payloadData = { parameters };

    return await commonRequest<ITicket[]>(
      'GET',
      payloadData,
      'Ticket/getMyLastTickets'
    );
  }

  async getMyTickets(parameters: any) {
    const payloadData = { parameters };

    return await commonRequest<TicketDto[]>(
      'GET',
      payloadData,
      'Ticket/getMyTickets'
    );
  }

  async getAllTickets(payload: any) {
    const payloadData = { payload };

    return await commonRequest<PagedResponse<TicketBaseDto>>(
      'POST',
      payloadData,
      'Ticket/getAllTickets'
    );
  }
  /**
   * GET Categories By Ticket Type
   *
   * @typedef {{ticketType: string}} ticketType
   * @param {ticketType} parameters - The {@link ticketType} to be retrieved
   *
   * @returns {Array} Returns the list of categories
   */
  async getCategoriesByTicketType(parameters: any) {
    const payloadData = { parameters };

    return await commonRequest<ITicketCategory[]>(
      'GET',
      payloadData,
      'Ticket/getCategoriesByTicketType'
    );
  }

  /**
   * GET Subategories By Category Id
   *
   * @typedef {{categoryId: number}} categoryId
   * @param {categoryId} parameters - The {@link categoryId} to be retrieved
   *
   * @returns {Array} Returns the list of subcategories
   */
  async getAllCategorySubCategories(parameters: any) {
    const payloadData = { parameters };

    return await commonRequest<ITicketSubcategory[]>(
      'GET',
      payloadData,
      'Ticket/getAllCategorySubCategories'
    );
  }
  /**
   * POST Subategories By Category Id
   */
  async addTicket(payload: FormData) {
    const payloadData = { payload };

    return await commonRequest<any>('POST', payloadData, 'Ticket/addTicket');
  }

  async addTicketComment(payload: FormData) {
    const payloadData = { payload };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'Ticket/addTicketComment'
    );
  }

  async updateAssignee(payload: {
    ticketId: string | number;
    assigneeId: string | number;
  }) {
    const payloadData = { payload };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'Ticket/updateAssignee'
    );
  }

  async updatePriority(payload: {
    ticketId: string | number;
    priority: TicketPriority | string;
  }) {
    const payloadData = { payload };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'Ticket/updatePriority'
    );
  }

  async updateStatus(payload: {
    ticketId: string | number;
    status: TicketStatus | string;
  }) {
    const payloadData = { payload };

    return await commonRequest<any>('POST', payloadData, 'Ticket/updateStatus');
  }

  async getTicket(payload: any) {
    return await commonRequest<TicketDto>(
      'GET',
      null,
      `/Ticket/getTicket?ticketId=${payload.ticketId}&extended=true`
    );
  }

  async getTicketAttachmentUrl(payload: any) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'GET',
      payloadData,
      `/Ticket/getTicketAttachmentUrl?ticketId=${payload.ticketId}&fileOrder=${
        payload.fileOrder || 0
      }`
    );
  }
  async deleteAttachment(payload: any) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      `/Ticket/deleteAttachment?ticketId=${payload.ticketId}&fileOrder=${
        payload.fileOrder || 0
      }`
    );
  }

  async getTicketCommentAttachmentUrl(payload: any) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'GET',
      payloadData,
      `/Ticket/getTicketCommentAttachmentUrl?commentId=${
        payload.commentId
      }&fileOrder=${payload.fileOrder || 0}`
    );
  }

  async getAllowedAssignees(payload: any) {
    const payloadData = { payload };
    return await commonRequest<Employee[]>(
      'GET',
      payloadData,
      `/Ticket/getAllowedAssignees?ticketId=${payload.ticketId}`
    );
  }

  async submitRequesterFeedback(payload: {
    ticketId: string | number;
    wasResolved: boolean;
  }) {
    const payloadData = { payload };
    return await commonRequest<Employee[]>(
      'POST',
      payloadData,
      `/Ticket/submitRequesterFeedback`
    );
  }
}

export default TicketingProvider;
