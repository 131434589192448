import React from 'react';

const addCategoryIcon = ({ stroke }) => {
  return (
    <span role="img" aria-label="export" className="anticon anticon-export">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.23462 6.99967H6.98462M6.98462 6.99967H4.73462M6.98462 6.99967V9.24967M6.98462 6.99967L6.98462 4.74967M12.9844 3.25L12.9844 10.7501C12.9844 11.9927 11.977 13.0001 10.7344 13.0001H3.23438C1.99173 13.0001 0.984375 11.9927 0.984375 10.7501V3.25C0.984375 2.00736 1.99173 1 3.23438 1H10.7344C11.977 1 12.9844 2.00736 12.9844 3.25Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};

export default addCategoryIcon;
