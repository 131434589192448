import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { RootState } from 'redux/store';

const useApiRequest = <T>(
  method,
  payload,
  initState,
  parameters = {},
  refetchPeriodMinutes = null,
  dependencies = [],
  ignoreHookExecution = false,
  ignoreStatusUpdate = false // Ignore status update when method is called
) => {
  const [data, setData] = useState<T>(initState);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('pending');
  const methodUpdateSelector = useSelector(
    (state: RootState) => state.methodUpdate
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (ignoreHookExecution) {
      return;
    }
    callMethod({ payload, parameters });
    let refetchInterval = null;
    if (refetchPeriodMinutes) {
      setInterval(() => {
        refetchInterval = callMethod({ payload, parameters });
      }, refetchPeriodMinutes * 60 * 1000);
    }

    return () => refetchInterval && clearInterval(refetchInterval);
  }, [...dependencies]);

  useEffect(() => {
    if (ignoreHookExecution) {
      return;
    }
    if (methodUpdateSelector.isMethodUpdated) {
      callMethod({ payload, parameters });
      dispatch(setMethodUpdate(false));
    }
  }, [methodUpdateSelector.isMethodUpdated, data]);

  async function callMethod({ payload, parameters }) {
    if (!method) {
      return;
    }
    try {
      !ignoreStatusUpdate && setStatus('pending');
      const response = await method(payload, parameters);

      if (
        response &&
        typeof response === 'object' &&
        Object.keys(response).length
      ) {
        setData(response.data);
        setStatus('success');
      } else {
        setError(response.error);
        setStatus('error');
      }
    } catch (error) {
      setError(error);
      setStatus('error');
    }
  }
  return { data, error, status };
};

export default useApiRequest;
