import { commonRequest } from 'services/generic';
import { ITeam } from './types';

export default class TeamsProvider {
  async getTeam(payload: string, parameters) {
    const payloadData = { payload, parameters };
    return await commonRequest<ITeam>('POST', payloadData, 'Team/GetTeam');
  }

  async getEmployeeTeams(parameters) {
    const payloadData = { parameters };
    return await commonRequest<ITeam>(
      'POST',
      payloadData,
      'Team/GetEmployeeTeams'
    );
  }

  async getMyTeams() {
    return await commonRequest<any>('POST', null, 'Team/GetMyTeams');
  }

  async updateTeam(payload: ITeam) {
    const payloadData = { payload };
    return await commonRequest<ITeam>('POST', payloadData, 'Team/updateTeam');
  }
  async getAllTeams(payload: string) {
    const payloadData = { payload };
    return await commonRequest<ITeam[]>(
      'POST',
      payloadData,
      'Team/getAllTeams'
    );
  }

  async addTeam(payload) {
    const payloadData = { payload };
    return await commonRequest<ITeam>('POST', payloadData, 'Team/addTeam');
  }

  async assignMember(payload = null) {
    const payloadData = { payload };
    return await commonRequest<ITeam>(
      'POST',
      payloadData,
      'Team/assignMembers'
    );
  }
  async removeMember(payload = null, parameters: object) {
    const payloadData = { payload, parameters };
    return await commonRequest<ITeam>('POST', payloadData, 'Team/RemoveMember');
  }

  async updateTeamPicture(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      '/Team/updateTeamPicture'
    );
  }

  async addTeamContact(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<any>('POST', payloadData, '/Team/AddContact');
  }

  async updateTeamContact(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<any>('POST', payloadData, '/Team/UpdateContact');
  }

  async deleteTeamContact(payload = null, parameters: object) {
    const payloadData = { payload, parameters };
    return await commonRequest<any>('POST', payloadData, '/Team/DeleteContact');
  }
}
