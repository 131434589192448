export enum AssignmentTypes {
  None = 'None',
  AllAssignements = 'AllAssignements',
  AssignedToMe = 'AssignedToMe',
  NotAssigned = 'NotAssigned',
  AssignedToOther = 'AssignedToOther'
}

export enum TicketPriority {
  None = 'None',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical'
}

export enum TicketType {
  None = 'None',
  AllCategories = 'AllCategories',
  Request = 'Request',
  Incident = 'Incident'
}

export enum TicketStatus {
  None = 'None',
  New = 'New',
  Open = 'Open',
  Resolved = 'Resolved',
  Closed = 'Closed'
}

interface TicketCategory {
  categoryId: number;
  ticketType: TicketType;
  name: string;
  //discuss with Vasile
  //   AutomatedTask?: AutomatedTask | null;
}
interface TicketSubCategory {
  subCategoryId: number;
  name: string;
  category: TicketCategory;
  // automatedTask?: AutomatedTask | null;
}
export interface TicketBaseDto {
  ticketId: number;
  type: TicketType;
  status: TicketStatus;
  priority: TicketPriority;
  title: string;
  details: string;
  assignee?: {
    externalAvatarUrl: string;
    employeeId: number;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  ticketNumber: number;
}

export interface PagedResponse<T> {
  responseData: T[];
  totalCount: number;
}
// not yet needed
// interface WorkQueueDto {
//   WorkQueueId: number;
//   Name: string;
// }
enum TicketUpdateType {
  None = 'None',
  Status = 'Status',
  Priority = 'Priority',
  Assignee = 'Assignee',
  Content = 'Content',
  Title = 'Title'
}
interface TicketUpdateDto {
  ticketId: number;
}

export interface Employee {
  externalAvatarUrl: string;
  employeeId: string | number;
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
}
export interface TicketCommentDto {
  commentId?: number | null;
  message: string;
  createdOn?: Date | null;
  author: Employee;
  attachmentNames: (string | null)[];
}
export interface TicketDto {
  ticketId: number;
  type: TicketType;
  status: TicketStatus;
  priority: TicketPriority;
  title: string;
  details: string;
  category: TicketCategory;
  subCategory?: TicketSubCategory | null;
  assignee?: Employee | null;
  requesterEmployeeId: number;
  requester: Employee;
  createdOn: Date;
  updatedOn?: Date | null;
  firstAssignedOn?: Date | null;
  workQueueId?: number | null;
  attachmentNames: string[];
  dueDate?: Date | null;
  comments: TicketCommentDto[];
  history: any[];
  isAwaitingUserFeedback?: boolean | null;
  awaitingUserFeedbackDate?: Date | null;
  ticketNumber: number;
}

export interface TicketCommentAddDto {
  ticketId: number;
  message: string;
  attachments?: FileList | null;
}
export interface TicketHistoryDto {
  ticketHistoryId: number;
  ticketId: number;
  updateType: TicketUpdateType;
  timeStamp: Date;
  updatedByEmployeeId?: number | null;
  previousStatus?: TicketStatus | null;
  updatedStatus?: TicketStatus | null;
  previousPriority?: TicketPriority | null;
  updatedPriority?: TicketPriority | null;
  previousAssignee?: Employee | null;
  updatedAssignee?: Employee | null;
  previousTitle?: string | null;
  updatedTitle?: string | null;
  previousDetails?: string | null;
  updatedDetails?: string | null;
  updatedBy?: Employee;
}
interface TicketUpdateContentDto extends TicketUpdateDto {
  title?: string | null;
  details?: string | null;
  files?: FileList | null;
}
interface TicketUpdateStatusDto extends TicketUpdateDto {
  status: TicketStatus;
}
interface TicketUpdatePriorityDto extends TicketUpdateDto {
  priority: TicketPriority;
}
interface TicketUpdateAssigneeDto extends TicketUpdateDto {
  assignedId?: number | null;
}
interface TicketRequesterFeedbackDto {
  ticketId: number;
  wasResolved: boolean;
}
