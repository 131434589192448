import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

export interface IModalContext {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const useModalComponentContext = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTopRoleModalOpen, setIsTopRoleModalOpen] = useState<boolean>(false);
  const [viewRequestModal, setViewRequestModal] = useState<boolean>(false);

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  function toggleTopRoleModal() {
    setIsTopRoleModalOpen(!isTopRoleModalOpen);
  }

  function toggleRequestModal() {
    setViewRequestModal(!viewRequestModal);
  }

  return {
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    isTopRoleModalOpen,
    setIsTopRoleModalOpen,
    toggleTopRoleModal,
    viewRequestModal,
    setViewRequestModal,
    toggleRequestModal,
  };
};

const ModalContext = createContext(
  {} as ReturnType<typeof useModalComponentContext>
);

export const ModalContextProvider = ({ children }) => {
  return (
    <ModalContext.Provider value={useModalComponentContext()}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
