import { TicketingService } from 'services';
import PersonSelector from '../PersonSelector';
import useIsUpdateOngoing from '../../../../../../../hooks/useIsUpdateOngoing';
import { RootState, useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { getTicketById } from 'redux/slices/ticketingSlice/actions';
import useAssigneeEmployeesInSameWorkQueue from './useAssigneeEmployeeInWorkQueue';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
} from 'utils/userManagement';
import avatar from 'assets/avatar.png';
export const AsigneeSelect = () => {
  const { selectedTicket: ticket } = useSelector(
    (state: RootState) => state.ticketing
  );
  const { user } = useAuth();

  const dispatch = useAppDispatch();
  const { observeUpdateEnd, isUpdateOnGoing } = useIsUpdateOngoing();
  const assigneeEmployees = useAssigneeEmployeesInSameWorkQueue(
    ticket?.ticketId
  );

  const onAssigneeChange = (employeeId) => {
    employeeId !== ticket?.assignee?.employeeId &&
      observeUpdateEnd(() =>
        TicketingService.updateAssignee({
          ticketId: ticket.ticketId,
          assigneeId: employeeId,
        }).then(() => {
          dispatch(getTicketById(ticket?.ticketId));
        })
      );
  };

  const allowSelectionCheck = () => {
    const userRole = getCurrentUserRole(user);
    const isAdminUserRole = allowPermision(userRole, adminRoles);
    const isUserPartOfWorkQueue = (email) =>
      assigneeEmployees.some((user) => user?.email.toLowerCase() === email);
    const isTicketClosed = ticket?.status === 'Closed';
    return (
      (isUserPartOfWorkQueue(user?.email) || isAdminUserRole) && !isTicketClosed
    );
  };

  return (
    <div
      style={
        isUpdateOnGoing
          ? {
              opacity: 0.4,
              pointerEvents: 'none',
            }
          : null
      }
    >
      <PersonSelector
        data={assigneeEmployees}
        avatarSrc={ticket?.assignee?.externalAvatarUrl ?? avatar}
        employee={ticket?.assignee}
        onSelect={allowSelectionCheck() ? onAssigneeChange : undefined}
      />
    </div>
  );
};
export default AsigneeSelect;
