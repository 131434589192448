import React, { Dispatch, SetStateAction } from "react";

interface DropdownContextType {
  dropdownKey: string | null;
  setDropdownKey: Dispatch<SetStateAction<string | null>>;
};

export const DropdownContext = React.createContext<DropdownContextType>({
  dropdownKey: null,
  setDropdownKey: () => {}
});

export const DropdownProvider = ({ children }) => {
  const [dropdownKey, setDropdownKey] = React.useState<string | null>(null);
  return (
    <DropdownContext.Provider value={{ dropdownKey, setDropdownKey }}>
      {children}
    </DropdownContext.Provider>
  );
};
