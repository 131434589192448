import {
  EmployeeDocumentSubType,
  EmployeeDocumentType,
  EmployeeDocumentStatus,
} from 'services/providers/EmployeeDocumentProvider/utils';

export const DocumentTypes = [
  EmployeeDocumentType.IdentityDocument,
  EmployeeDocumentType.Agreement,
  EmployeeDocumentType.Diploma,
  EmployeeDocumentType.TimeOffDocument,
  EmployeeDocumentType.Others,
];

export const DocumentStatuses = [
  EmployeeDocumentStatus.Uploaded,
  EmployeeDocumentStatus.Approved,
  EmployeeDocumentStatus.Denied,
  EmployeeDocumentStatus.Archived,
];

export const DocumentSubTypes = {
  [EmployeeDocumentType.IdentityDocument]: [
    EmployeeDocumentSubType.IdentificationDocument,
    EmployeeDocumentSubType.Passport,
    EmployeeDocumentSubType.DriversLicense,
    EmployeeDocumentSubType.BirthCertificate,
    EmployeeDocumentSubType.Other,
  ],
  [EmployeeDocumentType.Diploma]: [
    EmployeeDocumentSubType.BaccalaureateDiploma,
    EmployeeDocumentSubType.CollegeDiploma,
    EmployeeDocumentSubType.BachelorsDegree,
    EmployeeDocumentSubType.MastersDegree,
    EmployeeDocumentSubType.DoctorateDegree,
    EmployeeDocumentSubType.CoursesAndCertifications,
    EmployeeDocumentSubType.Other,
  ],
  [EmployeeDocumentType.Agreement]: [
    EmployeeDocumentSubType.WorkAgreement,
    EmployeeDocumentSubType.ServicesAgreement,
    EmployeeDocumentSubType.Appendix,
    EmployeeDocumentSubType.Assets,
    EmployeeDocumentSubType.Other,
  ],
  [EmployeeDocumentType.Others]: [EmployeeDocumentSubType.Other],
};
