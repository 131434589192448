import timezones from './metadata/timezones.json';

export const getDefaultISO2Code = (countryCode: string) => {
  const countryOfApp = countryCode.toLowerCase();
  /** Returns the default ISO2 code, based on the user's timezone */
  return (
    countryOfApp ||
    timezones[
      Intl.DateTimeFormat().resolvedOptions().timeZone as keyof typeof timezones
    ] ||
    'md'
  );
};
