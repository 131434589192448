import { FormattedMessage } from 'react-intl';
import { parseHourMinutes } from 'utils/getDateTimeDifference';

type ITimeOff = {
  enableClockIn: boolean;
  lastClockIn: any;
  statistics: Array<{
    statisticType: string;
    owner: object;
    totalDaysAllowed?: number;
    daysTaken: number;
    daysLocked: number;
    daysLeft?: number;
  }>;
};

export const parseTimeOffStatistics = (data) => {
  const timeOff = data as ITimeOff;
  const formatedStatistics = Object.fromEntries(
    timeOff.statistics
      .filter((policy) => policy.statisticType !== 'None')
      .map(({ statisticType, ...rows }) => [statisticType, rows])
  );
  const keyColumns = Object.keys(formatedStatistics);
  const rowKeys = Object.keys(Object.values(formatedStatistics)[0] || {});

  const getValue = (statisticType: string, row: string): string => {
    const rows = formatedStatistics[statisticType];
    if (
      ['PersonalLeave', 'LeaveOffsetting', 'Overtime'].includes(
        statisticType
      ) &&
      ['daysTaken', 'daysLocked'].includes(row)
    ) {
      return rows && parseHourMinutes(rows[row] * 24);
    }
    return (rows && rows[row]?.toString()) || '∞';
  };
  return formatedStatistics
    ? {
      keyColumns: keyColumns,
      rowKeys: rowKeys,
      formatedStatistics: formatedStatistics,
      getValue: getValue,
    }
    : {};
};

export const columnNameTable = [
  { title: <FormattedMessage id="Vacation" />, key: 'Vacation' },
  { title: <FormattedMessage id="PersonalLeave" />, key: 'PersonalLeave' },
  { title: <FormattedMessage id="WorkFromHomeShort" />, key: 'WorkFromHome' },
  { title: <FormattedMessage id="LeaveOffsetting" />, key: 'LeaveOffsetting' },
  { title: <FormattedMessage id="Medical" />, key: 'Medical' },
  { title: <FormattedMessage id="Unpaid" />, key: 'Unpaid' },
  { title: <FormattedMessage id="Paternity" />, key: 'Paternity' },
  { title: <FormattedMessage id="Maternity" />, key: 'Maternity' },
  { title: <FormattedMessage id="Childcare" />, key: 'Childcare' },
  { title: <FormattedMessage id="Overtime" />, key: 'Overtime' },
  { title: <FormattedMessage id="BloodDonation" />, key: 'BloodDonation' },
  { title: <FormattedMessage id="Bereavement" />, key: 'Bereavement' },
  { title: <FormattedMessage id="Marriage" />, key: 'Marriage' },
  {
    title: <FormattedMessage id="ForgotBadge" />,
    key: 'ForgotBadge',
  },
];

export const rowNameTable = [
  {
    titleRow: (
      <FormattedMessage id="TimeManagementWidget_RequestRowNameAllowed" />
    ),
    keyRow: 'totalDaysAllowed',
    colorRow: '#393EC0',
  },
  {
    titleRow: (
      <FormattedMessage id="TimeManagementWidget_RequestRowNameTaken" />
    ),
    keyRow: 'daysTaken',
    colorRow: '#EB5757',
  },
  {
    titleRow: (
      <FormattedMessage id="TimeManagementWidget_RequestRowNameFrozen" />
    ),
    keyRow: 'daysLocked',
    colorRow: '#F2C94C',
  },
  {
    titleRow: <FormattedMessage id="TimeManagementWidget_RequestRowNameLeft" />,
    keyRow: 'daysLeft',
    colorRow: '#219653',
  },
];
