import * as React from 'react';
import { motion } from 'framer-motion';

import { spring, SwitchProps } from './types';
import { useThemeContext } from '../../AppProvider/ConfigProviderSettings';

import './index.less';

const CustomSwitch: React.FC<SwitchProps> = (props) => {
  const {
    values,
    defaultValue,
    onChange,
  } = props;

  const { theme } = useThemeContext();

  const [isOn, setIsOn] =
    React.useState<boolean>(defaultValue === values[1].value);

  const toggleSwitch = () => {
    setIsOn(!isOn);
    onChange && onChange();
  };

  return (
    <div className='custom-switch' data-ison={isOn} onClick={toggleSwitch}>
      {isOn && <motion.div
        className={`second ${theme}`}
        whileHover={{
          opacity: 1,
          transition: { duration: 1 }
        }}
        whileTap={{ scale: 0.9 }}
      >
        {values[0].icon}
      </motion.div>}

      {!isOn ?
        // Light Theme
        <motion.div className={`custom-handle ${theme}`} layout transition={spring}>
          {values[0].icon}
        </motion.div>
        :
        // Dark Theme
        <motion.div className={`custom-handle ${theme}`} layout transition={spring}>
          {values[1].icon}
        </motion.div>
      }

      {!isOn && <motion.div
        className={`second ${theme}`}
        whileHover={{
          opacity: 1,
          transition: { duration: 1 }
        }}
        whileTap={{ scale: 0.9 }}
      >
        {values[1].icon}
      </motion.div>}
    </div>
  );
};

export default CustomSwitch;