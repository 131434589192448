import { CalendarOutlined } from '@ant-design/icons';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { useColleaguesRequestsFilterContext } from 'context/TimeManagement/ColleaguesRequests/context';
import dayjs from 'dayjs';
import { forwardRef, HTMLProps } from 'react';
import { useIntl } from 'react-intl';

import './CustomInputDatePicker.less';

interface CustomInputDatePickerProps extends HTMLProps<HTMLInputElement> {
  onClick?: () => void;
  dates?: any;
}
const formatDate = (date: Date | null | undefined) => {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return dayjs(date).format('DD/MM/YYYY');
  }
  return '';
};

const CustomInputDatePicker = forwardRef<
  HTMLInputElement,
  CustomInputDatePickerProps
>(({ value, onClick, dates, ...rest }, ref) => {
  const [startDate, endDate] = dates;
  const intl = useIntl();
  const { theme } = useThemeContext();
  
  const { timeOffColleaguesState } = useColleaguesRequestsFilterContext();
  // Placeholder
  const startDateLabel = intl.formatMessage({ id: 'DataStart' });
  const endDateLabel = intl.formatMessage({ id: 'DataEnd' });

  return (
    <div className={`container-picker-input ${theme} `} ref={ref}>
      <div className="picker-input">
        <input
          name="dateStart"
          type="text"
          readOnly
          placeholder={startDateLabel}
          autoComplete="off"
          value={
            timeOffColleaguesState?.filterBy?.period?.startDate
              ? formatDate(dayjs(timeOffColleaguesState?.filterBy?.period?.startDate).toDate())
              : formatDate(startDate)
          }
          onClick={onClick}
        />
      </div>
      <div className="picker-input">
        <input
          readOnly
          placeholder={endDateLabel}
          autoComplete="off"
          value={
            timeOffColleaguesState?.filterBy?.period?.endDate
              ? formatDate(dayjs(timeOffColleaguesState?.filterBy?.period?.endDate).toDate())
              : formatDate(endDate)
          }
          onClick={onClick}
        />
      </div>
      <div className="picker-bar"></div>
      <span className="picker-icon-suffix">
        <CalendarOutlined className="calendar-icon-dtp" onClick={onClick} />
      </span>
    </div>
  );
});

export default CustomInputDatePicker;
