export const DashboardSvg = () => {
  return (
    <span
      role="img"
      aria-label="dashboard"
      className="anticon anticon-dashboard"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.4667 1.39996C19.6449 1.39996 20.6 2.34201 20.6 3.50407L20.6 7.0959C20.6 8.25796 19.6449 9.2 18.4667 9.2H15.2667C14.0885 9.2 13.1334 8.25796 13.1334 7.09589L13.1334 3.50407C13.1334 2.34201 14.0885 1.39996 15.2667 1.39996L18.4667 1.39996Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.53336 1.39996C2.35515 1.39996 1.40002 2.34201 1.40002 3.50407L1.40003 7.0959C1.40003 8.25796 2.35516 9.2 3.53337 9.2H6.73337C7.91157 9.2 8.8667 8.25796 8.8667 7.09589L8.86669 3.50407C8.86669 2.34201 7.91156 1.39996 6.73336 1.39996L3.53336 1.39996Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.4667 12.8C19.6449 12.8 20.6 13.742 20.6 14.9041V18.4959C20.6 19.658 19.6449 20.6 18.4667 20.6H15.2667C14.0885 20.6 13.1334 19.658 13.1334 18.4959L13.1334 14.9041C13.1334 13.742 14.0885 12.8 15.2667 12.8H18.4667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.53337 12.8C2.35516 12.8 1.40003 13.742 1.40003 14.9041L1.40004 18.4959C1.40004 19.658 2.35516 20.6 3.53337 20.6H6.73337C7.91157 20.6 8.8667 19.658 8.8667 18.4959L8.8667 14.9041C8.8667 13.742 7.91157 12.8 6.73336 12.8H3.53337Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
