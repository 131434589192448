export enum EventsTypes {
  PersonalLeave = 'PersonalLeave',
  BloodDonation = 'BloodDonation',
  Medical = 'Medical',
  Vacation = 'Vacation',
  WorkFromHome = 'WorkFromHome',
  ForgotBadge = 'ForgotBadge',
  Unpaid = 'Unpaid',
  Paternity = 'Paternity',
  Childcare = 'Childcare',
  Holiday = 'Holiday',
  Maternity = 'Maternity',
  LeaveOffsetting = 'LeaveOffsetting',
  Overtime = 'Overtime',
  Bereavement = 'Bereavement',
  Marriage = 'Marriage',
  Weekend = 'Weekend',
  MilitaryTraining = 'MilitaryTraining',
  TwoOrMoreChildren = 'TwoOrMoreChildren',
  BusinessTrip = 'BusinessTrip',
  FirstDayOfSchool = 'FirstDayOfSchool',
  LastDayOfScool = 'LastDayOfScool',
  ChildcareLeaveUnpaid = 'ChildcareLeaveUnpaid',
  BirthOfAChild = 'BirthOfAChild',
  Draft = 'Draft'
}

type Color =
  | `rgb(${number}, ${number}, ${number})`
  | `rgba(${number}, ${number}, ${number}, ${number})`
  | `#${string}`;

export type IEventsTypes = {
  cellColor?: Color;
  darkcellColor?: Color;
};

export const EventsStatusDefinitions: Record<EventsTypes, IEventsTypes> = {
  [EventsTypes.Vacation]: {
    cellColor: 'rgba(90, 176, 255, 1)',
    darkcellColor: 'rgba(90, 176, 255, 1)'
  },
  [EventsTypes.Unpaid]: {
    cellColor: 'rgba(220, 31, 122, 1)',
    darkcellColor: 'rgba(220, 31, 122, 1)'
  },
  [EventsTypes.Medical]: {
    cellColor: 'rgba(242, 156, 76, 1)',
    darkcellColor: 'rgba(242, 156, 76, 1)'
  },
  [EventsTypes.Childcare]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Holiday]: {
    cellColor: '#EB5757',
    darkcellColor: '#EB5757'
  },
  [EventsTypes.PersonalLeave]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.BloodDonation]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Draft]: {
    cellColor: 'rgba(220, 31, 122, 1)',
    darkcellColor: 'rgba(220, 31, 122, 1)'
  },

  [EventsTypes.ForgotBadge]: {
    cellColor: 'rgba(152, 206, 0, 1)',
    darkcellColor: 'rgba(152, 206, 0, 1)'
  },
  [EventsTypes.Paternity]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Overtime]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.Maternity]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.LeaveOffsetting]: {
    cellColor: 'rgba(100, 165, 28, 1)',
    darkcellColor: 'rgba(100, 165, 28, 1)'
  },
  [EventsTypes.Bereavement]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.Marriage]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.MilitaryTraining]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.TwoOrMoreChildren]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.BusinessTrip]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.FirstDayOfSchool]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.LastDayOfScool]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.ChildcareLeaveUnpaid]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.BirthOfAChild]: {
    cellColor: 'rgba(70, 255, 177, 1)',
    darkcellColor: 'rgba(70, 255, 177, 1)'
  },
  [EventsTypes.WorkFromHome]: {
    cellColor: 'rgba(217, 63, 255, 1)',
    darkcellColor: 'rgba(217, 63, 255, 1)'
  },
  [EventsTypes.Weekend]: {
    cellColor: '#BDA7E899',
    darkcellColor: '#BDA7E899'
  }
};

export const legentColors = [
  { request: 'Vacation', color: 'rgba(90, 176, 255, 1)' },
  { request: 'HourlyRequest', color: 'rgba(100, 165, 28, 1)' },
  { request: 'PendingRequest', color: 'rgba(196, 196, 196, 1)' },
  { request: 'OverlappingEvents', color: 'rgba(255, 230, 0, 1)' },
  { request: 'WorkFromHome', color: 'rgba(217, 63, 255, 1)' },
  { request: 'SpecialLeave', color: 'rgba(70, 255, 177, 1)' },
  { request: 'SickLeave', color: 'rgba(242, 156, 76, 1)' },
  { request: 'Unpaid', color: 'rgba(220, 31, 122, 1)' },
  { request: 'PublicHoliday', color: '#EB5757' },
  { request: 'BadgeForgot', color: 'rgba(152, 206, 0, 1)' }
];
