export type IPolicyConfiguration = Record<
  'MD' | 'RO',
  {
    DefaultTimeOffRequests: Array<{
      icon: JSX.Element;
      title: JSX.Element | string;
      request: string;
      allowUnfinishedRequests?: boolean;
      isHidden?: boolean;
      policyType: PolicyTypes;
      isDocumentUploadRequired: boolean;
      allowEditRequest: boolean;
      isSingleDayRequest?: boolean;
      isAdminOnly?: boolean;
      isSpecialRequest?: boolean;
    }>;
  }
>;

export enum PolicyTypes {
  FullDay = 'FullDay',
  TimeBased = 'TimeBased',
}

export enum TypeOfPolicies {
  Vacation = 'Vacation',
  Medical = 'Medical',
  Paternity = 'Paternity',
  Maternity = 'Maternity',
  Childcare = 'Childcare',
  WorkFromHome = 'WorkFromHome',
  Unpaid = 'Unpaid',
  PersonalLeave = 'PersonalLeave',
  LeaveOffsetting = 'LeaveOffsetting',
  Overtime = 'Overtime',
  BloodDonation = 'BloodDonation',
  Bereavement = 'Bereavement',
  Marriage = 'Marriage',
  MilitaryTraining = 'MilitaryTraining',
  TwoOrMoreChildren = 'TwoOrMoreChildren',
  BusinessTrip = 'BusinessTrip',
  FirstDayOfSchool = 'FirstDayOfSchool',
  LastDayOfSchool = 'LastDayOfSchool',
  ChildcareLeaveUnpaid = 'ChildcareLeaveUnpaid',
  BirthOfAChild = 'BirthOfAChild',
  ForgotBadge = 'ForgotBadge',
}
