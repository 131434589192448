import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { DatePicker, Form, Input } from 'antd';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import {
  DateToBeginningOfLocalDate,
  convertToLocalDate
} from 'components/DateParser';
import { SelectDropdown } from 'components/Dropdown';
import { useModalContext } from 'components/Modal/ModalProvider';
import {
  onFinish,
  useParseCurrencyOptions
} from 'components/Software/SoftwareForm/utils';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { getCategoryOptions } from 'pages/Inventory/Categories/utils';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFormValidation } from 'redux/slices/methodUpdateSlice';
import { AssetsService } from 'services';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import HardwareHistory from './HardwareHistory';
import { handleDecimalsForIntegerValue } from './utils';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { AssetFormPlaceholder } from 'components/Placeholders';
import {
  addNewItemToAssets,
  updateAssetsStore
} from 'redux/slices/assetsSlice';
import { userRole } from '../../Employee/EmployeeForm/utils';
import {
  allowPermision,
  getCurrentUserRole,
  userRoles
} from '../../../utils/userManagement';
import { useAuth } from '../../../services/providers/AuthProvider';

type HardwareFormProps = {
  scope: string;
  assetId?: number | string;
  assignPerson?: object | null;
};

const HardwareForm: React.FC<HardwareFormProps> = ({
  scope,
  assetId,
  assignPerson
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const { toggleModal } = useModalContext();
  const { isMobile, deviceWidth } = useMobileContext();
  const [isFormDisabled, setIsFormDisabled] = React.useState<boolean>(false);
  const [showHistoryTable, setShowHistoryTable] =
    React.useState<boolean>(false);
  const [historyData, setHistoryData] = React.useState<any[]>([]);
  const [price, setPrice] = React.useState<string>(null);
  const hasPermissionToSeExtended = allowPermision(userRole, [
    userRoles.Admin,
    userRoles.Accountant
  ]);
  const categories =
    hasPermissionToSeExtended && getCategoryOptions({ assetType: 'Hardware' });
  const currencies = useParseCurrencyOptions();

  const assetParams = { assetId: assetId, extended: true };
  const { data, status } = useApiRequestHook<any>(
    AssetsService.getAsset,
    'true',
    [],
    assetParams,
    null,
    [],
    !assetId // ignoreHookExecution is true when assetId is 0
  );

  React.useEffect(() => {
    const getHistory = async () => {
      const assetHistoryResponse = await AssetsService.getAssetHistory({
        assetId: assetId as number
      });
      setHistoryData(assetHistoryResponse?.data ?? []);
    };

    if (hasPermissionToSeExtended) getHistory();
  }, [assetId]);

  const ButtonCustom = BaseButton(ButtonSimple);

  const onFinishFormActions = {
    dispatch,
    toggleModal,
    triggerOtherActionsWithResult: res => {
      if (scope === 'add') {
        dispatch(addNewItemToAssets(res?.data));
      } else if (scope === 'edit') {
        dispatch(updateAssetsStore(res?.data));
      }
    }
  };

  const noSelectedFieldText = intl.formatMessage({
    id: 'label_SoftwHardForm_placeholder_None'
  });

  React.useEffect(() => {
    form.setFieldValue('currency', 'EUR');

    if (scope === 'add') {
      return;
    }

    setIsFormDisabled(scope === 'view');

    if (status !== 'success') {
      return;
    }

    const fields = {
      assetName: data?.assetName,
      model: data?.model,
      partNumber: data?.partNumber,
      categoryId: data?.categoryName || noSelectedFieldText,
      datePurchased: data?.datePurchased
        ? convertToLocalDate(data.datePurchased)
        : null,
      details: data?.details,
      inventoryNumber: data?.inventoryNumber,
      invoiceNumber: data?.invoiceNumber,
      price: data?.price?.toString(),
      serialNumber: data?.serialNumber,
      currency: data?.currency || 'EUR',
      type: data?.type,
      assigneeName: data?.assigneeName
    };

    form.setFieldsValue(fields);
  }, [data, scope]);

  const { TextArea } = Input;
  const { theme } = useThemeContext();

  const navigate = useNavigate();

  return (
    <ComponentWithStatus
      status={scope === 'add' ? 'success' : status}
      Placeholder={
        <AssetFormPlaceholder
          boxheight={272}
          boxwidth={949}
          count={6}
          gutter={6}
        />
      }
    >
      <Form
        form={form}
        id="hardwareForm"
        key="hardwareFormKey"
        layout="horizontal"
        name="basic"
        labelAlign="left"
        disabled={isFormDisabled}
        labelWrap={true}
        onFinish={data => {
          const { price, ...hardwareData } = data;
          const payload =
            price === '' || !price || !price.length ? hardwareData : data;

          payload.categoryId =
            typeof payload.categoryId === 'string'
              ? categories
                  .filter(
                    category => category.props.children === payload.categoryId
                  )
                  .map(value => value.props && Number(value.props.value))[0]
              : payload.categoryId;

          payload.datePurchased = payload.datePurchased
            ? DateToBeginningOfLocalDate(payload.datePurchased)
            : null;

          dispatch(setFormValidation(true));
          onFinish(scope, payload, assetId, onFinishFormActions);
        }}
        onFinishFailed={validationError =>
          onFormFinishFailedValidation(validationError, dispatch)
        }
      >
        <div className="ant-form-two-columns-row">
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            label={<FormattedMessage id="label_SoftwHardForm_category" />}
            name="categoryId"
            isDisabled={isFormDisabled}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
          >
            {['edit', 'add'].includes(scope) ? (
              <SelectDropdown placement_bottom={true}>
                {categories}
              </SelectDropdown>
            ) : (
              <Input disabled />
            )}
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            offset={1}
            isDisabled
            isReadOnly={`col-form-item-component-view ${theme}`}
            label={<FormattedMessage id="label_SoftwHardForm_type" />}
            name="type"
            initialValue="Hardware"
            rules={setFormItemRules({
              isRequired: !isFormDisabled,
              requiredMessage: intl.formatMessage({
                id: 'CategoriesTypeValidationRequired'
              })
            })}
          >
            <Input disabled />
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            label={<FormattedMessage id="label_hardwareForm_asset_name" />}
            name="assetName"
            isDisabled={isFormDisabled}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            rules={setFormItemRules({
              isRequired: !isFormDisabled,
              requiredMessage: intl.formatMessage({
                id: 'AssetNameInputValidation'
              }),
              hasPattern: true,
              pattern:
                /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF '."\/\\,\-\u201C\u201D\u2013]+$/,
              patternMessage: intl.formatMessage({
                id: 'InventoryAssetsNameCharactersValidation'
              }),
              isMaxLengthRequired: true,
              maxLengthRequired: 50,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetNameLengthValidation'
              })
            })}
          >
            <TextArea autoSize={true} minLength={1} maxLength={51} />
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            name="inventoryNumber"
            label={<FormattedMessage id="label_hardwaForm_inventory_number" />}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            isDisabled={isFormDisabled}
            rules={setFormItemRules({
              isRequired: false,
              hasPattern: true,
              pattern: /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF \/\\-]+$/,
              patternMessage: intl.formatMessage({
                id: 'Inventory_Part_Serial_InvoiceCharactersValidationMessage'
              }),
              isMaxLengthRequired: true,
              maxLengthRequired: 50,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetNameLengthValidation'
              })
            })}
          >
            <Input minLength={1} maxLength={51} />
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            label={<FormattedMessage id="label_hardwareForm_model" />}
            name="model"
            isDisabled={isFormDisabled}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            rules={setFormItemRules({
              isRequired: false,
              hasPattern: true,
              pattern:
                /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF '."\/\\,\-\u201C\u201D\u2013]+$/,
              patternMessage: intl.formatMessage({
                id: 'InventoryAssetsNameCharactersValidation'
              }),
              isMaxLengthRequired: true,
              maxLengthRequired: 50,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetNameLengthValidation'
              })
            })}
          >
            <TextArea autoSize={true} minLength={1} maxLength={51} />
          </ColFormItemComponent>
          <ColFormItemComponent
            label={<FormattedMessage id="label_hardwareForm_part_number" />}
            className={`col-form-item-component ${theme}`}
            name="partNumber"
            offset={1}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            isDisabled={isFormDisabled}
            rules={setFormItemRules({
              isRequired: false,
              hasPattern: true,
              pattern: /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF \/\\-]+$/,
              patternMessage: intl.formatMessage({
                id: 'Inventory_Part_Serial_InvoiceCharactersValidationMessage'
              }),
              isMaxLengthRequired: true,
              maxLengthRequired: 50,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetNameLengthValidation'
              })
            })}
          >
            <Input minLength={1} maxLength={51} />
          </ColFormItemComponent>
          <ColFormItemComponent
            className={`col-form-item-component ${theme}`}
            name="details"
            offset={1}
            label={<FormattedMessage id="inventoryHardware_table_Details" />}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            isDisabled={isFormDisabled}
            rules={setFormItemRules({
              isRequired: false,
              isMaxLengthRequired: true,
              maxLengthRequired: 500,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetDetailsLengthValidation'
              })
            })}
          >
            <TextArea
              autoSize={{
                minRows: 1,
                maxRows: 4
              }}
              minLength={1}
              maxLength={501}
            />
          </ColFormItemComponent>
          <ColFormItemComponent
            label={<FormattedMessage id="label_hardwaForm_serial_number" />}
            className={`col-form-item-component ${theme}`}
            name="serialNumber"
            offset={1}
            isReadOnly={
              isFormDisabled ? `col-form-item-component-view ${theme}` : ``
            }
            isDisabled={isFormDisabled}
            rules={setFormItemRules({
              isRequired: false,
              hasPattern: true,
              pattern: /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF \/\\-]+$/,
              patternMessage: intl.formatMessage({
                id: 'Inventory_Part_Serial_InvoiceCharactersValidationMessage'
              }),
              isMaxLengthRequired: true,
              maxLengthRequired: 50,
              maxLengthRequiredMessage: intl.formatMessage({
                id: 'AssetNameLengthValidation'
              })
            })}
          >
            <Input minLength={1} maxLength={51} />
          </ColFormItemComponent>
          {hasPermissionToSeExtended && (
            <ColFormItemComponent
              className={`col-form-item-component ${theme}`}
              label={<FormattedMessage id="label_hardwaForm_date_purchased" />}
              name="datePurchased"
              isReadOnly={
                isFormDisabled ? `col-form-item-component-view ${theme}` : ``
              }
              isDisabled={isFormDisabled}
            >
              <DatePicker
                inputReadOnly={true}
                getPopupContainer={container => container}
                dropdownAlign={{ offset: [20, 270] }}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
              />
            </ColFormItemComponent>
          )}
          {hasPermissionToSeExtended && (
            <ColFormItemComponent
              offset={1}
              label={<FormattedMessage id="label_hardwaForm_invoice_number" />}
              className={`col-form-item-component ${theme}`}
              name="invoiceNumber"
              isReadOnly={
                isFormDisabled ? `col-form-item-component-view ${theme}` : ``
              }
              isDisabled={isFormDisabled}
              rules={setFormItemRules({
                isRequired: false,
                hasPattern: true,
                pattern: /^[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF \/\\-]+$/,
                patternMessage: intl.formatMessage({
                  id: 'Inventory_Part_Serial_InvoiceCharactersValidationMessage'
                }),
                isMaxLengthRequired: true,
                maxLengthRequired: 50,
                maxLengthRequiredMessage: intl.formatMessage({
                  id: 'AssetNameLengthValidation'
                })
              })}
            >
              <Input minLength={1} maxLength={51} />
            </ColFormItemComponent>
          )}

          {hasPermissionToSeExtended && (
            <ColFormItemComponent
              className={`col-form-item-component ${theme}`}
              style={{ maxHeight: '40px' }}
              label={<FormattedMessage id="label_SoftwHardForm_price" />}
              isReadOnly={
                isFormDisabled ? `col-form-item-component-view ${theme}` : ``
              }
              isDisabled={isFormDisabled}
            >
              <div className="ant-form-item-control-two-inputs">
                <ColFormItemComponent
                  className={`col-form-item-component ${theme}`}
                  name="price"
                  isReadOnly={
                    isFormDisabled
                      ? `col-form-item-component-view ${theme}`
                      : ``
                  }
                  isDisabled={isFormDisabled}
                  rules={setFormItemRules({
                    isRequired: false,
                    hasPattern: true,
                    pattern: /^(\d+)(,\d{0,2}|\.\d{0,2})?$/,
                    patternMessage: intl.formatMessage({
                      id: 'AssetPriceNumbersValidation'
                    })
                  })}
                >
                  <Input
                    value={price}
                    onChange={e =>
                      setPrice(
                        /^(\d+)(,\d{0,2}|\.\d{0,2})?$/.test(
                          e.currentTarget.value
                        )
                          ? e.currentTarget.value
                          : null
                      )
                    }
                    onBlur={e =>
                      setPrice(
                        handleDecimalsForIntegerValue(e.currentTarget.value)
                      )
                    }
                    style={{
                      width: deviceWidth > 769 ? '232px' : '100%'
                    }}
                  />
                </ColFormItemComponent>
                <ColFormItemComponent
                  className={`col-form-item-component ${theme}`}
                  name="currency"
                  rules={[
                    {
                      required: price ? true : false,
                      message: <FormattedMessage id="CurrencyValidation" />
                    }
                  ]}
                  isReadOnly={
                    isFormDisabled
                      ? `col-form-item-component-view ${theme}`
                      : ``
                  }
                  isDisabled={isFormDisabled}
                >
                  {['edit', 'add'].includes(scope) ? (
                    <SelectDropdown
                      allowClear={false}
                      dropdownStyle={{ animationDuration: '0s' }}
                      showSearch={false}
                      listHeight={isMobile ? 111 : 148}
                      style={{
                        width: '80px'
                      }}
                    >
                      {currencies}
                    </SelectDropdown>
                  ) : (
                    <Input
                      style={{
                        width: '80px'
                      }}
                    />
                  )}
                </ColFormItemComponent>
              </div>
            </ColFormItemComponent>
          )}

          {(scope === 'edit' || scope === 'view') && (
            <ColFormItemComponent
              label={<FormattedMessage id="label_SoftwHardForm_assignee" />}
              className={`col-form-item-component ${theme}`}
              offset={1}
              name="assigneeName"
              isDisabled
              isReadOnly={`col-form-item-component-view ${theme}`}
              onClick={() => {
                if (data && data?.assigneeId) {
                  toggleModal();
                  navigate(
                    `/allUsersList/viewProfile?employeeId=${data?.assigneeId}`
                  );
                }
              }}
            >
              <Input disabled />
            </ColFormItemComponent>
          )}
        </div>
      </Form>

      {historyData?.length && scope === 'view' ? (
        <div className="inventory-modal-history">
          <ButtonCustom
            className={`text-bold-normal btn-default-custom ${theme}`}
            key="hardwareHistoryButton"
            icon={showHistoryTable ? <MinusOutlined /> : <PlusOutlined />}
            onClick={() => setShowHistoryTable(state => !state)}
          >
            {showHistoryTable
              ? intl.formatMessage({ id: 'label_History_toggler_collapse' })
              : intl.formatMessage({ id: 'label_History_toggler_expand' })}
          </ButtonCustom>

          {showHistoryTable && <HardwareHistory historyData={historyData} />}
        </div>
      ) : null}
    </ComponentWithStatus>
  );
};

export default HardwareForm;
