export const LogOutComponent = () => {
  return (
    <span role='img' aria-label='export' className='anticon anticon-export'>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.76471 3.6999V2.2999C8.76471 1.9286 8.61597 1.5725 8.35121 1.30995C8.08645 1.0474 7.72736 0.899902 7.35294 0.899902H2.41176C2.03734 0.899902 1.67825 1.0474 1.4135 1.30995C1.14874 1.5725 1 1.9286 1 2.2999V10.6999C1 11.0712 1.14874 11.4273 1.4135 11.6899C1.67825 11.9524 2.03734 12.0999 2.41176 12.0999H7.35294C7.72736 12.0999 8.08645 11.9524 8.35121 11.6899C8.61597 11.4273 8.76471 11.0712 8.76471 10.6999V9.2999M4.52941 6.4999H13M13 6.4999L10.8824 4.3999M13 6.4999L10.8824 8.5999'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
};
