import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { HeaderMenuContext } from 'components/Layout/Header/HeaderMenuContext';
export const EditComponent = (props) => {
  const { isMobile } = useContext(HeaderMenuContext);
  return (
    <>
      <span
        role="img"
        aria-label="edit"
        className="anticon anticon-edit-icon"
        {...props}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2518 0.747586C15.0148 0.510574 14.7335 0.322564 14.4238 0.194293C14.1142 0.0660209 13.7824 0 13.4472 0C13.1121 0 12.7802 0.0660209 12.4706 0.194293C12.1609 0.322564 11.8796 0.510574 11.6426 0.747586L1.78315 10.6075C1.54211 10.8487 1.36045 11.1427 1.25251 11.4661L0.0339068 15.1234C-0.00502237 15.2408 -0.0105494 15.3667 0.0179435 15.487C0.0464363 15.6073 0.107826 15.7173 0.195252 15.8047C0.282677 15.8922 0.392694 15.9536 0.513005 15.9821C0.633315 16.0105 0.759179 16.005 0.876529 15.9661L4.53367 14.7474C4.85765 14.6408 5.15097 14.4581 5.39229 14.2168L15.2518 4.35821C15.489 4.12121 15.6772 3.83979 15.8055 3.53002C15.9339 3.22025 16 2.88822 16 2.5529C16 2.21758 15.9339 1.88555 15.8055 1.57578C15.6772 1.26602 15.489 0.984588 15.2518 0.747586ZM12.5852 1.69158C12.6979 1.57616 12.8323 1.48425 12.9807 1.42116C13.129 1.35807 13.2885 1.32506 13.4497 1.32404C13.611 1.32302 13.7708 1.35401 13.92 1.41522C14.0692 1.47643 14.2047 1.56663 14.3188 1.68061C14.4328 1.79459 14.5232 1.93008 14.5845 2.07921C14.6458 2.22835 14.6769 2.38818 14.676 2.54943C14.6751 2.71068 14.6422 2.87015 14.5793 3.0186C14.5163 3.16704 14.4245 3.30152 14.3092 3.41422L13.3319 4.39288L11.608 2.6689L12.5866 1.69158H12.5852ZM10.6654 3.61156L12.3879 5.33554L4.44968 13.2741C4.35487 13.3687 4.2394 13.4399 4.11236 13.4821L1.72049 14.2794L2.51778 11.8888C2.55989 11.7613 2.6311 11.6454 2.72577 11.5501L10.6654 3.61156Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </>
  );
};
