import { FormattedMessage } from 'react-intl';

export const titlesKeysofColumnsMembers = [
  {
    title: <FormattedMessage id="table_colleaguesCol_Full_Name" />,
    dataIndex: 'employeeName'
  },
  {
    title: <FormattedMessage id="table_ViewTeamCol_Official_function" />,
    dataIndex: 'employeePosition'
  },
  {
    title: <FormattedMessage id="table_ViewTeamCol_Email" />,
    dataIndex: 'email'
  },
  {
    title: <FormattedMessage id="table_ViewTeamCol_Phone_number" />,
    dataIndex: 'phoneNumber'
  },
  {
    title: <FormattedMessage id="table_ViewTeamCol_Date_assigned" />,
    dataIndex: 'dateAssigned'
  }
];
