import { Select } from 'antd';
import { TeamsService } from 'services';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import { useIntl } from 'react-intl';
import { useSafeIntl } from '../../UIComponents/SafeFormattedMessage';

const { Option } = Select;

const formActions = {
  edit: TeamsService.updateTeam,
  add: TeamsService.addTeam
};

export const onFinish = (scope, data, teamId, actions) => {
  data = teamId ? { teamId, ...data } : data;

  const getMessages =
    scope === 'edit'
      ? getSuccessMessages.success_updated_team
      : getSuccessMessages.success_added_team;

  onFormFinishWithPassedValidation(
    formActions,
    scope,
    data,
    actions,
    getMessages
  );
};

export const ParseEmployeeOptions = employees => {
  const intl = useSafeIntl();
  return employees && employees?.length
    ? employees.map(employee => {
        return (
          <Option key={employee?.employeeId} value={employee?.employeeId}>
            {employee?.firstName
              ? `${employee?.lastName} ${employee?.firstName}, ${intl.formatMessageSafely({ id: employee?.employeePosition })}`
              : `${employee?.employeeName}, ${intl.formatMessageSafely({ id: employee?.employeePosition })}`}
          </Option>
        );
      })
    : [];
};

export const parseDepartmentOptions = departments => {
  return departments.map(department => (
    <Option value={department.name}>{department.name}</Option>
  ));
};

export const parseEmployeesWithoutTLForOtherTeams = (members, teams) => {
  const uniqueLeadEmployeeIds = teams
    .map(team => team?.leadEmployeeId)
    .filter((value, index, self) => value && self.indexOf(value) === index);

  const employeesFree =
    members && Object.keys(members)
      ? members.filter(employee => {
          return !uniqueLeadEmployeeIds.includes(employee?.employeeId);
        })
      : [];

  return employeesFree;
};
