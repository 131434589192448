import { checkTargetForNewValues } from 'framer-motion';
import { TicketDto } from 'pages/Ticketing/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import methodUpdateSlice from 'redux/slices/methodUpdateSlice';
import { getTicketById } from 'redux/slices/ticketingSlice/actions';
import { AppDispatch, RootState } from 'redux/store';
import { TicketingService } from 'services';

/** Helper hook to fetch ticket ID based on URL params, else returns first ticket from fallbackData */
export default function useSelectedTicketFromURL(onError?: () => void) {
  const [URLParams] = useSearchParams();
  const ticketIdFromURL = URLParams.get('ticketId');

  const dispatch = useDispatch<AppDispatch>();
  const fallbackData = useSelector(
    (state: RootState) => state.ticketing.allTickets
  ).filter((ticket) => ticket !== undefined);

  useEffect(() => {
    if (fallbackData.length >= 1)
      dispatch(
        getTicketById(ticketIdFromURL ?? (fallbackData[0]?.ticketId as any))
      ).then(() => {
        onError && onError();
      });

    //eslint-disable-next-line
  }, [ticketIdFromURL]);
}
