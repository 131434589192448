export const setFormItemRules = ({
  isRequired = true,
  hasPattern,
  isSpecificLengthRequired,
  isMinLengthRequired,
  isMaxLengthRequired,
  requiredMessage,
  minLengthRequired,
  minLengthRequiredMessage,
  maxLengthRequired,
  maxLengthRequiredMessage,
  pattern,
  patternMessage,
  specificLengthRequiredMessage,
  requiredLength
}: {
  isRequired?: boolean;
  hasPattern?: boolean;
  isSpecificLengthRequired?: boolean;
  isMinLengthRequired?: boolean;
  isMaxLengthRequired?: boolean;
  allowOnlyNumbers?: boolean;
  requiredMessage?: string;
  pattern?: RegExp;
  patternMessage?: string;
  requiredLength?: number;
  specificLengthRequiredMessage?: string;
  minLengthRequired?: number;
  minLengthRequiredMessage?: string;
  maxLengthRequired?: number;
  maxLengthRequiredMessage?: string;
}) => {
  let rules = [];

  if (isRequired) {
    rules.push({ required: true, message: requiredMessage });
  }

  if (hasPattern) {
    rules.push({
      pattern: pattern ?? /^[a-zA-Z]*(?!.*--)(?!.* {2})[a-zA-Z -]*[a-zA-Z]$/,
      message: patternMessage
    });
  }

  if (isSpecificLengthRequired) {
    rules.push({
      len: requiredLength,
      message: specificLengthRequiredMessage
    });
  }

  if (isMinLengthRequired) {
    rules.push({ min: minLengthRequired, message: minLengthRequiredMessage });
  }

  if (isMaxLengthRequired) {
    rules.push({ max: maxLengthRequired, message: maxLengthRequiredMessage });
  }

  return rules;
};
