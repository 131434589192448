import { PlusCircleOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { ExportSvg } from 'Icons/ExportComponent';
import AddCategoryIcon from 'Icons/Inventory/AddCategoryIcon';
import ManageCategoryIcon from 'Icons/Inventory/ManageCategoryIcon';
import { HardwareListComponent } from 'Icons/Inventory/hardwareListComponent';
import { SoftwareListComponent } from 'Icons/Inventory/softwareListComponent';
import type { InputRef } from 'antd';
import { Dropdown, Menu, Space, Spin, Upload } from 'antd';
import 'components/AllUsersList/colleaguesStyles.less';
import AssignForm from 'components/AssignForm';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import AssetCategoryForm from 'components/Categories/CategoryForm';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import 'components/LayoutPageWithTitle.tsx/TopRoleModal/TopRoleModal.less';
import ModalComponent from 'components/Modal';
import {
  ModalContextProvider,
  useModalContext
} from 'components/Modal/ModalProvider';
import { TableComponentPlaceholder } from 'components/Placeholders';
import SoftwareForm from 'components/Software/SoftwareForm';
import TableComponent from 'components/Table';
import { getKeyOrTitleSelects } from 'components/TitlesColumns/utils';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAssets } from 'redux/slices/assetsSlice';
import { RootState } from 'redux/store';
import { AssetsService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { firstCharToUpperCase } from 'utils/lang/utils';
import normalizeString from 'utils/normalizeString';
import _sort from 'utils/sorter';
import { allowPermision, userRoles } from 'utils/userManagement';
import CustomSwitch from '../../../components/Switch';
import { columnsMobileSoftware } from './columnsMobileSoftware';
import { columnsSoftware } from './columnsSoftware';
import { frequencySoftware, titlesKeysofColumnsSoftware } from './utils';

type SoftwareProps = {
  formScoping?: string;
  assetId?: number | null;
  asignee?: string | null;
  formKeyId?: string | null;
  assignPerson?: object | null;
};

export const ViewSoftware: FC<SoftwareProps> = ({
  assetId,
  asignee,
  formScoping = 'add',
  formKeyId,
  assignPerson
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<InputRef>(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { theme } = useThemeContext();
  const isAllowedUserRole = allowPermision(user.role, [
    userRoles.Admin,
    userRoles.Accountant
  ]);

  const [totalRows, setTotalRows] = useState(null);

  const [status, setStatus] = useState('pending');
  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);

  const dispatch = useDispatch();

  const intl = useIntl();
  const addNewAssetText = intl.formatMessage({ id: 'AddNewAsset' });
  const addCategoryText = intl.formatMessage({ id: 'AddQuickCategory' });
  const softwateListText = intl.formatMessage({
    id: 'table_softwareTitle_Software_list'
  });
  const moreText = intl.formatMessage({ id: 'more' });
  const submitText = intl.formatMessage({ id: 'submit' });
  const cancelText = intl.formatMessage({ id: 'cancel' });
  const addText = intl.formatMessage({ id: 'add' });
  const saveText = intl.formatMessage({ id: 'save' });

  type ModalProps = {
    scope: string;
    modalTitle: string | JSX.Element;
    softwareAsigneeName: string | JSX.Element;
    softwareAsigneeType: string | JSX.Element;
    assetId: number;
    submitButtonText: string | JSX.Element;
    cancelButtonText: string | JSX.Element;
    formKeyId: string;
    assignPerson: any;
  };

  const [modalProps, setModalProps] = useState<ModalProps>({
    scope: formScoping,
    modalTitle: '',
    assetId: assetId,
    softwareAsigneeName: asignee,
    softwareAsigneeType: asignee,
    submitButtonText: submitText,
    cancelButtonText: cancelText,
    formKeyId: formKeyId,
    assignPerson: assignPerson
  });

  const {
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    isTopRoleModalOpen,
    setIsTopRoleModalOpen
  } = useModalContext();

  const params = {
    searchText,
    setSearchText,
    searchedColumn,
    setSearchedColumn,
    searchInput,
    isAllowedUserRole
  };

  window.localStorage.setItem('displayHardwareList', 'Software');

  useEffect(() => {
    const fetchAssets = () => {
      setStatus('pending');
      AssetsService.getAllAssets('true')
        .then(res => {
          const { data, error } = res;
          if (data) {
            const filtredAssets = data
              .filter(item => item.type === 'Software')
              .sort((a, b) =>
                _sort(b[defaultPresortedKey], a[defaultPresortedKey], 'string')
              );
            setData(filtredAssets);
            dispatch(setAssets(filtredAssets));
          } else {
            console.error(error);
          }
          setStatus('success');
        })
        .catch(err => {
          console.error(err);
          setStatus('error');
        });
    };

    fetchAssets();
  }, []);

  const CategoryMenu = ({ setIsOpenModal, setModalProps }) => (
    <Menu
      className={`dropdown-menu-categories ${theme}`}
      items={[
        {
          label: <FormattedMessage id="AddCategory" />,
          key: '0'
        },
        {
          label: (
            <Link to="../categories">
              <FormattedMessage id="ManageCategories" />
            </Link>
          ),
          key: '1'
        }
      ]}
      onClick={e => {
        if (e.key !== '0') {
          return;
        }
        setIsTopRoleModalOpen(false);
        setIsOpenModal(true);
        setModalProps(state => ({
          ...state,
          formKeyId: 'assetCategoryForm',
          modalTitle: <FormattedMessage id="AddQuickCategory" />,
          scope: 'add',
          cancelButtonText: cancelText,
          submitButtonText: addText
        }));
      }}
    />
  );

  interface ButtonsType {
    show?: boolean;
    className?: string;
    type?: string;
    icon?: JSX.Element;
    iconSrc?: any;
    text: ReactNode;
    action?: any;
    isUploadButton?: boolean;
    isMultipleUpload?: boolean;
    uploadAccept?: string;
    uploadMaxCount?: number;
    uploadInProgress?: boolean;
    uploadShowList?: boolean;
    beforeUploadAction?: any;
    isDropdown?: boolean;
    dropdownOverlay?: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >;
  }

  const topRoleButtons: ButtonsType[] = [
    {
      icon: <PlusCircleOutlined />,
      text: (
        <span>
          <FormattedMessage id="addItem" />
        </span>
      ),
      action: () => {
        toggleModal();
        setIsTopRoleModalOpen(false);
        setModalProps(state => ({
          ...state,
          scope: 'add',
          assetId: null,
          modalTitle: addNewAssetText,
          cancelButtonText: cancelText,
          submitButtonText: addText,
          formKeyId: 'AssetSoftwareForm'
        }));
      },
      show: isAllowedUserRole
    },
    {
      icon: <ExportSvg />,
      text: (
        <span>
          <FormattedMessage id="export" />
        </span>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
      },
      show: isAllowedUserRole
    },
    {
      icon: (
        <AddCategoryIcon stroke={`${theme === 'dark' ? '#fff' : '#402A87'}`} />
      ),
      text: (
        <span>
          <FormattedMessage id="AddCategory" />
        </span>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
        setIsModalOpen(true);
        setModalProps(state => ({
          ...state,
          formKeyId: 'assetCategoryForm',
          modalTitle: firstCharToUpperCase(addCategoryText),
          scope: 'add',
          cancelButtonText: cancelText,
          submitButtonText: addText
        }));
      },
      show: isAllowedUserRole
    },
    {
      icon: (
        <ManageCategoryIcon
          stroke={`${theme === 'dark' ? '#fff' : '#402A87'}`}
        />
      ),
      text: (
        <Link className={`categories-link ${theme}`} to="../categories">
          <FormattedMessage id="ManageCategories" />
        </Link>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
      },
      show: isAllowedUserRole
    }
  ];

  // const results =
  //   data.length !== 0 ? data.filter((item) => item.type === 'Software') : [];
  const ButtonComponent = BaseButton(ButtonSimple);

  const localStorageListInventaory = window.localStorage.getItem(
    'displayHardwareList'
  );

  const defaultPresortedKey = 'name';
  const [dataSource, setDataSource] = useState([]);

  const assetsStore = useSelector((state: RootState) => state.assets);
  useEffect(() => {
    setResults(assetsStore);
    setDataSource(assetsStore);
  }, [assetsStore]);

  useEffect(() => {
    if (localStorageListInventaory != null) {
      localStorageListInventaory === 'Hardware'
        ? navigate('/inventory/hardware')
        : navigate('/inventory/software');
    } else {
      window.localStorage.setItem('displayHardwareList', 'Hardware');
      navigate('/inventory/hardware');
    }
  }, [localStorageListInventaory]);

  const handleSearchToolbar = (value, columnSearch) => {
    setSearchText(value);
    const currValue = value;
    const colToBeSearched =
      titlesKeysofColumnsSoftware.filter(
        item =>
          intl.formatMessage({ id: item.title.props.id ?? null }) ===
          columnSearch
      )[0] || null;
    const currKey = colToBeSearched?.dataIndex ?? '';
    setSearchedColumn(currKey);

    results
      .filter(({ frequency }) => {
        frequencySoftware.filter(
          ({ dataIndex }) =>
            intl.formatMessage({ id: frequency }) ===
            intl.formatMessage({ id: dataIndex })
        );
      })
      .forEach(
        item =>
          (item.frequency = getKeyOrTitleSelects(
            intl.formatMessage({ id: item.frequency }),
            'frequencySoft'
          ))
      );

    const filteredData = results.filter(entry => {
      if (currValue === '') {
        // when current value on search is initial is empty
        return entry;
      }
      if (
        entry[currKey] === null ||
        entry[currKey] === undefined ||
        (entry[currKey] instanceof String && entry[currKey]?.trim() === '')
      ) {
        // when values from api is empty | null | undefined
        return false;
      }

      if (typeof entry[currKey] === 'object') {
        return Object.values(entry[currKey]).filter((e: string) =>
          normalizeString(e).startsWith(normalizeString(currValue))
        ).length;
      } else if (currKey === 'frequency') {
        return normalizeString(
          intl.formatMessage({ id: entry[currKey] })
        ).includes(normalizeString(currValue));
      } else if (currKey === 'price') {
        return normalizeString(
          `${String(entry[currKey])}${entry.currency}`
        ).includes(normalizeString(currValue.replaceAll(' ', '')));
      } else {
        return normalizeString(String(entry[currKey])).includes(
          normalizeString(currValue)
        );
      }
    });
    setDataSource(
      [...filteredData].sort((a, b) =>
        _sort(b[defaultPresortedKey], a[defaultPresortedKey], 'string')
      )
    );
  };
  const { isMobile, deviceHeight, isTabletDevice, deviceOS } =
    useMobileContext();

  enum ToolbarSearchOptions {
    Model = 'Model',
    Category = 'Category',
    Details = 'Details',
    Frequency = 'Frequency',
    Assignee = 'Assignee',
    Price = 'Price'
  }

  const SearchOptions = !isAllowedUserRole
    ? (({ Price, ...otherOptions }) => otherOptions)(ToolbarSearchOptions)
    : ToolbarSearchOptions;

  const editAssetModalTitle = intl.formatMessage({ id: 'EditAsset' });
  //['Name', 'Details', 'Frequency', 'Assignee']
  return (
    <>
      <ModalComponent
        alignSelf="middle"
        className="inventory-modal"
        isMaskClosable={
          !(modalProps.scope === 'edit' || modalProps.scope === 'add')
        }
        title={modalProps.modalTitle}
        formKeyId={modalProps.formKeyId}
        submitBtnText={modalProps.submitButtonText}
        children={
          modalProps.formKeyId === 'AssetSoftwareForm' ? (
            <SoftwareForm
              scope={modalProps.scope}
              assetId={modalProps.assetId}
              assignPerson={modalProps.assignPerson}
            />
          ) : modalProps.formKeyId === 'assetCategoryForm' ? (
            <AssetCategoryForm
              scope={modalProps.scope}
              categoryType="Software"
            />
          ) : (
            <AssignForm
              scope={modalProps.scope}
              assetId={modalProps.assetId}
              assignPerson={modalProps.assignPerson}
            />
          )
        }
        cancelBtnText={
          modalProps.scope === 'view' ? (
            <FormattedMessage id="close" />
          ) : (
            modalProps.cancelButtonText
          )
        }
        displayFooterSubmitButton={!(modalProps.scope === 'view')}
        editButton={
          modalProps.scope === 'view' &&
          allowPermision(user.role, [
            userRoles.Admin,
            userRoles.Accountant
          ]) ? (
            <ButtonComponent
              className={`text-bold-normal btn-primary-custom ${theme}`}
              key="editHardwareViewButton"
              type="primary"
              onClick={e => {
                setModalProps(state => ({
                  ...state,
                  modalTitle: editAssetModalTitle,
                  className: 'mobile-modal',
                  scope: 'edit',
                  cancelButtonText: cancelText,
                  submitButtonText: saveText
                }));
              }}
            >
              <FormattedMessage id="edit" />
            </ButtonComponent>
          ) : null
        }
        footerButtons={
          isMobile ? (
            modalProps.scope === 'view' &&
            allowPermision(user.role, [
              userRoles.Admin,
              userRoles.Accountant
            ]) ? (
              <>
                {modalProps.softwareAsigneeName &&
                modalProps.softwareAsigneeType !== 'None' ? (
                  <div className="inventory_footer_buttons ">
                    <ButtonComponent
                      className={`text-bold-normal btn-default-custom  ${theme}`}
                      key="unnassignAssetButton"
                      style={{ maxWidth: '100%' }}
                      onClick={() => {
                        setModalProps(state => ({
                          ...state,
                          scope: 'deassignAsset',
                          modalTitle: <FormattedMessage id="UnassignAsset" />,
                          submitButtonText: (
                            <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                          ),
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm',
                          assignPerson: modalProps.softwareAsigneeName
                        }));
                      }}
                    >
                      <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                    </ButtonComponent>
                  </div>
                ) : (
                  <div className="inventory_footer_buttons ">
                    <ButtonComponent
                      className={`text-bold-normal btn-default-custom  ${theme}`}
                      key="editHardwareViewButton"
                      style={{ maxWidth: '100%' }}
                      onClick={() => {
                        setModalProps(state => ({
                          ...state,
                          scope: 'assignColleague',
                          assetId: modalProps.assetId,
                          modalTitle: (
                            <FormattedMessage id="AssignToAColleague" />
                          ),
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                      }}
                    >
                      <FormattedMessage id="table_SoftwHardwBtn_colleague_assign" />
                    </ButtonComponent>

                    <ButtonComponent
                      className={`text-bold-normal btn-default-custom ant-dropdown-trigger  ${theme}`}
                      style={{ maxWidth: '100%' }}
                      onClick={() => {
                        setModalProps(state => ({
                          ...state,
                          scope: 'assignTeam',
                          assetId: modalProps.assetId,
                          className: 'mobile-modal',
                          modalTitle: <FormattedMessage id="AssignToATeam" />,
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                      }}
                    >
                      <FormattedMessage id="table_SoftwHardwBtn_team_assign" />
                    </ButtonComponent>
                  </div>
                )}
              </>
            ) : null
          ) : null
        }
      />
      <SearchToolbarComponent
        displaySearchIcon={false}
        initialSelected={intl.formatMessage({
          id: `inventorySoftware_toolbarFilter_${ToolbarSearchOptions.Model}`
        })}
        searchOptions={Object.keys(SearchOptions).map(value => ({
          value: value as keyof typeof SearchOptions,
          label: intl.formatMessage({
            id: `inventorySoftware_toolbarFilter_${value}`
          })
        }))}
        buttons={[
          {
            icon: <PlusCircleOutlined />,
            text: (
              <span>
                <FormattedMessage id="addItem" />
              </span>
            ),
            action: () => {
              setModalProps(state => ({
                ...state,
                scope: 'add',
                assetId: null,
                modalTitle: addNewAssetText,
                cancelButtonText: cancelText,
                submitButtonText: addText,
                formKeyId: 'AssetSoftwareForm'
              }));
              toggleModal();
            },
            show: isAllowedUserRole
          },
          {
            icon: <ExportSvg />,
            text: (
              <span>
                <FormattedMessage id="export" />
              </span>
            ),
            show: isAllowedUserRole
          },
          {
            text: moreText,
            isDropdown: true,
            dropdownOverlay: (
              <CategoryMenu
                setIsOpenModal={toggleModal}
                setModalProps={setModalProps}
                // role={role}
              />
            ),
            show: isAllowedUserRole
          }
        ]}
        displaySearchInput={true}
        onChangeInputSearch={handleSearchToolbar}
      />
      <br />
      <TableLayoutPage
        style={{
          height: `calc(${deviceHeight}px - 185px)`
        }}
        displayToggle={!isAllowedUserRole || !isMobile}
        displayButton={isAllowedUserRole}
        title={`${softwateListText} (${totalRows ?? 0})`}
        childrenButton={
          <CustomSwitch
            values={[
              {
                id: 0,
                value: 'Software',
                icon: <SoftwareListComponent />
              },
              {
                id: 1,
                value: 'Hardware',
                icon: <HardwareListComponent />
              }
            ]}
            defaultValue={'Software'}
            onChange={() => {
              localStorage.setItem('displayHardwareList', 'hardware');
              navigate('/inventory/hardware');
            }}
          />
        }
      >
        {isMobile ? (
          <TableComponent
            style={{
              minHeight: `calc(${deviceHeight}px - 22em)`
            }}
            scroll={
              !isMobile
                ? { x: 'calc(100vw - 220px)', y: 'calc(100vh - 42em)' }
                : {
                    x: 'calc(100vw - 213px)',
                    y: `calc(${deviceHeight}px - 24.1em)`
                  }
            }
            rowKey="assetId"
            role={user.role}
            columns={columnsMobileSoftware}
            params={params}
            dataSource={dataSource}
            data={dataSource}
            rowClassName={isAllowedUserRole ? 'hoverRow' : ''}
            isModalOpen={isModalOpen}
            className="softwareTable"
            setIsModalOpen={() => {
              if (!isModalOpen) {
                toggleModal();
              }
            }}
            hookUseStateActions={{
              setModalProps
            }}
            setTotalRows={setTotalRows}
          />
        ) : (
          <ComponentWithStatus
            status={status}
            Placeholder={<TableComponentPlaceholder count={6} />}
          >
            <TableComponent
              scroll={{
                x: 'calc(100vw - 213px)',
                y: 'calc(100vh - 332px)'
              }}
              style={{
                height: 'calc(100vh - 24.5em)',
                minHeight: 'calc(100vh - 24.5em)'
              }}
              rowKey="assetId"
              role={user.role}
              columns={columnsSoftware}
              params={params}
              dataSource={dataSource}
              data={dataSource}
              rowClassName={isAllowedUserRole ? 'hoverRow' : ''}
              isModalOpen={isModalOpen}
              setIsModalOpen={() => {
                if (!isModalOpen) {
                  toggleModal();
                }
              }}
              hookUseStateActions={{
                setModalProps
              }}
              className="softwareTable"
              setTotalRows={setTotalRows}
            />
          </ComponentWithStatus>
        )}
      </TableLayoutPage>
      <ModalContextProvider>
        <ModalComponent
          maskStyle={{ opacity: 0 }}
          className="top-role-popup"
          isOpen={isTopRoleModalOpen}
          setIsOpen={setIsTopRoleModalOpen}
          footerButtons={false}
          displayFooterCloseButton={false}
          displayFooterSubmitButton={false}
          maskClosable={true}
          width={254}
        >
          <section className="top-role-popup-buttons">
            <button style={{ background: 'none', border: 'none' }}>
              <CustomSwitch
                onChange={() => {
                  localStorage.setItem(
                    'displayHardwareList',
                    `${
                      window.localStorage.getItem('displayHardwareList') ===
                      'Hardware'
                        ? 'Software'
                        : 'Hardware'
                    }`
                  );
                  navigate('/inventory/hardware');
                  setIsTopRoleModalOpen(false);
                }}
                values={[
                  {
                    id: 0,
                    value: 'Software',
                    icon: <SoftwareListComponent />
                  },
                  {
                    id: 1,
                    value: 'Hardware',
                    icon: <HardwareListComponent />
                  }
                ]}
                defaultValue={
                  localStorage.getItem('displayHardwareList') &&
                  localStorage.getItem('displayHardwareList') === 'Hardware'
                    ? 'Hardware'
                    : 'Software'
                }
              />
            </button>

            {topRoleButtons.map((button, index) =>
              button.show ? (
                button.isUploadButton ? (
                  <Upload
                    key="upload"
                    multiple={button.isMultipleUpload ?? false}
                    beforeUpload={button.beforeUploadAction}
                    accept={button.uploadAccept}
                    maxCount={button.uploadMaxCount}
                    showUploadList={button.uploadShowList}
                  >
                    <ButtonComponent
                      className={`text-bold-normal btn-default-custom ${theme}`}
                      icon={button.icon}
                    >
                      {button.text}
                    </ButtonComponent>
                    {button.uploadInProgress && (
                      <Spin size="small" className={'uploader-spinner'} />
                    )}
                  </Upload>
                ) : button.isDropdown ? (
                  <Dropdown
                    overlayStyle={{ fontWeight: 500 }}
                    overlay={button.dropdownOverlay}
                    trigger={['click']}
                    key={index}
                  >
                    <Space>
                      <ButtonComponent
                        className={`text-bold-normal btn-default-custom ${theme}`}
                        key={index}
                      >
                        {button.text}
                      </ButtonComponent>
                    </Space>
                  </Dropdown>
                ) : (
                  <ButtonComponent
                    key={`button-component-${index}`}
                    type={button.type}
                    icon={
                      button.icon ? (
                        button.icon
                      ) : button.iconSrc ? (
                        <embed src={button.iconSrc} />
                      ) : null
                    }
                    className={
                      button.className
                        ? button.className
                        : `text-bold-normal btn-default-custom ${theme}`
                    }
                    style={{ margin: '0' }}
                    onClick={button.action}
                  >
                    {button.text}
                  </ButtonComponent>
                )
              ) : null
            )}
          </section>
        </ModalComponent>
      </ModalContextProvider>
    </>
  );
};

export default ViewSoftware;
