import { PlusOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import ModalComponent from 'components/Modal';
import { MultipleDropDownFilter } from 'components/UIComponents/MultipleDropDownFilter';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import SingleSelectDropdown from 'components/UIComponents/SingleSelectDropdown';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCalendarModal } from './CalendarModalContext';
import EventsCalendarPage from './CalendarPage';
import './calendar.less';
import { EventTypes, EventsViewType, ViewPeriodMode } from './types';

export const CalendarPage = () => {
  // Context
  const intl = useIntl();
  const { theme } = useThemeContext();
  const { isMobile, isTabletDevice, deviceHeight } = useMobileContext();
  const { calendarModalProps, openFormCalendarModal } = useCalendarModal();

  // State
  const [{ eventViewType, eventType, viewPeriodMode }, setSelectedOptions] =
    useState<any>({
      eventViewType: EventsViewType.Company,
      eventType: [...Object.keys(EventTypes)],
      viewPeriodMode: ViewPeriodMode.DayGridMonth
    });

  // Labels
  const eventCalendarLabel = intl.formatMessage({ id: 'EventsCalendar' });
  const datePeriodLabel = intl.formatMessage({ id: 'DatePeriod' });

  // Components
  const ButtonComponent = BaseButton(ButtonSimple);

  // Render
  return (
    <>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        buttons={[
          {
            text: intl.formatMessage({ id: 'createEvent' }),
            action: () => {
              openFormCalendarModal('create');
            },
            show: true
          }
        ]}
        children={
          <div className="calendar-toolbar search-toolbar_filters_container">
            <SingleSelectDropdown
              dropdownAlign={{ offset: [53, -14] }}
              label={`${eventCalendarLabel}: ${intl.formatMessage({
                id: eventViewType
              })}`}
              value={eventViewType}
              theme={theme}
              options={Object.keys(EventsViewType).map(value => ({
                value: value,
                label: intl.formatMessage({ id: value })
              }))}
              onChange={value => {
                setSelectedOptions(state => ({
                  ...state,
                  eventViewType: value
                }));
              }}
            />

            <SingleSelectDropdown
              dropdownAlign={{ offset: [53, 0] }}
              label={`${datePeriodLabel}: ${intl.formatMessage({
                id: viewPeriodMode
              })}`}
              value={viewPeriodMode}
              theme={theme}
              options={Object.values(ViewPeriodMode).map(value => ({
                value: value,
                label: intl.formatMessage({ id: value })
              }))}
              onChange={value => {
                setSelectedOptions(state => ({
                  ...state,
                  viewPeriodMode: value
                }));
              }}
            />

            <MultipleDropDownFilter
              placeholder={intl.formatMessage({ id: 'Categories' })}
              dropdownAlign={{ offset: [53, null] }}
              dataForOptions={EventTypes}
              value={eventType}
              preventDeselectAll={true}
              onChangeFunction={selected => {
                setSelectedOptions(state =>
                  //prevent deselecting all options, keep at least one
                  state.eventType.length === 1 && selected.length === 0
                    ? state
                    : {
                        ...state,
                        eventType: [...selected]
                      }
                );
              }}
              instanceId="Categories"
            />
          </div>
        }
      />
      <br />
      <TableLayoutPage
        renderCustomButton={
          isMobile && (
            <ButtonComponent
              className={`text-bold-normal btn-default-custom mobile-create-btn ${theme}`}
              icon={<PlusOutlined />}
              type="primary"
              style={{
                position: 'absolute',
                top: 16,
                left: 'calc(100% - 1.5em)',
                fontSize: '1em',
                zIndex: '999999'
              }}
              onClick={() => {
                openFormCalendarModal('create');
              }}
            ></ButtonComponent>
          )
        }
        style={{
          height: `calc(${deviceHeight}px - 185px)`,
          overflow: 'hidden',
          paddingTop: isMobile && '0px'
        }}
      >
        <ModalComponent {...calendarModalProps} />
        <EventsCalendarPage {...{ viewPeriodMode, eventType, eventViewType }} />
      </TableLayoutPage>
    </>
  );
};
