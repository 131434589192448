import { useEffect, useState } from 'react';
import { Employee } from 'pages/Ticketing/types';
import { TicketingService } from 'services';

const useAssigneeEmployeesInSameWorkQueue = (ticketId: number) => {
  const [assigneeEmployeesInWorkQueue, setAssigneeEmployeesInWorkQueue] =
    useState<Employee[]>([]);

  useEffect(() => {
    ticketId &&
      TicketingService.getAllowedAssignees({
        ticketId: ticketId,
      }).then((res) => {
        setAssigneeEmployeesInWorkQueue(res.data);
      }).catch(() => {setAssigneeEmployeesInWorkQueue([])});
  }, [ticketId]);

  return assigneeEmployeesInWorkQueue;
};
export default useAssigneeEmployeesInSameWorkQueue;
