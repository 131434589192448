import { CompanySettingsService } from '../../../services';
import { notifyWithSuccessfulDataUpdated } from '../../../utils/notificationsUtils';
import { getSuccessMessages } from '../../../utils/getSuccessMessages';
export const onFormFinish = (val) => {
  const transformedValues = {
    appConfiguration: {
      allowSameTeamLeadForMultipleTeams: val.allowSameTeamLeadForMultipleTeams,
      country: val.country,
      forbidOffsetForFutureHours: !val.forbidOffsetForFutureHours,
      leaveOffsetCustomPeriodReminderDays: val.leaveOffsetCustomPeriodReminderDays,
      limitMaximumAmountOfOffsetInAdvance: !val.limitMaximumAmountOfOffsetInAdvance,
      limitMaximumAmountOfPersonalLeave: !val.limitMaximumAmountOfPersonalLeave,
      limitOffsetStartInterval: val.limitOffsetStartInterval,
      maxEventRecurrenceRangeInDays: val.maxEventRecurrenceRangeInDays,
      maximumAmountOfOffsetInAdvanceHours: val.maximumAmountOfOffsetInAdvanceHours,
      maximumAmountOfPersonalLeaveHours: val.maximumAmountOfPersonalLeaveHours,
      minimumDurationOfPersonalLeaveInMinutes: val.minimumDurationOfPersonalLeaveInMinutes,
      offsetStartInterval: val.offsetStartInterval
        ? val.offsetStartInterval.map(time => time.format('HH:mm')).join('-')
        : null,
      offsettingStructure: val.offsettingStructure,
      offsettingValue: val.offsettingValue,
      reccuringJobsConfiguration: val.reccuringJobsConfiguration,
      slaTimeFrameDueDateDays: val.slaTimeFrameDueDateDays,
      slaTimeFrameRequesterDays: val.slaTimeFrameRequesterDays,
      timeZoneId: val.timeZoneId
    },
    countryCode: val.countryCode,
    email: val.email,
    emailProvider: val.emailProvider,
    emailType: val.emailType,
    name: val.name,
    office: val.office,
    phoneCode: val.phoneCode,
    phoneCodeCount: val.phoneCodeCount,
    phoneCodeCountry: val.phoneCodeCountry,
    phoneCodeDigit: val.phoneCodeDigit,
    phoneCount: val.phoneCount,
  };

  CompanySettingsService.updateCompanySettingsExtended(transformedValues)
    .then(() => {
      notifyWithSuccessfulDataUpdated('', getSuccessMessages.success_updated_platform_settings);
    })
}