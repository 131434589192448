import { Avatar, Select, Tooltip, Typography } from 'antd';
import styles from './personSelector.module.less';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { useEffectWithSkipFirstMount } from 'hooks/useEffectWithSkipFirstMount';
import { Employee } from 'pages/Ticketing/types';
import UserAvatar from 'components/Avatar';
import { SelectDropdown } from '../../../../../../../components/Dropdown';
import avatar from 'assets/avatar.png';

const PersonSelector = ({
  data = [],
  avatarSrc,
  employee,
  onSelect = null //if null, it displays only the default children element
}) => {
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);

  const intl = useIntl();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>(null);

  useEffect(() => {
    setSelectedEmployeeId(employee?.employeeId);
  }, [employee?.employeeId]);

  const { theme } = useThemeContext();

  const EmployeeSelectOptions = data?.map((employee: Employee) => {
    return {
      value: employee?.employeeId,
      label: (
        // <Tooltip title={employee?.lastName + ' ' + employee?.firstName}>
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <UserAvatar src={employee?.externalAvatarUrl ?? avatar} size={26} />
          <Typography.Text
            ellipsis
            style={{ fontWeight: 700, lineHeight: 'unset' }}
          >
            {employee?.lastName} {employee?.firstName}
          </Typography.Text>
        </div>
        // </Tooltip>
      )
    };
  });

  useEffectWithSkipFirstMount(
    () => {
      onSelect && onSelect(selectedEmployeeId);
    },
    [selectedEmployeeId],
    true
  );

  return (
    <div
      className={`${styles.wrap} ${onSelect ? styles.selectable : ''} ${theme}`}
    >
      <Avatar style={{ width: 26, height: 26 }} src={avatarSrc} />
      <SelectDropdown
        onSelect={option => {
          setSelectedEmployeeId(option);
          setIsSelectMode(true);
        }}
        onDropdownVisibleChange={() => {
          setIsSelectMode(prev => !prev);
        }}
        defaultValue={
          employee ? employee.lastName + ' ' + employee.firstName : undefined
        }
        showArrow={false}
        style={{
          width: 'calc(100% - 32px)',
          flexGrow: '1'
        }}
        allowClear={false}
        disabled={!onSelect}
        options={EmployeeSelectOptions}
        listHeight={130}
      />
      <>
        <Typography.Text>
          {employee
            ? employee?.lastName + ' ' + employee?.firstName
            : intl.formatMessage({ id: 'Unassigned' })}
        </Typography.Text>
      </>

      {onSelect && (
        <Typography style={{ marginLeft: 'auto !important' }}>
          <ArrowLeft
            className={
              isSelectMode ? styles.arrow__active : styles.arrow__simple
            }
          />
        </Typography>
      )}
    </div>
  );
};
export default PersonSelector;
