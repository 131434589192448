import * as React from 'react';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import PlaceholderWrapper from '../UI/PlaceholderWrapper';

export const MobileCalendarWidgetPlaceholder: React.FC = (props) => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      height={`${deviceHeight - 90 - 32 - 32}px`}
      width={`${deviceWidth - 32 - 52}px`}
      {...props}
    >
      <rect x="0" y="0" ry="20" rx="20" width="100%" height="50%" />
      <rect x="35%" y="60%" ry="5" rx="5" width="30%" height="5%" />
      <rect x="0%" y="70%" ry="0" rx="0" width="1%" height="10%" />
      <rect x="0%" y="85%" ry="0" rx="0" width="1%" height="10%" />
      <rect x="3%" y="70%" ry="0" rx="0" width="90%" height="10%" />
      <rect x="3%" y="85%" ry="0" rx="0" width="90%" height="10%" />
    </PlaceholderWrapper>
  );
};

export const MobilePagePlaceholder: React.FC = (props) => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      viewBox={`0 0 ${deviceWidth - 32} ${deviceHeight - 90 - 32}`}
      height={`${deviceHeight - 90 - 32}px`}
      width={`${deviceWidth - 32}px`}
      {...props}
    >
      <rect x="0" y="0" ry="20" rx="20" width="100%" height="100%" />
    </PlaceholderWrapper>
  );
};

export const MobileTimeManagementPlaceholder: React.FC = (props) => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      height={`${deviceHeight - 90 - 32 - 32}px`}
      width={`${deviceWidth - 32}px`}
      {...props}
    >
      <rect x="30%" y="5%" ry="5" rx="5" width="40%" height="5%" />
      <rect x="20%" y="12%" ry="5" rx="5" width="60%" height="3%" />


      <rect x="25%" y="35%" ry="5" rx="5" width="50%" height="5%" />
      <circle cx="50%" cy="50%" r="10%" />
      <rect x="5%" y="60%" ry="5" rx="5" width="90%" height="20%" />

      <rect x="5%" y="85%" ry="10" rx="10" width="90%" height="5%" />
      <circle cx="50%" cy="95%" r="1%" />
      <circle cx="45%" cy="95%" r="1%" />
      <circle cx="55%" cy="95%" r="1%" />
    </PlaceholderWrapper>
  );
};

export const MobileEditTeamPlaceholder: React.FC = (props) => {
  const { deviceWidth, deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      height={`${deviceHeight - 90 - 32}px`}
      width={`${deviceWidth - 32}px`}
      {...props}
    >
      <rect x="0" y="0" ry="20" rx="20" width="100%" height="10%" />
      <rect x="0" y="12%" ry="20" rx="20" width="100%" height="88%" />
    </PlaceholderWrapper>
  );
};


export const TicketingPlaceholderMobile: React.FC = props => {
  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {/* <rect x="36%" y="1%" ry="5" rx="5" width="30%" height="20" /> */}
      <circle cx="50%" cy="23%" r="15%" />
      <rect x="38%" y="38%" ry="5" rx="5" width="25%" height="20" />
      <rect x="4%" y="55%" ry="5" rx="5" width="90%" height="25" />

      <rect x="4%" y="64%" ry="5" rx="5" width="90%" height="15" />
      <rect x="4%" y="69%" ry="5" rx="5" width="90%" height="15" />
      <rect x="4%" y="74%" ry="5" rx="5" width="90%" height="15" />
      <rect x="4%" y="79%" ry="5" rx="5" width="90%" height="15" />
      <rect x="40%" y="86%" ry="5" rx="5" width="80" height="15" />
      
    </PlaceholderWrapper>
  );
};