export const ROFlagComponent = () => {
  return (
    <span role="img" aria-label="addItem" className="anticon flag-icon">
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.5H6.66563V15.5H0V0.5Z"
          fill="#00319C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66577 0.5H13.3345V15.5H6.66577V0.5Z"
          fill="#FFDE00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3345 0.5H20.0001V15.5H13.3345V0.5Z"
          fill="#DE2110"
        />
      </svg>
    </span>
  );
};
