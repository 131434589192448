import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AssignmentTypes,
  TicketPriority,
  TicketType,
  TicketStatus
} from '../../../pages/Ticketing/types';
import { useLocation } from 'react-router-dom';

type Nullable<T> = T | null;
type SingleOrArray<T> = T | T[];

type TicketingFiltersStore = {
  assignmentType: Nullable<AssignmentTypes>;
  urgencyTypes: SingleOrArray<Nullable<TicketPriority>>;
  categoriesTypes: SingleOrArray<Nullable<TicketType>>;
  statusTypes: SingleOrArray<Nullable<TicketStatus>>;
  paginationSize?: number;
};

const initialState: TicketingFiltersStore = {
  assignmentType: null,
  urgencyTypes: null,
  categoriesTypes: null,
  statusTypes: null,
  paginationSize:
    JSON.parse(localStorage.getItem('tablePagination'))?.['ticketingTable'] ||
    20
};

const ticketingFiltersSlice = createSlice({
  name: 'ticketingFilters',
  initialState,
  reducers: {
    setAssignmentType(state, action: PayloadAction<AssignmentTypes>) {
      state.assignmentType = action.payload;
    },
    setUrgencyTypes(state, action: PayloadAction<TicketPriority>) {
      return { ...state, urgencyTypes: action.payload };
    },
    setCategoriesTypes(state, action: PayloadAction<TicketType>) {
      state.categoriesTypes = action.payload;
    },
    setStatusTypes(state, action: PayloadAction<TicketStatus>) {
      return { ...state, statusTypes: action.payload };
    },
    setPaginationSize(state, action: PayloadAction<number>) {
      state.paginationSize = action.payload;
    },
    resetTicketingFilters: () => initialState
  }
});

export const {
  setAssignmentType,
  setCategoriesTypes,
  setStatusTypes,
  setUrgencyTypes,
  resetTicketingFilters,
  setPaginationSize
} = ticketingFiltersSlice.actions;
export default ticketingFiltersSlice.reducer;
