import { TimeSheetService } from 'services';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import dayjs from 'dayjs';
import { getSuccessMessages } from 'utils/getSuccessMessages';

export const onFinish = (scope, data, actions) => {
  const onFinishActions = {
    resolve: TimeSheetService.solveConflict,
  };

  onFormFinishWithPassedValidation(
    onFinishActions,
    scope,
    data,
    actions,
    getSuccessMessages.success_conflict_resolved
  );
};

export const onSubmit = (props) => {
  const { results, employeeId, onFinishFormActions } = props;
  const { timeclockedHour, timeclockedMinutes, dateOnly, ...sendData } =
    results;

  const formattedDateOnly = dayjs(dateOnly, 'MMMM D, YYYY')
    .locale('en')
    .format('MMMM D, YYYY');
  onFinish(
    'resolve',
    {
      clockInOutDate: formattedDateOnly
        ? dayjs(new Date(formattedDateOnly)).format('YYYY-MM-DDTHH:mm:ss[Z]')
        : null,
      employeeId: employeeId,
      timeLoggedByEmployee: dayjs(
        (timeclockedHour ? timeclockedHour.toString() : '00') +
          ':' +
          (timeclockedMinutes ? timeclockedMinutes.toString() : '00') +
          ':00',
        'HH:mm:ss'
      )
        .format('HH:mm:ss')
        .toString(),

      ...sendData,
    },
    onFinishFormActions ?? {}
  );
};

export const parseHourMinutes = (aproxHours: string): string => {
  if (aproxHours && !aproxHours.includes('.')) {
    return `${aproxHours}h`;
  }

  if (aproxHours && aproxHours.includes('.')) {
    let hoursSeparate = aproxHours?.toString().split('.');
    let hour = hoursSeparate[0] ?? 0;
    let minute = hoursSeparate[1] ?? 0;
    return `${hour}h ${minute}m`;
  }

  return '0h';
};

export const isEventTimeOffRequestBased = (
  eventType: string,
  eventDescription: string,
  requestId: number | string
): boolean =>
  requestId !== -1 &&
  ![
    'RevokedDeniedWorkFromHome',
    'TerminatedContract', 
    'EmployeeNotEmployed',
    'Trip',
    'SuspendedContract',
    'TechnicalUnemployment',
    'Stationary',
    'Detachment',
    'TeamEvent',
    'CompanyEvent',
    'None',
  ].includes(eventType) &&
  !['EmployeeNoExit', 'EmployeeNoEntry'].includes(eventDescription);
