import { Button, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { getColumnSearchProps } from 'components/SearchTable';
import { getTitleColumns } from 'components/TitlesColumns/utils';
import { FormattedMessage } from 'react-intl';
import _sort from 'utils/sorter';
import { allowPermision, rolesAllow, userRoles } from 'utils/userManagement';
import {
  getFilterCustomForStatus,
  getFilters,
  getFiltersWithSearch
} from '../utils';
import { titlesKeysofColumnsSoftware } from './utils';

import ThemedIcon from 'components/UIComponents/Icon';
import { ColleagueAssignIcon } from 'Icons/Inventory/ColleagueAssignIcon';
import { TeamAssignIcon } from 'Icons/Inventory/TeamAssignIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { MinusCircleContained } from 'Icons/Inventory/MinusCircleContained';

const { Text } = Typography;

const getAssigneePerson = data => {
  const assignee =
    data && data.length
      ? data.reduce((initialValue, currentValue) => {
          if (
            currentValue.activeAssignment === true &&
            currentValue.assignType !== 'None'
          ) {
            return [currentValue];
          }
          return initialValue;
        }, {})
      : '';

  return assignee;
};

export const columnsSoftware = (
  data,
  role = null,
  params,
  isOpen,
  setIsOpen,
  stateActions,
  dataSource
): ColumnsType<any> => {
  const tableColumnsSoftware = [
    {
      title: getTitleColumns('model', 'software'),
      dataIndex: 'model',
      key: 'model',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.model, b.model, 'string'),
      filterSearch: true,
      ...getColumnSearchProps('model', params),
      render: (_, record, index) =>
        record.assetId ? (
          <Button
            type="link"
            className="full-content-width-button"
            key={`view-hardware-btn-${index}`}
            onClick={() => {
              stateActions?.setModalProps(state => ({
                ...state,
                scope: 'view',
                assetId: parseInt(record?.assetId ?? index + 1),
                modalTitle: <FormattedMessage id="ViewAsset" />,
                cancelButtonText: <FormattedMessage id="cancel" />,
                formKeyId: 'AssetSoftwareForm'
              }));
              setIsOpen();
            }}
          >
            <Tooltip placement="top" title={record.model}>
              <Text underline className="text-medium-big ellipsis-cell-text">
                {record.model}
              </Text>
            </Tooltip>
          </Button>
        ) : (
          <Button
            type="link"
            className="full-content-width-button cursor-text-button"
          >
            <Tooltip placement="top" title={record.model}>
              <Text className="text-medium-big ellipsis-cell-text">
                {record.model}
              </Text>
            </Tooltip>
          </Button>
        )
    },
    {
      title: getTitleColumns('categoryName', 'software'),
      dataIndex: 'categoryName',
      key: 'categoryName',
      filters: getFilters(data, 'categoryName'),
      onFilter: (value: string, record) => {
        if (value === 'blank') {
          return record?.categoryName === null;
        }
        return record?.categoryName?.startsWith(value) || false;
      },
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.categoryName, b.categoryName, 'string'),
      render: (_, record) => (
        <Tooltip placement="top" title={record?.categoryName}>
          <p className={'line-clamp-1'}>{record.categoryName}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('details', 'software'),
      dataIndex: 'details',
      key: 'details',
      onFilter: (value: string, record) => record.details.indexOf(value) === 0,
      render: (_, record) => (
        <Tooltip placement="top" title={record.details}>
          <p className="line-clamp-1">{record.details}</p>
        </Tooltip>
      )
    },
    {
      title: getTitleColumns('frequency', 'software'),
      dataIndex: 'frequency',
      key: 'frequency',
      filters: getFilterCustomForStatus(data, 'frequency', 'frequencySoft'),
      onFilter: (value: string, record) =>
        record.frequency.indexOf(value) === 0,
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.frequency, b.frequency, 'string'),
      render: (_, record) => (
        <div className={'line-clamp-1'}>
          <FormattedMessage id={record.frequency} />
        </div>
      )
    },
    {
      title: getTitleColumns('price', 'software'),
      dataIndex: 'price',
      key: 'price',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.frequency, b.frequency, 'string'),

      className: `${allowPermision(role, [userRoles.Admin, userRoles.Accountant]) ? 'hoverEdit' : ''}`,
      render: (_, record) => {
        return (
          <div className={'line-clamp-1'}>
            <Tooltip placement="top" title={record?.price}>
              {record.price
                ? record.price + ' ' + (record.currency ? record.currency : '')
                : '-'}
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: getTitleColumns('assigneeName', 'software'),
      dataIndex: 'assigneeName',
      key: 'assigneeName',
      width: '20%',
      showSorterTooltip: false,
      sorter: (a, b) => _sort(a.assigneeName, b.assigneeName, 'string'),
      filters: getFiltersWithSearch(data),
      filterSearch: true,
      onFilter: (value: string, record) => {
        if (value === 'blank') {
          return record?.assigneeName === null || record?.assigneeName === '';
        }
        return record?.assigneeName?.startsWith(value) || false;
      },
      className: `${allowPermision(role, [userRoles.Admin, userRoles.Accountant]) ? 'hoverEdit' : ''}`,
      render: (_, record, index) => {
        return (
          <>
            <div className={'hideContent line-clamp-1'}>
              {record.assignType === 'Team' ? (
                <>
                  {record.assigneeName ? (
                    <>
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            {record?.assigneeName}{' '}
                            <FormattedMessage id="team" />
                          </>
                        }
                      >
                        {record.assigneeName} (<FormattedMessage id="team" />)
                      </Tooltip>
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <Tooltip placement="top" title={record?.assigneeName || '-'}>
                  {record.assigneeName || '-'}
                </Tooltip>
              )}
            </div>
            <div
              className={
                allowPermision(role, [userRoles.Admin, userRoles.Accountant])
                  ? 'showEdit'
                  : ''
              }
              style={{ display: 'none' }}
            >
              <div className="actionButtons">
                {record.assigneeName && record.assignType !== 'None' ? (
                  <ButtonWithExpandedText
                    displayedText={
                      <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                    }
                    embedSrc={<ThemedIcon icon={MinusCircleContained} />}
                    type="link"
                    onClick={() => {
                      stateActions?.setModalProps(state => ({
                        ...state,
                        scope: 'deassignAsset',
                        assetId: parseInt(record?.assetId ?? index + 1),
                        modalTitle: <FormattedMessage id="UnassignAsset" />,
                        submitButtonText: (
                          <FormattedMessage id="table_SoftwHardwBtn_unassign" />
                        ),
                        cancelButtonText: <FormattedMessage id="cancel" />,
                        formKeyId: 'AssignForm',
                        assignPerson: record.assigneeName
                      }));
                      setIsOpen();
                    }}
                  />
                ) : (
                  <>
                    <ButtonWithExpandedText
                      displayedText={
                        <FormattedMessage id="table_SoftwHardwBtn_colleague_assign" />
                      }
                      embedSrc={<ThemedIcon icon={ColleagueAssignIcon} />}
                      type="link"
                      onClick={() => {
                        stateActions?.setModalProps(state => ({
                          ...state,
                          scope: 'assignColleague',
                          assetId: parseInt(record?.assetId ?? index + 1),
                          modalTitle: (
                            <FormattedMessage id="AssignToAColleague" />
                          ),
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                        setIsOpen();
                      }}
                    />
                    <ButtonWithExpandedText
                      displayedText={
                        <FormattedMessage id="table_SoftwHardwBtn_team_assign" />
                      }
                      embedSrc={<ThemedIcon icon={TeamAssignIcon} />}
                      type="link"
                      onClick={() => {
                        stateActions?.setModalProps(state => ({
                          ...state,
                          scope: 'assignTeam',
                          assetId: parseInt(record?.assetId ?? index + 1),
                          modalTitle: <FormattedMessage id="AssignToATeam" />,
                          submitButtonText: <FormattedMessage id="assign" />,
                          cancelButtonText: <FormattedMessage id="cancel" />,
                          formKeyId: 'AssignForm'
                        }));
                        setIsOpen();
                      }}
                    />
                  </>
                )}
                <ButtonWithExpandedText
                  displayedText={<FormattedMessage id="edit" />}
                  embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                  type="link"
                  onClick={() => {
                    stateActions?.setModalProps(state => ({
                      ...state,
                      scope: 'edit',
                      assetId: parseInt(record?.assetId ?? index + 1),
                      modalTitle: <FormattedMessage id="EditAsset" />,
                      cancelButtonText: <FormattedMessage id="cancel" />,
                      submitButtonText: <FormattedMessage id="save" />,
                      formKeyId: 'AssetSoftwareForm',
                      assignPerson: record.assigneeName
                    }));
                    setIsOpen();
                  }}
                />
              </div>
            </div>
          </>
        );
      }
    }
  ];

  const dataSourceKeys = dataSource?.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
    titlesKeysofColumnsSoftware.some(item => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  return tableColumnsSoftware.filter(checkForDataDisplay);
};
