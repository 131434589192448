import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableWithSearchPagePlaceholder } from 'components/Placeholders';
import ReportsDetails from 'components/ReportsDetails';
import { useReportsFilterContext } from 'components/ReportsDetails/ReportsFilterContext';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReportService } from 'services';
import { IReport } from 'services/providers/ReportProvider/types';

export const ReportsDetailsPage = () => {
  const location = useLocation();
  const state = location.state;

  const { rangeFilter, requestColumnsFilter, generateAllByFilters } =
    useReportsFilterContext();
  const [status, setStatus] = useState<string>('pending');
  const path = location.pathname.split('/').pop();

  let defaultBodyParams = {
    reportName: path,
    from: rangeFilter?.startDate || null,
    to: rangeFilter?.endDate || null,
    presetId: state?.presetId
  };

  const { data: report } = useApiRequestHook<IReport>(
    ReportService.getReport,
    generateAllByFilters.reportName ? generateAllByFilters : defaultBodyParams,
    {},
    {},
    null,
    [generateAllByFilters]
  );

  useEffect(() => {
    setStatus(Object.keys(report).length === 0 ? 'pending' : 'success');
  }, [report]);

  return (
    <ComponentWithStatus
      status={status}
      Placeholder={<TableWithSearchPagePlaceholder />}
    >
      <ReportsDetails data={report} status={status} />
    </ComponentWithStatus>
  );
};
