import { useAuth } from 'services/providers/AuthProvider';
import { useEffect, useState } from 'react';
import AuthLayout from './AuthLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthTenant } from 'services/providers/AuthProvider/types';

const TenantChooserPage = () => {
  const { authenticateUser } = useAuth();
  const [tenants, setTenants] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  
  const authenticateWithTenant = async (tenantId: number) => {
    const { isUserAuthenticated } = await authenticateUser(state.idToken, state.provider, tenantId);
    navigate(isUserAuthenticated? '/redirect': '/login'); 
  }

  useEffect(() => {
    const setUp = () => {
      const tenants = state && Object.values(state?.tenants); 
      if(tenants?.length) {
        setTenants(tenants);
      }
      else {
        navigate('/login');
      }
    }
    setUp();
  }, [])
  return (
    <AuthLayout>
      <span className='optionsText'>Please choose a tenant:</span>
      {
        tenants.map((tenant: AuthTenant) => 
          <div className='optionButton' key={tenant.tenantId} onClick={() => authenticateWithTenant(tenant.tenantId)}>
            Continue with {tenant.tenantName}
          </div>) 
      }
    </AuthLayout>
  );
};

export default TenantChooserPage;
