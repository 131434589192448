import { Form, Input, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { onFinish } from './utils';
import useGetSearchParam from 'hooks/useGetSearchParams';
import { useDispatch } from 'react-redux';
import { useModalContext } from 'components/Modal/ModalProvider';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
import { FormattedMessage, useIntl } from 'react-intl';

const TeamForm = ({ scope, editTeam = null }) => {
  const [form] = Form.useForm();

  const teamId = useGetSearchParam('teamId', 'number').parsedParam;
  const dispatch = useDispatch();

  const { toggleModal } = useModalContext();
  const onFinishFormActions = {
    dispatch,
    toggleModal
  };

  const intl = useIntl();

  return (
    <Form
      form={form}
      id="teamForm"
      layout="horizontal"
      labelAlign="left"
      labelWrap
      labelCol={{ span: 6 }}
      onFinish={data => onFinish(scope, data, teamId, onFinishFormActions)}
      onFinishFailed={validationError =>
        onFormFinishFailedValidation(validationError, dispatch)
      }
    >
      <Row>
        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="label_CreateTeamForm_TeamName" />}
          name="name"
          rules={setFormItemRules({
            requiredMessage: intl.formatMessage({
              id: 'CreateTeamNameValidation'
            }),
            hasPattern: true,
            pattern:
              /^(?!^\s+$)[a-zA-Z0-9À-ž\u00C0-\u024F\u1E00-\u1EFF\s.,'"\-]*$/,

            patternMessage: intl.formatMessage({
              id: 'TeamsNameCharactersValidation'
            }),
            isMaxLengthRequired: true,
            maxLengthRequired: 25,
            maxLengthRequiredMessage: intl.formatMessage({
              id: '25CharactersInputValidation'
            })
          })}
          initialValue={editTeam?.name}
        >
          <Input minLength={1} maxLength={26} />
        </ColFormItemComponent>
      </Row>
      <Row>
        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="label_CreateTeamForm_Description" />}
          name="description"
          initialValue={editTeam?.description}
          rules={setFormItemRules({
            isRequired: false,
            isMaxLengthRequired: true,
            maxLengthRequired: 200,
            maxLengthRequiredMessage: intl.formatMessage({
              id: 'TeamDescriptionLengthValidation'
            }),
            hasPattern: true,
            pattern: /^(?!\s *$)/,
            patternMessage: intl.formatMessage({
              id: 'AssetNameCharactersValidation'
            })
          })}
        >
          <TextArea maxLength={201} />
        </ColFormItemComponent>
      </Row>
    </Form>
  );
};

export default TeamForm;
