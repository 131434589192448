import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import AddCategoryIcon from 'Icons/Inventory/AddCategoryIcon';
import { DeleteIconTrash } from 'Icons/Reports/DeleteIconTrash';
import { EditIcon } from 'Icons/Reports/EditIcon';
import { Typography } from 'antd';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { WidgetComponent } from 'components/Widgets';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';

import ModalComponent from 'components/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportService } from '../../../services';
import useApiRequestHook from '../../../hooks/useApiRequest.hook';
import { ReportPreset } from '../../../services/providers/ReportProvider/types';
import './ReportItem.less';

const ReportItem = ({ className, params }) => {
  const { theme } = useThemeContext();
  const { Text } = Typography;

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<{
    presetName: string;
    presetId: number;
  }>({ presetName: '', presetId: 0 });
  const ButtonCustom = BaseButton(ButtonSimple);

  let filtredPresets = params.presets.filter(
    preset => preset.reportName === params.state
  );

  let PresetsList = filtredPresets.map(item => {
    return {
      presetName: item.presetName,
      presetId: item.presetId
    };
  });

  const handleRemoveItem = () => {
    setShowConfirmDelete(false);
    params.presets = params.presets.filter(
      preset => preset.presetId !== selectedPreset.presetId
    );
    ReportService.deletePreset({ id: selectedPreset.presetId });
    notifyWithSuccessfulDataUpdated(getSuccessMessages.success_preset_deleted);
  };

  const intl = useIntl();
  const yesText = intl.formatMessage({ id: 'yes' });
  const noText = intl.formatMessage({ id: 'no' });
  return (
    <>
      <ModalComponent
        title={<FormattedMessage id="DeletePreset" />}
        className="delete-preset-modal"
        isOpen={showConfirmDelete}
        setIsOpen={setShowConfirmDelete}
        width="auto"
        submitBtnText={yesText}
        cancelBtnText={noText}
        onSubmit={() => handleRemoveItem()}
      >
        <>
          <p style={{ marginBlock: '0px' }}>
            <FormattedMessage
              id="deletePresetConfirm"
              values={{ selectedPreset: <b>{selectedPreset.presetName}</b> }}
            />
          </p>
        </>
      </ModalComponent>
      <div
        className={`report-item-wrapper border-custom ${theme} ${className}`}
      >
        {PresetsList.length > 0 ? (
          <WidgetComponent
            title={
              params.state ? (
                <Link
                  to={params.state}
                  state={{ isPreset: false, presets: filtredPresets }}
                  style={{ color: 'inherit' }}
                >
                  <FormattedMessage id={params.state} />
                </Link>
              ) : (
                ''
              )
            }
            bordered={false}
            className={`full-content report-item ${theme}`}
          >
            {params.state ? (
              <>
                <div className="report-item-divider"></div>
                <ul className="report-item-list">
                  {PresetsList.map((preset, index) => (
                    <li key={index}>
                      <Link
                        to={`/reports/${params.state}`}
                        state={{
                          ...preset.presetState,
                          presetName: preset.presetName,
                          isEdit: false,
                          presetId: preset.presetId,
                          isPreset: true
                        }}
                        className="reports-item-link"
                      >
                        <p>{preset.presetName}</p>
                      </Link>{' '}
                      <div className="report-item-options">
                        <span>
                          <Link
                            to={`/reports/${params.state}`}
                            state={{
                              ...preset.presetState,
                              presetName: preset.presetName,
                              presetId: preset.presetId,
                              isEdit: true,
                              presets: filtredPresets,
                              isPreset: true
                            }}
                          >
                            <EditIcon />
                          </Link>
                        </span>
                        <span
                          onClick={() => {
                            setSelectedPreset({
                              presetName: preset.presetName,
                              presetId: preset.presetId
                            });
                            setShowConfirmDelete(true);
                          }}
                        >
                          <DeleteIconTrash />
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <div className="report-item-soon">
                <Text className="report-item-soon-text">
                  <FormattedMessage id="ComingSoonText" />
                  ...
                </Text>
                <ButtonCustom
                  className={`add-report-btn text-bold-normal btn-primary-custom ${theme}`}
                  type="primary"
                >
                  <AddCategoryIcon stroke="#fff" />{' '}
                  <FormattedMessage id="AddReportText" />
                </ButtonCustom>
              </div>
            )}
          </WidgetComponent>
        ) : (
          <Link to={params.state} style={{ color: 'inherit' }}>
            <WidgetComponent
              title={params.title ? <FormattedMessage id={params.title} /> : ''}
              bordered={false}
              className={`full-content report-item ${theme}`}
            >
              {params.state ? (
                <>
                  <div className="report-item-divider"></div>
                  <ul className="report-item-list">
                    {PresetsList.map((preset, index) => (
                      <li key={index}>
                        <Link
                          to={`/reports/${params.state}`}
                          state={{
                            ...preset.presetState,
                            presetName: preset.presetName,
                            isEdit: false,
                            isPreset: true
                          }}
                          className="reports-item-link"
                        >
                          <p>{preset.presetName}</p>
                        </Link>{' '}
                        <div className="report-item-options">
                          <span>
                            <Link
                              to={`/reports/${params.state}`}
                              state={{
                                ...preset.presetState,
                                presetName: preset.presetName,
                                isEdit: true,
                                presets: filtredPresets,
                                isPreset: true
                              }}
                            >
                              <EditIcon />
                            </Link>
                          </span>
                          <span
                            onClick={() => {
                              setSelectedPreset({
                                presetName: preset.presetName,
                                presetId: preset.presetId
                              });
                              setShowConfirmDelete(true);
                            }}
                          >
                            <DeleteIconTrash />
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <div className="report-item-soon">
                  <Text className="report-item-soon-text">
                    <FormattedMessage id="ComingSoonText" />
                    ...
                  </Text>
                  <ButtonCustom
                    className={`add-report-btn text-bold-normal btn-primary-custom ${theme}`}
                    type="primary"
                  >
                    <AddCategoryIcon stroke="#fff" />{' '}
                    <FormattedMessage id="AddReportText" />
                  </ButtonCustom>
                </div>
              )}
            </WidgetComponent>
          </Link>
        )}
      </div>
    </>
  );
};

export default ReportItem;
