import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { FillArrowDown } from 'Icons/General/fillArrowDown';
import { FillArrowUp } from 'Icons/General/fillArrowUp';
import { useState } from 'react';

const FilterSort = ({ entireItem, dataSorce, setSorted }) => {
  const { theme } = useThemeContext();
  const initialData = dataSorce
    .map((data) => data[entireItem?.key])
    .filter((tableData) => tableData);

  const [sortedDataSource, setSortedDataSource] = useState(initialData);

  const [clickCount, setClickCount] = useState(0);

  const handleSort = () => {
    let sortedData = [...sortedDataSource];
    sortedData.sort(entireItem.sorter);

    let newClickCount = (clickCount + 1) % 3; // on third click the sort is disabled

    if (newClickCount === 0) {
      setClickCount(0);
      //work like disable of sort
      setSorted(dataSorce);
    } else {
      setClickCount(newClickCount);
      if (newClickCount === 1) {
        // First click, set clicked to arrow up and start ASC
        sortedData.sort(entireItem.sorter);
      } else {
        // Second click, set clicked to arrow down and start DESC
        sortedData.reverse();
      }
      //set sortedDataSorce also setSorted which return entire data of table by sorted data
      setSortedDataSource(sortedData);
      const reorderedDataSorce = sortedData.map((sortedItem) => {
        // Check object equality
        const originalItem = dataSorce.find(
          (item) =>
            JSON.stringify(item?.[entireItem?.key]) ===
            JSON.stringify(sortedItem)
        );
        return originalItem;
      });

      setSorted(reorderedDataSorce);
    }
  };

  return (
    <div className={`sort-container ${theme}`}>
      <FillArrowUp
        className={clickCount === 1 ? 'clicked' : ''}
        onClick={handleSort}
      />
      <FillArrowDown
        className={clickCount === 2 ? 'clicked' : ''}
        onClick={handleSort}
      />
    </div>
  );
};

export default FilterSort;
