import { EmployeeServices, TeamsService, TimeOffService } from 'services';
import {
  IEmployee,
  IEmployeeStatistics
} from 'services/providers/EmployeeProvider/types';
import EmployeeProfileCard from 'components/Employee/EmployeeProfileCard';
import useApiRequest from 'hooks/useApiRequest.hook';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { useSearchParams } from 'react-router-dom';
import {
  allowPermision,
  getCurrentUserRole,
  rolesAllow
} from 'utils/userManagement';
import { useAuth } from 'services/providers/AuthProvider';
import { useCompanySettings } from 'settings';
import { PolicyConfig } from 'AppProvider/ConfigManager/config';
import {
  MobilePagePlaceholder,
  ProfilePagePlaceholder
} from 'components/Placeholders';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { setLoggedUser } from 'redux/slices/userSlice';

function Profile() {
  let [searchParams] = useSearchParams();
  const employeeIdSearch = searchParams.get('employeeId') ?? 1;
  const [employeeId, setEmployeeId] = useState<number | string>(
    employeeIdSearch
  );
  const { countryCode } = useCompanySettings();

  const [dataEmployee, setDataEmployee] = useState(null);
  const [dataEmployeeTeams, setEmployeeTeams] = useState(null);
  const [dataEmployeeStatistics, setEmployeeStatistics] = useState<{
    data: IEmployeeStatistics;
  }>(null);
  const dispatch = useDispatch();

  const { data, status } = useApiRequest<IEmployee>(
    EmployeeServices.getEmployeeProfile,
    'false',
    {},
    {}
  );

  const { user } = useAuth();
  const { isMobile } = useMobileContext();

  const scope = !Object.keys(data).length
    ? 'MyProfile'
    : data.employeeId !== +employeeIdSearch
      ? 'ColleagueProfile'
      : 'MyProfile';
  const userRole = getCurrentUserRole(user);
  const isAllowedUserRole = allowPermision(userRole, rolesAllow);

  const getEmployeeProfile = async () => {
    let parameters = {
      employeeId: +employeeIdSearch
    };

    const profile =
      data.employeeId !== +employeeIdSearch && isAllowedUserRole
        ? await EmployeeServices.getEmployee(null, parameters)
        : { data, status };

    if (scope === 'MyProfile' && status === 'success') {
      dispatch(setLoggedUser(profile.data as IEmployee));
    }
    setDataEmployee(profile);

    const teams =
      data.employeeId !== +employeeIdSearch && isAllowedUserRole
        ? await TeamsService.getEmployeeTeams(parameters)
        : await TeamsService.getMyTeams();
    setEmployeeTeams(teams.data);

    const statistics =
      data.employeeId !== +employeeIdSearch && isAllowedUserRole
        ? await TimeOffService.getTimeOffStatisticsByEmployeeId(
            +employeeIdSearch
          )
        : await TimeOffService.getTimeOffStatistics();

    //set employee statistics and filter hidden policies
    setEmployeeStatistics({
      ...statistics,
      data: {
        ...statistics.data,
        statistics: statistics.data.statistics?.filter(({ statisticType }) => {
          const policy = PolicyConfig[countryCode]?.DefaultTimeOffRequests.find(
            policy => policy.request === statisticType
          );

          // If no matching policy is found, or if the policy is not hidden and not admin-only, include the statistic
          return (
            !policy ||
            (!policy.isHidden && !(policy.isAdminOnly && !isAllowedUserRole))
          );
        })
      }
    });
  };

  useEffect(() => {
    Object.keys(data).length && getEmployeeProfile();
  }, [scope, data]);

  const methodUpdateSelector = useSelector(
    (state: RootState) => state.methodUpdate
  );

  useEffect(() => {
    if (methodUpdateSelector.isMethodUpdated) {
      dispatch(setMethodUpdate(false));
    }

    if (status !== 'success' || !data) {
      return;
    }

    setEmployeeId(
      data.employeeId !== +employeeIdSearch
        ? +employeeIdSearch
        : data.employeeId
    );
  }, [methodUpdateSelector.isMethodUpdated]);

  useEffect(() => {
    //solving issue when employeeID is not updated when route is changed without refresh
    setEmployeeId(employeeIdSearch);
  }, [employeeIdSearch]);

  return (
    <>
      <ComponentWithStatus
        status={dataEmployee && dataEmployee.data ? 'success' : 'pending'}
        Placeholder={
          isMobile ? <MobilePagePlaceholder /> : <ProfilePagePlaceholder />
        }
      >
        <EmployeeProfileCard
          scope={scope}
          employeeId={employeeId}
          data={dataEmployee && dataEmployee.data}
          employeeTeamsData={dataEmployeeTeams && dataEmployeeTeams}
          employeeStatistics={dataEmployeeStatistics}
        />
      </ComponentWithStatus>
    </>
  );
}
export default Profile;
