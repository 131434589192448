import * as React from 'react';
import PlaceholderWrapper from '../UI/PlaceholderWrapper';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { J } from '@fullcalendar/core/internal-common';

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export const TimeManagementWidgetPlaceholder: React.FC<any> = props => {
  const { isMobile, isTabletDevice, isSmallLaptop } = useMobileContext();
  return (
    <PlaceholderWrapper
      width={props.width || '100%'}
      height={props.height || '100%'}
      {...props}
    >
      <rect x="24" y="27" rx="5" ry="5" width="230" height="22" />
      <rect x="26" y="54" rx="3" ry="3" width="220" height="10" />
      {isTabletDevice || isSmallLaptop ? (
        <>
        {new Array(8).fill(' ').map((_, i) => {
            return (
              <React.Fragment key={i}>
                <circle cx={`${85 + (i * 170)}`} cy="150" r="35" />

                <rect x={`${37 + (i * 170)}`} y="208" rx="10" ry="10" width="90" height="25" />

                <rect x={`${24 + (i * 170)}`} y="244" rx="2" ry="2" width="120" height="10" />
                <rect x={`${24 + (i * 170)}`} y="266" rx="2" ry="2" width="120" height="10" />
                <rect x={`${24 + (i * 170)}`} y="288" rx="2" ry="2" width="120" height="10" />
                <rect x={`${24 + (i * 170)}`} y="310" rx="2" ry="2" width="120" height="10" />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <>     {new Array(9).fill(' ').map((_, i) => {
          return (
            <React.Fragment key={i}>
              <circle cx={`${210 + i * 170}`} cy="170" r="35" />

              <rect
                x={`${162 + i * 170}`}
                y="228"
                rx="10"
                ry="10"
                width="90"
                height="25"
              />

              <rect
                x={`${150 + i * 170}`}
                y="294"
                rx="2"
                ry="2"
                width="120"
                height="10"
              />
              <rect
                x={`${150 + i * 170}`}
                y="316"
                rx="2"
                ry="2"
                width="120"
                height="10"
              />
              <rect
                x={`${150 + i * 170}`}
                y="338"
                rx="2"
                ry="2"
                width="120"
                height="10"
              />
              <rect
                x={`${150 + i * 170}`}
                y="360"
                rx="2"
                ry="2"
                width="120"
                height="10"
              />
            </React.Fragment>
          );
        })}</>
      )}
    </PlaceholderWrapper>
  );
};

export const SmallTimeManagementWidgetPlaceholder: React.FC<any> = props => {
  const { isMobile, isTabletDevice, isSmallLaptop } = useMobileContext();

  return (
    <PlaceholderWrapper
      width={props.width || '100%'}
      height={'100%'}
      {...props}
    >
      <>
        {isSmallLaptop ? (
          <>
            <rect x="24" y="27" rx="5" ry="5" width="230" height="22" />
            <rect x="26" y="54" rx="3" ry="3" width="220" height="10" />

            {new Array(4).fill(' ').map((_, i) => {
              return (
                <React.Fragment key={i}>
                  <circle cx={`${85 + i * 170}`} cy="150" r="35" />

                  <rect
                    x={`${37 + i * 170}`}
                    y="208"
                    rx="10"
                    ry="10"
                    width="90"
                    height="25"
                  />

                  <rect
                    x={`${24 + i * 170}`}
                    y="244"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                  <rect
                    x={`${24 + i * 170}`}
                    y="266"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                  <rect
                    x={`${24 + i * 170}`}
                    y="288"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                  <rect
                    x={`${24 + i * 170}`}
                    y="310"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <>
            {' '}
            <rect x="24" y="27" rx="5" ry="5" width="230" height="22" />
            <rect x="26" y="54" rx="3" ry="3" width="220" height="10" />
            {new Array(5).fill(' ').map((_, i) => {
              return (
                <React.Fragment key={i}>
                  <circle cx={`${85 + i * 170}`} cy="170" r="35" />

                  <rect
                    x={`${37 + i * 170}`}
                    y="228"
                    rx="10"
                    ry="10"
                    width="90"
                    height="25"
                  />

                  <rect
                    x={`${24 + i * 170}`}
                    y="294"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                  <rect
                    x={`${24 + i * 170}`}
                    y="316"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                  <rect
                    x={`${24 + i * 170}`}
                    y="338"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                  <rect
                    x={`${24 + i * 170}`}
                    y="360"
                    rx="2"
                    ry="2"
                    width="120"
                    height="10"
                  />
                </React.Fragment>
              );
            })}{' '}
          </>
        )}
      </>
    </PlaceholderWrapper>
  );
};

export const CalendarWidgetPlaceholder: React.FC<any> = props => {
  const { isMobile, isTabletDevice, isSmallLaptop } = useMobileContext();
  const calendarHeight =
    isMobile && isTabletDevice ? '' : isSmallLaptop ? '305' : '340';
  return (
    <PlaceholderWrapper
      width={props.width || '100%'}
      height={props.height || '100%'}
      {...props}
    >
      <rect
        x="0"
        y="0"
        rx="16"
        ry="16"
        width="50%"
        height={`${calendarHeight}px`}
      />
      <rect x="55%" y="113" rx="3" ry="3" width="102" height="7" />
      <rect x="55%" y="91" rx="3" ry="3" width="178" height="6" />
      <rect x="55%" y="139" rx="3" ry="3" width="178" height="6" />
      <rect x="55%" y="162" rx="3" ry="3" width="102" height="7" />
      <rect x="55%" y="189" rx="3" ry="3" width="178" height="6" />
      <rect x="55%" y="223" rx="14" ry="14" width="72" height="32" />
      <rect x="55%" y="224" rx="14" ry="14" width="72" height="32" />
      <rect x="55%" y="41" rx="3" ry="3" width="231" height="29" />
    </PlaceholderWrapper>
  );
};

export const EmployeeProfilePicturePlaceholder: React.FC<any> = props => (
  <PlaceholderWrapper
    viewBox="0 0 160 160"
    width={props.width || '160'}
    height={props.height || '160'}
    {...props}
    style={{ marginTop: '-180px' }}
  >
    <circle cx="50%" cy="50%" r="50%" />
  </PlaceholderWrapper>
);

export const TicketingWidgetPlaceholder: React.FC<any> = props => (
  <PlaceholderWrapper width="100%" height="100%" {...props}>
    <rect x="20" y="20" ry="5" rx="5" width="100%" height="30" />
  </PlaceholderWrapper>
);

export const SmallRequestsWidgetPlaceholder: React.FC<any> = props => {
  const { count } = props;

  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {new Array(count).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x={`${(100 / count) * i}%`}
            y="2%"
            rx="5"
            ry="5"
            width={`${100 / count - 1}%`}
            height="25%"
          />
        );
      })}

      {[...Array(18)].map((_, i) =>
        [...Array(count)].map((_, j) => (
          <rect
            key={`${i}-${j}`}
            x={`${(100 / count) * j}%`}
            y={`${(100 / 5) * i + 35}%`}
            width={`${getRandomArbitrary(0, 100 / count - 1)}%`}
            height={`15%`}
            rx="5"
            ry="5"
          />
        ))
      )}
    </PlaceholderWrapper>
  );
};

export const PapersListWidgetPlaceholder: React.FC<any> = props => (
  <PlaceholderWrapper width="100%" height="100%" {...props}>
    <rect x="20" y="0%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="12%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="24%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="36%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="48%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="60%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="72%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="84%" ry="5" rx="5" width="95%" height="10%" />
    <rect x="20" y="96%" ry="5" rx="5" width="95%" height="10%" />
  </PlaceholderWrapper>
);

export const TeamsWidgetPlaceholder: React.FC<any> = props => (
  <PlaceholderWrapper width="100%" height="100%" {...props} >
    <rect x="20" y="5%" ry="5" rx="5" width="90%" height="10%" />
    <rect x="20" y="21%" ry="5" rx="5" width="90%" height="54%" />

    <rect x="20" y="81%" ry="5" rx="5" width="90%" height="3%" />
    <rect x="20" y="86%" ry="5" rx="5" width="90%" height="3%" />
    <rect x="20" y="91%" ry="5" rx="5" width="90%" height="3%" />

  </PlaceholderWrapper>

  
)

export const PapersWidgetPlaceholder: React.FC<any> = props => (
  <PlaceholderWrapper width="100%" height="100%" {...props}>
    <rect x="20" y="5%" ry="5" rx="5" width="20%" height="10%" />
    <rect x="20" y="20%" ry="5" rx="5" width="90%" height="70%" />

  </PlaceholderWrapper>
)
export const TicketingPlaceholder: React.FC = props => {
  return (
    <PlaceholderWrapper height="100%" width="100%" {...props}>
      {/* <rect x="36%" y="1%" ry="5" rx="5" width="30%" height="20" /> */}
      <circle cx="50%" cy="20%" r="10%" />
      <rect x="38%" y="35%" ry="5" rx="5" width="25%" height="20" />
      <rect x="4%" y="50%" ry="5" rx="5" width="90%" height="15" />
      <rect x="4%" y="60%" ry="5" rx="5" width="90%" height="15" />
      <rect x="4%" y="70%" ry="5" rx="5" width="90%" height="15" />
      <rect x="4%" y="80%" ry="5" rx="5" width="90%" height="15" />
      <rect x="40%" y="87%" ry="5" rx="5" width="80" height="15" />
    </PlaceholderWrapper>
  );
};
