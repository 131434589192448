import { Col, Row } from 'antd';
import { FC } from 'react';
import EmployeeInfoFields from '../EmployeeInfoFields';
import '../EmployeeProfileCard.less';
import { ProfileAvatar } from './ProfileAvatar';

type EmployeeProfileProps = { data: any; scope?: string; employeeId?: any; };

export const ProfileUser: FC<EmployeeProfileProps> = ({
  data,
  scope,
  employeeId,
}) => {
  return (
    <Row className="content-on-profile full-content">
      <Col span={12} className="full-content">
        <div className="background-on-profile">
          <div className="img-wrapper" style={{ marginTop: '10%' }}>
            <ProfileAvatar scope={scope} data={data} userId={employeeId} />
          </div>
        </div>
      </Col>
      <Col span={12} className="full-content">
        <EmployeeInfoFields data={data} />
      </Col>
    </Row>
  );
};
