export const InventorySvg = () => {
  return (
    <span
      role="img"
      aria-label="inventory"
      className="anticon anticon-inventory"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5557 14.4C10.8013 14.4 10.0778 14.1003 9.54433 13.5669C9.01089 13.0334 8.71121 12.3099 8.71121 11.5555V3.0222C8.71121 2.645 8.86105 2.28325 9.12777 2.01653C9.39449 1.74982 9.75623 1.59998 10.1334 1.59998H12.9779C13.3551 1.59998 13.7168 1.74982 13.9835 2.01653C14.2503 2.28325 14.4001 2.645 14.4001 3.0222V11.5555C14.4001 12.3099 14.1004 13.0334 13.567 13.5669C13.0335 14.1003 12.31 14.4 11.5557 14.4ZM11.5557 14.4H3.02232C2.64512 14.4 2.28338 14.2501 2.01666 13.9834C1.74994 13.7167 1.6001 13.3549 1.6001 12.9778V10.1333C1.6001 9.75611 1.74994 9.39436 2.01666 9.12765C2.28338 8.86093 2.64512 8.71109 3.02232 8.71109H4.65788M8.71121 4.69331L7.28899 3.27109C7.02228 3.00446 6.6606 2.85468 6.28348 2.85468C5.90635 2.85468 5.54467 3.00446 5.27796 3.27109L3.26694 5.28211C3.00032 5.54881 2.85053 5.9105 2.85053 6.28762C2.85053 6.66474 3.00032 7.02642 3.26694 7.29313L9.66694 13.6931M11.5557 11.5555V11.5626"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
