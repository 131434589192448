import { commonRequest } from 'services/generic';
import { IClockout, IClockInOuts } from './types';

export default class ClockInOutProvider {
  async clockIn() {
    return await commonRequest('POST', null, 'TimeOff/clockIn');
  }

  async clockOut(payload: IClockout) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'TimeOff/clockOut');
  }

  async getClockInOut(payload: number) {
    const payloadData = { payload };

    return await commonRequest('POST', payloadData, 'TimeOff/getClockInOut');
  }

  async getClockInOuts(payload: IClockInOuts) {
    const payloadData = { payload };

    return await commonRequest('POST', payloadData, 'TimeOff/getClockInOuts');
  }
}
