import * as React from 'react';

import { getValue } from '../CardRequest/utils';
import { StatisticsProps } from './interfaces/Statistics.interface';
import { rowNameTable } from 'components/Widgets/TimeManagementWidget/utils';

import styles from './style.module.less';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

const Statistics: React.FC<StatisticsProps> = ({ data, request }) => {
  const { theme } = useThemeContext();
  const isSpecialRequest = request === 'Special';
  if (theme === 'dark') {
    rowNameTable[0].colorRow = '#fff';
  } else {
    rowNameTable[0].colorRow = '#393EC0';
  }

  let content;

  if (!isSpecialRequest) {
    content = data?.rowKeys
      ?.filter(row => row !== 'owner')
      .map(row => {
        return rowNameTable.map(({ titleRow, keyRow, colorRow }, rowIndex) => {
          if (keyRow === row) {
            return (
              <React.Fragment key={keyRow}>
                {data?.keyColumns.map((i, index) => {
                  if (request === i) {
                    return (
                      <div key={index} className={styles.statistics__item}>
                        <span className={styles.label}> {titleRow}</span>
                        <span
                          className={styles.value}
                          style={{ color: colorRow }}
                        >
                          {' '}
                          {getValue(i, row, data)}
                        </span>
                      </div>
                    );
                  }
                })}
              </React.Fragment>
            );
          }
        });
      });
  } else if (isSpecialRequest) {
    content = rowNameTable.map(({ titleRow, keyRow, colorRow }, index) => {
      return (
        <React.Fragment key={index}>
          <div className={styles.statistics__item}>
            <span className={styles.label}> {titleRow}</span>
            <span className={styles.value} style={{ color: colorRow }}>
              {' '}
              n/a
            </span>
          </div>
        </React.Fragment>
      );
    });
  }

  return <>{content}</>;
};

export default Statistics;
