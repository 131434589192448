import { useIntl } from "react-intl";
import { setMethodUpdate } from "redux/slices/methodUpdateSlice";
import { TicketingWorkQueueService } from "services";
import { getSuccessMessages } from "utils/getSuccessMessages";
import { notifyWithSuccessfulDataUpdated } from "utils/notificationsUtils";
import { onFormFinishWithPassedValidation } from "utils/onSubmitForm";

export const onFinish = (scope, data, actions) => {
    const formActions = {
      add: TicketingWorkQueueService.addWorker,
      edit: TicketingWorkQueueService.removeWorker,
    };
  
    if (scope === 'remove') {
        TicketingWorkQueueService.removeWorker(
      data
        ).then((res) => {          
          if (!actions) {
            return;
          }
    
          if (actions.dispatch && typeof actions.dispatch === 'function') {
            actions.dispatch(setMethodUpdate(true));
          }
          if (actions.toggleModal && typeof actions.toggleModal === 'function') {
            actions.toggleModal()
          }
          if(res?.data?.statusCode === 200 ) {
            notifyWithSuccessfulDataUpdated(
              '',
              getSuccessMessages.success_removed_worker
            );
          }
  
        });
        return;
      }
    const getMessages =
      scope === 'add'
        ? getSuccessMessages.succes_added_worker
        :  null
  
    onFormFinishWithPassedValidation(
      formActions,
      scope,
      data,
      actions,
      getMessages
    );
  };