export const showDownloadRequestButtonStatuses = {
  MD: ['Approved', 'Confirmed', 'ApprovedSecondary'],
  RO: ['Pending', 'ApprovedSecondary', 'Approved', 'Confirmed']
};

export const policyHasOrder = [
  'Vacation',
  'Unpaid',
  'Paternity',
  'Childcare',
  'Bereavement',
  'BloodDonation',
  'Marriage',
  'MilitaryTraining',
  'BusinessTrip',
  'TwoOrMoreChildren',
  'FirstDayOfSchool',
  'LastDayOfSchool',
  'ChildcareLeaveUnpaid',
  'BirthOfAChild'
];

//TO DO: refactor this based on policy config
export const shallDisplaySignedDocumentsToolTip = (
  status: string,
  isSigned: boolean,
  countryCode: string
) =>
  countryCode === 'RO' &&
  isSigned === false &&
  !['Confirmed', 'RevokePending'].includes(status);

//TO DO: refactor this based on policy config
export const isDownloadableRequestType = (requestType, countryCode: string) => {
  const fullDayPolicies = [
    'Vacation',
    'Unpaid',
    'Paternity',
    'Childcare',
    'Bereavement',
    'BloodDonation',
    'Marriage',
    // 'MilitaryTraining',
    // 'BusinessTrip',
    'TwoOrMoreChildren',
    'FirstDayOfSchool',
    'LastDayOfSchool',
    'ChildcareLeaveUnpaid',
    'BirthOfAChild'
  ];
  const includeTimeBasedPolicies = countryCode === 'RO';
  const timeBasedPolicies = ['LeaveOffsetting', 'Overtime', 'PersonalLeave'];
  let policies = fullDayPolicies;
  if (includeTimeBasedPolicies) {
    policies = [...policies, ...timeBasedPolicies, 'Maternity'];
  }
  return policies.includes(requestType);
};
