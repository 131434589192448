import { TicketingSettingsService } from '../../../services';
import { setMethodUpdate } from '../../../redux/slices/methodUpdateSlice';
import { notifyWithSuccessfulDataUpdated } from '../../../utils/notificationsUtils';
import { getSuccessMessages } from '../../../utils/getSuccessMessages';
import { onFormFinishWithPassedValidation } from '../../../utils/onSubmitForm';

export const onFinish = (scope, data, category, actions) => {
  const formActions = {
    add: TicketingSettingsService.addCategory,
    edit: TicketingSettingsService.updateCategory
  };

  if (scope === 'remove') {
    TicketingSettingsService.removeCategory(category.categoryId).then(res => {
      if (!actions) {
        return;
      }

      if (actions.dispatch && typeof actions.dispatch === 'function') {
        actions.dispatch(setMethodUpdate(true));
      }
      if (actions.toggleModal && typeof actions.toggleModal === 'function') {
        actions.toggleModal();
      }
      if (res?.data?.statusCode === 200) {
        notifyWithSuccessfulDataUpdated(
          '',
          getSuccessMessages.success_removed_category
        );
      }
    });
    return;
  }

  const serializeCategoryKey = (name: string) => {
    const words = name.split(' ')
    return 'Category_' + words.join('_')
  }

  data = category ? { categoryId: category.categoryId, key: category.key, ...data } : {key: serializeCategoryKey(data.name) , ...data };
  const getMessages =
    scope === 'add'
      ? getSuccessMessages.success_added_category
      : getSuccessMessages.success_edited_category;

  onFormFinishWithPassedValidation(
    formActions,
    scope,
    data,
    actions,
    getMessages
  );
};

export const onFinishSubCategories = (
  scope,
  data,
  categoryId,
  subCategory,
  actions
) => {
  const formActions = {
    add: TicketingSettingsService.addSubCategory,
    edit: TicketingSettingsService.updateSubCategory
  };

  if (scope === 'remove') {
    TicketingSettingsService.removeSubCategory(subCategory.subCategoryId).then(res => {
      if (!actions) {
        return;
      }

      if (actions.dispatch && typeof actions.dispatch === 'function') {
        actions.dispatch(setMethodUpdate(true));
      }
      if (actions.toggleModal && typeof actions.toggleModal === 'function') {
        actions.toggleModal();
      }
      if (res?.data?.statusCode === 200) {
        notifyWithSuccessfulDataUpdated(
          '',
          getSuccessMessages.success_removed_subcategory
        );
      }
    });
    return;
  }
  const serializeSubCategoryKey = (name: string) => {
    const words = name.split(' ')
    return 'SubCategory_' + words.join('_')
  }

  data = subCategory
    ? { subCategoryId: subCategory.subCategoryId, key: subCategory.key, categoryId: categoryId, ...data }
    : categoryId
      ? { categoryId: categoryId, key: serializeSubCategoryKey(data.name), ...data }
      : data;
  const getMessages =
    scope === 'add'
      ? getSuccessMessages.success_added_subcategory
      : getSuccessMessages.success_edited_subcategory;

  onFormFinishWithPassedValidation(
    formActions,
    scope,
    data,
    actions,
    getMessages
  );
};
