import { useIntlContext } from 'AppProvider/ConfigProviderSettings';

export const TimeComponent = () => {
  //time dynamically for each minute
  let workingTimeDynamically = new Date();

  const { locale } = useIntlContext();

  const dateOptions: any = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };

  const timeOptions: any = {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  };

  const dateString = workingTimeDynamically.toLocaleDateString(
    locale,
    dateOptions
  );
  const timeString = workingTimeDynamically.toLocaleTimeString([], timeOptions);

  const formattedDate =
    dateString.charAt(0).toUpperCase() + dateString.slice(1);

  return <div>{`${formattedDate} | ${timeString}`}</div>;
};

export default TimeComponent;
