export const TimeOffDenyRevocationIcon = ({ stroke }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.125 13H2.49999C1.67157 13 0.999995 12.3284 1 11.5L1.00006 2.49999C1.00006 1.67157 1.67163 1 2.50006 1H9.25023C10.0787 1 10.7502 1.67157 10.7502 2.5V7.375M10.7502 13L9.25024 11.5M9.25024 11.5L7.75024 10M9.25024 11.5L7.75024 13M9.25024 11.5L10.7502 10M3.62523 4H8.12524M3.62523 6.25H8.12524M3.62523 8.5H5.87524"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};