export enum CategoryTypes {}

export type TICKET_TYPES = 'incident' | 'service';

export enum UrgencyTypes {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical',
}

export const UrgencyValues = [
  {
    value: 1,
    name: 'Low',
  },
  {
    value: 2,
    name: 'Medium',
  },
  {
    value: 3,
    name: 'High',
  },
  {
    value: 4,
    name: 'Critical',
  },
];
