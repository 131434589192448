export const getDateTimeDifferenceInMinutes = (
  startDate: Date,
  endDate: Date
) => {
  const msInMinutes = 60 * 1000;
  return isNaN(
    Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / msInMinutes)
  )
    ? 0
    : Math.round(
        Math.abs(endDate.getTime() - startDate.getTime()) / msInMinutes
      );
};

export const getDateTimeDifferenceInDays = (startDate: Date, endDate: Date) => {
  const msInDay = 60 * 1000 * 60 * 24;
  return Math.round(
    Math.abs(endDate.getTime() - startDate.getTime()) / msInDay
  );
};

export const convertMinutesToHoursAndMinutes = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;

  return { hours, minutes };
};

export const getTimeWorkedOut = (startDate: Date, endDate: Date) => {
  const minutesWorked =
    startDate && endDate
      ? getDateTimeDifferenceInMinutes(startDate, endDate)
      : null;

  const { hours, minutes } = minutesWorked
    ? convertMinutesToHoursAndMinutes(minutesWorked)
    : { hours: 0, minutes: 0 };
  return { hours, minutes };
};

export const parseHourMinutes = aproxHours => {
  if (!aproxHours) {
    return '0h 0m';
  }
  if (aproxHours.toString().length === 1) {
    return `${aproxHours}h 0m`;
  } else {
    let hoursSeparate = aproxHours.toString().split('.');
    let hour = hoursSeparate[0];
    if (hoursSeparate.length !== 2) {
      return `${hour}h 0m`;
    }
    let minute = Math.round(parseFloat('0.'.concat(hoursSeparate[1])) * 60);
    hour = Number(hour) + Math.floor(minute / 60);
    minute = minute % 60;

    return `${hour}h ${minute}m`;
  }
};
