import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { ArrowRight } from 'Icons/TimeManagementWidget/arrowRight';
import { Button, Col, Typography } from 'antd';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import { TimesheetComponentPlaceholder } from 'components/Placeholders';
import TableComponent from 'components/Table';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import {
  useMobileContext,
  useThemeContext
} from '../../../../AppProvider/ConfigProviderSettings';
import { getNextMonth, getPreviousMonth } from '../utils';
import { columnsTimeSheet } from './columnsTimeSheet';

import '../TimeSheetMigrated/TimeSheetRefactor.less';

export const TimeSheetTable = ({
  setDateOfMonth,
  dateOfMonth,
  setIndexColumnHovered,
  data,
  params,
  setPage,
  page,
  hookUseStateActions,
  months,
  status
}) => {
  const { Title } = Typography;
  const { Text } = Typography;
  const navigate = useNavigate();
  const { deviceHeight } = useMobileContext();
  const { theme } = useThemeContext();

  type EditableTableProps = Parameters<typeof TableComponent>[0];

  type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

  const prevMonth = () => {
    const prevDate = getPreviousMonth(dateOfMonth.date);
    const month = dayjs(prevDate).format('MMMM');
    const year = dayjs(prevDate).year();

    setDateOfMonth({
      date: prevDate,
      month: month,
      year: year
    });

    triggerMethodUpdate();
  };

  const nextMonth = () => {
    const nextDate = getNextMonth(dateOfMonth.date);
    const month = dayjs(nextDate).format('MMMM');
    const year = dayjs(nextDate).year();

    setDateOfMonth({
      date: nextDate,
      month: month,
      year: year
    });

    triggerMethodUpdate();
  };

  const removeHover = () => {
    return {
      onMouseLeave: () => {
        setIndexColumnHovered(-1);
      }
    };
  };

  const dispatch = useDispatch();

  const triggerMethodUpdate = () => {
    dispatch(setMethodUpdate(true));
  };
  const displayAwaitingRequests = () => {
    const numberOfMonth = dayjs(dateOfMonth.date).month().toString();
    const searchParams = {
      statusFilter: 'AwaitingDecision',
      requestType: 'AllTypes',
      month: numberOfMonth,
      year: dateOfMonth.year
    };
    const pathname = `../../timeManagement/colleaguesRequests`;
    navigate({
      pathname,
      search: createSearchParams(searchParams).toString()
    });
  };

  const month = months[dayjs(dateOfMonth.date).get('month')];

  return (
    <TableLayoutPage
      title={<FormattedMessage id="table_TimesheetTitle_timesheet" />}
      displayToggle
      style={{
        minHeight: `calc(${deviceHeight}px - 185px)`
      }}
      childrenButton={
        <>
          <Col>
            <div className="flex-center-container">
              <Button
                type="text"
                icon={<ArrowLeft />}
                onClick={prevMonth}
                className="arrows-calendar"
              />
              <Title
                level={3}
                className={`align-padding-title ${theme} month-title`}
              >
                {month}, {dateOfMonth.year}
              </Title>
              <Button
                type="text"
                icon={<ArrowRight />}
                onClick={nextMonth}
                className="arrows-calendar"
              />
            </div>
          </Col>
          <Col flex="1 0 0">
            <div className="flex-center-container">
              {params.openTORCount && !!params.openTORCount ? (
                <Text
                  className="text-bold-normal text-align-end pointer"
                  onClick={displayAwaitingRequests}
                >
                  <FormattedMessage
                    id={
                      params.openTORCount === 1
                        ? 'TimeSheetSingleAttentionText'
                        : params.openTORCount > 1
                          ? 'TimeSheetAttentionText'
                          : undefined
                    }
                    values={{
                      requestCount: params.openTORCount ?? '10',
                      br: <br />
                    }}
                  />
                </Text>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </>
      }
    >
      <ComponentWithStatus
        status={status}
        Placeholder={
          <TimesheetComponentPlaceholder
            height={`calc(${deviceHeight}px - 185px)`}
          />
        }
      >
        <TableComponent
          className="timeSheet"
          rowClassName="timeSheet"
          rowKey="employeeId"
          dataSource={data}
          scroll={{
            x: 'calc(100vw - 196px)',
            y: `calc(${deviceHeight}px - 350px)`
          }}
          style={{
            minHeight: `calc(${deviceHeight}px - 22.7em)`
          }}
          tableSize="small"
          columns={columnsTimeSheet as ColumnTypes}
          data={data}
          params={params}
          onRow={removeHover}
          setPage={setPage}
          page={page}
          hookUseStateActions={hookUseStateActions}
        />
      </ComponentWithStatus>
    </TableLayoutPage>
  );
};
