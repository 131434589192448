export const ArrowRight = (props) => (
  <span role='img' aria-label='wfh' className='anticon anticon-wfh' {...props}>
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ verticalAlign: 'bottom' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1967 10.2612C13.6011 10.6692 13.6011 11.3308 13.1967 11.7388L10.2678 14.694C9.86337 15.102 9.20772 15.102 8.8033 14.694C8.39889 14.2859 8.39891 13.6244 8.8033 13.2164L11 11L8.8033 8.78361C8.39889 8.37557 8.39891 7.71404 8.8033 7.30602C9.2077 6.898 9.86335 6.89798 10.2678 7.30602L13.1967 10.2612Z'
        fill='currentColor'
      />
    </svg>
  </span>
);
