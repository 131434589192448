export type INotification = {
  createdDate: Date;
  employeeId: number;
  message: string;
  title: string;
  type: NotificationType;
  actionType: NotificationActionType;
  notificationId: number;
  readStatus: boolean;
  requestId?: number;
  ticketId?: number;
};

export enum NotificationType {
  None,
  Vacation,
  Unpaid,
  WorkFromHome,
  Medical,
  Paternity,
  Maternity,
  Childcare,
  PersonalLeave,
  LeaveOffsetting,
  Overtime,
  BloodDonation,
  Bereavement,
  Marriage,
}

export enum NotificationActionType {
  None,
  AwaitingAction,
  InformingAction,
  InformingActionWithRedirect
}
