import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Typography } from 'antd';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import dayjs from 'dayjs';

const { Text } = Typography;

export const ModalToolTip = eventsData => {
  const { theme } = useThemeContext();
  const events = eventsData.eventsData;
  return (
    <>
      {events.length > 0
        ? events.map(event => {
            const eventStartDate = new Date(event.startDate + 'Z');
            const eventEndDate = new Date(event.endDate + 'Z');
            return (
              <div className={`tooltip_window ${theme}`} key={event.requestId}>
                <Text
                  className={`text-medium-normal text-color-tooltip text-align-center ${theme}`}
                >
                  {event.eventType === 'Holiday' ? (
                    <>
                      <SafeFormattedMessage id={event.eventType} /> -{' '}
                      <SafeFormattedMessage id={event.eventDescription} />
                    </>
                  ) : (
                    <>
                      {' '}
                      <SafeFormattedMessage id={event.eventType} /> -{' '}
                      <SafeFormattedMessage id={event.requestStatus} />{' '}
                    </>
                  )}
                </Text>
                <Text
                  className={`text-medium-normal text-color-tooltip text-align-center ${theme}`}
                >
                  {event.eventType === 'PersonalLeave' ? (
                    <>
                      {dayjs(eventStartDate).format('DD/MM/YYYY')}
                      <br />
                      {Math.floor(
                        dayjs(eventEndDate).diff(
                          dayjs(eventStartDate),
                          'minute'
                        ) / 60
                      )}
                      h{' '}
                      {Math.floor(
                        dayjs(eventEndDate).diff(
                          dayjs(eventStartDate),
                          'minute'
                        ) % 60
                      )}
                      m, {dayjs(eventStartDate).format('HH:mm')} -{' '}
                      {dayjs(eventEndDate).format('HH:mm')}
                    </>
                  ) : (
                    <>
                      {eventStartDate === eventEndDate ? (
                        <> {dayjs(eventStartDate).format('DD/MM/YYYY')} </>
                      ) : (
                        <>
                          {' '}
                          {dayjs(eventStartDate).format('DD/MM/YYYY')} -{' '}
                          {dayjs(eventEndDate).format('DD/MM/YYYY')}{' '}
                        </>
                      )}
                    </>
                  )}
                </Text>
              </div>
            );
          })
        : ''}
    </>
  );
};
