import React from 'react';
import OptionButton from '../OptionButton';
import useOutsideClick from 'hooks/useOutsideClick.hook';
import { ArrowLeft } from 'Icons/Documents/arrowLeft';

import './SingleSelectDropdown.less';
import {
  useIntlContext,
  useMobileContext
} from 'AppProvider/ConfigProviderSettings';
import { ArrowUnion } from '../../../Icons/General/arrowUnion';

type OptionTypeProps<T> = {
  value: T;
  label: T;
};

type SingleSelectDropdownProps<T> = {
  value: T;
  defaultValue?: T;
  onChange?: (option: T) => void;

  className?: string;
  style?: React.CSSProperties;
  theme?: 'light' | 'dark';

  label?: React.ReactNode;
  preffixText?: React.ReactNode;
  placeholder?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  preffixTextSeparator?: string;

  options?: OptionTypeProps<T>[];
  optionsToShow?: number | null;

  isDropped?: boolean | null;
  dropdownAlign?: { offset: [number, number] };
  width?:
    | 'auto'
    | 'max-content'
    | 'min-content'
    | 'fit-content'
    | 'inherit'
    | 'initial'
    | 'unset';
  onDropChange?: (isDropped: boolean) => void;

  children?: React.ReactElement;
  showSuffixIcon?: boolean;
  useLabelForSelection?: boolean;
};

const SingleSelectDropdown = <T extends string>(
  {
    value = null,
    defaultValue = null,
    className = '',
    style,
    theme = 'light',
    label = '',
    preffixText = null,
    placeholder = null,
    suffixIcon = null,
    preffixTextSeparator,
    options = [],
    optionsToShow = null,
    isDropped = null,
    onChange,
    onDropChange,
    children,
    dropdownAlign = { offset: [0, 0] },
    width = 'max-content',
    showSuffixIcon = true,
    useLabelForSelection = false
  }: SingleSelectDropdownProps<T>,
  dropdownRef: React.ForwardedRef<HTMLFieldSetElement>
) => {
  const memoizedOptions = React.useMemo(() => options, [options]);
  const { isMobile } = useMobileContext();
  const [selectedOption, setSelectedOption] = React.useState<T | null>(
    value ?? defaultValue
  );
  const [_isDropped, setIsDropped] = React.useState<boolean>(false);

  const _dropdownRef = React.useRef<HTMLFieldSetElement | null>(null);
  useOutsideClick(_dropdownRef, () => setIsDropped(false));

  React.useEffect(() => {
    setSelectedOption(value ?? defaultValue);
  }, [value, defaultValue]);

  const onOptionChange = React.useCallback(
    (selectedOption: OptionTypeProps<T>) => {
      const selectedValue = useLabelForSelection
        ? selectedOption.label
        : selectedOption.value;

      if (selectedValue) {
        onChange && onChange(selectedValue);
        setSelectedOption(selectedValue);
      }
    },
    [memoizedOptions, onChange]
  );

  const { locale } = useIntlContext();
  const headerRef = React.useRef<HTMLButtonElement | null>(null);
  const [headerWidth, setHeaderWidth] = React.useState<number>(0);

  React.useEffect(() => {
    if (headerRef?.current) {
      setHeaderWidth(headerRef.current.offsetParent.clientWidth);
    }
  }, [selectedOption, locale]);

  const renderFieldset = (
    ref:
      | React.RefObject<HTMLFieldSetElement>
      | React.ForwardedRef<HTMLFieldSetElement>,
    ddButton: React.ReactNode,
    ddContent: React.ReactNode
  ) => (
    <fieldset
      className={`dd-wrapper ${className} ${theme}`}
      ref={ref}
      style={style}
    >
      {ddButton}
      {ddContent}
    </fieldset>
  );

  const renderDropdownButton = (isDropped: boolean, onChange: () => void) => {
    // const displayValue = selectedOption ?? label;
    // const displayPlaceholder = displayValue === null ? placeholder : null;
    const header = label || `${preffixText}: ${selectedOption}`.trim();
    return (
      <button
        aria-expanded={isDropped}
        aria-controls="dd-content"
        className="dd-header"
        ref={headerRef}
        type="button"
        onClick={onChange}
      >
        <span className={`dd-header__text ${isDropped ? 'active' : ''}`}>
          {header}
        </span>
        {showSuffixIcon ? (
          <ArrowUnion
            className={`dd-header__arrow ${isDropped ? 'arrow--show' : ''}`}
          />
        ) : null}
      </button>
    );
  };

  const renderContent = (isDropped: boolean, ddOptions: React.ReactNode) => {
    return isDropped ? (
      <div
        id="dd-content"
        className="dd-content"
        style={{
          ...{ top: `${dropdownAlign.offset[0]}px` },
          ...{ left: `${dropdownAlign.offset[1]}px` },
          ...{
            right: `${dropdownAlign.offset[1] === null && isMobile ? '-3px' : null}`
          },
          ...{
            width: width,
            minWidth: `${headerWidth + Math.abs(dropdownAlign.offset[1])}px`
          }
        }}
      >
        <ul
          className="dd__list"
          style={{
            ...{
              width: width,
              minWidth: `${headerWidth + Math.abs(dropdownAlign.offset[1])}px`
            }
          }}
        >
          {ddOptions}
        </ul>
      </div>
    ) : null;
  };

  const renderOptions = (isDropped: boolean) => {
    const optionsContent = memoizedOptions.map(option => {
      const selectedValue = useLabelForSelection ? option.label : option.value;
      return (
        <li
          className={`dd__item ${
            selectedValue === selectedOption ? 'dd__item--selected' : ''
          }`}
          key={option.value}
        >
          <OptionButton
            value={option.label}
            type="radio"
            checked={selectedValue === selectedOption}
            showShape={false}
            theme={theme}
            onChange={() => {
              onOptionChange(option);
              setIsDropped(false);
            }}
          />
        </li>
      );
    });

    return renderContent(isDropped, optionsContent);
  };

  const renderChildren = (isDropped: boolean) => {
    const childrenContent = React.Children.map(children, child => {
      return React.isValidElement(child) ? React.cloneElement(child) : child;
    });

    return renderContent(isDropped, childrenContent);
  };

  return (
    <>
      {memoizedOptions?.length
        ? renderFieldset(
            _dropdownRef,
            renderDropdownButton(_isDropped, () => setIsDropped(!_isDropped)),
            renderOptions(_isDropped)
          )
        : React.Children.count(children) > 0
          ? renderFieldset(
              dropdownRef,
              renderDropdownButton(
                isDropped,
                () => onDropChange && onDropChange(!isDropped)
              ),
              renderChildren(isDropped)
            )
          : null}
    </>
  );
};

export default React.forwardRef<
  HTMLFieldSetElement,
  SingleSelectDropdownProps<string>
>(SingleSelectDropdown);
