import { Typography, Button } from 'antd';

import { ReactElement, memo, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmployeeDocumentService } from 'services';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import {
  IDocumentFields,
  formattedDateSingular,
} from './DocumentShortInfo/utils';

export const DATE_FIELDS_ARR = [
  'birthDate',
  'issueDate',
  'expiryDate',
  'studyPeriodFrom',
  'studyPeriodTo',
  'signedOn',
  'validFrom',
  'validTo',
];
const FIELDS_TO_IGNORE = ['employeeId', 'employeeDocumentId'];

export const DocumentFields = memo<{ document: IDocumentFields }>(
  ({ document }): ReactElement => {
    const { theme } = useThemeContext();
    const [documentAttachmentUrl, setDocumentAttachmentUrl] = useState(null);

    // get document attachment URL on mount
    useEffect(() => {
      EmployeeDocumentService.getDocumentAttachmentUrl({
        parameters: {
          documentId: document.employeeDocumentId,
        },
      }).then((res) => {
        if (res.data) {
          setDocumentAttachmentUrl(res.data);
        }
      });
    }, []);
    return (
      <>
        {Object.entries(document).map(([key, value], index) => {
          return !FIELDS_TO_IGNORE.includes(key) ? (
            <div className="documentItemContainer" key={index}>
              {key === 'fileLocation' ? (
                <Button
                  className={`viewAttachment-button ${theme}`}
                  type="default"
                  disabled={!documentAttachmentUrl}
                  onClick={() => {
                    window.location.href =
                      documentAttachmentUrl as unknown as string;
                  }}
                >
                  {documentAttachmentUrl ? (
                    <FormattedMessage id="attachment" />
                  ) : (
                    <FormattedMessage id="NoAttachments" />
                  )}
                </Button>
              ) : (
                <>
                  <div>
                    <Typography className="documentItemLabel">
                      <FormattedMessage id={key} />
                    </Typography>
                  </div>
                  <div>
                    <Typography className="documentItemValue">
                      {(() => {
                        if (
                          !value ||
                          (DATE_FIELDS_ARR.includes(key) &&
                            !dayjs(value).isValid())
                        )
                          return '-';

                        if (DATE_FIELDS_ARR.includes(key))
                          return formattedDateSingular(value);

                        return <SafeFormattedMessage id={value} />;
                      })()}
                    </Typography>
                  </div>
                </>
              )}
            </div>
          ) : null;
        })}
      </>
    );
  }
);
