import { newAggregatedColumns } from './TimeSheetTable/additionalColumns';
import { IRequestError } from 'services/types';
import { downloadFile } from 'utils/downloadFiles';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import { Buffer } from 'buffer';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { FormattedMessage } from 'react-intl';

dayjs.extend(advancedFormat);

export const headerTranslations = {
  Holiday: <FormattedMessage id="TimeSheetHeaderColumns_Holidays" />,

  Medical: <FormattedMessage id="TimeSheetHeaderColumns_Medical_leave" />,

  TotalHoursWorked: (
    <FormattedMessage id="TimeSheetHeaderColumns_Worked_hours" />
  ),

  Unpaid: <FormattedMessage id="TimeSheetHeaderColumns_Unpaid_leave" />,
  Vacation: <FormattedMessage id="TimeSheetHeaderColumns_Paid_leave" />,
  WeekEnd: <FormattedMessage id="TimeSheetHeaderColumns_Rest_days" />,
  WorkingDays: <FormattedMessage id="TimeSheetHeaderColumns_Worked_days" />,

  AllHoursWorked: 'Total ore lucrate',
  WorkingDaysHoursWorked: 'Total ore zi lucratoare',
  WeekendHoursWorked: 'Total ore weekend',
  HolidayHoursWorked: 'Total ore sarbatoare',
  TotalHoursAdditional: 'Ore suplimentare',
  PersonalLeave: 'Invoiri',
  Paternity: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Paternity_leave" />
  ),
  Maternity: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Maternity_leave" />
  ),
  Childcare: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Childcare_leave" />
  ),
  BusinessTrip: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Business_trip" />
  ),
  Absence: <FormattedMessage id="TimeSheetColumnFilterOptions_Absence" />,
  DayOffWithSalary: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Days_off_with_salary_pay" />
  ),
  StudyLeave: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Study_leave" />
  ),
  SuspendedContract: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Employment_contract_suspension" />
  ),
  TechnicalUnemployment: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Technical_unemployment" />
  ),
  Stationary: (
    <FormattedMessage id="TimeSheetColumnFilterOptions_Work_on_hold" />
  ),
  Detachment: <FormattedMessage id="TimeSheetColumnFilterOptions_Detachment" />
};

export const headerTranslationsInitialColumns = {
  WorkingDays: <FormattedMessage id="RequestTimeOff" />,
  TotalHoursWorked: 'Ore lucrate',
  WeekEnd: 'Zile de repaus',
  Holiday: 'Zile de sărbătoare',
  Vacation: 'Concediu plătit',
  Unpaid: 'Concediu neplătit',
  Medical: 'Concediu medical'
};

export const getEventsDay = (
  event,
  requestStatus,
  eventDescription,
  hourMinutes,
  startDate,
  endDate,
  intl
) => {
  let startDateByTimezone = dayjs.tz(dayjs.utc(startDate), dayjs.tz.guess());
  let endDateByTimezone = dayjs.tz(dayjs.utc(endDate), dayjs.tz.guess());
  let formattedStartDate = startDateByTimezone.format('DD-MM-YYYY');
  let formattedEndDate = endDateByTimezone.format('DD-MM-YYYY');
  let timeFormattedStartDate = startDateByTimezone.format('HH:mm');
  let timeFormattedEndDate = endDateByTimezone.format('HH:mm');
  let TimeSheetTimeOffConflictVariable = dayjs(startDate).isSame(endDate, 'day')
    ? requestStatus === 'Unfinished'
      ? 'TimeSheetTimeOffUnfinishedConflictTextOneDay'
      : 'TimeSheetTimeOffConflictTextOneDay'
    : requestStatus === 'Unfinished'
      ? 'TimeSheetTimeOffUnfinishedConflictText'
      : 'TimeSheetTimeOffConflictText';

  return {
    Vacation: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    Unpaid: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),

        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    WorkFromHome: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),

        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    ForgotBadge: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),

        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),

    Medical: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),

        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    Maternity: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),

        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    Paternity: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),

        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    Childcare: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),

        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),

    PersonalLeave: intl.formatMessage(
      { id: 'TimeSheetTimeOffHoursConflict', defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        totalTime: hourMinutes,
        startTime: timeFormattedStartDate,
        endTime: timeFormattedEndDate
      }
    ),

    Offsetting: intl.formatMessage(
      { id: 'TimeSheetTimeOffHoursConflict', defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        totalTime: hourMinutes,
        startTime: timeFormattedStartDate,
        endTime: timeFormattedEndDate
      }
    ),

    Offset: intl.formatMessage(
      { id: 'TimeSheetOffsetConflictText', defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        time: hourMinutes,
        eventTime: formattedStartDate
      }
    ),
    Overtime: intl.formatMessage(
      { id: 'TimeSheetTimeOffHoursConflict', defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        totalTime: hourMinutes,
        startTime: timeFormattedStartDate,
        endTime: timeFormattedEndDate
      }
    ),
    Bereavement: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    BloodDonation: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    Marriage: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    MilitaryTraining: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    TwoOrMoreChildren: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    BusinessTrip: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    FirstDayOfSchool: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    LastDayOfSchool: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    ChildcareLeaveUnpaid: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    BirthOfAChild: intl.formatMessage(
      { id: TimeSheetTimeOffConflictVariable, defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: event || 'EmptyIdText',
          defaultMessage: ' '
        }),
        startDate: formattedStartDate,
        endDate: dayjs(startDate).isSame(endDate, 'day')
          ? ''
          : '' + formattedEndDate
      }
    ),
    WeekEnd: intl.formatMessage({
      id: 'TimeSheetWeekendConflictText',
      defaultMessage: ' '
    }),
    TerminatedContract: intl.formatMessage(
      { id: 'TimeSheetTerminatedContractConflictText', defaultMessage: ' ' },
      { time: formattedStartDate }
    ),
    EmployeeNotEmployed: intl.formatMessage(
      { id: 'TimeSheetEmployeeNotEmployedConflictText', defaultMessage: ' ' },
      { time: formattedStartDate }
    ),
    Holiday: intl.formatMessage(
      { id: 'TimeSheetHolidayConflictText', defaultMessage: ' ' },
      {
        event: intl.formatMessage({
          id: eventDescription.trim().replace(/\s/g, '') || 'EmptyIdText',
          defaultMessage: eventDescription
        })
      }
    ),
    Absence: intl.formatMessage({
      id: 'TimeSheetAbscenceConflictText',
      defaultMessage: ' '
    }),
    //Catch conflicts error when has trouble with Leave offseting logic
    OffsetNoMinimumWorkingHours: intl.formatMessage({
      id: 'TimeSheetLOdoesNotMeetMinimumWorkingHours',
      defaultMessage: ' '
    }),
    OffsetNotDuringClockedHours:
      eventDescription === 'OffsetClockedHoursConditionNotVerified'
        ? intl.formatMessage({
            id: 'TimeSheetNotAtOfficeAndOfficeHoursNotIdentified',
            defaultMessage: ' '
          })
        : intl.formatMessage({
            id: 'TimeSheetNotAtOffice',
            defaultMessage: ' '
          }),
    OffsetDuringWorkSchedule: intl.formatMessage({
      id: 'TimeSheetLODurringWorkingHours',
      defaultMessage: ' '
    }),
    //--- End Catch conflicts error when has trouble with Leave offseting logic
    ClockInOutDoor:
      eventDescription === 'EmployeeNoExit' ||
      eventDescription === 'EmployeeNoEntry'
        ? `${
            eventDescription === 'EmployeeNoExit'
              ? intl.formatMessage(
                  { id: 'TimeSheetEmployeeNoExitText', defaultMessage: ' ' },
                  { time: timeFormattedStartDate }
                )
              : intl.formatMessage(
                  { id: 'TimeSheetEmployeeNoEntryText', defaultMessage: ' ' },
                  { time: timeFormattedEndDate }
                )
          }.`
        : intl.formatMessage(
            { id: 'TimeSheetEmployeeWasInOfficeText', defaultMessage: ' ' },
            {
              time: hourMinutes,
              startTime: timeFormattedStartDate,
              endTime: timeFormattedEndDate
            }
          ),
    ClockInOutSystem: intl.formatMessage(
      { id: 'TimeSheetClockInOutSystemConflictText', defaultMessage: ' ' },
      {
        time: hourMinutes,
        startTime: timeFormattedStartDate,
        endTime: timeFormattedEndDate
      }
    ),
    ClockInOutEmployee: intl.formatMessage(
      { id: 'TImeSheetClockInOutEmployeeConflictText', defaultMessage: ' ' },
      { time: hourMinutes }
    ),
    ClockInOutHR: intl.formatMessage(
      { id: 'TimeSheetClockInOutHRConflictText', defaultMessage: ' ' },
      { time: hourMinutes }
    ),
    RevokedDeniedWorkFromHome: `${intl.formatMessage({
      id: 'TimeSheetWfhConflictText',
      defaultMessage: ' '
    })} ${
      eventDescription === 'WFHRequestRevoked'
        ? intl.formatMessage({
            id: 'TimeSheetRevokedWorkFromHomeConflictText',
            defaultMessage: ' '
          })
        : intl.formatMessage({
            id: 'TimeSheetDeniedWorkFromHomeConflictText',
            defaultMessage: ' '
          })
    }.`,
    DefaultSystemValue: intl.formatMessage(
      { id: 'TimeSheetDefaultHoursText', defaultMessage: ' ' },
      { time: hourMinutes }
    ),
    MinimumWorkedRangeRequirement: intl.formatMessage({
      id: 'TimeSheetMinimumWorkedRangeRequirement',
      defaultMessage: ' '
    }),
    None: intl.formatMessage(
      { id: 'TimeSheetNoneConflictText', defaultMessage: ' ' },
      { time: hourMinutes }
    )
  };
};

export const addingAdditionalColumns = (
  currentColumns,
  newColumns = [{}],
  countryCode: string
) => {
  const additionals = newAggregatedColumns(currentColumns, countryCode);

  return newColumns.map(newValues => {
    return additionals
      .filter(allAdditionalColumns => newValues === allAdditionalColumns.key)
      .reduce((acc, cur) => ({ ...acc, ...cur }));
  });
};

export const aproximateHour = (hours, forTabelAproximate) => {
  if (hours.length === 1) {
    return hours;
  }

  let hoursSeparate = hours.toString().split('.');
  let hour = hoursSeparate[0];
  let parseMinutes = hoursSeparate[1];
  let minutesString = Number(60 * parseFloat(`0.${parseMinutes}`));
  let minutes = Math.round(minutesString);
  let minutesTabel = parseInt(((minutes / 60) * 100).toString());
  let hourMinutes = `${hour}.${minutes}`;
  let hourMinutesTabel = `${hour}.${minutesTabel}`;
  return forTabelAproximate
    ? Math.round(parseFloat(hourMinutesTabel) * 10) / 10
    : hourMinutes;
};

export const months = dayjs.months();

export const getPreviousMonth = (date = dayjs()) => {
  const prevMonth = dayjs(date).subtract(1, 'month');
  const firstDay = 1;
  return prevMonth.set('date', firstDay).toDate();
};

export const getNextMonth = (date = dayjs()) => {
  return dayjs(date).add(1, 'month').set('date', 1).toDate();
};

export const getTimeSheetFileName = headers => {
  var filename = '';
  var disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return Buffer.from(filename, 'base64');
};
export const downloadTimeSheetFile = (
  templateRequest: Promise<{
    data: unknown;
    error: IRequestError;
    headers?: unknown;
  }>
) =>
  templateRequest.then(res => {
    if (!res.data || res.error) {
      return;
    }

    const byteArray = new Uint8Array(res.data as any);
    const blob = new Blob([byteArray]);
    const url = window.URL.createObjectURL(blob);

    downloadFile(url, getTimeSheetFileName(res.headers));
  });

export const allForConflict = {
  weekend: 'WeekEnd',
  workingFullTime: 'FullTime',
  absent: 'A',
  timeMaximAproximate: 7.5,
  timeMaxim: 8
};

export const getTeamsFilters = data => {
  const result = [];
  // Check if any employee has 'teams' property
  const hasTeams = data?.some(employee => employee.hasOwnProperty('teams'));

  if (data.length && hasTeams) {
    data
      .map((employee: IEmployee) => employee.teams)
      .map(employeeTeams => {
        if (Object.keys(employeeTeams).length > 0) {
          Object.entries(employeeTeams).map(([teamId, teamName]) => {
            if (!result.find(teamItem => teamItem.key === teamId)) {
              result.push({
                text: teamName,
                value: teamName,
                key: teamId
              });
            }
          });
        }
      });
  }
  return result;
};
export const getfilterDataConficts = employees =>
  groupByEmployeeDates(employees, 'employeeId');

const groupByEmployeeDates = (employees, property) =>
  employees.reduce((acc, obj) => {
    const key = obj[property];
    const daysWithConflicts = obj.timeSheetDays
      .filter(
        day =>
          day.conflictType === 'ConflictGeneral' ||
          day.conflictType === 'ConflictHours'
      )
      .map(dayWithConflict => dayWithConflict.dateOnly);

    return { ...acc, [`${key}`]: [...daysWithConflicts] };
  }, {});

//refactor new timesheet
export const getfilterDataConfictsNew = employees =>
  groupByEmployeeDatesNew(employees, 'id');

const groupByEmployeeDatesNew = (employees, property) =>
  employees.reduce((acc, obj) => {
    const key = obj[property];
    const daysWithConflicts = obj.days
      .filter(
        day =>
          day.conflictType === 'ConflictGeneral' ||
          day.conflictType === 'ConflictHours'
      )
      .map(dayWithConflict => dayWithConflict.dateOnly);

    return { ...acc, [`${key}`]: [...daysWithConflicts] };
  }, {});

export const filterDataForNextConflict = (
  employeeId,
  dateCurrent,
  refDataWithConflict
) => {
  const dataRef = refDataWithConflict;
  let nearestDate;

  let currentIndex = dataRef.indexOf(dateCurrent);
  if (currentIndex === -1) {
    // current date isn't conflict
    // next date with conflict
    dataRef.forEach(date => {
      let diff = dayjs(date).diff(dayjs(dateCurrent), 'days', true);
      if (diff > 0) {
        if (nearestDate || nearestDate === 0) {
          if (dayjs(date).diff(dayjs(nearestDate), 'days') < 0) {
            return (nearestDate = dataRef.indexOf(date));
          }
        } else {
          return (nearestDate = dataRef.indexOf(date));
        }
      }
    });
  }

  if (
    (currentIndex !== -1 && currentIndex + 1 < dataRef.length) ||
    (nearestDate !== -1 && nearestDate < dataRef.length)
  ) {
    // exists next conflict in current employee
    const indexOfDate =
      nearestDate || nearestDate === 0 ? nearestDate : currentIndex + 1;
    return {
      dateNext: dataRef[indexOfDate],
      currentEmployeeId: employeeId
    };
  } else if (dataRef.length && currentIndex !== 0) {
    // if exists dates wirch not parser
    // have to parse on start array with index 0
    return {
      dateNext: dataRef[0],
      currentEmployeeId: employeeId
    };
  }
  return {
    dateNext: null,
    currentEmployeeId: employeeId
  };
};

export const getDataFromDateWithConflict = (
  dateNext,
  employeeIdNext,
  dataInitial
) => {
  const employee = dataInitial.filter(
    employees => employees.employeeId === +employeeIdNext
  )[0];

  const employeeName = employee.employeeName;
  const employeeId = employee.employeeId;

  const dayWithConflict = employee.timeSheetDays.filter(
    day => day.dateOnly === dateNext
  );
  return {
    dayWithConflictNext: dayWithConflict,
    employeeNameNext: employeeName,
    employeeNextId: employeeId
  };
};
//refactor timesheet new
export const getDataFromDateWithConflictNew = (
  dateNext,
  employeeIdNext,
  dataInitial
) => {
  const employee = dataInitial.filter(
    employees => employees.id === +employeeIdNext
  )[0];

  const employeeName = employee.name;
  const employeeId = employee.id;

  const dayWithConflict = employee.days.filter(
    day => day.dateOnly === dateNext
  );
  return {
    dayWithConflictNext: dayWithConflict,
    employeeNameNext: employeeName,
    employeeNextId: employeeId
  };
};

export const removeDataResolved = (employeeId, date, refData) => {
  if (refData[employeeId]) {
    refData[employeeId] = refData[employeeId].filter(item => item !== date);
    return refData;
  }
};

export const aggColsTableFilters = columnType => ({
  filters: [
    {
      text: '>0',
      value: 0
    }
  ],
  onFilter: (value, record) => {
    return record?.aggregatedColumns[columnType] > value;
  }
});

export const torColsTableFilters = columnType => ({
  filters: [
    {
      text: '>0',
      value: 0
    }
  ],
  onFilter: (value, record) => {
    return record?.torColumns[columnType] > value;
  }
});
