export const SoftwareListComponent = () => {
  return (
    <span role='img' aria-label='import' className='anticon anticon-import'>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z'
          stroke='currentColor'
          strokeWidth='1.5'
        />
        <path
          d='M8.5 7C8.5 7.82843 7.82843 8.5 7 8.5C6.17157 8.5 5.5 7.82843 5.5 7C5.5 6.17157 6.17157 5.5 7 5.5C7.82843 5.5 8.5 6.17157 8.5 7Z'
          stroke='currentColor'
          strokeWidth='1.5'
        />
      </svg>
    </span>
  );
};
