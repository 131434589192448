import { FC } from 'react';
import EmployeeForm from 'components/Employee/EmployeeForm';

type EditProfilePageProps = {
  scope: string;
  employeeId?: string | number | { searchParamName: string };
};

const EditProfilePage: FC<EditProfilePageProps> = (props) => (
  <EmployeeForm {...props} />
);

export default EditProfilePage;
