import { SearchOutlined } from '@ant-design/icons';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Input, Row } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ButtonCustom = BaseButton(ButtonSimple);

const ThemeWrapper: React.FC<any> = (props) => {
  const { children, className } = props;
  const { theme } = useThemeContext();

  return (
    <ButtonCustom {...props} className={`${className} ${theme}`}>
      {children}
    </ButtonCustom>
  );
};

export const getColumnSearchProps = (): ColumnType<any> => ({
  filterDropdown: ({
    selectedKeys,
    setSelectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{ padding: 16 }}>
      <Input
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(confirm)}
        style={{ marginBottom: 16, display: 'block' }}
      />
      <Row style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
        <ThemeWrapper
          className="text-bold-normal btn-primary-custom"
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ flex: 1 }}
        >
          <span>
            <FormattedMessage id="ColumnFilterSearchText" />
          </span>
        </ThemeWrapper>
        <ThemeWrapper
          className="text-bold-normal btn-default-custom"
          onClick={() => {
            clearFilters();
            handleReset(clearFilters);
            handleSearch(confirm);
          }}
          size="small"
          style={{ flex: 1 }}
        >
          <FormattedMessage id="ColumnFilterResetText" />
        </ThemeWrapper>
      </Row>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#BDA7E8' : undefined }} />
  ),
  render: (text) => text,
});

export const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
};

export const handleReset = (clearFilters: () => void) => {
    clearFilters();
};
