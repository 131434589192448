import { FileIconSVG } from 'Icons/Ticketing/fileIcon';
import { ImageIconSVG } from 'Icons/Ticketing/imageIcon';
import { TrashIconSVG } from 'Icons/Ticketing/trashIcon';
import { VideoIconSVG } from 'Icons/Ticketing/videoIcon';
import { Typography } from 'antd';

const UploadAttachmentItem = ({ file, onRemove }) => {
  const AttachmentIconTypesMap = {
    image: <ImageIconSVG />,
    video: <VideoIconSVG />,
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
      <div>
        <Typography.Text>
          {AttachmentIconTypesMap[file.type.split('/')[0]] ?? <FileIconSVG />}
        </Typography.Text>
      </div>
      <Typography.Text>{file.name}</Typography.Text>{' '}
      <div onClick={() => onRemove(file)}>
        <Typography.Text>
          <TrashIconSVG />
        </Typography.Text>
      </div>
    </div>
  );
};

export default UploadAttachmentItem;
