import { ReactNode, useEffect, useState } from 'react';
import { useIntlContext } from 'AppProvider/ConfigProviderSettings';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Collapse } from 'antd';
import { ROFlagComponent } from 'Icons/Language/ROflagComponent';
import { GBflagComponent } from 'Icons/Language/GBflagComponent';
import { CheckMark } from 'Icons/Language/СheckMark';
import { FormattedMessage } from 'react-intl';
import { SupportedLanguages } from 'utils/lang/SupportedLanguages';

import './LanguageSelect.less';
import { ArrowUnion } from 'Icons/General/arrowUnion';

type LanguageSelectOption = {
  id: number;
  title: string;
  value: string;
  icon: ReactNode;
  isSelected: boolean;
};

const options: LanguageSelectOption[] = [
  {
    id: 1,
    title: 'English',
    value: SupportedLanguages.En,
    icon: <GBflagComponent />,
    isSelected: null,
  },
  {
    id: 2,
    title: 'Română',
    value: SupportedLanguages.Ro,
    icon: <ROFlagComponent />,
    isSelected: null,
  },
];

const LanguageSelect = () => {
  const { theme } = useThemeContext();
  const { setLocale, locale } = useIntlContext();
  const { Panel } = Collapse;

  const [value, setValue] = useState('');

  useEffect(() => {
    if (locale === SupportedLanguages.Ro) {
      setValue('Română');
    } else if (locale === SupportedLanguages.En) {
      setValue('English');
    }
  }, [locale]);

  const handleClick = (value) => {
    setLocale(value);
    setValue(value);
    localStorage.setItem('defaultLanguage', value);
  };

  useEffect(() => {
    options.forEach((option) => {
      option.isSelected = option.value === locale;
    });
  });

  return (
    <div className={`language-select-wrapper ${theme}`}>
      <Collapse
        defaultActiveKey={['0']}
        ghost
        expandIcon={() => <ArrowUnion  />}
      >
        <Panel header={<FormattedMessage id="language" />} key={1}>
          <div className="language-select-inner">
            {options.map((option, index) => (
              <div
                key={index}
                className={`language-item ${
                  option.isSelected ? 'language-item-selected' : ''
                }`}
                onClick={() => handleClick(option.value)}
              >
                {option.isSelected && <CheckMark stroke="currentColor" />}
                <div className="language-item-name">
                  <p>{option.title}</p>
                  {option.icon}
                </div>
              </div>
            ))}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default LanguageSelect;
