import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ArrowLeft } from 'Icons/TimeManagementWidget/arrowLeft';
import { Collapse, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { EmployeeDocumentService } from 'services';
import DocumentItem from './DocumentItem';
import './employeeDocumentsListstyle.less';
import { DocumentShortInfo } from './DocumentShortInfo';
import ComponentWithStatus from 'components/ComponentWithStatus';
import useApiRequest from 'hooks/useApiRequest.hook';
import { PapersListWidgetPlaceholder } from 'components/Placeholders';
const { Panel } = Collapse;

export default function EmployeeDocumentsList({ employeeId, documentType }) {
  const { theme } = useThemeContext();

  const { data, status } = useApiRequest<any>(
    EmployeeDocumentService.getEmployeeDocumentsByType,
    {
      parameters: { employeeId, documentType },
    },
    [],
    {},
    null,
    [documentType, employeeId]
  );

  return (
    <ComponentWithStatus
      status={status}
      Placeholder={<PapersListWidgetPlaceholder />}
    >
      {data.length === 0 ? (
        <div className="no-records-wrapper">
          <Typography.Title level={4}>
            <FormattedMessage id="NoRecordsYet" />
          </Typography.Title>
        </div>
      ) : (
        <>
          <Collapse
            className={`EmployeeDocumentsList-container ${theme}`}
            expandIconPosition={'end'}
            expandIcon={({ isActive }) => (
              <ArrowLeft
                style={{ transform: `rotate(${isActive ? -90 : 0}deg)` }}
              />
            )}
          >
            {data?.map((doc, index) => {
              return (
                <Panel
                  key={doc?.employeeDocumentId}
                  header={
                    <>
                      <Row className="title">
                        <Typography>{doc?.documentName}</Typography>
                      </Row>
                      <Row className="short-details">
                        <DocumentShortInfo document={doc} />
                      </Row>
                    </>
                  }
                >
                  <DocumentItem document={doc} />
                </Panel>
              );
            })}
          </Collapse>
        </>
      )}
    </ComponentWithStatus>
  );
}
