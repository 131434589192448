import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import TeamGridStyles from 'components/Team/TeamGrid/TeamGrid.module.less';
import GridCard from 'components/UIComponents/GridCard';
import GridCardStyles from 'components/UIComponents/GridCard/GridCard.module.less';
import GridLayout from 'components/UILayout/GridLayout';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { TeamsService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { MyTeam } from 'services/providers/EmployeeProvider/types';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  rolesAllow
} from 'utils/userManagement';
import { getGradientIndex, teamCardBgColor } from '../../../utils/teamsGradients';

interface ITeam {
  department: string;
  description?: string;
  leadEmployeeId?: number;
  leadEmployeeName?: string;
  membersCount: number;
  name?: string;
  teamId: number;
}

interface ListGroupProps {
  label: React.ReactElement;
  data?: React.ReactNode | null;
  hideOnMobile: boolean | null;
}

const TeamGrid = ({ teamGridItems }) => {
  const { data } = useApiRequestHook<MyTeam[]>(
    TeamsService.getMyTeams,
    null,
    [],
    {},
    null,
    [],
    false,
    true
  );

  const { theme } = useThemeContext();
  const { isMobile, deviceHeight } = useMobileContext();
  const { user } = useAuth();

  const userRole = getCurrentUserRole(user);
  const teamsId: number[] =
    data && data.length && data.map(employee => employee.teamId);

  const getTeamAbbreviation = (teamName: string) => {
    if (!teamName) {
      return '';
    }

    const words = teamName.split(/[\s-]+/);
    const abbreviation = words
      .map(word => word && word[0].toUpperCase())
      .join('');
    return abbreviation.length > 1
      ? abbreviation
      : words.map(word => word && word.slice(0, 2).toUpperCase()).join('');
  };

  const getListGroupItems = (team: ITeam): ListGroupProps[] => [
    {
      label: <FormattedMessage id="TlPmText" />,
      data: team?.leadEmployeeName,
      hideOnMobile: null
    },
    {
      label: <FormattedMessage id="department" />,
      data: team.department.split(/(?=[A-Z])/).join(' '),
      hideOnMobile: isMobile
    },
    {
      label: <FormattedMessage id="members" />,
      data: team.membersCount,
      hideOnMobile: isMobile
    }
  ];

  return (
    <GridLayout
      style={{
        height: allowPermision(userRole, adminRoles)
          ? !isMobile
            ? `calc(${deviceHeight}px - 292px)`
            : `calc(${deviceHeight}px - 282px)`
          : `calc(${deviceHeight}px - 292px)`
      }} 
      className={`${TeamGridStyles['layout']} ${TeamGridStyles[theme]}`}
      gridItems={teamGridItems}
      GridItem={(team: ITeam) => (
        <GridCard
          key={`TeamGrid__${team.teamId}`}
          className={TeamGridStyles['card']}
          header={
            <div
              className={`${GridCardStyles['header']} ${TeamGridStyles['card__header']}`}
            >
              <div className={TeamGridStyles['card__avatar']}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  role="img"
                >
                  <circle cx="17" cy="17" r="16" className={`${TeamGridStyles[teamCardBgColor[getGradientIndex(team.name)]]}`}></circle>
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    alignmentBaseline="central"
                  >
                    {getTeamAbbreviation(team?.name)}
                  </text>
                </svg>
              </div>
              {(teamsId && teamsId.length && teamsId.includes(team.teamId)) ||
              allowPermision(userRole, rolesAllow) ? (
                <Link
                  to={`viewTeam?teamId=${team.teamId}`}
                  className={TeamGridStyles['card__link']}
                  onClick={() => {
                    localStorage.setItem('team-id', String(team.teamId));
                  }}
                >
                  <h3 className={TeamGridStyles['card__title']}>
                    {team?.name}
                  </h3>
                </Link>
              ) : (
                <div className={TeamGridStyles['card__link']}>
                  <h3 className={TeamGridStyles['card__title']}>{team?.name}</h3>
                </div>
              )}
            </div>
          }
          body={
            <ul className={GridCardStyles['list-group']}>
              {getListGroupItems(team).map((item, index) =>
                item.hideOnMobile === false || item.hideOnMobile === null ? (
                  <li
                    key={`TeamGrid__${index}`}
                    className={GridCardStyles['list-group__item']}
                  >
                    {item.label ? (
                      <h4 className={GridCardStyles['list-group__label']}>
                        {item.label}
                      </h4>
                    ) : null}
                    {item.data ? (
                      <h4 className={GridCardStyles['list-group__data']}>
                        {item.data}
                      </h4>
                    ) : null}
                  </li>
                ) : null
              )}
            </ul>
          }
        ></GridCard>
      )}
    ></GridLayout>
  );
};

export default TeamGrid;
