export const TeamAssignIcon = ({ stroke }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 12.9999C0.249953 13.4141 0.585702 13.75 0.999916 13.75C1.41413 13.75 1.74995 13.4143 1.75 13.0001L0.25 12.9999ZM1.00025 10.7497L1.75025 10.7498L1.00025 10.7497ZM7.375 9.25C7.78921 9.25 8.125 8.91421 8.125 8.5C8.125 8.08579 7.78921 7.75 7.375 7.75V9.25ZM13 10C13.4142 10 13.75 9.66421 13.75 9.25C13.75 8.83579 13.4142 8.5 13 8.5V10ZM9.625 8.5C9.21079 8.5 8.875 8.83579 8.875 9.25C8.875 9.66421 9.21079 10 9.625 10V8.5ZM10.5625 10.9374C10.5625 11.3516 10.8983 11.6874 11.3125 11.6874C11.7267 11.6874 12.0625 11.3516 12.0625 10.9374H10.5625ZM12.0625 7.56241C12.0625 7.1482 11.7267 6.81241 11.3125 6.81241C10.8983 6.81241 10.5625 7.1482 10.5625 7.56241H12.0625ZM10.7417 0.34577C10.3804 0.143236 9.9233 0.27196 9.72077 0.633281C9.51824 0.994603 9.64696 1.4517 10.0083 1.65423L10.7417 0.34577ZM10.0083 4.84577C9.64696 5.0483 9.51824 5.5054 9.72077 5.86672C9.9233 6.22804 10.3804 6.35676 10.7417 6.15423L10.0083 4.84577ZM7.75 3.25C7.75 4.07843 7.07843 4.75 6.25 4.75V6.25C7.90685 6.25 9.25 4.90685 9.25 3.25H7.75ZM6.25 4.75C5.42157 4.75 4.75 4.07843 4.75 3.25H3.25C3.25 4.90685 4.59314 6.25 6.25 6.25V4.75ZM4.75 3.25C4.75 2.42157 5.42157 1.75 6.25 1.75V0.25C4.59314 0.25 3.25 1.59315 3.25 3.25H4.75ZM6.25 1.75C7.07843 1.75 7.75 2.42157 7.75 3.25H9.25C9.25 1.59315 7.90685 0.25 6.25 0.25V1.75ZM1.75 13.0001L1.75025 10.7498L0.250253 10.7497L0.25 12.9999L1.75 13.0001ZM3.25025 7.75C1.59353 7.75 0.250439 9.09294 0.250253 10.7497L1.75025 10.7498C1.75035 9.92147 2.42189 9.25 3.25025 9.25V7.75ZM3.25025 9.25H7.375V7.75H3.25025V9.25ZM13 8.5H11.3125V10H13V8.5ZM11.3125 8.5H9.625V10H11.3125V8.5ZM12.0625 10.9374V9.25H10.5625V10.9374H12.0625ZM12.0625 9.25V7.56241H10.5625V9.25H12.0625ZM10.0083 1.65423C10.7545 2.07249 11.125 2.67363 11.125 3.25H12.625C12.625 1.98247 11.8158 0.947853 10.7417 0.34577L10.0083 1.65423ZM11.125 3.25C11.125 3.82637 10.7545 4.42751 10.0083 4.84577L10.7417 6.15423C11.8158 5.55215 12.625 4.51753 12.625 3.25H11.125Z"
        fill={stroke}
      />
    </svg>
  );
};
