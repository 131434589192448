import { usePhoneInput } from 'react-international-phone';
import { getDefaultISO2Code } from './utils';
import { Input } from 'antd';
import { useCompanySettings } from 'settings';

interface TextPhoneInputProps {
  crudPhone: string;
  tagType?: string;
}

const TextPhoneInput: React.FC<TextPhoneInputProps> = ({
  crudPhone,
  tagType
}) => {
  const { countryCode } = useCompanySettings();
  const { inputValue, country } = usePhoneInput({
    defaultCountry: getDefaultISO2Code(countryCode),
    value:
      typeof crudPhone === 'string' && crudPhone.length > 0 ? crudPhone : ''
  });

  const changePhoneNumber = (phoneNumber, countryDialCode) => {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    const formattedNumber = `(+${countryDialCode}) ${cleanedNumber.replace(
      countryDialCode,
      ''
    )}`;
    return formattedNumber;
  };

  return crudPhone.length > 0 ? (
    tagType === 'input' ? (
      <Input value={crudPhone !== '' ? inputValue : ''} />
    ) : (
      <span>{changePhoneNumber(inputValue, country?.dialCode)}</span>
    )
  ) : null;
};

export default TextPhoneInput;
