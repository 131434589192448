import { Select } from 'antd';
import { AssetsService, EmployeeServices, TeamsService } from 'services';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { downloadFile } from 'utils/downloadFiles';
import _sort from 'utils/sorter';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import { useIntl } from 'react-intl';

const { Option } = Select;

const formActions = {
  edit: AssetsService.updateAsset,
  add: AssetsService.addAsset,
  assignColleague: AssetsService.assignAsset,
  assignTeam: AssetsService.assignAsset,
  deassignAsset: AssetsService.deassignAsset
};

export const onFinish = async (scope, data, assetId, actions, fileData?) => {
  if (Object.keys(data).includes('price')) {
    data.price = parseFloat(data.price.toString().replace(',', '.'));
  }

  data = assetId ? { assetId, ...data } : data;

  if (data.generateDocument) {
    await updatePerformedActionsWithGenerateDocumentOnFinish(
      scope,
      assetId,
      actions,
      fileData
    );
  }
  onFormFinishWithPassedValidation(
    formActions,
    scope,
    data,
    actions,
    getSelectedValueAsset(scope)
  );
};

const getSelectedValueAsset = scope => {
  switch (scope) {
    case 'add':
      return getSuccessMessages.success_added_asset;
    case 'edit':
      return getSuccessMessages.success_edited_asset;
    case 'assignColleague':
      return getSuccessMessages.success_assign_colleague;
    case 'assignTeam':
      return getSuccessMessages.success_assign_team;
    case 'deassignAsset':
      return getSuccessMessages.success_deassign_asset;
    default:
      return '';
  }
};

const getAssignmentAgreementFile = async (
  assetId: number,
  fileData: { fileName: string; employeeId: number; teamId: number; }
) => {
  const assignmentAgreementFile =
    await AssetsService.getAssetAssignmentAgreementFile({
      assetId: assetId,
      fileName: fileData.fileName,
      employeeId: fileData.employeeId,
      teamId: fileData.teamId
    });

  if (!assignmentAgreementFile.error) {
    return assignmentAgreementFile.data;
  }

  return null;
};

async function updatePerformedActionsWithGenerateDocumentOnFinish(
  scope,
  assetId,
  actions,
  fileData
) {
  const assignmentAgreementFile = await getAssignmentAgreementFile(
    assetId,
    fileData
  );

  if (!assignmentAgreementFile) {
    return;
  } else {
    const byteArray = new Uint8Array(assignmentAgreementFile);
    const blob = new Blob([byteArray]);
    const url = window.URL.createObjectURL(blob);
    const originalTriggerOtherActionsWithResult = actions.triggerOtherActionsWithResult;

    actions.triggerOtherActionsWithResult = (res) => {
      if (originalTriggerOtherActionsWithResult) {
        originalTriggerOtherActionsWithResult(res);
      }
      if ((scope !== 'edit' || scope !== 'add') && url) {
        downloadFile(url, fileData.fileName);
      }
    };

  }
}

export const ParseOptionsFrequency = valueCurrents => {
  const intl = useIntl();
  const { Option } = Select;

  return valueCurrents.map((current, index) => {
    return (
      <Option key={current} value={current}>
        {intl.formatMessage({ id: current })}
      </Option>
    );
  });
};

export const useParseCurrencyOptions = () => {
  const defaultCurrencies: string[] = ['MDL', 'EUR', 'USD', 'GBP'];
  const generalRootState = useSelector((state: RootState) => state.general);

  const generalRootStateEnums =
    generalRootState && Object.keys(generalRootState).length
      ? generalRootState.enums
      : [];

  const arrayCurrencies =
    generalRootStateEnums && Object.keys(generalRootStateEnums).length
      ? generalRootStateEnums.Currency
      : defaultCurrencies;

  return arrayCurrencies.map((current, index) => (
    <Option key={current} value={current}>
      {current}
    </Option>
  ));
};

export const parseTeamsOptions = async () => {
  const teamsResult = await TeamsService.getAllTeams('false');

  const teams = !teamsResult.error
    ? teamsResult.data
      .filter(employee => employee.leadEmployeeId && employee.teamId)
      .sort((a, b) => _sort(a.name, b.name, 'string'))
    : [];

  return teams.length ? (
    <>
      {teams.map(team => (
        <Option key={team.teamId} value={team.teamId}>
          {team.name}
        </Option>
      ))}
    </>
  ) : (
    <></>
  );
};

export const parseColleaguesOptions = async () => {
  const employeesResult = await EmployeeServices.getAll('false');
  const currentDate = new Date();
  const employees = !employeesResult.error
    ? employeesResult.data.filter(
      employee =>
        employee.employeeId &&
        (!employee.endDate ||
          (employee.endDate && new Date(employee.endDate) > currentDate))
    )
    : [];

  return employees.length ? (
    <>
      {employees.map(colleague => (
        <Option key={colleague.employeeId} value={colleague.employeeId}>
          {`${colleague.lastName} ${colleague.firstName}, ${colleague.position}`}
        </Option>
      ))}
    </>
  ) : (
    <></>
  );
};

export const parseAssignOptions = async (scope, assignPerson) => {
  const assigneeId =
    assignPerson && Object.keys(assignPerson).length
      ? assignPerson.assigneeId
      : '';

  const assigneeName =
    assignPerson && Object.keys(assignPerson).length
      ? assignPerson.assigneeName
      : '';

  const deAssign = (
    <Option value={assigneeId} key={assigneeId}>
      {assigneeName}
    </Option>
  );

  return scope === 'assignColleague'
    ? await parseColleaguesOptions()
    : scope === 'assignTeam'
      ? await parseTeamsOptions()
      : deAssign;
};
