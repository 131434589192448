import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type documentsSliceStore = {
  documentsModalScope: 'view' | 'create' | 'edit';
  editDocumentId: number;
};
const initialState: documentsSliceStore = {
  documentsModalScope: 'view',
  editDocumentId: null,
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocumentsModalScope(state, action: PayloadAction<any>) {
      return { ...state, documentsModalScope: action.payload };
    },
    setCurrentEditDocumentId(state, action: PayloadAction<any>) {
      return { ...state, editDocumentId: action.payload };
    },
  },
});

export const {
  setDocumentsModalScope: setDocumentsFormView,
  setCurrentEditDocumentId,
} = documentsSlice.actions;
export default documentsSlice.reducer;
