import { TimeOffApproveIcon } from 'Icons/TimeOffActionIcons/TimeOffApproveIcon';
import { TimeOffApproveRevocationIcon } from 'Icons/TimeOffActionIcons/TimeOffApproveRevocationIcon';
import { TimeOffCancelIcon } from 'Icons/TimeOffActionIcons/TimeOffCancelIcon';
import { TimeOffDenyIcon } from 'Icons/TimeOffActionIcons/TimeOffDenyIcon';
import { TimeOffDenyRevocationIcon } from 'Icons/TimeOffActionIcons/TimeOffDenyRevocationIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { TimeOffMarkAsConfirmedIcon } from 'Icons/TimeOffActionIcons/TimeOffMarkAsConfirmedIcon';
import { TimeOffRevokeIcon } from 'Icons/TimeOffActionIcons/TimeOffRevokeIcon';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import ThemedIcon from 'components/UIComponents/Icon';
import dayjs from 'dayjs';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { TimeOffService } from 'services';
import { useCompanySettings } from 'settings';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';
import {
  getPolicyByName,
  getTimeDiffDaysBasedOnCalendarType,
  policiesWithUpload
} from '../../TimeOffRequestForm/utils';
import {
  AdditionalFieldSchema,
  DaysAllowedType
} from '../../TimeOffRequestForm/types';
import { RootState } from 'redux/store';
import { convertToLocalDate } from 'components/DateParser';

type RequestActionButtonProps = {
  actionButtonText: string | JSX.Element;
  triggerActionPromiseMethod: () => Promise<any>;
  actionsOnSuccess?: () => void;
  embedSrc: string | JSX.Element;
  setMessageSuccess: string | JSX.Element;
};

type RequestActionButtonWithModalProps = {
  actionButtonText: string | JSX.Element;
  onOkSubmitMethod: () => Promise<any>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  stateActions: any;
  confirmModalProps: any;
  embedSrc: string | JSX.Element;
};

type RequestActionButtonWithStateActionFormModalProps = {
  requestId: number;
  actionButtonText: string | JSX.Element;
  stateActions: any;
  modalProps: any;
  requestType: string;
  embedSrc: string | JSX.Element;
  modalOpenValues?: any;
  setModalOpenValues?: Dispatch<SetStateAction<any>>;
};

type RequestActionsConfirmButtonsProps = {
  record: any;
  showHoverButtons: {
    showSubmitButton: boolean;
    showApproveButton: boolean;
    showConfirmRevokeButton: boolean;
    showConfirmButton: boolean;
    showApproveAndConfirmeButton: boolean;
  };
  setIsModalOpen: any;
  stateActions: any;
  userEmail: string;
  isUserAdmin: boolean;
  modalOpenValues?: any;
  setModalOpenValues?: Dispatch<SetStateAction<any>>;
};

type TimeOffRequestActionsDenyButtonsProps = {
  record: any;
  showHoverButtons: {
    showDenyButton: boolean;
    showCancelButton: boolean;
    showDenyRevokeButton: boolean;
    showSignButton: boolean;
    showRevokeButton: boolean;
  };
  setIsModalOpen: any;
  stateActions: any;
  userEmail: string;
  isUserAdmin: boolean;
  tableRequestType: string;
  modalOpenValues?: any;
  setModalOpenValues?: Dispatch<SetStateAction<any>>;
};

export const ApproveAndConfirmButtonPolicies = [
  'Medical',
  'WorkFromHome',
  'Childcare',
  'BloodDonation',
  'Bereavement',
  'Maternity',
  'Paternity',
  'PersonalLeave',
  'LeaveOffsetting',
  'Marriage',
  'ForgotBadge',
  'MilitaryTraining',
  'TwoOrMoreChildren',
  'BusinessTrip',
  'FirstDayOfSchool',
  'LastDayOfSchool',
  'ChildcareLeaveUnpaid',
  'BirthOfAChild'
];
const policiesWithoutDocuments = (countryCode: string) =>
  countryCode === 'MD'
    ? [
      'WorkFromHome',
      'PersonalLeave',
      'Overtime',
      'LeaveOffsetting',
      'ForgotBadge'
    ]
    : ['WorkFromHome', 'ForgotBadge'];
const cancelModalProps = {
  title: (
    <div className="cancel-request">
      <FormattedMessage id="CancelRequestModalTitle" />
    </div>
  ),
  message: <FormattedMessage id="CancelRequestModalMessage" />,
  requestType: <FormattedMessage id="cancel" />,
  messageSuccessfully: getSuccessMessages.success_canceled_request,
  embedSrc: <ThemedIcon icon={TimeOffCancelIcon} />
};

const denyModalProps = {
  title: <FormattedMessage id="DenyRequestModalTitle" />,
  message: <FormattedMessage id="DenyRequestModalMessage" />,
  okText: <FormattedMessage id="submit" />,
  cancelText: <FormattedMessage id="cancel" />,
  requestType: 'deny',
  embedSrc: TimeOffDenyIcon
};

const denyRevokeModalProps = {
  title: <FormattedMessage id="DenyRequestModalTitle" />,
  message: <FormattedMessage id="DenyRevocationModalMessage" />,
  requestType: 'denyRevoke',
  embedSrc: TimeOffDenyRevocationIcon
};

const confirmModalMark = {
  title: <FormattedMessage id="ConfirmRequestModalTitle" />,
  message: <FormattedMessage id="ConfirmRequestModalMessage" />,
  messageSuccessfully: getSuccessMessages.success_confirmed_mark_request,
  embedSrc: TimeOffEditIcon,
  requestType: 'markAsConfirmed'
};

export const RequestActionButton: FC<RequestActionButtonProps> = ({
  actionButtonText,
  triggerActionPromiseMethod,
  actionsOnSuccess,
  embedSrc,
  setMessageSuccess
}) => {
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
  const dispatch = useDispatch();
  return (
    <ButtonWithExpandedText
      displayedText={actionButtonText}
      embedSrc={embedSrc}
      disabled={requestSubmitted}
      type="link"
      onClick={() => {
        setRequestSubmitted(true);
        triggerActionPromiseMethod().then(res => {
          dispatch(setMethodUpdate(true));
          notifyWithSuccessfulDataUpdated('', setMessageSuccess);
          actionsOnSuccess && actionsOnSuccess();
          setRequestSubmitted(!!res);
        });
      }}
    />
  );
};

export const RequestActionButtonWithModal: FC<
  RequestActionButtonWithModalProps
> = ({
  actionButtonText,
  onOkSubmitMethod,
  setIsModalOpen,
  stateActions,
  confirmModalProps,
  embedSrc
}) => {
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);

    const rootSelector = useSelector((state: RootState) => state);

    const [daysAllowedType, setDaysAllowedType] = useState<DaysAllowedType>(
      DaysAllowedType.CalendarDays
    );
    const [additionalFieldsSchema, setAdditionalFieldsSchema] = useState<
      AdditionalFieldSchema[]
    >([]);

    const hadleButtonClick = () => {
      if (confirmModalProps.specificFiledsRecord) {
        const { startDate, endDate, daysTaken, publicName } =
          confirmModalProps.specificFiledsRecord;

        let hoursResults;
        const requestTypes = publicName;
        const hourlyBasedTimeOffRequestType = [
          'PersonalLeave',
          'Overtime',
          'LeaveOffsetting'
        ].includes(requestTypes);

        if (hourlyBasedTimeOffRequestType) {
          const parseStartDate = convertToLocalDate(startDate).second(0);
          const parseEndDate = convertToLocalDate(endDate).second(0);

          const clockInOutDifference = Math.round(
            parseEndDate.diff(parseStartDate, 'minute', true)
          );

          const clockInOutHoursSpentDiff = parseEndDate.diff(
            parseStartDate,
            'hours'
          );

          const clockInOutRemainedMinutesDiff = clockInOutDifference % 60;
          hoursResults = !endDate ? (
            <FormattedMessage id="OngoingRequestText" />
          ) : (
            clockInOutHoursSpentDiff + 'h ' + clockInOutRemainedMinutesDiff + 'm'
          );
        }

        getPolicyByName(
          confirmModalProps?.specificFiledsRecord?.requestType,
          setAdditionalFieldsSchema,
          setDaysAllowedType
        ).then(res => {
          const timeDifferenceInDays = getTimeDiffDaysBasedOnCalendarType(
            [dayjs(startDate), dayjs(endDate)],
            res?.daysAllowedType,
            rootSelector
          );
          setIsModalOpen(true);
          setRequestSubmitted(true);
          stateActions?.setOnOkModalPromise(() => onOkSubmitMethod);
          stateActions?.setConfirmModalProps({
            ...confirmModalProps,
            message: hourlyBasedTimeOffRequestType ? (
              <FormattedMessage
                id="SubmitRequestLeavesModalMessage"
                values={{
                  requestType: <FormattedMessage id={publicName} />,
                  amount: hoursResults,
                  hours: hourlyBasedTimeOffRequestType && (
                    <span>
                      {convertToLocalDate(startDate).format('HH')}
                      <span className={'minutes'}>
                        {convertToLocalDate(startDate).format('mm')}
                      </span>{' '}
                      - {convertToLocalDate(endDate).format('HH')}
                      <span className={'minutes'}>
                        {convertToLocalDate(endDate).format('mm')}
                      </span>
                    </span>
                  ),

                  startDate: dayjs(startDate).format('DD/MM/YYYY')
                }}
              />
            ) : (
              <FormattedMessage
                id="SubmitRequestModalMessage"
                values={{
                  requestType: <FormattedMessage id={publicName} />,
                  days: timeDifferenceInDays ? timeDifferenceInDays : daysTaken,
                  startDate: dayjs(startDate).format('DD/MM/YYYY'),
                  endDate: dayjs(endDate).format('DD/MM/YYYY')
                }}
              />
            )
          });
          setTimeout(() => setRequestSubmitted(false), 3000);
        });
      } else {
        setIsModalOpen(true);
        setRequestSubmitted(true);
        stateActions?.setOnOkModalPromise(() => onOkSubmitMethod);
        stateActions?.setConfirmModalProps(confirmModalProps);
        setTimeout(() => setRequestSubmitted(false), 3000);
      }
    };

    return (
      <ButtonWithExpandedText
        displayedText={actionButtonText}
        embedSrc={embedSrc}
        disabled={requestSubmitted}
        type="link"
        onClick={hadleButtonClick}
      />
    );
  };

export const RequestActionButtonWithStateActionFormModal: FC<
  RequestActionButtonWithStateActionFormModalProps
> = ({
  actionButtonText,
  stateActions,
  modalProps,
  requestId,
  requestType,
  embedSrc,
  modalOpenValues,
  setModalOpenValues
}) => {
    const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
    return (
      <ButtonWithExpandedText
        displayedText={actionButtonText}
        embedSrc={embedSrc}
        disabled={isButtonClicked}
        type="link"
        onClick={() => {
          setIsButtonClicked(true);
          setTimeout(() => {
            setIsButtonClicked(false);
          }, 2000);

          stateActions?.setModalProps({
            formKeyId: 'stateActionForm',
            requestType,
            modalTitle: modalProps?.title,
            submitBtnText: modalProps?.okText,
            cancelBtnText: modalProps?.cancelText,
            confirmMessage: modalProps?.message,
            timeOffId: requestId,
            confirmRequestType: modalProps?.requestType,
            confirmRequestRevocation: modalProps.revocation
          });

          stateActions?.toggleComponentModal();
          setModalOpenValues &&
            setModalOpenValues({
              editProfile: false,
              clockInclockOut: false,
              request: false,
              draft: false,
              stateAction: true
            });
        }}
      />
    );
  };

export const TimeOffRequestActionsConfirmButtons: FC<
  RequestActionsConfirmButtonsProps
> = ({
  record,
  showHoverButtons,
  setIsModalOpen,
  stateActions,
  userEmail,
  isUserAdmin
}) => {
    const startDate = dayjs(record.startDate).format('DD/MM/YYYY');
    const endDate = dayjs(record.endDate).format('DD/MM/YYYY');
    const formData = new FormData();
    formData.append('requestId', record.requestId);
    const intl = useIntl();
    const { countryCode } = useCompanySettings();
    const isRO = countryCode === 'RO';

    const submitModalProps = {
      title: <FormattedMessage id="SubmitRequestModalTitle" />,
      requestType: 'submit',
      messageSuccessfully: getSuccessMessages.success_submited_request,
      okText: intl.formatMessage({ id: 'confirm' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      specificFiledsRecord: record
    };

    const confirmModalProps = {
      title: intl.formatMessage({ id: 'Warning' }),
      requestType: 'markAsConfirmed',
      message: intl.formatMessage({
        id: 'ApproveAndConfirmedNotSignedModalMessage'
      }),
      messageSuccessfully: ApproveAndConfirmButtonPolicies.includes(
        record.requestType
      )
        ? getSuccessMessages.success_approved_and_confirmed_request
        : getSuccessMessages.success_confirmed_mark_request
    };

    const requestedByEmail = record.requestedByEmployeeEmail?.toLowerCase();
    const requestedForEmail = record.requestedForEmployee?.email?.toLowerCase();
    const primaryApproverEmployeeEmail =
      record.primaryApproverEmployee?.email?.toLowerCase();
    const secondaryApproverEmployeeEmail = record.secondaryApproverEmployees?.map(
      item => {
        if (item.hasApproved === null) {
          return item.email?.toLowerCase();
        }
      }
    );

    const showConfirmWithWarning =
      countryCode === 'RO' && (!record.isSigned || !record.hasSignature);

    switch (record.status) {
      case 'Unfinished':
        if (
          policiesWithUpload(countryCode).includes(record.requestType) &&
          record.attachmentUrls === ''
        ) {
          showHoverButtons.showSubmitButton = false;
          break;
        }

        // Check if any of the additional fields is empty - if so -> hide submit button.
        if (record.additionalFields?.some(field => field.value === null)) {
          showHoverButtons.showSubmitButton = false;
          break;
        }

        if (requestedByEmail === requestedForEmail) {
          if ((userEmail === requestedByEmail && record.endDate) || isUserAdmin) {
            showHoverButtons.showSubmitButton = true;
          } else {
            // set display submit button for admin when request is created  by user
            showHoverButtons.showSubmitButton = false;
          }
        } else {
          if (
            userEmail === requestedByEmail ||
            userEmail === primaryApproverEmployeeEmail ||
            isUserAdmin ||
            userEmail === requestedForEmail
          ) {
            showHoverButtons.showSubmitButton = true;
          } else {
            showHoverButtons.showSubmitButton = false;
          }
        }
        break;
      case 'Draft':
        if (requestedByEmail === requestedForEmail) {
          showHoverButtons.showSubmitButton = !!(
            userEmail === requestedByEmail && record.endDate
          );
        } else {
          showHoverButtons.showSubmitButton =
            userEmail === requestedByEmail ||
            userEmail === primaryApproverEmployeeEmail ||
            isUserAdmin ||
            userEmail === requestedForEmail;
        }
        break;
      case 'Pending':
        if (
          (userEmail === primaryApproverEmployeeEmail || isUserAdmin) &&
          ApproveAndConfirmButtonPolicies.includes(record.requestType)
        ) {
          showHoverButtons.showApproveAndConfirmeButton = true;
        }
        if (
          userEmail === primaryApproverEmployeeEmail ||
          isUserAdmin ||
          secondaryApproverEmployeeEmail.includes(userEmail)
        ) {
          showHoverButtons.showApproveButton = true;
        }
        break;
      case 'ApprovedSecondary':
        if (
          (userEmail === primaryApproverEmployeeEmail || isUserAdmin) &&
          ApproveAndConfirmButtonPolicies.includes(record.requestType)
        ) {
          showHoverButtons.showApproveAndConfirmeButton = true;
        }
        if (userEmail === primaryApproverEmployeeEmail || isUserAdmin) {
          showHoverButtons.showApproveButton = true;
        }
        break;
      case 'RevokePending':
        if (
          userEmail === primaryApproverEmployeeEmail ||
          isUserAdmin ||
          secondaryApproverEmployeeEmail.includes(userEmail)
        ) {
          showHoverButtons.showConfirmRevokeButton = true;
        }
        break;
      case 'Approved':
        if (userEmail === primaryApproverEmployeeEmail || isUserAdmin) {
          showHoverButtons.showConfirmButton = true;
        }
        break;
      default:
        return;
    }

    return (
      <>
        {/* SUMBIT BUTTON */}
        {showHoverButtons?.showSubmitButton && (
          <RequestActionButtonWithModal
            actionButtonText={
              <FormattedMessage id={submitModalProps.requestType} />
            }
            onOkSubmitMethod={() => TimeOffService.submit(formData)}
            setIsModalOpen={setIsModalOpen}
            stateActions={stateActions}
            confirmModalProps={submitModalProps}
            embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
          />
        )}

        {/* APPROVE BUTTON */}

        {showHoverButtons?.showApproveButton && (
          <RequestActionButton
            setMessageSuccess={getSuccessMessages.success_approved_request}
            actionButtonText={<FormattedMessage id="approve" />}
            triggerActionPromiseMethod={() =>
              TimeOffService.approveTimeOff({
                requestId: record.requestId
              })
            }
            embedSrc={<ThemedIcon icon={TimeOffApproveIcon} />}
          />
        )}

        {/* APPROVE AND CONFIRM BUTTON */}

        {showHoverButtons?.showApproveAndConfirmeButton &&
          (isRO ? (
            <RequestActionButtonWithModal
              stateActions={stateActions}
              setIsModalOpen={setIsModalOpen}
              confirmModalProps={
                record.isSigned ? confirmModalMark : confirmModalProps
              }
              actionButtonText={<FormattedMessage id="approveNconfirm" />}
              onOkSubmitMethod={() =>
                TimeOffService.confirm({
                  requestId: record.requestId
                })
              }
              embedSrc={<ThemedIcon icon={TimeOffApproveIcon} />}
            />
          ) : (
            <RequestActionButton
              setMessageSuccess={
                getSuccessMessages.success_confirmed_mark_request
              }
              actionButtonText={<FormattedMessage id="approveNconfirm" />}
              triggerActionPromiseMethod={() =>
                TimeOffService.confirm({
                  requestId: record.requestId
                })
              }
              embedSrc={<ThemedIcon icon={TimeOffApproveIcon} />}
            />
          ))}

        {showHoverButtons?.showConfirmRevokeButton && (
          <RequestActionButton
            setMessageSuccess={
              getSuccessMessages.success_revocation_request_confirmed
            }
            actionButtonText={<FormattedMessage id="confirmRevokation" />}
            triggerActionPromiseMethod={() =>
              TimeOffService.approveRevoke({
                requestId: record.requestId
              })
            }
            embedSrc={<ThemedIcon icon={TimeOffApproveRevocationIcon} />}
          />
        )}
        {showConfirmWithWarning && showHoverButtons?.showConfirmButton && (
          <RequestActionButtonWithModal
            actionButtonText={
              <FormattedMessage id={confirmModalMark.requestType} />
            }
            onOkSubmitMethod={() =>
              TimeOffService.confirm({
                requestId: record.requestId
              })
            }
            setIsModalOpen={setIsModalOpen}
            stateActions={stateActions}
            confirmModalProps={{
              ...confirmModalProps,
              message: intl.formatMessage({
                id: 'MarkAsConfirmedNotSignedModalMessage'
              })
            }}
            embedSrc={<ThemedIcon icon={TimeOffMarkAsConfirmedIcon} />}
          />
        )}
        {!showConfirmWithWarning && showHoverButtons?.showConfirmButton && (
          <RequestActionButton
            setMessageSuccess={confirmModalMark.messageSuccessfully}
            actionButtonText={
              <FormattedMessage id={confirmModalMark.requestType} />
            }
            triggerActionPromiseMethod={() =>
              TimeOffService.confirm({
                requestId: record.requestId
              })
            }
            embedSrc={<ThemedIcon icon={TimeOffMarkAsConfirmedIcon} />}
          />
        )}
      </>
    );
  };

export const getRequestTimeOffActionStatusDetails = ({ record }) => {
  let statusDetails: string | [] | JSX.Element = '';
  const primaryApproverEmployee = `${record.primaryApproverEmployee?.lastName} ${record.primaryApproverEmployee?.firstName}`;

  const arrayOFSecondaryEmployees = record?.secondaryApproverEmployees
    ?.map(item => {
      if (item.hasApproved === null) {
        return item.lastName + ' ' + item.firstName;
      }
    })
    .filter(item => item !== undefined);

  const secondaryApproverEmployee =
    arrayOFSecondaryEmployees.length > 1
      ? arrayOFSecondaryEmployees.join(', ')
      : arrayOFSecondaryEmployees;

  const approverEmployee =
    record?.secondaryApproverEmployees.length > 0
      ? secondaryApproverEmployee
      : primaryApproverEmployee;
  switch (record.status) {
    case 'Pending':
      return (statusDetails = approverEmployee);
    case 'Revoked':
    case 'Denied':
      return (statusDetails = `${record.lastActor ?? ''}`);
    case 'ApprovedSecondary':
      return (statusDetails = primaryApproverEmployee);
    case 'RevokePending':
      return (statusDetails = primaryApproverEmployee);
    case 'Approved': {
      return '';
    }
    default:
      return statusDetails;
  }
};

export const getRequestTimeOffActionFilterApprover = ({
  record,
  countryCode
}) => {
  const primaryApproverEmployee = {
    approverName: `${record.primaryApproverEmployee?.lastName} ${record.primaryApproverEmployee?.firstName}`,
    approverId: record.primaryApproverEmployee?.employeeId
  };

  const secondaryApproverEmployee = record.secondaryApproverEmployees
    ?.map(item => {
      if (item.hasApproved === null) {
        return {
          approverName: item.lastName + ' ' + item.firstName,
          approverId: item.employeeId
        };
      }
    })
    .filter(item => item !== undefined);

  const approverEmployee =
    record?.secondaryApproverEmployees.length > 0
      ? secondaryApproverEmployee
      : primaryApproverEmployee;

  switch (record.status) {
    case 'Pending':
      return approverEmployee;
    case 'Revoked':
    case 'ApprovedSecondary':
      return primaryApproverEmployee;
    case 'RevokePending':
      return primaryApproverEmployee;
    case 'Approved': {
      return !policiesWithoutDocuments(countryCode).includes(record.requestType)
        ? {
          approverName: 'Awaiting signed documents',
          approverId: 0
        }
        : '';
    }
  }
};
export const getFilterNextApprover = (data, countryCode: string) => {
  const isRO = countryCode === 'RO';

  const approvers = data
    .map(record => {
      const approver = getRequestTimeOffActionFilterApprover({
        record,
        countryCode
      });
      return approver;
    })
    .filter(item => item !== undefined);

  // Flatten the array of approvers.
  const flattenedApprovers = approvers.reduce((acc, curr) => {
    if (Array.isArray(curr)) {
      acc.push(...curr);
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  // Filter out duplicates based on approverId and approverName.
  const uniqueApprovers = flattenedApprovers.filter(
    (a, i) =>
      typeof a === 'object' &&
      a !== null &&
      flattenedApprovers.findIndex(
        s =>
          typeof s === 'object' &&
          s !== null &&
          a.approverId === s.approverId &&
          a.approverName === s.approverName
      ) === i
  );

  const nextApproversfiltredata = isRO
    ? uniqueApprovers
    : uniqueApprovers.filter(
      item => item.approverName !== 'Awaiting signed documents'
    );

  // Map the unique approvers for filter Next Approver.
  const res = nextApproversfiltredata.map(item => ({
    text: item.approverName,
    value: item.approverName,
    key: item.approverId
  }));

  return res;
};

export const TimeOffRequestActionsDenyButtons: FC<
  TimeOffRequestActionsDenyButtonsProps
> = ({
  record,
  setIsModalOpen,
  stateActions,
  showHoverButtons,
  userEmail,
  isUserAdmin,
  tableRequestType,
  modalOpenValues,
  setModalOpenValues
}) => {
    const primaryApproverEmployeeEmail =
      record.primaryApproverEmployee?.email?.toLowerCase();
    const requestedByEmail = record.requestedByEmployeeEmail?.toLowerCase();
    const requestedForEmail = record.requestedForEmployee?.email?.toLowerCase();
    const secondaryApproverEmployeeEmail = record.secondaryApproverEmployees?.map(
      item => {
        if (item.hasApproved === null) {
          return item.email?.toLowerCase();
        }
      }
    );

    const revokeModalProps = {
      title: <FormattedMessage id="RevokeRequestModalTitle" />,
      message: (
        <FormattedMessage
          id="RevokeModalMessage"
          values={{
            requestType: (
              <b>
                <FormattedMessage id={record.publicName} />{' '}
              </b>
            )
          }}
        />
      ),
      requestType: 'revoke'
    };

    switch (record.status) {
      case 'Pending':
        if (
          userEmail === primaryApproverEmployeeEmail ||
          isUserAdmin ||
          secondaryApproverEmployeeEmail.includes(userEmail)
        ) {
          showHoverButtons.showDenyButton = true;
        }
        break;
      case 'Unfinished':
      case 'Draft':
        showHoverButtons.showCancelButton = isUserAdmin
          ? true
          : requestedByEmail === requestedForEmail
            ? userEmail === requestedByEmail
            : userEmail === requestedByEmail ||
            userEmail === primaryApproverEmployeeEmail ||
            userEmail === requestedForEmail;
        break;
      case 'ApprovedSecondary':
        if (userEmail === primaryApproverEmployeeEmail || isUserAdmin) {
          showHoverButtons.showDenyButton = true;
        }
        break;
      case 'RevokePending':
        if (
          userEmail === primaryApproverEmployeeEmail ||
          isUserAdmin ||
          secondaryApproverEmployeeEmail.includes(userEmail)
        ) {
          showHoverButtons.showDenyRevokeButton = true;
        }
        break;
      case 'Approved':
        showHoverButtons.showRevokeButton =
          userEmail === primaryApproverEmployeeEmail ||
          isUserAdmin ||
          userEmail === requestedForEmail;
        break;
      case 'Confirmed':
        if (userEmail === primaryApproverEmployeeEmail || isUserAdmin) {
          showHoverButtons.showRevokeButton = true;
        }
        break;
      default:
        return;
    }

    return (
      <>
        {showHoverButtons?.showDenyButton && (
          <RequestActionButtonWithStateActionFormModal
            requestId={record.requestId}
            requestType={tableRequestType}
            actionButtonText={<FormattedMessage id="deny" />}
            modalProps={denyModalProps}
            stateActions={stateActions}
            embedSrc={<ThemedIcon icon={TimeOffDenyIcon} />}
            modalOpenValues={modalOpenValues}
            setModalOpenValues={setModalOpenValues}
          />
        )}
        {showHoverButtons?.showCancelButton && (
          <RequestActionButtonWithModal
            actionButtonText={cancelModalProps.requestType}
            confirmModalProps={cancelModalProps}
            onOkSubmitMethod={() =>
              TimeOffService.cancel({
                requestId: record.requestId
              })
            }
            setIsModalOpen={setIsModalOpen}
            stateActions={stateActions}
            embedSrc={<ThemedIcon icon={TimeOffCancelIcon} />}
          />
        )}
        {showHoverButtons?.showDenyRevokeButton && (
          <RequestActionButtonWithStateActionFormModal
            requestType={tableRequestType}
            requestId={record.requestId}
            embedSrc={<ThemedIcon icon={TimeOffDenyRevocationIcon} />}
            actionButtonText={<FormattedMessage id="denyRevokation" />}
            modalProps={denyRevokeModalProps}
            stateActions={stateActions}
            modalOpenValues={modalOpenValues}
            setModalOpenValues={setModalOpenValues}
          />
        )}

        {showHoverButtons?.showRevokeButton && (
          <RequestActionButtonWithStateActionFormModal
            requestType={tableRequestType}
            requestId={record.requestId}
            embedSrc={<ThemedIcon icon={TimeOffRevokeIcon} />}
            actionButtonText={<FormattedMessage id="revoke" />}
            modalProps={revokeModalProps}
            stateActions={stateActions}
            modalOpenValues={modalOpenValues}
            setModalOpenValues={setModalOpenValues}
          />
        )}
      </>
    );
  };

export const TimeOffRequestActionsEmployeeDenyButtons: FC<
  TimeOffRequestActionsDenyButtonsProps
> = ({
  record,
  setIsModalOpen,
  stateActions,
  showHoverButtons,
  userEmail,
  isUserAdmin,
  modalOpenValues,
  setModalOpenValues
}) => {
    const primaryApproverEmployeeEmail =
      record.primaryApproverEmployee?.email?.toLowerCase();
    const requestedByEmail = record.requestedByEmployeeEmail?.toLowerCase();
    const requestedForEmail = record.requestedForEmployee?.email?.toLowerCase();

    switch (record.status) {
      case 'Pending':
        if (userEmail === requestedForEmail) {
          showHoverButtons.showCancelButton = true;
          showHoverButtons.showSignButton =
            !record.isSigned && record.hasSignature;
        }
        break;
      case 'ApprovedSecondary':
        showHoverButtons.showSignButton =
          userEmail === requestedForEmail &&
          !record.isSigned &&
          record.hasSignature;
        showHoverButtons.showCancelButton =
          requestedByEmail === requestedForEmail
            ? userEmail === requestedByEmail
            : userEmail === requestedByEmail ||
            userEmail === primaryApproverEmployeeEmail ||
            isUserAdmin;
        break;
      case 'Approved':
        if (userEmail === requestedForEmail) {
          showHoverButtons.showSignButton =
            !record.isSigned && record.hasSignature;
        }
        break;
      default:
        return;
    }

    return (
      <>
        {showHoverButtons?.showSignButton && (
          <RequestActionButton
            setMessageSuccess={getSuccessMessages.success_sign_request}
            actionButtonText={<FormattedMessage id="sign" />}
            triggerActionPromiseMethod={() =>
              TimeOffService.sign({
                requestId: record.requestId
              })
            }
            embedSrc={<ThemedIcon icon={TimeOffApproveIcon} />}
          />
        )}
        {showHoverButtons?.showCancelButton && (
          <RequestActionButtonWithModal
            embedSrc={<ThemedIcon icon={TimeOffCancelIcon} />}
            actionButtonText={cancelModalProps.requestType}
            confirmModalProps={cancelModalProps}
            onOkSubmitMethod={() =>
              TimeOffService.cancel({
                requestId: record.requestId
              })
            }
            setIsModalOpen={setIsModalOpen}
            stateActions={stateActions}
          />
        )}
      </>
    );
  };
