import { FilterOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';
import './FilterColumn.less';
import { Filter } from 'Icons/General/filter';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { SearchTourch } from 'Icons/General/searchTourch';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { FormattedMessage } from 'react-intl';

interface FilterOption {
  text: string;
  value: string;
  key: string;
}

interface FilterColumnProps {
  options: FilterOption[];
  onFilterApply: (selectedOptions: string[]) => void;
}

const FilterColumn: React.FC<FilterColumnProps> = ({
  options,
  onFilterApply
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOptions, setFilteredOptions] =
    useState<FilterOption[]>(options);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);

  const filterContainerRef = useRef(null);

  const myDivRef = useRef(null);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const { theme } = useThemeContext();
  const ButtonComponent = BaseButton(ButtonSimple);

  const handleOptionClick = (value: string) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleResetClick = () => {
    onFilterApply(['']);
    setSelectedOptions([]);
    setSearchText('');
    setOpenFilter(false);
  };

  const handleApplyClick = () => {
    onFilterApply(selectedOptions);
    setOpenFilter(false);
  };

  useEffect(() => {
    // Filter options based on search text
    const filtered = options.filter(option =>
      option.text.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchText, options]);

  //Function which will solve problem with position of dialog filter
  const getCoordinates = () => {
    const rect = filterContainerRef.current.getBoundingClientRect();
    setCoordinates({ x: rect.x, y: rect.y });
  };

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
    getCoordinates();
  };

  // Function to handle div click and input focus
  const handleDivClick = () => {
    setIsFocused(true);
  };

  // Function to handle input blur
  const handleInputBlur = () => {
    setIsFocused(false);
  };

  // Funclion for click outside which will close dropdown filter
  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterContainerRef.current &&
      !filterContainerRef.current.contains(event.target as Node) &&
      openFilter
    ) {
      setOpenFilter(false);
    }
  };
  //for click outside
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openFilter]);

  return (
    <div className={`filter-container_table ${theme}`} ref={filterContainerRef}>
      {/* Filter Icon/Button */}
      <Filter
        className={`filter_icon_btn ${
          openFilter || selectedOptions.length > 0 ? 'open' : ''
        }`}
        onClick={handleOpenFilter}
      />

      {/* Filter Dialog */}
      {openFilter && (
        <div
          className="filter-dialog"
          style={{ top: `calc(${coordinates.y - 132}px)` }}
        >
          {/* Search Input */}
          <div
            className={`input-search-container ${
              isFocused ? 'focused' : 'default'
            }`}
          >
            <SearchTourch className="search-tourch-icon" />
            <input
              className="input_search"
              type="text"
              onFocus={handleDivClick}
              onBlur={handleInputBlur}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>

          {/* Options List */}
          <ul className="list-options">
            {filteredOptions.map(option => (
              <li
                key={option.key}
                className={`option_filter 
        ${selectedOptions.includes(option.value) ? 'selected' : ''}
                `}
              >
                <input
                  id={option.key}
                  type="checkbox"
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => handleOptionClick(option.value)}
                />
                <label htmlFor={option.key} className="label_option">
                  {option.text}
                </label>
              </li>
            ))}
          </ul>

          {/* Reset and Apply Buttons */}
          <div className="buttons-filter-footer">
            <ButtonComponent
              className={`text-bold-normal btn-default-custom ${theme}`}
              onClick={handleResetClick}
              style={{ padding: '4px 24px' }}
            >
              <FormattedMessage id="btn_reset" />
            </ButtonComponent>
            <ButtonComponent
              type="primary"
              className={`text-bold-normal btn-primary-custom ${theme}`}
              onClick={handleApplyClick}
              style={{ padding: '4px 24px' }}
            >
              <FormattedMessage id="btn_ok" />
            </ButtonComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterColumn;
