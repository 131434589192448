import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { IModalComponentProps } from 'components/Modal';
import ThemedIcon from 'components/UIComponents/Icon';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import _sort from 'utils/sorter';
import { SettingsCategoryForm } from '../../../components/SetitingsComponents/Category/SettingsCategoryForm';

export const useCategoryTableColumns = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<IModalComponentProps>>,
  editModalWidth: number | string,
  deleteModalWidth: number | string
): ColumnsType<any> => {
  const { isMobile, isTabletDevice } = useMobileContext();
  const intl = useIntl();
  // Row Actions
  // Edit category open modal and set modal props
  const handleEditClick = record => {
    setModalProps(state => ({
      ...state,
      scope: 'edit',
      key: 'ticketingCategoryForm',
      category: record,
      modalTitle: <FormattedMessage id="EditCategoryModalTitle" />,
      cancelButtonText: <FormattedMessage id="cancel" />,
      submitButtonText: <FormattedMessage id="save" />,
      width: editModalWidth,
      children: (
        <SettingsCategoryForm
          scope={'edit'}
          category={record}
        />)
    }));
    setIsOpen(true);
  };
  // Remove category open modal and set modal props
  const handleDeleteClick = record => {
    if (record?.isAssignedToTicket) {
      setModalProps(state => ({
        ...state,
        scope: 'remove',
        key: 'ticketingCategoryForm',
        category: record,
        modalTitle: <FormattedMessage id="RemoveCategoryModalTitle" />,
        displayFooterSubmitButton: false,
        displayFooterCloseButton: true,
        cancelButtonText: <FormattedMessage id="cancel" />,
        submitButtonText: <FormattedMessage id="remove" />,
        width: deleteModalWidth,
        children: (

          <>
            <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
              <FormattedMessage id="CategoryRemoveCantConfirmText" />
            </p>
          </>
        ),
      }));
    } else {
      setModalProps(state => ({
        ...state,
        scope: 'remove',
        key: 'ticketingCategoryForm',
        category: record,
        modalTitle: <FormattedMessage id="RemoveCategoryModalTitle" />,
        cancelButtonText: <FormattedMessage id="cancel" />,
        submitButtonText: <FormattedMessage id="remove" />,
        displayFooterSubmitButton: true,
        displayFooterCloseButton: true,
        width: deleteModalWidth,
        children: (
          <SettingsCategoryForm
            scope={'remove'}
            category={record}
          />
        ),
      }));
    }

    setIsOpen(true);
  };

  //------End Row Actions
  const tableColumns = [
    {
      title: <FormattedMessage id="SettingsSubcategory_Category_Name" />,
      dataIndex: 'categoryName',
      sorter: (a, b) => _sort(a.name, b.name, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => (
        <Link
          state={record}
          to={`/settings/viewCategory?categoryId=${record?.categoryId}`}
        >
          <p> {record.name}</p>
        </Link>
      )
    },
    {
      title: <FormattedMessage id="SettingsWorkQueues_Translations" />,
      dataIndex: 'key',

      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <p> {intl.formatMessage({ id: record.key, defaultMessage: record.name })}</p>
    },
    {
      title: <FormattedMessage id="SettingsCategory_Ticket_type" />,
      dataIndex: 'ticketType',
      sorter: (a, b) => _sort(a.ticketType, b.ticketType, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '15%',
      render: (_, record) => (
        <p><SafeFormattedMessage id={`TicketingCategory_${record?.ticketType}`} /></p>
      )
    },
    {
      title: <FormattedMessage id="SettingsCategory_Subcategories" />,
      dataIndex: 'subcategories',
      width: isTabletDevice || isMobile ? '10%' : '15%',
      render: (_, record) => <p>{record?.subCategoriesCount}</p>
    },
    {
      title: <FormattedMessage id="SettingsWorkQueues_Work_Queue" />,
      dataIndex: 'workQueue',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      className: 'hoverEdit',
      render: (_, record) => (
        <>
          <p>{record?.workQueue ? record?.workQueue?.name : '-'}</p>

          <div className={'showEdit'} style={{ display: 'none' }}>
            <div className="actionButtons">
              <ButtonWithExpandedText
                displayedText={<FormattedMessage id="edit" />}
                embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                key="edit-category-btn"
                type="link"
                onClick={() => handleEditClick(record)}
              />
              <ButtonWithExpandedText
                embedSrc={<ThemedIcon icon={TimeOffDeleteIcon} />}
                displayedText={<FormattedMessage id="remove" />}
                type="link"
                onClick={() => handleDeleteClick(record)}
              />
            </div>
          </div>
        </>
      )
    }
  ];
  return [...tableColumns];
};
