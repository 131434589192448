import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ArrowUnion } from 'Icons/General/arrowUnion';
import { useMemo, useState } from 'react';
import {
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import './InputPhone.less';
import { getDefaultISO2Code } from './utils';
import { defaultCountries } from './metadata/defaultCountries';
import { useCompanySettings } from 'settings';
interface DropdownInputPhoneProps {
  value: string;
  onChange: any;
  disablePhoneInput?: boolean;
}

const DropdownInputPhone: React.FC<DropdownInputPhoneProps> = ({ value, onChange, disablePhoneInput = false }) => {
  const { theme } = useThemeContext();
  const { countryCode, phoneCode } = useCompanySettings();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const parsedCountries = defaultCountries.map((c) => (parseCountry(c)));
  const currentCountry = parsedCountries.find(c => (c.dialCode === phoneCode.match(/\d+/g)[0])).iso2;
  const { inputValue, handlePhoneValueChange, country, setCountry } =
    usePhoneInput({
      defaultCountry: currentCountry,
      value: value === null || value === undefined ? '' : value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      
        if(openDropdown) 
        return setOpenDropdown(false);
      },
      prefix: "+(",
      charAfterDialCode: ") ",
      defaultMask: ".. .. .. ..",
      forceDialCode: true
    });

    const [selectedOption, setSelectedOption] = useState<string>(currentCountry)
    const optionChange = (value: string) => (event: React.MouseEvent<HTMLLIElement>) => {
      setCountry(value);
      setSelectedOption(value);
      setOpenDropdown(false)
    };


  return (
    <div className={`field-container ${theme}`}>
      <div className={`field-body ${openDropdown ? 'open' : ''}`}>
        <div className="field-row">
          <button
            type="button"
            className="btn_dropdown"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <div className="selector_btn_content">
              <FlagImage iso2={country.iso2} size="20px" />
              <ArrowUnion className={`arrow_union ${openDropdown ? 'rotate' : ''}`} />
            </div>
          </button>
          <div className="phone_input_devider"></div>
          <input
            type="text"
            name="phoneNumber"
            className="input_phone"
            disabled={disablePhoneInput}
            value={inputValue}
            onChange={handlePhoneValueChange }
          />
        </div>
      </div>
      {openDropdown && (
        <div className={`virtual-dropdown ${openDropdown ? 'open' : ''}`}>
          <ul className="dropdown-list">
            {parsedCountries.map((country) => {
              return (
                <li
                  key={country.iso2}
                  className={`country_option ${selectedOption === country.iso2 ? 'selected' : ''}`}
                  value={country.iso2}
                  onClick={optionChange(country.iso2)}
                >
                  <FlagImage iso2={country.iso2} size={'20px'} />
                  {country.name} +{country.dialCode}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownInputPhone;
