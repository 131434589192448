import {
  EmployeeDocumentStatus,
  EmployeeDocumentSubType,
  EmployeeDocumentType,
} from 'services/providers/EmployeeDocumentProvider/utils';
import dayjs from 'dayjs';
export interface IDocumentFields {
  employeeDocumentId?: number;
  documentType: EmployeeDocumentType;
  documentSubType: EmployeeDocumentSubType;
  documentStatus: EmployeeDocumentStatus;
  documentName: string;
  description?: string;
  employeeId: number;
  fileLocation?: string;
  personalId: string;
  birthDate: any;
  addressOfResidence: string;
  documentSeries: string;
  issuedBy?: string;
  issueDate?: any;
  expiryDate?: any;
  nationality: string;
  countryIssuer: string;
  licenseCategory: string;
  driverLicenseId: string;
  placeOfBirth?: string;
  institutionName: string;
  specialization: string;
  studyPeriodFrom: any;
  studyPeriodTo: any;
  signedOn?: any;
  validFrom?: any;
  validTo?: any;
}

export const formattedDateSingular = (date) => {
  return !dayjs(date).isValid() ? '' : dayjs(date).format('DD/MM/YYYY');
};

/**Helper function which declaratively handles the proper display of start-end date range, which also handles the case when either one is missing */
export const formatDateRange = (start, end) => {
  if (!start) return formattedDateSingular(end);
  if (!end) return formattedDateSingular(start);

  return formattedDateSingular(start) + ' - ' + formattedDateSingular(end);
};
/** Helper function to display a indefinite list of fields comma separated in a more dynamic and declarative way */
export const toCommaSeparatedValuesStr = (...fields) => {
  return fields.filter((i) => i).join(', ');
};
