import { Col, Row, Typography } from 'antd';
import styles from './ticketCard.module.less';
import { TicketDto } from 'pages/Ticketing/types';
import { LabelTag } from 'components/UIComponents/LabelTag';
import { TicketPriorityElement } from 'pages/Ticketing/utils';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

const TicketCard: React.FC<{ ticket: TicketDto }> = ({ ticket }) => {
  const {
    title = null,
    status = null,
    assignee = null,
    priority = null,
    ticketId = null
  } = ticket;
  const { selectedTicket } = useSelector((state: RootState) => state.ticketing);
  const isCardSelected = selectedTicket?.ticketId === ticketId;
  const intl = useIntl();
  return (
    <div className={`${styles.wrap} ${isCardSelected ? 'selected' : ''}`}>
      <Row justify={'space-between'}>
        <Col span={12}>
          <Typography.Text ellipsis>{title}</Typography.Text>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >
          <LabelTag>
            <FormattedMessage id={status} />
          </LabelTag>
        </Col>
      </Row>
      <Row style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
        <Col span={12}>
          <Typography.Text ellipsis>
            {assignee
              ? assignee?.lastName + ' ' + assignee?.firstName
              : intl.formatMessage({ id: 'Unassigned' })}
          </Typography.Text>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <TicketPriorityElement priority={priority} />
        </Col>
      </Row>
    </div>
  );
};
export default TicketCard;
