import { parseHourMinutes } from "utils/getDateTimeDifference";

export const getValue = (statisticType: string, row: string, statistics: any): string => {
    const rows = statistics.formatedStatistics[statisticType];
    if (
        ['PersonalLeave', 'LeaveOffsetting', 'Overtime'].includes(
            statisticType
        ) &&
        ['daysTaken', 'daysLocked'].includes(row)
    ) {
        return rows && parseHourMinutes(rows[row] * 24);
    }
    return (rows && rows[row]?.toString()) || '∞';
};