import React, { FC, useEffect, useState } from 'react';
import { Typography, Avatar, Select, Tooltip } from 'antd';
import { ITeam } from 'services/providers/EmployeeProvider/types';
import '../EmployeeProfileCard.less';
import './teamWidgetStyles.less';
import { getTitleSelects } from 'components/TitlesColumns/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { SelectDropdown } from '../../../Dropdown';
import { SafeFormattedMessage } from '../../../UIComponents/SafeFormattedMessage';
import { getGradientIndex, gradients } from '../../../../utils/teamsGradients';
import ComponentWithStatus from '../../../ComponentWithStatus';
import { TeamsWidgetPlaceholder } from '../../../Placeholders/Widgets';
import { Link, useNavigate } from 'react-router-dom';
import {
  useMobileContext,
  useThemeContext
} from '../../../../AppProvider/ConfigProviderSettings';
import { CheckMark } from '../../../../Icons/Language/СheckMark';

type EmployeeInfoFieldsProps = {
  data: ITeam[];
  scope?: string;
  firstNameEmployee?: any;
  gender: string;
};

const { Text } = Typography;

export const TeamWidget: FC<EmployeeInfoFieldsProps> = props => {
  const { data, scope, firstNameEmployee, gender } = props;
  const { theme } = useThemeContext();
  const { isMobile } = useMobileContext();
  const defaultTeamInfo = [
    {
      title: <FormattedMessage id="TeamWidget_Team_lead/PM" />,
      value: <FormattedMessage id="TeamWidget_noTlText" />
    },
    {
      title: <FormattedMessage id="TeamWidget_Departmet" />,
      value: <FormattedMessage id="TeamWidget_noDepartment" />
    },
    { title: <FormattedMessage id="TeamWidget_Members" />, value: 0 }
  ];

  useEffect(() => {
    if (!data) {
      setSelectedTeamInfo(defaultTeamInfo);
    } else {
      setSelectedTeamInfo(
        data.length
          ? data.find(team => team.teamId === parseInt(localStorage.getItem('selectedTeam'))) || data[0]
          : defaultTeamInfo
      );
    }
  }, [data]);

  const [selectedTeamInfo, setSelectedTeamInfo] =
    useState<any>(defaultTeamInfo);

  const [displayedTeamInfo, setDisplayedTeamInfo] = useState<
    { title: JSX.Element | string; value: JSX.Element | number | null }[]
  >([
    {
      title: <FormattedMessage id="TeamWidget_Team_lead/PM" />,
      value: <FormattedMessage id="TeamWidget_noTlText" />
    },
    {
      title: <FormattedMessage id="TeamWidget_Departmet" />,
      value: <FormattedMessage id="TeamWidget_noDepartment" />
    },
    { title: <FormattedMessage id="TeamWidget_Members" />, value: 0 }
  ]);

  // mobile-teams-widget Avatar Group Extended state

  useEffect(() => {
    setDisplayedTeamInfo([
      {
        title: <FormattedMessage id="TeamWidget_Team_lead/PM" />,
        value:
          selectedTeamInfo?.leadEmployeeId == null
            ? '-'
            : selectedTeamInfo?.leadEmployeeName
      },
      {
        title: <FormattedMessage id="TeamWidget_Departmet" />,
        value: selectedTeamInfo?.department
          ? getTitleSelects(selectedTeamInfo.department, 'departaments')
          : '-'
      },
      {
        title: <FormattedMessage id="TeamWidget_Members" />,
        value: selectedTeamInfo?.membersCount ?? '-'
      }
    ]);

  }, [data, selectedTeamInfo]);

  const getAbbreviatedTeamName = (teamName: string) => {
    const separatedTeamName = teamName.split(
      teamName.includes('-') ? '-' : ' '
    );

    return separatedTeamName.length > 1
      ? separatedTeamName[0].slice(0, 1).toUpperCase() +
          separatedTeamName[1].slice(0, 1).toUpperCase()
      : separatedTeamName.map(name => name.slice(0, 2).toUpperCase()).join(' ');
  };

  const intl = useIntl();
  const replaceLastLetter = name => {
    const new_name = name.slice(0, -1) + 'ei';
    return new_name;
  };

  const FemaleEmployeeTeamsText = intl.formatMessage(
    { id: 'FemaleEmployeeTeams' },
    {
      name: replaceLastLetter(firstNameEmployee)
    }
  );

  const MaleEmployeeTeamsText = intl.formatMessage(
    { id: 'MaleEmployeeTeams' },
    {
      name: firstNameEmployee
    }
  );

  data?.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      return 0;
    }
  });

  const navigate = useNavigate();
  return (
    <ComponentWithStatus
      status={data ? 'success' : 'pending'}
      Placeholder={<TeamsWidgetPlaceholder />}
    >
      <div className="teams-widget">
        {selectedTeamInfo.name ? (
          <>
            <div className="teams-widget__header">
              <span className="teams-widget__title">
                <FormattedMessage id="Teams" />
              </span>

              <SelectDropdown
                disabled={data.length === 1}
                showArrow={data.length > 1}
                onSelect={value => {
                  localStorage.setItem('selectedTeam', value)
                  setSelectedTeamInfo(data.find(team => team.teamId === value))
                 }
                }
                allowClear={false}
                showSearch={false}
                dropdownAlign={{ offset: [0, 4] }}
                popupClassName="teams-widget__select-dropdown"
                placement="bottomRight"
                value={
                  selectedTeamInfo?.name
                    ? getAbbreviatedTeamName(selectedTeamInfo?.name)
                    : null
                }
              >
                {data?.map(team => (
                  <Select.Option value={team.teamId} key={team.teamId}>
                    <div className={'select-dropdown-button'}>
                      <Avatar size={18} key={team.teamId}>
                        <Text className="text-medium-small">
                          {getAbbreviatedTeamName(team.name)}
                        </Text>
                      </Avatar>
                      <Text className={'option-label'}>
                        {team.name}
                        {team.name === selectedTeamInfo.name && (
                          <CheckMark stroke="currentColor" />
                        )}
                      </Text>
                    </div>
                  </Select.Option>
                ))}
              </SelectDropdown>
            </div>

            <div
              className="teams-widget__image"
              onClick={() => {
                navigate(
                  `/teams/viewTeam?teamId=${selectedTeamInfo?.teamId ?? 0}`
                );
              }}
              style={{
                backgroundImage:
                  gradients[getGradientIndex(selectedTeamInfo?.name)]
              }}
            >
              {selectedTeamInfo?.teamImageUrl && (
                  <img src={selectedTeamInfo.teamImageUrl} />
              )}
              <div className={"gradient-bg"}>
                <span className="teams-widget__name">
                  {selectedTeamInfo.name}
                </span>
              </div>
            </div>

            <div className="teams-widget__description">
              <div className="teams-widget__description-item">
                <span className="teams-widget__desc-field">
                  <FormattedMessage id="TeamWidget_Team_lead/PM" />
                </span>
                  <span className={'teams-widget__desc-value'}>
                    {selectedTeamInfo.leadEmployeeName}
                  </span>
              </div>
              <div className="teams-widget__description-item">
                <span className="teams-widget__desc-field">
                  <FormattedMessage id="TeamWidget_Departmet" />
                </span>
                  <span className={'teams-widget__desc-value'}>
                    <SafeFormattedMessage id={selectedTeamInfo.department} />
                  </span>
              </div>
              <div className="teams-widget__description-item">
                <span className="teams-widget__desc-field">
                  <FormattedMessage id="TeamWidget_Members" />
                </span>
                <span>{selectedTeamInfo.membersCount}</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="teams-widget__header">
              <span className="teams-widget__title">
                <FormattedMessage id="Teams" />
              </span>
            </div>
            <Text
              className={`text-bold-normal no-teams-text typography_text ${theme}`}
            >
              {scope !== 'ColleagueProfile' ? (
                <FormattedMessage id="teamsWidget_not_assigned_to_a_team_user" />
              ) : (
                <FormattedMessage
                  id="teamsWidget_not_assigned_to_a_team_admin"
                  values={{
                    one: (
                      <Link to={`/teams`}>
                        {' '}
                        <Text
                          underline
                          className={`text-bold-normal no-teams-text typography_text ${theme}`}
                        >
                          <FormattedMessage id="teamsWidget_one" />
                        </Text>{' '}
                      </Link>
                    )
                  }}
                />
              )}
            </Text>
          </>
        )}
      </div>
    </ComponentWithStatus>
  );
};
