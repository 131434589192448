export const GBflagComponent = () => {
  return (
    <span role="img" aria-label="addItem" className="anticon flag-icon">
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0.5H20V15.5H0V0.5Z" fill="#012169" />
        <path
          d="M2.34375 0.5L9.96875 6.15625L17.5625 0.5H20V2.4375L12.5 8.03125L20 13.5938V15.5H17.5L10 9.90625L2.53125 15.5H0V13.625L7.46875 8.0625L0 2.5V0.5H2.34375Z"
          fill="white"
        />
        <path
          d="M13.25 9.28125L20 14.25V15.5L11.5312 9.28125H13.25ZM7.5 9.90625L7.6875 11L1.6875 15.5H0L7.5 9.90625ZM20 0.5V0.59375L12.2188 6.46875L12.2812 5.09375L18.4375 0.5H20ZM0 0.5L7.46875 6H5.59375L0 1.8125V0.5Z"
          fill="#C8102E"
        />
        <path
          d="M7.53125 0.5V15.5H12.5312V0.5H7.53125ZM0 5.5V10.5H20V5.5H0Z"
          fill="white"
        />
        <path
          d="M0 6.53125V9.53125H20V6.53125H0ZM8.53125 0.5V15.5H11.5312V0.5H8.53125Z"
          fill="#C8102E"
        />
      </svg>
    </span>
  );
};
