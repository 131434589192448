import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableComponentPlaceholder } from 'components/Placeholders';
import TableComponent from 'components/Table';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { useMobileContext } from '../../../AppProvider/ConfigProviderSettings';

export const ReportsTable: FC<any> = ({ data, status }) => {
  const intl = useIntl();

  // Transform columns data
  const columns = data.columns.map(column => ({
    title: intl.formatMessage({ id: column.name || 'None' }),
    dataIndex: column.key,
    key: column.key,
    ellipsis: {
      showTitle: false
    }
  }));

  const cols = (): ColumnsType<any> => columns;
  const { isMobile } = useMobileContext();
  const items = data.items.map((item, index) => ({
    key: index.toString(),
    ...item.reduce((acc, value, i) => {
      const columnName = data.columns[i].key;
      if (data.columns[i].dataType === 'Enum') {
        acc[columnName] = (
          <Tooltip
            placement="top"
            title={intl.formatMessage({ id: value || 'None' })}
          >
            {intl.formatMessage({ id: value || 'None' })}
          </Tooltip>
        );
      } else {
        acc[columnName] = (
          <Tooltip placement="top" title={value}>
            {value}{' '}
          </Tooltip>
        );
      }
      return acc;
    }, {})
  }));

  return (
    <ComponentWithStatus
      status={status}
      Placeholder={<TableComponentPlaceholder count={8} />}
    >
      <TableComponent
        scroll={{
          x: 'calc(100vw - 213px)',
          y: isMobile ? 'calc(100vh - 28em)' : 'calc(100vh - 23em)'
        }}
        
        style={{
          height: 'calc(100vh - 23em)',
          minHeight: 'calc(100vh - 22.7em)'
        }}
        rowKey={items.key}
        columns={cols}
        data={items}
        dataSource={items}
        className="reportsDetailsTable"
      />
    </ComponentWithStatus>
  );
};
