import React from 'react';
import { useIntl } from 'react-intl';
import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

import { searchFilterBySelect } from './utils';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';

import './SelectDropdown.less';
import { ArrowUnion } from 'Icons/General/arrowUnion';

interface SelectDropdownProps extends SelectProps {
  placement_bottom?: boolean;
  listHeight?: number;
  customfilter?: boolean;
  disabled?: boolean;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  placement_bottom,
  customfilter,
  ...props
}) => {
  const intl = useIntl();
  const { theme } = useThemeContext();

  const searchFilter = (input: any, option: any) => {
    // If values are passed as children with <FormattedMessage /> component
    const valuesAreTranslated = !!option?.children?.props?.title ?? false;

    // If values are passed as children with intl.formatMessage({ id: 'id' }}) component
    const valuesAreFormatted = !!option?.children?.props?.id ?? false;

    const checkIncludes = (value: string | undefined) => {
      const normalizedInput = input
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      const normalizedValue = (value ?? '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return normalizedValue.includes(normalizedInput);
    };

    if (customfilter) {
      return searchFilterBySelect(input, option, intl);
    }

    if (valuesAreTranslated) {
      return checkIncludes(option?.children?.props.title);
    }

    if (valuesAreFormatted) {
      return checkIncludes(option?.children?.props.id);
    }

    return checkIncludes(option?.children);
  };

  const selectProps: SelectProps<any, DefaultOptionType> = {
    className: 'form-select',
    popupClassName: `search-select-dropdown ${
      placement_bottom ? 'placement_bottom' : ''
    } ${theme} form-dropdown`,
    style: { width: '100%' },
    // dropdownStyle: placement_bottom
    //   ? { animationDuration: '0s' }
    //   : ({} as React.CSSProperties),
    allowClear: true,
    showArrow: true,
    getPopupContainer: container => container,
    showSearch: true,
    virtual: false,
    filterOption: searchFilter,
    listHeight: props.listHeight ? props.listHeight : 148,
    disabled: props.disabled,
    suffixIcon: <ArrowUnion />,
    dropdownRender: menu => (
      <>
        <div
          className={`select_div ${theme} ${placement_bottom ? 'down' : 'top'}`}
        ></div>

        {menu}
      </>
    ),
    ...props
  };

  return <Select {...selectProps}>{props.children}</Select>;
};

export { SelectDropdown };
