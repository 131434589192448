import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffDownloadIcon } from 'Icons/TimeOffActionIcons/TimeOffDownloadIcon';
import { Row, UploadFile } from 'antd';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { FormattedMessage } from 'react-intl';

const ButtonCustom = BaseButton(ButtonSimple);

const AttachmentItem: React.FC<{
  file: UploadFile<any>;
  onRemoveButtonPress: () => void;
}> = ({ file, onRemoveButtonPress = () => {} }) => {
  let binaryData = [];
  binaryData.push(file);

  const { theme } = useThemeContext();
  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div className={`label-attachement ${theme} `}>
        <div className="attachemt_text">
          <FormattedMessage id="attachment" />
        </div>
        <div className="attachemnt_container-btns">
          <div className="btns-row">
            <a
              href={URL.createObjectURL(new Blob(binaryData))}
              download={file.name}
              rel="noopener noreferrer"
              target={'_blank'}
              className={`btn_download ${theme}`}
            >
              <TimeOffDownloadIcon />
            </a>
            <div className="separator"></div>

            <ButtonCustom
              className={`text-bold-normal btn-default-custom  ${theme}`}
              type="button"
              onClick={onRemoveButtonPress}
            >
              <TimeOffDeleteIcon />
            </ButtonCustom>
          </div>
        </div>
      </div>
    </Row>
  );
};
export default AttachmentItem;
