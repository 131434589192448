import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import { getFilters } from 'pages/Inventory/utils';
import { Link } from 'react-router-dom';
import { IEmployee, IRole } from 'services/providers/EmployeeProvider/types';
import _sort from 'utils/sorter';
import { getColumnSearchProps } from '../../SearchTable';
import { getTitleColumns } from '../../TitlesColumns/utils';
import { titlesKeysofColumnsColleagues } from './utils';
import { allowPermision, userRoles } from '../../../utils/userManagement';
import { SafeFormattedMessage } from '../../UIComponents/SafeFormattedMessage';
const { Text } = Typography;
const getTeamsFilters = data => {
  const result = [];
  if (data.length) {
    data
      .map((employee: IEmployee) => employee.teams)
      .map(employeeTeams => {
        if (Object.keys(employeeTeams).length > 0) {
          Object.entries(employeeTeams).map(([teamId, teamName]) => {
            if (!result.find(teamItem => teamItem.key === teamId)) {
              result.push({
                text: teamName,
                value: teamName,
                key: teamId
              });
            }
          });
        }
      });
  }
  result.push({
    text: 'blank',
    value: 'blank'
  });

  return result;
};

export const columnsMobileColleagues = (
  data,
  role,
  params,
  isModalOpen,
  setIsModalOpen,
  stateActions,
  dataSource
): ColumnsType => {
  const tableColumns = [
    {
      title: getTitleColumns('fullName', 'colleagues'),
      dataIndex: 'fullName',
      sorter: (a, b) => _sort(a.fullName, b.fullName, 'string'),
      ...getColumnSearchProps('fullName', params),
      ellipsis: {
        showTitle: false
      },
      defaultSortOrder: 'ascend' as SortOrder,
      render: (_, record) =>
        allowPermision(role, [
          userRoles.Admin,
          userRoles.HR,
          userRoles.Accountant
        ]) ? (
          <Link
            to={`viewProfile?employeeId=${record.employeeId}`}
            style={{ display: 'flex', whiteSpace: 'nowrap' }}
          >
            <Text underline className="text-medium-big ellipsis-cell-text">
              {record.fullName}
            </Text>
          </Link>
        ) : (
          record.fullName
        ),
      width: '50%'
    },
    // {
    //   title: getTitleColumns('firstName', 'colleagues'),
    //   dataIndex: 'firstName',
    //   sorter: (a, b) => _sort(a.firstName, b.firstName, 'string'),
    //   filterSearch: true,
    //   ...getColumnSearchProps('firstName', params),
    //   render: (_, record) =>
    //     params.isAdminUserRole ? (
    //       <Link
    //         to={`viewProfile?employeeId=${record.employeeId}`}
    //         style={{ display: 'flex', whiteSpace: 'nowrap' }}
    //       >
    //         <Text underline className="text-bold-big ellipsis-cell-text">
    //           {record.firstName}
    //         </Text>
    //       </Link>
    //     ) : (
    //       record.firstName
    //     ),
    //   width: '15%',
    // },
    {
      title: getTitleColumns('position', 'colleagues'),
      dataIndex: 'position',
      filters: getFilters(data, 'position'),
      onFilter: (value: string, record) => record.position.indexOf(value) === 0,
      render: (_, record) => (
        <div className="line-clamp-1">
          <SafeFormattedMessage id={record.position} />
        </div>
      ),
      width: '50%'
    }
  ];

  const dataSourceKeys = dataSource.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
    titlesKeysofColumnsColleagues.some(item => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  const checkForUserRoleIntern = (a: any) =>
    !(role === IRole[IRole.Intern] && a.dataIndex === 'phoneNumber');

  return tableColumns
    .filter(checkForDataDisplay)
    .filter(checkForUserRoleIntern);
};
