import { Typography, Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Panel } = Collapse;
export const FailedRowError = ({ errors, rowNumber }) => (
  <Collapse bordered={false} ghost>
    {Object.entries(errors).map(([errorType, errorDescription], index) => (
      <Panel
        header={
          <Typography.Text strong={true}>
            <FormattedMessage
              id="ImportValidationError"
              values={{
                rowNumber: rowNumber,
                errorType: <FormattedMessage id={errorType} />,
              }}
            />
          </Typography.Text>
        }
        key={index}
      >
        <ul>
          {(errorDescription as string).split(/[.!]/).map(
            (e) =>
              e && (
                <li>
                  <Typography>{e as string}</Typography>
                </li>
              )
          )}
        </ul>
      </Panel>
    ))}
  </Collapse>
);

/**
 * Filters and extracts unique values from an array of objects based on a specified parameter.
 * @param {Array} data - The array of objects to filter getAllAssetsData.
 * @param {string} paramName - The name of the parameter(in this case assigneeName) to extract and filter unique values from.
 * @returns {Array} - An array containing unique values in the specified format.
 */
export const getFiltersPosition = (data, paramName) => {
  // Step 1: Extract and filter unique values based on assigneeName or position
  const result = data
    .map((item) => item[paramName])
    .filter((pos, index, arr) => arr.indexOf(pos) === index);

  // Step 2: Transform the filtered values into the desired format for filter popup
  const res = result.map((item) => {
    // Handle null values by replacing them with "blank" 
    const textValue = item === null ? "blank" : item;
    return {
      text: <FormattedMessage id={textValue} />,
      value: textValue,
    };
  });

  // Step 3: Return the transformed array of unique values
  return res;
};
