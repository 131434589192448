export const getFileUploadFormData = (
  jsonData: { [key: string]: object } | null,
  files: { formDataFileName: string; uploadFiles: File[] | Blob[] | undefined } | null
) => {
  const formData = new FormData();

  if (files && files.uploadFiles && files.uploadFiles.length) {
    files.uploadFiles.map((f: File | Blob) => formData.append(`${files.formDataFileName}`, f as Blob))
  }

  if (jsonData && Object.keys(jsonData).length) {
    for (const key in jsonData) {
      formData.append(key, JSON.stringify(jsonData[key]));
    }
  }

  return formData;
};
