export const FillArrowDown = ({ className, ...props }) => (
  <div role="img" aria-label="filter_arrow_down" className={`filter_arrow_down ${className}`} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 8 3"
      fill="none"
    >
      <path
        d="M4 3L0.535898 6.52533e-07L7.4641 4.68497e-08L4 3Z"
        fill="currentColor"
      />
    </svg>
  </div>
);
