import { useIntl } from "react-intl";
import { setMethodUpdate } from "redux/slices/methodUpdateSlice";
import { TicketingWorkQueueService } from "services";
import { getSuccessMessages } from "utils/getSuccessMessages";
import { notifyWithSuccessfulDataUpdated } from "utils/notificationsUtils";
import { onFormFinishWithPassedValidation } from "utils/onSubmitForm";

export const onFinish = (scope, data, workQueueId, actions) => {

    const formActions = {
      add: TicketingWorkQueueService.addWorkQueue,
      edit: TicketingWorkQueueService.updateWorkQueue,
    };


    if (scope === 'remove') {
        TicketingWorkQueueService.removeWorkQueue(null, 
         workQueueId,
        ).then((res) => {          
          if (!actions) {
            return;
          }
    
          if (actions.dispatch && typeof actions.dispatch === 'function') {
            actions.dispatch(setMethodUpdate(true));
          }
          if (actions.toggleModal && typeof actions.toggleModal === 'function') {
            actions.toggleModal()
          }
          if(res?.data?.statusCode === 200 ) {
            notifyWithSuccessfulDataUpdated(
              '',
              getSuccessMessages.success_removed_work_queue
            );
          }
  
        });
        return;
      }

  
    data = workQueueId ? { workQueueId, ...data } : data.name;
    const getMessages =
      scope === 'add'
        ? getSuccessMessages.succes_added_work_queue
        :  getSuccessMessages.succes_edit_work_queue
  
    onFormFinishWithPassedValidation(
      formActions,
      scope,
      data,
      actions,
      getMessages
    );
  };