import { commonRequest } from '../../generic';
import { ITicketCategory, ITicketSubcategory } from '../TicketingProvider/types';
import { IExtendedTicketSubCategory, ITicketCategoryWithDetails } from './types';

class TicketingSettingsProvider {
  async getAllCategories() {
    return await commonRequest<ITicketCategory[]>(
      'GET',
      null,
      'Ticket/getAllCategories'
    );
  }

  async getAllCategoriesWithDetails() {
    return await commonRequest<ITicketCategoryWithDetails[]>(
      'GET',
      null,
      'Ticket/GetAllCategoriesWithDetails'
    );
  }

  async updateCategory(payload: ITicketCategory) {
    const payloadData = { payload };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'Ticket/updateCategory'
    );
  }

  async addCategory(payload: ITicketCategory) {
    const payloadData = { payload };

    return await commonRequest('POST', payloadData, 'Ticket/addCategory');
  }

  async removeCategory(payload: number) {
    return await commonRequest<any>(
      'POST',
      null,
      'Ticket/deleteCategory/' + payload
    );
  }

  async addSubCategory(payload: ITicketSubcategory) {
    const payloadData = { payload };

    return await commonRequest<any>(
      'POST',
      payloadData,
      'Ticket/addSubCategory'
    );
  }

  async getExtendedSubcategories(payload: number) {
    return await commonRequest<IExtendedTicketSubCategory[]>(
      'GET',
      null,
      'Ticket/getAllCategorySubCategoriesWithDetails?categoryId=' + payload
    );
  }

  async updateSubCategory(payload: ITicketSubcategory) {
    const payloadData = { payload };

    return await commonRequest('POST', payloadData, 'Ticket/updateSubCategory');
  }

  async removeSubCategory(payload: number) {
    return await commonRequest<any>(
      'POST',
      null,
      'Ticket/deleteSubCategory/' + payload
    );
  }
}

export default TicketingSettingsProvider;
