export const download_files = (files) => {
  //helper functor to initiate next download  
  const download_next =(i) => {
    if (i >= files.length) {
      return;
    }
    
    var a = document.createElement('a');
    a.href = files[i].link;
    a.target = '_parent';

    if ('download' in a) {
      a.download = files[i].fileName;
    }
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); 
    } 
    a.parentNode.removeChild(a);

    setTimeout(function() {
      download_next(i + 1);
    }, 500);
  }
  // Initiate the first download.
  files && download_next(0);
}

export const downloadFile = (url, fileName) => {
  fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
    .then((res) => res.blob())
    .then((res) => {
      const anchor = document.createElement('a');
      anchor.setAttribute('download', fileName);
      const href = URL.createObjectURL(res);
      anchor.href = href;
      anchor.setAttribute('target', '_blank');
      anchor.click();
      URL.revokeObjectURL(href);
    });
};