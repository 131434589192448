import { Typography } from 'antd';
import './labelTag.less';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
export const LabelTag = ({ variant = undefined, ...props }) => {
  const { theme } = useThemeContext();
  return (
    <div className={`wrap ${variant ? variant + '-' + theme : ''}`} {...props}>
      <Typography.Text ellipsis>{props.children}</Typography.Text>
    </div>
  );
};
