import { createContext, useContext, useState } from 'react';

const useTeamViewComponentContext = () => {
  const [displayMembers, setDisplayMembers] = useState<boolean>(false);
  const [displayContacts, setDisplayContacts] = useState<boolean>(false);

  function toggleDisplayMembers() {
    setDisplayMembers(!displayMembers);
  }

  return {
    displayMembers,
    setDisplayMembers,
    displayContacts,
    setDisplayContacts,
    toggleDisplayMembers
  };
};

const TeamViewContext = createContext(
  {} as ReturnType<typeof useTeamViewComponentContext>
);

export const TeamViewContextProvider = ({ children }) => {
  return (
    <TeamViewContext.Provider value={useTeamViewComponentContext()}>
      {children}
    </TeamViewContext.Provider>
  );
};

export const useTeamViewContext = () => useContext(TeamViewContext);
