/*This component was created in order to extend an AntDesign limitation 
of providing an interface for manipulating with Start/End time picker inputs for when they're out of focus
*/
import { useEffect, useRef } from 'react';
import { TimePicker } from 'antd';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';

import './index.less';

const { RangePicker } = TimePicker;

export default function TimeRangePicker(props) {
  const startTimeInputRef = useRef(null);
  const endTimeInputRef = useRef(null);
  const { isMobile, deviceOS } = useMobileContext();
  const { theme } = useThemeContext();

  useEffect(() => {
    const wrapper = document?.querySelector(
      '#custom-ant-timerange-picker-wrapper'
    );
    const pickerInputsHTML = wrapper?.querySelectorAll(
      '.ant-picker-input > input'
    );

    startTimeInputRef.current = pickerInputsHTML && pickerInputsHTML[0];
    endTimeInputRef.current = pickerInputsHTML && pickerInputsHTML[1];

    startTimeInputRef.current.addEventListener('blur', props?.onStartTimeBlur);

    endTimeInputRef.current.addEventListener('blur', props?.onEndTimeBlur);

    //cleanup
    return () => {
      startTimeInputRef.current.removeEventListener(
        'blur',
        props?.onStartTimeBlur
      );
      endTimeInputRef.current.removeEventListener('blur', props?.onEndTimeBlur);
    };
  }, []);

  return (
    <div id="custom-ant-timerange-picker-wrapper">
      <RangePicker
        popupClassName={theme}
        dropdownClassName={theme}
        placement='topLeft'
        {...props}
        inputReadOnly={true}
      />
    </div>
  );
}
