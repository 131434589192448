import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Form, Input, Select } from 'antd';
import { SelectDropdown } from 'components/Dropdown';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useModalContext } from '../../../Modal/ModalProvider';
import { onFinish } from '../utils';
import { ITicketCategoryWithDetails } from '../../../../services/providers/TicketingSettingsProvider/types';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import { useEffect, useState } from 'react';
type SettingsCategoryFormProps = {
  scope: string;
  category: ITicketCategoryWithDetails;
};

export const SettingsCategoryForm: React.FC<SettingsCategoryFormProps> = ({
  scope,
  category
}) => {
  const dispatch = useDispatch();
  const { toggleModal } = useModalContext();

  const [form] = Form.useForm();
  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();
  const onFinishFormActions = { dispatch, toggleModal };
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const ITEM_HEIGHT = 37;

  const ticketTypes = ['Request', 'Incident'];
  return (
    <Form
      form={form}
      id="ticketingCategoryForm"
      layout="horizontal"
      labelAlign="left"
      labelCol={{ span: 6 }}
      onFinish={data => {

        onFinish(scope, data, category, onFinishFormActions);
      }}

      initialValues={
        scope !== 'add'
          ? {
            name: category?.name,
            ticketType: category?.ticketType,
            key: category?.key
          }
          : null
      }
    >
      {scope === 'remove' ? (
        <>
          <p style={{ margin: '0 auto', width: '80%', textAlign: 'center' }}>
            <FormattedMessage
              id="CategoryRemoveConfirmText"
              values={{
                categoryName: category?.name
              }}
            />
          </p>
        </>
      ) : (
        <>
          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="SettingsCategoryForm_Name" />}
            name="name"
            isReadOnly={
              isMobile
                ? `col-form-item-component-view ${theme}`
                : `col-form-item-component ${theme}`
            }
            rules={[
              {
                required: true,
                message: <FormattedMessage id="provide_category_name_warning" />,
              },
            ]}
            required={true}
          >
            <Input minLength={1} />
          </ColFormItemComponent>
          <div
            className="member-select"
            style={{
              ...{
                minHeight: isDropdownOpened
                  ? `${ITEM_HEIGHT * 2}px`
                  : `0px`
              }
            }}
          >
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="SettingsCategory_Ticket_type" />}
              name="ticketType"
              isReadOnly={
                isMobile
                  ? `col-form-item-component-view ${theme}`
                  : `col-form-item-component ${theme}`
              }
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="provide_category_ticket_type_warning" />,
                },
              ]}
              required={true}
            >
              <SelectDropdown
                showSearch={true}
                allowClear={true}
                placement_bottom={true}
                onDropdownVisibleChange={() =>
                  setTimeout(
                    () => setIsDropdownOpened(!isDropdownOpened),
                    300
                  )
                }
                open={isDropdownOpened}
              >
                {ticketTypes.map((category, index) => {
                  const categoryTypes = category;

                  return (
                    <Select.Option key={index} value={category}>
                      <SafeFormattedMessage id={`TicketingCategory_${categoryTypes}`} />
                    </Select.Option>
                  );
                })}
              </SelectDropdown>
            </ColFormItemComponent>
          </div>
        </>
      )}
    </Form>
  );
};
