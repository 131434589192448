import { Typography } from 'antd';
import {
  headerTranslations,
  headerTranslationsInitialColumns,
  torColsTableFilters
} from 'pages/TimeManagement/TimeSheet/utils';

const { Text } = Typography;

export const initialAggregatedColumns = (
  aggColumnsWithData: Set<string>,
  countryCode: string
) => {
  const isRO = countryCode === 'RO';

  //TO DO: aggregated columns with data for Gambit
  return isRO
    ? Array.from(aggColumnsWithData)
        .filter(value =>
          Object.keys(headerTranslations).includes(value as string)
        )
        .map(item => {
          return {
            title: (
              <div className="cell-padding">
                <Text
                  className={`text-bold-normal text-align-center text_color_timesheet_text line-normal`}
                >
                  {headerTranslations[item as string] || ''}
                </Text>
              </div>
            ),
            key: item as string,
            className: `column_additional_timesheet`,
            width: 120,
            render: (_, record) => ({
              cellRowClassName: `additional`,
              content: (
                <div className="additional_cell" data-col={item as string}>
                  <Text
                    className={`text-medium-normal text_color_timesheet_text line-normal`}
                  >
                    {record[item as string]}
                  </Text>
                </div>
              )
            })
          };
        })
        .filter(record => record !== undefined)
    : Object.entries(headerTranslationsInitialColumns)
        .map(([key]) => {
          if (aggColumnsWithData.has(key))
            return {
              title: (
                <div className="cell-padding">
                  <Text
                    className={`text-bold-normal text-align-center text_color_timesheet_text line-normal`}
                  >
                    {headerTranslations[key] || ''}
                  </Text>
                </div>
              ),
              key: key,
              className: `column_additional_timesheet`,
              width: 100,
              render: (_, record) => ({
                cellRowClassName: `additional`,
                content: (
                  <div className="additional_cell" data-col={key}>
                    <Text
                      className={`text-medium-normal text_color_timesheet_text line-normal`}
                    >
                      {record[key]}
                    </Text>
                  </div>
                )
              })
            };
        })
        .filter(record => record !== undefined);
};

export const newAggregatedColumns = (data, countryCode: string) => {
  const isRO = countryCode === 'RO';
  return Object.entries(data).map(([key, value]) => {
    return {
      title: () => (
        <div className="cell-padding">
          <p
            className="text-color-center"
            style={{ overflowWrap: 'break-word' }}
          >
            {headerTranslations[value as string] || value}
          </p>
        </div>
      ),
      key: value as string,
      width: 120,
      render: (_, record) => ({
        cellRowClassName: `additional`,
        /*REMARK FOR THE FUTURE TO AVOID WASTING TIME AS I DID: for Jivy, the new agg columns (the ones that are added via filters) 
        take their source from data.aggregatedColumns while gambit takes from data.torColumns AND data.aggregatedColumns*/
        content: (
          <div className="number_of_cell">
            <Text
              className={`text-medium-normal text-align-center text_color_timesheet_text line-normal`}
            >
              {!isRO
                ? record.aggregatedColumns[value as string]
                : record.torColumns[value as string] ??
                  record.aggregatedColumns[value as string]}
            </Text>
          </div>
        )
      })
    };
  });
};

export const torAggregatedColumns = torColumns => {
  return torColumns.map(value => {
    return {
      title: () =>
        value !== 'PersonalLeave' ? (
          <p
            className="text-color-center"
            style={{ overflowWrap: 'break-word' }}
          >
            {value}
          </p>
        ) : (
          <p className="text-color-center" style={{ whiteSpace: 'nowrap' }}>
            {headerTranslations[value]}
          </p>
        ),
      key: value,
      ...torColsTableFilters(value),
      width: 60,
      render: (_, record) => ({
        cellRowClassName: `additional`,
        content: (
          <span data-col={value} className="cell-padding-center">
            {record.torColumns[value]}
          </span>
        )
      })
    };
  });
};
