import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'services/providers/AuthProvider';
import './authLayout.less';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { AnimatePresence, motion } from 'framer-motion';
import TimilyLogoLoading from '../../Icons/Logos/LogoTimily/TimilyLogoLoading';

const LoginCallBack = () => {
  const navigate = useNavigate();
  const { authenticateUser } = useAuth();
  useEffect(() => {
    const authenticate = async () => {
      const { isUserAuthenticated, tenants, idToken, provider } =
        await authenticateUser();
      if (isUserAuthenticated) {
        navigate('/redirect');
      } else if (tenants) {
        navigate('/tenant', { state: { tenants, idToken, provider } });
      } else {
        navigate('/login');
      }
    };

    authenticate();
  }, []);
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ repeat: Infinity, repeatDelay: 5 }}
        style={{
          width: '100vw',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <TimilyLogoLoading />
      </motion.div>
    </AnimatePresence>
  );
};

export default LoginCallBack;
