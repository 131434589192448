import { FormattedMessage } from 'react-intl';

export const titlesKeysofColumnsTeams = [
  {
    title: <FormattedMessage id="teams_tableCol_Team" />,
    dataIndex: 'name',
  },
  {
    title: <FormattedMessage id="teams_tableCol_TeamLead" />,
    dataIndex: 'leadEmployeeName',
  },
  {
    title: <FormattedMessage id="teams_tableCol_Members" />,
    dataIndex: 'countMembers',
  },
];
