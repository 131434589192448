import * as React from 'react';
export const CardViewComponent = () => {
  return (
    <span role='img' aria-label='addUser' className='anticon anticon-addUser paddingIcon'>
      <svg
        width='12'
        height='12'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7 1.375L7 12.625M1 7H12.625M1 3.25L1 10.75C1 11.9926 2.00736 13 3.25 13H10.75C11.9926 13 13 11.9926 13 10.75V3.25C13 2.00736 11.9926 1 10.75 1L3.25 1C2.00736 1 1 2.00736 1 3.25Z'
          stroke='currentColor'
          strokeWidth='1.5'
        />
      </svg>
    </span>
  );
};
