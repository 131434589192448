import { Typography } from 'antd';

const PreviewHoverIcon = ({ children }) => {
  return (
    <div
      style={{
        borderRadius: 4,
        background: 'rgba(255, 255, 255, 0.60)',
        display: 'flex',
        padding: 12,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        maxWidth: 30,
        maxHeight: 30,
      }}
    >
      <Typography>{children}</Typography>
    </div>
  );
};

export default PreviewHoverIcon;
