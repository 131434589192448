import * as React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';

enum statuses {
  success = 'success',
  error = 'error',
  loading = 'pending'
}

interface ComponentWithStatusProps {
  status: statuses | string | boolean;
  children: React.ReactNode;
  Placeholder?: React.ReactNode;
  ignoreAnimation?: boolean;
}

const analyzeStatus = (status, Placeholder) => {
  const statusMap = {
    [statuses.error]: <h1>Something went wrong</h1>,
    [statuses.loading]: Placeholder || <LoadingOutlined />
  };
  return statusMap[status];
};

const ComponentWithStatus: React.FC<ComponentWithStatusProps> = ({
  children,
  status,
  Placeholder,
  ignoreAnimation = false
}) => {
  return status === statuses.success ? (
    !ignoreAnimation ? (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%', height: '100%' }}
          transition={{ duration: 0.4 }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    ) : (
      <>{children}</>
    )
  ) : (
    analyzeStatus(status, Placeholder || null)
  );
};

export default ComponentWithStatus;
