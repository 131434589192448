import dayjs from 'dayjs';
export const convertFromISOStringToSlashedDDMMYYYY = (stringDate: string) => {
  if (
    !/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/g.test(
      stringDate
    )
  ) {
    return '';
  }

  return stringDate.slice(0, 10).split('-').reverse().join('/');
};
export const getTimezoneString = (date: dayjs.Dayjs) =>
  '(GMT' + dayjs(date).format('Z').split(':')[0] + ')';

export const getMillisFromTicks = (val) => val > 621355968000000000 ? ((val - 621355968000000000) / 10000) : 0;