import * as React from 'react';
export const ExportSvg = () => {
  return (
    <span role='img' aria-label='export' className='anticon anticon-export'>
      <svg
        width='1em'
        stroke='currentColor'
        height='1em'
        viewBox='0 0 20 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.4004 7.78014L18.4004 4.07426C18.4004 3.51263 18.1791 2.974 17.7853 2.57686C17.3915 2.17972 16.8573 1.95662 16.3004 1.95662L3.70039 1.95661C3.14344 1.95661 2.6093 2.17972 2.21547 2.57686C1.82164 2.97399 1.60039 3.51263 1.60039 4.07426L1.60039 7.78014M9.99922 20.0433L9.99922 8.04333M9.99922 8.04333L5.19922 12.6285M9.99922 8.04333L14.7992 12.6285'
          stroke='inherit'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
};
