import React from 'react';

import './OptionButton.less';

type OptionButtonProps = {
  value: string;
  checked: boolean;
  onChange: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'checkbox' | 'radio';
  showShape?: boolean;
  className?: string;
  style?: React.CSSProperties;
  theme?: 'light' | 'dark';
};

const OptionButton = ({
  value,
  checked,
  onChange,
  type = 'radio',
  showShape = false,
  className,
  style,
  theme
}: OptionButtonProps) => {
  return (
    <button
      // aria-labelledby={`option-wrapper-label-${value.toLowerCase()}`}
      className={`option-wrapper ${theme} ${className || ''}`}
      style={style}
      tabIndex={-1}
      type='button'
      onClick={($event) => onChange($event)}
    >
      {showShape && type === 'checkbox' && (
        <div
          aria-checked={checked}
          className={`option-wrapper__outer option-wrapper__outer-${type} ${
            checked ? `option-wrapper__outer-${type}--select` : ''
          }`}
          role={type}
          tabIndex={0}
          onKeyDown={($event: React.KeyboardEvent<HTMLDivElement>) => {
            switch ($event.code) {
              case 'Enter':
              case 'Space':
                onChange($event as any);
                break;
              default:
                break;
            };
          }}
        >
          <div
            className={`option-wrapper__inner-${type} ${
              checked ? `option-wrapper__inner-${type}--select` : ''
            }`}
          />
        </div>
      )}
      <span
        className='option-wrapper__label'
        // id={`option-wrapper-label-${value.toLowerCase()}`}
        // tabIndex={showShape ? -1 : 0}
      >{value}</span>
    </button>
  );
};

export default OptionButton;
