import { useLocation } from 'react-router-dom';
import { useAuth } from 'services/providers/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  EmployeeServices,
  GeneralService,
  TeamsService,
  EventService,
  CompanySettingsService
} from 'services';
import { useEffect, useState } from 'react';
import { setDefaultEnumValues, setEvents } from 'redux/slices/generalSlice';
import { setAuth0User, setUserRole } from 'redux/slices/userSlice';
import { Navigate } from 'react-router-dom';
import { setTeams, setMyTeams } from 'redux/slices/teamSlice';
import { setEmployee } from 'redux/slices/employeeSlice';
import { setCompanySettings } from 'redux/slices/companySettingsSlice';
import { ITeam } from 'services/providers/TeamsProvider/types';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import axios from 'axios';
import { MyTeam } from 'services/providers/EmployeeProvider/types';
import { ICompanySettings } from 'redux/types';

const Redirect = ({ user }) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [areRequests, setRequests] = useState(false);
  const currentUser = useSelector((state: RootState) => state.user);
  const storeSessionSettings = (companySettings: ICompanySettings) => {
    sessionStorage.setItem('companySettings', JSON.stringify(companySettings));
  };
  useEffect(() => {
    if (!areRequests && Object.keys(currentUser).length) {
      axios
        .all([
          GeneralService.getAllEnumsWithValues(),
          EmployeeServices.getAll('true'),
          TeamsService.getAllTeams('true'),
          EventService.getAllEvents(),
          TeamsService.getMyTeams(),
          CompanySettingsService.getCompanySettings()
        ])
        .then(
          axios.spread((...responses) => {
            const general = responses[0].data;
            const employees = responses[1].data as IEmployee[];
            const teams = responses[2].data as ITeam[];
            const events = responses[3].data;
            const myTeams = responses[4].data as MyTeam[];
            const companySettings = responses[5].data as ICompanySettings;
            dispatch(setDefaultEnumValues(general));
            dispatch(setEvents(events));
            dispatch(setEmployee(employees));
            dispatch(setTeams(teams));
            dispatch(setMyTeams(myTeams));
            dispatch(setCompanySettings(companySettings));
            setRequests(true);
            storeSessionSettings(companySettings);
          })
        );
    }

    if ((!currentUser || !Object.keys(currentUser).length) && user) {
      dispatch(setAuth0User(user));
      dispatch(setUserRole(user.role));
    }
  }, []);


  const lastVisitedPath = sessionStorage.getItem('LastVisitedPage')
    ? new URL(sessionStorage.getItem('LastVisitedPage')).pathname + new URL(sessionStorage.getItem('LastVisitedPage')).search
    : null;

  sessionStorage.removeItem('LastVisitedPage');

  return (
    <>
      {areRequests || user ? (
        <Navigate
          to={state ?? (lastVisitedPath || '/dashboard')}
          replace={true}
        />
      ) : (
        <Navigate to={'/login'} replace={true} />
      )}
    </>
  );
};

const AuthRedirect = () => {
  const { user, isLoading } = useAuth();
  return !isLoading && <Redirect user={user} />;
};

export default AuthRedirect;
