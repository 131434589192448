import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { DetailedViewIconSVG } from 'Icons/Ticketing/detailedViewIcon';
import { ListViewIconSVG } from 'Icons/Ticketing/listViewIcon';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { useEffect, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import './ticketingPage.less';
import TicketingToolbarFilters from './TicketingToolbarFilters';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { getAllTickets } from 'redux/slices/ticketingSlice/actions';
import { useTicketingModal } from '../../components/Ticketing/TicketingModalContext';
import ModalComponent from '../../components/Modal';
import { useLocation } from 'react-router-dom';
import { resetTicketingFilters } from 'redux/slices/ticketingFilters';
import {
  AssignmentTypes,
  TicketPriority,
  TicketStatus,
  TicketType
} from './types';
import ComponentWithStatus from 'components/ComponentWithStatus';
import {
  TableComponentPlaceholder,
  TicketingDetailedViewPlaceholder
} from 'components/Placeholders';
import { adminRoles, allowPermision } from '../../utils/userManagement';
import { useAuth } from '../../services/providers/AuthProvider';
import { useEffectWithSkipFirstMount } from '../../hooks/useEffectWithSkipFirstMount';
const ButtonComponent = BaseButton(ButtonSimple);
export default function TicketingPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();
  const dispatch = useDispatch<AppDispatch>();

  const { allTicketsLoading } = useSelector(({ ticketing }) => ticketing);
  const { ticketingFilters, ticketing } = useSelector(
    (state: RootState) => state
  );
  const { ticketingModalProps, openFormTicketingModal } = useTicketingModal();

  const [view, setView] = useState<'list' | 'detailed'>(
    location.pathname === '/ticketing/viewTicket' ? 'detailed' : 'list'
  );

  const toggleView = () =>
    setView(prev => (prev === 'list' ? 'detailed' : 'list'));

  useLayoutEffect(() => {
    setView(
      location.pathname === '/ticketing/viewTicket' ? 'detailed' : 'list'
    );
  }, [location]);

  useEffect(() => {
    dispatch(
      getAllTickets({
        filterBy: {
          priorities: ticketingFilters?.urgencyTypes as TicketPriority[],
          assignTypes: ticketingFilters?.assignmentType as AssignmentTypes[],
          statuses: ticketingFilters?.statusTypes as TicketStatus[],
          types: location.state
            ? location.state
            : (ticketingFilters?.categoriesTypes as TicketType[])
        },
        pagination: {
          resultSize:
            location.pathname === '/ticketing/viewTicket'
              ? 10
              : JSON.parse(localStorage.getItem('tablePagination'))?.[
              'ticketingTable'
              ] || 20,
          pageNumber: 1
        }
      })
    );

    // reset all filters in redux
    return () => {
      dispatch(resetTicketingFilters());
    };
  }, [dispatch]);

  useEffectWithSkipFirstMount(() => {
    dispatch(
      getAllTickets({
        filterBy: {
          priorities: ticketingFilters?.urgencyTypes as TicketPriority[],
          assignTypes: ticketingFilters?.assignmentType as AssignmentTypes[],
          statuses: ticketingFilters?.statusTypes as TicketStatus[],
          types: ticketingFilters?.categoriesTypes as TicketType[]
        },
        pagination: {
          resultSize:
            location.pathname === '/ticketing/viewTicket'
              ? 10
              : JSON.parse(localStorage.getItem('tablePagination'))?.[
              'ticketingTable'
              ] || 20,
          pageNumber: 1
        }
      })
    );
  }, [view]);

  const tableDisplayButtonMap = {
    list: {
      text: intl.formatMessage({ id: 'TicketingTableButton__detailedView' }),
      icon: <DetailedViewIconSVG />
    },
    detailed: {
      text: intl.formatMessage({ id: 'TicketingTableButton__listView' }),
      icon: <ListViewIconSVG />
    }
  };

  return (
    <>
      <ModalComponent {...ticketingModalProps} />

      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        buttons={[
          {
            text: intl.formatMessage({ id: 'createTicket' }),
            action: () => {
              openFormTicketingModal('incident');
            },
            show: true
          }
        ]}
        children={<TicketingToolbarFilters defaultCategory={location.state} />}
      />
      <br />
      <TableLayoutPage
        title={<FormattedMessage id="TicketingTable__TicketsList" />}
        className={`${view === 'detailed' ? 'ticketing_table_layout_page' : ''}`}
        renderCustomButton
        displayButton={allowPermision(user.role, adminRoles)}
        displayToggle
        childrenButton={
          <ButtonComponent
            className={`text-bold-normal btn-default-custom ${theme}`}
            icon={tableDisplayButtonMap[view].icon}
            onClick={() => {
              navigate(
                view === 'detailed' ? '/ticketing' : '/ticketing/viewTicket'
              );

              toggleView();
            }}
          >
            {tableDisplayButtonMap[view].text}
          </ButtonComponent>
        }
        style={{
          height: `calc(${deviceHeight}px - 185px)`,
          overflow: 'hidden'
        }}
      >
        {/* TicketingListView and TicketingDetailedView components are rendered based on routing, see routes */}
        <ComponentWithStatus
          ignoreAnimation={true}
          status={ticketing?.allTicketsLoaded ? 'success' : 'pending'}
          Placeholder={
            view === 'detailed' ? (
              <TicketingDetailedViewPlaceholder />
            ) : (
              <TableComponentPlaceholder count={5} />
            )
          }
        >
          <Outlet />
        </ComponentWithStatus>
      </TableLayoutPage>
    </>
  );
}
