import React from 'react';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import GridCardStyles from 'components/UIComponents/GridCard/GridCard.module.less';

interface AvatarProps {
  src: string;
  alt: string;
};

interface ListGroupProps {
  label: React.ReactElement | null;
  data: string | null;
};

interface CardProps {
  className?: string;
  title?: React.ReactNode | null;
  avatar?: AvatarProps | null;
  header?: React.ReactNode | null;
  divider?: boolean;
  body?: React.ReactNode | null;
  listGroup?: ListGroupProps[];
  onClick?: () => void;
};

const GridCard = ({
  className = '',
  title = null,
  avatar = null,
  header = null,
  divider = true,
  body = null,
  listGroup = [],
  onClick = () => { }
}: CardProps) => {
  const { theme } = useThemeContext();

  return (
    <li
      className={`${GridCardStyles["grid-card"]} ${GridCardStyles[theme]} ${className}`}
      onClick={() => onClick()}
    >
      {header ? <>{header}</> : null}
      {divider ? <hr className={GridCardStyles["divider"]}></hr> : null}
      {body ? <>{body}</> : null}
    </li>
  );
};

export default GridCard;
