import * as React from 'react';

const ErrorBoundary = ({ children }) => {
  const [error, setError] = React.useState('');

  const promiseRejectionHandler = React.useCallback(event => {
    setError(event.reason);
  }, []);

  const resetError = React.useCallback(() => {
    setError('');
  }, []);

  React.useEffect(() => {
    window.addEventListener('unhandledrejection', promiseRejectionHandler);

    return () => {
      window.removeEventListener('unhandledrejection', promiseRejectionHandler);
    };
  }, []);

  return error
    ? window.location.replace(`${process.env.REACT_APP_DOMAIN}/login`)
    : children;
};

export default ErrorBoundary;
