import { Modal, ModalProps } from 'antd';
import { Dispatch, FC, ReactNode, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';
import { useConfirmationModalContext } from './ConfirmationModalProvider';
import {
  CustomIntlContextProvider,
  IntlContextProvider,
  useThemeContext,
} from 'AppProvider/ConfigProviderSettings';

import './ConfirmationModalComponent.less';
import '../Modal.less';
import '../../Buttons/buttonStyles.less';
import { FormattedMessage } from 'react-intl';

export interface IConfirmModalComponentProps extends ModalProps {
  showConfirmModal?: boolean;
  setShowConfirmModal?: Dispatch<SetStateAction<boolean>>;
  onCancelModalAction?: () => void;
  isMethodUpdatedOnClose?: boolean;
  confirmContent?: ReactNode;
  setMessageSuccessfully?: string | JSX.Element;
  className?: string;
  onOkModalAction?: (...arg) => Promise<any>;
  showclosableBtn?: boolean;
}

const { confirm } = Modal;

const ConfirmModalComponent: FC<IConfirmModalComponentProps> = ({
  title,
  okText,
  width,
  cancelText,
  showConfirmModal,
  setShowConfirmModal,
  onCancelModalAction,
  isMethodUpdatedOnClose,
  setMessageSuccessfully,
  confirmContent,
  className,
  onOkModalAction,
  showclosableBtn,
}) => {
  const { theme } = useThemeContext();
  const { isConfirmModalOpen, setIsConfirmModalOpen, onOkModalPromise } =
    useConfirmationModalContext();

  const destroyModal = () => {
    setIsConfirmModalOpen(false);
    Modal.destroyAll();
  };

  const dispatch = useDispatch();
  const contentInlineDivStyle = {
    display: 'flex',
    justifyContent: 'center',
    color: theme === 'light' ? '#402A87' : '#BDA7E8',
  };

  useEffect(() => {
    const showModal = showConfirmModal ?? isConfirmModalOpen;
    if (!showModal) {
      return;
    }

    confirm({
      closable: !showclosableBtn? showclosableBtn : true,
      width: width,
      icon: null,
      centered: true,
      mask: true,
      maskStyle: {
        opacity: 0.5,
        color: theme === 'light' ? '#EDECF8' : '#1D1B33',
      },
      className: `confirm-modal-component ${className} ${theme}`,
      content: (
        <CustomIntlContextProvider>
          <IntlContextProvider>
            <div style={contentInlineDivStyle}>
              <h3 className='confirmation-modal-title'>{title ?? 'Confirm'}</h3>
            </div>
            <div className='confirmation-modal-content-msg' style={contentInlineDivStyle}>
              {confirmContent ?? 'Are you sure?'}
            </div>
          </IntlContextProvider>
        </CustomIntlContextProvider>
      ),
      bodyStyle: { display: 'flex', justifyContent: 'center' },
      okText: okText ?? (
        <CustomIntlContextProvider>
          <IntlContextProvider>
            <FormattedMessage id="yes" />
          </IntlContextProvider>
        </CustomIntlContextProvider>
      ),
      cancelText: cancelText ?? (
        <CustomIntlContextProvider>
          <IntlContextProvider>
            <FormattedMessage id="no" />{' '}
          </IntlContextProvider>
        </CustomIntlContextProvider>
      ),
      okButtonProps: {
        className: `btn-primary-custom ${theme}`,
        style: { textAlign: 'center' },
      },
      cancelButtonProps: {
        className: `btn-default-custom ${theme}`,
        style: { textAlign: 'center' },
      },
      onCancel: () => {
        if (onCancelModalAction && typeof onCancelModalAction === 'function') {
          onCancelModalAction();
        }

        if (setShowConfirmModal && typeof setShowConfirmModal === 'function') {
          setShowConfirmModal(false);
        }

        destroyModal();
      },

      onOk: () => {
        //we have two methods to handle the onOk event, via onOkModalPromise (old method) and if we pass onOK manually
        if (onOkModalAction && typeof onOkModalAction === 'function') {
          onOkModalAction().then(() => {
            dispatch(setMethodUpdate(isMethodUpdatedOnClose ?? true));
            notifyWithSuccessfulDataUpdated(
              setMessageSuccessfully ? '' : null,
              setMessageSuccessfully ? setMessageSuccessfully : null
            );
            destroyModal();
          });
        } else {
          onOkModalPromise().then(() => {
            dispatch(setMethodUpdate(isMethodUpdatedOnClose ?? true));
            notifyWithSuccessfulDataUpdated(
              setMessageSuccessfully ? '' : null,
              setMessageSuccessfully ? setMessageSuccessfully : null
            );
            destroyModal();
          });
        }
      },
    });
  }, [showConfirmModal, isConfirmModalOpen]);

  return <></>;
};

export default ConfirmModalComponent;
