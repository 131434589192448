import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { ColumnsType } from 'antd/es/table';
import { FormattedMessage } from 'react-intl';
import { LabelTag } from '../../../components/UIComponents/LabelTag';
import { TicketDto } from '../types';
import {
  TicketPriorityElement,
  formatAssigneeName,
  formatTicketTitle,
  sortByPriority,
  sortByStatus,
} from '../utils';
import _sort from 'utils/sorter';

export const useTicketTableColumns = (): ColumnsType<TicketDto> => {
  const { isMobile, isTabletDevice } = useMobileContext();
  const tableColumns = [
    {
      title: <FormattedMessage id="TicketingTableColumn_title" />,
      dataIndex: 'title',
      sorter: (a, b) => _sort(a.title, b.title, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => formatTicketTitle(record),
    },
    {
      title: <FormattedMessage id="TicketingTableColumn_details" />,
      dataIndex: 'details',
      sorter: (a, b) => _sort(a.details, b.details, 'string'),
      ellipsis: true,
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <>{record.details}</>,
    },
    {
      title: <FormattedMessage id="TicketingTableColumn_assignee" />,
      dataIndex: 'assignee',
      sorter: (a, b) =>
        _sort(formatAssigneeName(a), formatAssigneeName(b), 'string'),
      ellipsis: true,
      width: '20%',
      render: (_, record) =>
        record?.assignee ? (
          formatAssigneeName(record)
        ) : (
          <FormattedMessage id="Unassigned" />
        ),
    },
    {
      title: <FormattedMessage id="TicketingTableColumn_priority" />,
      dataIndex: 'priority',
      sorter: (a, b) => sortByPriority(a, b),
      width: '10ch',
      align: 'center' as const,
      render: (_, record) => (
        <TicketPriorityElement priority={record.priority} />
      ),
    },
    {
      title: <FormattedMessage id="TicketingTableColumn_status" />,
      dataIndex: 'status',
      sorter: (a, b) => sortByStatus(a, b),
      width: '10ch',
      align: 'center' as const,
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LabelTag>
            <FormattedMessage id={'TicketingStatus_' + record.status} />
          </LabelTag>
        </div>
      ),
    },
  ];
  return [...tableColumns];
};
