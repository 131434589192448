export const responseWithData = (data, headers) => ({
  data,
  error: null,
  headers
});

export const emptyResponse = () => ({
  data: null,
  error: null,
});

export const responseError = (error) => ({
  data: null,
  error,
});
