import { Col, Row, Typography } from 'antd';
import { PolicyConfig } from 'AppProvider/ConfigManager/config';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import ModalComponent from 'components/Modal';
import TimeOffRequestForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm';
import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'services/providers/AuthProvider';
import { useCompanySettings } from 'settings';
import { allowPermision, getCurrentUserRole } from 'utils/userManagement';
import { TimeManagementDetails } from './ClockInOut';
import { Requests } from './Requests';
import MobileRequests from './Requests/mobile';
import './Table/tableStyles.less';
import './timeStyles.less';

export type PersonalTimeOffModalProps = {
  isTimeOffRequestForm?: boolean;
  formKeyId: string;
  modalTitle: string | JSX.Element;
  submitBtnText: string | JSX.Element;
  cancelBtnText: string | JSX.Element;
  additionalBtnText?: string;
  requestType?: string;
  scope?: string;
  timeOffId?: number;
  displayRequestButton?: boolean;
  requestTypeOff?: string;
  displayFooterAdditionalButton?: boolean;
  requestTypes?: any;
};

type TimeManagementWidgetProps = {
  openModal?: {
    editProfile: boolean;
    clockInclockOut: boolean;
    request: boolean;
  };
  setModalOpen?: React.Dispatch<
    React.SetStateAction<{
      editProfile: boolean;
      clockInclockOut: boolean;
      request: boolean;
    }>
  >;
  size?: string;
  employeeStatistics?: any;
  scope?: string;
};

export const TimeManagementWidget: FC<TimeManagementWidgetProps> = ({
  openModal,
  setModalOpen,
  size,
  employeeStatistics,
  scope
}) => {
  const { user } = useAuth();
  const intl = useIntl();
  const requestTimeOffText = intl.formatMessage({
    id: 'RequestTimeOffModalTitle'
  });

  const sendUnfinishedText = intl.formatMessage({ id: 'sendUnfinished' });

  const { Text } = Typography;
  const { theme } = useThemeContext();
  const requestFormProps = {
    formKeyId: 'timeOffForm',
    requestType: 'personal',
    modalTitle: requestTimeOffText,
    submitBtnText: <FormattedMessage id="request" />,
    cancelBtnText: <FormattedMessage id="cancel" />,
    additionalBtnText: sendUnfinishedText,
    scope: 'request',
    isTimeOffRequestForm: true,
    displayRequestButton: true,
    requestTypeOff: '',
    requestTypes: []
  };

  const [modalProps, setModalProps] =
    useState<PersonalTimeOffModalProps>(requestFormProps);
  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, ['Admin']);
  const [activeIdxTable, setActiveIdxTable] = useState(0);
  const { isMobile } = useMobileContext();
  const trakingWidthTable = useRef(null);
  const trakingWidthItem = useRef(null);
  const { countryCode } = useCompanySettings();
  const timeOffPolicies = PolicyConfig[
    countryCode
  ]?.DefaultTimeOffRequests.filter(request => {
    return !request.isHidden && (isAdminUserRole ? true : !request.isAdminOnly);
  });

  const numberOfPolicies = timeOffPolicies?.length ?? 0;

  const [widthTrackingCarouselTabel, setWidthTrackingCarouselTabel] =
    useState(0);
  const [widthTrackingCarouselRequest, setWidthTrackingCarouselRequest] =
    useState(0);

  useEffect(() => {
    if (trakingWidthTable.current) {
      setWidthTrackingCarouselTabel(trakingWidthTable.current.offsetWidth);
    }
    if (trakingWidthItem.current) {
      setWidthTrackingCarouselRequest(trakingWidthItem.current.offsetWidth);
    }
    const newCurrentWidths = event => {
      if (trakingWidthTable.current) {
        setWidthTrackingCarouselTabel(trakingWidthTable.current.offsetWidth);
      }
      if (trakingWidthItem.current) {
        setWidthTrackingCarouselRequest(trakingWidthItem.current.offsetWidth);
      }
    };
    window.addEventListener('resize', newCurrentWidths);
    return () => {
      window.removeEventListener('resize', newCurrentWidths);
    };
  }, [trakingWidthItem, trakingWidthTable]);

  //get current url path
  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      {!isMobile ? (
        //Desktop view for time management
        <Row
          className={`time-management-widget-row ${
            path === '/dashboard' ? 'dashboard' : 'my_profile'
          } `}
        >
          {openModal.request && (
            <ModalComponent
              key={modalProps.formKeyId ?? '1'}
              formKeyId={modalProps.formKeyId}
              children={
                <TimeOffRequestForm
                  employeeId={
                    employeeStatistics &&
                    employeeStatistics.data &&
                    employeeStatistics.data.employeeId
                  }
                  requestType={modalProps.requestType}
                  scope={modalProps.scope}
                  timeOffId={modalProps.timeOffId}
                  requestTypeOff={modalProps.requestTypeOff}
                />
              }
              isMaskClosable={modalProps.scope === 'view'}
              title={modalProps.modalTitle}
              cancelBtnText={modalProps.cancelBtnText}
              submitBtnText={modalProps.submitBtnText}
              additionalBtnText={modalProps.additionalBtnText}
              displayFooterSubmitButton={modalProps.displayRequestButton}
              displayFooterAdditionalButton={
                modalProps.displayFooterAdditionalButton
              }
            />
          )}

          {/* header name of widget with clockIn clockOut btns */}
          <TimeManagementDetails
            scope={scope}
            openModal={openModal}
            setModalOpen={setModalOpen}
            enableClockIn={
              employeeStatistics &&
              employeeStatistics.data &&
              employeeStatistics.data.enableClockIn
            }
            lastClockIn={
              employeeStatistics &&
              employeeStatistics.data &&
              employeeStatistics.data.lastClockIn
            }
            workingHours={
              employeeStatistics &&
              employeeStatistics.data &&
              employeeStatistics.data.workingHours
            }
          />

          <Requests
            data={employeeStatistics && employeeStatistics.data}
            scope={scope}
            openModal={openModal}
            setModalOpen={setModalOpen}
            setModalProps={setModalProps}
            requestFormProps={requestFormProps}
          />
        </Row>
      ) : (
        //--- Until here Desktop view
        <Col
          style={{
            height: '100%'
          }}
        >
          <Row
            className="full-content time-management-widget-inner"
            justify="space-between"
            gutter={[0, 24]}
          >
            {openModal.request && (
              <ModalComponent
                key={modalProps.formKeyId ?? '1'}
                formKeyId={modalProps.formKeyId}
                children={
                  <TimeOffRequestForm
                    requestType={modalProps.requestType}
                    scope={modalProps.scope}
                    timeOffId={modalProps.timeOffId}
                    requestTypeOff={modalProps.requestTypeOff}
                    employeeId={
                      employeeStatistics &&
                      employeeStatistics.data &&
                      employeeStatistics.data.employeeId
                    }
                  />
                }
                displayFooterAdditionalButton={
                  modalProps.displayFooterAdditionalButton
                }
                isMaskClosable={modalProps.scope === 'view'}
                title={modalProps.modalTitle}
                cancelBtnText={modalProps.cancelBtnText}
                submitBtnText={modalProps.submitBtnText}
                additionalBtnText={modalProps.additionalBtnText}
                displayFooterSubmitButton={modalProps.displayRequestButton}
              />
            )}
            <Col style={{ height: 'max-content', width: '100%' }}>
              <TimeManagementDetails
                scope={scope}
                openModal={openModal}
                setModalOpen={setModalOpen}
                enableClockIn={
                  employeeStatistics &&
                  employeeStatistics.data &&
                  employeeStatistics.data.enableClockIn
                }
                lastClockIn={
                  employeeStatistics &&
                  employeeStatistics.data &&
                  employeeStatistics.data.lastClockIn
                }
                workingHours={
                  employeeStatistics &&
                  employeeStatistics.data &&
                  employeeStatistics.data.workingHours
                }
              />
            </Col>

            <Col
              style={{
                height: '100%',
                width: '100%'
              }}
            >
              <MobileRequests
                data={employeeStatistics && employeeStatistics.data}
                scope={scope}
                openModal={openModal}
                setModalOpen={setModalOpen}
                setModalProps={setModalProps}
                requestFormProps={requestFormProps}
              />
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};
