import { FC } from 'react';
import { Button } from 'antd';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ButtonProps } from 'antd/lib/button';

import './ButtonWithExpandedText.less';

interface ButtonWithExpandedTextProps extends ButtonProps {
  displayedText: string | JSX.Element;
  embedSrc: string | JSX.Element;
  isDownload?: boolean;
}

const ButtonWithExpandedText: FC<ButtonWithExpandedTextProps> = ({
  displayedText,
  embedSrc,
  isDownload = false,
  ...props
}) => {
  const { theme } = useThemeContext();
  return (
    <>
      <Button
        key={props.id ?? 'editEmployeeButton'}
        type="link"
        className={`button-with-expanded-text ${theme}`}
        {...props}
      >
        {typeof embedSrc === 'string' ? (
          <img src={embedSrc} alt="Button Icon" />
        ) : (
          embedSrc
        )}
        <span>{displayedText}</span>
      </Button>
    </>
  );
};
export default ButtonWithExpandedText;
