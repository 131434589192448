import { Card, CardProps } from 'antd';
import React from 'react';
import classes from './index.module.less';

interface WidgetComponentProps extends CardProps {
  center?: boolean;
}

export const WidgetComponent: React.FC<WidgetComponentProps> = (props) => {
  const { children, className, center, ...rest } = props;

  return (
    <Card
      {...rest}
      className={`${className} ${center ? classes.content__center : ''}`}
    >
      {children}
    </Card>
  );
};