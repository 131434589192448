import { Select } from 'antd';
import { AssetCategoryService } from 'services';
import UseApiRequestHook from 'hooks/useApiRequest.hook';

export const getCategoryOptions = ({ assetType }) => {
  const getAllCategories = UseApiRequestHook<any[]>(
    AssetCategoryService.getAllAssetCategories,
    'true',
    []
  );
  const { Option } = Select;

  const inventoryCategories =
    getAllCategories.status === 'success'
      ? getAllCategories.data.filter(
          (category) => category.assetType === assetType
        )
      : ['Hardware', 'Software'];

  return inventoryCategories.map((category, index) => (
    <Option
      key={category?.categoryId ?? index}
      value={category?.categoryId ?? index}
    >
      {category?.name}
    </Option>
  ));
};
