export const FileIconSVG = ({ stroke = 'currentColor' }) => {
  return (
    <span
      role="img"
      aria-label="detailedView"
      className="anticon anticon-detailedView"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
      >
        <path
          d="M5.00007 5.5H11.0001M5.00007 8.5H11.0001M5.00007 11.5H8.00007M3.49983 1.5H12.5C13.6046 1.5 14.5 2.39545 14.5 3.50004L14.4998 15.5C14.4998 16.6046 13.6043 17.5 12.4998 17.5L3.49975 17.5C2.39518 17.5 1.49975 16.6045 1.49976 15.5L1.49983 3.49999C1.49984 2.39542 2.39527 1.5 3.49983 1.5Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
