export const ArrowRightRounded = props => {
  return (
    <span
      role="img"
      aria-label="arrow-right"
      className="anticon anticon-arrow-right"
      {...props}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 3L14 8M14 8L9 13M14 8L2 8"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
