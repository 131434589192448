import { Form } from "antd";
import ModalComponent from "components/Modal";
import { useModalContext } from "components/Modal/ModalProvider";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { TimeOffService } from "../../../services";
import DayViewForm from "./DayViewForm/DayViewForm";
import AttendanceTable from "./Table/attendanceTable";
import './index.less';
import { useThemeContext } from "AppProvider/ConfigProviderSettings";
import { BaseButton, ButtonSimple } from "components/Buttons";

const AttendanceRepport = () => {

  const [modalProps, setModalProps] = useState({
    formKeyId: '',
    submitButtonResolveText: '',
    submitButtonResolveAndNextText: '',
    cancelButtonText: '',
    modalTitle: '',
    employeeId: 0,
    employeeName: '',
    data: {
      employeeId: 0,
      dateOnly: '',
      letter: '',
      conflicts: []
    }
  });
  const ButtonCustom = BaseButton(ButtonSimple);
  const { theme } = useThemeContext();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [status, setStatus] = useState('pending');
  const [dateValue, setDateValue] = useState<string>('');
  const { toggleModal, setIsModalOpen } = useModalContext();
  const hookUseStateActions = { setModalProps, toggleModal };

  const currentDate = dayjs();

  const currentMonth = currentDate.format('MMMM');
  const currentDateObj = currentDate;
  const currentYear = currentDate.year();

  const [dateOfMonth, setDateOfMonth] = useState({
    month: currentMonth,
    date: currentDateObj,
    year: currentYear
  });

  const debounced = useDebouncedCallback(
    (value: string) => {
      setDateValue(value);
    },
    1000 // 1 second delay
  );

  useEffect(() => {
    // Call debounced function with the formatted date string
    const formattedDate = dayjs(dateOfMonth.date).format('YYYY-MM-DDTHH:mm:ss');
    debounced(formattedDate);
  }, [dateOfMonth.date, debounced]); // Include `debounced` in the dependency array

  useEffect(() => {
    const fetchEvents = () => {
      setStatus('pending');
      TimeOffService.getAttendance(
        dayjs(dateOfMonth.date).format('YYYY-MM-DDTHH:mm:ss')
      )
        .then(result => {
          const { data, error } = result;
          if (error) {
            console.error(error);
            setStatus('error');
            return;
          }
          if (data) {
            // const monthsData = (data as AttendanceData)?.months;
            setDataSource(data as any);
            setStatus('success');

          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setStatus('error');
        });
    };


    if (dateValue) {
      fetchEvents(); // Initial fetch
      const intervalId = setInterval(fetchEvents, 20 * 60 * 1000); // Set interval to 20 minutes

      return () => clearInterval(intervalId); // Clear interval on unmount
    }
  }, [dateValue]);


  return (
    <>
      <ModalComponent
        formKeyId={modalProps.formKeyId}
        title={modalProps.modalTitle}
        displayFooterSubmitButton={false}
        isMaskClosable={false}
        footerButtons={
          modalProps.formKeyId === 'dayEditForm' ? (
            <div className="btns_row">
              <div className="btn_col_default">
                <ButtonCustom
                  className={`text-bold-normal btn-default-custom ${theme}`}
                  style={{ width: '100%', maxWidth: '100%' }}
                  key="closeModalButton"
                  onClick={() => {

                    toggleModal();
                  }}
                >
                  {modalProps.cancelButtonText ?? 'close'}
                </ButtonCustom>
              </div>

            </div>
          ) : null
        }
        displayFooterCloseButton={false}
        children={

          <DayViewForm
            form={form}
            key="timesheet"
            data={modalProps.data}

          />

        }
        cancelBtnText={modalProps.cancelButtonText}
      />
      <AttendanceTable
        setDateOfMonth={setDateOfMonth}
        dateOfMonth={dateOfMonth}
        hookUseStateActions={hookUseStateActions}
        data={dataSource}
        status={status}
      />
    </>
  );
};

export default AttendanceRepport;