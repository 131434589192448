import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction
} from 'react';

export interface ISwitchContext {
  isToggleChecked: boolean;
  setIsToggleChecked: Dispatch<SetStateAction<boolean>>;
}

const useSwitchComponentContext = () => {
  const [isToggleChecked, setIsToggleChecked] = useState<boolean>(false);

  function toggleSwitch() {
    setIsToggleChecked(!isToggleChecked);
  }

  return {
    isToggleChecked,
    setIsToggleChecked,
    toggleSwitch
  };
};

const SwitchContext = createContext(
  {} as ReturnType<typeof useSwitchComponentContext>
);

export const SwitchContextProvider = ({ children }) => {
  return (
    <SwitchContext.Provider value={useSwitchComponentContext()}>
      {children}
    </SwitchContext.Provider>
  );
};

export const useSwitchContext = () => useContext(SwitchContext);
