import styles from './markAsResolvedBtn.module.less';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { FormattedMessage } from 'react-intl';
import { TicketingService } from 'services';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'redux/store';
import { getTicketById } from 'redux/slices/ticketingSlice/actions';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
const ButtonCustom = BaseButton(ButtonSimple);

const MarkAsResolvedBtn = () => {
  const { selectedTicket } = useSelector((state: RootState) => state.ticketing);
  const dispatch = useAppDispatch();
  const { theme } = useThemeContext();
  return (
    <div className={styles.wrap}>
      <div className={styles.markAsResolvedContainer}>
        <ButtonCustom
          onClick={() => {
            return TicketingService.submitRequesterFeedback({
              ticketId: selectedTicket?.ticketId,
              wasResolved: false,
            }).then(() => {
              dispatch(getTicketById(selectedTicket?.ticketId));
            });
          }}
          className={`text-bold-normal btn-default-custom ${theme}`}
          type="default"
        >
          <FormattedMessage id="no" />
        </ButtonCustom>
        <ButtonCustom
          onClick={() => {
            return TicketingService.submitRequesterFeedback({
              ticketId: selectedTicket?.ticketId,
              wasResolved: true,
            }).then(() => {
              dispatch(getTicketById(selectedTicket?.ticketId));
            });
          }}
          type="primary"
          className={`text-bold-normal btn-primary-custom ${theme}`}
        >
          <FormattedMessage id="yes" />
        </ButtonCustom>
      </div>
    </div>
  );
};
export default MarkAsResolvedBtn;
