import { Typography, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import TableComponent from 'components/Table';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

const { Text } = Typography;

const columns = (
  data = null,
  role = null,
  params,
  isModalOpen,
  setIsOpen,
  stateActions
): ColumnsType<any> => [
  {
    title: 'No',
    dataIndex: 'assignmentId',
    key: 'assignmentId',
    render: (_, record) => data.indexOf(record) + 1,
    width: 41,
  },
  {
    title: <FormattedMessage id="Actor" />,
    dataIndex: 'assignerName',
    ellipsis: {
      showTitle: false,
    },
    render: (_, record) => (
      <Tooltip placement="top" title={record.assignerName}>
        <Text underline className="text-medium-big ellipsis-cell-text">
          {record.assignerName}
        </Text>
      </Tooltip>
    ),
  },
  {
    title: <FormattedMessage id="Action" />,
    dataIndex: 'action',
    render: (_, record) => {
      const isAssigned =
        record &&
        Object.keys(record).length &&
        record.assigneeName &&
        record.assignType !== 'None';
      return (
        <p className="line-clamp-2">
          {isAssigned ? (
            <FormattedMessage id="Assigned" />
          ) : (
            <FormattedMessage id="Deassigned" />
          )}
        </p>
      );
    },
  },
  {
    title: <FormattedMessage id="Assignee" />,
    dataIndex: 'assigneeName',
    ellipsis: {
      showTitle: false,
    },
    render: (_, record) => (
      <Tooltip placement="top" title={record.assigneeName}>
        <Text underline className="text-bold-big ellipsis-cell-text">
          {record.assigneeName}
        </Text>
      </Tooltip>
    ),
  },
  {
    title: <FormattedMessage id="Date" />,
    dataIndex: 'dateAssigned',
    render: (_, record) => {
      const dateAssigned = record.eventDate
        ? dayjs(record.eventDate).format('DD/MM/YYYY')
        : '';
      return <p className="line-clamp-2">{dateAssigned}</p>;
    },
  },
];

const HardwareHistory = ({ historyData }) => {
  return (
    <TableLayoutPage style={{ marginTop: '24px' }}>
      <TableComponent
        rowKey="assignmentId"
        columns={columns}
        dataSource={historyData}
        data={historyData}
        className="hardWareHistoryTable"
        style={{ maxHeight: '224px' }}
        scroll={{ y: '150px' }}
      />
    </TableLayoutPage>
  );
};

export default HardwareHistory;
