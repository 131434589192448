import {
  getDateTimeDifferenceInDays,
  getDateTimeDifferenceInMinutes
} from 'utils/getDateTimeDifference';
import dayjs from 'dayjs';
import { TimeOffService } from 'services';
import { DateToUTC, DateToBeginningOfLocalDate } from 'components/DateParser';
import { AdditionalFieldSchema, DaysAllowedType } from './types';
import dayjsBusinessDays from 'dayjs-business-days2';
import { FormattedMessage } from 'react-intl';
import { PolicyConfig } from 'AppProvider/ConfigManager/config';

export const enhanceDayJsWithHollidayEvents = generalRootSelector => {
  if (generalRootSelector) {
    const options = {
      holidays:
        generalRootSelector?.general?.events
          ?.filter(e => e.eventType === 'Holiday')
          .map(m => m.eventDate) || [],
      holidayFormat: `YYYY-MM-DDT00:00:00`
    };
    dayjs.extend(dayjsBusinessDays, options);
  } else {
    dayjs.extend(dayjsBusinessDays);
  }
};

export const policiesWithUpload = (countryCode: string) =>
  PolicyConfig[countryCode]?.DefaultTimeOffRequests.filter(
    policy => policy.isDocumentUploadRequired
  ).map(policy => policy.request);

//TO DO: refactor this based on policy config
export const policiesWithUnfinish = [
  'Medical',
  'Paternity',
  'Maternity',
  'Childcare',
  'Marriage',
  'BloodDonation',
  'Bereavement'
];

//TO DO: refactor this based on policy config
export const defaultTimeOffPolicies = (countryCode: string) => {
  if (!countryCode) {
    return;
  }
  return PolicyConfig[countryCode]?.DefaultTimeOffRequests.map(
    policy => policy.request
  );
};

export const timeOffPoliciesTitles = [
  {
    title: <FormattedMessage id="None" />,
    dataIndex: 'None'
  },
  {
    title: <FormattedMessage id="Vacation" />,
    dataIndex: 'Vacation'
  },
  {
    title: <FormattedMessage id="Unpaid" />,
    dataIndex: 'Unpaid'
  },
  {
    title: <FormattedMessage id="WorkFromHome" />,
    dataIndex: 'WorkFromHome'
  },
  {
    title: <FormattedMessage id="Medical" />,
    dataIndex: 'Medical'
  },
  {
    title: <FormattedMessage id="Paternity" />,
    dataIndex: 'Paternity'
  },
  {
    title: <FormattedMessage id="Maternity" />,
    dataIndex: 'Maternity'
  },
  {
    title: <FormattedMessage id="Childcare" />,
    dataIndex: 'Childcare'
  },
  {
    title: <FormattedMessage id="PersonalLeave" />,
    dataIndex: 'PersonalLeave'
  },
  {
    title: <FormattedMessage id="LeaveOffsetting" />,
    dataIndex: 'LeaveOffsetting'
  },
  {
    title: <FormattedMessage id="Overtime" />,
    dataIndex: 'Overtime'
  },
  {
    title: <FormattedMessage id="BloodDonation" />,
    dataIndex: 'BloodDonation'
  },
  {
    title: <FormattedMessage id="Bereavement" />,
    dataIndex: 'Bereavement'
  },
  {
    title: <FormattedMessage id="Marriage" />,
    dataIndex: 'Marriage'
  },
  {
    title: <FormattedMessage id="ForgotBadge" />,
    dataIndex: 'ForgotBadge'
  },
  {
    title: <FormattedMessage id="MilitaryTraining" />,
    dataIndex: 'MilitaryTraining'
  },
  {
    title: <FormattedMessage id="TwoOrMoreChildren" />,
    dataIndex: 'TwoOrMoreChildren'
  },
  {
    title: <FormattedMessage id="BusinessTrip" />,
    dataIndex: 'BusinessTrip'
  },
  {
    title: <FormattedMessage id="FirstDayOfSchool" />,
    dataIndex: 'FirstDayOfSchool'
  },
  {
    title: <FormattedMessage id="LastDayOfSchool" />,
    dataIndex: 'LastDayOfSchool'
  },
  {
    title: <FormattedMessage id="ChildcareLeaveUnpaid" />,
    dataIndex: 'ChildcareLeaveUnpaid'
  },
  {
    title: <FormattedMessage id="BirthOfAChild" />,
    dataIndex: 'BirthOfAChild'
  }
];

export const MedicalTypeOptions = [
  {
    title: <FormattedMessage id="GeneralDisease" />,
    dataIndex: 'GeneralDisease'
  },
  {
    title: <FormattedMessage id="WorkTravelAccident" />,
    dataIndex: 'WorkTravelAccident'
  },

  {
    title: <FormattedMessage id="AccidentAtWork" />,
    dataIndex: 'AccidentAtWork'
  },
  {
    title: <FormattedMessage id="OccupationalDisease" />,
    dataIndex: 'OccupationalDisease'
  },

  {
    title: <FormattedMessage id="InfectiousDiseaseGroupA" />,
    dataIndex: 'InfectiousDiseaseGroupA'
  },
  {
    title: <FormattedMessage id="SurgicalEmergency" />,
    dataIndex: 'SurgicalEmergency'
  },
  {
    title: <FormattedMessage id="Quarantine" />,
    dataIndex: 'Quarantine'
  },
  {
    title: <FormattedMessage id="PregnancyAndConfinement" />,
    dataIndex: 'PregnancyAndConfinement'
  },
  {
    title: <FormattedMessage id="CaringSickChild" />,
    dataIndex: 'CaringSickChild'
  },
  {
    title: <FormattedMessage id="ReductionOfWork" />,
    dataIndex: 'ReductionOfWork'
  },
  {
    title: <FormattedMessage id="TransferTemporaryJob" />,
    dataIndex: 'TransferTemporaryJob'
  },
  {
    title: <FormattedMessage id="Tuberculosis" />,
    dataIndex: 'Tuberculosis'
  },
  {
    title: <FormattedMessage id="CardiovascularDisease" />,
    dataIndex: 'CardiovascularDisease'
  },
  {
    title: <FormattedMessage id="AIDSNeoplasms" />,
    dataIndex: 'AIDSNeoplasms'
  },
  {
    title: <FormattedMessage id="MaternalRiskFactors" />,
    dataIndex: 'MaternalRiskFactors'
  }
];

//TO DO: refactor this based on policy config
export const isUploadingEnabled = (
  timeOffRequestType: string,
  countryCode: string
) => {
  return policiesWithUpload(countryCode)?.includes(timeOffRequestType);
};
//TO DO: refactor this based on policy config

export const isRequestEditable = (timeOffRequestType: string) =>
  ['Medical'].includes(timeOffRequestType);

//TO DO: refactor this based on policy config

export const isTimeBasedRequest = (timeOffRequestType: string) =>
  ['PersonalLeave', 'LeaveOffsetting', 'Overtime', 'Offsetting'].includes(
    timeOffRequestType
  );

export const changeModalSubmitBtnText = (isDraftRequest: boolean, intl) => {
  const timeOffSubmitBtn =
    document.querySelectorAll('button[form=timeOffForm]#submitButton')[-1] ||
    null;

  if (!timeOffSubmitBtn || !timeOffSubmitBtn.children.length) {
    return;
  }

  timeOffSubmitBtn.children[0].textContent = timeOffSubmitBtn
    ? isDraftRequest
      ? intl.formatMessage({ id: 'saveDraft' })
      : intl.formatMessage({ id: 'request' })
    : intl.formatMessage({ id: 'request' });
};

export const getTimeDiffDaysBasedOnCalendarType = (
  dates: [dayjs.Dayjs, dayjs.Dayjs],
  daysAllowedType: DaysAllowedType,
  rootSelector
) => {
  const startDate = dates[0];
  const endDate = dates[1];
  const calculateBusinessDiff = () => {
    if (endDate.diff(startDate, 'day') === 0) {
      timeDifferenceInDays = startDate.isBusinessDay() ? 1 : 0;
    } else if (!endDate.isBusinessDay()) {
      timeDifferenceInDays = endDate.businessDiff(startDate);
    } else {
      timeDifferenceInDays = endDate.businessDiff(startDate) + 1;
    }
  };

  let timeDifferenceInDays: number = 0;
  switch (daysAllowedType) {
    case DaysAllowedType.CalendarDays: {
      timeDifferenceInDays =
        getDateTimeDifferenceInDays(startDate.toDate(), endDate.toDate()) + 1;
      break;
    }
    case DaysAllowedType.WorkingDays: {
      enhanceDayJsWithHollidayEvents(rootSelector);
      calculateBusinessDiff();
      break;
    }
    case DaysAllowedType.CalendarDaysNoWeekends: {
      enhanceDayJsWithHollidayEvents(null);
      calculateBusinessDiff();
      break;
    }
    case DaysAllowedType.CalendarDaysNoHolidays: {
      enhanceDayJsWithHollidayEvents(rootSelector);
      let dd = startDate;
      const allDaysCounter = getDateTimeDifferenceInDays(
        startDate.toDate(),
        endDate.toDate()
      );
      for (let i = 0; i <= allDaysCounter; i++) {
        if (!dd.isHoliday()) {
          timeDifferenceInDays++;
        }
        dd = dd.add(1, 'day');
      }
      break;
    }
    default:
      break;
  }

  return timeDifferenceInDays;
};

export const updateDaysAllowed = (
  requestType,
  statisticData,
  requestStatus,
  setDaysAllowed
) => {
  if (!statisticData) {
    return;
  }

  const selectedPolicy = statisticData.filter(
    policy => policy.statisticType === requestType
  );

  const isDraftRequest = requestStatus === 'Draft' ? true : false;
  const daysLeft = selectedPolicy.length ? selectedPolicy[0].daysLeft : null;

  const daysAllowed = isDraftRequest ? null : daysLeft;

  setDaysAllowed(daysAllowed);
};

export const updateDatesValues = (
  dates: [dayjs.Dayjs, dayjs.Dayjs],
  form,
  setStartDate,
  setEndDate,
  setDaysRequested,
  daysAllowedType: DaysAllowedType,
  rootSelector
) => {
  const startDate = dayjs(dates && dates.length ? dates[0] : null);
  const endDate = dayjs(dates && dates.length > 1 ? dates[1] : null);
  const isStartDateValid = startDate.isValid();
  const isEndDateValid = endDate.isValid();
  setStartDate(isStartDateValid ? startDate : dayjs());
  setEndDate(isEndDateValid ? endDate : null);

  dates = dates ? (dates[0] ? dates : [dayjs(), dates[1]]) : [null, null];
  form.setFieldValue('dates', dates);

  if (!isStartDateValid || !isEndDateValid) {
    return;
  }

  const timeDifferenceInDays = getTimeDiffDaysBasedOnCalendarType(
    [startDate, endDate],
    daysAllowedType,
    rootSelector
  );

  form.setFieldValue('daysRequested', timeDifferenceInDays);

  setDaysRequested(timeDifferenceInDays);
};

export const updateDateTimeValues = (
  dates: [dayjs.Dayjs, dayjs.Dayjs],
  form,
  setDaysRequested
) => {
  let startDateTime = dayjs(dates && dates.length ? dates[0] : null);
  let endDateTime = dayjs(dates && dates.length > 1 ? dates[1] : null);

  const timeDifferenceInMinutes = getDateTimeDifferenceInMinutes(
    startDateTime.toDate(),
    endDateTime.toDate()
  );

  const hoursRequested = toHhMm(
    Math.floor(timeDifferenceInMinutes / 60),
    timeDifferenceInMinutes % 60
  );

  form.setFieldValue('daysRequested', hoursRequested);

  setDaysRequested(hoursRequested);
};

export const getListOfEmployees = ({ employees }): [] => {
  const currentDate = new Date();
  return employees &&
    employees.employee &&
    Object.keys(employees.employee).length
    ? employees.employee
        .filter(
          fe =>
            !fe.endDate || (fe.endDate && new Date(fe.endDate) > currentDate)
        )
        .map(employee => {
          return {
            id: employee.employeeId,
            name: employee.lastName + ' ' + employee.firstName,
            officialFunction: employee.officialFunction || 'N.A.',
            workingSchedule: employee.workingSchedule
          };
        })
    : [];
};

export const getListOfMedicalLeaveTypes = (generalRootSelector): [] => {
  debugger;
  return Object.keys(generalRootSelector).length
    ? generalRootSelector.enums?.MedicalLeaveType &&
      Array.isArray(generalRootSelector.enums?.MedicalLeaveType)
      ? generalRootSelector.enums?.MedicalLeaveType.filter(
          policy => policy !== 'None'
        )
      : defaultTimeOffPolicies(
          generalRootSelector?.companySettings?.settings?.countryCode
        )
    : defaultTimeOffPolicies(
        generalRootSelector?.companySettings?.settings?.countryCode
      );
};

export const filterPoliciesByGender = (policies, gender) => {
  const malePolicies = ['Paternity', 'BirthOfAChild'];
  if (gender === 'Female') {
    return policies.filter(policy => !malePolicies.includes(policy.request));
  }

  return policies;
}
export const getListOfTimeOffPolicies = (generalRootSelector): [] => {
  return Object.keys(generalRootSelector).length
    ? generalRootSelector.enums?.TimeOffPolicies &&
      Array.isArray(generalRootSelector.enums?.TimeOffPolicies)
      ? generalRootSelector.enums?.TimeOffPolicies.filter(
          policy => policy !== 'None'
        )
      : []
    : [];
};

export const getListOfRequestStatuses = (generalRootSelector): [] => {
  return Object.keys(generalRootSelector).length
    ? generalRootSelector.enums?.TimeOffRequestStatus &&
      Array.isArray(generalRootSelector.enums?.TimeOffRequestStatus)
      ? generalRootSelector.enums?.TimeOffRequestStatus.filter(
          policy => policy !== 'None'
        )
      : []
    : [];
};

export const getPolicyByName = (
  policyName: string,
  setAdditionalFieldsSchema,
  setDaysAllowedType
): Promise<any> => {
  return new Promise(resolve => {
    TimeOffService.getPolicyByName({ name: policyName }).then((res: any) => {
      const additionalFields = res.data.additionalFieldsSchema || [];
      setAdditionalFieldsSchema(additionalFields);
      setDaysAllowedType(mapDaysCalendarTypes(res.data.daysAllowedType));
      resolve({
        additionalFields,
        daysAllowedType: mapDaysCalendarTypes(res.data.daysAllowedType)
      });
    });
  });
};

const mapDaysCalendarTypes = (serverType: string): DaysAllowedType => {
  switch (serverType) {
    case 'CalendarDays':
      return DaysAllowedType.CalendarDays;
    case 'WorkingDays':
      return DaysAllowedType.WorkingDays;
    case 'CalendarDaysNoHolidays':
      return DaysAllowedType.CalendarDaysNoHolidays;
    case 'CalendarDaysNoWeekends':
      return DaysAllowedType.CalendarDaysNoWeekends;
    default:
      return DaysAllowedType.WorkingDays;
  }
};

export const getDaysRequested = (daysRequested: string) => {
  if (daysRequested === null) {
    return '';
  }

  if (typeof daysRequested === 'string' && daysRequested.includes(':')) {
    return daysRequested;
  }

  const parsedDays = parseInt(daysRequested);
  if (!isNaN(parsedDays)) {
    return `${parsedDays}`;
  }

  return '';
};

export const toHhMm = (hours: number, minutes: number) => {
  return `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;
};

export const prepareAdditionalFields = (
  formData,
  additionalFieldsSchema: AdditionalFieldSchema[]
) => {
  if (additionalFieldsSchema && additionalFieldsSchema.length) {
    var additionalFields = [];
    additionalFieldsSchema.map(fieldSchema => {
      let fieldValue;
      switch (fieldSchema.fieldType) {
        case 'date': {
          fieldValue = formData[fieldSchema.name]
            ? DateToBeginningOfLocalDate(formData[fieldSchema.name])
            : null; //if no date is provided, dont parse it
          break;
        }
        case 'timeRange': {
          const arrayOfData = formData[fieldSchema.name];
          const start = new Date(formData.dates);
          const end = new Date(formData.dates);
          const startDateFromAddFields = new Date(arrayOfData[0]);
          const endDateFromAddFields = new Date(arrayOfData[1]);
          start.setHours(startDateFromAddFields.getHours());
          start.setMinutes(startDateFromAddFields.getMinutes());
          start.setSeconds(0);
          start.setMilliseconds(0);
          end.setHours(endDateFromAddFields.getHours());
          end.setMinutes(endDateFromAddFields.getMinutes());
          end.setSeconds(0);
          end.setMilliseconds(0);
          fieldValue = {
            start: DateToUTC(start),
            end: DateToUTC(end)
          };
          formData.dates = [dayjs(start), dayjs(end)];
          break;
        }
        default: {
          fieldValue = formData[fieldSchema.name];
          break;
        }
      }
      additionalFields.push({
        name: fieldSchema.name,
        value: fieldValue
      });
    });
    formData['additionalFields'] = additionalFields;
  }
};

export const calculateStartEndDates = ({
  startDate,
  endDate,
  dates,
  isTimeBasedRequest,
  formData
}) => {
  let calculatedStartDate: string;
  let calculatedEndDate: string;

  if (isTimeBasedRequest) {
    startDate = formData.dates[0] || startDate;
    endDate = formData.dates[1] || endDate;
  }

  calculatedStartDate = startDate
    ? isTimeBasedRequest
      ? DateToUTC(startDate)
      : DateToBeginningOfLocalDate(startDate)
    : dates.length
    ? isTimeBasedRequest
      ? DateToUTC(dates[0])
      : DateToBeginningOfLocalDate(dates[0])
    : null;

  calculatedEndDate = endDate
    ? isTimeBasedRequest
      ? DateToUTC(endDate)
      : DateToBeginningOfLocalDate(endDate)
    : dates.length && dates[1]
    ? isTimeBasedRequest
      ? DateToUTC(dates[1])
      : DateToBeginningOfLocalDate(dates[1])
    : isTimeBasedRequest
    ? calculatedStartDate
    : null;

  return [calculatedStartDate, calculatedEndDate];
};
