export const TimeOffSubmitIcon = ({ stroke }) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.91452 8.2512L7.31058 12.2892C7.57226 13.0461 8.63342 13.0714 8.93089 12.3278L12.936 2.31649C13.2146 1.62003 12.5351 0.923376 11.8319 1.18454L1.56372 4.99821C0.813357 5.2769 0.811684 6.33764 1.56117 6.61869L5.91452 8.2512Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
};

