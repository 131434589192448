export const OvertimeComponent = () => {
  return (
    <span role='img' aria-label='vacantion' className='anticon anticon-vacantion'>
      <svg width='2.5rem'
        height='2.5rem'
        viewBox='0 0 41 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M18.8661 1.57957C9.2451 1.57957 1.44578 9.37889 1.44578 18.9998C1.44578 28.6208 9.2451 36.4201 18.8661 36.4201C19.9834 36.4201 21.0754 36.315 22.1332 36.1144C22.5254 36.04 22.9037 36.2976 22.9781 36.6899C23.0525 37.0821 22.7949 37.4604 22.4026 37.5348C21.2565 37.7522 20.0742 37.8659 18.8661 37.8659C8.44662 37.8659 0 29.4193 0 18.9998C0 8.58041 8.44662 0.133789 18.8661 0.133789C29.2855 0.133789 37.7321 8.58041 37.7321 18.9998C37.7321 19.3991 37.4085 19.7227 37.0092 19.7227C36.61 19.7227 36.2863 19.3991 36.2863 18.9998C36.2863 9.37889 28.487 1.57957 18.8661 1.57957ZM18.8661 11.0618C19.2653 11.0618 19.5889 11.3854 19.5889 11.7846V20.7467L25.8983 22.8498C26.2771 22.9761 26.4818 23.3855 26.3555 23.7642C26.2293 24.143 25.8199 24.3477 25.4411 24.2214L18.6375 21.9535C18.3423 21.8551 18.1432 21.5789 18.1432 21.2677V11.7846C18.1432 11.3854 18.4668 11.0618 18.8661 11.0618ZM33.6074 25.0806C34.0066 25.0806 34.3303 25.4043 34.3303 25.8035V30.7504H39.2771C39.6764 30.7504 40 31.074 40 31.4733C40 31.8725 39.6764 32.1961 39.2771 32.1961H34.3303V37.143C34.3303 37.5422 34.0066 37.8659 33.6074 37.8659C33.2081 37.8659 32.8845 37.5422 32.8845 37.143V32.1961H27.9376C27.5384 32.1961 27.2147 31.8725 27.2147 31.4733C27.2147 31.074 27.5384 30.7504 27.9376 30.7504H32.8845V25.8035C32.8845 25.4043 33.2081 25.0806 33.6074 25.0806Z" fill="currentColor"/>
      </svg>
    </span>
  );
};
