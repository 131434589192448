import React from 'react';

const useOutsideClick = <T extends Element>(
  target: React.RefObject<T | null>,
  callback: () => void
): void => {
  const handleClick = (event: MouseEvent): void => {
    if (target?.current && !target.current?.contains(event.target as Node)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [target, callback]);
};

export default useOutsideClick;
