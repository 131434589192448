import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  fetchMoreTickets,
  getAllTickets,
  getTicketById,
  updateTicketPriority
} from './actions';
import { TicketDto } from 'pages/Ticketing/types';
import { TicketingStore } from './types';

const initialState: TicketingStore = {
  allTicketsLoading: true,
  error: null,
  allTicketsLoaded: false,
  allTickets: [],
  selectedTicketByIdLoading: true,
  selectedTicket: null,
  totalNumberOfTickets: 0
};

const ticketingSlice = createSlice({
  name: 'ticketing',
  initialState,
  reducers: {
    setMoreTickets(state, action: PayloadAction<TicketDto[]>) {
      return {
        ...state,
        allTickets: [...state.allTickets, ...action.payload]
      };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllTickets.pending, state => {
        state.allTicketsLoading = true;
      })
      .addCase(getAllTickets.fulfilled, (state, { payload }) => {
        const { data, totalCount } = payload as any;
        state.allTicketsLoading = false;
        state.allTickets = data;
        state.totalNumberOfTickets = totalCount;
        state.allTicketsLoaded = true;

        if (!state.selectedTicket) {
          state.selectedTicket = data[0];
        }
      })
      .addCase(getAllTickets.rejected, state => {
        state.allTicketsLoading = false;
      })
      .addCase(getTicketById.rejected, state => {
        state.selectedTicketByIdLoading = false;
      })
      .addCase(getTicketById.pending, state => {
        state.selectedTicketByIdLoading = true;
      })
      .addCase(getTicketById.fulfilled, (state, { payload }) => {
        state.selectedTicketByIdLoading = false;
        state.selectedTicket = payload;
        //if ticket doesnt exist, push it to the store
        if (
          !state.allTickets.some(
            (ticket: TicketDto) => ticket?.ticketId === payload?.ticketId
          )
        ) {
          state.allTickets.unshift(payload);
        } else {
          //if ticket exists already, just replace the currrent ticket in store
          state.allTickets = state.allTickets.map(ticket => {
            if (ticket?.ticketId === payload?.ticketId) {
              return payload;
            }

            return ticket;
          });
        }
      })
      .addCase(updateTicketPriority.fulfilled, (state, { payload }) => {
        state.allTickets = state.allTickets.map((ticket: TicketDto) =>
          ticket?.ticketId === payload.ticketId ? payload : ticket
        );
      })
      .addCase(fetchMoreTickets.fulfilled, (state, { payload }) => {
        state.allTickets.push(...payload);
      });
  }
});

export const { setMoreTickets } = ticketingSlice.actions;
export default ticketingSlice.reducer;
