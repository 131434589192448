import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { BaseButton, ButtonSimple } from 'components/Buttons';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { IssueService } from 'services';
import {
  notifyWithError,
  notifyWithErrorOnMaxSizeUploadFile,
  notifyWithSuccessfulDataUpdated
} from 'utils/notificationsUtils';
import { getFileUploadFormData } from 'utils/uploadFileRequestWithFormData';
import { useThemeContext } from '../../AppProvider/ConfigProviderSettings';
import { TimeOffDownloadIcon } from 'Icons/TimeOffActionIcons/TimeOffDownloadIcon';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { validateFileType } from 'components/UploadFile/utils';

const IssueCollectorForm: React.FC<any> = props => {
  const [form] = Form.useForm();
  const formData = new FormData();
  const { theme } = useThemeContext();
  const [fileList, setFileList] = React.useState<any>([]);
  const ButtonCustom = BaseButton(ButtonSimple);
  //upload file extension
  const acceptFilesExtension =
    'image/jpeg, image/jpg, image/png, image/gif, image/bmp, image/webp, image/svg+xml, application/pdf';

  return (
    <Form
      form={form}
      id="issueCollectorForm"
      layout="horizontal"
      labelAlign="left"
      labelCol={{ span: 6 }}
      onFinish={async data => {
        formData.append('userFeedback', data.description);

        const parsedData = getFileUploadFormData(
          {
            userFeedback: data.description
          },
          fileList.length > 0
            ? {
                formDataFileName: 'attachments',
                uploadFiles: fileList
              }
            : null
        );

        await IssueService.getUserFeedback(parsedData).then(r => {
          props.hideModal();
          notifyWithSuccessfulDataUpdated(
            <FormattedMessage id={'IssueSentSuccess'} />
          );
        });
      }}
    >
      <ColFormItemComponent
        span={24}
        label={<FormattedMessage id="Description" />}
        name="description"
        rules={[
          {
            required: true,
            message: <FormattedMessage id="DescriptionNotProvidedText" />
          }
        ]}
      >
        <TextArea rows={3} minLength={1} maxLength={501} />
      </ColFormItemComponent>
      <ColFormItemComponent
        span={24}
        name="uploadFile"
        style={{
          display: 'flex',
          justifyContent: 'start',
          textAlign: 'left'
        }}
        wrapperCol={{ span: 24 }}
      >
        <Upload
          multiple={true}
          fileList={fileList}
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: false
          }}
          itemRender={(_, file) => {
            let binaryData = [];
            binaryData.push(file);

            return (
              <div>
                <div className={`label-attachement ${theme} `}>
                  <div className="attachemt_text">
                    <FormattedMessage id="attachment" />
                  </div>
                  <div className="attachemnt_container-btns">
                    <div className="btns-row">
                      <a
                        href={URL.createObjectURL(new Blob(binaryData))}
                        download={file.name}
                        rel="noopener noreferrer"
                        target={'_blank'}
                        className={`btn_download ${theme}`}
                      >
                        <TimeOffDownloadIcon />
                      </a>
                      <div className="separator"></div>
                      <ButtonCustom
                        className={`text-bold-normal btn-default-custom  ${theme}`}
                        type="button"
                        onClick={() => {
                          const index = fileList.indexOf(file);
                          const newFileList = fileList.slice();
                          newFileList.splice(index, 1);
                          setFileList(newFileList);
                        }}
                      >
                        <TimeOffDeleteIcon />
                      </ButtonCustom>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
          accept={acceptFilesExtension}
          beforeUpload={uploadedFile => {
            const isAllowedType = validateFileType(
              uploadedFile,
              acceptFilesExtension
            );
            if (!isAllowedType) {
              notifyWithError({
                message: <FormattedMessage id="upload_file_type_not_allowed" />,
                description: null
              });
              form.validateFields(['uploadFile']);
              return false;
            }
            if (uploadedFile.size && uploadedFile.size >= 10 * 1000000) {
              notifyWithErrorOnMaxSizeUploadFile(10);
              form.validateFields(['uploadFile']);
              return false;
            }

            setFileList([...fileList, uploadedFile]);
            return false;
          }}
        >
          <ButtonCustom
            className={`text-bold-normal btn-upload-custom ${theme}`}
            style={{
              padding: '4px 8px'
            }}
          >
            <span>
              <FormattedMessage id={'Upload'} />
            </span>
          </ButtonCustom>
        </Upload>
      </ColFormItemComponent>
    </Form>
  );
};

export default IssueCollectorForm;
