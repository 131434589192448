export const ArrowUnion = (props) => (
  <span role="img" aria-label="union" className="union_icon" {...props}>
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7388 4.6967C4.33075 5.10111 3.66922 5.10109 3.2612 4.6967L0.306023 1.76777C-0.101996 1.36337 -0.102018 0.707721 0.306023 0.303304C0.714065 -0.101113 1.37559 -0.101091 1.78361 0.303304L4 2.5L6.21639 0.303304C6.62443 -0.101113 7.28596 -0.10109 7.69398 0.303304C8.102 0.707698 8.10202 1.36335 7.69398 1.76777L4.7388 4.6967Z"
        fill="currentColor"
      />
    </svg>
  </span>
);
