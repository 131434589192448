export const StatusProfileSvg = (props) => {
  return (
    <span
      role='img'
      aria-label='status'
      className='anticon anticon-status'
      {...props}
    >
      <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_60_530)'>
          <circle cx='8.5' cy='8.5' r='4' fill='#219653' />
          <circle
            cx='8.5'
            cy='8.5'
            r='3.75'
            stroke='#402A87'
            strokeWidth='0.5'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_60_530'
            x='0.5'
            y='0.5'
            width='16'
            height='16'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset />
            <feGaussianBlur stdDeviation='2' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.25098 0 0 0 0 0.168627 0 0 0 0 0.529412 0 0 0 0.4 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_60_530'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_60_530'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </span>
  );
};
