import React from "react";

interface Props {
  item: any;
  column: { key: string; render?: (column: any, item: any, index?: number) => any; width?: number };
  onMouseEnter?: (index: number) => void;
  itemIndex: number;
  onMouseLeave?: () => void;
  indexRow?: number;
}

const TableRowCell: React.FC<Props> = ({
  item,
  column,
  onMouseEnter,
  itemIndex,
  onMouseLeave,
  indexRow
}) => {
  const { key } = column;
  const cellContent = item[key];
  const handleMouseEnter = () => {
    if (onMouseEnter) {
    onMouseEnter(itemIndex);
    }
  };
  const handleMouseLeave = () => {
    if (onMouseLeave) {
    onMouseLeave();
    }
  };
  const tdClassName = column.render?.(column, item,  indexRow)?.cellRowClassName ?? "";

  return (
    <td
      className={`table-component-body-cell ${tdClassName}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="body-cell-container"
        style={{ width: column.width ? `${column.width}px` : "auto" }}
      >
        {column.render
          ? column.render(column, item,  indexRow)?.content || column.render(column, item , indexRow)
          : cellContent}
      </div>
    </td>
  );
};

export default TableRowCell;