import { MicrosoftIconComponent } from 'Icons/LogIn/Microsoft';
import { GoogleIconComponent } from 'Icons/LogIn/Google';
import {
  useAuth,
  checkAuthTokenSilently
} from 'services/providers/AuthProvider';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseJwt } from 'pages/Redirect/AuthRedirect/utils';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import AuthLayout from './AuthLayout';

const LoginPage = () => {
  const {
    initiateAuthenticationWith,
    isAuthenticated,
    setUser,
    getAccessTokenSilently
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const checkForExistingAuthentication = async () => {
      if (isAuthenticated) {
        navigate('/');
      } else {
        await checkAuthTokenSilently();
        const token = getAccessTokenSilently();
        if (token) {
          setUser({ email: parseJwt(token).email } as IEmployee);
          navigate('/redirect', { state: location?.state?.from });
        }
      }
    };
    checkForExistingAuthentication();
  }, []);
  return (
    <AuthLayout>
      <span className="optionsText">Log In</span>
      <div
        className="optionButton"
        onClick={() => initiateAuthenticationWith('google')}
      >
        <GoogleIconComponent />
        Continue with Google
      </div>
      <div
        className="optionButton"
        onClick={() => initiateAuthenticationWith('ms')}
      >
        <MicrosoftIconComponent />
        Continue with Microsoft Account
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
