import React from 'react';
import TableRowCell from './TableRowCell';

interface TableRowProps {
  data: any[];
  columns: any[];
  filteredResults: string[];
  rowClassName?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

const TableRow: React.FC<TableRowProps> = ({ data, columns, rowClassName, onMouseEnter, onMouseLeave }) => {


  return (
    <>
      {data.map((item, itemIndex) => (
        <tr key={`table-body-${itemIndex}`} className={`table-component-body-row ${rowClassName ?? ``}`}>
          {columns.map((column, columnIndex) => (
            <TableRowCell
              key={`table-row-cell-${columnIndex}`}
              item={item}
              column={column}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              indexRow={itemIndex}
              itemIndex={columnIndex}
            />
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableRow;

