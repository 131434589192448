import { commonRequest } from 'services/generic';

type GetAssetCategoryParams = {
  categoryId: number;
  extended: boolean;
};
export default class AssetCategoryProvider {
  async getAssetCategory(payload: string, parameters: GetAssetCategoryParams) {
    if (!parameters.categoryId) {
      return;
    } else {
      const payloadData = { parameters };
      return await commonRequest<any>(
        'POST',
        payloadData,
        '/Inventory/GetAssetCategory'
      );
    }
  }
  async getAllAssetCategories(payload: string) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      '/Inventory/getAllAssetCategories'
    );
  }

  async addAssetCategory(payload: string) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      '/Inventory/AddAssetCategory'
    );
  }

  async updateAssetCategory(payload: string) {
    const payloadData = { payload };
    return await commonRequest<any>(
      'POST',
      payloadData,
      '/Inventory/UpdateAssetCategory'
    );
  }

  async deleteAssetCategory(payload: string, parameters: object) {
    const payloadData = { parameters };

    return await commonRequest<any>(
      'POST',
      payloadData,
      '/Inventory/DeleteAssetCategory'
    );
  }
}
