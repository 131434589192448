import { getTitleSelects } from 'components/TitlesColumns/utils';
import { FormattedDate, FormattedMessage } from 'react-intl';

export const getFiltersHistory = (data) => {
  let historyArray = [];
  if (data.length) {
    historyArray = data.filter(
      (assignment) => assignment.assignType !== 'None'
    );
  }
  const uniqueHistory = historyArray.filter(
    (a, i) =>
      historyArray.findIndex(
        (s) => a.assigneeId === s.assigneeId && a.assignType === s.assignType
      ) === i
  );

  const res = uniqueHistory.map((item) => ({
    text: item.assigneeName,
    value: item.assigneeName,
    key: item.assigneeId,
  }));
  return res;
};

export const getFiltersWithSearch = (data) => {
  let historyArray = [];
  if (data.length) {
    historyArray = data;
  }
  const uniqueHistory = data.filter(
    (a, i) =>
      data.findIndex(
        (s) => a.assigneeId === s.assigneeId
      ) === i
  );

  const res = uniqueHistory.map((item) => {
    const textValue = item.assigneeName === null || item.assigneeName === "" ? "blank" : item.assigneeName;
    return {
      text: textValue === "blank" ? (<FormattedMessage id="blank" />) : textValue,
      value: textValue,
      key: item.assigneeId,
    };
  });

  return res;
};

/**
 * Filters and extracts unique values from an array of objects based on a specified parameter.
 * @param {Array} data - The array of objects to filter getAllAssetsData.
 * @param {string} paramName - The name of the parameter(in this case assigneeName) to extract and filter unique values from.
 * @returns {Array} - An array containing unique values in the specified format.
 */
export const getFilters = (data, paramName) => {
  // Step 1: Extract and filter unique values based on assigneeName
  const result = data
    .map((item) => item[paramName])
    .filter((pos, index, arr) => arr.indexOf(pos) === index);

  // Step 2: Transform the filtered values into the desired format for filter popup
  const res = result.map((item) => {
    // Handle null values by replacing them with "blank" 
    const textValue = item === null || item === "" ? "blank" : item;
    return {
      text: textValue === "blank" ? (<FormattedMessage id="blank" />) : textValue,
      value: textValue,
    };
  });

  // Step 3: Return the transformed array of unique values
  return res;
};


export const getFilterCustomForStatus = (
  data,
  paramName,
  nameTitleArrayRef
) => {
  const result = data
    .map((item) => item[paramName])
    .filter((pos, index, arr) => arr.indexOf(pos) === index);

  const res = result.map((item) => ({
    text: getTitleSelects(item, nameTitleArrayRef),
    value: item,
  }));
  return res;
};
