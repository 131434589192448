export const MicrosoftIconComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14328_60388)">
        <path d="M8.55464 8.55464H0V0H8.55464V8.55464Z" fill="#F1511B"/>
        <path d="M18 8.55464H9.44543V0H18V8.55464Z" fill="#80CC28"/>
        <path d="M8.55443 18.0001H0V9.4455H8.55443V18.0001Z" fill="#00ADEF"/>
        <path d="M18 18.0001H9.44543V9.4455H18V18.0001Z" fill="#FBBC09"/>
      </g>
      <defs>
        <clipPath id="clip0_14328_60388">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
