import React from 'react';
import { personalTimeOffReducer, initialState } from './reducers';
import { TimeOffRequestsAndResponse } from '../types';

export interface TimeOffPersonalContext {
  timeOffPersonalState: TimeOffRequestsAndResponse;
  timeOffPersonalDispatch: React.Dispatch<any>;
};

const TimeOffPersonalProviderContext = React.createContext<TimeOffPersonalContext>(
  {} as TimeOffPersonalContext
);

export const TimeOffPersonalProvider = ({ children }) => {
  const [
    timeOffPersonalState,
    timeOffPersonalDispatch
  ] = React.useReducer(personalTimeOffReducer, initialState);
  return (
    <TimeOffPersonalProviderContext.Provider
      value={{ timeOffPersonalState, timeOffPersonalDispatch }}
    >
      {children}
    </TimeOffPersonalProviderContext.Provider>
  );
};

export const usePersonalRequestsFilterContext = () => {
  return React.useContext(TimeOffPersonalProviderContext);
};
