import React from 'react';
import { motion } from 'framer-motion';

const circleVariants = i => ({
  animate: {
    scale: [1, 1.5, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      ease: 'easeInOut',
      delay: i * 0.2
    }
  }
});

const containerVariants = {
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const colors = ['#0099ff', '#22cc88', '#ffaa00', '#ff0055'];

const TimilyLogoLoading = () => (
  <motion.div
    variants={containerVariants}
    animate="animate"
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
      gap: '10px',
      width: 100,
      height: 100,
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    {[0, 1, 3, 2].map((_, i) => (
      <motion.div
        key={i}
        variants={circleVariants(i)}
        style={{
          width: 30,
          height: 30,
          borderRadius: '50%',
          backgroundColor: colors[i]
        }}
      />
    ))}
  </motion.div>
);

export default TimilyLogoLoading;
