import { ReportsFiltersProvider } from 'components/ReportsDetails/ReportsFilterContext';
import ReportsView from 'components/ReportsView';
import './Reports.less';

const ReportsPage = () => {
  return (
    <ReportsFiltersProvider>
      <ReportsView />
    </ReportsFiltersProvider>
  );
};

export default ReportsPage;
