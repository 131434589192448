import * as React from 'react';

import { Input, Row, Space } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import normalizeString from 'utils/normalizeString';
import { FormattedMessage } from 'react-intl';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
type DataIndex = keyof any;
const ButtonCustom = BaseButton(ButtonSimple);

const ThemeWrapper: React.FC<any> = (props) => {
  const { children, className } = props;
  const { theme } = useThemeContext();

  return (
    <ButtonCustom {...props} className={`${className} ${theme}`}>
      {children}
    </ButtonCustom>
  );
};

export const getColumnSearchProps = (
  dataIndex,
  params: any,
  onFilter?: (value: string | number | boolean, record: any) => boolean
): ColumnType<any> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 16 }}>
      <Input
        ref={params.searchInput}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() =>
          handleSearch(selectedKeys as string[], confirm, dataIndex, params)
        }
        style={{ marginBottom: 16, display: 'block' }}
      />
      <Row style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
        <ThemeWrapper
          className="text-bold-normal btn-primary-custom"
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex, params)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ flex: 1 }}
        >
          <span>
            <FormattedMessage id="ColumnFilterSearchText" />
          </span>
        </ThemeWrapper>
        <ThemeWrapper
          className="text-bold-normal btn-default-custom"
          onClick={() => {
            clearFilters();
            handleReset(clearFilters, params);
            handleSearch(selectedKeys as string[], confirm, dataIndex, params);
          }}
          size="small"
          style={{ flex: 1 }}
        >
          <FormattedMessage id="ColumnFilterResetText" />
        </ThemeWrapper>
      </Row>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#BDA7E8' : undefined }} />
  ),
  onFilter: onFilter
    ? onFilter
    : (value, record) =>
        !record[dataIndex]
          ? false
          : normalizeString(record[dataIndex]).includes(
              normalizeString(value as string)
            ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => params.searchInput.current?.select(), 100);
    }
  },
  render: (text) => text,
});

export const handleSearch = (
  selectedKeys: string[],
  confirm: (param?: FilterConfirmProps) => void,
  dataIndex: DataIndex,
  params
) => {
  confirm();

  params.setSearchText(selectedKeys[0]);
  params.setSearchedColumn(dataIndex);
};

export const handleReset = (clearFilters: () => void, params) => {
  clearFilters();
  params.setSearchText('');
};
