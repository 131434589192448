import styles from './ticketHistory.module.less';
import { Avatar, Typography } from 'antd';
import { HistoryUpdateType } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import dayjs from 'dayjs';
import { getTimezoneString } from 'utils/displayDate';

const TicketHistory = props => {
  const { selectedTicket } = useSelector((state: RootState) => state.ticketing);

  const { index, update, history } = props;
  const { requester, awaitingUserFeedbackDate } = selectedTicket;

  const isLastUpdate = index === history.length - 1;
  return (
    <div className={styles.wrap}>
      <div className={styles.historyHeader}>
        <Avatar size={26} src={update?.updatedBy?.externalAvatarUrl} />
        <Typography.Title level={5}>
          {update?.updatedBy?.lastName} {update?.updatedBy?.firstName}
        </Typography.Title>
        <Typography>
          {dayjs(update?.timeStamp).format('DD/MM/YYYY HH:mm') +
            ' ' +
            getTimezoneString(dayjs(update?.timeStamp))}
        </Typography>
      </div>
      <div className={styles.entry} key={update.ticketHistoryId}>
        <Typography.Text className={styles.updateType}>
          <HistoryUpdateType
            update={update}
            requester={requester}
            dueDate={awaitingUserFeedbackDate}
            isLast={isLastUpdate}
          />
        </Typography.Text>
      </div>
    </div>
  );
};
export default TicketHistory;
